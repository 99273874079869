import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import { useEffect } from "react";
import { useCommonTitlesStore } from "../../../../../store/useStores";

const FileUpload = ({
  files = [],
  addChange,
  id,
  canupdate,
  canupdateifnull,
  accept = null,
  multiple = true,
}) => {
  const [fileList, setFileList] = useState(files);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const keys = files.map((file) => file.uid);

  useEffect(() => {
    let tempfiles = files.map((file) => ({
      ...file,
      name:
        file?.name?.length > 30
          ? file.name.substring(0, 30) + "..."
          : file.name,
    }));
    setFileList(tempfiles);
  }, []);

  useEffect(() => {
    let tempFiles = [];
    let isPreuploaded = false;
    if (fileList.length) {
      fileList.forEach((file) => {
        if (keys.includes(file.uid)) {
          isPreuploaded = true;
        } else {
          isPreuploaded = false;
          tempFiles.push(file.originFileObj);
        }
      });
      !isPreuploaded && setFilesToUpload(tempFiles);
    } else {
      setFilesToUpload([]);
    }
  }, [fileList]);

  useEffect(() => {
    addChange(id, filesToUpload, "add");
  }, [filesToUpload]);

  const onChange = (info) => {
    let tempFileList = info.fileList.map((file) => ({
      ...file,
      name:
        file.name.length > 30 ? file.name.substring(0, 30) + "..." : file.name,
      status: "done",
    }));

    if (accept) {
      let extAllowed = accept.split(",").map((val) => val.trim());
      tempFileList = tempFileList.filter((file) =>
        extAllowed.includes(file.name.match(/(\.[^\.]+)$/)[1])
      );
    }

    setFileList(tempFileList);
  };

  // console.log("fileList: ", fileList);
  // console.log("filesToUpload: ", filesToUpload);

  const onRemove = (info) => {
    if (keys.includes(info.uid)) {
      const file = {
        id: info.uid,
        view:
          info.name.length > 20
            ? info.name.substring(0, 20) + "..."
            : info.name,
        action: "D",
      };
      addChange(id, file, "add to delete");
    } else {
      addChange(id, info.originFileObj, "remove");
    }
  };

  const props = {
    name: "file",
    multiple: multiple,
    fileList: fileList,
    accept: accept,
    onChange: (info) => onChange(info),
    onRemove: (info) => onRemove(info),
    //directory: true,
  };

  const isDisabled =
    canupdate === false && canupdateifnull === false
      ? true
      : canupdate === false && canupdateifnull === true && files.length
      ? true
      : false;

  if (isDisabled) {
    return (
      <Button
        disabled={true}
        style={{ marginLeft: 8, marginTop: 8 }}
        icon={<UploadOutlined />}
      >
        {commonTitles["CO|1|UPLOAD"]}
      </Button>
    );
  }

  if (multiple === false && fileList.length) {
    return (
      <Upload {...props}>
        <Button
          disabled={true}
          style={{ marginLeft: 8, marginTop: 8 }}
          icon={<UploadOutlined />}
        >
          {id === "import"
            ? commonTitles["CO|1|CHOOSE"]
            : commonTitles["CO|1|UPLOAD"]}
        </Button>
      </Upload>
    );
  }

  return (
    <Upload {...props}>
      <Button
        // disabled={
        //   canupdate === false && canupdateifnull === false
        //     ? true
        //     : canupdate === false && canupdateifnull === true && files.length
        //     ? true
        //     : false
        // }
        style={{ marginLeft: 8, marginTop: 8 }}
        icon={<UploadOutlined />}
      >
        {id === "import"
          ? commonTitles["CO|1|CHOOSE"]
          : commonTitles["CO|1|UPLOAD"]}
      </Button>
    </Upload>
  );
};

export default FileUpload;
