import Flex from "../../../../../../../helpers/components/Flex";
import { Input, Select, Image } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../../../../store/useStores";
import { useState, useEffect } from "react";
import countryPhoneCodes from "../../../../../helpers/countryPhoneCodes";
import apiGetLanguagesList from "../../../../../../../api/endpoints/apiGetLanguagesList";

const { Option } = Select;

const PhoneType = ({ id, required, addChange, autoCompletedFields }) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [validPhone, setValidPhone] = useState(true);
  const [countries, setCountries] = useState([]);
  const [changes, setChanges] = useState({ code: "+30" });

  useEffect(() => {
    const fetchData = async () => {
      const serverData = await apiGetLanguagesList(
        baseURL,
        language,
        workspaceID
      );

      const temp = Object.values(serverData.flagfiles).map((flag) => {
        const countryName = flag.split(".")[0] ?? "";
        const phoneCode =
          countryPhoneCodes.filter(
            (country) => country.name === countryName
          )?.[0]?.code ?? "";
        return { name: countryName, phoneCode: phoneCode, flagName: flag };
      });
      setCountries(temp);
    };

    fetchData();
  }, []);

  const checkPhoneValidity = (phone) => {
    if (phone) {
      setValidPhone(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone));
    } else {
      setValidPhone(true);
    }
  };

  const createValue = (value, config) => {
    if (config === "code") {
      if (changes?.phone) {
        if (value) {
          const newValue = value + " " + changes.phone;
          addChange(id, newValue, null);
        } else {
          if (changes?.code) {
            const newValue = changes?.phone ?? null;
            addChange(id, newValue, null);
          }
        }
      }
    } else {
      if (value) {
        let newValue = null;
        if (changes?.code) {
          newValue = changes.code + " " + value;
        } else {
          newValue = value;
        }
        addChange(id, newValue, null);
      } else {
        addChange(id, null, null);
      }
    }
  };

  let value = "";
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    value = autoCompletedFields?.[id] ?? "";
  }

  return (
    <Flex key={countries.length} flexDirection={"column"}>
      <Flex
        flexDirection={"row"}
        style={{ alignContent: "center", width: 300 }}
      >
        <Select
          style={{
            width: "40%",
            marginLeft: 8,
            marginTop: 7,
          }}
          showSearch={true}
          onChange={(e) => {
            setChanges({ ...changes, code: e });
            createValue(e, "code");
          }}
          filterOption={(iv, op) => {
            let opToUse = op.key ? op.key : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          defaultValue={"+30"}
          placeholder={commonTitles["CO|1|OPTIONAL"]}
          virtual={false}
        >
          <Option value={""} />
          {countries
            ?.filter((country) => country.phoneCode && country.flagName)
            ?.map((item) => (
              <Option
                className="option"
                key={item?.phoneCode}
                value={item?.phoneCode}
                //label={item?.name ?? ""}
              >
                <Flex
                  flexDirection={"row"}
                  style={{ gap: "1rem", alignItems: "center" }}
                >
                  <Image
                    width={20}
                    src={require(`../../../../../../../assets/Flags/${item?.flagName}`)}
                    alt={item?.name ?? ""}
                    preview={false}
                  />
                  <p>{`${item?.phoneCode}`}</p>
                </Flex>
              </Option>
            ))}
        </Select>
        <Input
          type="text"
          style={{
            border: "1.25px solid",
            borderColor: "rgb(182, 180, 180)",
            borderRadius: 5,
            fontFamily: "Arial, Helvetica, sans-serif",
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            fontSize: 14,
            letterSpacing: "0.03em",
            height: 32,
            //minWidth: 158,
            width: "60%",
          }}
          onChange={(e) => {
            checkPhoneValidity(e.target.value);
            setChanges({ ...changes, phone: e.target.value });
            createValue(e.target.value, "phone");
          }}
          defaultValue={value}
          disabled={value ? true : false}
        />
        {required === "Y" && (
          <p style={{ color: "grey", marginTop: 8 }}>
            <WarningOutlined />
          </p>
        )}
      </Flex>
      {!validPhone && (
        <p
          style={{
            marginTop: "0.5rem",
            marginLeft: "0.5rem",
            fontStyle: "italic",
            color: "red",
          }}
        >
          {commonTitles["CO|1|INVLDPHONE"]}
        </p>
      )}
    </Flex>
  );
};

export default PhoneType;
