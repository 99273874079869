import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../../../../store/useStores";
import { convertDateFormat } from "../../../../../../ultimateTable/helpers/converters";
import { WarningOutlined } from "@ant-design/icons";
import Flex from "../../../../../../../helpers/components/Flex";
import CustomDatePicker from "../../../../../../../helpers/components/CustomDatepicker";

const OnlyDateType = ({ id, required, addChange, autoCompletedFields }) => {
  const dateFormat = useAuthStore((state) => state.dateFormat);
  const dateFormatToUse = convertDateFormat(dateFormat);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let value = "";
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    value = autoCompletedFields[id];
  }

  return (
    <Flex flexDirection={"row"}>
      <CustomDatePicker
        dateFormatToUse={dateFormatToUse}
        value={value?.from}
        id={id}
        config={"new"}
        isDisabled={value?.from ? true : false}
        commonTitles={commonTitles}
        addChange={addChange}
        showTime={false}
      />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 8, marginLeft: 15 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default OnlyDateType;
