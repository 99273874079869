import Flex from "../../../../../helpers/components/Flex";

const RenderAlert = ({ value }) => {
  return (
    <Flex flexDirection={"column"}>
      <p
        style={{
          color: value?.color?.[1] === "F" && value?.color?.[0],
        }}
      >
        {value?.value ?? ""}
      </p>
    </Flex>
  );
};

export default RenderAlert;
