import "./userRoles.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { UserRolesModal } from "./UserRolesModal";
import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import { UserRolesRightsModal } from "./UserRolesRightsModal";
import { useState } from "react";
import { useAuthStore } from "../../../store/useStores";
import axios from "axios";

const UserRolesBody = ({
  data,
  titles,
  headers,
  showModal,
  modalVisible,
  modalData,
  handleOk,
  handleCancel,
  handleDelete,
  fetchDataAgain,
}) => {
  const [userRoleRightsModalVisible, setUserRoleRightsModalVisible] =
    useState(false);
  const [userID, setUserID] = useState(null);
  const baseURL = useAuthStore((state) => state.baseURL);

  const showUserRoleRights = (id) => {
    setUserID(id);
    setUserRoleRightsModalVisible(true);
  };

  const onSave = async (userRoleRights) => {
    const url = `${baseURL}/User_role_modules_api/save`;

    let modules = {};
    userRoleRights.forEach((item) => {
      modules = {
        ...modules,
        [item.M_ID]: { V: item.V, I: item.I, U: item.U, D: item.D },
      };
    });

    try {
      const response = await axios.post(
        url,
        { id: userID, modules: modules },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("User_role_modules_api/save: response: ", response.data);

      if (response.data.STATUS === "Success") {
        fetchDataAgain();
        setUserRoleRightsModalVisible(false);
      } else {
        message(response.data.MESSAGE, [3]);
      }
    } catch (err) {}
  };

  const onCancel = () => {
    setUserRoleRightsModalVisible(false);
  };

  return (
    <TableBody>
      {data.map((item) => (
        <TableRow key={item.UR_ID}>
          <TableCell width={"5%"}>
            <Tooltip placement="right" title={titles["MO|9|EDITROW"]}>
              <EditOutlined
                onClick={() => showModal(item, "edit")}
                style={{
                  fontSize: "18px",
                  color: "rgb(8, 8, 46)",
                }}
              />
            </Tooltip>
            {modalVisible && (
              <UserRolesModal
                key={`${item.UR_ID}-modal`}
                visible={modalVisible}
                data={modalData}
                headers={headers}
                titles={titles}
                onSave={handleOk}
                onDelete={handleDelete}
                onCancel={handleCancel}
                config={"edit"}
              />
            )}
          </TableCell>
          <TableCell key={`${item.UR_ID}-module`}>{item.T_TITLE}</TableCell>
          <TableCell key={`${item.UR_ID}-button`} width="10%">
            <Button
              key={`${item.UR_ID}-button`}
              shape="round"
              className="dropdown"
              onClick={() => showUserRoleRights(item.UR_ID)}
              style={{ backgroundColor: "rgb(8, 8, 46)", color: "#fff" }}
            >
              {titles["MO|9|MODULEPRIV"]}
            </Button>
          </TableCell>
        </TableRow>
      ))}
      {userRoleRightsModalVisible && (
        <UserRolesRightsModal
          visible={userRoleRightsModalVisible}
          title={titles["MO|9|MODULEPRIV"]}
          onSave={onSave}
          onCancel={onCancel}
          id={userID}
        />
      )}
    </TableBody>
  );
};

export default UserRolesBody;
