const createDataToShow = (
  chartData,
  chartType,
  grouping,
  displayFunction,
  config
) => {
  const dataToShow = [];

  if (chartType === "Bar") {
    if (grouping === "Daily") {
      if (
        (displayFunction === "count" || displayFunction === "sum") &&
        Object.entries(chartData.daily ?? {}).length
      ) {
        const tempData = Object.entries(chartData.daily)
          .filter(([key, value]) => key !== "totalSum")
          .map(([key, value]) => {
            return { id: key, ...value };
          });

        dataToShow.push(...tempData);
      }

      if (
        (displayFunction === "pctCount" || displayFunction === "pctSum") &&
        Object.entries(chartData.daily ?? {}).length
      ) {
        let tempData = Object.entries(chartData.daily)
          .filter(([key, value]) => key !== "totalSum")
          .map(([key, value]) => {
            let newValue = {};
            Object.entries(value).forEach(([itemKey, itemValue]) => {
              if ((itemKey !== "sum") & (itemKey !== "id")) {
                if (chartData.daily?.totalSum !== 0) {
                  newValue = {
                    ...newValue,
                    [itemKey]:
                      Math.round(
                        (itemValue / chartData.daily?.totalSum) * 100
                      ) / 100,
                  };
                } else {
                  newValue = { ...newValue, [itemKey]: 0 };
                }
              }
            });
            return { id: key, ...newValue };
          });
        dataToShow.push(...tempData);
      }
    }

    if (grouping === "Monthly") {
      if (
        (displayFunction === "count" || displayFunction === "sum") &&
        Object.entries(chartData.monthly ?? {}).length
      ) {
        let tempData = Object.entries(chartData.monthly)
          .filter(([key, value]) => key !== "totalSum")
          .map(([key, value]) => {
            return { id: key, ...value };
          });
        dataToShow.push(...tempData);
      }

      if (
        (displayFunction === "pctCount" || displayFunction === "pctSum") &&
        Object.entries(chartData.monthly ?? {}).length
      ) {
        let tempData = Object.entries(chartData.monthly)
          .filter(([key, value]) => key !== "totalSum")
          .map(([key, value]) => {
            let newValue = {};
            Object.entries(value).forEach(([itemKey, itemValue]) => {
              if ((itemKey !== "sum") & (itemKey !== "id")) {
                if (chartData.monthly?.totalSum !== 0) {
                  newValue = {
                    ...newValue,
                    [itemKey]:
                      Math.round(
                        (itemValue / chartData.monthly?.totalSum) * 100
                      ) / 100,
                  };
                } else {
                  newValue = { ...newValue, [itemKey]: 0 };
                }
              }
            });
            return { id: key, ...newValue };
          });
        dataToShow.push(...tempData);
      }
    }

    if (grouping === "Yearly") {
      if (
        (displayFunction === "count" || displayFunction === "sum") &&
        Object.entries(chartData.yearly ?? {}).length
      ) {
        let tempData = Object.entries(chartData.yearly)
          .filter(([key, value]) => key !== "totalSum")
          .map(([key, value]) => {
            return { id: key, ...value };
          });
        dataToShow.push(...tempData);
      }

      if (
        (displayFunction === "pctCount" || displayFunction === "pctSum") &&
        Object.entries(chartData.yearly ?? {}).length
      ) {
        let tempData = Object.entries(chartData.yearly)
          .filter(([key, value]) => key !== "totalSum")
          .map(([key, value]) => {
            let newValue = {};
            Object.entries(value).forEach(([itemKey, itemValue]) => {
              if ((itemKey !== "sum") & (itemKey !== "id")) {
                if (chartData.yearly?.totalSum !== 0) {
                  newValue = {
                    ...newValue,
                    [itemKey]:
                      Math.round(
                        (itemValue / chartData.yearly?.totalSum) * 100
                      ) / 100,
                  };
                } else {
                  newValue = { ...newValue, [itemKey]: 0 };
                }
              }
            });
            return { id: key, ...newValue };
          });
        dataToShow.push(...tempData);
      }
    }
  }

  if (
    chartType === "Pie" &&
    config === "single" &&
    Object.keys(chartData).length
  ) {
    const generalKey = Object.keys(chartData)[0];
    if (displayFunction === "count" || displayFunction === "sum") {
      const keys = Object.keys(Object.values(chartData)[0]).filter(
        (item) => item !== "sum"
      );
      const tempValues = Object.entries(Object.values(chartData)[0]).filter(
        ([key]) => key !== "sum"
      );
      const values = tempValues.map((item) => item[1]);

      for (let i = 0; i < keys.length; i++) {
        dataToShow.push({
          id: keys[i],
          label: keys[i],
          value: values[i],
          generalKey: generalKey,
        });
      }
    }

    if (displayFunction === "pctCount" || displayFunction === "pctSum") {
      const sum = Object.entries(Object.values(chartData)[0]).filter(
        ([key]) => key === "sum"
      )[0][1];

      const keys = Object.keys(Object.values(chartData)[0]).filter(
        (item) => item !== "sum"
      );
      const tempValues = Object.entries(Object.values(chartData)[0]).filter(
        ([key]) => key !== "sum"
      );
      const values = tempValues.map((item) =>
        sum ? Math.round((item[1] / sum) * 100) / 100 : 0
      );

      for (let i = 0; i < keys.length; i++) {
        dataToShow.push({
          id: keys[i],
          label: keys[i],
          value: values[i],
          generalKey: generalKey,
        });
      }
    }
  }

  if (
    chartType === "Pie" &&
    config === "radial" &&
    Object.entries(chartData).length
  ) {
    if (displayFunction === "count" || displayFunction === "sum") {
      let tempData = [];
      Object.entries(chartData)
        .filter(([key, value]) => key !== "totalSum")
        .forEach(([key, value]) => {
          const id = key;

          const data = Object.entries(value)
            .filter(([valueKey]) => valueKey !== "sum")
            .map(([itemKey, itemValue]) => ({
              x: itemKey,
              y: itemValue,
            }));

          tempData.push({ id: id, data: data });
        });
      dataToShow.push(...tempData);
    }

    if (displayFunction === "pctCount" || displayFunction === "pctSum") {
      let tempData = [];
      Object.entries(chartData)
        .filter(([key, value]) => key !== "totalSum")
        .forEach(([key, value]) => {
          const id = key;

          const sum = Object.entries(value).filter(
            ([valueKey]) => valueKey === "sum"
          )[0][1];

          const data = Object.entries(value)
            .filter(([valueKey]) => valueKey !== "sum")
            .map(([itemKey, itemValue]) => ({
              x: itemKey,
              y: chartData?.totalSum
                ? Math.round((itemValue / chartData?.totalSum) * 100) / 100
                : 0,
            }));

          tempData.push({ id: id, data: data });
        });
      dataToShow.push(...tempData);
    }
  }

  if (chartType === "Line") {
    let lineChartData = {};

    if (grouping === "Daily") {
      lineChartData = chartData?.daily ?? {};
    }

    if (grouping === "Monthly") {
      lineChartData = chartData?.monthly ?? {};
    }

    if (grouping === "Yearly") {
      lineChartData = chartData.yearly ?? {};
    }

    if (
      (displayFunction === "count" || displayFunction === "sum") &&
      Object.keys(lineChartData).length
    ) {
      let keys = Object.entries(Object.values(lineChartData)[0]).filter(
        ([key]) => key !== "sum"
      );
      keys = keys.map((key) => key[0]);
      const tempData = [];
      for (let i = 0; i < keys.length; i++) {
        const data = [];
        Object.entries(lineChartData)
          .filter(([key, value]) => key !== "totalSum")
          .forEach(([key, value]) => {
            data.push({ x: key, y: value[keys[i]] });
          });
        tempData.push({ id: keys[i], data: data });
      }
      dataToShow.push(...tempData);
    }

    if (
      (displayFunction === "pctCount" || displayFunction === "pctSum") &&
      Object.keys(lineChartData).length
    ) {
      let keys = Object.entries(Object.values(lineChartData)[0]).filter(
        ([key]) => key !== "sum"
      );
      keys = keys.map((key) => key[0]);

      const tempData = [];
      for (let i = 0; i < keys.length; i++) {
        const data = [];
        Object.entries(lineChartData)
          .filter(([key, value]) => key !== "totalSum")
          .forEach(([key, value]) => {
            const sum = Object.entries(value).filter(
              ([valueKey]) => valueKey === "sum"
            )[0][1];

            data.push({
              x: key,
              y: lineChartData?.totalSum
                ? Math.round((value[keys[i]] / lineChartData?.totalSum) * 100) /
                  100
                : 0,
            });
          });
        tempData.push({ id: keys[i], data: data });
      }
      dataToShow.push(...tempData);
    }
  }

  return dataToShow;
};

export default createDataToShow;
