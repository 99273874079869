import axios from "axios";

const apiGetModuleFields = async (baseURL, id) => {
  const url = `${baseURL}/app_fields_api/list`;
  try {
    const response = await axios.post(
      url,
      { moduleid: id },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("apiGetModuleFields: response: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiGetModuleFields;
