import "./staticList.css";
import { Layout } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import StaticListSettings from "./StaticListSettings";
import StaticListHead from "./StaticListHead";
import StaticListBody from "./StaticListBody";
import Flex from "../../../../../../helpers/components/Flex";

const { Content } = Layout;

const StaticList = ({ addChange, data, config, fields }) => {
  const [bodyData, setBodyData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (Object.keys(data)?.length && config === "edit") {
      let temp = [];
      if (data.FIELDOPTIONS) {
        temp = data.FIELDOPTIONS.map((item, index) => {
          if (item?.FO_ORDER) {
            return item;
          } else {
            return { ...item, FO_ORDER: index };
          }
        });
      }

      setBodyData(temp);
    }
  }, []);

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const handleOk = (config, changes, index) => {
    if (config === "edit") {
      const tempData = bodyData.map((item) => {
        if (item.FO_ORDER === index) {
          return { ...item, ...changes };
        } else {
          return item;
        }
      });
      setBodyData(tempData);
      setModalVisible(false);
    }

    if (config === "new") {
      const temp = [
        ...bodyData,
        {
          ...changes,
          FO_ORDER: bodyData.length + 1,
          FO_BACKCOLOR: changes.FO_BACKCOLOR ?? "#ffffff",
        },
      ];
      setBodyData(temp);
      setNewItemModalVisible(false);
    }
  };

  useEffect(() => addChange("FIELDOPTIONS", bodyData), [bodyData]);

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
    }

    if (config === "delete") {
      setDeleteModalVisible(false);
    }
  };

  const handleDelete = (index) => {
    let temp = bodyData.filter((item, ind) => item.FO_ORDER !== index);
    temp = temp.map((item, index) => ({
      ...item,
      FO_ORDER: index + 1,
    }));
    setBodyData(temp);
    setDeleteModalVisible(false);
  };

  const onSort = (children) => {
    const newIndex = children.newIndex;
    const oldIndex = children.oldIndex;

    let tempBodyData = [];

    bodyData.forEach((item, index) => {
      if (newIndex > oldIndex) {
        if (index < oldIndex || index > newIndex) {
          tempBodyData[index] = item;
        } else if (index > oldIndex && index <= newIndex) {
          tempBodyData[index - 1] = item;
        } else if (index === oldIndex) {
          tempBodyData[newIndex] = item;
        }
      } else if (oldIndex === newIndex) {
        tempBodyData[index] = item;
      } else {
        if (index < newIndex || index > oldIndex) {
          tempBodyData[index] = item;
        } else if (index >= newIndex && index < oldIndex) {
          tempBodyData[index + 1] = item;
        } else if (index === oldIndex) {
          tempBodyData[newIndex] = item;
        }
      }
    });

    tempBodyData = tempBodyData.map((item, index) => ({
      ...item,
      FO_ORDER: index,
    }));

    setBodyData(tempBodyData);
  };

  const showDeleteModal = () => setDeleteModalVisible(true);

  return (
    <Flex
      flexDirection={"column"}
      style={{
        marginTop: config === "edit" ? "0.5rem" : "-2rem",
        marginBottom: "0.5rem",
      }}
    >
      <Layout>
        <StaticListSettings
          key={"new-item"}
          newItemModalVisible={newItemModalVisible}
          showModal={showModal}
          handleOk={handleOk}
          handleDelete={handleDelete}
          handleCancel={handleCancel}
          fields={fields}
        />
        {/* <Layout className="site-layout"> */}
        <Content
          style={{
            paddingBottom: 15,
            paddingLeft: 20,
            paddingRight: 20,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        >
          <TableContainer
            component={Paper}
            className="testing_table"
            style={{ maxHeight: "max-content" }}
          >
            <Table stickyHeader>
              <StaticListHead />
              {bodyData?.length ? (
                <StaticListBody
                  key={bodyData?.length}
                  data={bodyData}
                  modalVisible={modalVisible}
                  modalData={modalData}
                  deleteModalVisible={deleteModalVisible}
                  showModal={showModal}
                  showDeleteModal={showDeleteModal}
                  handleOk={handleOk}
                  handleDelete={handleDelete}
                  handleCancel={handleCancel}
                  onSort={onSort}
                  fields={fields}
                />
              ) : null}
            </Table>
          </TableContainer>
        </Content>
        {/* </Layout> */}
      </Layout>
    </Flex>
  );
};

export default StaticList;
