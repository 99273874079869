import "./modulesConfig.css";
import { Layout, Button, message } from "antd";
import { ModulesConfigModal } from "./modals/ModulesConfigModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import axios from "axios";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const { Content } = Layout;

const ModulesConfigSettings = ({
  headers,
  types,
  titles,
  fields,
  staticOptions,
  modules,
  projects,
  newItemModalVisible,
  showModal,
  updateData,
  handleCancel,
  handleDelete,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);

  const onSave = async (changes) => {
    const url = `${baseURL}/app_modules_api/save`;

    try {
      const response = await axios.post(
        url,
        {
          id: "",
          name: changes?.T_TITLE ?? "",
          singularname: changes?.TITLESINGULAR ?? "",
          abbr: changes?.M_ABBREVIATION ?? "",
          descr: changes?.DESCR ?? "",
          type: changes?.M_TYPE ?? "",
          projectspecific: changes?.M_PROJECTSPECIFIC ?? "",
          copyfrommodule: changes?.copyfrommodule ?? "",
          copydata: changes?.copydata ?? "N",
          copyfromproject: changes?.copyfromproject ?? "",
          lastmodified: "",
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("app_modules_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changesWithExtraInfo = {
          ["M_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          ["M_ID"]: response?.data?.ID,
          ...changes,
        };
        updateData("new", changesWithExtraInfo);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"} style={{ justifyContent: "flex-end" }}>
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {titles["MO|2|NEWROW"]}
          </Button>
          {newItemModalVisible && (
            <ModulesConfigModal
              key={"new-item"}
              visible={newItemModalVisible}
              headers={headers}
              types={types}
              titles={titles}
              fields={fields}
              projects={projects}
              staticOptions={staticOptions}
              modules={modules}
              onSave={onSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
            />
          )}
        </div>
      </Flex>
    </Content>
  );
};

export default ModulesConfigSettings;
