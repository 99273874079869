import { CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import Flex from "../../../../helpers/components/Flex";
import { Image } from "antd";
import htmlCodesRemover from "../../../ultimateTable/helpers/htmlCodesRemover";
import { useState } from "react";
import { useCommonTitlesStore } from "../../../../store/useStores";

const ClientTableDisplayDecider = (render, type, value, title) => {
  const [visible, setVisible] = useState(false);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  if (
    type === "24" /* type = Document link */ ||
    type === "25" /* type = Single image link */
  ) {
    const newValue = value?.map((item) => (
      <Flex key={item?.uid} direction={"horizontal"}>
        {value?.length > 1 && <p style={{ marginRight: 5 }}>{"•"}</p>}
        {/* <a
          className="link"
          href={`${item?.url}`}
          target="_blank"
          rel="noreferrer"
        >
          {item?.name}
          <br />
        </a> */}
        <div onClick={() => setVisible(false)}>
          <Image
            preview={{
              visible,
              onVisibleChange: (value) => {
                setVisible(value);
              },
              mask: (
                <Flex
                  flexDirection={"row"}
                  style={{ gap: "0.5rem", alignItems: "center" }}
                >
                  <EyeOutlined />
                  {/* <p>{commonTitles["CO|1|PREVIEW"]}</p> */}
                </Flex>
              ),
            }}
            width={50}
            src={item?.url}
          />
        </div>
      </Flex>
    ));
    return newValue;
  }

  if (type === "6" /* type = Static List */) {
    let newValue = value;
    if (typeof value === "object") {
      newValue = value?.view;
    }

    if (newValue === "Ναι") {
      return <CheckOutlined style={{ color: "green" }} />;
    }

    if (newValue === "Όχι") {
      return <CloseOutlined style={{ color: "red" }} />;
    }

    return htmlCodesRemover(newValue);
  }

  if (type === "1" /* type = Text */ || type === "27" /* type = Text Area */) {
    if (value && value.includes("&#39;")) {
      let splitValue = value
        .split("&#39;")
        .filter((item) => item !== "")
        .join("'");
      if (splitValue && splitValue.includes("&#10;")) {
        let newSplitValue = splitValue
          .split("&#10;")
          .filter((item) => item !== "");
        const newValue = newSplitValue.map((item) => (
          <p key={item}>
            {htmlCodesRemover(item)}
            <br />
          </p>
        ));
        return newValue;
      }
      return htmlCodesRemover(splitValue);
    } else if (value && value.includes("&#10;")) {
      let newSplitValue = value.split("&#10;").filter((item) => item !== "");
      const newValue = newSplitValue.map((item) => (
        <p key={item}>
          {htmlCodesRemover(item)}
          <br />
        </p>
      ));
      return newValue;
    } else {
      return htmlCodesRemover(value);
    }
  }

  return htmlCodesRemover(value);
};

export default ClientTableDisplayDecider;
