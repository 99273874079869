const createServerValue = (tags, precision) => {
  if (tags.length) {
    let serverValue = `F${precision}`;

    tags.forEach((tag) => {
      serverValue += tag;
    });

    if (serverValue.includes("#abs#")) {
      serverValue = serverValue.split("#abs#").join("abs");
    }

    if (serverValue.includes("#sqrt#")) {
      serverValue = serverValue.split("#sqrt#").join("sqrt");
    }

    if (serverValue.includes("#log#")) {
      serverValue = serverValue.split("#log#").join("log");
    }

    if (serverValue.includes("#exp#")) {
      serverValue = serverValue.split("#exp#").join("exp");
    }

    if (serverValue.includes("#M_PI#")) {
      serverValue = serverValue.split("#M_PI#").join("M_PI");
    }

    if (serverValue.includes("#pow#")) {
      serverValue = serverValue.split("#pow#").join("pow");
    }

    return serverValue;
  } else return "";
};

export default createServerValue;
