import { Modal, Button } from "antd";
import UltimateTable from "../../../UltimateTable";
import { useCommonTitlesStore } from "../../../../../store/useStores";

const DisplayModalInfo = ({
  visible,
  onCancel,
  modalTitle = "",
  moduleTitle,
  columnData = [],
  data = [],
  filters = {},
  staticOptions = {},
  foreignKeyValues = {},
  privileges = {},
  updateData,
  deleteData,
  insertData,
  updateFilters,
  updateOrderConfig,
  moduleID,
  changeDisplayedColumns,
  totalRowWidth,
  autoCompletedFields,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Modal
      title={modalTitle}
      open={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      width="80vw"
      style={{ marginTop: -20.5, height: "80vh" }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CLOSEBTN"]}
        </Button>,
      ]}
    >
      <UltimateTable
        key={columnData.length}
        title={moduleTitle ?? ""}
        columns={columnData}
        data={{ ...data }}
        filters={filters ?? {}}
        staticOptions={staticOptions ?? {}}
        foreignKeyValues={foreignKeyValues ?? {}}
        privileges={privileges ?? {}}
        updateData={updateData}
        deleteData={deleteData}
        insertData={insertData}
        updateFilters={updateFilters}
        updateOrderConfig={updateOrderConfig}
        moduleID={moduleID}
        config={"info"}
        changeDisplayedColumns={changeDisplayedColumns}
        totalRowWidth={totalRowWidth}
        autoCompletedFields={autoCompletedFields}
      />
    </Modal>
  );
};

export default DisplayModalInfo;
