const config = (set) => ({
  chartType: "BarChart",
  setChartType: (newType) => set(() => ({ chartType: newType })),

  grouping: "",
  setGrouping: (newGrouping) => set(() => ({ grouping: newGrouping })),

  dateField: "",
  setDateField: (newDateField) => set(() => ({ dateField: newDateField })),

  xAxisField: "",
  setXAxisField: (newXAxisField) => set(() => ({ xAxisField: newXAxisField })),

  groupField: "",
  setGroupField: (newGroupField) => set(() => ({ groupField: newGroupField })),

  groupFieldOptions: [],
  setGroupFieldOptions: (newGroupFieldOptions) =>
    set(() => ({ groupFieldOptions: newGroupFieldOptions })),

  dateFieldOptions: [],
  setDateFieldOptions: (newDateFieldOptions) =>
    set(() => ({ dateFieldOptions: newDateFieldOptions })),

  sumFieldOptions: [],
  setSumFieldOptions: (newSumFieldOptions) =>
    set(() => ({ sumFieldOptions: newSumFieldOptions })),

  displayFunction: null,
  setDisplayFunction: (newDisplayFunction) =>
    set(() => ({ displayFunction: newDisplayFunction })),

  sumField: null,
  setSumField: (newSumField) => set(() => ({ sumField: newSumField })),

  groupMode: "grouped",
  setGroupMode: (newGroupMode) => set(() => ({ groupMode: newGroupMode })),

  color: "nivo",
  setColor: (newColor) => set(() => ({ color: newColor })),

  startAngle: 0,
  setStartAngle: (newStartAngle) => set(() => ({ startAngle: newStartAngle })),

  endAngle: 360,
  setEndAngle: (newEndAngle) => set(() => ({ endAngle: newEndAngle })),

  innerRadius: 0,
  setInnerRadius: (newInnerRadius) =>
    set(() => ({ innerRadius: newInnerRadius })),

  interpolation: "natural",
  setInterpolation: (newInterpolation) =>
    set(() => ({ interpolation: newInterpolation })),

  layout: "vertical",
  setLayout: (newLayout) => set(() => ({ layout: newLayout })),

  lineGroupMode: true,
  setLineGroupMode: (newLineGroupMode) =>
    set(() => ({ lineGroupMode: newLineGroupMode })),

  chartSettingsVisible: false,
  setChartSettingsVisible: (newChartSettingsVisible) =>
    set(() => ({ chartSettingsVisible: newChartSettingsVisible })),

  yValuesUsed: false,
  setYValuesUsed: (newYValuesUsed) =>
    set(() => ({ yValuesUsed: newYValuesUsed })),

  showAverage: false,
  setShowAverage: (newShowAverage) =>
    set(() => ({ showAverage: newShowAverage })),

  is3D: true,
  setIs3D: (newIs3D) => set(() => ({ is3D: newIs3D })),

  radialDisplay: false,
  setRadialDisplay: (newRadialDisplay) =>
    set(() => ({ radialDisplay: newRadialDisplay })),

  showTrendline: false,
  setShowTrendline: (newShowTrendline) =>
    set(() => ({ showTrendline: newShowTrendline })),

  trendline: "linear",
  setTrendline: (newTrendline) => set(() => ({ trendline: newTrendline })),

  areaDisplay: false,
  setAreaDisplay: (newAreaDisplay) =>
    set(() => ({ areaDisplay: newAreaDisplay })),

  showConfigurator: true,
  setShowConfigurator: (newShowConfigurator) =>
    set(() => ({ showConfigurator: newShowConfigurator })),

  chartBase64: "",
  setChartBase64: (newChartBase64) =>
    set(() => ({ chartBase64: newChartBase64 })),

  chartColor9: [
    "#54bebe",
    "#76c8c8",
    "#98d1d1",
    "#badbdb",
    "#dedad2",
    "#e4bcad",
    "#df979e",
    "#d7658b",
    "#c80064",
  ],

  colorSet20: [
    "rgb(37,102,118)",
    "rgb(120,216,188)",
    "rgb(85,76,122)",
    "rgb(170,186,212)",
    "rgb(192,88,61)",
    "rgb(192,225,92)",
    "rgb(28,241,163)",
    "rgb(124,138,79)",
    "rgb(6,150,104)",
    "rgb(229,195,93)",
    "rgb(250,46,85)",
    "rgb(255,162,112)",
    "rgb(150,127,242)",
    "rgb(235,103,249)",
    "rgb(144,40,107)",
    "rgb(188,125,163)",
    "rgb(83,54,197)",
    "rgb(252,194,251)",
    "rgb(125,10,246)",
    "rgb(4,148,251)",
  ],

  colorSet40: [
    "rgb(12,192,170)",
    "rgb(47,75,78)",
    "rgb(194,220,184)",
    "rgb(93,153,170)",
    "rgb(52,9,103)",
    "rgb(115,127,201)",
    "rgb(233,201,250)",
    "rgb(186,113,234)",
    "rgb(188,227,51)",
    "rgb(151,37,84)",
    "rgb(189,131,110)",
    "rgb(65,0,2)",
    "rgb(68,242,112)",
    "rgb(148,49,5)",
    "rgb(241,97,95)",
    "rgb(124,138,79)",
    "rgb(225,198,55)",
    "rgb(255,0,135)",
    "rgb(223,138,29)",
    "rgb(22,123,43)",
  ],

  chartColorPalette: [
    "#19439c",
    "#285aa6",
    "#3871af",
    "#4788b9",
    "#569fc2",
    "#66b6cc",
    "#75cdd5",
    "#84e4df",
    "#93fbe8",
    "#87f2d2",
    "#74e2b5",
    "#62d399",
    "#50c37c",
    "#3db460",
    "#2ba443",
    "#189526",
    "#06850a",
    "#158a12",
    "#349a2e",
    "#53a949",
    "#72b965",
    "#92c980",
    "#b1d89b",
    "#d0e8b7",
    "#eff7d2",
    "#fff9d2",
    "#ffeeb7",
    "#ffe39b",
    "#ffd880",
    "#ffcd65",
    "#ffc249",
    "#ffb72e",
    "#ffac12",
    "#ff9e00",
    "#ff8a00",
    "#ff7600",
    "#ff6200",
    "#ff4d00",
    "#ff3900",
    "#ff2500",
    "#ff1100",
    "#fc0003",
    "#ed0012",
    "#dd0022",
    "#ce0032",
    "#be0041",
    "#af0051",
    "#9f0061",
    "#900070",
    "#800080",
  ],
});

const ChartStore = {
  config,
  shouldLog: true,
};

export default ChartStore;
