import Flex from "../../../../../../helpers/components/Flex";
import { Input } from "antd";

const NewFieldInfo = ({ changes, addChange, fields }) => {
  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[243].TITLE}:
      </p>
      <Input
        style={{
          width: 300,
          marginLeft: 15,
          marginTop: -5,
          marginBottom: "1rem",
          borderRadius: 5,
        }}
        onChange={(e) => addChange("INFO", e.target.value)}
        defaultValue={changes?.INFO ?? ""}
      />
    </Flex>
  );
};

export default NewFieldInfo;
