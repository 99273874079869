import Flex from "../../../../../../../../helpers/components/Flex";
import { Input } from "antd";
import { useState, useEffect, useRef } from "react";
import { tagInputStyle } from "../../styles/TagItemStyles";
import validateTag from "../../helpers/functions/validateTag";
import InvalidInput from "../../helpers/components/InvalidInput";

const InputElement = ({
  tags,
  updateTags,
  showInputMessage,
  updateShowInputMessage,
  inputVisible,
  updateInputVisible,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);

    const isValid = validateTag(e.target.value);

    if (isValid || !e.target.value) {
      updateShowInputMessage(false);
    } else {
      updateShowInputMessage(true);
    }
  };

  const handleInputConfirm = (e) => {
    const isValid = validateTag(e.target.value);
    if (isValid || !e.target.value) {
      if (inputValue && !tags.includes(inputValue)) {
        if (tags.length) {
          if (
            !tags[tags.length - 1].includes("[") &&
            !tags[tags.length - 1].includes("#")
          ) {
            let newTags = [...tags];

            if (newTags[tags.length - 1] !== ",") {
              newTags[tags.length - 1] = tags[tags.length - 1] + inputValue;
            } else {
              newTags.push(inputValue);
            }

            updateTags(newTags);
          } else {
            updateTags([...tags, inputValue]);
          }
        } else {
          updateTags([inputValue]);
        }
      }
      updateInputVisible(false);
      setInputValue("");
    }
  };

  return (
    <Flex flexDirection={"column"} style={{ width: "100%" }}>
      <Input
        ref={inputRef}
        type="text"
        size="small"
        style={{
          ...tagInputStyle,
          marginTop: tags.length === 0 ? -15 : -10,
          marginBottom: 10,
        }}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
      />
      {showInputMessage && <InvalidInput />}
    </Flex>
  );
};

export default InputElement;
