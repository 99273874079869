import "./alertConfig.css";
import { Layout, Card, Spin } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import AlertConfigSettings from "./AlertConfigSettings";
import AlertConfigHead from "./AlertConfigHead";
import AlertConfigBody from "./AlertConfigBody";
import apiGetAlertsList from "../../../api/endpoints/apiGetAlertsList";
import { useAuthStore } from "../../../store/useStores";
import Navigation from "../../navigation/Navigation";
import Flex from "../../../helpers/components/Flex";
import filterDataOnSearch from "../helpers/filterDataOnSearch";
import { DeleteModal } from "./deleteModals";
import axios from "axios";
import {
  useCommonTitlesStore,
  useModTitlesStore,
} from "../../../store/useStores";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import BackgroundWrapper from "../../../helpers/components/BackgroundWrapper";
import TableWrapper from "../../../helpers/components/TableWrapper";
import AdministrationBanner from "../administrationBanner/AdministrationBanner";

const { Content } = Layout;

const AlertConfig = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [data, setData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [modules, setModules] = useState({});
  const [titles, setTitles] = useState({});
  const [fields, setFields] = useState({});
  const [staticOptions, setStaticOptions] = useState({});

  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({});

  const [filtersChanged, setFiltersChanged] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const serverData = await apiGetAlertsList(baseURL);

    const tempAlerts =
      serverData?.alerts?.map((item) => {
        if (item?.INFOFIELDTITLES?.includes("<br>")) {
          return {
            ...item,
            INFOFIELDTITLES: item?.INFOFIELDTITLES?.split("<br>")?.join(", "),
          };
        } else return item;
      }) ?? [];

    setData(tempAlerts);
    setBodyData(tempAlerts);
    setModules(serverData?.modules ?? {});
    setTitles(serverData?.moduletitles ?? {});
    setFields(serverData?.fields ?? {});
    setStaticOptions(serverData?.staticoptions ?? {});
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [projectID, language, filtersChanged]);

  const reverseFiltersChanged = () => {
    setFiltersChanged(!filtersChanged);
  };

  const headers = Object.keys(titles)
    ? [
        { id: "F_MODULEID", title: titles["FD|207|TITLE"] },
        { id: "A_TRIGGER_FIELDID", title: titles["FD|202|TITLE"] },
        { id: "A_WARNING_BEFORE", title: titles["FD|204|TITLE"] },
        { id: "A_RAISED_AFTER", title: titles["FD|205|TITLE"] },
        { id: "INFORMATIONFIELDS", title: titles["FD|208|TITLE"] },
        { id: "ALERTTEXT", title: titles["FD|206|TITLE"] },
        { id: "A_ACTIVE", title: titles["FD|209|TITLE"] },
        { id: "A_FILTER", title: titles["FD|231|TITLE"] },
      ]
    : [];

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const updateData = (config, changes, id) => {
    if (config === "edit") {
      setModalVisible(false);
      const tempData = bodyData.map((item) => {
        if (item.A_ID === id) {
          return { ...item, ...changes };
        } else {
          return item;
        }
      });
      setBodyData(tempData);
      setData(tempData);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
      setBodyData([...bodyData, changes]);
      setData([...data, changes]);
    }
  };

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
    }
  };

  const showDeleteModal = (id) => {
    setDeleteModalVisible(true);
    setItemToDelete(id);
  };
  const cancelDeleteModal = () => setDeleteModalVisible(false);

  const handleDelete = async (id) => {
    const url = `${baseURL}/App_alerts_api/delete`;

    try {
      const response = await axios.post(
        url,
        {
          id: id,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_alerts_api/delete: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        setBodyData(bodyData.filter((item) => item.A_ID !== id));
        setDeleteModalVisible(false);
        setModalVisible(false);
        setCoreChange(!coreChange);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateSearchValue = (change) => {
    const tempData = filterDataOnSearch(data, change, headers);

    if (change) {
      setBodyData(tempData);
    } else {
      setBodyData(data);
    }
  };

  return (
    <Flex flexDirection={"column"} key={data.length}>
      <Navigation />
      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.alertConfig}`}
          updateSearchValue={updateSearchValue}
          showSearch={true}
        />
        {headers.length && (
          <TableWrapper>
            <Layout
              style={{
                borderRadius: 10,
                overflow: "hidden",
                height: "max-content",
                width: "100%",
              }}
            >
              <AlertConfigSettings
                key={"new-item"}
                headers={headers}
                titles={titles}
                modules={modules}
                newItemModalVisible={newItemModalVisible}
                showModal={showModal}
                updateData={updateData}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
                fields={fields}
              />
              <Layout className="site-layout">
                <Content
                  style={{
                    paddingBottom: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                    minHeight: "max-content",
                    backgroundColor: "transparent",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    style={{ maxHeight: "70vh" }}
                    className="testing_table"
                  >
                    {isLoading ? (
                      <Card
                        style={{
                          borderRadius: "10px",
                          marginBottom: "10px",
                          width: "98.5vw",
                          display: "flex",
                          justifyContent: "center",
                          height: "85vh",
                        }}
                      >
                        <Spin size="large" style={{ marginTop: "15rem" }} />
                      </Card>
                    ) : (
                      <Table stickyHeader>
                        <AlertConfigHead headers={headers} />
                        <AlertConfigBody
                          key={`body`}
                          data={bodyData}
                          headers={headers}
                          titles={titles}
                          modules={modules}
                          modalVisible={modalVisible}
                          modalData={modalData}
                          showModal={showModal}
                          updateData={updateData}
                          handleDelete={handleDelete}
                          handleCancel={handleCancel}
                          fields={fields}
                          showDeleteModal={showDeleteModal}
                          reverseFiltersChanged={reverseFiltersChanged}
                        />
                      </Table>
                    )}
                  </TableContainer>
                </Content>
              </Layout>
            </Layout>
          </TableWrapper>
        )}
      </BackgroundWrapper>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          selectedItems={[itemToDelete]}
          onDelete={handleDelete}
          onCancel={cancelDeleteModal}
        />
      )}
    </Flex>
  );
};

export default AlertConfig;
