import Logo from "./logo/Logo";
import Alerts from "./alerts/Alerts";
import Settings from "./settings/Settings";
import Projects from "./projects/Projects";
import Flex from "../../helpers/components/Flex";
import ProjectLogo from "./projectLogo/ProjectLogo";
import NavigationWrapper from "./navigationWrapper/NavigationWrapper";
import Menu from "./menu/Menu";
import User from "./user/User";

const Navigation = ({ paramMenu = [], paramMenuTitle = "" }) => {
  return (
    <NavigationWrapper>
      <Flex
        flexDirection={"row"}
        style={{
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: " 1rem",
          zIndex: 5,
          marginLeft: "-2rem",
        }}
      >
        <Logo />
        <Menu paramMenu={paramMenu} paramMenuTitle={paramMenuTitle} />
      </Flex>
      <Flex
        style={{
          width: "max-content",
          justifyContent: "flex-end",
        }}
      >
        <ProjectLogo />
        <Projects />
        <Alerts />
        <User />
        <Settings />
      </Flex>
    </NavigationWrapper>
  );
};

export default Navigation;
