import StopIcon from "@mui/icons-material/Stop";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AddIcon from "@mui/icons-material/Add";

export const serverData = {
  "0-0-0-1": {
    auditNumber: "1",
    start: "11/01/22",
    end: "20/01/2022",
    legislation: "national",
    participants: [
      {
        name: "Νικόλαος",
        surname: "Αθανασίου",
        title: "Συντονιστής Ασφαλείας",
        from: "11/01/2022",
        to: "20/01/2022",
        company: "Εταιρεία Α.Ε",
        position: "Επιθεωρούμενος",
      },
    ],
  },
  "0-0-0-2": {
    auditNumber: "2",
    start: "21/01/22",
    end: "30/01/2022",
    legislation: "national",
    participants: [
      {
        name: "Νικόλαος",
        surname: "Αθανασίου",
        title: "Συντονιστής Ασφαλείας",
        from: "21/01/2022",
        to: "30/01/2022",
        company: "Εταιρεία Α.Ε",
        position: "Επιθεωρούμενος",
      },
      {
        name: "Μιχαήλ",
        surname: "Κωνσταντίνου",
        title: "Συντονιστής Ασφαλείας",
        from: "14/03/2022",
        to: "21/03/2022",
        company: "Εταιρεία 2 Α.Ε",
        position: "Επιθεωρητής",
      },
    ],
  },
  "0-0-1-1": {
    auditNumber: "1",
    start: "14/03/22",
    end: "21/03/2022",
    legislation: "national",
    auditee: { text: "Δημήτρης Γεωργίου", checked: ["Co-Auditor", "Observer"] },
    participants: [
      {
        name: "Μιχαήλ",
        surname: "Κωνσταντίνου",
        title: "Συντονιστής Ασφαλείας",
        from: "14/03/2022",
        to: "21/03/2022",
        company: "Εταιρεία 2 Α.Ε",
        position: "Επιθεωρητής",
      },
    ],
  },
};

export const serverTreeData = [
  {
    title: "Έργο 1",
    key: "0-0",
    icon: <StopIcon style={{ color: "#186A8E" }} />,
    children: [
      {
        title: "Τμήμα 1",
        key: "0-0-0",
        icon: <StopIcon style={{ color: "#f3dfa3" }} />,
        children: [
          {
            title: "1 [11 Ιαν - 20 Ιαν]",
            key: "0-0-0-1",
            icon: <AssignmentIcon style={{ color: "#98C99C" }} />,
          },
          {
            title: "2 [21 Ιαν - 30 Ιαν]",
            key: "0-0-0-2",
            icon: <AssignmentIcon style={{ color: "#98C99C" }} />,
          },
        ],
      },
      {
        title: "Τμήμα 2",
        key: "0-0-1",
        icon: <StopIcon style={{ color: "#f3dfa3" }} />,
        children: [
          {
            title: "1 [11 Ιαν - 20 Ιαν]",
            key: "0-0-1-1",
            icon: <AssignmentIcon style={{ color: "#98C99C" }} />,
          },
        ],
      },
    ],
  },
];

export const qHeaders = [
  { type: "text", title: "Πεδίο", id: "description", width: "20%" },
  { type: "checkbox", title: "Τηρείται", id: "compliance", width: "5%" },
  { type: "text", title: "Ευρήματα", id: "findings", width: "20%" },
  { type: "text", title: "Πρόοδος", id: "progress", width: "5%" },
  { type: "text", title: "Αξιολόγηση", id: "rate", width: "5%" },
  { type: "text", title: "Υπεύθυνος", id: "responsible", width: "10%" },
  { type: "text", title: "Προθεσμία", id: "deadline", width: "5%" },
  { type: "text", title: "Ολοκλήρωση έως", id: "completionDate", width: "5%" },
  { type: "text", title: "Σχόλια", id: "comments", width: "10%" },
  { type: "text", title: "Σχετικά", id: "references", width: "10%" },
  { type: "text", title: "Συν.", id: "att", width: "5%" },
];

export const QHeaders = [
  {
    F_ALIGN: "Left",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1000",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "27",
    F_UNIQUE: "",
    F_WIDTH: "1000",
    T_TITLE: "Πεδίο",
  },
  {
    F_ALIGN: "Center",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1001",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "checkbox",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "6",
    F_UNIQUE: "",
    F_WIDTH: "50",
    T_TITLE: "Τηρείται",
  },
  {
    F_ALIGN: "Left",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1002",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "27",
    F_UNIQUE: "",
    F_WIDTH: "1000",
    T_TITLE: "Ευρήματα",
  },
  {
    F_ALIGN: "Center",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1003",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "progress",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "6",
    F_UNIQUE: "",
    F_WIDTH: "100",
    T_TITLE: "Πρόοδος",
  },
  {
    F_ALIGN: "Center",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1004",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "6",
    F_UNIQUE: "",
    F_WIDTH: "100",
    T_TITLE: "Αξιολόγηση",
  },
  {
    F_ALIGN: "Left",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1005",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "1",
    F_UNIQUE: "",
    F_WIDTH: "300",
    T_TITLE: "Υπεύθυνος",
  },
  {
    F_ALIGN: "Center",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1006",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "6",
    F_UNIQUE: "",
    F_WIDTH: "150",
    T_TITLE: "Προθεσμία",
  },
  {
    F_ALIGN: "Center",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1007",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "4",
    F_UNIQUE: "",
    F_WIDTH: "200",
    T_TITLE: "Ολοκλήρωση έως",
  },
  {
    F_ALIGN: "Left",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1008",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "27",
    F_UNIQUE: "",
    F_WIDTH: "500",
    T_TITLE: "Σχόλια",
  },
  {
    F_ALIGN: "Left",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1009",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "27",
    F_UNIQUE: "",
    F_WIDTH: "500",
    T_TITLE: "Σχετικά",
  },
  {
    F_ALIGN: "Left",
    F_CHILDFIELDID: "0",
    F_EDITWIDTH: "300",
    F_FORMAT: "1000",
    F_ID: "1010",
    F_LIST: "Y",
    F_ORDER: "5",
    F_PARENTFIELDID: "0",
    F_RENDER: "",
    F_REPORTWIDTH: "0",
    F_REQUIRED: "Y",
    F_TOTALS: "N",
    F_TYPE: "27",
    F_UNIQUE: "",
    F_WIDTH: "100",
    T_TITLE: "Συν.",
  },
];

export const QData = {
  1: {
    1000: "Ο εργοδότης λαμβάνει γνώση ενυπογράφως των υποδείξεων του Ι.Ε.[Ν. 3850/2010]",
    1001: "Y",
    "1001-server": "1999",
    1002: "Tο Βιβλίο Γραπτών Υποδείξεων της Ιατρού Εργασίας είναι ενημερωμένο και υπογεγραμμένο από τον εργοταξιάρχη.",
    1003: "Τηρείται",
    "1003-server": "2004",
  },
  2: {
    1000: "Καταχώρηση των γραπτών υποδείξεων από τον Ι.Ε. στο Β.Υ.Ι.Ε. [Ν. 3850/2010]",
    1001: "Ν",
    "1001-server": "1999",
    1002: "Εύρημα 1",
    1003: "Ανοιχτό",
    "1003-server": "2006",
  },
  3: {
    1000: "Καταχώρηση των γραπτών υποδείξεων από τον Ι.Ε. στο Β.Υ.Ι.Ε. [Ν. 3850/2010]",
    1001: "Ν",
    "1001-server": "1999",
    1002: "Εύρημα 1",
    1003: "Ανοιχτό",
    "1003-server": "2006",
  },
};

export const staticOptions = {
  1001: {
    1998: { TITLE: "Y", BC: "#ffffff", ORDER: "1" },
    1999: { TITLE: "N", BC: "#ffffff", ORDER: "2" },
  },
  1003: {
    2000: { TITLE: "Καμία ενέργεια", BC: "#C0C0C0", ORDER: "1" },
    2001: { TITLE: "Σε εξέλιξη", BC: "#FFFF00", ORDER: "2" },
    2002: { TITLE: "Ολοκληρώθηκε", BC: "#50B000", ORDER: "3" },
    2003: { TITLE: "Παρωχημένο", BC: "#cc9966", ORDER: "4" },
    2004: { TITLE: "Τηρείται", BC: "#FFFFFF", ORDER: "5" },
    2005: { TITLE: "Καλή πρακτική", BC: "#80FF80", ORDER: "6" },
    2006: { TITLE: "Ανοιχτό", BC: "#FF0000", ORDER: "7" },
  },
  1004: {
    2007: { TITLE: "Υψηλή", BC: "#ffffff", ORDER: "1" },
    2008: { TITLE: "Μέτρια", BC: "#ffffff", ORDER: "2" },
    2009: { TITLE: "Χαμηλή", BC: "#ffffff", ORDER: "3" },
  },
  1006: {
    2010: { TITLE: "Άμεσα", BC: "#ffffff", ORDER: "1" },
    2011: { TITLE: "5", BC: "#ffffff", ORDER: "2" },
    2012: { TITLE: "10", BC: "#ffffff", ORDER: "3" },
    2013: { TITLE: "15", BC: "#ffffff", ORDER: "4" },
    2014: { TITLE: "20", BC: "#ffffff", ORDER: "5" },
    2015: { TITLE: "25", BC: "#ffffff", ORDER: "6" },
    2016: { TITLE: "30", BC: "#ffffff", ORDER: "7" },
    2017: { TITLE: "35", BC: "#ffffff", ORDER: "8" },
    2018: { TITLE: "40", BC: "#ffffff", ORDER: "9" },
    2019: { TITLE: "Ολοκληρώθηκε", BC: "#ffffff", ORDER: "10" },
    2020: { TITLE: "Δ/Α", BC: "#ffffff", ORDER: "11" },
  },
};

export const qData = {
  Q1: {
    section: "Γενικές Απαιτήσεις Α&Υ",
    subsections: [
      {
        name: "Θέματα Ιατρού Εργασίας",
        fields: [
          {
            description:
              "Ο εργοδότης λαμβάνει γνώση ενυπογράφως των υποδείξεων του Ι.Ε.[Ν. 3850/2010]",
            compliance: "Y",
            findings:
              "Tο Βιβλίο Γραπτών Υποδείξεων της Ιατρού Εργασίας είναι ενημερωμένο και υπογεγραμμένο από τον εργοταξιάρχη.",
            progress: "Τηρείται",
          },
          {
            description:
              "Καταχώρηση των γραπτών υποδείξεων από τον Ι.Ε. στο Β.Υ.Ι.Ε. [Ν. 3850/2010]",
            compliance: "Ν",
            findings: "Εύρημα 1",
            progress: "Δεν τηρείται",
          },
          {
            description:
              "Καταχώρηση των γραπτών υποδείξεων από τον Ι.Ε. στο Β.Υ.Ι.Ε. [Ν. 3850/2010]",
            compliance: "Ν",
            findings: "Εύρημα 1",
            progress: "Δεν τηρείται",
          },
        ],
      },
    ],
  },
  Q2: {
    section: "Mηχανήματα Έργων/Οχήματα",
    subsections: [
      {
        name: "Άδειες - Έγγραφα",
        fields: [
          {
            description:
              "Τα Μ.Ε. να συνοδεύονται με τα αποδεικτικά στοιχεία ασφάλισης. [Ν. 489/1976]",
            compliance: "Y",
            findings:
              "Eλέγχθηκε δειγματοληπτικά προσκομίσθηκε η ασφάλιση των φορτηγών Δ.Χ. ΕΚΑ 7407 / ΕΚΑ 7434 / ΕΚΑ 7437 / ΕΚΑ 7704 και των Μ.Ε. 127501 - φορτωτής / ",
            progress: "Τηρείται",
          },
        ],
      },
      {
        name: "Χειριστές - Οδηγοί",
        fields: [
          {
            description:
              "Άδεια χειριστή Μ.Ε. & βεβαίωση αναγγελίας βοηθού χειριστή Μ.Ε. [Π.Δ. 113/2012]",
            compliance: "Y",
            findings:
              "Zητήθηκαν και προσκομίσθηκαν οι άδειες χειριστή Μ.Ε. των Η.Ντουράκη / Θ.Μπαλαγιάννη / Ε.Ράγγου / Ρ.Κεχαγιά / Α.Μουδούρη",
            progress: "Τηρείται",
          },
        ],
      },
    ],
  },
};

export const headers = [
  { type: "text", title: "Όνομα", id: "name" },
  { type: "text", title: "Επώνυμο", id: "surname" },
  { type: "text", title: "Τίτλος", id: "title" },
  { type: "date", title: "Από", id: "from" },
  { type: "date", title: "Έως", id: "to" },
  { type: "text", title: "Εταιρεία", id: "company" },
  { type: "text", title: "Θέση", id: "position" },
];

export const requirements = {
  headers: [
    { type: "text", title: "Έγγραφο", id: "file" },
    { type: "attachement", title: "Συνημμένο", id: "attachement" },
  ],
  childrenHeaders: [
    { type: "text", title: "Περιγραφή", id: "description" },
    { type: "text", title: "Άρθρο", id: "article" },
    { type: "text", title: "Τμήμα", id: "section" },
    { type: "text", title: "Υποτμήμα", id: "subsection" },
  ],
  data: {
    r1: {
      file: "Υ.Α. 16440/93",
      attachement: "",
      children: {
        0: {
          description:
            "Οι εγχωρίως κατασκευαζόμενα και εισαγόμενα ικριώματα πρέπει να συνοδεύονται από Οδηγίες συναρμολόγησης του κατασκευαστή, πρότυπα κατασκευής και μελέτη αντοχής φορτίων.",
          article: "αρ. 3&5",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        1: {
          description:
            "Για μεταλλική σκαλωσιά υπάρχει βεβαίωση εξέτασης τύπου και κατάλληλη σήμανση των στοιχείων της.",
          article: "αρ. 6",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        2: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        3: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        4: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        5: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
      },
    },
    r2: {
      file: "Ν. 1396/1983 ",
      attachement: "",
      children: {
        0: {
          description:
            "Ο κύριος του έργου εάν δεν ανατεθεί όλο το έργο σε έναν εργολάβο αναλαμβάνει τα μέτρα Α&Υ του έργου.",
          article: "αρ. 4",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        1: {
          description:
            "Ο εργολάβος και ο υπεργολάβος συνεργάζονται σε ό,τι αφορά τα μέτρα ασφαλείας του έργου.",
          article: "αρ. 6",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        2: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        3: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        4: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        5: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        6: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
      },
    },
    r3: {
      file: "Ν. 1450/1988 ",
      attachement: "",
      children: {
        0: {
          description:
            "Οι εγχωρίως κατασκευαζόμενα και εισαγόμενα ικριώματα πρέπει να συνοδεύονται από Οδηγίες συναρμολόγησης του κατασκευαστή, πρότυπα κατασκευής και μελέτη αντοχής φορτίων.",
          article: "αρ. 3&5",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        1: {
          description:
            "Για μεταλλική σκαλωσιά υπάρχει βεβαίωση εξέτασης τύπου και κατάλληλη σήμανση των στοιχείων της.",
          article: "αρ. 6",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        2: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        3: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        4: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        5: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
      },
    },
    r4: {
      file: "Ν. 1577/1998 ",
      attachement: "",
      children: {
        0: {
          description:
            "Ο κύριος του έργου εάν δεν ανατεθεί όλο το έργο σε έναν εργολάβο αναλαμβάνει τα μέτρα Α&Υ του έργου.",
          article: "αρ. 4",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        1: {
          description:
            "Ο εργολάβος και ο υπεργολάβος συνεργάζονται σε ό,τι αφορά τα μέτρα ασφαλείας του έργου.",
          article: "αρ. 6",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        2: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        3: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        4: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        5: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        6: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
      },
    },
    r5: {
      file: "Ν. 1201/1997 ",
      attachement: "",
      children: {
        0: {
          description:
            "Οι εγχωρίως κατασκευαζόμενα και εισαγόμενα ικριώματα πρέπει να συνοδεύονται από Οδηγίες συναρμολόγησης του κατασκευαστή, πρότυπα κατασκευής και μελέτη αντοχής φορτίων.",
          article: "αρ. 3&5",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        1: {
          description:
            "Για μεταλλική σκαλωσιά υπάρχει βεβαίωση εξέτασης τύπου και κατάλληλη σήμανση των στοιχείων της.",
          article: "αρ. 6",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        2: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        3: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        4: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        5: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
      },
    },
    r6: {
      file: "Ν. 1187/1985 ",
      attachement: "",
      children: {
        0: {
          description:
            "Ο κύριος του έργου εάν δεν ανατεθεί όλο το έργο σε έναν εργολάβο αναλαμβάνει τα μέτρα Α&Υ του έργου.",
          article: "αρ. 4",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        1: {
          description:
            "Ο εργολάβος και ο υπεργολάβος συνεργάζονται σε ό,τι αφορά τα μέτρα ασφαλείας του έργου.",
          article: "αρ. 6",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        2: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        3: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        4: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        5: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        6: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
      },
    },
    r7: {
      file: "Ν. 1785/1999 ",
      attachement: "",
      children: {
        0: {
          description:
            "Οι εγχωρίως κατασκευαζόμενα και εισαγόμενα ικριώματα πρέπει να συνοδεύονται από Οδηγίες συναρμολόγησης του κατασκευαστή, πρότυπα κατασκευής και μελέτη αντοχής φορτίων.",
          article: "αρ. 3&5",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        1: {
          description:
            "Για μεταλλική σκαλωσιά υπάρχει βεβαίωση εξέτασης τύπου και κατάλληλη σήμανση των στοιχείων της.",
          article: "αρ. 6",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        2: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        3: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        4: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        5: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
      },
    },
    r8: {
      file: "Ν. 1574/1989 ",
      attachement: "",
      children: {
        0: {
          description:
            "Ο κύριος του έργου εάν δεν ανατεθεί όλο το έργο σε έναν εργολάβο αναλαμβάνει τα μέτρα Α&Υ του έργου.",
          article: "αρ. 4",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        1: {
          description:
            "Ο εργολάβος και ο υπεργολάβος συνεργάζονται σε ό,τι αφορά τα μέτρα ασφαλείας του έργου.",
          article: "αρ. 6",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        2: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        3: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        4: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        5: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
        6: {
          description: "Ο μελετητής έχει συντάξει μελέτη μέτρων ασφαλείας. ",
          article: "αρ. 7",
          section: "Διαχείριση Α&Υ Τεχνικών Έργων",
          subsection: "Γενικές Απαιτήσεις",
        },
      },
    },
    r9: {
      file: "Ν. 1444/2002 ",
      attachement: "",
      children: {
        0: {
          description:
            "Οι εγχωρίως κατασκευαζόμενα και εισαγόμενα ικριώματα πρέπει να συνοδεύονται από Οδηγίες συναρμολόγησης του κατασκευαστή, πρότυπα κατασκευής και μελέτη αντοχής φορτίων.",
          article: "αρ. 3&5",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        1: {
          description:
            "Για μεταλλική σκαλωσιά υπάρχει βεβαίωση εξέτασης τύπου και κατάλληλη σήμανση των στοιχείων της.",
          article: "αρ. 6",
          section: "Ικριώματα",
          subsection: "Τεχνικός Φάκελος - Έγγραφα",
        },
        2: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        3: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        4: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
        5: {
          description:
            "Η συναρμολόγηση σκαλωσιάς γίνεται βάσει των οδηγιών του κατασκευαστή ή της μελέτης του μηχανικού.",
          article: "αρ. 7",
          section: "Ικριώματα",
          subsection: "",
        },
      },
    },
  },
};

export const sections = {
  headers: [
    { type: "text", title: "Αρ.", id: "id" },
    { type: "text", title: "Τμήμα", id: "section" },
  ],
  childrenHeaders: [
    { type: "text", title: "Αρ", id: "id" },
    { type: "text", title: "Υποτμήμα", id: "subsection" },
  ],
  data: {
    s1: {
      id: "01",
      section: "Βιολογικοί Παράγοντες",
      children: {
        0: {
          id: "01.01",
          subsection: "Αιματογενείς Παράγοντες",
        },
        1: {
          id: "01.02",
          subsection: "Εκτίμηση Επικινδυνότητας",
        },
        2: {
          id: "01.03",
          subsection: "Λεγεωννέλα",
        },
        3: {
          id: "01.04",
          subsection: "Λεπτοσπείρωση",
        },
        4: {
          id: "01.05",
          subsection: "Ιατρική Παρακολούθηση",
        },
        5: {
          id: "01.06",
          subsection: "Σχέδιο Διάτρησης - Γόμωσης - Πυροδότησης",
        },
      },
    },
    s2: {
      id: "02",
      section: "Χημικοί Παράγοντες",
      children: {
        0: {
          id: "02.01",
          subsection: "Ιατρική Παρακολούθηση",
        },
        1: {
          id: "02.02",
          subsection: "Εκτίμηση Επικινδυνότητας",
        },
        2: {
          id: "02.03",
          subsection: "Ενημέρωση",
        },
        3: {
          id: "02.04",
          subsection: "Καρκινογόνοι Παράγοντες",
        },
      },
    },
    s3: {
      id: "03",
      section: "Γενικές Απαιτήσεις Α&Υ",
      children: {
        0: {
          id: "03.01",
          subsection: "Θέματα Ιατρού Εργασίας",
        },
        1: {
          id: "03.02",
          subsection: "Υποχρεώσεις Εργοδότη",
        },
        2: {
          id: "03.03",
          subsection: "Υποχρεώσεις Εργαζομένων",
        },
        3: {
          id: "03.04",
          subsection: "Θέματα Τεχνικού Ασφάλειας",
        },
        4: {
          id: "03.05",
          subsection: "Θέματα Συντονιστή Ασφάλειας",
        },
        5: {
          id: "03.06",
          subsection: "Επισήμανση Κινδύνων Παραδοχών Μελέτης",
        },
      },
    },
    s4: {
      id: "04",
      section: "Mηχανήματα Έργων/Οχήματα",
      children: {
        0: {
          id: "04.01",
          subsection: "Άδειες - Έγγραφα",
        },
        1: {
          id: "04.02",
          subsection: "Χειριστές - Οδηγοί",
        },
        2: {
          id: "04.03",
          subsection: "Έλεγχος - Συντήρηση",
        },
        3: {
          id: "04.04",
          subsection: "Κυκλοφορία - Οδική Ασφάλεια",
        },
      },
    },
  },
};

export const auditFunctions = {
  headers: [
    { type: "text", title: "Τίτλος", id: "title" },
    { type: "checkbox", title: "Επιθεωρητής", id: "auditor" },
    { type: "text", title: "Σειρά έγκρισης", id: "hierarchy" },
  ],
  data: {
    af1: {
      title: "Επιθεωρητής",
      auditor: "Y",
      hierarchy: "Επιθεωρητής",
    },
    af2: {
      title: "Επιθεωρούμενος",
      auditor: "N",
      hierarchy: "2",
    },
    af3: {
      title: "Επιθεωρούμενος – Εκπρόσωπος Εταιρείας",
      auditor: "N",
      hierarchy: "1",
    },
    af4: {
      title: "Παρατηρητής",
      auditor: "N",
      hierarchy: "",
    },
    af5: {
      title: "Βοηθός Επιθεωρητής",
      auditor: "N",
      hierarchy: "",
    },
  },
};

export const progress = {
  headers: [
    { type: "text", title: "Περιγραφή", id: "description" },
    { type: "color", title: "Χρώμα", id: "color" },
    { type: "text", title: "Συντελεστής", id: "factor" },
    { type: "text", title: "Σειρά εμφάνισης", id: "displayOrder" },
  ],
  data: {
    p1: {
      description: "Καμία ενέργεια",
      color: "#C0C0C0",
      factor: "1",
      displayOrder: "1",
    },
    p2: {
      description: "Σε εξέλιξη",
      color: "#FFFF00",
      factor: "2",
      displayOrder: "2",
    },
    p3: {
      description: "Ολοκληρώθηκε",
      color: "#50B000",
      factor: "3",
      displayOrder: "3",
    },
    p4: {
      description: "Παρωχημένο",
      color: "#cc9966",
      factor: "0",
      displayOrder: "4",
    },
    p5: {
      description: "Τηρείται",
      color: "#FFFFFF",
      factor: "4",
      displayOrder: "5",
    },
    p6: {
      description: "Καλή πρακτική",
      color: "#80FF80",
      factor: "0",
      displayOrder: "6",
    },
    p7: {
      description: "Ανοιχτό",
      color: "#FF0000",
      factor: "1",
      displayOrder: "7",
    },
  },
};

export const ratings = {
  headers: [
    { type: "text", title: "Ονομασία", id: "name" },
    { type: "text", title: "Προθεσμία", id: "deadline" },
    { type: "checkbox", title: "CAR", id: "car" },
    { type: "color", title: "Χρώμα", id: "color" },
    { type: "text", title: "Συντελεστής", id: "factor" },
    { type: "text", title: "Σειρά εμφάνισης", id: "displayOrder" },
  ],
  data: {
    rt1: {
      name: "Υψηλή",
      deadline: "Άμεσα",
      car: "Y",
      color: "#FF0000",
      factor: "3",
      displayOrder: "1",
    },
    rt2: {
      name: "Μέτρια",
      deadline: "5",
      car: "Y",
      color: "#FFFF00",
      factor: "2",
      displayOrder: "2",
    },
    rt3: {
      name: "Χαμηλή",
      deadline: "20",
      car: "Ν",
      color: "#4169E1",
      factor: "1",
      displayOrder: "3",
    },
  },
};

export const deadlines = {
  headers: [
    { type: "text", title: "Ημέρες", id: "days" },
    { type: "text", title: "Σειρά εμφάνισης", id: "displayOrder" },
  ],
  data: {
    d1: {
      days: "Άμεσα",
      displayOrder: "1",
    },
    d2: {
      days: "5",
      displayOrder: "2",
    },
    d3: {
      days: "10",
      displayOrder: "3",
    },
    d4: {
      days: "15",
      displayOrder: "4",
    },
    d5: {
      days: "20",
      displayOrder: "5",
    },
    d6: {
      days: "25",
      displayOrder: "6",
    },
    d7: {
      days: "30",
      displayOrder: "7",
    },
    d8: {
      days: "35",
      displayOrder: "8",
    },
    d9: {
      days: "40",
      displayOrder: "9",
    },
    d10: {
      days: "Ολοκληρώθηκε",
      displayOrder: "10",
    },
    d11: {
      days: "Δ/Α",
      displayOrder: "11",
    },
  },
};

export const checkboxOptions = [
  {
    label: "Επιθεωρούμενος – Εκπρόσωπος Εταιρείας",
    value: "Auditee - Company’s representative",
  },
  { label: "Επιθεωρούμενος", value: "Auditee" },
  { label: "Βοηθός Επιθεωρητής", value: "Assistant Auditor" },
  { label: "Επιθεωρητής", value: "Co-Auditor" },
  { label: "Παρατηρητής", value: "Observer" },
];
