import Flex from "../../../../../../helpers/components/Flex";
import CustomDatePicker from "../../../../../../helpers/components/CustomDatepicker";
import FilterDateTime from "./FilterDateTime";
import { WarningOutlined } from "@ant-design/icons";
import { useAuthStore } from "../../../../../../store/useStores";

const DateTime = ({
  value,
  id,
  config,
  addChange,
  required,
  canupdate,
  canupdateifnull,
  row,
  autoCompletedFields = {},
  render,
}) => {
  const userID = useAuthStore((state) => state.userID);

  if (config === "filters") {
    return (
      <FilterDateTime
        value={value}
        id={id}
        addChange={addChange}
        config={config}
      />
    );
  }

  let isDisabled = false;
  if (config !== "filters") {
    if (Object.keys(autoCompletedFields).includes(id)) {
      isDisabled = true;
    } else {
      if (canupdate === false) {
        if (canupdateifnull === true) {
          if (value?.length) {
            if (row[`${id}-US`] !== userID) {
              isDisabled = true;
            }
          }
        } else {
          isDisabled = true;
        }
      }
    }
  }

  let newValue = render === "ALERT" ? value?.value ?? "" : value;
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    newValue = autoCompletedFields[id]?.from ?? "";
  }

  return (
    <Flex flexDirection={"row"}>
      <CustomDatePicker
        value={newValue}
        id={id}
        config={config}
        isDisabled={isDisabled}
        addChange={addChange}
        showTime={true}
      />
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 8, marginLeft: 15 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default DateTime;
