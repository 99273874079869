import { Input, Modal, Select, Button, ColorPicker, Tooltip } from "antd";
import { useState, useMemo, useEffect } from "react";
import Flex from "../../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../../store/useStores";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";

const { Option } = Select;

export const StaticListModal = ({
  visible,
  data,
  index,
  fields,
  onSave,
  onCancel,
  config,
}) => {
  const [changes, setChanges] = useState({});
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [colorRgb, setColorRgb] = useState("rgb(255,255,255)");

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  useEffect(() => {
    if (config === "edit") {
      setColorRgb(hexToRgb(data.FO_BACKCOLOR));
    }

    if (config === "new") {
      setChanges({ FO_ID: "" });
    }
  }, []);

  const rgbToHex = (color) => {
    const rgb = (color.r << 16) | (color.g << 8) | (color.b << 0);
    return "#" + (0x1000000 + rgb).toString(16).slice(1);
  };

  const hexToRgb = (hex) => {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) {
      const colorArray = normal.slice(1).map((e) => parseInt(e, 16));
      return `rgb(${colorArray[0]},${colorArray[1]},${colorArray[2]})`;
    }

    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) {
      const colorArray = shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));
      return `rgb(${colorArray[0]},${colorArray[1]},${colorArray[2]})`;
    }

    return null;
  };

  return (
    <Modal
      key={`${data}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      data={data}
      style={{
        marginTop: 40,
      }}
      title={
        config === "edit"
          ? commonTitles["CO|1|EDITOPT"]
          : commonTitles["CO|1|NEWOPT"]
      }
      onOk={() => onSave(config, changes, index)}
      onCancel={() => onCancel(config)}
      footer={[
        <Button key="back" onClick={() => onCancel(config)}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(config, changes, index)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <Flex flexDirection={"row"} style={{ marginTop: "3rem" }}>
        <div className="modal-text">
          <p>{fields[4].TITLE}:</p>
          <p>{commonTitles["CO|1|COLOR"]}:</p>
        </div>
        <div className="modal-input">
          <div className="select">
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginTop: -5,
                marginBottom: "1rem",
              }}
              onChange={(e) => addChange("T_TITLE", e.target.value)}
              defaultValue={config === "edit" ? data.T_TITLE : null}
            />
          </div>
          <Flex flexDirection={"row"} style={{ gap: 10 }}>
            <ColorPicker
              format={"rgb"}
              value={colorRgb}
              style={{ marginLeft: 15 }}
              onChange={(e) => {
                setColorRgb(e);
                addChange("FO_BACKCOLOR", rgbToHex(e.metaColor));
              }}
            />
            <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
              <Button
                className="dropdown"
                shape="round"
                icon={
                  <FormatColorResetIcon
                    style={{ fontSize: 18, marginTop: 0 }}
                  />
                }
                onClick={() => {
                  addChange("FO_BACKCOLOR", "#ffffff");
                  setColorRgb("rgb(255,255,255)");
                }}
                style={{
                  backgroundColor: "transparent",
                  color: "#6082B6",
                  border: "none",
                }}
              />
            </Tooltip>
          </Flex>
        </div>
      </Flex>
    </Modal>
  );
};
