import { Select } from "antd";
import Flex from "../../../../../helpers/components/Flex";

const { Option } = Select;

const SingleSelection = ({
  value = "",
  id,
  title,
  config,
  staticOptions = {},
  staticOptionsKey,
  addChange,
}) => {
  let newValue = value;

  if (id === "dateformat") {
    newValue = value?.split(",")[0];
  }

  if (id === "timeformat") {
    newValue = value?.split(",")[1];
  }

  return (
    <Flex
      key={staticOptionsKey}
      flexDirection={"row"}
      style={{ justifyContent: "flex-start" }}
    >
      <div className="modal-text" style={{ width: "35%" }}>
        <p>{title}:</p>
      </div>
      <div className="modal-input" style={{ width: "65%" }}>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 15,
            }}
            showSearch={true}
            onChange={(e) => addChange(id, e)}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={config === "edit" ? newValue : ""}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {Object.entries(staticOptions?.[staticOptionsKey] ?? {})?.map(
              ([key, value]) => (
                <Option key={value.TITLE} className="option" value={key}>
                  {value.TITLE}
                </Option>
              )
            )}
          </Select>
        </div>
      </div>
    </Flex>
  );
};

export default SingleSelection;
