const config = (set) => ({
  currentPage: 1,
  setCurrentPage: (newCurrentPage) =>
    set(() => ({ currentPage: newCurrentPage })),

  pageSize: {},
  setPageSize: (newPageSize) => set(() => ({ pageSize: newPageSize })),
});

const PaginationStore = {
  config,
  shouldLog: true,
};

export default PaginationStore;
