import "../../../css/ultimateTable.css";
import { Col, Row, Card, Layout } from "antd";
import FormControl from "@mui/material/FormControl";
import { useAuthStore } from "../../../../../store/useStores";
import inputDecider from "../../inputDecider/inputDecider";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import DisplayDecider from "./DisplayDecider";
import htmlCodesRemover from "../../htmlCodesRemover";

const { Content } = Layout;

const FiltersModalContent = ({
  columns,
  filters,
  columnsFiltered,
  staticOptions,
  foreignKeyValues,
  addFilterChange,
  filterChanges,
  privileges,
  moduleID,
}) => {
  const role = useAuthStore((state) => state.role);
  const { width } = useWindowDimensions();

  columns = columns.filter(
    (column) =>
      column.F_TYPE !== "24" && column.F_TYPE !== "25" && column.F_TYPE !== "29"
  );

  let newColumns = [];
  let i = 0;

  const isFirefox = typeof InstallTrigger !== "undefined";

  [...columns]
    .sort(
      (
        { F_EDITORDER: F_EDITORDER_A = 0 },
        { F_EDITORDER: F_EDITORDER_B = 0 }
      ) => {
        if (!F_EDITORDER_A) {
          if (isFirefox) {
            return -1;
          }
          return 1;
        } else if (!F_EDITORDER_B) {
          return -1;
        } else {
          return Number(F_EDITORDER_A) - Number(F_EDITORDER_B);
        }
      }
    )
    .forEach((column) => {
      if (column?.F_SINGLELINE) {
        if (column.F_SINGLELINE === "L") {
          newColumns.push(column);
          newColumns.push({
            F_ID: i.toString(),
            T_TITLE: "",
            F_REQUIRED: "N",
            F_TYPE: "0",
            F_LIST: "Y",
          });
        }

        if (column.F_SINGLELINE === "R") {
          newColumns.push({
            F_ID: i.toString(),
            T_TITLE: "",
            F_REQUIRED: "N",
            F_TYPE: "0",
            F_LIST: "Y",
          });
          newColumns.push(column);
        }

        i++;
      } else {
        newColumns.push(column);
      }
    });

  let viewRights = {};
  newColumns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  return (
    <Content>
      <Card>
        <Row gutter={[20, 10]} justify={width < 1450 ? "center" : "left"}>
          {newColumns
            .filter((column) => viewRights[column.F_ID])
            .map(
              ({
                F_ID,
                F_TYPE,
                T_TITLE,
                DEP_FIELDS,
                F_FORMAT,
                F_PARENTFIELDID,
                DECIMAL,
              }) =>
                F_TYPE !== "24" &&
                F_TYPE !== "25" && (
                  <Col
                    className="gutter-row"
                    span={width < 1450 ? 20 : 10}
                    key={F_ID}
                  >
                    <div className="grid-horizontal">
                      <DisplayDecider
                        filterChanges={filterChanges}
                        filters={filters}
                        fieldID={F_ID}
                        fieldType={F_TYPE}
                        fieldTitle={htmlCodesRemover(T_TITLE)}
                      />
                      <FormControl
                        sx={{
                          mt: -1,
                          mb: 0.5,
                          ml: 1,
                          mr: 1,
                          minWidth: 300,
                        }}
                        className="filters-row"
                        key={F_ID}
                      >
                        {inputDecider(
                          F_TYPE,
                          F_ID,
                          filters[F_ID] ?? null,
                          staticOptions,
                          foreignKeyValues,
                          addFilterChange,
                          "filters",
                          "",
                          Object.prototype.toString.call(DEP_FIELDS) ===
                            "[object Array]"
                            ? DEP_FIELDS
                            : [DEP_FIELDS],
                          filters,
                          true,
                          true,
                          moduleID,
                          [],
                          "",
                          F_FORMAT,
                          F_PARENTFIELDID,
                          filterChanges,
                          DECIMAL ?? "0"
                        )}
                      </FormControl>
                    </div>
                  </Col>
                )
            )}
        </Row>
      </Card>
    </Content>
  );
};

export default FiltersModalContent;
