import Flex from "../../../helpers/components/Flex";
import { Card, DatePicker, Select, Checkbox } from "antd";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import { convertDateFormat } from "../../ultimateTable/helpers/converters";
import "../css/safetyAudit.css";
import moment from "moment";
import { checkboxOptions } from "../server";
import { useState } from "react";
import { useEffect } from "react";

const { Option } = Select;

const AddAudit = ({ projects, sites, addChange }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);
  const [projectUsed, setProjectUsed] = useState(projects[0].key);

  return (
    <Flex flexDirection={"column"}>
      <Card style={{ borderRadius: "10px", marginTop: "0.5rem" }}>
        <Flex flexDirection={"column"} style={{ gap: 15 }}>
          <Flex flexDirection={"row"}>
            <p
              style={{
                width: "8rem",
                fontWeight: 600,
                marginRight: "1rem",
              }}
            >
              Έργο:
            </p>
            <div className="select">
              <Select
                allowClear
                style={{
                  width: 150,
                }}
                showSearch={true}
                filterOption={(iv, op) =>
                  op.value
                    .toLocaleLowerCase()
                    .includes((iv || "").toLocaleLowerCase())
                }
                onChange={(e) => {
                  addChange("project", e);
                  setProjectUsed(e);
                }}
                defaultValue={""}
                dropdownStyle={{ overflowY: "auto" }}
                virtual={false}
              >
                <Option key={""} className="option" value={""} />
                {projects.map((project) => (
                  <Option
                    key={project.key}
                    className="option"
                    value={project.key}
                  >
                    {project.title}
                  </Option>
                ))}
              </Select>
            </div>
          </Flex>
          <Flex key={projectUsed} flexDirection={"row"}>
            <p
              style={{
                width: "8rem",
                fontWeight: 600,
                marginRight: "1rem",
              }}
            >
              Εγκατάσταση:
            </p>
            <div className="select">
              <Select
                allowClear
                style={{
                  width: 150,
                }}
                showSearch={true}
                filterOption={(iv, op) =>
                  op.value
                    .toLocaleLowerCase()
                    .includes((iv || "").toLocaleLowerCase())
                }
                onChange={(e) => addChange("site", e)}
                defaultValue={""}
                dropdownStyle={{ overflowY: "auto" }}
                virtual={false}
              >
                <Option key={""} className="option" value={""} />
                {sites[projectUsed].map((site) => (
                  <Option key={site.key} className="option" value={site.key}>
                    {site.title}
                  </Option>
                ))}
              </Select>
            </div>
          </Flex>
          <Flex flexDirection={"row"} style={{ gap: 50 }}>
            <Flex flexDirection={"column"} style={{ gap: 3 }}>
              <p style={{ fontWeight: 600 }}>Αρ. Επιθέωρησης:</p>
              <input
                type="text"
                className="edit-input"
                onChange={(e) => addChange("auditNumber", e.target.value)}
              />
            </Flex>
            <Flex flexDirection={"column"} style={{ gap: 3 }}>
              <p style={{ fontWeight: 600 }}>Αρχή:</p>
              <DatePicker
                key={"from"}
                id={"from"}
                format={dateFormatToUse}
                placeholder={[commonTitles["CO|1|SLCTDATE"]]}
                //defaultValue={moment()}
                onChange={(dateString) =>
                  addChange(
                    "start",
                    dateString ? dateString.format(dateFormatToUse) : ""
                  )
                }
                bordered={true}
                style={{
                  width: 160,
                  height: 30,
                  borderRadius: 5,
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} style={{ gap: 3 }}>
              <p style={{ fontWeight: 600 }}>Τέλος:</p>
              <DatePicker
                key={"from"}
                id={"from"}
                format={dateFormatToUse}
                placeholder={[commonTitles["CO|1|SLCTDATE"]]}
                //defaultValue={moment()}
                onChange={(dateString) =>
                  addChange(
                    "end",
                    dateString ? dateString.format(dateFormatToUse) : ""
                  )
                }
                bordered={true}
                style={{
                  width: 160,
                  height: 30,
                  borderRadius: 5,
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} style={{ gap: 3 }}>
              <p style={{ fontWeight: 600 }}>Νομοθεσία:</p>
              <div className="select">
                <Select
                  allowClear
                  style={{
                    width: 150,
                  }}
                  showSearch={true}
                  filterOption={(iv, op) =>
                    op.value
                      .toLocaleLowerCase()
                      .includes((iv || "").toLocaleLowerCase())
                  }
                  defaultValue={""}
                  onChange={(e) => addChange("legislation", e)}
                  dropdownStyle={{ overflowY: "auto" }}
                  virtual={false}
                >
                  <Option key={""} className="option" value={""} />
                  <Option
                    key={"national"}
                    className="option"
                    value={"national"}
                  >
                    Εθνική
                  </Option>
                  <Option
                    key={"european"}
                    className="option"
                    value={"european"}
                  >
                    Ευρωπαϊκή
                  </Option>
                </Select>
              </div>
            </Flex>
          </Flex>
          <Flex flexDirection={"row"} style={{ marginTop: "1rem" }}>
            <Flex flexDirection={"row"}>
              <Flex flexDirection={"column"} style={{ gap: 3 }}>
                <p style={{ fontWeight: 600 }}>Επιθεωρούμενος:</p>
                <textarea
                  type="text"
                  style={{
                    minWidth: 200,
                    borderRadius: 10,
                    minHeight: 60,
                    paddingLeft: 5,
                  }}
                  onChange={(e) => addChange("auditee", e.target.value, "text")}
                />
              </Flex>
              <Checkbox.Group
                style={{
                  marginLeft: 20,
                  marginTop: 40,
                }}
                options={checkboxOptions}
                defaultValue={[]}
                onChange={(checkedValues) => {
                  addChange("auditee", checkedValues, "checked");
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} style={{ gap: 10 }}>
              <Flex flexDirection={"column"} style={{ gap: 1 }}>
                <p style={{ fontWeight: 600 }}>Καιρικές Συνθήκες:</p>
                <textarea
                  type="text"
                  style={{
                    maxWidth: 500,
                    borderRadius: 10,
                    height: 40,
                    paddingLeft: 5,
                  }}
                  onChange={(e) => addChange("weather", e.target.value)}
                />
              </Flex>
              <Flex flexDirection={"column"} style={{ gap: 1 }}>
                <p style={{ fontWeight: 600 }}>Άλλο:</p>
                <textarea
                  type="text"
                  style={{
                    width: 500,
                    borderRadius: 10,
                    minHeight: 40,
                    paddingLeft: 5,
                  }}
                  onChange={(e) => addChange("other", e.target.value)}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

export default AddAudit;
