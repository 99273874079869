import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, Image } from "antd";
import image404 from "../../assets/error404.jpg";
import { useAuthStore, useCommonTitlesStore } from "../../store/useStores";
import Flex from "../../helpers/components/Flex";
import BackgroundWrapper from "../../helpers/components/BackgroundWrapper";

const NoMatchRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <BackgroundWrapper>
      <Card
        style={{
          display: "flex",
          width: "98vw",
          height: "max-content",
          backgroundColor: "white",
          marginLeft: 15,
          marginTop: 15,
          borderRadius: 15,
        }}
      >
        <Flex flexDirection={"row"}>
          <Image
            src={image404}
            alt="logo"
            width={280}
            height={250}
            preview={false}
          />
          <Flex flexDirection={"column"}>
            <p
              style={{
                marginTop: 60,
                marginLeft: 20,
                fontSize: 20,
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              OOPS!
            </p>
            <p
              style={{
                marginLeft: 20,
                fontSize: 16,
                fontWeight: 200,
              }}
            >
              {commonTitles["CO|1|NOPAGEFOUN"]} {baseURL}
              {location.pathname}
            </p>
            <Flex flexDirection={"row"}>
              <Button
                style={{
                  marginLeft: 18,
                  marginTop: 5,
                  backgroundColor: "rgb(8, 8, 46)",
                  color: "#fff",
                  borderRadius: 5,
                }}
                onClick={() => navigate("/home")}
              >
                {commonTitles["CO|1|BACKHOME"]}
              </Button>
              <Button
                style={{
                  marginLeft: 20,
                  marginTop: 5,
                  backgroundColor: "rgb(8, 8, 46)",
                  color: "#fff",
                  borderRadius: 5,
                }}
                onClick={() => navigate(-1)}
              >
                {commonTitles["CO|1|BACKPREV"]}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </BackgroundWrapper>
  );
};

export default NoMatchRoute;
