import { message } from "antd";
import axios from "axios";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";

const apiAlertReport = async (baseURL, suffix, commonTitles) => {
  const url = `${baseURL}/Report_api/alertReport`;
  const docformat = suffix === "xlsx" ? "E" : "P";
  try {
    const response = await axios.post(
      url,
      { docformat: docformat },
      {
        withCredentials: true,
      }
    );
    //console.log("apiReport: response: ", response.data);
    // if (response?.data?.STATUS === "Success") {
    //   message.success("Επιτυχής έκδοση αναφοράς", [3]);
    // } else {
    //   message.warning("Σφάλμα", [3]);
    // }

    return response.data;
  } catch (err) {
    //message.error(err, [3]);
    displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
  }
};

export default apiAlertReport;
