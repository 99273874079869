import { Input } from "antd";
import Flex from "../../../../../helpers/components/Flex";
import { WarningOutlined } from "@ant-design/icons";
import { useAuthStore } from "../../../../../store/useStores";
import htmlCodesRemover from "../../htmlCodesRemover";

const TextType = ({
  value,
  id,
  required,
  config,
  addChange,
  canupdate,
  canupdateifnull,
  row,
  autoCompletedFields = {},
  size,
}) => {
  const userID = useAuthStore((state) => state.userID);

  let newValue = htmlCodesRemover(value) ?? "";
  // if (value && value.includes("&#39;")) {
  //   let splitValue = value
  //     .split("&#39;")
  //     .filter((item) => item !== "")
  //     .join("'");
  //   newValue = splitValue;
  //   if (splitValue && splitValue.includes("&#10;")) {
  //     let newSplitValue = value.split("&#10;").filter((item) => item !== "");
  //     let str = "";
  //     newSplitValue.forEach((item) => {
  //       if (str !== "") {
  //         str += "\n";
  //       }
  //       str += item;
  //     });
  //     newValue = str;
  //   }
  // } else if (value && value.includes("&#10;")) {
  //   let newSplitValue = value.split("&#10;").filter((item) => item !== "");
  //   let str = "";
  //   newSplitValue.forEach((item) => {
  //     if (str !== "") {
  //       str += "\n";
  //     }
  //     str += item;
  //   });
  //   newValue = str;
  // }

  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    newValue = autoCompletedFields[id];
  }

  let isDisabled = false;
  if (config !== "filters") {
    if (Object.keys(autoCompletedFields).includes(id)) {
      isDisabled = true;
    } else {
      if (canupdate === false) {
        if (canupdateifnull === true) {
          if (newValue.length) {
            if (row[`${id}-US`] !== userID) {
              isDisabled = true;
            }
          }
        } else {
          isDisabled = true;
        }
      }
    }
  }

  return (
    <Flex flexDirection={"row"}>
      <Input
        type="text"
        style={{
          border: "1.25px solid",
          borderColor: "rgb(182, 180, 180)",
          borderRadius: 5,
          fontFamily: "Arial, Helvetica, sans-serif",
          paddingRight: "0.5rem",
          paddingLeft: "0.5rem",
          fontSize: 14,
          letterSpacing: "0.03em",
          height: 30,
          minWidth: 275,
        }}
        defaultValue={newValue ?? ""}
        onChange={(e) => addChange(id, e.target.value, null)}
        disabled={isDisabled}
        maxLength={size ? size : null}
      />
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default TextType;
