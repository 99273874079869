import { Input } from "antd";
import Flex from "../../../../../helpers/components/Flex";

const InputType = ({ value = null, id, title, config, addChange }) => {
  return (
    <Flex
      key={id}
      flexDirection={"row"}
      style={{ justifyContent: "flex-start" }}
    >
      <div className="modal-text" style={{ width: "35%" }}>
        <p>{title}:</p>
      </div>

      <div className="modal-input" style={{ width: "65%" }}>
        <div className="select">
          <Input
            style={{
              width: 300,
              marginLeft: 15,
              marginTop: 0,
              marginBottom: "1rem",
            }}
            onChange={(e) => addChange(id, e.target.value)}
            defaultValue={config === "edit" ? value : null}
          />
        </div>
      </div>
    </Flex>
  );
};

export default InputType;
