import { useCommonTitlesStore } from "../../../../store/useStores";
import { Select } from "antd";
import { useChartStore } from "../../../../store/useStores";
import "./../../css/ultimateChart.css";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const Grouping = () => {
  const grouping = useChartStore((state) => state.grouping);
  const setGrouping = useChartStore((state) => state.setGrouping);
  const chartType = useChartStore((state) => state.chartType);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const { width } = useWindowDimensions();

  const options = [
    { value: "Daily", label: commonTitles.daily },
    { value: "Monthly", label: commonTitles.monthly },
    { value: "Yearly", label: commonTitles.yearly },
  ];

  const trimmedOptions = [{ value: "Yearly", label: commonTitles.yearly }];

  return (
    <div className="select">
      <Select
        style={{
          width: width < 1525 ? 120 : 200,
          marginLeft: 0,
          marginRight: 10,
          marginTop: 0,
          marginBottom: -50,
        }}
        showSearch={true}
        filterOption={(iv, op) => {
          let opToUse = op.children ? op.children : "";
          return opToUse.toLowerCase().includes(iv.toLowerCase());
        }}
        dropdownStyle={{
          minWidth: "max-content",
          paddingLeft: 5,
          paddingRight: 5,
          overflowY: "auto",
        }}
        onChange={(e) => setGrouping(e)}
        defaultValue={grouping}
        placeholder={`${commonTitles.grouping}`}
        virtual={false}
      >
        <Option className="option" key={""} value={null} />
        {/* {chartType === "Pie"
          ? trimmedOptions.map(({ value, label }) => (
              <Option
                className="option"
                key={`${value}-${label}`}
                value={value}
                label={label}
              >
                {label}
              </Option>
            ))
          : options.map(({ value, label }) => (
              <Option
                className="option"
                key={`${value}-${label}`}
                value={value}
                label={label}
              >
                {label}
              </Option>
            ))} */}
        {options.map(({ value, label }) => (
          <Option
            className="option"
            key={`${value}-${label}`}
            value={value}
            label={label}
          >
            {label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Grouping;
