import React from "react";
import Blog from "./blog/Blog";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import WhatTestSite from "./whatTestSite/WhatTestSite";
import Navbar from "./navbar/Navbar";
import "../../App.css";
import {
  useCommonTitlesStore,
  useAuthStore,
  useModTitlesStore,
  useFilterStore,
  useAlertStore,
  useMenuStore,
} from "../../store/useStores";
import { useState, useEffect } from "react";

const Welcome = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [formType, setFormType] = useState("login");

  const setAlerts = useAlertStore((state) => state.setAlerts);
  const setAlertTitles = useAlertStore((state) => state.setAlertTitles);
  const setMenu = useMenuStore((state) => state.setMenu);
  const setUserID = useAuthStore((state) => state.setUserID);
  const setWorkspaceID = useAuthStore((state) => state.setWorkspaceID);
  const setProjectID = useAuthStore((state) => state.setProjectID);
  const setRole = useAuthStore((state) => state.setRole);
  const setShowGroup = useAuthStore((state) => state.setShowGroup);
  const setFirstName = useAuthStore((state) => state.setFirstName);
  const setLastName = useAuthStore((state) => state.setLastName);
  const setUsername = useAuthStore((state) => state.setUsername);
  const setPassword = useAuthStore((state) => state.setPassword);
  const setDateFormat = useAuthStore((state) => state.setDateFormat);
  const setTimeFormat = useAuthStore((state) => state.setTimeFormat);
  const setLoginStatus = useAuthStore((state) => state.setLoginStatus);
  const setModTitles = useModTitlesStore((state) => state.setModTitles);
  const setFilters = useFilterStore((state) => state.setFilters);

  const changeFormType = (type) => {
    setFormType(type);
  };

  useEffect(() => {
    setLoginStatus("");
    setFirstName("");
    setLastName("");
    setUsername("");
    setPassword("");
    setRole("");
    setShowGroup("");
    setUserID("");
    setWorkspaceID("");
    setProjectID("");
    setDateFormat("");
    setTimeFormat("");
    setAlerts({});
    setAlertTitles({});
    setMenu([]);
    setModTitles({});
    setFilters({});
  }, []);

  return (
    // <div>
    <div className="gradient__bg" style={{ height: "max-content" }}>
      <Navbar
      // tag1={`${commonTitles.home}`}
      // tag2={`${commonTitles.whatIs} PLCS`}
      // tag3={`${commonTitles.projects}`}
      />
      <Header
        key={formType}
        formType={formType}
        changeFormType={changeFormType}
      />
    </div>
    /* <WhatTestSite />
      <Blog /> 
       <Footer /> 
    </div> */
  );
};

export default Welcome;
