import apiUpload from "../../../../api/endpoints/apiUpload";

const uploadFiles = async (
  filesToUpload,
  attachColumns,
  baseURL,
  commonTitles
) => {
  let uploadFileTracker = {};
  let uploadError = false;

  for (let i = 0; i < attachColumns?.length; i++) {
    if (!uploadError) {
      let id = attachColumns[i];
      uploadFileTracker = { ...uploadFileTracker, [id]: [] };
      for (let j = 0; j < filesToUpload[id]?.length; j++) {
        const fmData = new FormData();
        fmData.append("file", filesToUpload[id][j]);

        const { uploadStatus, uploadedFile } = await apiUpload(
          baseURL,
          fmData,
          commonTitles
        );

        if (uploadStatus !== "Success") {
          uploadError = true;
          break;
        }

        uploadFileTracker[id].push(uploadedFile);
      }
    } else break;
  }

  if (uploadError) {
    return { uploadFileTracker, uploadStatus: "Error" };
  }

  return { uploadFileTracker, uploadStatus: "Success" };
};

export default uploadFiles;
