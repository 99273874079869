import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "./banner/Banner";
import Navigation from "../navigation/Navigation";
import {
  useFilterStore,
  useMenuStore,
  useAuthStore,
  useCommonTitlesStore,
  useChartStore,
  useModuleStore,
} from "../../store/useStores";
import apiGetData from "../../api/endpoints/apiGetData";
import moment from "moment";
import { convertDateFormat } from "../ultimateTable/helpers/converters";
import countData from "./UC helpers/countData";
import createData from "./UC helpers/createData";
import UltimateChartSettings from "./UC settings/UltimateChartSettings";
import UltimateChartBody from "./UC body/UltimateChartBody";
import "./css/ultimateChart.css";
import createFilters from "./UC helpers/createFilters";
import createOptions from "./UC helpers/createOptions";
import Flex from "../../helpers/components/Flex";
import { Spin, Card } from "antd";
import htmlCodesRemover from "../ultimateTable/helpers/htmlCodesRemover";
import useWindowDimensions from "../ultimateTable/hooks/useWindowDimensions";
import apiGetMetrics from "../../api/endpoints/apiGetMetrics";
import BackgroundWrapper from "../../helpers/components/BackgroundWrapper";
import ModuleWrapper from "../module/wrappers/ModuleWrapper";
import newCountData from "./UC helpers/newCountData/newCountData";
import createChartTitle from "./UC helpers/createChartTitle";

const UltimateChart = () => {
  const { moduleID } = useParams();
  const dateTypes = useModuleStore((state) => state.dateTypes);
  const numberTypes = useModuleStore((state) => state.numberTypes);
  const baseURL = useAuthStore((state) => state.baseURL);
  const role = useAuthStore((state) => state.role);
  const menu = useMenuStore((state) => state.menu);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [foreignKeyValues, setForeignKeyValues] = useState({});
  const [staticOptions, setStaticOptions] = useState({});
  const [dataToUse, setDataToUse] = useState([]);
  const [chartData, setChartData] = useState({});
  const [filterStatus, setFilterStatus] = useState(false);
  const [groupFieldStatus, setGroupFieldStatus] = useState(false);

  const filters = useFilterStore((state) => state.filters);
  const setFilters = useFilterStore((state) => state.setFilters);

  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);
  const language = useAuthStore((state) => state.language);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const chartType = useChartStore((state) => state.chartType);
  const setChartType = useChartStore((state) => state.setChartType);
  const grouping = useChartStore((state) => state.grouping);
  const setGrouping = useChartStore((state) => state.setGrouping);
  const groupField = useChartStore((state) => state.groupField);
  const setGroupField = useChartStore((state) => state.setGroupField);
  const dateField = useChartStore((state) => state.dateField);
  const setDateField = useChartStore((state) => state.setDateField);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const setDisplayFunction = useChartStore((state) => state.setDisplayFunction);
  const sumField = useChartStore((state) => state.sumField);
  const setSumField = useChartStore((state) => state.setSumField);
  const setChartBase64 = useChartStore((state) => state.setChartBase64);
  const groupFieldOptions = useChartStore((state) => state.groupFieldOptions);
  const setGroupFieldOptions = useChartStore(
    (state) => state.setGroupFieldOptions
  );
  const dateFieldOptions = useChartStore((state) => state.dateFieldOptions);
  const setDateFieldOptions = useChartStore(
    (state) => state.setDateFieldOptions
  );
  const sumFieldOptions = useChartStore((state) => state.sumFieldOptions);
  const setSumFieldOptions = useChartStore((state) => state.setSumFieldOptions);

  const setGroupMode = useChartStore((state) => state.setGroupMode);
  const setStartAngle = useChartStore((state) => state.setStartAngle);
  const setEndAngle = useChartStore((state) => state.setEndAngle);
  const setLayout = useChartStore((state) => state.setLayout);
  const setColor = useChartStore((state) => state.setColor);
  const setLineGroupMode = useChartStore((state) => state.setLineGroupMode);
  const setInterpolation = useChartStore((state) => state.setInterpolation);
  const setInnerRadius = useChartStore((state) => state.setInnerRadius);

  const [yValuesUsed, setYValuesUsed] = useState(false);
  const [xValuesUsed, setXValuesUsed] = useState(true);
  const [noDateDataColumnStatus, setNoDateDataColumnStatus] = useState(false);
  const [chartVisible, setChartVisible] = useState(false);
  const [reportIconVisible, setReportIconVisible] = useState(false);
  const [chartTitle, setChartTitle] = useState("");

  const [paramMenu, setParamMenu] = useState([]);
  const [paramMenuTitle, setParamMenuTitle] = useState("");
  const [privileges, setPrivileges] = useState({});
  const [moduleParams, setModuleParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [chartParamMenuVisible, setChartParamMenuVisible] = useState(true);
  const { width } = useWindowDimensions();

  const changeChartParamMenuVisible = (value) => {
    setChartParamMenuVisible(value);
  };

  //console.log("groupField: ", groupField);

  const invertChartVisibility = () => {
    setChartVisible(!chartVisible);
    setReportIconVisible(!reportIconVisible);
  };

  // let moduleParams = null;

  const invertFilterStatus = () => {
    setFilterStatus(!filterStatus);
  };
  const invertGroupFieldStatus = () => {
    setGroupFieldStatus(!groupFieldStatus);
  };

  //console.log("UltimateChart: filters[moduleID]: ", filters[moduleID]);

  useEffect(async () => {
    setIsLoading(true);
    const {
      rows,
      rows1,
      fields,
      staticoptions,
      fkvalues,
      filter,
      menu: parametricMenu,
      canview,
      candelete,
      caninsert,
      canupdate,
    } = await apiGetData(
      moduleID,
      baseURL,
      filters[moduleID] ? filters[moduleID] : {}
    );

    //console.log("fkvalues: ", fkvalues);

    //const tempData = createData(rows, fields, fkvalues, staticoptions, baseURL);

    setData(rows1);

    let tempColumns = [];
    tempColumns =
      fields
        .filter((field) => field.F_LIST === "Y")
        .map((field) => ({
          ...field,
          T_TITLE: htmlCodesRemover(field.T_TITLE) ?? field.TITLE,
        })) ?? [];
    setColumns(tempColumns);
    setForeignKeyValues(fkvalues);
    setStaticOptions(staticoptions);
    setFilters({ [moduleID]: { ...filter } });
    setParamMenu(parametricMenu?.items ?? []);
    setParamMenuTitle(parametricMenu?.title);
    let hasAnyUpdatePrivileges = false;
    let hasAnyInsertPrivileges = false;
    fields.forEach((field) => {
      if (field?.canupdate || field?.canupdateifnull) {
        hasAnyUpdatePrivileges = true;
      }
      if (field?.caninsert) {
        hasAnyInsertPrivileges = true;
      }
    });
    setPrivileges({
      canDelete: candelete,
      canInsert: caninsert || hasAnyInsertPrivileges ? true : false,
      canCopy: caninsert,
      canUpdate: canupdate || hasAnyUpdatePrivileges ? true : false,
      canView: canview,
    });
    setIsLoading(false);
  }, [moduleID, filterStatus]);

  useEffect(() => {
    for (let i = 0; i < menu.length; i++) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].ME_MODULEID === moduleID) {
          setModuleParams(menu[i].children[j]);
        }
      }
    }

    for (let i = 0; i < paramMenu.length; i++) {
      if (paramMenu[i].ME_MODULEID === moduleID) {
        setModuleParams(paramMenu[i]);
      }
    }
  }, [menu, paramMenu]);

  useEffect(() => {
    const columnsWithDate = columns
      .filter(({ F_TYPE }) => F_TYPE === "4")
      .map(({ F_ID }) => F_ID);

    let tempData = Object.entries(data).map(([key, value]) => {
      const dateColumns = {};
      Object.values(columnsWithDate).forEach((columnID) => {
        dateColumns[`${columnID}-date`] = moment(
          value[columnID],
          dateFormatToUse
        ).toDate();
      });
      return { rowID: key, ...value, ...dateColumns };
    });

    setDataToUse([...tempData]);
  }, [data]);

  const updateValuesUsed = (id, value) => {
    if (id === "x") {
      setXValuesUsed(value);
    }
    if (id === "y") {
      setYValuesUsed(value);
    }
  };

  const updateNoDateDataColumnStatus = (value) => {
    setNoDateDataColumnStatus(value);
  };

  //console.log("UltimateChart: data: ", data);

  const [eraseButton, setEraseButton] = useState(false);
  const [eraseButton2, setEraseButton2] = useState(false);

  useEffect(async () => {
    if (eraseButton) {
      if (
        dataToUse.length &&
        columns.length &&
        dateField &&
        //groupField &&
        grouping
      ) {
        const { dailyCount, monthlyCount, yearlyCount } = newCountData(
          dataToUse,
          columns,
          filters[moduleID],
          dateFormatToUse,
          commonTitles,
          groupField,
          dateField,
          foreignKeyValues,
          language,
          xValuesUsed,
          yValuesUsed,
          noDateDataColumnStatus,
          grouping,
          displayFunction,
          sumField,
          dateTypes,
          numberTypes
        );

        setChartData({
          daily: dailyCount,
          monthly: monthlyCount,
          yearly: yearlyCount,
        });

        // console.log("dailyCount: ", dailyCount);
        // console.log("monthlyCount: ", monthlyCount);
        // console.log("yearlyCount: ", yearlyCount);
      } else {
        setChartData({});
      }
    }

    const title = createChartTitle(
      dateField,
      groupField,
      grouping,
      displayFunction,
      sumField,
      columns,
      commonTitles,
      dateTypes
    );

    setChartTitle(title);

    if (eraseButton2) {
      if (dateField && groupField && grouping && displayFunction && chartType) {
        const tempData = await apiGetMetrics(
          moduleID,
          baseURL,
          filters,
          dateField,
          groupField,
          grouping,
          displayFunction,
          chartType
        );
      }
    }
  }, [
    dataToUse,
    dateField,
    groupField,
    groupFieldStatus,
    grouping,
    xValuesUsed,
    yValuesUsed,
    commonTitles,
    noDateDataColumnStatus,
    filterStatus,
    eraseButton,
    eraseButton2,
    displayFunction,
    sumField,
  ]);

  useEffect(() => {
    setChartType(null);
    setGroupField(null);
    setDateField(null);
    setDisplayFunction(null);
    setGrouping(null);
    setSumField(null);
    setGroupMode("stacked");
    setColor("nivo");
    setStartAngle(0);
    setEndAngle(360);
    setInnerRadius(0.3);
    setInterpolation("natural");
    setLayout("vertical");
    setLineGroupMode(true);
    setChartBase64("");
    // setDateFieldOptions([]);
    // setGroupFieldOptions([]);
  }, [moduleID]);

  useEffect(() => {
    setXValuesUsed(true);
    setYValuesUsed(false);
    setNoDateDataColumnStatus(false);
    invertGroupFieldStatus();
  }, [groupField]);

  useEffect(() => {
    let childxAxisField;
    let xAxisFieldToUse = dateField;

    if (dateField?.includes("-")) {
      let tempXAxisField = dateField.split(" - ");

      xAxisFieldToUse = tempXAxisField[0];
      childxAxisField = tempXAxisField[1];
    }

    let dependendDate = false;

    if (
      childxAxisField &&
      dateTypes.includes(
        columns
          .filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
          ?.DEP_FIELDS.filter((field) => field?.F_ID === childxAxisField)?.[0]
          ?.F_TYPE
      )
    ) {
      dependendDate = true;
    }

    let childGroupField;
    let groupFieldToUse = groupField;

    if (groupField?.includes("-")) {
      let tempGroupField = groupField.split(" - ");

      groupFieldToUse = tempGroupField[0];
      childGroupField = tempGroupField[1];
    }
    let dependendNumber = false;

    if (
      childGroupField &&
      numberTypes.includes(
        columns
          .filter((column) => column?.F_ID === groupFieldToUse)?.[0]
          ?.DEP_FIELDS.filter((field) => field?.F_ID === childGroupField)?.[0]
          ?.F_TYPE
      )
    ) {
      dependendNumber = true;
    }

    const dateFieldType = columns.filter(
      (column) => column?.F_ID === xAxisFieldToUse
    )?.[0]?.F_TYPE;
    const groupFieldType = columns.filter(
      (column) => column?.F_ID === groupFieldToUse
    )?.[0]?.F_TYPE;

    if (
      sumFieldOptions?.length &&
      ["sum", "pctSum"].includes(displayFunction)
    ) {
      if (dateTypes.includes(dateFieldType) || dependendDate) {
        if (groupField) {
          if (sumField) {
            setSumField(sumField);
          } else {
            setSumField(sumFieldOptions?.[0]?.id);
          }
        } else {
          setSumField("");
          if (!numberTypes.includes(groupFieldType) || dependendNumber) {
            setDisplayFunction("");
          }
        }
      } else {
        setSumField("");
        if (!numberTypes.includes(groupFieldType) && !dependendNumber) {
          setDisplayFunction("");
        }
      }
    }
  }, [dateField, groupField, displayFunction]);

  // const { tempDateFieldOptions, tempGroupFieldOptions } = createOptions(
  //   dataToUse,
  //   columns,
  //   foreignKeyValues,
  //   staticOptions,
  //   role,
  //   privileges
  // );

  useEffect(() => {
    if (dataToUse.length) {
      const {
        tempDateFieldOptions,
        tempGroupFieldOptions,
        tempSumFieldOptions,
      } = createOptions(
        dataToUse,
        columns,
        foreignKeyValues,
        staticOptions,
        role,
        privileges,
        numberTypes
      );

      setDateFieldOptions(tempDateFieldOptions);
      setGroupFieldOptions(tempGroupFieldOptions);
      setSumFieldOptions(tempSumFieldOptions);
    }
  }, [dataToUse, privileges]);

  const updateFilters = (changes, config) => {
    if (config === "update") {
      const tempfilters = createFilters(filters[moduleID], changes, columns);
      setFilters({ [moduleID]: { ...tempfilters } });
    }

    if (config === "erase") {
      setFilters({ [moduleID]: {} });
    }

    invertFilterStatus();
  };

  useEffect(() => {
    if (width > 1525) {
      setChartParamMenuVisible(true);
    }
  }, [width]);

  return (
    <Flex
      key={`${width} - ${chartParamMenuVisible}-${sumField}`}
      flexDirection={"column"}
    >
      <Navigation paramMenu={paramMenu} paramMenuTitle={paramMenuTitle} />
      <BackgroundWrapper>
        <ModuleWrapper>
          <Banner
            key={moduleParams?.T_TITLE}
            title={moduleParams?.T_TITLE ?? ""}
            path={`/module/${moduleParams.ME_MODULEID}`}
            reportIconVisible={reportIconVisible}
            moduleID={moduleID}
          />
          <div
            style={{
              paddingTop: "6.5rem",
              width: "100%",
            }}
          >
            {isLoading ? (
              <Card
                style={{
                  borderRadius: "10px",
                  paddingBottom: -8,
                  margin: 10,
                  minHeight: 78,
                }}
                bodyStyle={{ display: "flex", justifyContent: "center" }}
              >
                <Spin />
              </Card>
            ) : (
              <Flex flexDirection={width < 1525 ? "row:" : "column"}>
                {chartParamMenuVisible && (
                  <UltimateChartSettings
                    dateFieldOptions={dateFieldOptions}
                    groupFieldOptions={groupFieldOptions}
                    sumFieldOptions={sumFieldOptions}
                    chartVisible={chartVisible}
                    invertChartVisibility={invertChartVisibility}
                    chartData={chartData}
                    changeChartParamMenuVisible={changeChartParamMenuVisible}
                    eraseButton={eraseButton}
                    setEraseButton={setEraseButton}
                    eraseButton2={eraseButton2}
                    setEraseButton2={setEraseButton2}
                    columns={columns}
                  />
                )}
                {chartVisible && (
                  <UltimateChartBody
                    key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-${yValuesUsed}-BarOpt`}
                    chartData={chartData}
                    columns={columns}
                    filters={filters[moduleID]}
                    foreignKeyValues={foreignKeyValues}
                    staticOptions={staticOptions}
                    updateFilters={updateFilters}
                    updateValuesUsed={updateValuesUsed}
                    yValuesUsed={yValuesUsed}
                    xValuesUsed={xValuesUsed}
                    noDateDataColumnStatus={noDateDataColumnStatus}
                    updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
                    privileges={privileges}
                    chartParamMenuVisible={chartParamMenuVisible}
                    changeChartParamMenuVisible={changeChartParamMenuVisible}
                    chartTitle={chartTitle}
                  />
                )}
              </Flex>
            )}
          </div>
        </ModuleWrapper>
      </BackgroundWrapper>
    </Flex>
  );
};

export default UltimateChart;
