const htmlCodesRemover = (value, sliceNumber = 100, type = "display") => {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === "object") {
    return value;
  }

  let splitValue = value
    .split("&#39;")
    .filter((item) => item !== "")
    .join("'")
    .split("&#34;")
    .filter((item) => item !== "")
    .join(`"`);

  if (splitValue && splitValue.includes("&#10;")) {
    let newSplitValue = splitValue.split("&#10;").filter((item) => item !== "");

    if (type === "input") {
      let newValue = "";
      newSplitValue.forEach((item) => {
        if (newValue !== "") {
          newValue += "\n";
        }
        newValue += item;
      });
      return newValue;
    }

    const newValue = newSplitValue.map((item) => (
      <p key={item}>
        {item}
        <br />
      </p>
    ));
    if (newValue.length > sliceNumber) {
      return newValue.slice(0, sliceNumber) + "...";
    } else {
      return newValue;
    }
  }

  if (splitValue && splitValue.includes("\n")) {
    let newSplitValue = splitValue.split("\n").filter((item) => item !== "");
    const newValue = newSplitValue.map((item) => (
      <p key={item}>
        {item}
        <br />
      </p>
    ));
    if (newValue.length > sliceNumber) {
      return newValue.slice(0, sliceNumber) + "...";
    } else {
      return newValue;
    }
  }

  if (splitValue.length > sliceNumber) {
    return splitValue.slice(0, sliceNumber) + "...";
  } else {
    return splitValue;
  }

  // return splitValue;

  // if (value && value.includes("&#39;")) {
  //   let splitValue = value
  //     .split("&#39;")
  //     .filter((item) => item !== "")
  //     .join("'");
  //   if (splitValue && splitValue.includes("&#10;")) {
  //     let newSplitValue = value.split("&#10;").filter((item) => item !== "");
  //     const newValue = newSplitValue.map((item) => (
  //       <p key={item}>
  //         {item}
  //         <br />
  //       </p>
  //     ));
  //     if (newValue.length > 100) {
  //       return newValue.slice(0, 100) + "...";
  //     } else {
  //       return newValue;
  //     }
  //   }

  //   return splitValue;
  // } else if (value && value.includes("&#10;")) {
  //   let newSplitValue = value.split("&#10;").filter((item) => item !== "");
  //   const newValue = newSplitValue.map((item) => (
  //     <p key={item}>
  //       {item}
  //       <br />
  //     </p>
  //   ));

  //   return newValue;
  // } else {
  //   return value;
  // }
};

export default htmlCodesRemover;
