import { Input, ColorPicker, Card, Checkbox, Button, Tooltip } from "antd";
import { useState, useEffect } from "react";
import Flex from "../../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../../store/useStores";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";

const ColorNumber = ({ addChange, data = [], config }) => {
  const [alertInfoChanges, setAlertInfoChanges] = useState({});
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [range1ColorRgb, setRange1ColorRgb] = useState("rgb(255,255,255)");
  const [range2ColorRgb, setRange2ColorRgb] = useState("rgb(255,255,255)");
  const [range3ColorRgb, setRange3ColorRgb] = useState("rgb(255,255,255)");
  const [range4ColorRgb, setRange4ColorRgb] = useState("rgb(255,255,255)");
  const [range1Checked, setRange1Checked] = useState(true);
  const [range2Checked, setRange2Checked] = useState(true);
  const [range3Checked, setRange3Checked] = useState(true);
  const [range4Checked, setRange4Checked] = useState(true);

  useEffect(() => {
    if (data?.RENDEROPTIONS?.RANGE1?.type === "font") {
      setRange1Checked(false);
    }

    if (data?.RENDEROPTIONS?.RANGE2?.type === "font") {
      setRange2Checked(false);
    }

    if (data?.RENDEROPTIONS?.RANGE3?.type === "font") {
      setRange3Checked(false);
    }

    if (data?.RENDEROPTIONS?.RANGE4?.type === "font") {
      setRange4Checked(false);
    }
  }, []);

  const addAlertInfoChange = (id, value, suffix) => {
    const tempData = data?.RENDEROPTIONS ?? {};

    const tempDataIDinfo = tempData?.[id] ?? {};
    let temp = {
      ...tempData,
      ...alertInfoChanges,
      [id]: { ...tempDataIDinfo, ...alertInfoChanges[id], [suffix]: value },
    };

    if (temp?.[id]?.REFMODULE) {
      if (temp?.[id]?.value) {
        delete temp[id].value;
      }
    }

    setAlertInfoChanges(temp);
    addChange("RENDEROPTIONS", temp);
  };

  const clearColor = (id) => {
    const tempData = {
      ...data?.RENDEROPTIONS,
      ...alertInfoChanges,
      [id]: {
        value: null,
        color: "#FFFFFF",
        type: "background",
      },
    };

    setAlertInfoChanges(tempData);
    addChange("RENDEROPTIONS", tempData);
  };

  useEffect(() => {
    if (config === "edit") {
      setRange1ColorRgb(
        hexToRgb(data?.RENDEROPTIONS?.RANGE1?.color ?? "#ffffff")
      );
      setRange2ColorRgb(
        hexToRgb(data?.RENDEROPTIONS?.RANGE2?.color ?? "#ffffff")
      );

      setRange3ColorRgb(
        hexToRgb(data?.RENDEROPTIONS?.RANGE3?.color ?? "#ffffff")
      );
      setRange4ColorRgb(
        hexToRgb(data?.RENDEROPTIONS?.RANGE4?.color ?? "#ffffff")
      );
    }
  }, []);

  const rgbToHex = (color) => {
    const rgb = (color.r << 16) | (color.g << 8) | (color.b << 0);
    return "#" + (0x1000000 + rgb).toString(16).slice(1);
  };

  const hexToRgb = (hex) => {
    const normal = hex?.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) {
      const colorArray = normal.slice(1).map((e) => parseInt(e, 16));
      return `rgb(${colorArray[0]},${colorArray[1]},${colorArray[2]})`;
    }

    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) {
      const colorArray = shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));
      return `rgb(${colorArray[0]},${colorArray[1]},${colorArray[2]})`;
    }

    return null;
  };

  return (
    <Flex
      flexDirection={"row"}
      style={{
        gap: "0.5rem",
        justifyItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <p
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "flex-end",
        }}
      />
      <Card>
        <Flex
          flexDirection={"row"}
          style={{ marginTop: "1rem", width: "max-content" }}
        >
          <div className="modal-text">
            <p>{commonTitles["CO|1|RANGE"]} 1:</p>
            <p>{commonTitles["CO|1|RANGE"]} 2:</p>
            <p>{commonTitles["CO|1|RANGE"]} 3:</p>
            <p>{commonTitles["CO|1|RANGE"]} 4:</p>
          </div>
          <div className="modal-input">
            <Flex
              flexDirection={"row"}
              style={{ gap: 5, justifyItems: "center", marginLeft: 12 }}
            >
              <p>{commonTitles["CO|1|FROM"]}:</p>
              <div className="select">
                <Input
                  style={{
                    width: 60,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: -5,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange("RANGE1", e.target.value, "from");
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.RANGE1?.from ?? null
                      : null
                  }
                />
              </div>
              <p>{commonTitles["CO|1|TO"]}:</p>
              <div className="select">
                <Input
                  style={{
                    width: 60,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: -5,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange("RANGE1", e.target.value, "to");
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.RANGE1?.to ?? null
                      : null
                  }
                />
              </div>
              <ColorPicker
                format={"rgb"}
                value={range1ColorRgb}
                style={{ marginLeft: 15, marginTop: -5 }}
                onChange={(e) => {
                  setRange1ColorRgb(e);
                  addAlertInfoChange("RANGE1", rgbToHex(e.metaColor), "color");
                }}
              />
              <Checkbox
                style={{ marginTop: 0, marginLeft: 10 }}
                checked={range1Checked ? true : false}
                onChange={(e) => {
                  setRange1Checked(!range1Checked);
                  addAlertInfoChange(
                    "RANGE1",
                    e.target.checked ? "background" : "font",
                    "type"
                  );
                }}
              >
                {commonTitles["CO|1|CLRINBCK"]}
              </Checkbox>
              <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
                <Button
                  className="dropdown"
                  shape="round"
                  icon={
                    <FormatColorResetIcon
                      style={{ fontSize: 18, marginTop: 0 }}
                    />
                  }
                  onClick={() => {
                    clearColor("RANGE1");
                    setRange1ColorRgb("rgb(255,255,255)");
                    setRange1Checked(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#6082B6",
                    border: "none",
                  }}
                />
              </Tooltip>
            </Flex>

            <Flex
              flexDirection={"row"}
              style={{
                gap: 5,
                justifyItems: "center",
                marginLeft: 12,
                marginTop: 7,
              }}
            >
              <p>{commonTitles["CO|1|FROM"]}:</p>
              <div className="select">
                <Input
                  style={{
                    width: 60,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: -5,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange("RANGE2", e.target.value, "from");
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.RANGE2?.from ?? null
                      : null
                  }
                />
              </div>
              <p>{commonTitles["CO|1|TO"]}:</p>
              <div className="select">
                <Input
                  style={{
                    width: 60,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: -5,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange("RANGE2", e.target.value, "to");
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.RANGE2?.to ?? null
                      : null
                  }
                />
              </div>
              <ColorPicker
                format={"rgb"}
                value={range2ColorRgb}
                style={{ marginLeft: 15, marginTop: -5 }}
                onChange={(e) => {
                  setRange2ColorRgb(e);
                  addAlertInfoChange("RANGE2", rgbToHex(e.metaColor), "color");
                }}
              />
              <Checkbox
                style={{ marginTop: 0, marginLeft: 10 }}
                checked={range2Checked ? true : false}
                onChange={(e) => {
                  setRange2Checked(!range2Checked);
                  addAlertInfoChange(
                    "RANGE2",
                    e.target.checked ? "background" : "font",
                    "type"
                  );
                }}
              >
                {commonTitles["CO|1|CLRINBCK"]}
              </Checkbox>
              <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
                <Button
                  className="dropdown"
                  shape="round"
                  icon={
                    <FormatColorResetIcon
                      style={{ fontSize: 18, marginTop: 0 }}
                    />
                  }
                  onClick={() => {
                    clearColor("RANGE2");
                    setRange2ColorRgb("rgb(255,255,255)");
                    setRange2Checked(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#6082B6",
                    border: "none",
                  }}
                />
              </Tooltip>
            </Flex>

            <Flex
              flexDirection={"row"}
              style={{
                gap: 5,
                justifyItems: "center",
                marginLeft: 12,
                marginTop: 6,
              }}
            >
              <p>{commonTitles["CO|1|FROM"]}:</p>
              <div className="select">
                <Input
                  style={{
                    width: 60,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: -5,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange("RANGE3", e.target.value, "from");
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.RANGE3?.from ?? null
                      : null
                  }
                />
              </div>
              <p>{commonTitles["CO|1|TO"]}:</p>
              <div className="select">
                <Input
                  style={{
                    width: 60,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: -5,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange("RANGE3", e.target.value, "to");
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.RANGE3?.to ?? null
                      : null
                  }
                />
              </div>
              <ColorPicker
                format={"rgb"}
                value={range3ColorRgb}
                style={{ marginLeft: 15, marginTop: -5 }}
                onChange={(e) => {
                  setRange3ColorRgb(e);
                  addAlertInfoChange("RANGE3", rgbToHex(e.metaColor), "color");
                }}
              />
              <Checkbox
                style={{ marginTop: 0, marginLeft: 10 }}
                checked={range3Checked ? true : false}
                onChange={(e) => {
                  setRange3Checked(!range3Checked);
                  addAlertInfoChange(
                    "RANGE3",
                    e.target.checked ? "background" : "font",
                    "type"
                  );
                }}
              >
                {commonTitles["CO|1|CLRINBCK"]}
              </Checkbox>
              <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
                <Button
                  className="dropdown"
                  shape="round"
                  icon={
                    <FormatColorResetIcon
                      style={{ fontSize: 18, marginTop: 0 }}
                    />
                  }
                  onClick={() => {
                    clearColor("RANGE3");
                    setRange3ColorRgb("rgb(255,255,255)");
                    setRange3Checked(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#6082B6",
                    border: "none",
                  }}
                />
              </Tooltip>
            </Flex>

            <Flex
              flexDirection={"row"}
              style={{
                gap: 5,
                justifyItems: "center",
                marginLeft: 12,
                marginTop: 6,
              }}
            >
              <p>{commonTitles["CO|1|FROM"]}:</p>
              <div className="select">
                <Input
                  style={{
                    width: 60,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: -5,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange("RANGE4", e.target.value, "from");
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.RANGE4?.from ?? null
                      : null
                  }
                />
              </div>
              <p>{commonTitles["CO|1|TO"]}:</p>
              <div className="select">
                <Input
                  style={{
                    width: 60,
                    marginLeft: 5,
                    marginRight: 5,
                    marginTop: -5,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange("RANGE4", e.target.value, "to");
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.RANGE4?.to ?? null
                      : null
                  }
                />
              </div>
              <ColorPicker
                format={"rgb"}
                value={range4ColorRgb}
                style={{ marginLeft: 15, marginTop: -5 }}
                onChange={(e) => {
                  setRange4ColorRgb(e);
                  addAlertInfoChange("RANGE4", rgbToHex(e.metaColor), "color");
                }}
              />
              <Checkbox
                style={{ marginTop: 0, marginLeft: 10 }}
                checked={range4Checked ? true : false}
                onChange={(e) => {
                  setRange4Checked(!range4Checked);
                  addAlertInfoChange(
                    "RANGE4",
                    e.target.checked ? "background" : "font",
                    "type"
                  );
                }}
              >
                {commonTitles["CO|1|CLRINBCK"]}
              </Checkbox>
              <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
                <Button
                  className="dropdown"
                  shape="round"
                  icon={
                    <FormatColorResetIcon
                      style={{ fontSize: 18, marginTop: 0 }}
                    />
                  }
                  onClick={() => {
                    clearColor("RANGE4");
                    setRange4ColorRgb("rgb(255,255,255)");
                    setRange4Checked(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#6082B6",
                    border: "none",
                  }}
                />
              </Tooltip>
            </Flex>
          </div>
        </Flex>
      </Card>
    </Flex>
  );
};

export default ColorNumber;
