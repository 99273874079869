const config = (set) => ({
  modTitles: {},
  setModTitles: (newItem) => set(() => ({ modTitles: newItem })),
});

const ModTitlesStore = {
  config,
  shouldLog: true,
};

export default ModTitlesStore;
