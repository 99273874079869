import { useState } from "react";
import Button from "@mui/material/Button";
import ReactTooltip from "react-tooltip";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "./import.css";
import {
  useAuthStore,
  useCommonTitlesStore,
  useImportStore,
} from "../../../../store/useStores";
import { ImportModal } from "./ImportModal";
import apiImport from "../../../../api/endpoints/apiImport";
import { message } from "antd";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";
import ConfirmationModal from "./Confirmation Modal";

const Import = ({ moduleID }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);
  const baseURL = useAuthStore((state) => state.baseURL);
  const projectID = useAuthStore((state) => state.projectID);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const file = useImportStore((state) => state.file);
  const setFile = useImportStore((state) => state.setFile);
  const confirmationMessage = useImportStore(
    (state) => state.confirmationMessage
  );
  const setConfirmationMessage = useImportStore(
    (state) => state.setConfirmationMessage
  );

  const showImportModal = () => {
    setImportModalVisible(true);
  };

  const onSave = async (changes) => {
    const fmData = new FormData();
    fmData.append("file", changes?.[0]);
    fmData.append("moduleid", moduleID);
    fmData.append("projectid", projectID);

    // for (var pair of fmData.entries()) {
    //   console.log(pair);
    // }

    const response = await apiImport(baseURL, fmData, commonTitles);

    if (response?.STATUS === "Success") {
      setImportModalVisible(false);
      displayResponseMessage(commonTitles["CO|1|UPLDFILE"], 5, "success");
      window.location.reload();
    } else if (response?.STATUS === "Confirmation") {
      const responseMsg = response?.MESSAGE ?? {};
      let template = commonTitles[responseMsg.ID];

      Object.entries(responseMsg)
        .filter(([key]) => key !== "ID")
        .forEach(([key, value]) => {
          var re = new RegExp(key, "g");
          template = template.replace(re, value);
        });
      setImportModalVisible(false);
      setConfirmationModalVisible(true);
      setConfirmationMessage(template);
      setFile(changes);
    } else if (!changes?.[0]) {
      displayResponseMessage(commonTitles["CO|1|NOFILE"], 5, "warning");
    } else {
      const responseMsg = response?.MESSAGE ?? {};
      let template = commonTitles[responseMsg.ID];

      Object.entries(responseMsg)
        .filter(([key]) => key !== "ID")
        .forEach(([key, value]) => {
          var re = new RegExp(key, "g");
          template = template.replace(re, value);
        });

      //message.error(template, [5]);
      displayResponseMessage(template, 5, "warning");
    }

    // setImportModalVisible(false);
  };

  const onConfirm = async (file) => {
    const fmData = new FormData();
    fmData.append("file", file?.[0]);
    fmData.append("moduleid", moduleID);
    fmData.append("projectid", projectID);
    fmData.append("confirmed", true);

    // for (var pair of fmData.entries()) {
    //   console.log(pair);
    // }

    const response = await apiImport(baseURL, fmData, commonTitles);

    if (response?.STATUS === "Success") {
      setConfirmationModalVisible(false);
      displayResponseMessage(commonTitles["CO|1|UPLDFILE"], 5, "success");
      setConfirmationMessage("");
      setFile({});
      window.location.reload();
    } else {
      const responseMsg = response?.MESSAGE ?? {};
      let template = commonTitles[responseMsg.ID];

      Object.entries(responseMsg)
        .filter(([key]) => key !== "ID")
        .forEach(([key, value]) => {
          var re = new RegExp(key, "g");
          template = template.replace(re, value);
        });

      //message.error(template, [5]);
      displayResponseMessage(template, 5, "warning");
    }
  };

  const onCancel = (config) => {
    if (config === "import") {
      setImportModalVisible(false);
    }

    if (config === "confirmation") {
      setConfirmationModalVisible(false);
      setConfirmationMessage("");
      setFile({});
    }
  };

  return (
    <div className="upload">
      <a
        data-tip={`${commonTitles.importTitle} ${commonTitles.from} ${commonTitles.file}`}
      >
        <Button sx={{ color: bannerTextColor }} onClick={showImportModal}>
          {<FileUploadIcon />}
        </Button>
      </a>
      <ReactTooltip place="bottom" />
      <ImportModal
        visible={importModalVisible}
        onSave={onSave}
        onCancel={onCancel}
      />
      <ConfirmationModal
        visible={confirmationModalVisible}
        file={file}
        confirmationMessage={confirmationMessage}
        onSave={onConfirm}
        onCancel={onCancel}
      />
    </div>
  );
};

export default Import;
