import Flex from "../../../../../helpers/components/Flex";
import { Input, Select, Image } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../../store/useStores";
import { useState, useEffect } from "react";
import apiGetLanguagesList from "../../../../../api/endpoints/apiGetLanguagesList";
import countryPhoneCodes from "../../../../module/helpers/countryPhoneCodes";

const { Option } = Select;

const PhoneType = ({
  value,
  id,
  config,
  required,
  addChange,
  canupdate,
  canupdateifnull,
  row,
  autoCompletedFields = {},
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const userID = useAuthStore((state) => state.userID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [validPhone, setValidPhone] = useState(true);
  const [countries, setCountries] = useState([]);
  const [changes, setChanges] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const serverData = await apiGetLanguagesList(
        baseURL,
        language,
        workspaceID
      );

      const temp = Object.values(serverData.flagfiles).map((flag) => {
        const countryName = flag.split(".")[0] ?? "";
        const phoneCode =
          countryPhoneCodes.filter(
            (country) => country.name === countryName
          )?.[0]?.code ?? "";
        return { name: countryName, phoneCode: phoneCode, flagName: flag };
      });
      setCountries(temp);
    };

    fetchData();
  }, []);

  const checkPhoneValidity = (phone) => {
    if (phone) {
      setValidPhone(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone));
    } else {
      setValidPhone(true);
    }
  };

  const createValue = (inputValue, mode) => {
    if (mode === "code") {
      if (changes?.phone) {
        if (inputValue) {
          const newValue = inputValue + " " + changes.phone;
          addChange(id, newValue, null);
        } else {
          if (changes?.code) {
            const newValue = changes?.phone ?? null;
            addChange(id, newValue, null);
          } else if (value) {
            if (value.includes(" ")) {
              const newValue = value?.split(" ")[1].trim();
              addChange(id, newValue, null);
            } else {
              const newValue = value;
              addChange(id, newValue, null);
            }
          }
        }
      } else if (value) {
        if (inputValue) {
          if (value.includes(" ")) {
            const newValue = inputValue + " " + value?.split(" ")[1].trim();
            addChange(id, newValue, null);
          } else {
            const newValue = inputValue + " " + value;
            addChange(id, newValue, null);
          }
        } else {
          if (value.includes(" ")) {
            const newValue = value?.split(" ")[1].trim();
            addChange(id, newValue, null);
          } else {
            const newValue = inputValue + " " + value;
            addChange(id, newValue, null);
          }
        }
      } else {
        if (config === "filters") {
          addChange(id, inputValue, null);
        }
      }
    } else {
      if (inputValue) {
        let newValue = null;
        if (changes?.code) {
          newValue = changes.code + " " + inputValue;
        } else if (value?.split(" ")[0]) {
          newValue = value?.split(" ")[0] + " " + inputValue;
        } else {
          newValue = inputValue;
        }
        addChange(id, newValue, null);
      } else {
        addChange(id, null, null);
      }
    }
  };

  let newValue = value;
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    newValue = autoCompletedFields[id];
  }

  let isDisabled = false;
  if (config !== "filters") {
    if (Object.keys(autoCompletedFields).includes(id)) {
      isDisabled = true;
    } else {
      if (canupdate === false) {
        if (canupdateifnull === true) {
          if (value.length) {
            if (row[`${id}-US`] !== userID) {
              isDisabled = true;
            }
          }
        } else {
          isDisabled = true;
        }
      }
    }
  }

  return (
    <Flex flexDirection={"column"}>
      <Flex
        flexDirection={"row"}
        style={{ alignContent: "center", width: 300 }}
      >
        <Select
          style={{
            width: "40%",
            marginLeft: 8,
            marginTop: 7,
          }}
          showSearch={true}
          onChange={(e) => {
            setChanges({ ...changes, code: e });
            createValue(e, "code");
          }}
          filterOption={(iv, op) => {
            let opToUse = op.key ? op.key : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          defaultValue={
            newValue?.includes("+") ? newValue.split(" ")?.[0] ?? null : null
          }
          placeholder={commonTitles["CO|1|OPTIONAL"]}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option value={""} />
          {countries
            ?.filter((country) => country.phoneCode && country.flagName)
            ?.map((item) => (
              <Option
                className="option"
                key={item?.phoneCode}
                value={item?.phoneCode}
                //label={item?.name ?? ""}
              >
                <Flex
                  flexDirection={"row"}
                  style={{ gap: "1rem", alignItems: "center" }}
                >
                  <Image
                    width={20}
                    src={require(`../../../../../assets/Flags/${item?.flagName}`)}
                    alt={item?.name ?? ""}
                    preview={false}
                  />
                  <p>{`${item?.phoneCode}`}</p>
                </Flex>
              </Option>
            ))}
        </Select>
        <Input
          type="text"
          style={{
            border: "1.25px solid",
            borderColor: "rgb(182, 180, 180)",
            borderRadius: 5,
            fontFamily: "Arial, Helvetica, sans-serif",
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            fontSize: 14,
            letterSpacing: "0.03em",
            height: 32,
            //minWidth: 158,
            width: "60%",
          }}
          defaultValue={
            value?.includes("+") ? value.split(" ")?.[1] ?? null : value
          }
          onChange={(e) => {
            checkPhoneValidity(e.target.value);
            setChanges({ ...changes, phone: e.target.value });
            createValue(e.target.value, "phone");
          }}
        />
        {required === "Y" && (
          <p style={{ color: "grey", marginTop: 8 }}>
            <WarningOutlined />
          </p>
        )}
      </Flex>
      {!validPhone && !isDisabled && config !== "filters" && (
        <p
          style={{
            marginTop: "0.5rem",
            marginLeft: "0.5rem",
            fontStyle: "italic",
            color: "red",
          }}
        >
          {commonTitles["CO|1|INVLDPHONE"]}
        </p>
      )}
    </Flex>
  );
};

export default PhoneType;
