export const tagInputStyle = {
  width: 150,
  height: 30,
  marginInlineEnd: 8,
  verticalAlign: "center",
  marginTop: -5,
};

export const tagPlusStyle = {
  height: 30,
  background: "default",
  borderStyle: "dashed",
  maringTop: 0,
  paddingTop: 5,
};

export const addFieldButtonStyle = {
  border: "dashed 0.5px #E5E4E2",
  backgroundColor: "transparent",
  fontSize: 12,
  heigth: 22,
  padding: 3,
  paddingTop: 0,
  paddingBottom: 0,
};

export const addFunctionButtonStyle = {
  border: "dashed 0.5px #E5E4E2",
  backgroundColor: "transparent",
  fontSize: 12,
  heigth: 22,
  padding: 3,
  paddingTop: 0,
  paddingBottom: 0,
};

export const eraseButtonStyle = {
  border: "none",
  backgroundColor: "transparent",
  color: "#6082B6",
};

export const tagStyle = {
  userSelect: "none",
  marginLeft: 0,
  marginRight: 0,
  padding: 0,
  fontSize: 15,
  paddingLeft: 2,
  paddingRight: 2,
};

export const editInputStyle = {
  width: 150,
  height: 30,
  marginInlineEnd: 8,
  verticalAlign: "center",
  marginTop: -5,
  letterSpacing: 2,
};
