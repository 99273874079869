import axios from "axios";

const apiChangeTheme = async (baseURL, darkTheme) => {
  const url = `${baseURL}/Users_api/change_theme`;

  let darkThemeValue = "Y";

  if (!darkTheme) {
    darkThemeValue = "N";
  }

  try {
    const response = await axios.post(
      url,
      { darktheme: darkThemeValue },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Users_api/change_theme: response.data: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiChangeTheme;
