import Flex from "../../../helpers/components/Flex";
import { useAuthStore } from "../../../store/useStores";

const NavigationWrapper = ({ children }) => {
  const navigationColor = useAuthStore((state) => state.navigationColor);

  const style = {
    alignItems: "center",
    position: "fixed",
    width: "100%",
    zIndex: 5,
    padding: "0.5rem 3rem",
    background: navigationColor,
    height: 60,
  };

  return (
    <Flex flexDirection={"row"} style={style}>
      {children}
    </Flex>
  );
};

export default NavigationWrapper;
