import Flex from "../../../../../helpers/components/Flex";
import { Input } from "antd";
import validateValue from "../numberType/validateValue";
import { useState } from "react";
import { useCommonTitlesStore } from "../../../../../store/useStores";

const CountingField = ({
  value,
  id,
  config,
  addChange,
  dependentField,
  decimal,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [status, setStatus] = useState(null);

  let maxLengthInt = "1000";
  let maxLengthDec = decimal;

  const checkValueValiditiy = (id, value, suffix) => {
    let type = "2";
    if (decimal && decimal !== "0") {
      type = "3";
    }

    const isValid = validateValue(value, type);
    if (isValid) {
      if (type === "2") {
        addChange(id, value, suffix);
      }

      if (type === "3") {
        if (value.includes(".")) {
          const int = value.split(".")?.[0];
          const decimal = value.split(".")?.[1];
          if (int.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else if (decimal.length > parseInt(maxLengthDec)) {
            setStatus("error");
          } else {
            addChange(id, value, suffix);
            setStatus(null);
          }
        }

        if (value.includes(",")) {
          const int = value.split(",")?.[0];
          const decimal = value.split(",")?.[1];
          if (int.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else if (decimal.length > parseInt(maxLengthDec)) {
            setStatus("error");
          } else {
            addChange(id, value, suffix);
            setStatus(null);
          }
        }

        if (!value.includes(".") && !value.includes(",")) {
          if (value.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else {
            addChange(id, value, suffix);
            setStatus(null);
          }
        }
      }
    } else {
      setStatus("error");
    }
  };

  if (config === "filters") {
    return (
      <Flex flexDirection={"column"}>
        <Flex flexDirection={"row"}>
          <div
            className="datepicker-text"
            style={{ width: 27, display: "flex", justifyContent: "right" }}
          >
            <p>{commonTitles["CO|1|FROM"]}:</p>
          </div>
          <Input
            type="text"
            status={status}
            style={{
              border: "1.25px solid",
              borderColor: !status && "rgb(182, 180, 180)",
              borderRadius: 5,
              fontFamily: "Arial, Helvetica, sans-serif",
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
              fontSize: 14,
              letterSpacing: "0.03em",
              height: 30,
              width: 240,
            }}
            //maxLength={type === "2" ? maxLengthInt : null}
            defaultValue={
              dependentField ? value?.[dependentField]?.from : value?.from ?? ""
            }
            onChange={(e) => {
              if (dependentField) {
                addChange(
                  id,
                  { [dependentField]: e.target.value.replace(/,/g, ".") },
                  "from"
                );
              } else {
                checkValueValiditiy(
                  id,
                  e.target.value.replace(/,/g, "."),
                  "from"
                );
              }
            }}
          />
        </Flex>
        <Flex flexDirection={"row"}>
          <div
            className="datepicker-text"
            style={{ width: 27, display: "flex", justifyContent: "right" }}
          >
            <p>{commonTitles["CO|1|TO"]}:</p>
          </div>
          <Input
            type="text"
            status={status}
            style={{
              border: "1.25px solid",
              borderColor: !status && "rgb(182, 180, 180)",
              borderRadius: 5,
              fontFamily: "Arial, Helvetica, sans-serif",
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
              fontSize: 14,
              letterSpacing: "0.03em",
              height: 30,
              width: 240,
            }}
            //maxLength={type === "2" ? maxLengthInt : null}
            defaultValue={
              dependentField ? value?.[dependentField]?.to : value?.to ?? ""
            }
            onChange={(e) => {
              if (dependentField) {
                addChange(
                  id,
                  { [dependentField]: e.target.value.replace(/,/g, ".") },
                  "to"
                );
              } else {
                checkValueValiditiy(
                  id,
                  e.target.value.replace(/,/g, "."),
                  "to"
                );
              }
            }}
          />
        </Flex>
      </Flex>
    );
  } else if (config === "copy") {
    return (
      <Input
        placeholder={commonTitles["CO|1|DFIELDMSG"]}
        disabled={true}
        style={{ width: 278, height: 34 }}
      />
    );
  } else {
    return (
      <Input
        value={value ?? ""}
        disabled={true}
        style={{ width: 278, height: 34 }}
      />
    );
  }
};

export default CountingField;
