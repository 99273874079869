function getConfigMenu(modTitles) {
  const configMenu = [
    {
      title: `${modTitles.menu}`,
      path: "/config/menu",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.commonData} & ${modTitles.modules}`,
      path: "/config/modules",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.alertConfig}`,
      path: "/config/alerts",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.emailsConfig}`,
      path: "/config/emails",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.userRoles}`,
      path: "/config/user_roles",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.users}`,
      path: "/config/users",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.languages}`,
      path: "/config/languages",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.projects}`,
      path: "/config/projects",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.clients}`,
      path: "/config/clients",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.consultants}`,
      path: "/config/consultants",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.sites}`,
      path: "/config/sites",
      cName: "dropdown-link",
    },
    {
      title: `${modTitles.systemTitles}`,
      path: "/config/system_titles",
      cName: "dropdown-link",
    },
  ];
  return configMenu;
}

export default getConfigMenu;
