import "../../../css/newRegisterModal.css";
import { WarningOutlined } from "@ant-design/icons";
import Flex from "../../../../../../../helpers/components/Flex";
import { Input } from "antd";

const { TextArea } = Input;

const TextareaType = ({
  id,
  required,
  addChange,
  autoCompletedFields,
  size,
}) => {
  let value = null;
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    value = autoCompletedFields[id];
  }

  return (
    <Flex flexDirection={"row"}>
      <TextArea
        type="text"
        defaultValue={value}
        disabled={value ? true : false}
        style={{ minWidth: 275, paddingTop: 5, minHeight: 100 }}
        onChange={(e) => addChange(id, e.target.value, null)}
        maxLength={size ? size : null}
      />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 20 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default TextareaType;
