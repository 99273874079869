import { message } from "antd";

const displayResponseMessage = (messageToDisplay, duration, type) => {
  if (type === "info") {
    message.info(messageToDisplay, [duration]);
  }

  if (type === "success") {
    message.success(messageToDisplay, [duration]);
  }

  if (type === "error") {
    message.error(messageToDisplay, [duration]);
  }

  if (type === "warning") {
    message.warning(messageToDisplay, [duration]);
  }
};

export default displayResponseMessage;
