import { Input, Modal, Select, Button, Checkbox, Spin } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore, useAuthStore } from "../../../store/useStores";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody, TableHead } from "@mui/material";
import apiGetProjectModuleFields from "../../../api/endpoints/apiGetProjectModuleFields";

const { Option } = Select;

const FieldModalContent = ({
  fields,
  headers,
  updateFields,
  allChecked,
  selectAll,
  project,
  module,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Flex
      flexDirection={"column"}
      style={{ marginTop: "2rem", marginBottom: "2rem" }}
    >
      <h3
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <p>{`${commonTitles["CO|1|PROJECT"]}: ${project}`}</p>
        <p>{`${commonTitles["CO|1|MODULE"]}: ${module}`}</p>
      </h3>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "60vh", marginTop: "2rem" }}
        className="testing_table"
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  style={{ fontWeight: 700, color: "rgb(8, 8, 46)" }}
                  key={header.id}
                >
                  <Flex
                    flexDirection={"row"}
                    style={{
                      gap: "0.5rem",
                      justifyContent: index > 0 ? "center" : "left",
                    }}
                  >
                    {index === 1 && (
                      <Checkbox
                        checked={allChecked}
                        onChange={(e) => selectAll(e.target.checked)}
                      />
                    )}
                    {header.title}
                  </Flex>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item) => (
              <TableRow key={item.ID}>
                <TableCell>{item.FIELDTITLE}</TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={item.ACTIVE}
                    onChange={(e) =>
                      updateFields(item.ID, "ACTIVE", e.target.checked)
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  {item.ACTIVE && (
                    <Input
                      defaultValue={item.PROJECTFIELDTITLE}
                      onChange={(e) =>
                        updateFields(
                          item.ID,
                          "PROJECTFIELDTITLE",
                          e.target.value
                        )
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export const FieldModal = ({
  visible,
  title,
  onSave,
  onCancel,
  projectID,
  moduleID,
  project,
  module,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const [fields, setFields] = useState([]);
  const [titles, setTitles] = useState({});
  const [headers, setHeaders] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(async () => {
    const { fields, moduletitles } = await apiGetProjectModuleFields(
      baseURL,
      projectID,
      moduleID
    );
    setFields(fields);
    setTitles(moduletitles);
    setHeaders([
      { id: "FIELDTITLE", title: moduletitles["FD|292|TITLE"] },
      { id: "ACTIVE", title: moduletitles["FD|293|TITLE"] },
      { id: "PROJECTFIELDTITLE", title: moduletitles["FD|294|TITLE"] },
    ]);

    let includedAll = true;

    fields.forEach((item) => {
      if (!item.ACTIVE) {
        includedAll = false;
      }
    });
    setAllChecked(includedAll);
  }, [projectID, moduleID]);

  const updateFields = (fieldID, id, value) => {
    const newState = fields.map((item) => {
      if (item.ID === fieldID) {
        return { ...item, [id]: value };
      }

      return item;
    });

    setFields(newState);
  };

  const selectAll = (value) => {
    const newState = fields.map((item) => {
      return { ...item, ACTIVE: value };
    });

    setFields(newState);
    setAllChecked(value);
  };

  return (
    <Modal
      key={`userRoleFieldRights-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        minWidth: "60vw",
      }}
      bodyStyle={{ paddingLeft: 80, paddingRight: 80 }}
      title={title}
      onOk={() => onSave(projectID, moduleID, fields)}
      onCancel={() => onCancel()}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(projectID, moduleID, fields)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      {fields?.length ? (
        <FieldModalContent
          projectID={projectID}
          moduleID={moduleID}
          headers={headers}
          allChecked={allChecked}
          fields={fields}
          updateFields={updateFields}
          selectAll={selectAll}
          project={project}
          module={module}
        />
      ) : (
        <Spin />
      )}
    </Modal>
  );
};
