import { Modal, Button, Card } from "antd";
import { useEffect, useState } from "react";
import Flex from "../../../../../helpers/components/Flex";
import {
  useAdminStore,
  useCommonTitlesStore,
} from "../../../../../store/useStores";
import StepContent from "../StepContent";

export const EditFieldModal = ({
  visible,
  headers,
  data,
  bodyData,
  fields,
  refModules,
  staticOptions,
  onSave,
  onCancel,
  config,
  showDeleteModal,
  currentModuleID,
}) => {
  const [changes, setChanges] = useState({});
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const moduleID = useAdminStore((state) => state.moduleID);
  const [type, setType] = useState(data.F_TYPE);

  const addChange = (id, value) => {
    if (typeof id === "object") {
      setChanges({ ...changes, [id[0]]: value[0], [id[1]]: value[1] });
    } else {
      setChanges({ ...changes, [id]: value });
    }
  };

  useEffect(() => {
    setChanges({ F_MODULEID: moduleID });
  }, []);

  const changeType = (value) => {
    setType(value);
    // setChanges({
    //   T_TITLE: data?.T_TITLE ?? "",
    //   F_MODULEID: changes?.F_MODULEID,
    //   F_LASTMODIFIED: data?.F_LASTMODIFIED,
    //   F_TYPE: value,
    //   F_ORDER: data?.F_ORDER,
    // });
    setChanges({ ...data, ...changes, F_TYPE: value, F_RENDER: "" });
  };

  return (
    <Modal
      key={"new-item"}
      mask={false}
      maskClosable={false}
      open={visible}
      headers={headers}
      style={{
        marginTop: "-11vh",
        display: "flex",
        marginLeft: "20vw",
        width: "max-content",
      }}
      title={commonTitles["CO|1|EDITFLD"]}
      onOk={() => onSave(changes)}
      onDelete={() => showDeleteModal(data.F_ID)}
      onCancel={() => onCancel(config)}
      footer={[
        <Button key="back" onClick={() => onCancel(config)}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,

        <Button
          key="delete"
          style={{
            backgroundColor: config === "instantEdit" ? "grey" : "red",
            color: "white",
            display: config === "instantEdit" && "none",
          }}
          onClick={() => showDeleteModal(data.F_ID)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,

        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(changes)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <Flex
        flexDirection={"column"}
        style={{
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <Card
          bodyStyle={{
            height: "55vh",
            display: "flex",
            width: "60vw",
            overflowY: "auto",
            justifyContent: "center",
          }}
        >
          <StepContent
            current={1}
            type={type}
            changeType={changeType}
            changes={changes}
            addChange={addChange}
            fields={fields}
            staticOptions={staticOptions}
            refModules={refModules}
            config={"edit"}
            data={data}
            bodyData={bodyData}
            currentModuleID={currentModuleID}
          />
        </Card>
      </Flex>
    </Modal>
  );
};
