import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";

const createOptions = (
  dataToUse,
  columns,
  foreignKeyValues,
  staticOptions,
  role,
  privileges,
  numberTypes
) => {
  let tempDateFieldOptions = [];

  let viewRights = {};
  columns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  if (dataToUse.length) {
    columns
      .filter((column) => viewRights[column.F_ID])
      .forEach((column) => {
        if (column.F_TYPE === "30") {
          column.DEP_FIELDS.forEach((field) => {
            if (
              field.F_TYPE !== "1" &&
              field.F_TYPE !== "24" &&
              field.F_TYPE !== "25" &&
              field.F_TYPE !== "26" &&
              field.F_TYPE !== "27" &&
              field.F_TYPE !== "29"
            )
              tempDateFieldOptions.push({
                id: `${column.F_ID} - ${field.F_ID}`,
                view: `${column.T_TITLE} - ${field.TITLE}`,
              });
          });
        } else if (
          column.F_TYPE !== "1" &&
          column.F_TYPE !== "24" &&
          column.F_TYPE !== "25" &&
          column.F_TYPE !== "26" &&
          column.F_TYPE !== "27" &&
          column.F_TYPE !== "29" &&
          viewRights[column.F_ID]
        ) {
          tempDateFieldOptions.push({
            id: column.F_ID,
            view: htmlCodesRemover(column.T_TITLE) ?? column.T_TITLE,
          });
        }
      });
  }

  //console.log("tempDateFieldOptions: ", tempDateFieldOptions);

  let tempGroupFieldOptions = [];

  if (dataToUse.length) {
    columns
      .filter((column) => viewRights[column.F_ID])
      .forEach((column) => {
        if (column.F_TYPE === "30") {
          column.DEP_FIELDS.forEach((field) => {
            if (
              field.F_TYPE !== "1" &&
              field.F_TYPE !== "4" &&
              field.F_TYPE !== "5" &&
              field.F_TYPE !== "24" &&
              field.F_TYPE !== "25" &&
              field.F_TYPE !== "26" &&
              field.F_TYPE !== "27" &&
              field.F_TYPE !== "29"
            )
              tempGroupFieldOptions.push({
                id: `${column.F_ID} - ${field.F_ID}`,
                view: `${column.T_TITLE} - ${field.TITLE}`,
              });
          });
        } else if (
          column.F_TYPE !== "1" &&
          column.F_TYPE !== "4" &&
          column.F_TYPE !== "5" &&
          column.F_TYPE !== "24" &&
          column.F_TYPE !== "25" &&
          column.F_TYPE !== "26" &&
          column.F_TYPE !== "27" &&
          column.F_TYPE !== "29" &&
          viewRights[column.F_ID]
        ) {
          tempGroupFieldOptions.push({
            id: column.F_ID,
            view: htmlCodesRemover(column.T_TITLE) ?? column.T_TITLE,
          });
        }
      });
  }

  let tempSumFieldOptions = [];

  if (dataToUse.length) {
    columns
      .filter((column) => viewRights[column.F_ID])
      .forEach((column) => {
        // if (column.F_TYPE === "30") {
        //   column.DEP_FIELDS.forEach((field) => {
        //     if (numberTypes.includes(field?.F_TYPE)) {
        //       tempSumFieldOptions.push({
        //         id: `${column.F_ID} - ${field.F_ID}`,
        //         view: `${column.T_TITLE} - ${field.TITLE}`,
        //       });
        //     }
        //   });
        // } else
        if (numberTypes.includes(column.F_TYPE) && viewRights[column.F_ID]) {
          tempSumFieldOptions.push({
            id: column.F_ID,
            view: htmlCodesRemover(column.T_TITLE) ?? column.T_TITLE,
          });
        }
      });
  }

  return { tempDateFieldOptions, tempGroupFieldOptions, tempSumFieldOptions };
};

export default createOptions;
