import "./consultantsConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

const ConsultantsConfigHead = ({ headers }) => {
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell key={"ghost-cell"} width="10%" />
        {headers.map((item) => (
          <TableCell key={item.F_ID} className="admin-header">
            <p key={`${item.T_TITLE} - text`}>{item.T_TITLE}</p>
          </TableCell>
        ))}
        <TableCell width="10%" />
      </TableRow>
    </TableHead>
  );
};

export default ConsultantsConfigHead;
