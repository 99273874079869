import "../../../css/newRegisterModal.css";
import { WarningOutlined } from "@ant-design/icons";
import Flex from "../../../../../../../helpers/components/Flex";
import FileUpload from "../../../fileUpload/FileUpload";

const ImageType = ({ id, required, addChange }) => {
  return (
    <Flex flexDirection={"row"}>
      <FileUpload
        files={[]}
        addChange={addChange}
        id={id}
        multiple={false}
        accept={".jpg, .jpeg, .png"}
      />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 11, marginLeft: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default ImageType;
