import { useCommonTitlesStore } from "../../../../../store/useStores";
import "../../../css/ultimateTable.css";

const RenderColor = ({ value }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <>
      {["Εκπρόθεσμο", "Expired"].includes(value.view) ? (
        <p className="expired">{value.view}</p>
      ) : (
        <p className="soon-to-expire">{value.view}</p>
      )}
    </>
  );
};

export default RenderColor;
