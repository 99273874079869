import Flex from "../../../../../helpers/components/Flex";
import { Input } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../../store/useStores";
import { useState } from "react";

const EmailType = ({
  value,
  id,
  config,
  required,
  addChange,
  canupdate,
  canupdateifnull,
  row,
  autoCompletedFields = {},
}) => {
  const userID = useAuthStore((state) => state.userID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [validEmail, setValidEmail] = useState(true);

  const checkEmailValidity = (email) => {
    if (email) {
      setValidEmail(/\S+@\S+\.\S+/.test(email));
    } else {
      setValidEmail(true);
    }
  };

  let newValue = value;
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    newValue = autoCompletedFields[id];
  }

  let isDisabled = false;
  if (config !== "filters") {
    if (Object.keys(autoCompletedFields).includes(id)) {
      isDisabled = true;
    } else {
      if (canupdate === false) {
        if (canupdateifnull === true) {
          if (value.length) {
            if (row[`${id}-US`] !== userID) {
              isDisabled = true;
            }
          }
        } else {
          isDisabled = true;
        }
      }
    }
  }

  return (
    <Flex flexDirection={"column"}>
      <Flex flexDirection={"row"}>
        <Input
          type="text"
          style={{
            border: "1.25px solid",
            borderColor: "rgb(182, 180, 180)",
            borderRadius: 5,
            fontFamily: "Arial, Helvetica, sans-serif",
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            fontSize: 14,
            letterSpacing: "0.03em",
            height: 30,
            minWidth: 275,
          }}
          defaultValue={newValue ?? ""}
          onChange={(e) => {
            if (config !== "filters") {
              checkEmailValidity(e.target.value);
            }
            addChange(id, e.target.value, null);
          }}
          disabled={isDisabled}
        />
        {required === "Y" && (
          <p style={{ color: "grey", marginTop: 8 }}>
            <WarningOutlined />
          </p>
        )}
      </Flex>
      {!validEmail && !isDisabled && config !== "filters" && (
        <p
          style={{
            marginTop: "0.5rem",
            marginLeft: "0.5rem",
            fontStyle: "italic",
            color: "red",
          }}
        >
          {commonTitles["CO|1|INVLDEMAIL"]}
        </p>
      )}
    </Flex>
  );
};

export default EmailType;
