import { Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import CopyModal from "../../helpers/modals/copyModal/CopyModal";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";
import axios from "axios";
import {
  transformDataForServer,
  convertDataFromServer,
  tranformChangesforServer,
} from "../../../module/helpers/converters";
import { useState } from "react";
import copyFiles from "../fileManagement/copyFiles";
import deleteFiles from "../fileManagement/deleteFiles";
import uploadFiles from "../fileManagement/uploadFiles";
import apiGetValueColor from "../../../../api/endpoints/apiGetValueColor";

const CopyAction = ({
  row,
  rowID,
  moduleID,
  columns,
  config,
  title,
  foreignKeyValues,
  staticOptions,
  privileges,
  canCopy,
  dataToUse,
  originalData,
  insertData,
  autoCompletedFields,
  updateTotals,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const userID = useAuthStore((state) => state.userID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const language = useAuthStore((state) => state.language);
  const [changes, setChanges] = useState({});
  const [copyModalVisible, setCopyModalVisible] = useState(false);

  let attachColumns = [];
  let automaticFieldColumns = [];
  let renderAlertColumns = [];

  columns.forEach((column) => {
    if (column.F_TYPE === "24" || column.F_TYPE === "25") {
      attachColumns.push(column.F_ID);
    }
    if (column.F_TYPE === "29") {
      automaticFieldColumns.push(column.F_ID);
    }
    if (column.F_RENDER === "ALERT") {
      renderAlertColumns.push(column.F_ID);
    }
  });

  const showCopyModal = () => {
    const rowToCopy = Object.fromEntries(
      Object.entries(row).filter(([key]) => attachColumns.includes(key))
    );
    let temp = {};
    Object.entries(rowToCopy).forEach(([key, value]) => {
      temp[key] = [];
      value.forEach((item) => {
        let newItem = { name: item.name, uid: item.uid, action: "C" };
        temp[key].push(newItem);
      });
    });
    setChanges({ ...temp });
    setCopyModalVisible(true);
  };

  const addCopyChange = (id, value, config) => {
    if (value && value !== "" && value.length !== 0) {
      if (attachColumns.includes(id)) {
        if (changes[id]) {
          if (config === "add") {
            let fileExists = [];
            for (let i = 0; i < value.length; i++) {
              changes[id].forEach((file) => {
                if (file.uid === value[i].uid) {
                  fileExists[i] = true;
                }
              });
            }

            for (let i = 0; i < value.length; i++) {
              !fileExists[i] &&
                setChanges({
                  ...changes,
                  [id]: [...changes[id], value[i]],
                });
            }
          } else if (config === "add to delete") {
            let newValue = changes[id].filter((item) => item.uid !== value.id);
            setChanges({ ...changes, [id]: newValue });
          } else {
            let tempChanges = [];
            changes[id].forEach((file) => tempChanges.push(file));
            tempChanges = tempChanges.filter((file) => file.uid !== value.uid);
            if (tempChanges.length) {
              setChanges({ ...changes, [id]: [...tempChanges] });
            } else {
              delete changes[id];
              setChanges({ ...changes });
            }
          }
        } else {
          if (config === "add to delete") {
            setChanges({ ...changes, [id]: [value] });
          } else {
            setChanges({
              ...changes,
              [id]: value,
            });
          }
        }
      } else {
        if (
          (!originalData[rowID][id] ||
            originalData[rowID][id] === "" ||
            originalData[rowID][id]?.length === 0) &&
          (!value || value === "" || value?.length === 0)
        ) {
          delete changes[id];
          setChanges({ ...changes });
        } else {
          setChanges({ ...changes, [id]: value });
        }
      }
    } else {
      if (attachColumns.includes(id)) {
        if (!row[id]?.length) {
          if (!changes[id]) {
            delete changes[id];
            setChanges({ ...changes });
          }
        }
      } else {
        if (!Object.keys(row).includes(id)) {
          delete changes[id];
          setChanges({ ...changes });
        } else {
          setChanges({ ...changes, [id]: value });
        }
      }
    }
  };

  const onCopySave = async (id, changes, config) => {
    const url = `${baseURL}/Data_api/save`;

    let filesToCopy = {};
    let filesToUpload = {};
    attachColumns.forEach((id) => {
      filesToCopy = { ...filesToCopy, [id]: [] };
      filesToUpload = { ...filesToUpload, [id]: [] };

      if (changes[id]) {
        let tempFilesToCopy = changes[id].filter(
          (file) => "action" in file && file.action === "C"
        );
        filesToCopy[id].push(...tempFilesToCopy);

        let tempFilesToUpload = changes[id].filter(
          (file) => !("action" in file)
        );
        filesToUpload[id].push(...tempFilesToUpload);
      }
    });

    const { copyFileTracker, copyStatus } = await copyFiles(
      filesToCopy,
      attachColumns,
      baseURL,
      commonTitles
    );

    const { uploadFileTracker, uploadStatus } = await uploadFiles(
      filesToUpload,
      attachColumns,
      baseURL,
      commonTitles
    );

    if (uploadStatus === "Success" && copyStatus === "Success") {
      let fileTracker = {};
      for (let i = 0; i < attachColumns.length; i++) {
        let id = attachColumns[i];
        fileTracker = {
          ...fileTracker,
          [id]: [...uploadFileTracker[id], ...copyFileTracker[id]],
        };
      }
      //console.log("onSuccess: fileTracker: ", fileTracker);

      const rowToCopy = Object.fromEntries(
        Object.entries(row).filter(
          ([key]) =>
            !automaticFieldColumns.includes(key) &&
            !attachColumns.includes(key) &&
            key !== "rowID"
        )
      );
      //console.log("onCopySave: rowToCopy: ", rowToCopy);

      let renderAlertColorValues = {};

      for (let i = 0; i < renderAlertColumns.length; i++) {
        const colorValue = await apiGetValueColor(
          baseURL,
          renderAlertColumns[i],
          changes?.[renderAlertColumns[i]] ?? ""
        );

        renderAlertColorValues = {
          ...renderAlertColorValues,
          [renderAlertColumns[i]]: {
            value: changes?.[renderAlertColumns[i]] ?? "",
            color: colorValue?.COL ?? ["#ffffff", "B"],
          },
        };
      }

      const transformedChanges = tranformChangesforServer(
        changes,
        columns,
        dataToUse,
        rowID,
        foreignKeyValues,
        fileTracker,
        baseURL
      );
      //console.log("onCopySave: transformedChanges: ", transformedChanges);

      const dataForServer = transformDataForServer(
        rowID,
        originalData,
        columns,
        staticOptions,
        foreignKeyValues,
        "copy"
      );
      //console.log("onCopySave: dataForServer: ", dataForServer);

      const tempData = await convertDataFromServer(
        columns,
        rowID,
        originalData,
        staticOptions,
        foreignKeyValues,
        transformedChanges,
        "copy",
        userID,
        renderAlertColorValues
      );
      //console.log("onCopySave: tempData: ", tempData);

      try {
        const response = await axios.post(
          url,
          { moduleid: moduleID, ...dataForServer, ...transformedChanges },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("insertData: response: ", response?.data);
        if (response?.data?.STATUS === "Success") {
          //message.success(commonTitles["CO|1|REGMSG1"], [3]);
          displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
          const changes = {
            [response?.data?.ROWID]: {
              ["LM"]: response?.data?.TIMESTAMP,
              [response?.data?.AUTOID?.FIELDID]: response?.data?.AUTOID?.VALUE,
              ...rowToCopy,
              ...tempData,
            },
          };
          insertData(changes);
          updateTotals(response?.data?.totals);
          setChanges({});
          setCopyModalVisible(false);
        } else {
          const responseMsg = response?.data?.MESSAGE ?? {};
          let template = commonTitles[responseMsg.ID];
          Object.entries(responseMsg)
            .filter(([key]) => key !== "ID")
            .forEach(([key, value]) => {
              var re = new RegExp(key, "g");
              template = template.replace(re, value);
            });
          //message.warning(template, [5]);
          displayResponseMessage(template, 5, "warning");
        }
      } catch (err) {
        //message.error(err, [3]);
        displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
      }
    } else {
      deleteFiles(uploadFileTracker, attachColumns, baseURL, commonTitles);
    }
  };

  const onCopyCancel = () => {
    setChanges({});
    setCopyModalVisible(false);
  };

  if (canCopy && config !== "alerts") {
    return (
      <>
        <Button
          className="infocard-btn"
          style={{
            marginTop: 15,
            marginLeft: language === "EL" ? 80 : 50,
          }}
          shape="round"
          icon={<CopyOutlined />}
          size="small"
          onClick={showCopyModal}
        >
          {commonTitles["CO|1|COPY"]}
        </Button>
        <CopyModal
          visible={copyModalVisible}
          title={title}
          row={row}
          columns={columns}
          staticOptions={staticOptions}
          foreignKeyValues={foreignKeyValues}
          onSave={onCopySave}
          onCancel={onCopyCancel}
          changes={changes}
          addChange={addCopyChange}
          privileges={privileges}
          autoCompletedFields={autoCompletedFields}
          moduleID={moduleID}
        />
      </>
    );
  } else {
    return null;
  }
};

export default CopyAction;
