import Flex from "../../../../../helpers/components/Flex";
import FileUpload from "../../../../module/banner/newRegister/fileUpload/FileUpload";
import { WarningOutlined } from "@ant-design/icons";

const ImageType = ({
  value,
  id,
  addChange,
  canupdate,
  canupdateifnull,
  config,
  required,
}) => {
  return (
    <Flex flexDirection={"row"}>
      <FileUpload
        files={value ?? []}
        addChange={addChange}
        id={id}
        multiple={false}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        accept={".jpg, .jpeg, .png"}
      />
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 11, marginLeft: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default ImageType;
