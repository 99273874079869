import Flex from "../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../store/useStores";

const RenderColor = ({ value, config }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  if (config !== "alerts") {
    return (
      <Flex flexDirection={"row"}>
        <p className="p-context">{value}</p>
        {value === "1" ? (
          <p className="very-low">(Πολύ Χαμηλή)</p>
        ) : value === "2" ? (
          <p className="low">(Χαμηλή)</p>
        ) : value === "3" ? (
          <p className="medium">(Μεσαία)</p>
        ) : value === "4" ? (
          <p className="high">(Υψηλή)</p>
        ) : (
          <p className="very-high">(Πολύ Υψηλή)</p>
        )}
      </Flex>
    );
  } else {
    return (
      <>
        {value.view === commonTitles["CO|1|EXPIRED"] ? (
          <p className="very-high">{commonTitles["CO|1|EXPIRED"]}</p>
        ) : (
          <p className="high">{commonTitles["CO|1|TOBEEXPRD"]}</p>
        )}
      </>
    );
  }
};

export default RenderColor;
