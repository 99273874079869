import React from "react";
import { Card, Button, Badge, Tooltip } from "antd";
import {
  SettingOutlined,
  CloseOutlined,
  TableOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import ChartBodySettings from "../ChartBodySettings";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { useState } from "react";
import Bar from "./Bar";
import createDataToShow from "../../UC helpers/createDataToShow";
import createChartConfigurations from "../../UC helpers/createChartConfigurations";
import { UltimateChartTableModal } from "../table/UltimateChartTable";
//import { FiltersModal } from "../../UC helpers/UC modals";
import FiltersModal from "../../../ultimateTable/helpers/modals/filtersModal/FiltersModal";
import Flex from "../../../../helpers/components/Flex";
import { useEffect } from "react";
import domtoimage from "dom-to-image";
import timeout from "../../../../helpers/functions/timeout";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";
import { EyeOutlined } from "@ant-design/icons";

const BarOpt = ({
  chartData,
  columns,
  filters,
  foreignKeyValues,
  staticOptions,
  updateFilters,
  updateValuesUsed,
  yValuesUsed,
  xValuesUsed,
  noDateDataColumnStatus,
  updateNoDateDataColumnStatus,
  privileges,
  chartParamMenuVisible,
  changeChartParamMenuVisible,
  chartTitle,
}) => {
  const chartType = useChartStore((state) => state.chartType);
  const groupMode = useChartStore((state) => state.groupMode);
  const grouping = useChartStore((state) => state.grouping);
  const groupField = useChartStore((state) => state.groupField);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const layout = useChartStore((state) => state.layout);
  const [filtersModalVisible, setFiltersModalVisible] = useState(false);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();
  const [chartCardWidth, setChartCardWidth] = useState("");

  const setChartBase64 = useChartStore((state) => state.setChartBase64);

  const chartSettingsVisible = useChartStore(
    (state) => state.chartSettingsVisible
  );
  const setChartSettingsVisible = useChartStore(
    (state) => state.setChartSettingsVisible
  );
  const [chartTableVisible, setChartTableVisible] = useState(false);

  const onCancel = () => {
    setChartTableVisible(false);
  };

  const applyFilters = (changes, config) => {
    updateFilters(changes, config);
    setFiltersModalVisible(false);
  };
  const cancelFiltersModal = () => {
    setFiltersModalVisible(false);
  };
  const eraseFilters = () => {
    updateFilters(null, "erase");
    setFiltersModalVisible(false);
  };

  let groupFieldToUse;
  let childGroupField = null;
  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    const parentGroupField = tempGroupField[0];
    childGroupField = tempGroupField[1];
    groupFieldToUse = parentGroupField;
  } else {
    groupFieldToUse = groupField;
  }

  const groupFieldColumn = columns.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  if (childGroupField) {
    const childTitle = groupFieldColumn.DEP_FIELDS.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
  }

  //console.log("BarOpt: chartData: ", chartData);

  const dataToShow = createDataToShow(
    chartData,
    chartType,
    grouping,
    displayFunction
  );

  //console.log("BarOpt: dataToShow: ", dataToShow);

  const { indexBy, keys, max, total } = createChartConfigurations(
    dataToShow,
    chartType,
    displayFunction,
    groupMode
  );

  // console.log("BarOpt: indexBy: ", indexBy);
  // console.log("BarOpt: keys: ", keys);

  const filterCount = filters ? Object.keys(filters).length : 0;

  useEffect(async () => {
    await timeout(600);
    const elem = document.getElementById("barChart");

    domtoimage
      .toPng(elem)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;

        // var link = document.createElement("a");
        // link.download = "my-image-name.jpeg";
        // link.href = dataUrl;
        // link.click();

        const getBase64StringFromDataURL = (dataURL) =>
          dataURL.replace("data:", "").replace(/^.+,/, "");

        fetch(img.src)
          .then((res) => res.blob())
          .then((blob) => {
            // Read the Blob as DataURL using the FileReader API
            const reader = new FileReader();
            reader.onloadend = () => {
              //console.log("reader.result: ", reader.result);

              // Convert to Base64 string
              const base64 = getBase64StringFromDataURL(reader.result);
              // console.log("base64: ", base64);
              setChartBase64(base64);
            };
            reader.readAsDataURL(blob);
          });
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  }, [dataToShow, layout]);

  useEffect(() => {
    let tempChartCardWidth;

    if (width < 1525) {
      if (chartSettingsVisible) {
        if (chartParamMenuVisible) {
          tempChartCardWidth = "62vw";
        } else {
          tempChartCardWidth = "77.5vw";
        }
      } else {
        if (chartParamMenuVisible) {
          tempChartCardWidth = "82.5vw";
        } else {
          tempChartCardWidth = "98vw";
        }
      }
    } else {
      if (chartSettingsVisible) {
        tempChartCardWidth = "80vw";
      } else {
        tempChartCardWidth = "100vw";
      }
    }

    setChartCardWidth(tempChartCardWidth);
  }, [width, chartSettingsVisible, chartParamMenuVisible]);

  return (
    <Flex
      key={`${width} - ${displayFunction}-${max}`}
      flexDirection={"column"}
      style={{ marginTop: width < 1525 ? "1rem" : 0 }}
    >
      {dataToShow.length && (
        <Flex flexDirection={"row"}>
          {chartSettingsVisible && (
            <Card
              style={{
                width: "20vw",
                height: width < 1525 ? "80vh" : "70vh",
                borderRadius: "10px",
                marginLeft: "9px",
                marginRight: "9px",
                marginTop: "-3px",
              }}
            >
              <Button
                style={{
                  backgroundColor: "rgb(8, 8, 46)",
                  color: "#fff",
                  width: 50,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
                shape="round"
                icon={<CloseOutlined />}
                onClick={() => setChartSettingsVisible(false)}
              />
              <ChartBodySettings
                columns={columns}
                updateValuesUsed={updateValuesUsed}
                yValuesUsed={yValuesUsed}
                xValuesUsed={xValuesUsed}
                config="bar"
                noDateDataColumnStatus={noDateDataColumnStatus}
                updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
                keys={keys}
              />
            </Card>
          )}
          <Card
            key={`${width} - ${displayFunction}`}
            style={{
              borderRadius: "10px",
              marginRight: "9px",
              marginTop: "-3px",
              marginBottom: "10px",
              marginLeft: !chartSettingsVisible && "10px",
              width: chartCardWidth,
              height: width < 1525 ? "80vh" : "70vh",
            }}
          >
            <Flex flexDirection={"row"}>
              <div className="bar-opt">
                <Flex flexDirection={"row"}>
                  {!chartParamMenuVisible && (
                    <Tooltip
                      placement="right"
                      title={commonTitles["CO|1|SHOWCHPAR"]}
                    >
                      <Button
                        style={{
                          backgroundColor: "rgb(8, 8, 46)",
                          color: "#fff",
                          width: 50,
                          paddingTop: 5,
                          paddingBottom: 5,
                          marginRight: "0.3rem",
                        }}
                        shape="round"
                        icon={<EyeOutlined />}
                        onClick={() => changeChartParamMenuVisible(true)}
                      />
                    </Tooltip>
                  )}
                  {!chartSettingsVisible && (
                    <Tooltip
                      placement="bottom"
                      title={commonTitles["CO|1|CONFIG"]}
                    >
                      <Button
                        style={{
                          backgroundColor: "rgb(8, 8, 46)",
                          color: "#fff",
                          width: 50,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                        shape="round"
                        icon={<SettingOutlined />}
                        onClick={() => setChartSettingsVisible(true)}
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    placement="right"
                    title={commonTitles["CO|1|FILTERS"]}
                  >
                    <Button
                      style={{
                        backgroundColor: "rgb(8, 8, 46)",
                        color: "#fff",
                        //width: 50,
                        paddingTop: 5,
                        paddingBottom: 5,
                        marginLeft: 5,
                      }}
                      shape="round"
                      icon={<FilterOutlined />}
                      onClick={() => setFiltersModalVisible(true)}
                    >
                      {filters && filterCount > 0 && (
                        <Badge
                          size="small"
                          style={{
                            marginLeft: 2,
                            marginBottom: 5,
                            paddingBottom: 2,
                            backgroundColor: "rgb(8, 8, 46)",
                            border: "1px solid rgb(8, 8, 46)",
                          }}
                          count={filterCount}
                        />
                      )}
                    </Button>
                  </Tooltip>
                  {filtersModalVisible && (
                    <FiltersModal
                      visible={filtersModalVisible}
                      columns={columns}
                      onSave={applyFilters}
                      onCancel={cancelFiltersModal}
                      onErase={eraseFilters}
                      filters={filters}
                      foreignKeyValues={foreignKeyValues}
                      staticOptions={staticOptions}
                      privileges={privileges}
                    />
                  )}
                </Flex>
              </div>

              {!chartTableVisible && (
                <div className="bar-opt-end">
                  <Tooltip
                    placement="left"
                    title={commonTitles["CO|1|CDATATABLE"]}
                  >
                    <Button
                      style={{
                        backgroundColor: "rgb(8, 8, 46)",
                        color: "#fff",
                        width: 50,
                        height: 32,
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                      shape="round"
                      icon={<TableOutlined />}
                      onClick={() => setChartTableVisible(true)}
                    />
                  </Tooltip>
                </div>
              )}
            </Flex>
            <p
              style={{
                fontWeight: 600,
                fontSize: 12,
                marginLeft: 200,
                marginBottom: 0,
              }}
            >
              {chartTitle}
            </p>
            <div
              key={`${width} - ${displayFunction}-${max}`}
              id="barChart"
              className="chart__display"
            >
              <Bar
                key={max}
                indexBy={indexBy}
                keys={keys}
                data={dataToShow}
                total={total}
                max={max}
                columns={columns}
                staticOptions={staticOptions}
              />
            </div>
          </Card>

          {chartTableVisible && (
            <UltimateChartTableModal
              visible={chartTableVisible}
              dataToShow={dataToShow}
              onCancel={onCancel}
            />
          )}
        </Flex>
      )}
      {!dataToShow.length && (
        <Flex flexDirection={"row"}>
          <Card
            style={{
              borderRadius: "10px",
              marginRight: "9px",
              marginTop: "-15px",
              marginBottom: "10px",
              marginLeft: "10px",
              width: "98.5vw",
              display: "flex",
              justifyContent: "flex-start",
              height: "50vh",
            }}
          >
            <Flex flexDirection={"row"} style={{ gap: "28vw" }}>
              <Tooltip placement="right" title={commonTitles["CO|1|FILTERS"]}>
                <Button
                  style={{
                    backgroundColor: "rgb(8, 8, 46)",
                    color: "#fff",
                    //width: 50,
                    paddingTop: 5,
                    paddingBottom: 5,
                    marginLeft: 5,
                  }}
                  shape="round"
                  icon={<FilterOutlined />}
                  onClick={() => setFiltersModalVisible(true)}
                >
                  {filters && filterCount > 0 && (
                    <Badge
                      size="small"
                      style={{
                        marginLeft: 2,
                        marginBottom: 5,
                        paddingBottom: 2,
                        backgroundColor: "rgb(8, 8, 46)",
                        border: "1px solid rgb(8, 8, 46)",
                      }}
                      count={filterCount}
                    />
                  )}
                </Button>
              </Tooltip>
              {filtersModalVisible && (
                <FiltersModal
                  visible={filtersModalVisible}
                  columns={columns}
                  onSave={applyFilters}
                  onCancel={cancelFiltersModal}
                  onErase={eraseFilters}
                  filters={filters}
                  foreignKeyValues={foreignKeyValues}
                  staticOptions={staticOptions}
                  privileges={privileges}
                />
              )}
              <div className="chart__display">
                <p>{`${commonTitles["CO|1|CBODYMSG1"]}: ${groupFieldName}`}</p>
              </div>
            </Flex>
          </Card>
        </Flex>
      )}
    </Flex>
  );
};

export default BarOpt;
