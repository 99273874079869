const config = (set) => ({
  alert: null,
  setAlert: (newAlert) => set(() => ({ alert: newAlert })),
});

const AlertMessageStore = {
  config,
  shouldLog: true,
};

export default AlertMessageStore;
