import axios from "axios";

const apiGetAlertsList = async (baseURL) => {
  const url = `${baseURL}/App_alerts_api/list`;
  try {
    const response = await axios.post(
      url,
      {},
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("App_alerts_api/list: response: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiGetAlertsList;
