import Flex from "../../../../../helpers/components/Flex";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../../store/useStores";
import { convertDateFormat } from "../../converters";
import daysLeft from "../../daysLeft";
import { WarningOutlined } from "@ant-design/icons";

const RenderDeadline = ({ value, columns, row }) => {
  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  // const progressID = columns
  //   .filter((column) => column.F_RENDER === "PROGRESS")
  //   .map((item) => item.F_ID)[0];

  // let showCounter = true;
  // if (progressID) {
  //   console.log("row?.[progressID]?.view: ", row?.[progressID]?.view);
  //   if (row?.[progressID]?.view ?? "".split("%")[0] === "100") {
  //     showCounter = false;
  //   }
  // }

  let deadlineMsg = commonTitles["CO|1|DDLINEMSG"];
  let re = new RegExp("<1>", "g");
  deadlineMsg = deadlineMsg.replace(re, daysLeft(value, dateFormatToUse));
  let deadlineCounterText;
  if (daysLeft(value, dateFormatToUse) > 7) {
    deadlineCounterText = <p className="p-footer">( {deadlineMsg} )</p>;
  }

  if (
    daysLeft(value, dateFormatToUse) < 7 &&
    daysLeft(value, dateFormatToUse) >= 0
  ) {
    let deadlineMsg = commonTitles["CO|1|DDLINEMSG"];
    let re = new RegExp("<1>", "g");
    deadlineMsg = deadlineMsg.replace(re, daysLeft(value, dateFormatToUse));
    deadlineCounterText = (
      <Flex flexDirection={"row"}>
        <WarningOutlined
          style={{
            color: "rgb(240, 201, 6)",
            marginRight: 5,
            marginTop: 2,
          }}
        />
        <span className="p-footer-warning">( {deadlineMsg} )</span>
      </Flex>
    );
  }

  if (daysLeft(value, dateFormatToUse) < 0) {
    let expirationMsg = commonTitles["CO|1|EXPIREDCNT"];
    let re = new RegExp("<1>", "g");
    expirationMsg = expirationMsg.replace(
      re,
      -daysLeft(value, dateFormatToUse)
    );
    deadlineCounterText = (
      <Flex flexDirection={"row"}>
        <WarningOutlined
          style={{ color: "red", marginRight: 5, marginTop: 0, width: 10 }}
        />
        <p style={{ fontSize: 10 }} className="p-footer-pastdue">
          ( {expirationMsg} )
        </p>
      </Flex>
    );
  }
  return (
    <Flex flexDirection={"column"}>
      <p className="p-context">{value}</p>
      {deadlineCounterText}
    </Flex>
  );
};

export default RenderDeadline;
