import "./users.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { UsersModal } from "./UsersModal";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tooltip, Button, message } from "antd";
import axios from "axios";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import { useState } from "react";
import { ProjectAccessRightsModal } from "./ProjectAccessRights";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const UsersBody = ({
  data,
  titles,
  roles,
  staticOptions,
  showModal,
  modalVisible,
  modalData,
  updateData,
  handleCancel,
  showDeleteModal,
  resetPassword,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [userID, setUserID] = useState(null);
  const [projectAccessRightsModalVisible, setProjectAccessRightsModalVisible] =
    useState(false);

  const cancelProjectModal = () => setProjectAccessRightsModalVisible(false);

  const saveProjectAccessRights = async (userID, userProjectAccessRights) => {
    const url = `${baseURL}/User_projects_api/save`;

    const projects = userProjectAccessRights
      .filter((item) => item.HASACCESS)
      .map((project) => project.ID);

    try {
      const response = await axios.post(
        url,
        { userid: userID, projects: projects },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("User_projects_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        setProjectAccessRightsModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const onSave = async (changes) => {
    const url = `${baseURL}/Users_api/save`;

    //console.log("changes: ", changes);

    const changesForServer = {
      id: modalData?.USER_ID,
      lastname:
        changes?.lastname !== modalData.USER_LASTNAME &&
        changes?.lastname !== undefined
          ? changes?.lastname
          : modalData.USER_LASTNAME,
      firstname:
        changes?.firstname !== modalData.USER_FIRSTNAME &&
        changes?.firstname !== undefined
          ? changes?.firstname
          : modalData.USER_FIRSTNAME,
      username:
        changes?.username !== modalData.USER_LOGIN &&
        changes?.username !== undefined
          ? changes?.username
          : modalData.USER_LOGIN,
      email:
        changes?.email !== modalData.USER_EMAIL && changes?.email !== undefined
          ? changes?.email
          : modalData.USER_EMAIL,
      smsphone:
        changes?.smsphone !== modalData.USER_SMSPHONE &&
        changes?.smsphone !== undefined
          ? changes?.smsphone
          : modalData.USER_SMSPHONE,
      type:
        changes?.type !== modalData.USER_TYPE && changes?.type !== undefined
          ? changes?.type
          : modalData.USER_TYPE,
      roleid:
        changes?.roleid !== modalData.USER_ROLEID &&
        changes?.roleid !== undefined
          ? changes?.roleid
          : modalData.USER_ROLEID,
      active:
        changes?.active !== modalData.USER_ACTIVE &&
        changes?.active !== undefined
          ? changes?.active
          : modalData.USER_ACTIVE,
      dateformat:
        changes?.dateformat !== modalData.USER_DATEFORMAT?.split(",")[0] &&
        changes?.dateformat !== undefined
          ? changes?.dateformat
          : modalData.USER_DATEFORMAT?.split(",")[0],
      timeformat:
        changes?.timeformat !== modalData.USER_DATEFORMAT?.split(",")[1] &&
        changes?.timeformat !== undefined
          ? changes?.timeformat
          : modalData.USER_DATEFORMAT?.split(",")[1],
      lastmodified: modalData.USER_LASTMODIFIED ?? "",
    };

    //console.log("changesForServer: ", { ...changesForServer });

    try {
      const response = await axios.post(
        url,
        { ...changesForServer },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Users_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        let userRolesTitle = "";
        if (changes.roleid) {
          changes.roleid.split(",").map((item) => {
            if (userRolesTitle !== "") {
              userRolesTitle += ", ";
            }
            userRolesTitle += roles[item];
          });
        } else {
          modalData.USER_ROLEID.split(",").map((item) => {
            if (userRolesTitle !== "") {
              userRolesTitle += ", ";
            }
            userRolesTitle += roles[item];
          });
        }

        const changesWithExtraInfo = {
          ["USER_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          USER_LASTNAME:
            changes?.lastname !== modalData.USER_LASTNAME &&
            changes?.lastname !== undefined
              ? changes?.lastname
              : modalData.USER_LASTNAME,
          USER_FIRSTNAME:
            changes?.firstname !== modalData.USER_FIRSTNAME &&
            changes?.firstname !== undefined
              ? changes?.firstname
              : modalData.USER_FIRSTNAME,
          USER_LOGIN:
            changes?.username !== modalData.USER_LOGIN &&
            changes?.username !== undefined
              ? changes?.username
              : modalData.USER_LOGIN,
          USER_EMAIL:
            changes?.email !== modalData.USER_EMAIL &&
            changes?.email !== undefined
              ? changes?.email
              : modalData.USER_EMAIL,
          USER_SMSPHONE:
            changes?.smsphone !== modalData.USER_SMSPHONE &&
            changes?.smsphone !== undefined
              ? changes?.smsphone
              : modalData.USER_SMSPHONE,
          USER_TYPE:
            changes?.type !== modalData.USER_TYPE && changes?.type !== undefined
              ? changes?.type
              : modalData.USER_TYPE,
          USER_ROLEID:
            changes?.roleid !== modalData.USER_ROLEID &&
            changes?.roleid !== undefined
              ? changes?.roleid
              : modalData.USER_ROLEID,
          ["USER_ROLEID-title"]:
            userRolesTitle ?? modalData["USER_ROLEID-title"],
          USER_ACTIVE:
            changes?.active !== modalData.USER_ACTIVE &&
            changes?.active !== undefined
              ? changes?.active
              : modalData.USER_ACTIVE,
          USER_DATEFORMAT:
            `${changes?.dateformat ?? ""},${changes?.timeformat ?? ""}` !==
            modalData.USER_DATEFORMAT
              ? `${
                  changes?.dateformat !==
                    modalData.USER_DATEFORMAT.split(",")[0] &&
                  changes?.dateformat !== undefined
                    ? changes?.dateformat
                    : modalData.USER_DATEFORMAT.split(",")[0]
                },${
                  changes?.timeformat !==
                    modalData.USER_DATEFORMAT.split(",")[1] &&
                  changes?.timeformat !== undefined
                    ? changes?.timeformat
                    : modalData.USER_DATEFORMAT.split(",")[1]
                }`
              : modalData.USER_DATEFORMAT,
        };
        //console.log("changesWithExtraInfo: ", changesWithExtraInfo);
        updateData("edit", changesWithExtraInfo, modalData.USER_ID);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <TableBody>
      {data.map((item) => (
        <TableRow key={item.USER_ID}>
          <TableCell>
            <Tooltip placement="right" title={titles["MO|6|EDITROW"]}>
              <EditOutlined
                onClick={() => showModal(item, "edit")}
                style={{
                  fontSize: "18px",
                  color: "rgb(8, 8, 46)",
                }}
              />
            </Tooltip>
          </TableCell>
          <TableCell key={`${item?.USER_ID}-lastname`}>
            {item?.USER_LASTNAME}
          </TableCell>
          <TableCell key={`${item?.USER_ID}-firstname`}>
            {item?.USER_FIRSTNAME}
          </TableCell>
          <TableCell key={`${item?.USER_ID}-username`}>
            {item?.USER_LOGIN}
          </TableCell>
          <TableCell key={`${item?.USER_ID}-role`}>
            {item?.USER_TYPE === "S"
              ? titles?.["FO|583|"]
              : titles?.["FO|584|"]}
          </TableCell>
          <TableCell key={`${item?.USER_ID}-rolename`}>
            {item?.["USER_ROLEID-title"]}
          </TableCell>
          <TableCell key={`${item.USER_ID}-status`} align="center">
            {item.USER_ACTIVE === "Y" ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </TableCell>
          <TableCell width={"8%"}>
            <Button
              shape="round"
              className="dropdown"
              onClick={() => {
                setUserID(item.USER_ID);
                setProjectAccessRightsModalVisible(true);
              }}
              style={{ backgroundColor: "rgb(8, 8, 46)", color: "#fff" }}
            >
              {titles?.["MO|6|PROJECTS"]}
            </Button>
          </TableCell>
        </TableRow>
      ))}
      {modalVisible && (
        <UsersModal
          visible={modalVisible}
          data={modalData}
          staticOptions={staticOptions}
          titles={titles}
          roles={roles}
          onSave={onSave}
          onCancel={handleCancel}
          showDeleteModal={showDeleteModal}
          resetPassword={resetPassword}
          config={"edit"}
        />
      )}
      {projectAccessRightsModalVisible && (
        <ProjectAccessRightsModal
          visible={projectAccessRightsModalVisible}
          userID={userID}
          title={titles["MO|6|PROJECTS"]}
          onSave={saveProjectAccessRights}
          onCancel={cancelProjectModal}
        />
      )}
    </TableBody>
  );
};

export default UsersBody;
