import Login from "./login/Login";
import ChangePassword from "./changePassword/ChangePassword";
import Register from "./register/Register";
import React from "react";
import "./header.css";

const Header = ({ formType, changeFormType }) => {
  return (
    <div className="testSite__header section__padding" id="Home">
      <div className="testSite__header-content">
        <h1 className="gradient__text">
          {/* Let's Take Care of Safety in Workspaces */}
          Safety, an element of civilization and sustainability
        </h1>
        <p>A complete Health, Safety and Environment Management Platform</p>

        {/* <div className="testSite__header-content__input">
          <input type="email" placeholder="Your Email Address" />
          <button className="button">Get Started</button>
        </div> */}
      </div>
      <div className="header-form">
        {formType === "register" ? (
          <Register changeFormType={changeFormType} />
        ) : formType === "changePassword" ? (
          <ChangePassword changeFormType={changeFormType} />
        ) : (
          <Login changeFormType={changeFormType} />
        )}
      </div>
    </div>
  );
};

export default Header;
