import moment from "moment";

const noGroupDateDaily = (
  columns,
  data,
  xAxisField,
  dateFormatToUse,
  commonTitles,
  xValuesUsed,
  yValuesUsed,
  noDateDataColumnStatus,
  dependentColumns
) => {
  let childxAxisField;
  let xAxisFieldToUse = xAxisField;

  if (xAxisField?.includes("-")) {
    let tempXAxisField = xAxisField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  const xAxisFieldColumn = columns.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )[0];

  let xAxisFieldName;
  let childxAxisFieldIndex;
  let childxAxisNumber;

  if (childxAxisField) {
    const childTitle = xAxisFieldColumn.DEP_FIELDS?.filter(
      (field) => field.F_ID === childxAxisField
    ).map((item) => item.TITLE);
    xAxisFieldName = `${xAxisFieldColumn.T_TITLE} - ${childTitle}`;
    childxAxisNumber = xAxisFieldColumn.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === xAxisFieldToUse
    )[0].DEP_FIELDS;

    childxAxisFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childxAxisField;
    });
  } else {
    xAxisFieldName = xAxisFieldColumn.T_TITLE;
  }

  const isDependent =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "30"
      ? true
      : false;

  const isAlert =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  let dates = data.map((item) => {
    if (dependentColumns.includes(xAxisFieldToUse)) {
      if (item[xAxisFieldToUse] && item[xAxisFieldToUse].includes(",")) {
        const tempValue = item[xAxisFieldToUse].split(", ");
        const finalValue = tempValue[childxAxisFieldIndex];
        return finalValue;
      } else {
        return item[xAxisFieldToUse];
      }
    } else {
      if (isAlert) {
        if (item[xAxisFieldToUse]?.value) {
          return item[xAxisFieldToUse]?.value;
        } else return null;
      } else {
        if (item[xAxisFieldToUse]) {
          return item[xAxisFieldToUse];
        } else return null;
      }
    }
  });

  let uniqueDates = [];
  dates.forEach((item) => {
    if (item && !uniqueDates.includes(item)) {
      uniqueDates.push(item);
    }
  });

  //console.log("uniqueDates: before: ", uniqueDates);

  uniqueDates.sort((a, b) => {
    return (
      moment(a, dateFormatToUse).toDate() - moment(b, dateFormatToUse).toDate()
    );
  });

  uniqueDates.push(commonTitles["CO|1|NODATEDATA"]);

  let dailyCount = {};
  let noDateAvailable = 0;

  for (let i = 0; i < uniqueDates.length; i++) {
    let noGroupFieldCounter = 0;

    for (let z = 0; z < data.length; z++) {
      let dateValue = isAlert
        ? data[z][xAxisFieldToUse]?.value
        : data[z][xAxisFieldToUse];

      if (!dateValue && uniqueDates[i] === commonTitles["CO|1|NODATEDATA"]) {
        noDateAvailable += 1;
      }

      if (
        dateValue === uniqueDates[i] ||
        (isDependent && dateValue.includes(uniqueDates[i]))
      ) {
        noGroupFieldCounter += 1;
      }
    }

    if (uniqueDates[i] !== commonTitles["CO|1|NODATEDATA"]) {
      dailyCount = {
        ...dailyCount,
        [uniqueDates[i]]: {
          ...dailyCount[uniqueDates[i]],
          count: noGroupFieldCounter,
        },
      };
    }
  }

  if (noDateAvailable && !noDateDataColumnStatus) {
    dailyCount = {
      ...dailyCount,
      [commonTitles["CO|1|NODATEDATA"]]: { count: noDateAvailable },
    };
  }

  let totalSum = 0;
  Object.entries(dailyCount).forEach(([key, value]) => {
    let sum = 0;

    Object.values(value).forEach((item) => {
      sum += item;
      totalSum += item;
    });
    if (sum || !xValuesUsed) {
      dailyCount[key] = { ...dailyCount[key], sum: sum };
    } else {
      if (xValuesUsed) {
        delete dailyCount[key];
      }
    }
  });

  return {
    dailyCount: { ...dailyCount, totalSum: totalSum },
    monthlyCount: {},
    yearlyCount: {},
  };
};

export default noGroupDateDaily;
