import axios from "axios";
import htmlCodesRemover from "../../components/ultimateTable/helpers/htmlCodesRemover";

const apiGetMenu = async (baseURL) => {
  const url = `${baseURL}/App_menu_api/apigetmenu`;
  try {
    const response = await axios.post(
      url,
      { packageid: 1 },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    //console.log("apigetmenu: response: ", response.data);

    const languages = response.data.languages;
    const projects = response.data.projects;
    const adminMenu = response.data.adminmenu;
    const username = response.data.username;

    let tempData = response.data.menu;
    let menuData = [];
    for (let i = 0; i < tempData.length; i++) {
      menuData = tempData.filter((item) => item["ME_PARENTID"] === null);
    }

    let temp = {};
    for (let i = 0; i < menuData.length; i++) {
      temp = { ...temp, [menuData[i]["ME_ID"]]: [] };
      for (let j = 0; j < tempData.length; j++) {
        if (
          tempData[j]["ME_PARENTID"] === menuData[i]["ME_ID"] &&
          tempData[j]["ME_MODULEID"]
        ) {
          temp[menuData[i]["ME_ID"]].push(tempData[j]);
          if (tempData[j]["M_TYPE"]) {
            menuData[i]["M_TYPE"] = tempData[j]["M_TYPE"];
          }
        }
      }
    }

    for (let i = 0; i < menuData.length; i++) {
      menuData[i] = { ...menuData[i], children: temp[menuData[i]["ME_ID"]] };
    }

    for (let i = 0; i < menuData.length; i++) {
      for (let j = 0; j < menuData[i].children.length; j++) {
        // if (menuData[i].children[j].T_TITLE.includes("&#39;")) {
        menuData[i].children[j].T_TITLE = htmlCodesRemover(
          menuData[i].children[j].T_TITLE
        );
        // .split("&#39;")
        //   .filter((item) => item !== "")
        //   .join("'");
        // }
      }
    }

    //console.log("menuData: ", menuData);

    return { menuData, languages, adminMenu, projects, username };
  } catch (err) {}
};

export default apiGetMenu;
