import "./userRoles.css";
import { Layout, Select, Button } from "antd";
import { UserRolesModal } from "./UserRolesModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";

const { Option } = Select;
const { Content } = Layout;

const UserRolesSettings = ({
  headers,
  titles,
  newItemModalVisible,
  showModal,
  handleOk,
  handleCancel,
  handleDelete,
}) => {
  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"}>
        <Content
          style={{
            padding: 5,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        />
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {titles["MO|9|NEWROW"]}
          </Button>
          {newItemModalVisible && (
            <UserRolesModal
              key={"new-item"}
              visible={newItemModalVisible}
              headers={headers}
              titles={titles}
              onSave={handleOk}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
            />
          )}
        </div>
      </Flex>
    </Content>
  );
};

export default UserRolesSettings;
