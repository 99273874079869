import { Card } from "antd";
import Flex from "../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../store/useStores";

const DeleteModalContent = ({ selectedItems }) => {
  const count = selectedItems.length ?? 0;
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let deleteMsg2 = commonTitles["CO|1|DELETEMSG2"];
  var re = new RegExp("<1>", "g");
  deleteMsg2 = deleteMsg2.replace(re, count);

  return (
    <Flex flexDirection={"column"}>
      <Card>
        {count === 1 ? (
          <p>{commonTitles["CO|1|DELETEMSG1"]}</p>
        ) : (
          <p>{deleteMsg2}</p>
        )}
      </Card>
    </Flex>
  );
};

export default DeleteModalContent;
