import Flex from "../../../../../../helpers/components/Flex";
import CustomDatePicker from "../../../../../../helpers/components/CustomDatepicker";
import { useCommonTitlesStore } from "../../../../../../store/useStores";

const DependentDate = ({ value, id, fieldID, title, addChange, config }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let newValue = value ?? "";
  if (value && fieldID in value) {
    newValue = value[fieldID];
  }
  return (
    <Flex flexDirection={"column"} style={{ width: 300 }}>
      <Flex flexDirection={"column"}>
        <div className="datepicker-text" style={{ flex: 1 }}>
          <p>
            {title} - {commonTitles["CO|1|FROM"]}:
          </p>
        </div>
        <CustomDatePicker
          value={newValue}
          id={id}
          dependentField={fieldID}
          config={config}
          suffix="from"
          isDisabled={false}
          addChange={addChange}
          showTime={false}
        />
      </Flex>
      <Flex flexDirection={"column"}>
        <div className="datepicker-text" style={{ flex: 1 }}>
          <p>
            {title} - {commonTitles["CO|1|TO"]}:
          </p>
        </div>
        <CustomDatePicker
          value={newValue}
          id={id}
          dependentField={fieldID}
          config={config}
          suffix="to"
          isDisabled={false}
          addChange={addChange}
          showTime={false}
        />
      </Flex>
    </Flex>
  );
};

export default DependentDate;
