import { Input, Modal, Select, Button } from "antd";
import { useState } from "react";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../store/useStores";
import { ConfirmationDeleteModal } from "./ConfirmationDeleteModal";

const { Option } = Select;

export const UserRolesModal = ({
  visible,
  data,
  headers,
  titles,
  onSave,
  onDelete,
  onCancel,
  config,
}) => {
  const [changes, setChanges] = useState({});
  const dataChanged = Object.keys(changes);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  const cancelConfirmDeleteModal = () => setConfirmDeleteModalVisible(false);

  return (
    <Modal
      key={`${data}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      data={data}
      headers={headers}
      titles={titles}
      style={{
        minWidth: "max-content",
      }}
      bodyStyle={{ paddingLeft: 80, paddingRight: 80 }}
      title={config === "edit" ? titles["MO|9|EDITROW"] : titles["MO|9|NEWROW"]}
      onOk={() => onSave(config)}
      onCancel={() => onCancel(config)}
      onDelete={() => setConfirmDeleteModalVisible(true)}
      footer={[
        <Button key="back" onClick={() => onCancel(config)}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <>
          {config === "edit" && (
            <Button
              key="delete"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => setConfirmDeleteModalVisible(true)}
            >
              {commonTitles["CO|1|DELETEBTN"]}
            </Button>
          )}
        </>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() =>
            onSave(
              config,
              changes,
              data?.UR_ID ?? "",
              data?.UR_LASTMODIFIED ?? ""
            )
          }
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <Flex flexDirection={"row"} style={{ marginTop: "3rem" }}>
        <div className="modal-text">
          {headers.map((header) => (
            <p key={header}>{header}:</p>
          ))}
        </div>
        <div className="modal-input">
          <div className="select">
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginTop: -10,
                marginBottom: "1rem",
              }}
              onChange={(e) => addChange("name", e.target.value)}
              defaultValue={config === "edit" ? data.T_TITLE : null}
            />
          </div>
        </div>
        {confirmDeleteModalVisible && (
          <ConfirmationDeleteModal
            visible={confirmDeleteModalVisible}
            onDelete={onDelete}
            onCancel={cancelConfirmDeleteModal}
            data={data}
          />
        )}
      </Flex>
    </Modal>
  );
};
