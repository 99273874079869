import { useEffect, useState } from "react";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";

const ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

const useSort = (
  items,
  columns,
  updateOrderConfig,
  config,
  orderBy,
  orderDirection
) => {
  const [sortConfig, setSortConfig] = useState({
    columnID: null,
    direction: null,
  });

  useEffect(() => {
    if (config !== "alerts") {
      if (orderBy) {
        setSortConfig({ columnID: orderBy, direction: orderDirection });
      } else {
        setSortConfig({ columnID: columns?.[0]?.F_ID, direction: "ASC" });
      }
    }
  }, [columns]);

  const selectedColumnId = sortConfig.columnID ?? null;
  const selectedDirection = sortConfig.direction ?? null;

  useEffect(() => {
    updateOrderConfig(selectedColumnId, selectedDirection);
  }, [selectedColumnId, selectedDirection]);
  // updateOrderConfig(selectedColumnId, selectedDirection);

  const doSort = () => {
    if (
      selectedColumnId === null ||
      selectedDirection === null ||
      items.length === 0
    ) {
      return items;
    }

    const sortedItems = [...items];

    const column =
      columns.find((column) => column.F_ID === selectedColumnId) ?? null;
    const isAlertsInfo =
      (config === "alerts" && column.F_ID === "info") ?? false;
    const isNumber =
      (column?.F_TYPE === "2" ||
        column?.F_TYPE === "3" ||
        column?.F_TYPE === "116") ??
      false;
    const isDate = column?.F_TYPE === "4" ?? false;
    const isStatic = column?.F_TYPE === "6" ?? false;
    const isAttachment =
      (column?.F_TYPE === "24" || column?.F_TYPE === "25") ?? false;

    const valueToTest = isStatic
      ? sortedItems[0][selectedColumnId]?.view
      : isAttachment
      ? sortedItems[0][selectedColumnId][0].name
      : sortedItems[0][selectedColumnId];
    const isPercentage =
      valueToTest && column?.F_TYPE === "6"
        ? !!valueToTest.match(/[%]/)
        : false;

    let comparator = null;
    if (isAlertsInfo) {
      comparator = (a, b) =>
        selectedDirection === ORDER.ASC
          ? a[selectedColumnId]?.[0]?.value.localeCompare(
              b[selectedColumnId]?.[0]?.value
            )
          : -a[selectedColumnId]?.[0]?.value.localeCompare(
              b[selectedColumnId]?.[0]?.value
            );
    } else if (isNumber) {
      if (column?.F_RENDER === "ALERT") {
        comparator = (a, b) =>
          selectedDirection === ORDER.ASC
            ? parseFloat(a[selectedColumnId]?.value) -
              parseFloat(b[selectedColumnId]?.value)
            : -(
                parseFloat(a[selectedColumnId]?.value) -
                parseFloat(b[selectedColumnId]?.value)
              );
      } else {
        comparator = (a, b) =>
          selectedDirection === ORDER.ASC
            ? parseFloat(a[selectedColumnId]) - parseFloat(b[selectedColumnId])
            : -(
                parseFloat(a[selectedColumnId]) -
                parseFloat(b[selectedColumnId])
              );
      }
    } else if (isDate) {
      comparator = (a, b) =>
        selectedDirection === ORDER.ASC
          ? a[`${selectedColumnId}-date`] - b[`${selectedColumnId}-date`]
          : -(a[`${selectedColumnId}-date`] - b[`${selectedColumnId}-date`]);
    } else if (isPercentage) {
      comparator = (a, b) =>
        selectedDirection === ORDER.ASC
          ? parseInt(a[selectedColumnId]?.view.replace("%.*$", "")) -
            parseInt(b[selectedColumnId]?.view.replace("%.*$", ""))
          : -(
              parseInt(a[selectedColumnId]?.view.replace("%.*$", "")) -
              parseInt(b[selectedColumnId]?.view.replace("%.*$", ""))
            );
    } else if (isStatic) {
      comparator = (a, b) =>
        selectedDirection === ORDER.ASC
          ? a?.[selectedColumnId]?.view?.localeCompare(
              b?.[selectedColumnId]?.view
            )
          : -a?.[selectedColumnId]?.view?.localeCompare(
              b?.[selectedColumnId]?.view
            );
    } else if (isAttachment) {
      comparator = (a, b) =>
        selectedDirection === ORDER.ASC
          ? a?.[selectedColumnId]?.[0]?.name?.localeCompare(
              b?.[selectedColumnId]?.[0]?.name
            )
          : -a?.[selectedColumnId]?.[0]?.name?.localeCompare(
              b?.[selectedColumnId]?.[0]?.name
            );
    } else {
      comparator = (a, b) => {
        if (selectedColumnId in a && selectedColumnId in b) {
          return selectedDirection === ORDER.ASC
            ? a?.[selectedColumnId]?.localeCompare(b?.[selectedColumnId])
            : -a?.[selectedColumnId]?.localeCompare(b?.[selectedColumnId]);
        }
      };
    }

    sortedItems.sort(comparator);

    return sortedItems;
  };

  const sortedItems = doSort();

  const decideSortIcon = (columnID, title) => {
    if (selectedColumnId === columnID) {
      if (selectedDirection === ORDER.ASC) {
        return (
          <Badge
            count={<ArrowUpOutlined style={{ color: "rgb(8, 8, 46)" }} />}
            offset={[10, 5]}
          >
            {title}
          </Badge>
        );
      } else if (selectedDirection === ORDER.DESC) {
        return (
          <Badge
            count={<ArrowDownOutlined style={{ color: "rgb(8, 8, 46)" }} />}
            offset={[10, 5]}
          >
            {title}
          </Badge>
        );
      }
    }

    return (
      <Badge
        count={
          <SwapOutlined rotate={90} style={{ color: "grey", opacity: 0.25 }} />
        }
        offset={[10, 7]}
      >
        {title}
      </Badge>
    );
  };

  const sort = (columnID) => {
    let direction = null;
    if (selectedColumnId === columnID) {
      if (selectedDirection === ORDER.ASC) {
        direction = ORDER.DESC;
      } else if (selectedDirection === ORDER.DESC) {
        direction = null;
      } else {
        direction = ORDER.ASC;
      }
    } else {
      direction = ORDER.ASC;
    }

    setSortConfig({ columnID, direction });
  };

  return { sortedItems, sort, decideSortIcon };
};

export default useSort;
