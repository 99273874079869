import { Modal } from "antd";
import { useState, useEffect } from "react";
import Flex from "../../../helpers/components/Flex";
import {
  useAuthStore,
  useCommonTitlesStore,
  useModuleStore,
} from "../../../store/useStores";
import ModalFooter from "./modalFooter/ModalFooter";
import DisplayDecider from "./deciders/DisplayDecider";

const EmailConfigModal = ({
  visible,
  data = [],
  headers,
  modules,
  titles,
  onSave,
  onCancel,
  config,
  fields,
  showDeleteModal,
  notifyFields,
  templates = {},
}) => {
  const [changes, setChanges] = useState({});
  const [moduleID, setModuleID] = useState(null);
  const [notificationText, setNotificationText] = useState("");
  const [triggerField, setTriggerField] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [periodType, setPeriodType] = useState("");
  const [infoFields, setInfoFields] = useState([]);
  const [notifyFieldOptions, setNotifyFieldOptions] = useState([]);
  const projectID = useAuthStore((state) => state.projectID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const dateTypes = useModuleStore((state) => state.dateTypes);

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  const updateModuleID = (id) => setModuleID(id);
  const updateNotificationText = (value) => setNotificationText(value);
  const updateNotificationType = (value) => setNotificationType(value);
  const updatePeriodType = (value) => setPeriodType(value);

  useEffect(() => {
    if (config === "new") {
      setChanges({
        ...changes,
        N_ACTIVE: "Y",
        N_ID: "",
        N_LASTMODIFIED: "",
        NOTIFICATIONTEXT: "",
        N_CONTACT: "",
        N_WARNING_BEFORE: "0",
        N_RAISED_AFTER: "0",
        N_PERIOD_NUM: "",
        N_PERIOD_TYPE: "",
        N_EVENT: "",
        N_NOTIFY_FIELD: "",
        N_NOTIFY_EMAILS: "",
        N_TEMPLATEID: "",
        N_EXPIREINDICATION: "Y",
        INFORMATIONFIELDS: "",
        N_FILTER: {},
        N_PARENTID: projectID,
        N_TYPE: "",
        N_WEEK_DAY: "",
        N_MONTH_DAY: "",
      });
    }

    if (config === "edit") {
      const defaultInfoFieldsValue =
        data?.N_INFORMATION_FIELDIDS?.split(",")
          ?.map((item) => item)
          .filter((datum) => datum) ?? [];

      setNotificationText(data.T_TITLE);
      setTriggerField(data.TRIGGERFIELDTITLE);
      setInfoFields(defaultInfoFieldsValue);
      setChanges({ ...changes, N_MODULEID: data?.N_MODULEID });
      setModuleID(data?.N_MODULEID);
      setNotifyFieldOptions(notifyFields?.[data?.M_MODULEID] ?? []);
      setNotificationType(data?.N_TYPE ?? "");
      setPeriodType(data?.N_PERIOD_TYPE ?? "");
    }
  }, []);

  useEffect(() => {
    if (notificationText && notificationText !== data?.T_TITLE) {
      setChanges({
        ...changes,
        NOTIFICATIONTEXT: notificationText ?? "",
      });
    }

    setNotifyFieldOptions(notifyFields?.[moduleID] ?? []);
  }, [moduleID, notificationText]);

  useEffect(() => {
    if (config === "edit") {
      if (changes?.N_MODULEID !== data?.N_MODULEID) {
        setNotificationText("");
        setTriggerField(null);
        setNotificationType("");
        setInfoFields([]);
      } else {
        const defaultInfoFieldsValue =
          data?.N_INFORMATION_FIELDIDS?.split(",")
            ?.map((item) => item)
            .filter((datum) => datum) ?? [];

        setNotificationText(data.T_TITLE);
        setNotificationType(data.N_TYPE);
        setTriggerField(data.TRIGGERFIELDTITLE);
        setInfoFields(defaultInfoFieldsValue);
      }
    }
  }, [moduleID]);

  const triggerFieldOptions =
    fields[moduleID]?.filter((item) => dateTypes.includes(item.F_TYPE)) ?? [];

  return (
    <Modal
      key={`${config}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        marginTop: 20,
        minWidth: "max-content",
      }}
      bodyStyle={{
        paddingLeft: 80,
        paddingRight: 160,
        height: "60vh",
        overflowY: "auto",
      }}
      title={
        config === "new" ? titles["MO|20|NEWROW"] : titles["MO|20|EDITROW"]
      }
      onOk={() => onSave(changes)}
      onCancel={() => onCancel(config)}
      onDelete={() => showDeleteModal(data?.N_ID)}
      footer={
        <ModalFooter
          data={data}
          changes={changes}
          config={config}
          onSave={onSave}
          onCancel={onCancel}
          showDeleteModal={showDeleteModal}
        />
      }
    >
      <Flex
        flexDirection={"column"}
        style={{ marginTop: "2rem", marginBottom: "2rem", fontWeight: 600 }}
      >
        <DisplayDecider
          id={"N_MODULEID"}
          title={titles["FD|376|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />

        <DisplayDecider
          id={"N_TYPE"}
          title={titles["FD|377|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />
        {["S", "L", "R"].includes(notificationType) && (
          <>
            <DisplayDecider
              id={"N_TRIGGER_FIELDID"}
              title={titles["FD|359|TITLE"]}
              data={data}
              addChange={addChange}
              moduleID={moduleID}
              updateModuleID={updateModuleID}
              modules={modules}
              triggerField={triggerField}
              triggerFieldOptions={triggerFieldOptions}
              titles={titles}
              notifyFieldOptions={notifyFieldOptions}
              templates={templates}
              infoFields={infoFields}
              notificationText={notificationText}
              updateNotificationText={updateNotificationText}
              config={config}
              fields={fields}
              notificationType={notificationType}
              updateNotificationType={updateNotificationType}
              changes={changes}
              periodType={periodType}
              updatePeriodType={updatePeriodType}
            />
            <DisplayDecider
              id={"N_WARNING_BEFORE"}
              title={titles["FD|360|TITLE"]}
              data={data}
              addChange={addChange}
              moduleID={moduleID}
              updateModuleID={updateModuleID}
              modules={modules}
              triggerField={triggerField}
              triggerFieldOptions={triggerFieldOptions}
              titles={titles}
              notifyFieldOptions={notifyFieldOptions}
              templates={templates}
              infoFields={infoFields}
              notificationText={notificationText}
              updateNotificationText={updateNotificationText}
              config={config}
              fields={fields}
              notificationType={notificationType}
              updateNotificationType={updateNotificationType}
              changes={changes}
              periodType={periodType}
              updatePeriodType={updatePeriodType}
            />
            <DisplayDecider
              id={"N_RAISED_AFTER"}
              title={titles["FD|361|TITLE"]}
              data={data}
              addChange={addChange}
              moduleID={moduleID}
              updateModuleID={updateModuleID}
              modules={modules}
              triggerField={triggerField}
              triggerFieldOptions={triggerFieldOptions}
              titles={titles}
              notifyFieldOptions={notifyFieldOptions}
              templates={templates}
              infoFields={infoFields}
              notificationText={notificationText}
              updateNotificationText={updateNotificationText}
              config={config}
              fields={fields}
              notificationType={notificationType}
              updateNotificationType={updateNotificationType}
              changes={changes}
              periodType={periodType}
              updatePeriodType={updatePeriodType}
            />
            <DisplayDecider
              id={"N_PERIOD_NUM"}
              title={`${titles["FD|362|TITLE"]} (${commonTitles["CO|1|NUMSING"]})`}
              data={data}
              addChange={addChange}
              moduleID={moduleID}
              updateModuleID={updateModuleID}
              modules={modules}
              triggerField={triggerField}
              triggerFieldOptions={triggerFieldOptions}
              titles={titles}
              notifyFieldOptions={notifyFieldOptions}
              templates={templates}
              infoFields={infoFields}
              notificationText={notificationText}
              updateNotificationText={updateNotificationText}
              config={config}
              fields={fields}
              notificationType={notificationType}
              updateNotificationType={updateNotificationType}
              changes={changes}
              periodType={periodType}
              updatePeriodType={updatePeriodType}
            />
            <DisplayDecider
              id={"N_PERIOD_TYPE"}
              title={`${titles["FD|362|TITLE"]} (${titles["FD|363|TITLE"]})`}
              data={data}
              addChange={addChange}
              moduleID={moduleID}
              updateModuleID={updateModuleID}
              modules={modules}
              triggerField={triggerField}
              triggerFieldOptions={triggerFieldOptions}
              titles={titles}
              notifyFieldOptions={notifyFieldOptions}
              templates={templates}
              infoFields={infoFields}
              notificationText={notificationText}
              updateNotificationText={updateNotificationText}
              config={config}
              fields={fields}
              notificationType={notificationType}
              updateNotificationType={updateNotificationType}
              changes={changes}
              periodType={periodType}
              updatePeriodType={updatePeriodType}
            />
            {periodType === "W" && (
              <DisplayDecider
                id={"N_WEEK_DAY"}
                title={titles["FD|379|TITLE"]}
                data={data}
                addChange={addChange}
                moduleID={moduleID}
                updateModuleID={updateModuleID}
                modules={modules}
                triggerField={triggerField}
                triggerFieldOptions={triggerFieldOptions}
                titles={titles}
                notifyFieldOptions={notifyFieldOptions}
                templates={templates}
                infoFields={infoFields}
                notificationText={notificationText}
                updateNotificationText={updateNotificationText}
                config={config}
                fields={fields}
                notificationType={notificationType}
                updateNotificationType={updateNotificationType}
                changes={changes}
                periodType={periodType}
                updatePeriodType={updatePeriodType}
              />
            )}
            {periodType === "M" && (
              <DisplayDecider
                id={"N_MONTH_DAY"}
                title={titles["FD|380|TITLE"]}
                data={data}
                addChange={addChange}
                moduleID={moduleID}
                updateModuleID={updateModuleID}
                modules={modules}
                triggerField={triggerField}
                triggerFieldOptions={triggerFieldOptions}
                titles={titles}
                notifyFieldOptions={notifyFieldOptions}
                templates={templates}
                infoFields={infoFields}
                notificationText={notificationText}
                updateNotificationText={updateNotificationText}
                config={config}
                fields={fields}
                notificationType={notificationType}
                updateNotificationType={updateNotificationType}
                changes={changes}
                periodType={periodType}
                updatePeriodType={updatePeriodType}
              />
            )}
          </>
        )}
        {notificationType === "E" && (
          <DisplayDecider
            id={"N_EVENT"}
            title={titles["FD|364|TITLE"]}
            data={data}
            addChange={addChange}
            moduleID={moduleID}
            updateModuleID={updateModuleID}
            modules={modules}
            triggerField={triggerField}
            triggerFieldOptions={triggerFieldOptions}
            titles={titles}
            notifyFieldOptions={notifyFieldOptions}
            templates={templates}
            infoFields={infoFields}
            notificationText={notificationText}
            updateNotificationText={updateNotificationText}
            config={config}
            fields={fields}
            notificationType={notificationType}
            updateNotificationType={updateNotificationType}
            changes={changes}
            periodType={periodType}
            updatePeriodType={updatePeriodType}
          />
        )}
        <DisplayDecider
          id={"N_NOTIFY_FIELD"}
          title={titles["FD|365|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />
        <DisplayDecider
          id={"N_NOTIFY_EMAILS"}
          title={titles["FD|366|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />
        <DisplayDecider
          id={"N_TEMPLATEID"}
          title={titles["FD|367|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />
        {/* <DisplayDecider
          id={"N_EXPIREINDICATION"}
          title={titles["FD|368|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        /> */}
        <DisplayDecider
          id={"N_CONTACT"}
          title={titles["FD|370|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />
        <DisplayDecider
          id={"NOTIFICATIONTEXT"}
          title={titles["FD|369|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />
        <DisplayDecider
          id={"INFORMATIONFIELDS"}
          title={titles["FD|371|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />
        <DisplayDecider
          id={"N_ACTIVE"}
          title={titles["FD|373|TITLE"]}
          data={data}
          addChange={addChange}
          moduleID={moduleID}
          updateModuleID={updateModuleID}
          modules={modules}
          triggerField={triggerField}
          triggerFieldOptions={triggerFieldOptions}
          titles={titles}
          notifyFieldOptions={notifyFieldOptions}
          templates={templates}
          infoFields={infoFields}
          notificationText={notificationText}
          updateNotificationText={updateNotificationText}
          config={config}
          fields={fields}
          notificationType={notificationType}
          updateNotificationType={updateNotificationType}
          changes={changes}
          periodType={periodType}
          updatePeriodType={updatePeriodType}
        />
      </Flex>
    </Modal>
  );
};

export default EmailConfigModal;
