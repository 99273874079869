import Reports from "./reports/Reports";
import Data from "./data/Data";
import { useCommonTitlesStore } from "../../../store/useStores";
import BannerWrapper from "../../../helpers/components/BannerWrapper";

const Banner = ({ title, path, reportIconVisible, moduleID }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <BannerWrapper title={title} path={commonTitles.metrics}>
      <Data path={path} />
      <Reports
        title={title}
        moduleID={moduleID}
        reportIconVisible={reportIconVisible}
      />
    </BannerWrapper>
  );
};

export default Banner;
