import "../navigation.css";
import { Dropdown, Space } from "antd";
import { useState } from "react";
import {
  useMenuStore,
  useAuthStore,
  useCommonTitlesStore,
} from "../../../store/useStores";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import ProjectSearch from "./ProjectSearch";
import Flex from "../../../helpers/components/Flex";
import { useRef } from "react";

const Projects = () => {
  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );
  const projects = useMenuStore((state) => state.projects);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const setProjectID = useAuthStore((state) => state.setProjectID);
  const [projectItems, setProjectItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [project, setProject] = useState("");

  const projectIDs = projects?.map((project) => project.V_ROWID) ?? [];

  const updateVisibility = (value) => setVisible(value);

  const changeProjectItems = (value) => {
    const projectSearchItem = [
      {
        key: "search",
        label: (
          <ProjectSearch
            value={value}
            updateVisibility={updateVisibility}
            changeProjectItems={changeProjectItems}
          />
        ),
      },
    ];

    if (value) {
      const tempProjectItems =
        projects
          .filter((project) =>
            project.VALUE.toLowerCase().includes(value.toLowerCase())
          )
          .map((item) => ({
            key: item.V_ROWID,
            label: (
              <p
                id={item.V_ROWID}
                onClick={(e) => {
                  if (e) {
                    changeProject(item.V_ROWID);
                  }
                }}
              >
                {item.VALUE}
              </p>
            ),
          })) ?? [];

      setProjectItems([...projectSearchItem, ...tempProjectItems]);
    } else {
      const tempProjectItems =
        projects.map((item) => ({
          key: item.V_ROWID,
          label: (
            <p
              id={item.V_ROWID}
              onClick={(e) => {
                if (e) {
                  changeProject(item.V_ROWID);
                  setVisible(false);
                }
              }}
            >
              {item.VALUE}
            </p>
          ),
        })) ?? [];

      setProjectItems([...projectSearchItem, ...tempProjectItems]);
    }
  };

  useEffect(() => {
    if (projects.length) {
      const currentProject =
        projects.filter((project) => project?.CURRENT === "Y")[0] ?? null;
      const tempCurrentProject = projects[0];

      if (currentProject) {
        setProject(currentProject.VALUE);
      } else {
        setProject(tempCurrentProject.VALUE);
      }
    }

    const projectSearchItem = [
      {
        key: "search",
        label: (
          <ProjectSearch
            value={null}
            updateVisibility={updateVisibility}
            changeProjectItems={changeProjectItems}
          />
        ),
      },
    ];

    const tempProjectItems =
      projects.map((item) => ({
        key: item.V_ROWID,
        label: (
          <p
            id={item.V_ROWID}
            onClick={(e) => {
              if (e) {
                changeProject(item.V_ROWID);
                setVisible(false);
              }
            }}
          >
            {item.VALUE}
          </p>
        ),
      })) ?? [];

    setProjectItems([...projectSearchItem, ...tempProjectItems]);
  }, [projects]);

  let navigate = useNavigate();

  const changeProject = async (project) => {
    const url = `${baseURL}/Projects_api/change`;
    try {
      const response = await axios.post(
        url,
        {
          projectid: project,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data.STATUS === "Success") {
        setProject(project);
        setProjectID(project);
        navigate("/home");
      } else {
        //message.error("Αδυναμία επικοινωνίας με τον server", [3]);
        displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
      }

      //console.log("Projects_api: response: ", response.data);
    } catch (err) {
      console.log("err: ", err);
    }
  };

  let scrollbarInnerText = "";

  projects.forEach((project) => {
    if (scrollbarInnerText !== "") {
      scrollbarInnerText += "\n\n";
    }
    scrollbarInnerText += project?.VALUE;
  });

  const projectMenuRef = useRef(null);

  const closeOpenMenus = (e) => {
    let isScrollbar = false;

    if (projects.length > 1) {
      if (e.target.innerText === scrollbarInnerText) {
        isScrollbar = true;
      }
    }

    let isBanner = false;
    if (
      (e.target.nodeName === "DIV" || e.target.nodeName === "INPUT") &&
      e.target.className !== "project__searchInput"
    ) {
      isBanner = true;
    }

    if (
      (e.target.innerText || e.target.innerText === undefined || isBanner) &&
      !isScrollbar &&
      projectMenuRef.current &&
      visible &&
      !projectMenuRef.current.contains(e.target)
    ) {
      setVisible(false);
      const projectID = projects.filter(
        (project) => project.VALUE === e.target.innerText
      )?.[0]?.V_ROWID;
      if (projectID && projectIDs.includes(e.target.id)) {
        changeProject(projectID);
      }
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const style = {
    alignItems: "center",
    width: "max-content",
    marginRight: 5,
    color: "#fff",
    marginLeft: "2rem",
    fontFamily: "var(--font-family)",
    fontSize: 15,
    fontWeight: 500,
    zIndex: 5,
  };

  return (
    <Flex key={project} flexDirection={"row"} style={style}>
      <div
        ref={projectMenuRef}
        // onClick={() => {
        //   setVisible(!visible);
        // }}
        onMouseEnter={() => setVisible(true)}
        //onMouseLeave={() => setVisible(false)}
        style={{ marginRight: 15 }}
      >
        <Dropdown
          open={visible}
          overlayStyle={{
            overflowY: "auto",
            height: "80vh",
            borderRadius: 10,
          }}
          menu={{ items: projectItems }}
        >
          <Space style={{ color: navigationTextColor }}>{project}</Space>
        </Dropdown>
      </div>
    </Flex>
  );
};

export default Projects;
