import { Input, Modal, Select, Button } from "antd";
import { useState } from "react";
import Flex from "../../../../helpers/components/Flex";
import CustomCheckbox from "../../../../helpers/components/CustomCheckbox";
import { useCommonTitlesStore } from "../../../../store/useStores";
import { useEffect } from "react";
import htmlCodesRemover from "../../../ultimateTable/helpers/htmlCodesRemover";

const { Option } = Select;

export const ModulesConfigModal = ({
  visible,
  data,
  modules,
  headers,
  titles,
  fields,
  projects,
  staticOptions,
  onSave,
  onDelete,
  onCancel,
  config,
  showDeleteModal,
}) => {
  const [changes, setChanges] = useState({});
  const dataChanged = Object.keys(changes);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [copyFromModule, setCopyFromModule] = useState(false);
  const [copyData, setCopyData] = useState(false);
  const [modalHeaders, setModalHeaders] = useState([]);

  const tempHeaders =
    config === "edit"
      ? headers.filter((header, index) => index !== 6)
      : headers;

  useEffect(() => setModalHeaders(tempHeaders), []);

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  const copydataHandler = (id, value) => {
    addChange("copydata", value);
    if (value && value === "Y") {
      setCopyData(true);
      setModalHeaders([
        ...modalHeaders,
        { F_ID: "copyfromproject", TITLE: fields["210"] },
      ]);
    } else {
      setCopyData(false);
      setModalHeaders(
        modalHeaders.filter((header) => header.TITLE !== fields["210"])
      );
    }
  };

  const showAsterisk = (id) => {
    if (dataChanged.includes(id)) {
      let isChanged = false;
      if (config === "edit") {
        const valueToCheck = data?.[id]?.includes("&#39;")
          ? data[id]
              .split("&#39;")
              .filter((item) => item !== "")
              .join("'")
          : data[id];

        if (changes[id] !== valueToCheck) {
          isChanged = true;
        } else {
          isChanged = false;
        }
      }

      if (config === "new") {
        if (changes[id]) {
          isChanged = true;
        } else {
          isChanged = false;
        }
      }
      return isChanged;
    }
  };

  return (
    <Modal
      key={`${data}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      data={data}
      headers={headers}
      titles={titles}
      style={{
        marginTop: -40,
        display: "flex",
        width: "max-content",
      }}
      title={config === "edit" ? titles["MO|2|EDITROW"] : titles["MO|2|NEWROW"]}
      onOk={() => onSave(changes)}
      onCancel={() => onCancel(config)}
      onDelete={() => showDeleteModal(data.M_ID)}
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            marginBottom: "-2rem",
          }}
          key={"footer-info"}
        >
          <p
            style={{
              color: "rgb(228, 21, 21)",
              fontSize: 18,
              marginRight: "0.3rem",
            }}
          >
            *
          </p>
          <p> - {commonTitles["CO|1|PNDGSAVE"]} </p>
        </div>,
        <Button key="back" onClick={() => onCancel(config)}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        config === "edit" && (
          <Button
            key="delete"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={() => showDeleteModal(data.M_ID)}
          >
            {commonTitles["CO|1|DELETEBTN"]}
          </Button>
        ),
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(changes)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <Flex
        flexDirection={"row"}
        style={{ margin: "2.5rem", marginBottom: "1.5rem" }}
      >
        <div
          className="modal-text"
          style={{ display: "flex", width: "max-content" }}
        >
          {modalHeaders?.map((header) => (
            <Flex key={header.F_ID} flexDirection={"row"}>
              {showAsterisk(header.F_ID) ? (
                <p style={{ color: "red", marginRight: 5 }}>*</p>
              ) : (
                <p style={{ marginRight: 12 }}></p>
              )}
              <p
                key={header.F_ID}
                style={{
                  marginRight: 5,
                  display: "flex",
                  justifyContent: "flex-end !important",
                }}
              >
                {header.TITLE}:
              </p>
            </Flex>
          ))}
        </div>
        <div className="modal-input">
          <div className="select">
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginTop: -10,
                marginBottom: "1rem",
                borderRadius: 5,
              }}
              onChange={(e) => addChange("T_TITLE", e.target.value)}
              defaultValue={config === "edit" ? data.T_TITLE : null}
            />
          </div>
          <div className="select">
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginTop: 0,
                marginBottom: "1rem",
                borderRadius: 5,
              }}
              onChange={(e) => addChange("TITLESINGULAR", e.target.value)}
              defaultValue={config === "edit" ? data.TITLESINGULAR : null}
            />
          </div>
          <div className="select">
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginBottom: "1rem",
                marginTop: "0.5rem",
                borderRadius: 5,
              }}
              onChange={(e) => addChange("M_ABBREVIATION", e.target.value)}
              defaultValue={config === "edit" ? data.M_ABBREVIATION : null}
            />
          </div>
          <div className="select">
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginTop: -10,
                marginBottom: "1rem",

                borderRadius: 5,
              }}
              onChange={(e) => addChange("DESCR", e.target.value)}
              defaultValue={
                config === "edit"
                  ? htmlCodesRemover(data.DESCR) ?? data.DESCR
                  : null
              }
            />
          </div>
          <div className="select">
            <Select
              style={{
                width: 300,
                marginLeft: 15,
                marginBottom: "1rem",
              }}
              showSearch={true}
              onChange={(e) => addChange("M_TYPE", e)}
              filterOption={(iv, op) => {
                let opToUse = op?.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              defaultValue={
                config === "edit" && data.M_TYPE === "M"
                  ? staticOptions?.[239]?.M?.TITLE
                  : config === "edit" && data.M_TYPE === "P"
                  ? staticOptions?.[239]?.P?.TITLE
                  : ""
              }
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option className="option" value={""} />
              <Option className="option" value={"M"}>
                {staticOptions?.[239]?.M?.TITLE}
              </Option>
              <Option className="option" value={"P"}>
                {staticOptions?.[239]?.P?.TITLE}
              </Option>
            </Select>
          </div>

          <div
            style={{
              marginLeft: 15,
              marginTop: -5,
              marginBottom: 20,
              width: 340,
            }}
          >
            <CustomCheckbox
              id={"M_PROJECTSPECIFIC"}
              value={config === "edit" ? data.M_PROJECTSPECIFIC ?? "N" : "N"}
              addChange={addChange}
              config={config}
              isDisabled={false}
            />
          </div>
          {/* <div className="select">
            <Select
              style={{
                width: 300,
                marginLeft: 15,
                marginBottom: "1rem",
              }}
              showSearch={true}
              onChange={(e) => addChange("M_PROJECTSPECIFIC", e)}
              filterOption={(iv, op) => {
                let opToUse = op?.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              defaultValue={config === "edit" && data.M_PROJECTSPECIFIC}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option className="option" value={""} />
              <Option className="option" value={"Y"}>
                {staticOptions?.[199]?.Y?.TITLE}
              </Option>
              <Option className="option" value={"N"}>
                {staticOptions?.[199]?.N?.TITLE}
              </Option>
            </Select>
          </div> */}
          {config !== "edit" && (
            <div className="select">
              <Select
                style={{
                  width: 300,
                  marginLeft: 15,
                  marginBottom: "1rem",
                }}
                showSearch={true}
                onChange={(e) => {
                  addChange("copyfrommodule", e);
                  if (e) {
                    setCopyFromModule(true);
                    setModalHeaders([
                      ...modalHeaders,
                      { F_ID: "copydata", TITLE: fields["201"] },
                    ]);
                  } else {
                    setCopyFromModule(false);
                    setModalHeaders(
                      modalHeaders.filter(
                        (header) => header.TITLE !== fields["201"]
                      )
                    );
                  }
                }}
                filterOption={(iv, op) => {
                  let opToUse = op?.children ? op.children : "";
                  return opToUse.toLowerCase().includes(iv.toLowerCase());
                }}
                defaultValue={""}
                dropdownStyle={{ overflowY: "auto" }}
                virtual={false}
              >
                <Option className="option" value={""} />
                {modules.map((module) => (
                  <Option
                    key={module.M_ID}
                    className="option"
                    value={module.M_ID}
                  >
                    {module?.T_TITLE}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {copyFromModule && (
            <div
              style={{
                marginLeft: 15,
                marginTop: -8,
                marginBottom: 20,
                width: 340,
              }}
            >
              <CustomCheckbox
                id={"copydata"}
                value={"N"}
                addChange={copydataHandler}
                config={config}
                isDisabled={false}
              />
            </div>

            // <div className="select">
            //   <Select
            //     style={{
            //       width: 300,
            //       marginLeft: 15,
            //       marginBottom: "1rem",
            //     }}
            //     showSearch={true}
            //     onChange={(e) => {
            //       addChange("copydata", e);
            //       if (e && e === "Y") {
            //         setCopyData(true);
            //         setModalHeaders([
            //           ...modalHeaders,
            //           { F_ID: "copyfromproject", TITLE: fields["210"] },
            //         ]);
            //       } else {
            //         setCopyData(false);
            //         setModalHeaders(
            //           modalHeaders.filter(
            //             (header) => header.TITLE !== fields["210"]
            //           )
            //         );
            //       }
            //     }}
            //     filterOption={(iv, op) => {
            //       let opToUse = op?.children ? op.children : "";
            //       return opToUse.toLowerCase().includes(iv.toLowerCase());
            //     }}
            //     defaultValue={"N"}
            //     dropdownStyle={{ overflowY: "auto" }}
            //     virtual={false}
            //   >
            //     <Option className="option" value={""} />
            //     <Option className="option" value={"Y"}>
            //       {staticOptions?.[199]?.Y?.TITLE}
            //     </Option>
            //     <Option className="option" value={"N"}>
            //       {staticOptions?.[199]?.N?.TITLE}
            //     </Option>
            //   </Select>
            // </div>
          )}
          {copyData && (
            <div className="select" key={copyData}>
              <Select
                style={{
                  width: 300,
                  marginLeft: 15,
                  marginBottom: "1rem",
                }}
                showSearch={true}
                onChange={(e) => addChange("copyfromproject", e)}
                filterOption={(iv, op) => {
                  let opToUse = op?.children ? op.children : "";
                  return opToUse.toLowerCase().includes(iv.toLowerCase());
                }}
                dropdownStyle={{ overflowY: "auto" }}
                virtual={false}
              >
                <Option className="option" value={""} />
                {projects.map((project) => (
                  <Option
                    key={project.V_ROWID}
                    className="option"
                    value={project.V_ROWID}
                  >
                    {project.VALUE}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </div>
      </Flex>
    </Modal>
  );
};
