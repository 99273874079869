import {
  useCommonTitlesStore,
  useModuleStore,
} from "../../../../store/useStores";
import { Select } from "antd";
import { useChartStore } from "../../../../store/useStores";
import "./../../css/ultimateChart.css";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const DisplayFunction = ({
  columns,
  sumFieldOptions,
  dependendDate,
  dependendNumber,
}) => {
  const dateField = useChartStore((state) => state.dateField);
  const groupField = useChartStore((state) => state.groupField);
  const sumField = useChartStore((state) => state.sumField);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const setDisplayFunction = useChartStore((state) => state.setDisplayFunction);

  const dateTypes = useModuleStore((state) => state.dateTypes);
  const numberTypes = useModuleStore((state) => state.numberTypes);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();

  let displayFunctionOptions = [
    { value: "count", label: commonTitles.count },
    { value: "pctCount", label: commonTitles.pctCount },
    // { value: "sum", label: commonTitles.sum },
    // { value: "pctSum", label: commonTitles.pctSum },
  ];

  const dateFieldType = columns.filter(
    (column) => column?.F_ID === dateField
  )?.[0]?.F_TYPE;
  const groupFieldType = columns.filter(
    (column) => column?.F_ID === groupField
  )?.[0]?.F_TYPE;

  if (sumFieldOptions.length) {
    if (dateTypes.includes(dateFieldType) || dependendDate) {
      if (groupField) {
        displayFunctionOptions.push({ value: "sum", label: commonTitles.sum });
        displayFunctionOptions.push({
          value: "pctSum",
          label: commonTitles.pctSum,
        });
      }
    } else if (numberTypes.includes(groupFieldType) || dependendNumber) {
      displayFunctionOptions.push({ value: "sum", label: commonTitles.sum });
      displayFunctionOptions.push({
        value: "pctSum",
        label: commonTitles.pctSum,
      });
    } else {
      displayFunctionOptions.filter(
        (item) => !["sum", "pctSum"].includes(item.value)
      );
    }
  }

  return (
    <div key={groupField} className="select">
      <Select
        style={{
          width: width < 1525 ? 120 : 200,
          marginLeft: 0,
          marginRight: 10,
          marginTop: 0,
          marginBottom: -50,
        }}
        showSearch={true}
        filterOption={(iv, op) => {
          let opToUse = op.children ? op.children : "";
          return opToUse.toLowerCase().includes(iv.toLowerCase());
        }}
        dropdownStyle={{
          minWidth: "max-content",
          paddingLeft: 5,
          paddingRight: 5,
          overflowY: "auto",
        }}
        onChange={(e) => setDisplayFunction(e)}
        defaultValue={displayFunction}
        placeholder={`${commonTitles.displayFunction}`}
        virtual={false}
      >
        <Option className="option" key={""} value={null} />
        {displayFunctionOptions.map(({ label, value }) => (
          <Option className="option" key={`${value}-${label}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default DisplayFunction;
