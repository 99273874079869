import Flex from "../../../helpers/components/Flex";
import { useAuthStore } from "../../../store/useStores";

const BannerWrapper = ({ children }) => {
  const bannerColor = useAuthStore((state) => state.bannerColor);

  const style = {
    paddingTop: "5rem",
    position: "fixed",
    paddingLeft: "2rem",
    backgroundColor: bannerColor,
    width: "100%",
    paddingBottom: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
    height: "100px",
  };

  return <Flex style={style}>{children}</Flex>;
};

export default BannerWrapper;
