import { message } from "antd";
import axios from "axios";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";

const apiUpload = async (baseURL, bodyFormData, commonTitles) => {
  const url = `${baseURL}/Data_api/uploadfile`;
  try {
    const response = await axios.post(url, bodyFormData, {
      withCredentials: true,
    });
    console.log("apiUpload: response.data: ", response.data);
    if (response?.data?.STATUS === "Success") {
      // message.success(commonTitles["CO|1|UPLDFILE"], [3]);
      displayResponseMessage(commonTitles["CO|1|UPLDFILE"], 3, "success");
    } else {
      //message.warning(commonTitles["CO|1|NOFILE"], [3]);
      displayResponseMessage(commonTitles["CO|1|NOFILE"], 3, "error");
    }
    const uploadedFile = {
      name: response.data.NAME,
      link: `${baseURL}/attachments/${response.data.UID}`,
      uid: response.data.UID,
      action: "I",
    };
    const uploadStatus = response.data.STATUS;
    return { uploadStatus, uploadedFile };
  } catch (err) {
    //message.error(err, [3]);
    displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
  }
};

export default apiUpload;
