import { useCommonTitlesStore } from "../../../../../../../../store/useStores";
import { addFunctionButtonStyle } from "../../styles/TagItemStyles";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import FunctionsModal from "../../modals/FunctionsModal";

const AddFunctionButton = ({
  tags,
  updateTags,
  showFunctionModal,
  cancelFunctionModal,
  functionModalVisible,
  editFunctionTagInfo,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const addFunction = (id, index) => {
    if (index >= 0) {
      const newTags = [...tags];
      newTags[index] = id;
      updateTags(newTags);
    } else {
      if (id !== "#M_PI#") {
        updateTags([...tags, id, "("]);
      } else {
        updateTags([...tags, id]);
      }
    }

    cancelFunctionModal();
  };

  const deleteFunction = (index) => {
    if (index >= 0) {
      let newTags = [...tags];
      newTags.splice(index, 1);
      updateTags(newTags);
    }
    cancelFunctionModal();
  };
  return (
    <>
      <Button
        icon={
          <PlusOutlined
            style={{ fontSize: 10 }}
            onClick={() => showFunctionModal()}
          />
        }
        style={addFunctionButtonStyle}
        onClick={() => showFunctionModal()}
      >
        {commonTitles["CO|1|ADDMTHFN"]}
      </Button>
      {functionModalVisible && (
        <FunctionsModal
          visible={functionModalVisible}
          addFunction={addFunction}
          deleteFunction={deleteFunction}
          onCancel={cancelFunctionModal}
          editFunctionTagInfo={editFunctionTagInfo}
        />
      )}
    </>
  );
};

export default AddFunctionButton;
