import AutomaticIdType from "./automaticIdType/AutomaticIdType";
import DateType from "./dateType/onlyDate/DateType";
import DependentType from "./dependentType/DependentType";
import DocumentType from "./documentType/DocumentType";
import ImageType from "./imageType/ImageType";
import TextType from "./textType/TextType";
import TextareaType from "./textareaType/TextareaType";
import DateTime from "./dateType/dateTime/DateTime";
import MultipleSelectionType from "./otherModuleType/MultipleSelectionType";
import SingleSelectionType from "./otherModuleType/SingleSelectionType";
import StaticListType from "./staticListType/StaticListType";
import EmailType from "./emailType/EmailType";
import PhoneType from "./phoneType/PhoneType";
import CheckboxType from "./checkboxType/CheckboxType";
import Flex from "../../../../helpers/components/Flex";
import GeneralCase from "./generalCase/GeneralCase";
import ButtonType from "./buttonType/ButtonType";
import NumberType from "./numberType/NumberType";
import CountingField from "./countingField/CountingField";

const inputDecider = (
  type,
  id,
  value,
  staticOptions,
  foreignKeyValues,
  addChange,
  config,
  required,
  dependentFields,
  row,
  canupdate,
  canupdateifnull,
  moduleID,
  autoCompletedFields,
  render,
  size,
  parentField,
  changes,
  decimal
) => {
  if (type === "0" /* type = single line*/) {
    return <Flex flexDirection={"row"} />;
  }

  if (type === "1" /* type = text*/) {
    return (
      <TextType
        value={value}
        id={id}
        config={config}
        required={required}
        addChange={addChange}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        row={row}
        autoCompletedFields={autoCompletedFields}
        size={size}
      />
    );
  }

  if (type === "27" /* type = textarea*/) {
    return (
      <TextareaType
        value={value}
        id={id}
        config={config}
        required={required}
        addChange={addChange}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        row={row}
        autoCompletedFields={autoCompletedFields}
        size={size}
      />
    );
  }

  if (type === "29" /* type = automatic id */) {
    return <AutomaticIdType value={value} config={config} />;
  }

  if (type === "30" /* type = dependent field*/) {
    return (
      <DependentType
        value={value}
        id={id}
        addChange={addChange}
        config={config}
        foreignKeyValues={foreignKeyValues}
        staticOptions={staticOptions}
        dependentFields={dependentFields}
      />
    );
  }

  if (type === "24" /* type = Document link */) {
    return (
      <DocumentType
        value={value}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
      />
    );
  }

  if (type === "25" /* type = Single image link */) {
    return (
      <ImageType
        value={value}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
      />
    );
  }

  if (type === "2" /* type = integer */ || type === "3" /* type = decimal */) {
    return (
      <NumberType
        value={value}
        id={id}
        config={config}
        required={required}
        addChange={addChange}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        row={row}
        autoCompletedFields={autoCompletedFields}
        size={size}
        type={type}
        render={render}
      />
    );
  }

  if (type === "4" /* type = date*/) {
    return (
      <DateType
        value={value}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        row={row}
        autoCompletedFields={autoCompletedFields}
        render={render}
      />
    );
  }

  if (type === "5" /* type = date and time*/) {
    return (
      <DateTime
        value={value}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        row={row}
        autoCompletedFields={autoCompletedFields}
        render={render}
      />
    );
  }

  if (type === "28" /* type = Values from another module (multiple)*/) {
    const key = changes?.[parentField] ?? "nokey";
    return (
      <MultipleSelectionType
        key={key}
        row={row}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        foreignKeyValues={foreignKeyValues}
        autoCompletedFields={autoCompletedFields}
        changes={changes}
        parentField={parentField}
        moduleID={moduleID}
      />
    );
  }

  if (type === "7" /* type = Values from another module */) {
    const key = changes?.[parentField] ?? "nokey";
    return (
      <SingleSelectionType
        key={key}
        value={value}
        row={row}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        foreignKeyValues={foreignKeyValues}
        autoCompletedFields={autoCompletedFields}
        changes={changes}
        parentField={parentField}
        moduleID={moduleID}
      />
    );
  }

  if (type === "6" /* type = static list */) {
    return (
      <StaticListType
        value={value}
        row={row}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        staticOptions={staticOptions}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "33" /* type = email*/) {
    return (
      <EmailType
        value={value}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        row={row}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "36" /* type = phone*/) {
    return (
      <PhoneType
        value={value}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        row={row}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "99" /* type === Checkbox */) {
    return (
      <CheckboxType
        value={value}
        id={id}
        config={config}
        addChange={addChange}
        required={required}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
        row={row}
      />
    );
  }

  if (type === "26" /* type = Button */) {
    return <ButtonType moduleID={moduleID} />;
  }

  if (type === "116" /* type = counting field */) {
    return (
      <CountingField
        value={value}
        id={id}
        config={config}
        required={required}
        addChange={addChange}
        decimal={decimal}
      />
    );
  }

  return (
    <GeneralCase
      value={value}
      id={id}
      config={config}
      addChange={addChange}
      required={required}
      canupdate={canupdate}
      canupdateifnull={canupdateifnull}
      row={row}
    />
  );
};

export default inputDecider;
