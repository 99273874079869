import axios from "axios";

const apiGetValueColor = async (baseURL, fieldID, value) => {
  const url = `${baseURL}/Data_api/getvaluecolor`;

  try {
    const response = await axios.post(
      url,
      { fieldid: fieldID, value: value },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    //console.log("Data_api/getvaluecolor: response: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiGetValueColor;
