import "./modulesConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

const ModulesConfigHead = ({ headers, sort, decideSortIcon }) => {
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell key={"ghost-cell"} />
        {headers
          .filter((header, index) => index !== 1 && index !== 6)
          .map((item) => (
            <TableCell
              key={item.F_ID}
              className="admin-header"
              onClick={() => sort(item.F_ID)}
              align={item.F_ID === "M_PROJECTSPECIFIC" ? "center" : "left"}
            >
              <p key={`${item} - text`}>
                {decideSortIcon(item.F_ID, item.TITLE)}
              </p>
            </TableCell>
          ))}
        <TableCell width="10%" />
        <TableCell width="10%" />
      </TableRow>
    </TableHead>
  );
};

export default ModulesConfigHead;
