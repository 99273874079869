import axios from "axios";

async function apiGetTitles(
  workspaceID,
  projectID,
  language,
  setModTitles,
  baseURL
) {
  //const url = `${baseURL}/App_titles_api/get_titles/${workspaceID}/ME/${language}/${projectID}`;
  const url = `${baseURL}/App_titles_api/get_titles`;
  try {
    const response = await axios.post(
      url,
      {
        workspaceid: workspaceID,
        reftype: "ME",
        lang: language,
        projectid: projectID,
      },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    //console.log("apiGetTitles: response.data: ", response?.data);

    const commonData = response?.data["ME|1002|"];
    const modules = response?.data["ME|1001|"];
    const administration = response?.data["ME|1|"];
    const menu = response?.data["ME|2|"];
    const alertConfig = response?.data["ME|9|"];
    const emailsConfig = response?.data["ME|20|"];
    const userRoles = response?.data["ME|10|"];
    const users = response?.data["ME|11|"];
    const languages = response?.data["ME|12|"];
    const projects = response?.data["ME|4|"];
    const clients = response?.data["ME|13|"];
    const consultants = response?.data["ME|14|"];
    const sites = response?.data["ME|15|"];
    const systemTitles = response?.data["ME|17|"];
    const alerts = response?.data["ME|5|"];
    const logout = response?.data["ME|8|"];

    const temp = {
      commonData,
      modules,
      administration,
      menu,
      alertConfig,
      emailsConfig,
      userRoles,
      users,
      languages,
      projects,
      clients,
      consultants,
      sites,
      systemTitles,
      alerts,
      logout,
    };

    setModTitles(temp);
  } catch (err) {}
}

export default apiGetTitles;
