import { Select } from "antd";

const { Option } = Select;

const SelectInput = ({
  id,
  multiple,
  defaultValue,
  addChange,
  options = [],
}) => {
  return (
    <div className="select">
      <Select
        style={{
          width: 300,
          marginLeft: 15,
          marginBottom: "1rem",
        }}
        mode={multiple}
        onChange={(e) => {
          addChange(id, e);
        }}
        showSearch={true}
        defaultValue={defaultValue}
        filterOption={(iv, op) => {
          let opToUse = op?.children ? op.children : "";
          return opToUse.toLowerCase().includes(iv.toLowerCase());
        }}
        dropdownStyle={{ overflowY: "auto" }}
        virtual={false}
      >
        <Option className="option" value={""} />
        {options.map((option) => (
          <Option key={option.ID} className="option" value={option.ID}>
            {option.T_TITLE}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SelectInput;
