import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  useAuthStore,
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { Radio, Space } from "antd";

const LineGroupMode = () => {
  const lineGroupMode = useChartStore((state) => state.lineGroupMode);
  const setLineGroupMode = useChartStore((state) => state.setLineGroupMode);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const language = useAuthStore((state) => state.language);
  const [options, setOptions] = useState([]);

  useEffect(
    () =>
      setOptions([
        { value: false, label: commonTitles["CO|1|CUMULATIVE"] },
        { value: true, label: commonTitles["CO|1|INDIVIDUAL"] },
      ]),
    [language, commonTitles]
  );

  return (
    <FormControl sx={{ color: "rgb(8, 8, 46)", mt: 2 }}>
      <FormLabel
        sx={{ color: "rgb(84, 190, 243)", fontSize: "15px", mb: "10px" }}
        id="demo-row-radio-buttons-group-label"
      >
        {commonTitles.groupStyle}
      </FormLabel>
      <Radio.Group
        onChange={(e) => setLineGroupMode(e.target.value)}
        value={lineGroupMode}
      >
        <Space direction="vertical">
          {options.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </FormControl>
  );
};

export default LineGroupMode;
