import Flex from "../../../../helpers/components/Flex";
import InputDecider from "./InputDecider";
import HeaderDecider from "./HeaderDecider";

const DisplayDecider = ({
  id,
  title,
  data,
  addChange,
  moduleID,
  updateModuleID,
  modules,
  triggerField,
  triggerFieldOptions,
  titles,
  notifyFieldOptions,
  templates,
  infoFields,
  notificationText,
  updateNotificationText,
  config,
  fields,
  notificationType,
  updateNotificationType,
  changes,
  periodType,
  updatePeriodType,
}) => {
  return (
    <Flex key={id} flexDirection={"row"} style={{ alignItems: "start" }}>
      <HeaderDecider
        id={id}
        title={title}
        changes={changes}
        data={data}
        config={config}
        notificationType={notificationType}
      />
      <InputDecider
        id={id}
        data={data}
        addChange={addChange}
        moduleID={moduleID}
        updateModuleID={updateModuleID}
        modules={modules}
        triggerField={triggerField}
        triggerFieldOptions={triggerFieldOptions}
        titles={titles}
        notifyFieldOptions={notifyFieldOptions}
        templates={templates}
        infoFields={infoFields}
        notificationText={notificationText}
        updateNotificationText={updateNotificationText}
        config={config}
        fields={fields}
        notificationType={notificationType}
        updateNotificationType={updateNotificationType}
        periodType={periodType}
        updatePeriodType={updatePeriodType}
      />
    </Flex>
  );
};

export default DisplayDecider;
