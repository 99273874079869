import Flex from "../../../../../../helpers/components/Flex";
import NewFieldTitle from "./NewFieldTitle";
import NewFieldType from "./NewFieldType";
import NewFieldInfo from "./NewFieldInfo";

const NewFieldBasicInfo = ({
  fields,
  changes,
  addChange,
  staticOptions,
  changeType,
}) => {
  return (
    <Flex flexDirection={"column"} style={{ gap: "0.5rem", marginTop: "2rem" }}>
      <NewFieldTitle changes={changes} addChange={addChange} fields={fields} />
      <NewFieldType
        changes={changes}
        addChange={addChange}
        fields={fields}
        staticOptions={staticOptions}
        changeType={changeType}
      />
      <NewFieldInfo changes={changes} addChange={addChange} fields={fields} />
    </Flex>
  );
};

export default NewFieldBasicInfo;
