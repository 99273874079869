import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";

const createData = (alerts, commonTitles) => {
  let temp = [];
  for (let i = 0; i < alerts.alerts?.length; i++) {
    let info = [];
    const currentAlert = alerts.alerts[i];
    const alertID = currentAlert.A_ID;
    const moduleID = alerts.alerttypes[alertID].MODULEID;
    const infoFieldIDs = alerts.alerttypes[alertID].INFOFIELDIDS;
    const infoFieldValues = currentAlert.INFOFIELDSARR;

    for (let i = 0; i < infoFieldIDs.length; i++) {
      info.push({
        title:
          htmlCodesRemover(
            alerts.fields[moduleID].filter(
              (field) => field.F_ID === infoFieldIDs[i]
            )?.[0]?.T_TITLE
          ) ?? "",
        value: htmlCodesRemover(infoFieldValues[i]),
      });
    }

    temp.push({
      //key: alerts["alerts"][i]["V_ID"],
      //rowID: alerts["alerts"][i]["V_ROWID"],
      //moduleID: alerts["alerttypes"][alerts["alerts"][i]["A_ID"]].MODULEID,
      //module:
      //   alerts["modules"][
      //     alerts["alerttypes"][alerts["alerts"][i]["A_ID"]]["MODULEID"]
      //   ],
      //info: htmlCodesRemover(alerts["alerts"][i]["INFOFIELDS"]),
      //type: htmlCodesRemover(
      //   alerts["alerttypes"][alerts["alerts"][i]["A_ID"]]["TRIGGERFIELDNAME"]
      // ),
      key: currentAlert.V_ID,
      rowID: currentAlert.V_ROWID,
      moduleID: moduleID,
      module: alerts.modules[moduleID],
      info: info,
      type: htmlCodesRemover(alerts.alerttypes[alertID].TRIGGERFIELDNAME),
      date: currentAlert.V_VALUE,
      status: {
        view:
          currentAlert.EXPIRED === "Y"
            ? commonTitles["CO|1|EXPIRED"]
            : commonTitles["CO|1|TOBEEXPRD"],
      },
    });
  }

  return temp;
};

export default createData;
