import { Card, Image } from "antd";
import infoDisplayDecider from "../../helpers/infoDisplayDecider/infoDisplayDecider";
import { useCommonTitlesStore } from "../../../../store/useStores";
import { EyeOutlined } from "@ant-design/icons";
import Flex from "../../../../helpers/components/Flex";
import { useState } from "react";

const MiniCard = ({ columns, row, config, foreignKeyValues }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [visible, setVisible] = useState(false);

  let columnToShow = columns
    .filter((column) => column.F_LIST === "Y")
    .filter((column) => column.F_FRAME === "Y")
    .filter((column, index) => index === 0);

  if (!columnToShow.length) {
    columnToShow = columns.filter((column, index) => index === 0);
  }

  if (columnToShow[0].F_TYPE === "25") {
    return (
      <div onClick={() => setVisible(false)}>
        {row[columnToShow[0].F_ID]?.[0]?.url ? (
          <Image
            id="image"
            width={250}
            height={"fit-content"}
            src={row[columnToShow[0].F_ID]?.[0]?.url}
            alt={row[columnToShow[0].F_ID]?.[0]?.name}
            preview={{
              visible,
              onVisibleChange: (value) => {
                setVisible(value);
              },
              mask: (
                <Flex
                  flexDirection={"row"}
                  style={{ gap: "0.5rem", alignItems: "center" }}
                >
                  <EyeOutlined />
                  <p>{commonTitles["CO|1|PREVIEW"]}</p>
                </Flex>
              ),
            }}
          />
        ) : (
          <Card bodyStyle={{ display: "flex", justifyContent: "center" }}>
            <p style={{ marginLeft: 8 }}>
              <i>{commonTitles["CO|1|NOIMGAV"]}</i>
            </p>
          </Card>
        )}
      </div>
    );
  }

  return (
    <Card
      style={{
        backgroundColor: "rgb(8, 8, 46)",
        borderRadius: "10px",
        width: "250px",
        maxWidth: "250px",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        display: "flex",
        wordWrap: "break-word",
      }}
      bodyStyle={{
        maxWidth: "230px",
        minHeight: "max-content",
        overflow: "hidden",
        wordWrap: "break-word",
      }}
      className="id-card"
    >
      {columnToShow.map(
        ({ F_ID, F_TYPE, T_TITLE, F_RENDER, F_FRAME }, index) => (
          <div key={`info-card-${F_ID}`}>
            <h5>{T_TITLE}</h5>
            <h1>
              {infoDisplayDecider(
                F_RENDER,
                F_TYPE,
                row[F_ID],
                config,
                columns,
                row,
                row[`${F_ID}-server`] ?? null,
                foreignKeyValues,
                F_ID,
                true
              )}
            </h1>
          </div>
        )
      )}
    </Card>
  );
};

export default MiniCard;
