import Flex from "../../../../../../../helpers/components/Flex";
import { WarningOutlined } from "@ant-design/icons";
import { useCommonTitlesStore } from "../../../../../../../store/useStores";
import { useState } from "react";
import { Input } from "antd";

const EmailType = ({ id, required, addChange, autoCompletedFields }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [validEmail, setValidEmail] = useState(true);

  const checkEmailValidity = (email) => {
    if (email) {
      setValidEmail(/\S+@\S+\.\S+/.test(email));
    } else {
      setValidEmail(true);
    }
  };

  let value = "";
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    value = autoCompletedFields?.[id] ?? "";
  }

  return (
    <Flex flexDirection={"column"}>
      <Flex flexDirection={"row"}>
        <Input
          type="text"
          className="edit-input"
          style={{ minWidth: 275 }}
          onChange={(e) => {
            checkEmailValidity(e.target.value);
            addChange(id, e.target.value, null);
          }}
          defaultValue={value}
          disabled={value ? true : false}
        />
        {required === "Y" && (
          <p style={{ color: "grey", marginTop: 8 }}>
            <WarningOutlined />
          </p>
        )}
      </Flex>
      {!validEmail && (
        <p
          style={{
            marginTop: "0.5rem",
            marginLeft: "0.5rem",
            fontStyle: "italic",
            color: "red",
          }}
        >
          {commonTitles["CO|1|INVLDEMAIL"]}
        </p>
      )}
    </Flex>
  );
};

export default EmailType;
