import Flex from "../../helpers/components/Flex";
import {
  useCommonTitlesStore,
  usePaginationStore,
} from "../../store/useStores";
import { Select, Pagination } from "antd";

const { Option } = Select;

const UltimateTablePagination = ({
  updateCurrentPage,
  updatePageSize,
  total,
  moduleID,
  showGroup,
}) => {
  const currentPage = usePaginationStore((state) => state.currentPage);
  const pageSize = usePaginationStore((state) => state.pageSize);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Flex
      flexDirection={"row"}
      style={{ marginTop: "0.5rem", justifyContent: "center" }}
    >
      <Pagination
        size="small"
        current={currentPage}
        showSizeChanger={false}
        responsive={false}
        total={total}
        pageSize={showGroup ? total : pageSize?.[moduleID] ?? 20}
        onChange={(page, pageSize) => updateCurrentPage(page, pageSize)}
      />

      <Select
        style={{
          width: 150,
          marginLeft: 7,
        }}
        disabled={showGroup && true}
        showSearch={true}
        onChange={(e) => updatePageSize(e)}
        filterOption={(iv, op) => {
          let opToUse = op.children ? op.children : "";
          return opToUse.toLowerCase().includes(iv.toLowerCase());
        }}
        defaultValue={showGroup ? total : pageSize?.[moduleID] ?? 20}
        dropdownStyle={{ overflowY: "auto" }}
        virtual={false}
      >
        <Option key={10} className="option" value={10}>
          {commonTitles["CO|1|10PPAGE"]}
        </Option>
        <Option key={20} className="option" value={20}>
          {commonTitles["CO|1|20PPAGE"]}
        </Option>
        <Option key={50} className="option" value={50}>
          {commonTitles["CO|1|50PPAGE"]}
        </Option>
        <Option key={total} className="option" value={total}>
          {commonTitles["CO|1|SHOWALL"]}
        </Option>
      </Select>
    </Flex>
  );
};

export default UltimateTablePagination;
