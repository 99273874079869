import { useCommonTitlesStore } from "../../../../../../../store/useStores";

const useFunctionTags = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const functionTags = {
    ["#sqrt#"]: {
      value: "sqrt",
      tooltip: (
        <p style={{ display: "flex", flexDirection: "row" }}>
          {commonTitles["CO|1|EXEMGRA"]}
          <span style={{ marginLeft: 4 }}>&#8730;</span>
          <span style={{ borderTop: "1px solid white" }}>x</span> = sqrt(x)
        </p>
      ),
      title: commonTitles["CO|1|MATHSQRT"],
    },
    ["#abs#"]: {
      value: "abs",
      tooltip: (
        <p style={{ display: "flex", flexDirection: "row" }}>
          {commonTitles["CO|1|EXEMGRA"]} |x| = abs(x)
        </p>
      ),
      title: commonTitles["CO|1|MATHABS"],
    },

    ["#exp#"]: {
      value: "exp",
      tooltip: (
        <p style={{ display: "flex", flexDirection: "row" }}>
          {commonTitles["CO|1|EXEMGRA"]} e<sup>x</sup> = exp(x)
        </p>
      ),
      title: commonTitles["CO|1|MATHEXP"],
    },
    ["#log#"]: {
      value: "log",
      tooltip: (
        <p style={{ display: "flex", flexDirection: "row" }}>
          {commonTitles["CO|1|EXEMGRA"]} log(x) = log(x)
        </p>
      ),
      title: commonTitles["CO|1|MATHLOG"],
    },
    ["#pow#"]: {
      value: "pow",
      tooltip: (
        <p style={{ display: "flex", flexDirection: "row" }}>
          {commonTitles["CO|1|EXEMGRA"]} x<sup>3</sup> = pow(x,3)
        </p>
      ),
      title: commonTitles["CO|1|MATHPOW"],
    },
    ["#M_PI#"]: {
      value: "π",
      tooltip: (
        <p style={{ display: "flex", flexDirection: "row" }}>π = 3.14</p>
      ),
      title: commonTitles["CO|1|MATHPI"],
    },
  };

  return functionTags;
};

export default useFunctionTags;
