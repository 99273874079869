import { Input } from "antd";
import { editInputStyle } from "../../styles/TagItemStyles";
import { useRef, useEffect } from "react";

const EditInput = ({
  index,
  editInputValue,
  handleEditInputChange,
  handleEditInputConfirm,
}) => {
  const editInputRef = useRef(null);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  return (
    <Input
      ref={editInputRef}
      key={`${index}-editInput`}
      size="small"
      style={editInputStyle}
      value={editInputValue}
      onChange={handleEditInputChange}
      onBlur={handleEditInputConfirm}
      onPressEnter={handleEditInputConfirm}
    />
  );
};

export default EditInput;
