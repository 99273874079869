import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
import { MenuOutlined } from "@ant-design/icons";
import { Table } from "antd";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { useCommonTitlesStore } from "../../../../../store/useStores";
import htmlCodesRemover from "../../../../ultimateTable/helpers/htmlCodesRemover";

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: "grab",
      color: "#999",
    }}
  />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const mySort = (data, hasOrder = () => true) => {
  let order = 1;

  return data.map((row) => ({
    ...row,
    order: hasOrder(row) ? order++ : null,
  }));
};

const ReportTable = ({ data, updateReportParams }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    let selectedRowKeysIDs = [];
    selectedRowKeys.forEach((item) => {
      if (reportData.length) {
        reportData.forEach((datum) => {
          if (datum.index === item) {
            selectedRowKeysIDs.push(datum.id);
          }
        });
      }
    });
    updateReportParams("selectedfields", selectedRowKeysIDs);
  }, [selectedRowKeys]);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: 30,
      className: "drag-visible",
      render: (d, dd, i) => <DragHandle />,
    },
    {
      title: `${commonTitles.selectedFields}`,
      dataIndex: "field",
      key: "field",
      className: "drag-visible",
    },
    {
      title: `${commonTitles.displayOrder}`,
      dataIndex: "order",
      key: "order",
    },
  ];

  const reportData = data
    .map(({ T_TITLE, F_ID }) => ({
      key: htmlCodesRemover(T_TITLE),
      id: F_ID,
      field: htmlCodesRemover(T_TITLE),
      index: htmlCodesRemover(T_TITLE),
      order: htmlCodesRemover(T_TITLE),
    }))
    .filter((e) => !["HANDLING", "ΔΙΑΧΕΙΡΙΣΗ"].includes(e.field));

  for (let i = 1; i < reportData?.length + 1; i++) {
    reportData[i - 1] = {
      ...reportData[i - 1],
      key: `${i}`,
      order: `${i}`,
    };
  }

  const [dataSource, setDataSource] = useState(reportData);

  useEffect(() => {
    const keys = reportData.map(({ index }) => index);
    setSelectedRowKeys(keys);

    const dataSourceTemp = mySort(dataSource, () => true);
    setDataSource(dataSourceTemp);
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let dataSourceTemp = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      dataSourceTemp = mySort(dataSourceTemp, (row) => row.order !== null);

      setDataSource(dataSourceTemp);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      const dataSourceTemp = mySort(dataSource, (row) =>
        selectedRowKeys.includes(row.index)
      );
      setDataSource(dataSourceTemp);
    },
  };

  useEffect(() => {
    const sortedSelectedRowKeys = dataSource
      .filter((item) => item.order !== null)
      .map((obj) => obj.index);

    setSelectedRowKeys(sortedSelectedRowKeys);
  }, [dataSource]);

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      rowSelection={{ ...rowSelection, selectedRowKeys }}
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      rowKey="index"
      size="small"
      //scroll={{ x: 60 }}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default ReportTable;
