import { useEffect, useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { loginUsingGet, loginUsingPost } from "../../../../api/login/login";
import { Button, Card, message, Input } from "antd";
import { Box } from "@material-ui/core";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";

const Login = ({ changeFormType }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const setLanguage = useAuthStore((state) => state.setLanguage);
  const setUserID = useAuthStore((state) => state.setUserID);
  const setWorkspaceID = useAuthStore((state) => state.setWorkspaceID);
  const setProjectID = useAuthStore((state) => state.setProjectID);
  const setRole = useAuthStore((state) => state.setRole);
  const setRoleID = useAuthStore((state) => state.setRoleID);
  const username = useAuthStore((state) => state.username);
  const password = useAuthStore((state) => state.password);
  const setFirstName = useAuthStore((state) => state.setFirstName);
  const setLastName = useAuthStore((state) => state.setLastName);
  const setUsername = useAuthStore((state) => state.setUsername);
  const setPassword = useAuthStore((state) => state.setPassword);
  const setDateFormat = useAuthStore((state) => state.setDateFormat);
  const setTimeFormat = useAuthStore((state) => state.setTimeFormat);
  const loginStatus = useAuthStore((state) => state.loginStatus);
  const setLoginStatus = useAuthStore((state) => state.setLoginStatus);
  const setDarkTheme = useAuthStore((state) => state.setDarkTheme);
  // const message = useAuthStore((state) => state.message);
  // const setMessage = useAuthStore((state) => state.setMessage);

  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");

  // useEffect(() => {
  //   usernameRef.current.focus();
  // }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const onSuccess = (data) => {
    if (!data?.login_status) {
      message.error(commonTitles[data?.message], [3]);
      //displayResponseMessage(commonTitles[data?.message], 3, "error");
      return;
    }

    const firstName = data?.currentuser?.USER_FIRSTNAME;
    const lastName = data?.currentuser?.USER_LASTNAME;

    let darkThemeValue = true;

    if (data?.currentuser?.USER_THEME) {
      if (data?.currentuser?.USER_THEME === "N") {
        darkThemeValue = false;
      }
    }

    setLoginStatus(data?.login_status);
    setFirstName(firstName);
    setLastName(lastName);
    setUsername(firstName + " " + lastName);
    setRole(data?.user_role);
    setRoleID(data?.user_roleid);
    setLanguage(data?.currentuser?.USER_LANGUAGE);
    setUserID(data?.user_id);
    setWorkspaceID(data?.workspace_id);
    setProjectID(data?.project_id);
    setDateFormat(data?.user_dateformat);
    setTimeFormat(data?.user_timeformat);
    setDarkTheme(darkThemeValue);
  };

  const usingGet = async () => {
    const data = await loginUsingGet({ username, password, baseURL });
    onSuccess(data);
  };

  const usingPost = async () => {
    const data = await loginUsingPost({ username, password, baseURL });
    onSuccess(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //await usingGet();
      await usingPost();
    } catch (err) {
      //   if (!err?.response) {
      //     setErrMsg("No Server Response");
      //   } else if (err.response?.status === 400) {
      //     setErrMsg("Missing Username or Password");
      //   } else if (err.response?.status === 401) {
      //     setErrMsg("Unauthorized");
      //   } else {
      //     setErrMsg("Login Failed");
      //   }
      //   errRef.current.focus();
    }
  };

  if (loginStatus === "Success") {
    navigate("/home");
  }

  if (loginStatus === "ChangePassword") {
    navigate("/changePassword");
  }

  return (
    <section className="login">
      <Box className="login-box">
        {/* <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p> */}

        <h1>{commonTitles.login}</h1>

        <Card
          className="login-card"
          style={{
            borderRadius: "10px",
            border: "#0a1738",
            overflow: "hidden",
          }}
        >
          <form onSubmit={handleSubmit} className="login-form">
            <p style={{ marginBottom: 5 }}>{commonTitles.username}:</p>
            {/* <input
              type="text"
              style={{ color: "black" }}
              id="username"
              //ref={usernameRef}
              autoComplete="off"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
            /> */}
            <Input
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                marginRight: "1rem",
                marginBottom: "1.5rem",
                width: "18.5rem",
                borderRadius: 10,
                color: "black",
                backgroundColor: "white",
              }}
              required
            />

            <p style={{ marginBottom: 5 }}>{commonTitles.password}:</p>
            {/* <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            /> */}
            <Input.Password
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              width={"3rem"}
              style={{
                marginRight: "1rem",
                marginBottom: "1rem",
                width: "18.5rem",
                borderRadius: 10,
                color: "black",
                backgroundColor: "white",
              }}
              required
            />
            <button className="login__signInButton">
              {commonTitles.login}
            </button>

            {/* <Button
              className="login__toRegister"
              onClick={() => changeFormType("register")}
            >
              {commonTitles.regNewUser}
            </Button> */}
          </form>
        </Card>
      </Box>
    </section>
  );
};

export default Login;
