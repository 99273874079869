const createHeaders = (titles) => {
  let headers = [];
  if (Object.keys(titles).length) {
    headers = [
      { id: "N_MODULEID", title: titles["FD|376|TITLE"], align: "left" },
      { id: "N_TYPE", title: titles["FD|377|TITLE"], align: "left" },
      {
        id: "N_TRIGGER_FIELDID",
        title: titles["FD|359|TITLE"],
        align: "left",
      },
      {
        id: "N_WARNING_BEFORE",
        title: titles["FD|360|TITLE"],
        align: "center",
      },
      {
        id: "N_RAISED_AFTER",
        title: titles["FD|361|TITLE"],
        align: "center",
      },
      {
        id: "N_PERIOD_NUM",
        title: titles["FD|362|TITLE"],
        align: "center",
      },
      {
        id: "N_PERIOD_TYPE",
        title: titles["FD|363|TITLE"],
        align: "center",
      },
      {
        id: "N_WEEK_DAY",
        title: titles["FD|379|TITLE"],
        align: "left",
      },
      { id: "N_EVENT", title: titles["FD|364|TITLE"], align: "left" },
      { id: "N_NOTIFY_FIELD", title: titles["FD|365|TITLE"], align: "left" },
      { id: "N_NOTIFY_EMAILS", title: titles["FD|366|TITLE"], align: "left" },
      { id: "N_TEMPLATEID", title: titles["FD|367|TITLE"], align: "left" },
      // {
      //   id: "N_EXPIREINDICATION",
      //   title: titles["FD|368|TITLE"],
      //   align: "center",
      // },
      {
        id: "NOTIFICATIONTEXT",
        title: titles["FD|369|TITLE"],
        align: "left",
      },
      { id: "N_CONTACT", title: titles["FD|370|TITLE"], align: "left" },
      {
        id: "INFORMATIONFIELDS",
        title: titles["FD|371|TITLE"],
        align: "left",
      },
      { id: "N_ACTIVE", title: titles["FD|373|TITLE"], align: "center" },
      { id: "N_FILTER", title: titles["FD|372|TITLE"], align: "center" },
    ];
  } else {
    headers = [];
  }

  return headers;
};

export default createHeaders;
