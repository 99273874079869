import { message } from "antd";
import axios from "axios";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";

const apiImport = async (baseURL, bodyFormData, commonTitles) => {
  const url = `${baseURL}/Data_api/importfile`;
  try {
    const response = await axios.post(url, bodyFormData, {
      withCredentials: true,
    });

    console.log("apiImport: response.data: ", response.data);
    return response?.data;
  } catch (err) {
    displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
  }
};

export default apiImport;
