import Flex from "../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../store/useStores";
import { Button } from "antd";

const ModalFooterButtons = ({
  data,
  changes,
  config,
  onSave,
  onCancel,
  showDeleteModal,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Flex flexDirection={"row"} style={{ justifyContent: "flex-end" }}>
      <Button key="back" onClick={() => onCancel(config)}>
        {commonTitles["CO|1|CANCELBTN"]}
      </Button>

      {config === "edit" && (
        <Button
          key="delete"
          style={{ backgroundColor: "red", color: "white" }}
          onClick={() => showDeleteModal(data?.N_ID)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>
      )}
      <Button
        key="submit"
        type="primary"
        style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
        onClick={() => onSave(changes)}
      >
        {commonTitles["CO|1|SAVEBTN"]}
      </Button>
    </Flex>
  );
};

export default ModalFooterButtons;
