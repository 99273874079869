import "./home.css";
import Navigation from "../navigation/Navigation";
import Banner from "./banner/Banner";
import { useState } from "react";
import { useEffect } from "react";
import {
  useAlertStore,
  useAuthStore,
  useModTitlesStore,
  useCommonTitlesStore,
  usePaginationStore,
} from "../../store/useStores";
import UltimateTable from "../ultimateTable/UltimateTable";
import createColumns from "./helpers/createColumns";
import createData from "./helpers/createData";
import filterAlertsOnSearch from "./helpers/filterAlertsOnSearch";
import Flex from "../../helpers/components/Flex";
import apiGetAlerts from "../../api/endpoints/apiGetAlerts";
import apiGetAlertTitles from "../../api/endpoints/apiGetAlertTitles";
import apiAlertDownload from "../../api/endpoints/apiAlertDonwload";
import apiAlertReport from "../../api/endpoints/apiAlertReport";
import { useLocation } from "react-router-dom";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";
import countTotalRowWidth from "../module/helpers/countTotalRowWidth";
import htmlCodesRemover from "../ultimateTable/helpers/htmlCodesRemover";
import apiGetCommonTitles from "../../api/endpoints/apiGetCommonTitles";
import TableWrapper from "../../helpers/components/TableWrapper";
import BackgroundWrapper from "../../helpers/components/BackgroundWrapper";
import Loader from "./loader/Loader";

const Home = () => {
  const location = useLocation();
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const alertsChanged = useAlertStore((state) => state.alertsChanged);
  const setAlertsChanged = useAlertStore((state) => state.setAlertsChanged);
  const [data, setData] = useState({});
  const [allData, setAllData] = useState({});
  const [foreignKeyValues, setForeignKeyValues] = useState({});
  const [staticOptions, setStaticOptions] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const projectID = useAuthStore((state) => state.projectID);
  const setAlertsAndTitles = useAlertStore((state) => state.setAlertsAndTitles);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const setShowGroup = useAuthStore((state) => state.setShowGroup);
  const [privileges, setPrivileges] = useState({});

  const pageSize = usePaginationStore((state) => state.pageSize);
  const setPageSize = usePaginationStore((state) => state.setPageSize);
  const currentPage = usePaginationStore((state) => state.currentPage);
  const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
  const setCommonTitles = useCommonTitlesStore(
    (state) => state.setCommonTitles
  );

  // const [filters, setFilters] = useState({});
  // const [filterStatus, setFilterStatus] = useState(false);

  // const invertFilterStatus = () => {
  //   setFilterStatus(!filterStatus);
  // };

  let totalRowWidth = 1;

  useEffect(() => setShowGroup(""), []);

  useEffect(async () => {
    setIsLoading(true);

    let updatedCommonTitles = await apiGetCommonTitles(
      language,
      setCommonTitles,
      baseURL
    );

    const alertsData = await apiGetAlerts(baseURL);

    const alertTitlesData = await apiGetAlertTitles(
      workspaceID,
      language,
      baseURL
    );

    setAlertsAndTitles(alertsData, alertTitlesData);

    const tempData = createData(alertsData, updatedCommonTitles);
    setAllData({ ...tempData });
    setData({ ...tempData });

    const columns = createColumns(alertTitlesData, updatedCommonTitles);
    totalRowWidth = countTotalRowWidth(columns) ?? 1;
    setColumnData(columns);

    let hasAnyUpdatePrivileges = false;
    let hasAnyInsertPrivileges = false;

    for (let i = 0; i < Object.keys(alertsData?.fields).length; i++) {
      let key = Object.keys(alertsData?.fields)[i];
      alertsData?.fields[key].forEach((field) => {
        if (field?.canupdate || field?.canupdateifnull) {
          hasAnyUpdatePrivileges = true;
        }
        if (field?.caninsert) {
          hasAnyInsertPrivileges = true;
        }
      });

      const tempPrivileges = {
        ...privileges,
        [key]: {
          canDelete: alertsData?.modulespriv?.[key]?.candelete,
          canInsert:
            alertsData?.modulespriv?.[key]?.caninsert || hasAnyInsertPrivileges
              ? true
              : false,
          canCopy: alertsData?.modulespriv?.[key]?.caninsert,
          canUpdate:
            alertsData?.modulespriv?.[key]?.canupdate || hasAnyUpdatePrivileges
              ? true
              : false,
          canView: alertsData?.modulespriv?.[key]?.canview,
        },
      };

      setPrivileges(tempPrivileges);
    }

    // let moduleForeignKeyValues = {};

    // Object.entries(alertsData?.modules).forEach(([key, value]) => {
    //   moduleForeignKeyValues = {
    //     ...moduleForeignKeyValues,
    //     [key]: { view: value },
    //   };
    // });

    // let tempTypeForeignKeyValues = Object.entries(alertsData?.fields).map(
    //   ([key, value]) => {
    //     let newValue = value.filter((item) => ["4", "5"].includes(item.F_TYPE));
    //     return newValue;
    //   }
    // );

    // let typeForeignKeyValues = {};

    // for (let i = 0; i < tempTypeForeignKeyValues.length; i++) {
    //   for (let j = 0; j < tempTypeForeignKeyValues[i].length; j++) {
    //     typeForeignKeyValues = {
    //       ...typeForeignKeyValues,
    //       [tempTypeForeignKeyValues[i][j].F_ID]: {
    //         view: htmlCodesRemover(tempTypeForeignKeyValues[i][j].T_TITLE),
    //       },
    //     };
    //   }
    // }

    // setForeignKeyValues({
    //   module: moduleForeignKeyValues,
    //   type: typeForeignKeyValues,
    // });

    // const tempStaticOptions = {
    //   status: {
    //     Y: {
    //       ORDER: "1",
    //       BC: "#ffffff",
    //       TITLE: commonTitles["CO|1|EXPIRED"],
    //     },
    //     N: {
    //       ORDER: "2",
    //       BC: "#ffffff",
    //       TITLE: commonTitles["CO|1|TOBEEXPRD"],
    //     },
    //   },
    // };
    // setStaticOptions(tempStaticOptions);

    // const tempFilters = alertsData?.filters ?? {};
    // setFilters(tempFilters);
    setIsLoading(false);
  }, [projectID, language, location.key, alertsChanged]);

  // const updateFilters = (changes, config) => {
  //   if (config === "update") {
  //     setFilters({ ...changes });
  //   }

  //   if (config === "erase") {
  //     setFilters({});
  //   }

  //   invertFilterStatus();
  // };

  const updateData = (changes, showAlert) => {
    // if (showAlert) {
    // let tempData = {};
    // Object.entries(data).forEach(([key, value]) => {
    //   if (value.rowID === changes.rowID) {
    //     tempData = { ...tempData, [key]: changes };
    //   } else {
    //     tempData = { ...tempData, [key]: value };
    //   }
    // });
    // setData(tempData);
    // setAllData(tempData);
    // } else {
    // let tempData = {};
    // Object.entries(data)
    //   .filter(([key, value]) => value.rowID !== changes.rowID)
    //   .forEach(([key, value]) => {
    //     tempData = { ...tempData, [key]: value };
    //   });
    //setData(tempData);
    // setAllData(tempData);
    // }

    setAlertsChanged(!alertsChanged);
  };

  const deleteData = (changes) => {
    setData({ ...changes });
    setAllData({ ...changes });
    setAlertsChanged(!alertsChanged);
  };

  const updateSearchValue = (change) => {
    const tempData = filterAlertsOnSearch(allData, change);

    if (change) {
      setData({ ...tempData });
    } else {
      setData(allData);
    }
  };

  const [orderBy, setOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("");

  const updateOrderConfig = (id, direction) => {
    let convertedID = id;
    let convertedDirection = direction;
    if (direction === "ASC") {
      convertedDirection = "A";
    }

    if (direction === "DESC") {
      convertedDirection = "D";
    }

    if (direction === null) {
      convertedID = null;
    }

    setOrderBy(convertedID);
    setOrderDirection(convertedDirection);
  };

  const downloadPdfReport = async (suffix) => {
    //console.log("downloadPdfReport: suffix: ", suffix);
    const response = await apiAlertReport(baseURL, suffix, commonTitles);
    //console.log("Home: downloadPdfReport: response: ", response);
    if (response.STATUS === "Success") {
      const downloadResponse = await apiAlertDownload(
        baseURL,
        response.MESSAGE,
        `PLCS Suite ${modTitles.alerts ?? "Alerts"}`,
        suffix,
        commonTitles
      );

      console.log("Home: onSuccess: downloadResponse: ", downloadResponse);
      //message.success("Επιτυχής έκδοση αναφοράς", [3]);
    } else {
      //message.error("Παρουσιάστηκε σφάλμα κατά την έκδοση της αναφοράς", [3]);

      displayResponseMessage(
        "Παρουσιάστηκε σφάλμα κατά την έκδοση της αναφοράς",
        5,
        "error"
      );
    }
  };

  const updateCurrentPage = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const updatePageSize = (size) => {
    setPageSize(size);

    if (Math.ceil(Object.values(data).length / size) < currentPage) {
      setCurrentPage(Math.ceil(Object.values(data).length / size));
    }
  };

  const changeDisplayedColumns = (key, viewRights) => {
    const columnsTemp = columnData
      .filter((column) => viewRights[column.F_ID])
      .map((column) => {
        let isImportant = column.F_LIST;

        if (key === "") {
          isImportant = "Y";
        } else {
          if (column.F_ID === key) {
            isImportant = column.F_LIST === "Y" ? "" : "Y";
          }
        }

        return { ...column, F_LIST: isImportant };
      });

    setColumnData(columnsTemp);
  };

  totalRowWidth = countTotalRowWidth(columnData);

  return (
    <Flex
      key={`${location.key} - ${columnData.length}`}
      flexDirection={"column"}
    >
      <Navigation />
      <BackgroundWrapper>
        <Banner
          updateSearchValue={updateSearchValue}
          downloadPdfReport={downloadPdfReport}
        />
        <TableWrapper>
          {isLoading ? (
            <Loader />
          ) : (
            <UltimateTable
              key={columnData.length}
              columns={columnData}
              data={data}
              updateOrderConfig={updateOrderConfig}
              privileges={{
                canView: true,
                canInsert: true,
                canDelete: true,
                canUpdate: true,
                canCopy: true,
                ...privileges,
              }}
              updateData={updateData}
              deleteData={deleteData}
              config={"alerts"}
              totalRowWidth={totalRowWidth}
              updateCurrentPage={updateCurrentPage}
              updatePageSize={updatePageSize}
              changeDisplayedColumns={changeDisplayedColumns}
              //   filters={filters}
              //   foreignKeyValues={foreignKeyValues}
              //   staticOptions={staticOptions}
              //   updateFilters={updateFilters}
            />
          )}
        </TableWrapper>
      </BackgroundWrapper>
    </Flex>
  );
};

export default Home;
