import moment from "moment";

const daysLeft = (date, dateFormat) => {
  const myDate = moment(date, dateFormat).toDate();

  let today = new Date();
  let difference = myDate.getTime() - today.getTime();
  let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

  return totalDays;
};

export default daysLeft;
