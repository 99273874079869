const noGroupNoDate = (
  columns,
  data,
  xAxisFieldToUse,
  xAxisFieldColumn,
  childxAxisField,
  countColumns,
  staticListColumns,
  multipleValuesColumns,
  foreignKeyValues,
  dependentColumns,
  numberAlertColumns,
  commonTitles,
  xValuesUsed,
  yValuesUsed,
  noDateDataColumnStatus
) => {
  const isAlert =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  let xAxisFieldName;
  let childxAxisFieldIndex;
  let childxAxisNumber;

  if (childxAxisField) {
    const childTitle = xAxisFieldColumn.DEP_FIELDS.filter(
      (field) => field.F_ID === childxAxisField
    ).map((item) => item.TITLE);
    xAxisFieldName = `${xAxisFieldColumn.T_TITLE} - ${childTitle}`;
    childxAxisNumber = xAxisFieldColumn.DEP_FIELDS.length;
    const dependentFields = columns.filter(
      (column) => column.F_ID === xAxisFieldToUse
    )[0].DEP_FIELDS;

    childxAxisFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childxAxisField;
    });
  } else {
    xAxisFieldName = xAxisFieldColumn.T_TITLE;
  }

  let xAxisFieldValues = data.map((item) => {
    if (multipleValuesColumns.includes(xAxisFieldToUse)) {
      return item[`${xAxisFieldToUse}-server`];
    } else if (dependentColumns.includes(xAxisFieldToUse)) {
      if (item[xAxisFieldToUse] && item[xAxisFieldToUse].includes(",")) {
        const tempValue = item[xAxisFieldToUse].split(", ");
        const finalValue = tempValue[childxAxisFieldIndex];
        return finalValue;
      } else {
        return item[xAxisFieldToUse];
      }
    } else if (numberAlertColumns.includes(xAxisFieldToUse)) {
      return item[xAxisFieldToUse]?.value;
    } else return item[xAxisFieldToUse];
  });

  let uniqueXAxisFieldValues = [];
  xAxisFieldValues.forEach((item) => {
    if (
      item &&
      staticListColumns.includes(xAxisFieldToUse) &&
      typeof item === "object"
    ) {
      item = item?.view;
    }
    if (item && item.includes(",") && !countColumns.includes(xAxisFieldToUse)) {
      const myArray = item.split(",");
      myArray.forEach((value) => {
        if (value && !uniqueXAxisFieldValues.includes(value)) {
          uniqueXAxisFieldValues.push(value);
        }
      });
    } else {
      if (item && !uniqueXAxisFieldValues.includes(item)) {
        uniqueXAxisFieldValues.push(item);
      }
    }
  });

  if (multipleValuesColumns.includes(xAxisFieldToUse)) {
    uniqueXAxisFieldValues = uniqueXAxisFieldValues.map((value) => {
      if (foreignKeyValues[xAxisFieldToUse][value]?.view.includes(",")) {
        const myArray =
          foreignKeyValues[xAxisFieldToUse][value]?.view.split(", ");
        return myArray[0];
      } else {
        return foreignKeyValues[xAxisFieldToUse][value]?.view;
      }
    });
  }

  uniqueXAxisFieldValues.push(
    `${commonTitles["CO|1|NODATAFOR"]} ${xAxisFieldName}`
  );

  const isStaticList =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "6"
      ? true
      : false;

  const isMultiple =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "28"
      ? true
      : false;

  let dailyCount = {};
  let noDateAvailable = 0;

  for (let i = 0; i < uniqueXAxisFieldValues.length; i++) {
    let noGroupFieldCounter = 0;

    for (let z = 0; z < data.length; z++) {
      let dateValue = data[z][xAxisFieldToUse] ?? "";

      if (isAlert) {
        dateValue = data[z][xAxisFieldToUse]?.value;
      } else if (isStaticList) {
        dateValue = data[z][xAxisFieldToUse]?.view;
      }

      if (
        !dateValue &&
        uniqueXAxisFieldValues[i] ===
          `${commonTitles["CO|1|NODATAFOR"]} ${xAxisFieldName}`
      ) {
        noDateAvailable += 1;
      }

      if (
        dateValue === uniqueXAxisFieldValues[i] ||
        (isMultiple && dateValue.includes(uniqueXAxisFieldValues[i]))
      ) {
        noGroupFieldCounter += 1;
      }
    }

    if (
      uniqueXAxisFieldValues[i] !==
      `${commonTitles["CO|1|NODATAFOR"]} ${xAxisFieldName}`
    ) {
      dailyCount = {
        ...dailyCount,
        [uniqueXAxisFieldValues[i]]: {
          count: noGroupFieldCounter,
          sum: noGroupFieldCounter,
        },
      };
    }
  }

  if (noDateAvailable && !noDateDataColumnStatus) {
    dailyCount = {
      ...dailyCount,
      [`${commonTitles["CO|1|NODATAFOR"]} ${xAxisFieldName}`]: {
        count: noDateAvailable,
        sum: noDateAvailable,
      },
    };
  }

  let totalSum = 0;

  Object.entries(dailyCount).forEach(([key, value]) => {
    Object.values(value).forEach((item) => {
      totalSum += item;
    });
  });

  return {
    dailyCount: { ...dailyCount, totalSum: totalSum },
    monthlyCount: {},
    yearlyCount: {},
  };
};

export default noGroupNoDate;
