import "./clientsConfig.css";
import { Layout, Card, Spin } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ClientsConfigSettings from "./ClientsConfigSettings";
import ClientsConfigHead from "./ClientsConfigHead";
import ClientsConfigBody from "./ClientsConfigBody";
import apiGetData from "../../../api/endpoints/apiGetData";
import {
  useAuthStore,
  useCommonTitlesStore,
  useModTitlesStore,
} from "../../../store/useStores";
import Navigation from "../../navigation/Navigation";
import Flex from "../../../helpers/components/Flex";
import filterDataOnSearch from "../helpers/filterDataOnSearch";
import createData from "../../module/helpers/createData";
import axios from "axios";
import { DeleteModal } from "./deleteModals";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import BackgroundWrapper from "../../../helpers/components/BackgroundWrapper";
import AdministrationBanner from "../administrationBanner/AdministrationBanner";
import TableWrapper from "../../../helpers/components/TableWrapper";

const { Content } = Layout;

const ClientsConfig = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [staticOptions, setStaticOptions] = useState({});
  const [foreignKeyValues, setForeignKeyValues] = useState({});

  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const { rows, fields, staticoptions, fkvalues } = await apiGetData(
      "14",
      baseURL,
      {}
    );
    setIsLoading(false);
    const tempData = createData(rows, fields, fkvalues, staticoptions, baseURL);
    const newTempData = Object.values(tempData);

    setHeadData(fields);
    setForeignKeyValues(fkvalues);
    setStaticOptions(staticoptions);
    setData(newTempData);
    setAllData(newTempData);
  };

  useEffect(() => {
    if (data.length) {
      data.sort((a, b) =>
        a["284"] > b["284"] ? 1 : b["284"] > a["284"] ? -1 : 0
      );
      setData(data);
    }
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    fetchData();
  }, [projectID, language]);

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const updateData = (config, changes) => {
    if (config === "edit") {
      let tempData = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].ROWID === Object.keys(changes)[0]) {
          tempData[i] = { ...data[i], ...Object.values(changes)[0] };
        } else {
          tempData[i] = data[i];
        }
      }
      setModalVisible(false);
      setData(tempData);
      setAllData(tempData);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
      const temp = [...data, Object.values(changes)[0]];
      setData(temp);
      setAllData(temp);
    }
  };

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
    }
  };

  const showDeleteModal = (id) => {
    setDeleteModalVisible(true);
    setItemToDelete(id);
  };
  const cancelDeleteModal = () => setDeleteModalVisible(false);

  const handleDelete = async (id) => {
    const url = `${baseURL}/Data_api/delete`;
    try {
      const response = await axios.post(
        url,
        { moduleid: "5", rowid: id },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Data_api/delete: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|SUCDLTMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const temp = data.filter((item) => item.ROWID !== id);
        setData(temp);
        setAllData(temp);
        setDeleteModalVisible(false);
        setModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [3]);
        displayResponseMessage(template, 3, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateSearchValue = (change) => {
    const tempData = filterDataOnSearch(allData, change, headData);

    if (change) {
      setData([...tempData]);
    } else {
      setData(allData);
    }
  };

  return (
    <Flex flexDirection={"column"}>
      <Navigation />

      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.clients}`}
          updateSearchValue={updateSearchValue}
          showSearch={true}
        />

        <TableWrapper>
          {isLoading ? (
            <Card
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
                width: "98.5vw",
                display: "flex",
                justifyContent: "center",
                height: "85vh",
              }}
            >
              <Spin size="large" style={{ marginTop: "15rem" }} />
            </Card>
          ) : (
            <>
              {headData.length && (
                <Layout
                  key={data.length}
                  style={{
                    borderRadius: 10,
                    overflow: "hidden",
                    height: "max-content",
                    width: "100%",
                  }}
                >
                  <ClientsConfigSettings
                    key={"new-item"}
                    headers={headData}
                    staticOptions={staticOptions}
                    foreignKeyValues={foreignKeyValues}
                    newItemModalVisible={newItemModalVisible}
                    showModal={showModal}
                    updateData={updateData}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                  />

                  <Layout className="site-layout">
                    <Content
                      style={{
                        paddingBottom: 15,
                        paddingLeft: 20,
                        paddingRight: 20,
                        minHeight: "max-content",
                        backgroundColor: "transparent",
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        className="testing_table"
                        style={{ maxHeight: "70vh" }}
                      >
                        <Table stickyHeader>
                          <ClientsConfigHead headers={headData} />
                          <ClientsConfigBody
                            key={`body`}
                            data={data}
                            headers={headData}
                            staticOptions={staticOptions}
                            foreignKeyValues={foreignKeyValues}
                            modalVisible={modalVisible}
                            modalData={modalData}
                            showModal={showModal}
                            updateData={updateData}
                            showDeleteModal={showDeleteModal}
                            handleCancel={handleCancel}
                          />
                        </Table>
                      </TableContainer>
                    </Content>
                  </Layout>
                </Layout>
              )}
            </>
          )}
        </TableWrapper>
      </BackgroundWrapper>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          selectedItems={[itemToDelete]}
          onDelete={handleDelete}
          onCancel={cancelDeleteModal}
        />
      )}
    </Flex>
  );
};

export default ClientsConfig;
