import "./dropdown.css";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpIcon from "@mui/icons-material/Help";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import Language from "../language/Language";
import { Button, Switch } from "antd";
import { UserOutlined } from "@ant-design/icons";
import {
  useAuthStore,
  useCommonTitlesStore,
  useAlertStore,
  useMenuStore,
  useModTitlesStore,
  useFilterStore,
} from "../../../../store/useStores";
import Flex from "../../../../helpers/components/Flex";
import apiChangeTheme from "../../../../api/endpoints/apiChangeTheme";

const Dropdown = ({ updateVisibility }) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const darkTheme = useAuthStore((state) => state.darkTheme);
  const setDarkTheme = useAuthStore((state) => state.setDarkTheme);
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const setAlerts = useAlertStore((state) => state.setAlerts);
  const setAlertTitles = useAlertStore((state) => state.setAlertTitles);
  const setMenu = useMenuStore((state) => state.setMenu);
  const language = useAuthStore((state) => state.language);
  const setLanguage = useAuthStore((state) => state.setLanguage);
  const setUserID = useAuthStore((state) => state.setUserID);
  const setWorkspaceID = useAuthStore((state) => state.setWorkspaceID);
  const setProjectID = useAuthStore((state) => state.setProjectID);
  const setRole = useAuthStore((state) => state.setRole);
  const setShowGroup = useAuthStore((state) => state.setShowGroup);
  const username = useMenuStore((state) => state.username);
  const setFirstName = useAuthStore((state) => state.setFirstName);
  const setLastName = useAuthStore((state) => state.setLastName);
  const setUsername = useAuthStore((state) => state.setUsername);
  const setPassword = useAuthStore((state) => state.setPassword);
  const setDateFormat = useAuthStore((state) => state.setDateFormat);
  const setTimeFormat = useAuthStore((state) => state.setTimeFormat);
  const setLoginStatus = useAuthStore((state) => state.setLoginStatus);
  const setModTitles = useModTitlesStore((state) => state.setModTitles);
  const setFilters = useFilterStore((state) => state.setFilters);
  let navigate = useNavigate();

  const handleLogout = (e) => {
    //setLanguage("EN");
    setLoginStatus("");
    setFirstName("");
    setLastName("");
    setUsername("");
    setPassword("");
    setRole("");
    setShowGroup("");
    setUserID("");
    setWorkspaceID("");
    setProjectID("");
    setDateFormat("");
    setTimeFormat("");
    setAlerts({});
    setAlertTitles({});
    setMenu([]);
    setModTitles({});
    setFilters({});
    navigate("/");
  };

  const updateTheme = async (darkThemeValue) => {
    const response = await apiChangeTheme(baseURL, darkThemeValue);
    console.log("updateTheme: response: ", response);
  };

  return (
    <Flex
      flexDirection={"column"}
      style={{ justifyContent: "flex-start", gap: "1.5rem", marginTop: "2rem" }}
    >
      <Language key={language} updateVisibility={updateVisibility} />
      <Flex
        flexDirection={"row"}
        style={{ alignItems: "center", gap: "1rem", marginLeft: "0.8rem" }}
      >
        <AccountCircleIcon
          style={{ color: "rgb(8, 8, 46)" }}
          fontSize="large"
        />
        <Link to="/profile">
          <Flex
            flexDirection={"column"}
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <p style={{ fontSize: 14 }}>{commonTitles.profile}</p>
            <p style={{ fontSize: 14 }}>
              <b>{username}</b>
            </p>
          </Flex>
        </Link>
      </Flex>

      <div className="settings-switch">
        <p>{"Σκούρο θέμα"}</p>
        <Switch
          style={{ border: "none" }}
          checked={darkTheme}
          onChange={() => {
            setDarkTheme(!darkTheme);
            updateTheme(!darkTheme);
          }}
        />
      </div>
      <Flex
        style={{ width: "100%", marginTop: "3rem", justifyContent: "center" }}
      >
        <Button
          style={{
            display: "flex",
            width: "max-content",
          }}
          onClick={handleLogout}
          type="primary"
          shape="round"
        >
          {modTitles.logout}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Dropdown;
