import "./alertConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

const AlertConfigHead = ({ headers }) => {
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell key={"ghost-cell"} />
        {headers.map((item, index) => (
          <TableCell
            key={item.id}
            className="admin-header"
            align={
              [headers.length - 1, headers.length - 2].includes(index)
                ? "center"
                : "left"
            }
          >
            <p key={`${item.id} - text`}>{item.title}</p>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default AlertConfigHead;
