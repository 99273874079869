import Flex from "../../../../../helpers/components/Flex";
import { Tooltip } from "antd";
import "../../../css/ultimateTable.css";
import { CheckOutlined } from "@ant-design/icons";

const DisplayDecider = ({
  filterChanges,
  fieldID,
  filters,
  fieldType,
  fieldTitle,
}) => {
  if (fieldType === "0") {
    return <Flex />;
  }

  if (filterChanges[fieldID] || filters[fieldID]) {
    if (fieldTitle?.length > 22) {
      return (
        <div className="filters-first_column">
          <Flex flexDirection={"row"}>
            <p className="asterisk">*</p>
            <Tooltip placement="bottom" title={fieldTitle}>
              {fieldTitle.slice(0, 22) + "... "}:
            </Tooltip>
          </Flex>
        </div>
      );
    } else {
      return (
        <div className="filters-first_column">
          <p className="asterisk">*</p>
          {fieldTitle}:
        </div>
      );
    }
  } else if (
    (filters[fieldID] && !(fieldType === "4" || fieldType === "5")) ||
    (filters[fieldID] && (filters[fieldID].from || filters[fieldID].to))
  ) {
    if (fieldTitle?.length > 22) {
      return (
        <div className="filters-first_column">
          <Tooltip placement="bottom" title={fieldTitle}>
            <CheckOutlined
              style={{
                color: "green",
                marginRight: 10,
                marginTop: -2,
                fontSize: 20,
              }}
            />
            {fieldTitle.slice(0, 22) + "... "}:
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div className="filters-first_column">
          <CheckOutlined
            style={{
              color: "green",
              marginRight: 10,
              marginTop: -2,
              fontSize: 20,
            }}
          />
          {fieldTitle}:
        </div>
      );
    }
  } else {
    if (fieldTitle?.length > 22) {
      return (
        <Tooltip placement="bottom" title={fieldTitle}>
          <p className="filters-first_column">
            {fieldTitle.slice(0, 22) + "... "}:
          </p>
        </Tooltip>
      );
    } else {
      return <p className="filters-first_column">{fieldTitle}:</p>;
    }
  }
};

export default DisplayDecider;
