import moment from "moment";

const noGroupDateYearly = (
  columns,
  data,
  xAxisField,
  dateFormatToUse,
  commonTitles,
  xValuesUsed,
  yValuesUsed,
  noDateDataColumnStatus,
  dependentColumns
) => {
  let childxAxisField;
  let xAxisFieldToUse = xAxisField;

  if (xAxisField?.includes("-")) {
    let tempXAxisField = xAxisField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  const xAxisFieldColumn = columns.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )[0];

  let xAxisFieldName;
  let childxAxisFieldIndex;
  let childxAxisNumber;

  if (childxAxisField) {
    const childTitle = xAxisFieldColumn.DEP_FIELDS?.filter(
      (field) => field.F_ID === childxAxisField
    ).map((item) => item.TITLE);
    xAxisFieldName = `${xAxisFieldColumn.T_TITLE} - ${childTitle}`;
    childxAxisNumber = xAxisFieldColumn.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === xAxisFieldToUse
    )[0].DEP_FIELDS;

    childxAxisFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childxAxisField;
    });
  } else {
    xAxisFieldName = xAxisFieldColumn.T_TITLE;
  }

  const isAlert =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  const isDependent =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "30"
      ? true
      : false;

  let uniqueYears = [];

  let years = data.map((item) => {
    if (dependentColumns.includes(xAxisFieldToUse)) {
      if (item[xAxisFieldToUse] && item[xAxisFieldToUse].includes(",")) {
        const tempValue = item[xAxisFieldToUse].split(", ");
        const finalValue = tempValue[childxAxisFieldIndex];
        return moment(finalValue, dateFormatToUse).toDate().getFullYear();
      } else {
        return item[xAxisFieldToUse] ?? null;
      }
    } else {
      if (isAlert) {
        if (item[xAxisFieldToUse]?.value) {
          return moment(item[xAxisFieldToUse]?.value, dateFormatToUse)
            .toDate()
            .getFullYear();
        } else return null;
      } else {
        if (item[xAxisFieldToUse]) {
          return moment(item[xAxisFieldToUse], dateFormatToUse)
            .toDate()
            .getFullYear();
        } else return null;
      }
    }
  });

  years.forEach((item) => {
    if (item && !uniqueYears.includes(item)) {
      uniqueYears.push(item);
    }
  });

  uniqueYears.sort((a, b) => {
    return a - b;
  });

  //console.log("uniqueYears: ", uniqueYears);

  let yearlyCount = {};
  let noDateAvailable = 0;

  for (let i = 0; i < uniqueYears.length; i++) {
    let noGroupFieldCounter = 0;

    for (let z = 0; z < data.length; z++) {
      let dateValue = isAlert
        ? data[z][xAxisFieldToUse]?.value
        : data[z][xAxisFieldToUse];

      if (!dateValue) {
        noDateAvailable += 1;
      } else {
        let year = moment(dateValue, dateFormatToUse).toDate().getFullYear();

        if (
          year === uniqueYears[i] ||
          (isDependent && dateValue.includes(uniqueYears[i]))
        ) {
          noGroupFieldCounter += 1;
        }
      }
    }

    yearlyCount = {
      ...yearlyCount,
      [uniqueYears[i]]: {
        ...yearlyCount[uniqueYears[i]],
        count: noGroupFieldCounter,
        sum: noGroupFieldCounter,
      },
    };
  }

  if (noDateAvailable && !noDateDataColumnStatus) {
    yearlyCount = {
      ...yearlyCount,
      [commonTitles["CO|1|NODATEDATA"]]: {
        count: noDateAvailable,
        sum: noDateAvailable,
      },
    };
  }

  let totalSum = 0;
  Object.entries(yearlyCount).forEach(([key, value]) => {
    Object.values(value).forEach((item) => {
      totalSum += item;
    });
  });

  return {
    dailyCount: {},
    monthlyCount: {},
    yearlyCount: { ...yearlyCount, totalSum: totalSum },
  };
};

export default noGroupDateYearly;
