import "./staticList.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";
import { useCommonTitlesStore } from "../../../../../../store/useStores";

const AdminMenuHead = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell style={{ width: "5%" }} key={"ghost-cell"} />
        <TableCell align={"left"} className="admin-header">
          <p>{commonTitles["CO|1|SLOPTS"]}</p>
        </TableCell>
        <TableCell style={{ width: "5%" }} key={"ghost-cell-2"} />
        <TableCell style={{ width: "5%" }} key={"ghost-cell-3"} />
      </TableRow>
    </TableHead>
  );
};

export default AdminMenuHead;
