import "./register.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { Card, Input } from "antd";
import Box from "@material-ui/core/Box";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register = ({ changeFormType }) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const username = useAuthStore((state) => state.username);
  const setUsername = useAuthStore((state) => state.setUsername);
  const password = useAuthStore((state) => state.password);
  const setPassword = useAuthStore((state) => state.setPassword);

  let navigate = useNavigate();

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const usernameRef = useRef();
  const errRef = useRef();

  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [matchPassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  // useEffect(() => {
  //   usernameRef.current.focus();
  // }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatch(password === matchPassword);
  }, [password, matchPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [username, password, matchPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(
        `https://localhost/PLCS/Login/apiloginuser/${username}/${password}`,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setSuccess(true);
      //clear state and controlled inputs
      // setUsername("");
      // setPassword("");
      setMatchPassword("");
      navigate("/login");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <section className="register">
      <Box className="register-box">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>

        <h1>{commonTitles.register}</h1>
        <Card
          className="register-card"
          style={{
            borderRadius: "10px",
            border: "#0a1738",
            overflow: "hidden",
          }}
        >
          <form onSubmit={handleSubmit} className="register-form">
            <h4>{commonTitles.username}:</h4>
            {/* <input
              type="text"
              id="username"
              //ref={usernameRef}
              autoComplete="off"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
              aria-invalid={validName ? "false" : "true"}
              aria-describedby="uidnote"
              onFocus={() => setUserFocus(true)}
              onBlur={() => setUserFocus(false)}
            /> */}
            <Input
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                marginRight: "1rem",
                marginBottom: "0.3rem",
                width: "18.5rem",
                borderRadius: 10,
                color: "black",
                backgroundColor: "white",
              }}
              required
            />
            <h6
              id="uidnote"
              className={
                userFocus && username && !validName
                  ? "instructions"
                  : "offscreen"
              }
            >
              {commonTitles.loginMsg1}
            </h6>

            <h4>{commonTitles.password}:</h4>
            {/* <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              aria-invalid={validPassword ? "false" : "true"}
              aria-describedby="pwdnote"
              onFocus={() => setPasswordFocus(true)}
              onBlur={() => setPasswordFocus(false)}
            /> */}
            <Input.Password
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              width={"3rem"}
              style={{
                marginRight: "1rem",
                marginBottom: "0.3rem",
                width: "18.5rem",
                borderRadius: 10,
                color: "black",
                backgroundColor: "white",
              }}
              required
            />
            <h6
              id="passwordNote"
              className={
                passwordFocus && !validPassword ? "instructions" : "offscreen"
              }
            >
              {commonTitles.loginMsg2}
            </h6>

            <h4>{commonTitles.confirmPswd}:</h4>
            {/* <input
              type="password"
              id="confirm_password"
              onChange={(e) => setMatchPassword(e.target.value)}
              value={matchPassword}
              required
              aria-invalid={validMatch ? "false" : "true"}
              aria-describedby="confirmNote"
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            /> */}
            <Input.Password
              id="confirm_password"
              value={matchPassword}
              onChange={(e) => setMatchPassword(e.target.value)}
              width={"3rem"}
              style={{
                marginRight: "1rem",
                marginBottom: "0.3rem",
                width: "18.5rem",
                borderRadius: 10,
                color: "black",
                backgroundColor: "white",
              }}
              required
            />
            <h6
              id="confirmNote"
              className={
                matchFocus && !validMatch ? "instructions" : "offscreen"
              }
            >
              {commonTitles.pwdMatchMsg}
            </h6>

            <button className="register__registerButton">
              {commonTitles.register}
            </button>

            <button
              className="register__toLogin"
              onClick={() => changeFormType("login")}
            >
              {commonTitles.alreadyReg} - {commonTitles.login}
            </button>
          </form>
        </Card>
      </Box>
    </section>
  );
};

export default Register;
