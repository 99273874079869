import { Card, Spin } from "antd";

const LoadingCard = () => {
  return (
    <Card
      style={{
        borderRadius: "10px",
        marginBottom: "10px",
        width: "98.5vw",
        display: "flex",
        justifyContent: "center",
        height: "85vh",
      }}
    >
      <Spin size="large" style={{ marginTop: "15rem" }} />
    </Card>
  );
};

export default LoadingCard;
