import axios from "axios";

const apiTriggerSendEmail = async (baseURL) => {
  const url = `${baseURL}/Email_notifications/triggersendemails`;
  try {
    const response = await axios.post(
      url,
      {},
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log(
      "Email_notifications/triggersendemails: response: ",
      response.data
    );
    return response.data;
  } catch (err) {}
};

export default apiTriggerSendEmail;
