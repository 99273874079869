import { ResponsiveRadialBar } from "@nivo/radial-bar";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import Flex from "../../../../helpers/components/Flex";

const Radial = ({ data, columns, staticOptions }) => {
  const color = useChartStore((state) => state.color);
  const innerRadius = useChartStore((state) => state.innerRadius);
  const sumField = useChartStore((state) => state.sumField);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const groupField = useChartStore((state) => state.groupField);
  const colorSet20 = useChartStore((state) => state.colorSet20);
  const colorSet40 = useChartStore((state) => state.colorSet40);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const groupFieldColumn = columns?.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  let groupFieldType;
  let childGroupFieldIndex;
  let childGroupNumber;
  if (childGroupField) {
    const childTitle = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    const childType = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.F_TYPE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
    groupFieldType = childType;
    childGroupNumber = groupFieldColumn?.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === groupFieldToUse
    )[0]?.DEP_FIELDS;

    childGroupFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childGroupField;
    });
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
    groupFieldType = groupFieldColumn?.F_TYPE;
  }

  let numOfLegends = Object.keys(data[0].data).length;

  //console.log("RadialChart: data: ", data);

  //console.log("data: ", data);

  //const dataForLegends = [];

  let dataForLegends = data[0].data.map((item) => item.x);

  //console.log("dataForLegends: ", dataForLegends);

  // const numOfLegends = Object.keys(dataForLegends[0]).length;

  let chartColor = colorSet20;
  if (numOfLegends > 20) {
    chartColor = [...colorSet20, ...colorSet40];
  }

  let dataForLegendsPart1 = [];
  let dataForLegendsPart2 = [];
  if (numOfLegends <= 20) {
    dataForLegendsPart1.push(...dataForLegends);
    // dataForLegendsPart1.push(
    //   `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
    // );
  } else {
    for (let i = 0; i < dataForLegends.length; i++) {
      if (i <= numOfLegends / 2 - 1) {
        dataForLegendsPart1.push(dataForLegends[i]);
      } else {
        dataForLegendsPart2.push(dataForLegends[i]);
      }
    }
    // dataForLegendsPart2.push(
    //   `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
    // );
  }

  if (groupFieldType === "6") {
    let options = Object.values(staticOptions?.[groupField]);
    options.sort((a, b) => a.ORDER - b.ORDER);

    dataForLegends = options.map((option) => option.TITLE);
    numOfLegends = dataForLegends.length;
    if (dataForLegends.length > 20) {
      dataForLegendsPart1 = dataForLegends.slice(
        0,
        dataForLegends.length / 2 - 1
      );
      dataForLegendsPart2 = dataForLegends.slice(
        dataForLegends.length / 2 - 1,
        dataForLegends.length
      );
      dataForLegendsPart2.push(
        `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
      );
    } else {
      dataForLegendsPart1 = dataForLegends;
      dataForLegendsPart1.push(
        `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
      );
    }
  }

  //console.log("data: ", data);

  if (["sum", "pctSum"].includes(displayFunction) && !sumField) {
    dataForLegends = data.map((item) => item?.id);
    if (dataForLegends.length <= 20) {
      dataForLegendsPart1 = dataForLegends;
    } else {
      dataForLegendsPart1 = dataForLegends.slice(
        0,
        dataForLegends.length / 2 - 1
      );
      dataForLegendsPart2 = dataForLegends.slice(
        dataForLegends.length / 2 - 1,
        dataForLegends.length
      );
    }
  }

  //console.log("data: ", data);

  let colorKeys = data?.[0]?.data.map((item) => item?.x);

  if (
    !groupField ||
    (["sum", "pctSum"].includes(displayFunction) && !sumField)
  ) {
    colorKeys = data?.map((item) => item?.id);
  }

  if (groupFieldType === "6") {
    colorKeys = Object.values(staticOptions?.[groupField]).map(
      (item) => item.TITLE
    );
  }

  if (
    groupFieldType === "6" &&
    ["sum", "pctSum"].includes(displayFunction) &&
    sumField
  ) {
    colorKeys = Object.values(staticOptions?.[groupField]).map(
      (item) => item.TITLE
    );
  }

  let i = 0;
  let radialColors = {};

  //console.log("colorKeys: ", colorKeys);

  colorKeys.forEach((key, index) => {
    if (groupFieldType === "6") {
      const color = Object.values(staticOptions?.[groupField]).filter(
        (item) => item?.TITLE === key
      )?.[0]?.BC;

      if (!["#ffffff", "rgb(255, 255, 255)"].includes(color) && color) {
        radialColors = { ...radialColors, [key]: color };
      } else {
        radialColors = { ...radialColors, [key]: chartColor[i] };
        i++;
      }
    } else {
      radialColors = { ...radialColors, [key]: chartColor[i] };
      i++;
    }
  });

  radialColors = {
    ...radialColors,
    [`${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`]: "black",
  };

  //console.log("radialColors: ", radialColors);

  const getColor = (radial) => {
    //console.log("radial: ", radial);
    if (
      !groupField ||
      (["sum", "pctSum"].includes(displayFunction) &&
        !sumField &&
        groupFieldType !== "6")
    ) {
      return radialColors?.[radial?.groupId];
    }
    return radialColors?.[radial?.category];
  };

  // console.log("dataForLegendsPart1: ", dataForLegendsPart1);
  // console.log("dataForLegendsPart2: ", dataForLegendsPart2);

  let max = 10;
  for (let i = 0; i < data.length; i++) {
    let tempMax = 0;
    for (let j = 0; j < data[i].data.length; j++) {
      tempMax += data[i].data[j].y;
    }
    if (tempMax > max) {
      max = tempMax;
    }
  }

  let showLegends = true;

  if (!groupField) {
    showLegends = false;
  }

  if (["sum", "pctSum"].includes(displayFunction) && !sumField) {
    showLegends = false;
  }

  return (
    <>
      <ResponsiveRadialBar
        data={data}
        maxValue={
          ["pctCount", "pctSum"].includes(displayFunction)
            ? 1
            : max > 10
            ? max
            : 10
        }
        //colors={numOfLegends > 10 ? chartColorPalette : { scheme: color }}
        colors={getColor}
        innerRadius={innerRadius}
        valueFormat={
          ["pctCount", "pctSum"].includes(displayFunction) ? ">-.2p" : ">-.0f"
        }
        padding={0.2}
        theme={{
          axis: {
            legend: {
              text: {
                fill: "rgb(8, 8, 46)",
              },
            },
            ticks: {
              text: {
                fill: "rgb(8, 8, 46)",
              },
            },
          },
        }}
        cornerRadius={2}
        margin={{ top: 50, right: 220, bottom: 120, left: 40 }}
        radialAxisStart={{ tickSize: 5, tickPadding: 10, tickRotation: 0 }}
        circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
        // legends={[
        //   {
        //     anchor: "right",
        //     direction: "column",
        //     justify: false,
        //     translateX: 80,
        //     translateY: 0,
        //     itemsSpacing: 3,
        //     itemDirection: "left-to-right",
        //     itemWidth: 50,
        //     itemHeight: 12,
        //     itemTextColor: "rgb(8, 8, 46)",
        //     symbolSize: 12,
        //     symbolShape: "circle",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemTextColor: "#000",
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
      <Flex
        flexDirection={"column"}
        style={{
          marginLeft: "-20rem",
          marginTop: "4rem",
          display: !showLegends && "none",
        }}
      >
        {dataForLegendsPart1.map((item, index) => (
          <Flex
            key={index}
            flexDirection={"row"}
            style={{
              maxHeight: 13,
              marginBottom: 2,
              marginTop: numOfLegends < 15 ? 4 : 2,
            }}
          >
            <div
              style={{
                width: numOfLegends < 15 ? 13 : 10,
                maxHeight: numOfLegends < 15 ? 12 : 9,
                borderRadius: 15,
                background: radialColors[item],
              }}
            />
            <p
              style={{
                marginTop: numOfLegends < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfLegends < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
      <Flex
        flexDirection={"column"}
        style={{
          marginLeft: "1rem",
          marginTop: "4rem",
          display: !showLegends && "none",
        }}
      >
        {dataForLegendsPart2.map((item, index) => (
          <Flex
            key={index}
            flexDirection={"row"}
            style={{
              maxHeight: 13,
              marginBottom: 2,
              marginTop: numOfLegends < 15 ? 4 : 2,
            }}
          >
            <div
              style={{
                width: numOfLegends < 15 ? 13 : 10,
                maxHeight: numOfLegends < 15 ? 12 : 9,
                borderRadius: 15,
                background: radialColors[item],
              }}
            />
            <p
              style={{
                marginTop: numOfLegends < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfLegends < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default Radial;
