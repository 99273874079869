import "../../../css/newRegisterModal.css";
import { WarningOutlined } from "@ant-design/icons";
import Flex from "../../../../../../../helpers/components/Flex";
import { Input } from "antd";

const TextType = ({ id, required, addChange, autoCompletedFields, size }) => {
  let value = null;
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    value = autoCompletedFields[id];
  }
  return (
    <Flex flexDirection={"row"}>
      <Input
        type="text"
        className="edit-input"
        style={{ minWidth: 275 }}
        onChange={(e) => addChange(id, e.target.value, null)}
        defaultValue={value}
        disabled={value ? true : false}
        maxLength={size ? size : null}
      />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default TextType;
