import { CheckSquareFilled } from "@ant-design/icons";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";

const CheckboxType = ({ value }) => {
  if (value) {
    if (value === "Y") {
      return (
        <CheckSquareFilled
          style={{ color: "rgb(180, 203, 241)", fontSize: "21px" }}
        />
      );
    } else {
      return <SquareOutlinedIcon style={{ color: "#908e8e" }} />;
    }
  } else {
    return <SquareOutlinedIcon style={{ color: "#908e8e" }} />;
  }
};

export default CheckboxType;
