import { Modal, Button, Card, Spin, Input } from "antd";
import { useEffect, useState } from "react";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import apiGetModuleTitles from "../../../api/endpoints/apiGetModuleTitles";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead, TableBody } from "@mui/material";

const TitlesModalContent = ({
  data,
  headers,
  moduleTitle,
  languages,
  addChange,
  project,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Card
      bodyStyle={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        width: "60vw",
        overflowY: "auto",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <h3>
        {commonTitles["CO|1|PROJECT"]}: {project}
      </h3>
      <h3>
        {commonTitles["CO|1|MODULE"]}: {moduleTitle}
      </h3>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "70vh" }}
        className="testing_table"
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={header.id}
                  className="admin-header"
                  align="center"
                >
                  <p>{header.title}</p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: "50%" }} align="left">
                  {item.T1_TITLE}
                </TableCell>
                <TableCell align="center">
                  <Input
                    defaultValue={item.T2_TITLE}
                    onChange={(e) =>
                      addChange(item.T1_ID, {
                        T1_ID: item.T1_ID,
                        T2_ID: item.T2_ID,
                        T2_TITLE: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export const TitlesModal = ({
  visible,
  moduleID,
  onSave,
  onCancel,
  project,
}) => {
  const [changes, setChanges] = useState({});
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const projectID = useAuthStore((state) => state.projectID);
  const language = useAuthStore((state) => state.language);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [languages, setLanguages] = useState({});
  const [moduleTitle, setModuleTitle] = useState(null);

  const addChange = (id, value) => {
    const exisitingIDs = changes.titles.map((item) => item.T1_ID);
    let temp = changes.titles;

    if (!exisitingIDs.includes(id)) {
      temp.push(value);
    } else {
      temp = changes.titles.map((item) => {
        if (item.T1_ID === id) {
          return value;
        } else {
          return item;
        }
      });
    }

    setChanges({ ...changes, titles: temp });
  };

  const fetchData = async () => {
    setIsLoading(true);
    const serverData = await apiGetModuleTitles(
      baseURL,
      projectID,
      moduleID,
      language
    );
    setModuleTitle(serverData.callertitle);
    setHeaders([
      { id: "T1_TITLE", title: serverData.column1title },
      { id: "T2_TITLE", title: serverData.column2title },
    ]);
    setLanguages(serverData.languages);
    setData(serverData.titles);
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [projectID, language]);

  useEffect(() => {
    setChanges({
      projectid: projectID,
      moduleid: moduleID,
      lang: language,
      titles: [],
    });
  }, []);

  return (
    <Modal
      key={"new-item"}
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        marginTop: "-11vh",
        display: "flex",
        marginLeft: "20vw",
        width: "max-content",
      }}
      title={commonTitles["CO|1|MODTITLES"]}
      onOk={() => onSave(changes)}
      onCancel={() => onCancel()}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(changes)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      {isLoading ? (
        <Card
          style={{
            borderRadius: "10px",
            marginBottom: "10px",
            width: "98.5vw",
            display: "flex",
            justifyContent: "center",
            height: "85vh",
          }}
        >
          <Spin size="large" style={{ marginTop: "15rem" }} />
        </Card>
      ) : (
        <TitlesModalContent
          data={data}
          headers={headers}
          moduleTitle={moduleTitle}
          languages={languages}
          addChange={addChange}
          project={project}
        />
      )}
    </Modal>
  );
};
