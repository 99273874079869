import { Card, Modal, Button } from "antd";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../store/useStores";
import siteInputDecider from "./helpers/siteInputDecider";

export const NewSiteModal = ({
  visible,
  data,
  headers,
  staticOptions,
  foreignKeyValues,
  onSave,
  onCancel,
  config,
}) => {
  const [changes, setChanges] = useState({});
  const columnsChanged = Object.keys(changes);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const addChange = (id, value) => {
    if (value && value !== "" && value?.length !== 0) {
      setChanges({ ...changes, [id]: value });
    } else {
      delete changes[id];
      setChanges({ ...changes });
    }
  };

  return (
    <Modal
      key={`${data}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      data={data}
      headers={headers}
      style={{
        marginTop: -40,
        display: "flex",
        justifyContent: "flex-start",
        width: "70vw",
      }}
      title={commonTitles["CO|1|NEWSITE"]}
      onOk={() => onSave(changes, data?.ROWID)}
      onCancel={() => onCancel(config)}
      footer={[
        <Button key="back" onClick={() => onCancel(config)}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(changes, data?.ROWID)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <Flex flexDirection={"column"} style={{ paddingLeft: "-8rem" }}>
        <Card
          bodyStyle={{
            maxHeight: "60vh",
            overflowY: "auto",
            width: "max-content",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {headers.map(({ F_ID, F_TYPE, T_TITLE }) => (
            <Flex
              key={F_ID}
              flexDirection={"row"}
              style={{ marginLeft: "-13rem", justifyContent: "flex-start" }}
            >
              {columnsChanged.includes(F_ID) ? (
                <div className="filters-first_column">
                  <p className="asterisk">*</p>
                  {T_TITLE}:
                </div>
              ) : (
                <p className="filters-first_column">{T_TITLE}:</p>
              )}
              <FormControl
                sx={{
                  mt: -1,
                  mb: 0.5,
                  ml: 1,
                  mr: 1,
                  minWidth: 300,
                }}
                className="filters-row"
                key={F_ID}
              >
                {siteInputDecider(
                  F_TYPE,
                  F_ID,
                  null,
                  staticOptions,
                  foreignKeyValues,
                  addChange,
                  true,
                  true
                )}
              </FormControl>
            </Flex>
          ))}
        </Card>
      </Flex>
    </Modal>
  );
};
