import Flex from "../../../../../helpers/components/Flex";
import CustomCheckbox from "../../../../../helpers/components/CustomCheckbox";
import { WarningOutlined } from "@ant-design/icons";
import { useAuthStore } from "../../../../../store/useStores";

const CheckboxType = ({
  value,
  id,
  config,
  canupdate,
  canupdateifnull,
  required,
  addChange,
  row,
}) => {
  const userID = useAuthStore((state) => state.userID);

  let isDisabled = false;
  if (config !== "filters") {
    if (canupdate === false) {
      if (canupdateifnull === true) {
        if (value.length) {
          if (row[`${id}-US`] !== userID) {
            isDisabled = true;
          }
        }
      } else {
        isDisabled = true;
      }
    }
  }

  return (
    <Flex flexDirection={"column"} style={{ marginLeft: 40 }}>
      <CustomCheckbox
        id={id}
        value={value}
        addChange={addChange}
        config={config}
        isDisabled={isDisabled}
      />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default CheckboxType;
