//import logo from "../../../assets/PLCS-logo-name.png";
import logo from "../../../assets/PLCS-logoo.png";
import logoName from "../../../assets/PLCS-nameee.png";
import { Link } from "react-router-dom";
import { Image } from "antd";
import Flex from "../../../helpers/components/Flex";
import "../navigation.css";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";

const Logo = () => {
  const { width } = useWindowDimensions();
  return (
    <div className="navigation__links-logo">
      <Link to="/home">
        <Flex flexDirection={"row"}>
          <Image
            src={logo}
            alt="logo"
            width={40}
            height={40}
            style={{ marginTop: width < 1035 && 8 }}
            preview={false}
          />
          <p
            className="logo-text"
            style={{
              color: "#00429d",
              fontWeight: 700,
              fontSize: 20,
              marginTop: 11,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            PLCS SUITE
          </p>
        </Flex>
      </Link>
    </div>
  );
};

export default Logo;
