import React from "react";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@material-ui/core/styles";
import {
  useCommonTitlesStore,
  useChartStore,
} from "../../../../store/useStores";

function valuetext(value) {
  return `${value}°`;
}

const endMarks = [
  {
    value: 120,
    label: "120°",
  },
  {
    value: 180,
    label: "180°",
  },
  {
    value: 270,
    label: "270°",
  },
  {
    value: 360,
    label: "360°",
  },
];

const useStyles = makeStyles((theme) => ({
  mark: {
    color: "rgb(8, 8, 46)",
    fontSize: "13px",
  },
  valueLabelDisplay: {
    color: "white",
    background: "rgb(8, 8, 46)",
  },
}));

const EndAngle = () => {
  const classes = useStyles();
  const endAngle = useChartStore((state) => state.endAngle);
  const setEndAngle = useChartStore((state) => state.setEndAngle);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <div className="chart__options-angles">
      <p>{commonTitles.endAngle}</p>
      <Slider
        sx={{ width: 160, mt: -1 }}
        size="small"
        aria-label="Start Angle"
        value={endAngle}
        onChange={(e) => setEndAngle(e.target.value)}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={1}
        marks={endMarks}
        classes={{
          markLabel: classes.mark,
          valueLabel: classes.valueLabelDisplay,
        }}
        min={120}
        max={360}
      />
    </div>
  );
};

export default EndAngle;
