const chooseSentDateDisplay = (periodType, value, titles) => {
  if (periodType === "D") {
    return "-";
  }

  if (periodType === "W") {
    const options = [
      { ID: "SUN", T_TITLE: titles["FO|171|TITLE"] },
      { ID: "MON", T_TITLE: titles["FO|172|TITLE"] },
      { ID: "TUE", T_TITLE: titles["FO|173|TITLE"] },
      { ID: "WED", T_TITLE: titles["FO|174|TITLE"] },
      { ID: "THU", T_TITLE: titles["FO|175|TITLE"] },
      { ID: "FRI", T_TITLE: titles["FO|176|TITLE"] },
      { ID: "SAT", T_TITLE: titles["FO|177|TITLE"] },
    ];

    if (value) {
      return options.filter((option) => option.ID === value)?.[0]?.T_TITLE;
    } else {
      return "-";
    }
  }

  if (periodType === "M") {
    const options = [
      { ID: "FD", T_TITLE: titles["FO|178|TITLE"] },
      { ID: "LW", T_TITLE: titles["FO|179|TITLE"] },
      { ID: "LD", T_TITLE: titles["FO|180|TITLE"] },
    ];
    if (value) {
      return options.filter((option) => option.ID === value)?.[0]?.T_TITLE;
    } else {
      return "-";
    }
  }

  return "-";
};

export default chooseSentDateDisplay;
