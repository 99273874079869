import { Select, Input } from "antd";
import FileUpload from "../../../module/banner/newRegister/fileUpload/FileUpload";
import Flex from "../../../../helpers/components/Flex";

const { Option } = Select;
const { TextArea } = Input;

const projectInputDecider = (
  type,
  id,
  value,
  staticOptions,
  foreignKeyValues,
  addChange,
  canupdate,
  canupdateifnull,
  rowID,
  projectID,
  commonTitles,
  config
) => {
  if (type === "1" /* type = text*/) {
    let newValue = value ?? "";
    if (value && value.includes("&#39;")) {
      let splitValue = value
        .split("&#39;")
        .filter((item) => item !== "")
        .join("'");
      newValue = splitValue;
      if (splitValue && splitValue.includes("&#10;")) {
        let newSplitValue = value.split("&#10;").filter((item) => item !== "");
        let str = "";
        newSplitValue.forEach((item) => {
          if (str !== "") {
            str += "\n";
          }
          str += item;
        });
        newValue = str;
      }
    } else if (value && value.includes("&#10;")) {
      let newSplitValue = value.split("&#10;").filter((item) => item !== "");
      let str = "";
      newSplitValue.forEach((item) => {
        if (str !== "") {
          str += "\n";
        }
        str += item;
      });
      newValue = str;
    }

    return (
      <Flex flexDirection={"row"}>
        <Input
          type="text"
          style={{
            border: "1.25px solid",
            borderColor: "rgb(182, 180, 180)",
            borderRadius: 5,
            fontFamily: "Arial, Helvetica, sans-serif",
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            fontSize: 14,
            letterSpacing: "0.03em",
            height: 30,
            minWidth: 275,
          }}
          defaultValue={newValue ?? ""}
          onChange={(e) => addChange(id, e.target.value, null)}
        />
      </Flex>
    );
  }

  if (type === "27" /* type = textarea*/) {
    let newValue = value ?? "";
    if (value && value.includes("&#39;")) {
      let splitValue = value
        .split("&#39;")
        .filter((item) => item !== "")
        .join("'");
      newValue = splitValue;
      if (splitValue && splitValue.includes("&#10;")) {
        let newSplitValue = value.split("&#10;").filter((item) => item !== "");
        let str = "";
        newSplitValue.forEach((item) => {
          if (str !== "") {
            str += "\n";
          }
          str += item;
        });
        newValue = str;
      }
    } else if (value && value.includes("&#10;")) {
      let newSplitValue = value.split("&#10;").filter((item) => item !== "");
      let str = "";
      newSplitValue.forEach((item) => {
        if (str !== "") {
          str += "\n";
        }
        str += item;
      });
      newValue = str;
    }

    return (
      <Flex flexDirection={"row"}>
        <TextArea
          type="text"
          style={{ minWidth: 275 }}
          defaultValue={newValue ?? ""}
          onChange={(e) => addChange(id, e.target.value, null)}
        />
      </Flex>
    );
  }

  if (
    type === "24" /* type = Document link */ ||
    type === "25" /* type = Single image link */
  ) {
    return (
      <Flex flexDirection={"row"}>
        <FileUpload
          files={value ?? []}
          addChange={addChange}
          id={id}
          canupdate={canupdate}
          canupdateifnull={canupdateifnull}
        />
      </Flex>
    );
  }

  if (type === "7" /* type = Values from another module */) {
    let options = [];
    if (foreignKeyValues[id]) {
      options = Object.entries(foreignKeyValues[id]).map(([key, value]) => ({
        id: key,
        view: value.view,
      }));
      options.sort((a, b) => a.view.localeCompare(b.view));
    }

    let newValue = value ?? "";

    return (
      <Flex flexDirection={"row"}>
        <div className="select">
          <Select
            allowClear
            style={{
              width: 278,
              marginLeft: 7,
              marginTop: 4,
            }}
            showSearch={true}
            filterOption={(iv, op) => {
              let opToUse = op.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            onChange={(e) => addChange(id, e, null)}
            defaultValue={newValue}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option value="" />
            {options.map((item) => (
              <Option key={item?.view} className="option" value={item?.id}>
                {item?.view}
              </Option>
            ))}
          </Select>
        </div>
      </Flex>
    );
  }

  if (type === "6" /* type = Values from static list */) {
    let newValue = value?.view ?? {};

    if (typeof value !== "object") {
      newValue = value ? value : [];
    }

    return (
      <Flex flexDirection={"column"}>
        <div className="select">
          <Select
            allowClear
            style={{
              width: 278,
              marginLeft: 7,
              marginTop: 8,
            }}
            showSearch={true}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            onChange={(e) => addChange(id, e, null)}
            defaultValue={newValue}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
            disabled={config === "edit" && projectID === rowID && true}
          >
            <Option value=""></Option>
            {staticOptions?.[id] &&
              Object.entries(staticOptions[id]).map(([key, value]) => {
                return (
                  <Option key={value?.ORDER} className="option" value={key}>
                    {value?.TITLE}
                  </Option>
                );
              })}
          </Select>
        </div>
        {config === "edit" && projectID === rowID && (
          <p style={{ marginLeft: 8, fontSize: 12, color: "red" }}>
            {commonTitles["CO|1|PROJNOUPD"]}
          </p>
        )}
      </Flex>
    );
  }

  const newValue = value ?? "";

  return (
    <Flex flexDirection={"row"}>
      <Input
        type="text"
        defaultValue={newValue}
        onChange={(e) => addChange(id, e.target.value, null)}
      />
    </Flex>
  );
};

export default projectInputDecider;
