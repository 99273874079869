const validateValue = (value, type) => {
  const validChars =
    type === "3"
      ? ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", ","]
      : ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let isValid = true;
  let numberOfNonNumberChars = 0;
  const chars = value.split("");
  chars.forEach((char) => {
    if (char === "." || char === ",") {
      numberOfNonNumberChars++;
    }
    if (!validChars.includes(char) || numberOfNonNumberChars > 1) {
      isValid = false;
    }
  });

  return isValid;
};

export default validateValue;
