import noGroupDateDaily from "./noGroupDateDaily";
import noGroupDateMonthly from "./noGroupDateMonthly";
import noGroupDateYearly from "./noGroupDateYearly";
import noGroupNoDate from "./noGroupNoDate";
import withGroupDateDaily from "./withGroupDateDaily";
import withGroupDateMonthly from "./withGroupDateMonthly";
import withGroupDateYearly from "./withGroupDateYearly";
import withGroupNoDate from "./withGroupNoDate";
import withGroupNoDateSum from "./withGroupNoDateSum";
import withNumberGroupDateDailySum from "./withNumberGroupDateDailySum";
import withNumberGroupDateMonthlySum from "./withNumberGroupDateMonthlySum";
import withNumberGroupDateYearlySum from "./withNumberGroupDateYearlySum";

const newCountData = (
  data,
  columns,
  filters,
  dateFormatToUse,
  commonTitles,
  groupField,
  xAxisField,
  foreignKeyValues,
  language,
  xValuesUsed,
  yValuesUsed,
  noDateDataColumnStatus,
  grouping,
  displayFunction,
  sumField,
  dateTypes,
  numberTypes
) => {
  let childxAxisField;
  let xAxisFieldToUse = xAxisField;

  if (xAxisField?.includes("-")) {
    let tempXAxisField = xAxisField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  const xAxisFieldColumn = columns.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )[0];

  const dateColumns = columns
    .filter((column) => dateTypes.includes(column.F_TYPE))
    .map((value) => value.F_ID);

  const staticListColumns = columns
    .filter((column) => column.F_TYPE === "6")
    .map((value) => value.F_ID);

  const multipleValuesColumns = columns
    .filter((column) => column.F_TYPE === "28" || column.F_TYPE === "7")
    .map((value) => value.F_ID);

  const dependentColumns = columns
    .filter((column) => column.F_TYPE === "30")
    .map((value) => value.F_ID);

  const countColumns = columns
    .filter((column) => column.F_TYPE === "116")
    .map((value) => value.F_ID);

  const numberAlertColumns = columns
    .filter(
      (column) =>
        column.F_RENDER === "ALERT" && numberTypes.includes(column?.F_TYPE)
    )
    .map((value) => value.F_ID);

  let dependendDate = false;

  if (
    childxAxisField &&
    dateTypes.includes(
      columns
        .filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
        ?.DEP_FIELDS.filter((field) => field?.F_ID === childxAxisField)?.[0]
        ?.F_TYPE
    )
  ) {
    dependendDate = true;
  }

  if (!groupField) {
    if (dateColumns.includes(xAxisFieldColumn?.F_ID) || dependendDate) {
      if (grouping === "Daily") {
        const chartData = noGroupDateDaily(
          columns,
          data,
          xAxisField,
          dateFormatToUse,
          commonTitles,
          xValuesUsed,
          yValuesUsed,
          noDateDataColumnStatus,
          dependentColumns
        );
        return chartData;
      }

      if (grouping === "Monthly") {
        const chartData = noGroupDateMonthly(
          columns,
          data,
          xAxisField,
          dateFormatToUse,
          commonTitles,
          language,
          xValuesUsed,
          yValuesUsed,
          noDateDataColumnStatus,
          dependentColumns
        );
        return chartData;
      }

      if (grouping === "Yearly") {
        const chartData = noGroupDateYearly(
          columns,
          data,
          xAxisField,
          dateFormatToUse,
          commonTitles,
          xValuesUsed,
          yValuesUsed,
          noDateDataColumnStatus,
          dependentColumns
        );
        return chartData;
      }
    } else {
      const chartData = noGroupNoDate(
        columns,
        data,
        xAxisFieldToUse,
        xAxisFieldColumn,
        childxAxisField,
        countColumns,
        staticListColumns,
        multipleValuesColumns,
        foreignKeyValues,
        dependentColumns,
        numberAlertColumns,
        commonTitles,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus
      );
      return chartData;
    }
  }

  if (
    (dateColumns.includes(xAxisFieldColumn?.F_ID) || dependendDate) &&
    !["sum", "pctSum"].includes(displayFunction)
  ) {
    if (grouping === "Daily") {
      const chartData = withGroupDateDaily(
        columns,
        data,
        xAxisField,
        dateFormatToUse,
        commonTitles,
        groupField,
        foreignKeyValues,
        staticListColumns,
        dependentColumns,
        countColumns,
        numberAlertColumns,
        multipleValuesColumns,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus
      );
      return chartData;
    }

    if (grouping === "Monthly") {
      const chartData = withGroupDateMonthly(
        columns,
        data,
        xAxisField,
        dateFormatToUse,
        commonTitles,
        groupField,
        foreignKeyValues,
        staticListColumns,
        dependentColumns,
        countColumns,
        numberAlertColumns,
        multipleValuesColumns,
        language,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus
      );
      return chartData;
    }

    if (grouping === "Yearly") {
      const chartData = withGroupDateYearly(
        columns,
        data,
        xAxisField,
        dateFormatToUse,
        commonTitles,
        groupField,
        foreignKeyValues,
        staticListColumns,
        dependentColumns,
        countColumns,
        numberAlertColumns,
        multipleValuesColumns,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus
      );
      return chartData;
    }
  } else if (
    (dateColumns.includes(xAxisFieldColumn?.F_ID) || dependendDate) &&
    ["sum", "pctSum"].includes(displayFunction) &&
    sumField
  ) {
    if (grouping === "Daily") {
      const chartData = withNumberGroupDateDailySum(
        columns,
        data,
        xAxisField,
        dateFormatToUse,
        commonTitles,
        groupField,
        foreignKeyValues,
        staticListColumns,
        dependentColumns,
        countColumns,
        numberAlertColumns,
        multipleValuesColumns,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus,
        sumField
      );
      return chartData;
    }

    if (grouping === "Monthly") {
      const chartData = withNumberGroupDateMonthlySum(
        columns,
        data,
        xAxisField,
        dateFormatToUse,
        commonTitles,
        groupField,
        foreignKeyValues,
        staticListColumns,
        dependentColumns,
        countColumns,
        numberAlertColumns,
        multipleValuesColumns,
        language,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus,
        sumField
      );
      return chartData;
    }

    if (grouping === "Yearly") {
      const chartData = withNumberGroupDateYearlySum(
        columns,
        data,
        xAxisField,
        dateFormatToUse,
        commonTitles,
        groupField,
        foreignKeyValues,
        staticListColumns,
        dependentColumns,
        countColumns,
        numberAlertColumns,
        multipleValuesColumns,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus,
        sumField
      );
      return chartData;
    }
  } else {
    if (
      ["sum", "pctSum"].includes(displayFunction) &&
      !dateColumns.includes(xAxisFieldColumn?.F_ID) &&
      !dependendDate
    ) {
      const chartData = withGroupNoDateSum(
        columns,
        data,
        xAxisFieldToUse,
        dateFormatToUse,
        commonTitles,
        groupField,
        foreignKeyValues,
        staticListColumns,
        dependentColumns,
        countColumns,
        numberAlertColumns,
        multipleValuesColumns,
        childxAxisField,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus,
        displayFunction
      );
      return chartData;
    } else if (
      !dateColumns.includes(xAxisFieldColumn?.F_ID) &&
      !dependendDate
    ) {
      const chartData = withGroupNoDate(
        columns,
        data,
        xAxisFieldToUse,
        dateFormatToUse,
        commonTitles,
        groupField,
        foreignKeyValues,
        staticListColumns,
        dependentColumns,
        countColumns,
        numberAlertColumns,
        multipleValuesColumns,
        childxAxisField,
        xValuesUsed,
        yValuesUsed,
        noDateDataColumnStatus,
        displayFunction
      );
      return chartData;
    }
  }
};

export default newCountData;
