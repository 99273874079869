import { useCommonTitlesStore } from "../../../../../../../store/useStores";
import { Input } from "antd";

const DependentType = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Input
      placeholder={commonTitles["CO|1|DFIELDMSG"]}
      disabled={true}
      style={{ width: 278, height: 34 }}
    />
  );
};

export default DependentType;
