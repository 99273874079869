import "./consultantsConfig.css";
import { Layout, Button, message } from "antd";
import { NewConsultantModal } from "./NewConsultantModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore, useAuthStore } from "../../../store/useStores";
import axios from "axios";
import { tranformChangesforServer } from "../../module/helpers/converters";
import { convertDataFromServer } from "../../module/helpers/converters";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const { Content } = Layout;

const ConsultantsConfigSettings = ({
  headers,
  staticOptions,
  foreignKeyValues,
  newItemModalVisible,
  showModal,
  updateData,
  handleCancel,
  handleDelete,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const userID = useAuthStore((state) => state.userID);

  const onSave = async (changes) => {
    // console.log("changes: ", changes);

    const url = `${baseURL}/Data_api/save`;

    const transformedChanges = tranformChangesforServer(
      changes,
      headers,
      [],
      "",
      foreignKeyValues,
      [],
      baseURL
    );

    //console.log("onSave: transformedChanges: ", transformedChanges);

    const tempData = convertDataFromServer(
      headers,
      "",
      [],
      staticOptions,
      foreignKeyValues,
      transformedChanges,
      "insert",
      userID
    );

    //console.log("onSave: tempdata: ", tempData);

    try {
      const response = await axios.post(
        url,
        { moduleid: "15", ...transformedChanges },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Data_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changes = {
          [response?.data?.ROWID]: {
            ["LM"]: response?.data?.TIMESTAMP,
            ROWID: `${response?.data?.ROWID}`,
            ...tempData,
          },
        };
        //console.log("onSave: changes: ", changes);
        updateData("new", changes);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"} style={{ justifyContent: "flex-end" }}>
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {commonTitles["CO|1|NEWCONS"]}
          </Button>
          {newItemModalVisible && (
            <NewConsultantModal
              key={"new-item"}
              visible={newItemModalVisible}
              headers={headers}
              staticOptions={staticOptions}
              foreignKeyValues={foreignKeyValues}
              onSave={onSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
            />
          )}
        </div>
      </Flex>
      {/* {isUploading && message.loading("Uploading files...", 4)} */}
    </Content>
  );
};

export default ConsultantsConfigSettings;
