import { Select } from "antd";
import Flex from "../../../../../helpers/components/Flex";

const { Option } = Select;

const MultipleSelection = ({ value, id, title, config, roles, addChange }) => {
  let userRoles = [];

  if (config === "edit") {
    userRoles = value?.split(",") ?? [];
    if (userRoles.length === 1 && userRoles[0] === "0") {
      userRoles = [];
    }
  }

  return (
    <Flex
      key={id}
      flexDirection={"row"}
      style={{ justifyContent: "flex-start" }}
    >
      <div className="modal-text" style={{ width: "35%" }}>
        <p>{title}:</p>
      </div>
      <div className="modal-input" style={{ width: "65%" }}>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 15,
            }}
            showSearch={true}
            mode="multiple"
            onChange={(e) => {
              let valueForServer = "";
              e.map((item) => {
                if (valueForServer !== "") {
                  valueForServer += ",";
                }
                valueForServer += item;
              });
              addChange(id, valueForServer);
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={config === "edit" ? userRoles : []}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={null} />
            {Object.entries(roles).map(([key, optionValue]) => (
              <Option key={key} className="option" value={key}>
                {optionValue}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </Flex>
  );
};

export default MultipleSelection;
