import React from "react";
import {
  useChartStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { Select } from "antd";
import "./../../css/ultimateChart.css";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const GroupField = ({ groupFieldOptions }) => {
  //const capitilizedGroupFieldOptions = capitalizeWords(groupFieldOptions);

  const groupField = useChartStore((state) => state.groupField);
  const setGroupField = useChartStore((state) => state.setGroupField);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const { width } = useWindowDimensions();

  return (
    <div className="select">
      <Select
        style={{
          width: width < 1525 ? 120 : 200,
          marginLeft: 0,
          marginRight: 10,
          marginTop: 0,
          marginBottom: -50,
        }}
        showSearch={true}
        filterOption={(iv, op) => {
          let opToUse = op.children ? op.children : "";
          return opToUse.toLowerCase().includes(iv.toLowerCase());
        }}
        dropdownStyle={{
          minWidth: "max-content",
          paddingLeft: 5,
          paddingRight: 5,
          overflowY: "auto",
        }}
        onChange={(e) => setGroupField(e)}
        defaultValue={groupField}
        placeholder={`${commonTitles.groupfield}`}
        virtual={false}
      >
        <Option className="option" key={""} value={null} />
        {groupFieldOptions.map(({ id, view }) => (
          <Option className="option" key={`${id}-${view}`} value={id}>
            {view}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default GroupField;
