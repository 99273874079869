import "./adminMenu.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

const AdminMenuHead = ({ headers }) => {
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell style={{ width: "5%" }} key={"ghost-cell"} />
        <TableCell style={{ width: "5%" }} key={"ghost-cell-2"} />
        {headers.map((item, index) => (
          <TableCell
            align={index === headers.length - 1 ? "center" : "left"}
            key={item}
            className="admin-header"
          >
            <p key={`${item} - text`}>{item}</p>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AdminMenuHead;
