import "../../../css/ultimateTable.css";
import { Card, Col, Row, Tooltip, Layout } from "antd";
import FormControl from "@mui/material/FormControl";
import { useAuthStore } from "../../../../../store/useStores";
import { useState } from "react";
import inputDecider from "../../inputDecider/inputDecider";
import Flex from "../../../../../helpers/components/Flex";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import htmlCodesRemover from "../../htmlCodesRemover";

const { Content } = Layout;

const CopyModalContent = ({
  row,
  columns,
  staticOptions,
  foreignKeyValues,
  changes,
  addChange,
  columnsChanged,
  privileges,
  autoCompletedFields,
  moduleID,
}) => {
  const role = useAuthStore((state) => state.role);
  const userID = useAuthStore((state) => state.userID);
  const { width } = useWindowDimensions();

  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);

  const checkEmailValidity = (email) => {
    if (email) {
      setValidEmail(/\S+@\S+\.\S+/.test(email));
    } else {
      setValidEmail(true);
    }
  };

  const checkPhoneValidity = (phone) => {
    if (phone) {
      setValidPhone(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone));
    } else {
      setValidPhone(true);
    }
  };

  const attachColumns = columns
    .filter((column) => column.F_TYPE === "24" || column.F_TYPE === "25")
    .map((item) => item.F_ID);

  let newFilesAdded = false;
  attachColumns.forEach((id) => {
    if (row[id]) {
      const initialFileIDs = row[id].map((file) => file.uid);

      if (changes[id]) {
        changes[id].forEach((file) => {
          if (!initialFileIDs.includes(file.uid)) {
            newFilesAdded = true;
          }
        });
      }
    }
  });

  const isFirefox = typeof InstallTrigger !== "undefined";

  let newColumns = [];
  let i = 0;

  [...columns]
    .sort(
      (
        { F_EDITORDER: F_EDITORDER_A = 0 },
        { F_EDITORDER: F_EDITORDER_B = 0 }
      ) => {
        if (!F_EDITORDER_A) {
          if (isFirefox) {
            return -1;
          }
          return 1;
        } else if (!F_EDITORDER_B) {
          return -1;
        } else {
          return Number(F_EDITORDER_A) - Number(F_EDITORDER_B);
        }
      }
    )
    .forEach((column) => {
      if (column?.F_SINGLELINE) {
        if (column.F_SINGLELINE === "L") {
          newColumns.push(column);
          newColumns.push({
            F_ID: i.toString(),
            T_TITLE: "",
            F_REQUIRED: "N",
            F_TYPE: "0",
            F_LIST: "Y",
          });
        }

        if (column.F_SINGLELINE === "R") {
          newColumns.push({
            F_ID: i.toString(),
            T_TITLE: "",
            F_REQUIRED: "N",
            F_TYPE: "0",
            F_LIST: "Y",
          });
          newColumns.push(column);
        }

        i++;
      } else {
        newColumns.push(column);
      }
    });

  let viewRights = {};
  newColumns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  return (
    <Flex flexDirection={"column"}>
      <Content>
        <Card>
          <Row gutter={[20, 10]} justify={width < 1450 ? "center" : "left"}>
            {newColumns
              .filter((column) => viewRights[column.F_ID])
              .map(
                ({
                  F_ID,
                  F_TYPE,
                  T_TITLE,
                  F_REQUIRED,
                  INFO,
                  canupdate,
                  canupdateifnull,
                  F_PARENTFIELDID,
                  F_RENDER,
                  F_FORMAT,
                }) => (
                  <Col
                    className="gutter-row"
                    span={newColumns.length === 1 ? 20 : width < 1450 ? 20 : 10}
                    key={F_ID}
                  >
                    <div className="grid-horizontal">
                      <div className="filters-first_column">
                        <>
                          {((columnsChanged.includes(F_ID) &&
                            !attachColumns.includes(F_ID)) ||
                            (columnsChanged.includes(F_ID) &&
                              attachColumns.includes(F_ID) &&
                              newFilesAdded)) &&
                            T_TITLE && <p className="asterisk">*</p>}

                          {T_TITLE && (
                            <>
                              {T_TITLE.length > 22 ? (
                                <Tooltip
                                  placement="bottom"
                                  color="white"
                                  overlayInnerStyle={{ color: "black" }}
                                  title={
                                    <Flex flexDirection={"column"}>
                                      <b>{htmlCodesRemover(T_TITLE)}</b>
                                      {INFO && <i>{INFO}</i>}
                                    </Flex>
                                  }
                                >
                                  {htmlCodesRemover(T_TITLE).slice(0, 22) +
                                    "..."}
                                  :
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  placement="bottom"
                                  title={INFO && <i>{INFO}</i>}
                                  color="white"
                                  overlayInnerStyle={{ color: "black" }}
                                >
                                  {htmlCodesRemover(T_TITLE)}:
                                </Tooltip>
                              )}
                            </>
                          )}
                        </>
                      </div>

                      <FormControl
                        sx={{
                          mt: -1,
                          mb: 0.5,
                          ml: 1,
                          mr: 1,
                          minWidth: 300,
                        }}
                        className="filters-row"
                        key={F_ID}
                      >
                        {inputDecider(
                          F_TYPE,
                          F_ID,
                          row[F_ID],
                          staticOptions,
                          foreignKeyValues,
                          addChange,
                          "copy",
                          F_REQUIRED,
                          [],
                          row,
                          true,
                          true,
                          moduleID,
                          autoCompletedFields,
                          F_RENDER,
                          F_FORMAT,
                          F_PARENTFIELDID,
                          changes
                        )}
                      </FormControl>
                    </div>
                  </Col>
                )
              )}
          </Row>
        </Card>
      </Content>
    </Flex>
  );
};

export default CopyModalContent;
