import AlertMessageStore from "./config/AlertMessageStore";
import AlertStore from "./config/AlertStore";
import AuthStore from "./config/AuthStore";
import CommonTitlesStore from "./config/CommonTitlesStore";
import MenuStore from "./config/MenuStore";
import ModTitlesStore from "./config/ModTitlesStore";
import FilterStore from "./config/FilterStore";
import ChartStore from "./config/ChartStore";
import AdminStore from "./config/AdminStore";
import ImportStore from "./config/ImportStore";
import PaginationStore from "./config/PaginationStore";
import ModuleStore from "./config/ModuleStore";

const Stores = {
  AlertMessageStore,
  AlertStore,
  AuthStore,
  CommonTitlesStore,
  MenuStore,
  ModTitlesStore,
  FilterStore,
  ChartStore,
  AdminStore,
  ImportStore,
  PaginationStore,
  ModuleStore,
};

export default Stores;
