import { useEffect, useState } from "react";
import SelectFieldModalContent from "./SelectFieldModalContent";
import {
  useCommonTitlesStore,
  useAdminStore,
  useAuthStore,
} from "../../../../../../../../store/useStores";
import { Modal, Button } from "antd";
import apiGetModulesList from "../../../../../../../../api/endpoints/apiGetModulesList";

const SelectFieldModal = ({
  visible,
  onCancel,
  deleteField,
  addField,
  editFieldTagInfo,
  currentFieldID,
  currentModuleID,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  //const currentModuleID = useAdminStore((state) => state.moduleID);
  const baseURL = useAuthStore((state) => state.baseURL);

  const [moduleID, setModuleID] = useState("");
  const [moduleTitle, setModuleTitle] = useState("");
  const [fieldID, setFieldID] = useState("");
  const [fieldTitle, setFieldTitle] = useState("");
  const [finalValue, setFinalValue] = useState("");

  const updateModuleID = (id) => setModuleID(id);
  const updateFieldID = (id) => setFieldID(id);
  const updateModuleTitle = (title) => setModuleTitle(title);
  const updateFieldTitle = (title) => setFieldTitle(title);

  useEffect(async () => {
    setModuleID(editFieldTagInfo.moduleID);
    setFieldID(editFieldTagInfo.fieldID);

    const { modules } = await apiGetModulesList(baseURL);
    const tempModuleTitle =
      modules.filter((module) => module.M_ID === editFieldTagInfo.moduleID)?.[0]
        ?.T_TITLE ?? "";
    setModuleTitle(tempModuleTitle);
  }, []);

  useEffect(() => {
    if (moduleID === currentModuleID) {
      setFinalValue(`[${fieldID}]`);
    } else {
      setFinalValue(`[${moduleID}|${fieldID}]`);
    }
  }, [moduleID, fieldID]);

  return (
    <Modal
      title={commonTitles["CO|1|ADDFIELD"]}
      open={visible}
      onCancel={onCancel}
      width={600}
      style={{ marginTop: 40 }}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="delete"
          style={{ backgroundColor: "red", color: "white" }}
          onClick={() => deleteField(editFieldTagInfo.index)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() =>
            addField(
              finalValue,
              fieldTitle,
              moduleTitle,
              editFieldTagInfo.index
            )
          }
        >
          {commonTitles["CO|1|ADDBTN"]}
        </Button>,
      ]}
    >
      <SelectFieldModalContent
        moduleID={moduleID}
        fieldID={fieldID}
        updateModuleID={updateModuleID}
        updateFieldID={updateFieldID}
        updateModuleTitle={updateModuleTitle}
        updateFieldTitle={updateFieldTitle}
        currentFieldID={currentFieldID}
        currentModuleID={currentModuleID}
      />
    </Modal>
  );
};

export default SelectFieldModal;
