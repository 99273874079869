import "../modulesConfig.css";
import { Layout, Spin, Card, message } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import FieldsConfigSettings from "./FieldsConfigSettings";
import FieldsConfigHead from "./FieldsConfigHead";
import FieldsConfigBody from "./FieldsConfigBody";
import apiGetModuleFields from "../../../../api/endpoints/apiGetModuleFields";
import {
  useAdminStore,
  useAuthStore,
  useCommonTitlesStore,
  useModTitlesStore,
} from "../../../../store/useStores";
import Navigation from "../../../navigation/Navigation";
import Flex from "../../../../helpers/components/Flex";
import filterFieldsDataOnSearch from "../../../module/helpers/filterFieldsDataOnSearch";
import axios from "axios";
import { DeleteModal } from "../modals/deleteModals";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";
import htmlCodesRemover from "../../../ultimateTable/helpers/htmlCodesRemover";
import apiUpdateFieldsOrder from "../../../../api/endpoints/apiUpdateFieldsOrder";
import BackgroundWrapper from "../../../../helpers/components/BackgroundWrapper";
import TableWrapper from "../../../../helpers/components/TableWrapper";
import AdministrationBanner from "../../administrationBanner/AdministrationBanner";

const { Content } = Layout;

const FieldsConfig = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const id = useAdminStore((state) => state.moduleID);
  const bannerTitle = useAdminStore((state) => state.bannerTitle);

  const [data, setData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [refModules, setRefModules] = useState([]);
  const [fields, setFields] = useState({});
  const [staticOptions, setStaticOptions] = useState({});

  const [selection, setSelection] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const serverData = await apiGetModuleFields(baseURL, id);

    setFields(serverData.fields);
    setStaticOptions(serverData.staticoptions);
    setRefModules(serverData.refmodules);
    setHeadData([
      { id: serverData.fields[4].FIELD, title: serverData.fields[4].TITLE },
      { id: serverData.fields[5].FIELD, title: serverData.fields[5].TITLE },
      { id: serverData.fields[7].FIELD, title: serverData.fields[7].TITLE },
      { id: serverData.fields[8].FIELD, title: serverData.fields[8].TITLE },
      { id: serverData.fields[9].FIELD, title: serverData.fields[9].TITLE },
      { id: serverData.fields[10].FIELD, title: serverData.fields[10].TITLE },
      { id: serverData.fields[11].FIELD, title: serverData.fields[11].TITLE },
      { id: serverData.fields[32].FIELD, title: serverData.fields[32].TITLE },
    ]);

    const tempData = serverData.rows.map((item) => ({
      ...item,
      T_TITLE: htmlCodesRemover(item.T_TITLE) ?? item.T_TITLE,
    }));

    setBodyData(tempData);
    setData(tempData);
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [projectID, language]);

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const updateDataEditOrder = async (items) => {
    const fields = items.map((item) => ({
      F_ID: item.F_ID,
      F_EDITORDER: item.F_EDITORDER,
      F_SINGLELINE: item.F_SINGLELINE,
    }));

    const response = await apiUpdateFieldsOrder(baseURL, fields);

    if (response?.STATUS === "Success") {
      const newData = items.sort((a, b) => a.F_ORDER - b.F_ORDER);
      setBodyData(newData);
      setData(newData);
    }
  };

  const updateData = (config, changes) => {
    if (config === "edit") {
      setModalVisible(false);
      const tempData = bodyData.map((item) => {
        if (item.F_ID === changes?.F_ID) {
          return { ...item, ...changes };
        } else {
          return item;
        }
      });
      setBodyData(tempData);
      setData(tempData);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
      setBodyData([...bodyData, changes]);
      setData([...data, changes]);
    }
  };

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
    }
  };

  const showDeleteModal = (id) => {
    setItemToDelete(id);
    setDeleteModalVisible(true);
  };
  const cancelDeleteModal = () => setDeleteModalVisible(false);

  const handleDelete = async (id) => {
    const url = `${baseURL}/App_fields_api/delete`;

    try {
      const response = await axios.post(
        url,
        {
          id: id,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_fields_api/delete: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        setBodyData(bodyData.filter((item) => item.F_ID !== id));
        setData(bodyData.filter((item) => item.F_ID !== id));
        setDeleteModalVisible(false);
        setModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateSearchValue = (change) => {
    let tempData = [];

    tempData = filterFieldsDataOnSearch(bodyData, change, headData);

    if (change) {
      setBodyData(tempData);
    } else {
      setBodyData(data);
    }
  };

  const onSort = async (children) => {
    const newIndex = children.newIndex;
    const oldIndex = children.oldIndex;

    if (newIndex === oldIndex) {
      return;
    }

    var element = bodyData[oldIndex];
    bodyData.splice(oldIndex, 1);
    bodyData.splice(newIndex, 0, element);

    bodyData.forEach((item, index) => (item.F_ORDER = (index + 1).toString()));

    setBodyData([...bodyData]);

    const url = `${baseURL}/App_fields_api/updateorder`;
    const fieldIds = bodyData.map((item) => item.F_ID);

    try {
      const response = await axios.post(
        url,
        {
          fieldids: fieldIds,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_fields_api/updateorder: response: ", response?.data);
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <Flex flexDirection={"column"}>
      <Navigation />
      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.commonData} & ${modTitles.modules} `}
          updateSearchValue={updateSearchValue}
          showSearch={true}
          field={`/ ${bannerTitle}`}
        />
        <TableWrapper>
          {isLoading ? (
            <Card
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
                width: "98.5vw",
                display: "flex",
                justifyContent: "center",
                height: "85vh",
              }}
            >
              <Spin size="large" style={{ marginTop: "15rem" }} />
            </Card>
          ) : (
            <Layout
              style={{
                borderRadius: 10,
                overflow: "hidden",
                height: "max-content",
                width: "100%",
              }}
            >
              <FieldsConfigSettings
                key={"new-item"}
                headData={headData}
                bodyData={bodyData}
                fields={fields}
                refModules={refModules}
                staticOptions={staticOptions}
                newItemModalVisible={newItemModalVisible}
                showModal={showModal}
                updateData={updateData}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
                updateDataEditOrder={updateDataEditOrder}
                currentModuleID={id}
              />
              <Layout className="site-layout">
                <Content
                  style={{
                    paddingBottom: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                    minHeight: "max-content",
                    backgroundColor: "transparent",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    className="testing_table"
                    style={{ maxHeight: "70vh" }}
                  >
                    <Table stickyHeader>
                      <FieldsConfigHead headers={headData} />
                      <FieldsConfigBody
                        key={bodyData?.length}
                        data={bodyData}
                        headData={headData}
                        fields={fields}
                        refModules={refModules}
                        staticOptions={staticOptions}
                        modalVisible={modalVisible}
                        deleteModalVisible={deleteModalVisible}
                        modalData={modalData}
                        showModal={showModal}
                        updateData={updateData}
                        handleDelete={handleDelete}
                        showDeleteModal={showDeleteModal}
                        handleCancel={handleCancel}
                        onSort={onSort}
                        currentModuleID={id}
                      />
                    </Table>
                  </TableContainer>
                </Content>
              </Layout>
            </Layout>
          )}
        </TableWrapper>
      </BackgroundWrapper>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          selectedItems={[itemToDelete]}
          onDelete={handleDelete}
          onCancel={cancelDeleteModal}
        />
      )}
    </Flex>
  );
};

export default FieldsConfig;
