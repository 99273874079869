import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./data.css";
import DnsIcon from "@mui/icons-material/Dns";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";

const Data = ({ path }) => {
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Link
      to={`${path}`}
      className="metrics"
      data-tip={`${commonTitles.data}`}
      style={{ color: bannerTextColor }}
    >
      {<DnsIcon />}
      <ReactTooltip place="bottom" />
    </Link>
  );
};

export default Data;
