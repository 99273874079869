export const convertMonthToLabel = (month, commonTitles, config, language) => {
  const monthToUse = month + 1;

  if (monthToUse === 1) {
    if (config === "short") {
      if (language === "EL") {
        return "Ιαν.";
      } else {
        return "Jan.";
      }
    }
    return commonTitles.january;
  }
  if (monthToUse === 2) {
    if (config === "short") {
      if (language === "EL") {
        return "Φεβ.";
      } else {
        return "Feb.";
      }
    }
    return commonTitles.february;
  }
  if (monthToUse === 3) {
    if (config === "short") {
      if (language === "EL") {
        return "Μαρ.";
      } else {
        return "Mar.";
      }
    }
    return commonTitles.march;
  }
  if (monthToUse === 4) {
    if (config === "short") {
      if (language === "EL") {
        return "Απρ.";
      } else {
        return "Apr.";
      }
    }
    return commonTitles.april;
  }
  if (monthToUse === 5) {
    return commonTitles.may;
  }
  if (monthToUse === 6) {
    if (config === "short") {
      if (language === "EL") {
        return "Ιουν.";
      }
    } else return commonTitles.june;
  }
  if (monthToUse === 7) {
    if (config === "short") {
      if (language === "EL") {
        return "Ιουλ.";
      }
    } else return commonTitles.july;
  }
  if (monthToUse === 8) {
    if (config === "short") {
      if (language === "EL") {
        return "Αυγ.";
      } else {
        return "Aug.";
      }
    }
    return commonTitles.august;
  }
  if (monthToUse === 9) {
    if (config === "short") {
      if (language === "EL") {
        return "Σεπτ.";
      } else {
        return "Sept.";
      }
    }
    return commonTitles.september;
  }
  if (monthToUse === 10) {
    if (config === "short") {
      if (language === "EL") {
        return "Οκτ.";
      } else {
        return "Oct.";
      }
    }
    return commonTitles.october;
  }
  if (monthToUse === 11) {
    if (config === "short") {
      if (language === "EL") {
        return "Νοεμ.";
      } else {
        return "Nov.";
      }
    }
    return commonTitles.november;
  }
  if (monthToUse === 12) {
    if (config === "short") {
      if (language === "EL") {
        return "Δεκ.";
      } else {
        return "Dec.";
      }
    }
    return commonTitles.december;
  }
};

export const convertDateFormat = (dateFormat) => {
  if (dateFormat === "21" /* DD/MM/YY */) {
    return "DD/MM/YY";
  } else if (dateFormat === "15" /* DD/MM/YYYY */) {
    return "DD/MM/YYYY";
  } else if (dateFormat === "16" /* MM/DD/YYYY */) {
    return "MM/DD/YYYY";
  } else if (dateFormat === "22" /* MM/DD/YY */) {
    return "MM/DD/YY";
  } else if (dateFormat === "17" /* YYYY/MM/DD */) {
    return "YYYY/MM/DD";
  }

  return "YYYY/MM/DD";
};
