import Flex from "../../../helpers/components/Flex";
import { Card, Button, DatePicker, Select, Checkbox } from "antd";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead, TableBody } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { NewParticipantModal } from "../helpers/modals";
import { useState } from "react";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import { convertDateFormat } from "../../ultimateTable/helpers/converters";
import "../css/safetyAudit.css";
import dayjs from "dayjs";
import { checkboxOptions } from "../server";

const { Option } = Select;

const GeneralInfo = ({ headers, bodyData, updateBodyData }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);
  const [changes, setChanges] = useState({});

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  const [newParticipantModalVisible, setNewParticipantModalVisible] =
    useState(false);

  const showNewParticipantModal = () => {
    setNewParticipantModalVisible(true);
  };

  const onCheckBoxChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const addParticipant = () => {
    updateBodyData("participants", [...bodyData.participants, { ...changes }]);
    setChanges({});
    setNewParticipantModalVisible(false);
  };

  const cancelNewParticipantModal = () => {
    setNewParticipantModalVisible(false);
  };

  return (
    <Flex flexDirection={"column"}>
      <Card style={{ borderRadius: "10px", marginTop: "0.5rem" }}>
        <Flex flexDirection={"column"} style={{ gap: 15 }}>
          <Flex flexDirection={"row"} style={{ gap: 50 }}>
            <Flex flexDirection={"column"} style={{ gap: 3 }}>
              <p style={{ fontWeight: 600 }}>Αρ. Επιθέωρησης:</p>
              <input
                type="text"
                className="edit-input"
                defaultValue={bodyData.auditNumber ?? ""}
                onChange={(e) => updateBodyData("auditNumber", e.target.value)}
              />
            </Flex>
            <Flex flexDirection={"column"} style={{ gap: 3 }}>
              <p style={{ fontWeight: 600 }}>Αρχή:</p>
              <DatePicker
                key={"from"}
                id={"from"}
                format={dateFormatToUse}
                placeholder={[commonTitles["CO|1|SLCTDATE"]]}
                defaultValue={
                  bodyData?.start ? dayjs(bodyData.start, dateFormatToUse) : ""
                }
                onChange={(dateString) =>
                  updateBodyData(
                    "start",
                    dateString ? dateString.format(dateFormatToUse) : ""
                  )
                }
                bordered={true}
                style={{
                  width: 160,
                  height: 30,
                  borderRadius: 5,
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} style={{ gap: 3 }}>
              <p style={{ fontWeight: 600 }}>Τέλος:</p>
              <DatePicker
                key={"from"}
                id={"from"}
                format={dateFormatToUse}
                placeholder={[commonTitles["CO|1|SLCTDATE"]]}
                defaultValue={
                  bodyData?.end ? dayjs(bodyData.end, dateFormatToUse) : ""
                }
                onChange={(dateString) =>
                  updateBodyData(
                    "end",
                    dateString ? dateString.format(dateFormatToUse) : ""
                  )
                }
                bordered={true}
                style={{
                  width: 160,
                  height: 30,
                  borderRadius: 5,
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} style={{ gap: 3 }}>
              <p style={{ fontWeight: 600 }}>Νομοθεσία:</p>
              <div className="select">
                <Select
                  allowClear
                  style={{
                    width: 150,
                  }}
                  showSearch={true}
                  filterOption={(iv, op) =>
                    op.value
                      .toLocaleLowerCase()
                      .includes((iv || "").toLocaleLowerCase())
                  }
                  onChange={(e) => updateBodyData("legislation", e)}
                  defaultValue={bodyData.legislation ?? "national"}
                  dropdownStyle={{ overflowY: "auto" }}
                  virtual={false}
                >
                  <Option
                    key={"national"}
                    className="option"
                    value={"national"}
                  >
                    Εθνική
                  </Option>
                  <Option
                    key={"european"}
                    className="option"
                    value={"european"}
                  >
                    Ευρωπαϊκή
                  </Option>
                </Select>
              </div>
            </Flex>
          </Flex>
          <Flex flexDirection={"row"} style={{ marginTop: "1rem" }}>
            <Flex flexDirection={"row"}>
              <Flex flexDirection={"column"} style={{ gap: 3 }}>
                <p style={{ fontWeight: 600 }}>Επιθεωρούμενος:</p>
                <textarea
                  type="text"
                  style={{
                    minWidth: 200,
                    borderRadius: 10,
                    minHeight: 60,
                    paddingLeft: 5,
                  }}
                  defaultValue={bodyData.auditee?.text ?? ""}
                  onChange={(e) =>
                    updateBodyData("auditee", e.target.value, "text")
                  }
                />
              </Flex>
              <Checkbox.Group
                style={{
                  marginLeft: 20,
                  marginTop: 40,
                }}
                options={checkboxOptions}
                defaultValue={bodyData.auditee?.checked ?? []}
                onChange={(checkedValues) => {
                  onCheckBoxChange(checkedValues);
                  updateBodyData("auditee", checkedValues, "checked");
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} style={{ gap: 10 }}>
              <Flex flexDirection={"column"} style={{ gap: 1 }}>
                <p style={{ fontWeight: 600 }}>Καιρικές Συνθήκες:</p>
                <textarea
                  type="text"
                  style={{
                    maxWidth: 500,
                    borderRadius: 10,
                    height: 40,
                    paddingLeft: 5,
                  }}
                  defaultValue={bodyData.weather ?? ""}
                  onChange={(e) => updateBodyData("weather", e.target.value)}
                />
              </Flex>
              <Flex flexDirection={"column"} style={{ gap: 1 }}>
                <p style={{ fontWeight: 600 }}>Άλλο:</p>
                <textarea
                  type="text"
                  style={{
                    width: 500,
                    borderRadius: 10,
                    minHeight: 40,
                    paddingLeft: 5,
                  }}
                  defaultValue={bodyData.other ?? ""}
                  onChange={(e) => updateBodyData("other", e.target.value)}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Card style={{ borderRadius: "10px", marginTop: "0.5rem" }}>
        <p style={{ fontWeight: 600 }}>Συμμετέχοντες:</p>
        <div className="switch-button">
          <Button
            style={{
              justifyContent: "flex-end",
              marginTop: -40,
              marginBottom: 5,
              border: "none",
              color: "#186A8E",
            }}
            onClick={showNewParticipantModal}
          >
            + Προσθήκη συμμετέχοντα
          </Button>
        </div>
        {newParticipantModalVisible && (
          <NewParticipantModal
            visible={newParticipantModalVisible}
            headers={headers}
            onSave={addParticipant}
            onCancel={cancelNewParticipantModal}
            changes={changes}
            addChange={addChange}
          />
        )}

        <TableContainer component={Paper} className="testing_table">
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header.id}
                    align="center"
                    style={{
                      backgroundColor: "#cfe2f3",
                      fontSize: 12,
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {header.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bodyData.participants.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {headers.map((header, index) => (
                      <TableCell key={`${index}-${header.id}`} align="center">
                        {item[header.id] ?? null}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Flex>
  );
};

export default GeneralInfo;
