import "./adminTitles.css";
import { Layout } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import AdminTitlesHead from "./AdminTitlesHead";
import AdminTitlesBody from "./AdminTitlesBody";
import apiGetSystemTitles from "../../../api/endpoints/apiGetSystemTitles";
import { useAuthStore } from "../../../store/useStores";

const { Content } = Layout;

const AdminTitlesContent = ({
  selection,
  changes,
  updateChanges,
  addChange,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const fetchData = async (refType, refID) => {
    const serverData = await apiGetSystemTitles(
      baseURL,
      language,
      refType,
      refID
    );

    setData(serverData.titles);
    setHeaders([
      { id: "T1_TITLE", title: serverData.column1title },
      { id: "T2_TITLE", title: serverData.column2title },
    ]);
  };

  useEffect(() => {
    if (selection === "CO") {
      fetchData("CO", "");
      updateChanges({
        system: "1",
        reftype: "CO",
        refid: "",
        lang: language,
        titles: [],
      });
    } else if (selection === "ME") {
      fetchData("ME", "");
      updateChanges({
        system: "1",
        reftype: "ME",
        refid: "",
        lang: language,
        titles: [],
      });
    } else {
      fetchData("MO", selection);
      updateChanges({
        system: "1",
        reftype: "MO",
        refid: selection,
        lang: language,
        titles: [],
      });
    }
  }, [selection]);

  return (
    <Layout className="site-layout">
      <Content
        style={{
          paddingBottom: 15,
          paddingLeft: 20,
          paddingRight: 20,
          minHeight: "67vh",
          backgroundColor: "transparent",
        }}
      >
        <TableContainer
          component={Paper}
          className="testing_table"
          style={{ maxHeight: "65vh", flexDirection: "column" }}
        >
          <Table stickyHeader>
            <AdminTitlesHead headers={headers} />
            <AdminTitlesBody
              key={selection}
              data={data}
              changes={changes}
              addChange={addChange}
            />
          </Table>
        </TableContainer>
      </Content>
    </Layout>
  );
};

export default AdminTitlesContent;
