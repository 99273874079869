import "./adminMenu.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { AdminMenuModal } from "./AdminMenuModal";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  useAuthStore,
  useCommonTitlesStore,
  useMenuStore,
} from "../../../store/useStores";
import axios from "axios";
import { message } from "antd";
import { useState } from "react";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";

const AdminMenuBody = ({
  data,
  types,
  modules,
  titles,
  headers,
  showModal,
  showDeleteModal,
  modalVisible,
  modalData,
  updateData,
  handleCancel,
  handleDelete,
  onSort,
}) => {
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const menu = useMenuStore((state) => state.menu);
  const setMenu = useMenuStore((state) => state.setMenu);

  const DragHandle = SortableHandle(({ style }) => (
    <span style={{ ...style, ...{ cursor: "move" } }}>
      <DragHandleIcon />
    </span>
  ));

  const TableBodySortable = SortableContainer(({ children }) => (
    <TableBody>{children}</TableBody>
  ));

  const Row = SortableElement(({ data, ...other }) => {
    return (
      <TableRow {...other}>
        <TableCell style={{ width: "5%" }}>
          <Tooltip placement="right" title={titles["MO|1|EDITROW"]}>
            <EditOutlined
              onClick={() => showModal(data, "edit")}
              style={{
                fontSize: "18px",
                color: "rgb(8, 8, 46)",
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell style={{ width: "5%" }}>
          <DragHandle />
        </TableCell>
        <TableCell>{htmlCodesRemover(data?.T_TITLE)}</TableCell>
        <TableCell>
          {htmlCodesRemover(
            Object.entries(modules).filter(
              ([key, value]) => key === data?.ME_MODULEID
            )?.[0]?.[1]
          )}
        </TableCell>
        <TableCell align="center">
          {data.ME_VISIBLE === "Y" ? (
            <CheckOutlined style={{ color: "green" }} />
          ) : (
            <CloseOutlined style={{ color: "red" }} />
          )}
        </TableCell>
      </TableRow>
    );
  });

  const onSave = async (changes) => {
    const url = `${baseURL}/App_menu_api/save`;
    try {
      const response = await axios.post(
        url,
        { ...modalData, ...changes },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("app_modules_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changesWithExtraInfo = {
          ["ME_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          ["ME_LASTUSER"]: response?.data?.LASTUSER,
          ["ME_WORKSPACEID"]: response?.data?.WORKSPACEID,
          ...modalData,
          ...changes,
        };

        updateData(
          "edit",
          changesWithExtraInfo,
          parseInt(modalData.ME_ORDER) - 1
        );

        let alreadyExists = false;
        let tempMenu = [];
        if (changesWithExtraInfo.ME_VISIBLE === "Y") {
          tempMenu = menu.map((item) => {
            if (changesWithExtraInfo.ME_PARENTID === item.ME_ID) {
              let tempChildren = item.children.map((child) => {
                const childrenIDs = item.children.map((item) => item.ME_ID);

                if (childrenIDs.includes(changesWithExtraInfo.ME_ID)) {
                  alreadyExists = true;
                }
                if (changesWithExtraInfo.ME_ID === child.ME_ID) {
                  return changesWithExtraInfo;
                } else {
                  return child;
                }
              });

              return {
                ...item,
                children: tempChildren,
              };
            } else {
              return item;
            }
          });

          if (!alreadyExists) {
            tempMenu = menu.map((item) => {
              if (changesWithExtraInfo.ME_PARENTID === item.ME_ID) {
                let tempChildren = [...item.children, changesWithExtraInfo];

                return {
                  ...item,
                  children: tempChildren,
                };
              } else {
                return item;
              }
            });
          }

          setMenu(tempMenu);
        } else {
          const tempMenu = menu.map((item) => {
            if (changesWithExtraInfo.ME_PARENTID === item.ME_ID) {
              let tempChildren = item.children.filter(
                (child) => changesWithExtraInfo.ME_ID !== child.ME_ID
              );
              return {
                ...item,
                children: tempChildren,
              };
            } else {
              return item;
            }
          });

          setMenu(tempMenu);
        }

        setCoreChange(!coreChange);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <TableBodySortable onSortEnd={onSort} useDragHandle>
      {data?.map((item, index) => (
        <Row key={index} index={index} data={item} />
      ))}
      {modalVisible && (
        <AdminMenuModal
          key={`${data.ME_ID}-modal`}
          visible={modalVisible}
          data={modalData}
          headers={headers}
          types={types}
          titles={titles}
          modules={modules}
          onSave={onSave}
          onDelete={handleDelete}
          onCancel={handleCancel}
          showDeleteModal={showDeleteModal}
          config={"edit"}
        />
      )}
    </TableBodySortable>
  );
};

export default AdminMenuBody;
