import { message } from "antd";
import axios from "axios";

const apiReport = async (baseURL, moduleID, changes) => {
  console.log("apiReport: changes: ", changes);
  const url = `${baseURL}/Report_api/report`;
  try {
    const response = await axios.post(
      url,
      { moduleid: moduleID, ...changes },
      {
        withCredentials: true,
      }
    );
    //console.log("apiReport: response: ", response.data);
    // if (response?.data?.STATUS === "Success") {
    //   message.success("Επιτυχής έκδοση αναφοράς", [3]);
    // } else {
    //   message.warning("Σφάλμα", [3]);
    // }

    return response.data;
  } catch (err) {
    message.error(err, [3]);
  }
};

export default apiReport;
