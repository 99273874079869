import { message } from "antd";
import axios from "axios";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";

const apiChangePassword = async (baseURL, changes, commonTitles) => {
  const url = `${baseURL}/Users_api/changepassword`;
  try {
    const response = await axios.post(
      url,
      { ...changes },
      {
        withCredentials: true,
      }
    );
    console.log("apiChangePassword: response: ", response.data);
    // if (response?.data?.STATUS === "Success") {
    //   message.success(commonTitles["CO|1|PASSWDSUCC"], [3]);
    // } else {
    //   message.warning(commonTitles[response?.data?.MESSAGE], [3]);
    // }
    return response?.data;
  } catch (err) {
    //message.error(err, [3]);
    displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
  }
};

export default apiChangePassword;
