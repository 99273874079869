import { Modal, Button, Steps, Card } from "antd";
import { useState } from "react";
import Flex from "../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../store/useStores";
import StepContent from "../StepContent";

export const NewFieldModal = ({
  visible,
  headers,
  data,
  fields,
  refModules,
  staticOptions,
  onSave,
  onCancel,
  config,
  currentModuleID,
}) => {
  const [changes, setChanges] = useState({ F_SINGLELINE: "" });
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [type, setType] = useState(null);

  const addChange = (id, value) => {
    if (typeof id === "object") {
      setChanges({ ...changes, [id[0]]: value[0], [id[1]]: value[1] });
    } else {
      setChanges({ ...changes, [id]: value });
    }
  };

  //console.log("NewFieldModal: changes: ", changes);

  const changeType = (value) => {
    setType(value);
    setChanges({
      T_TITLE: changes?.T_TITLE ?? "",
      F_TYPE: value,
      F_SINGLELINE: "",
    });
  };

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setChanges({ ...changes, F_RENDER: "" });
    setCurrent(current - 1);
  };

  let showNextButton = false;
  if (changes.T_TITLE && changes.F_TYPE && current === 0) {
    showNextButton = true;
  }

  return (
    <Modal
      key={"new-item"}
      mask={false}
      maskClosable={false}
      open={visible}
      headers={headers}
      style={{
        marginTop: "-11vh",
        display: "flex",
        marginLeft: "20vw",
        width: "max-content",
      }}
      title={"Νέο Πεδίο"}
      onOk={() => onSave(changes)}
      onCancel={() => onCancel(config)}
      footer={[
        <Button key="back" onClick={() => onCancel(config)}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(changes)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <Flex
        flexDirection={"column"}
        style={{
          marginTop: "2.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <Steps
          current={current}
          style={{ marginBottom: "1rem", marginLeft: "10%", width: "80%" }}
          items={[
            {
              title: `${fields[4].TITLE} & ${fields[5].TITLE}`,
            },
            {
              title: commonTitles["CO|1|FLDCONF"],
            },
          ]}
        ></Steps>
        <Card
          bodyStyle={{
            height: "55vh",
            display: "flex",
            width: "60vw",
            overflowY: "auto",
            justifyContent: "center",
          }}
        >
          <StepContent
            current={current}
            type={type}
            changeType={changeType}
            changes={changes}
            addChange={addChange}
            fields={fields}
            staticOptions={staticOptions}
            refModules={refModules}
            data={data}
            bodyData={data}
            config={"new"}
            currentModuleID={currentModuleID}
          />
        </Card>
        {showNextButton && (
          <Flex
            flexDirection={"row"}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              style={{
                display: "flex",
                width: "max-content",
                marginTop: "0.5rem",
              }}
              onClick={() => next()}
            >
              {commonTitles["CO|1|NEXT"]}
            </Button>
          </Flex>
        )}
        {current > 0 && type && (
          <Flex
            flexDirection={"row"}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              type="primary"
              style={{
                display: "flex",
                width: "max-content",
                marginTop: "0.5rem",
              }}
              onClick={() => prev()}
            >
              {commonTitles["CO|1|PREV"]}
            </Button>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
