import "./consultantsConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { EditConsultantModal } from "./EditConsultantModal";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip, message } from "antd";
import ConsultantTableDisplayDecider from "./helpers/consultantTableDisplayDecider";
import { useCommonTitlesStore } from "../../../store/useStores";
import { useAuthStore } from "../../../store/useStores";
import { tranformChangesforServer } from "../../module/helpers/converters";
import { convertDataFromServer } from "../../module/helpers/converters";
import axios from "axios";
import { transformDataForServer } from "../../module/helpers/converters";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const ConsultantsConfigBody = ({
  data,
  headers,
  staticOptions,
  foreignKeyValues,
  showModal,
  modalVisible,
  modalData,
  updateData,
  handleCancel,
  showDeleteModal,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const userID = useAuthStore((state) => state.userID);

  let originalData = {};
  data.forEach((item) => {
    originalData = { ...originalData, [item.ROWID]: item };
  });

  const onSave = async (changes, rowID, lastModified) => {
    const url = `${baseURL}/Data_api/save`;

    const transformedChanges = tranformChangesforServer(
      changes,
      headers,
      data,
      rowID,
      foreignKeyValues,
      [],
      baseURL
    );
    //console.log("updateData: transformedChanges: ", transformedChanges);

    const dataForServer = transformDataForServer(
      rowID,
      originalData,
      headers,
      staticOptions,
      foreignKeyValues
    );
    //console.log("updateData: dataForServer: ", dataForServer);

    const tempData = convertDataFromServer(
      headers,
      rowID,
      originalData,
      staticOptions,
      foreignKeyValues,
      transformedChanges,
      "update",
      userID
    );
    //console.log("updateData: tempData: ", tempData);

    try {
      const response = await axios.post(
        url,
        {
          moduleid: "15",
          rowid: rowID,
          lastmodified: lastModified,
          ...dataForServer,
          ...transformedChanges,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Data_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|UPDMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|UPDMSG1"], 3, "success");
        const changes = {
          [rowID]: {
            ...modalData[rowID],
            ...tempData,
            LM: response?.data?.TIMESTAMP,
            ROWID: `${response?.data?.ROWID}`,
            US: userID,
          },
        };
        updateData("edit", changes);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];
        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });
        //message.warning(template, [3]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <TableBody>
      {data.map((row) => (
        <TableRow key={row.ROWID}>
          <TableCell>
            <Tooltip placement="right" title={commonTitles["CO|1|EDITCONS"]}>
              <EditOutlined
                onClick={() => showModal(row, "edit")}
                style={{
                  fontSize: "18px",
                  color: "rgb(8, 8, 46)",
                }}
              />
            </Tooltip>
          </TableCell>
          {headers.map(({ F_ID, F_RENDER, T_TITLE, F_TYPE }) => (
            <TableCell key={F_ID}>
              {ConsultantTableDisplayDecider(
                F_RENDER,
                F_TYPE,
                row[F_ID],
                T_TITLE
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
      {modalVisible && (
        <EditConsultantModal
          visible={modalVisible}
          data={modalData}
          originalData={originalData}
          headers={headers}
          staticOptions={staticOptions}
          foreignKeyValues={foreignKeyValues}
          onSave={onSave}
          onCancel={handleCancel}
          showDeleteModal={showDeleteModal}
          config={"edit"}
        />
      )}
    </TableBody>
  );
};

export default ConsultantsConfigBody;
