import { useAuthStore } from "../../../store/useStores";
import { Link } from "react-router-dom";
import { Dropdown, Space } from "antd";
import Flex from "../../../helpers/components/Flex";

const ParamMenu = ({ paramMenu, paramMenuTitle }) => {
  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );

  const paramItems =
    paramMenu.map((item) => ({
      key: item.ME_ORDER,
      label: (
        <Link to={`/module/${item.ME_MODULEID}`}>
          {item?.T_TITLE?.includes("&#39;")
            ? item.T_TITLE.split("&#39;").join("'")
            : item?.T_TITLE ?? ""}
        </Link>
      ),
    })) ?? [];

  const titleStyle = {
    color: "#fff",
    fontFamily: "var(--font-family)",
    fontWeight: 500,
    fontSize: 15,
    textTransform: "capitalize",
    wordSpacing: "0.3em",
    zIndex: 5,
    margin: "0 1rem",
    cursor: "pointer",
  };

  if (!paramMenu?.length) {
    return <Flex />;
  }

  return (
    <Flex
      flexDirection={"row"}
      style={{ marginTop: "-1rem", marginRight: "2rem" }}
    >
      <Dropdown menu={{ items: paramItems }}>
        <a style={titleStyle} onClick={(e) => e.preventDefault()}>
          <Space style={{ color: navigationTextColor }}>{paramMenuTitle}</Space>
        </a>
      </Dropdown>
    </Flex>
  );
};

export default ParamMenu;
