import { Input, Modal, Select, Button, Checkbox, Spin } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import apiGetUserRoleFieldRights from "../../../api/endpoints/apiGetUserRoleFieldRights";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore, useAuthStore } from "../../../store/useStores";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody, TableHead } from "@mui/material";

const { Option } = Select;

const FieldModalContent = ({
  fieldRights,
  headers,
  updateFieldRigths,
  allChecked,
  selectAll,
}) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "70vh" }}
      className="testing_table"
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                style={{ fontWeight: 700, color: "rgb(8, 8, 46)" }}
                key={header.id}
              >
                <Flex flexDirection={"row"} style={{ gap: "0.5rem" }}>
                  {index !== 0 && (
                    <Checkbox
                      checked={allChecked[header.id]}
                      onChange={(e) => selectAll(header.id, e.target.checked)}
                    />
                  )}
                  {header.title}
                </Flex>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(fieldRights).map((item) => (
            <TableRow key={item.F_ID}>
              {headers.map(({ id }, index) => (
                <>
                  {index === 0 ? (
                    <TableCell key={id}>{item[id]}</TableCell>
                  ) : (
                    <TableCell key={id} align="center">
                      <Checkbox
                        checked={item[id]}
                        onChange={(e) =>
                          updateFieldRigths(item.F_ID, id, e.target.checked)
                        }
                      />
                    </TableCell>
                  )}
                </>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const FieldModal = ({
  visible,
  title,
  onSave,
  onCancel,
  roleID,
  moduleID,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const [fieldRights, setFieldRights] = useState({});
  const [headers, setHeaders] = useState([]);
  const [allChecked, setAllChecked] = useState({
    V: false,
    I: false,
    U: false,
    E: false,
  });

  useEffect(async () => {
    const { fields, moduletitles } = await apiGetUserRoleFieldRights(
      baseURL,
      roleID,
      moduleID
    );
    setFieldRights(fields);
    setHeaders([
      { id: "T_TITLE", title: moduletitles["FD|260|TITLE"] },
      { id: "V", title: moduletitles["FD|256|TITLE"] },
      { id: "I", title: moduletitles["FD|257|TITLE"] },
      { id: "U", title: moduletitles["FD|258|TITLE"] },
      { id: "E", title: moduletitles["FD|261|TITLE"] },
    ]);
    let viewAll = true;
    let insertAll = true;
    let updateAll = true;
    let updateIfNullAll = true;

    Object.values(fields).forEach((item) => {
      if (!item.V) {
        viewAll = false;
      }
      if (!item.I) {
        insertAll = false;
      }
      if (!item.U) {
        updateAll = false;
      }
      if (!item.E) {
        updateIfNullAll = false;
      }
    });
    setAllChecked({
      V: viewAll,
      I: insertAll,
      U: updateAll,
      E: updateIfNullAll,
    });
  }, [roleID, moduleID]);

  const updateFieldRights = (fieldID, id, value) => {
    setFieldRights({
      ...fieldRights,
      [fieldID]: { ...fieldRights[fieldID], [id]: value },
    });
  };

  const selectAll = (id, value) => {
    let newState = {};
    Object.values(fieldRights).forEach((item) => {
      newState = { ...newState, [item.F_ID]: { ...item, [id]: value } };
    });

    setFieldRights(newState);
    setAllChecked({ ...allChecked, [id]: value });
  };

  return (
    <Modal
      key={`userRoleFieldRights-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        minWidth: "80vw",
      }}
      bodyStyle={{ paddingLeft: 80, paddingRight: 80 }}
      title={title}
      onOk={() => onSave(fieldRights)}
      onCancel={() => onCancel()}
      footer={[
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(fieldRights)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      {Object.values(fieldRights)?.length ? (
        <FieldModalContent
          roleID={roleID}
          moduleID={moduleID}
          headers={headers}
          allChecked={allChecked}
          fieldRights={fieldRights}
          updateFieldRigths={updateFieldRights}
          selectAll={selectAll}
        />
      ) : (
        <Spin />
      )}
    </Modal>
  );
};
