const createChartTitle = (
  dateField,
  groupField,
  grouping,
  displayFunction,
  sumField,
  columns,
  commonTitles,
  dateTypes
) => {
  const groupingTitles = {
    Daily: commonTitles.daily,
    Monthly: commonTitles.monthly,
    Yearly: commonTitles.yearly,
  };

  const displayFunctionTitles = {
    count: commonTitles.count,
    pctCount: commonTitles.pctCount,
    sum: commonTitles.sum,
    pctSum: commonTitles.pctSum,
  };

  let childxAxisField;
  let xAxisFieldToUse = dateField;

  if (dateField?.includes("-")) {
    let tempXAxisField = dateField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  const xAxisFieldColumn = columns.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )?.[0];

  let xAxisFieldName;
  let childxAxisFieldIndex;
  let childxAxisNumber;

  if (childxAxisField) {
    const childTitle = xAxisFieldColumn.DEP_FIELDS?.filter(
      (field) => field.F_ID === childxAxisField
    ).map((item) => item.TITLE);
    xAxisFieldName = `${xAxisFieldColumn?.T_TITLE} - ${childTitle}`;
    childxAxisNumber = xAxisFieldColumn.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === xAxisFieldToUse
    )[0]?.DEP_FIELDS;

    childxAxisFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childxAxisField;
    });
  } else {
    xAxisFieldName = xAxisFieldColumn?.T_TITLE;
  }

  let dependendDate = false;

  if (
    childxAxisField &&
    dateTypes?.includes(
      columns
        .filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
        ?.DEP_FIELDS.filter((field) => field?.F_ID === childxAxisField)?.[0]
        ?.F_TYPE
    )
  ) {
    dependendDate = true;
  }

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const groupFieldColumn = columns.filter(
    (column) => column.F_ID === groupFieldToUse
  )?.[0];

  let groupFieldName;
  let childGroupFieldIndex;
  let childGroupNumber;
  if (childGroupField) {
    const childTitle = groupFieldColumn?.DEP_FIELDS.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
    childGroupNumber = groupFieldColumn?.DEP_FIELDS.length;
    const dependentFields = columns.filter(
      (column) => column.F_ID === groupFieldToUse
    )[0]?.DEP_FIELDS;

    childGroupFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childGroupField;
    });
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
  }

  let sumFieldColumn = columns.filter(
    (column) => column.F_ID === sumField
  )?.[0];

  let title = "";

  if (groupField) {
    if (dateTypes?.includes(xAxisFieldColumn?.F_TYPE) || dependendDate) {
      if (sumField && ["sum", "pctSum"].includes(displayFunction)) {
        title = `${displayFunctionTitles[displayFunction]}: ${groupFieldName} - ${sumFieldColumn?.T_TITLE} (${groupingTitles?.[grouping]} ${commonTitles.grouping})`;
      } else {
        title = `${displayFunctionTitles[displayFunction]}: ${xAxisFieldName} - ${groupFieldName} (${groupingTitles?.[grouping]} ${commonTitles.grouping})`;
      }
    } else {
      title = `${displayFunctionTitles[displayFunction]}: ${xAxisFieldName} - ${groupFieldName}`;
    }
  } else {
    title = `${displayFunctionTitles[displayFunction]}: ${xAxisFieldName}`;
  }

  return title;
};

export default createChartTitle;
