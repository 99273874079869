import Flex from "../../../../../../helpers/components/Flex";
import { Input } from "antd";
import { useCommonTitlesStore } from "../../../../../../store/useStores";

const Widths = ({ data, addChange, fields, config }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[10].TITLE?.split(" - ")?.[0]}:
      </p>
      <Flex flexDirection={"row"} style={{ marginLeft: 22, width: 350 }}>
        <p>{fields[10].TITLE?.split(" - ")?.[1]}:</p>
        <Input
          style={{
            width: 80,
            marginLeft: 10,
            marginTop: -5,
            marginBottom: "1rem",
            marginRight: 8,
            borderRadius: 5,
          }}
          onChange={(e) => addChange("F_WIDTH", e.target.value)}
          defaultValue={config === "edit" ? data.F_WIDTH : null}
        />
        {/* <p>{fields[31].TITLE}:</p>
        <Input
          style={{
            width: 63,
            marginLeft: 10,
            marginTop: -5,
            marginBottom: "1rem",
            marginRight: 8,
            borderRadius: 5,
          }}
          onChange={(e) => addChange("F_EDITWIDTH", e.target.value)}
          defaultValue={config === "edit" ? data.F_EDITWIDTH : null}
        /> */}
        <p>{commonTitles["CO|1|REPORT"]}:</p>
        <Input
          style={{
            width: 80,
            marginLeft: 10,
            marginTop: -5,
            marginBottom: "1rem",
            borderRadius: 5,
          }}
          onChange={(e) => addChange("F_REPORTWIDTH", e.target.value)}
          defaultValue={config === "edit" ? data.F_REPORTWIDTH : null}
        />
      </Flex>
    </Flex>
  );
};

export default Widths;
