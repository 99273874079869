import "./staticList.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { StaticListModal } from "./StaticListModal";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
} from "react-sortable-hoc";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useState } from "react";
import { ConfirmationDeleteModal } from "../../../modals/deleteModals";
import { useCommonTitlesStore } from "../../../../../../store/useStores";

const StaticListBody = ({
  data,
  showModal,
  showDeleteModal,
  modalVisible,
  deleteModalVisible,
  modalData,
  handleOk,
  handleDelete,
  handleCancel,
  onSort,
  fields,
}) => {
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [indexToEdit, setIndexToEdit] = useState(null);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const DragHandle = SortableHandle(({ style }) => (
    <span style={{ ...style, ...{ cursor: "move" } }}>
      <DragHandleIcon />
    </span>
  ));

  const TableBodySortable = SortableContainer(({ children }) => (
    <TableBody>{children}</TableBody>
  ));

  const Row = SortableElement(({ data, ...other }) => {
    return (
      <TableRow {...other}>
        <TableCell style={{ width: "5%", backgroundColor: data.FO_BACKCOLOR }}>
          <DragHandle />
        </TableCell>
        <TableCell style={{ backgroundColor: data.FO_BACKCOLOR }}>
          {data?.T_TITLE}
        </TableCell>
        <TableCell style={{ width: "5%", backgroundColor: data.FO_BACKCOLOR }}>
          <Tooltip placement="left" title={commonTitles["CO|1|EDITOPT"]}>
            <EditOutlined
              onClick={() => {
                setIndexToEdit(data.FO_ORDER);
                showModal(data, "edit");
              }}
              style={{
                fontSize: "18px",
                color: "rgb(8, 8, 46)",
              }}
            />
          </Tooltip>
          {modalVisible && (
            <StaticListModal
              key={`${data.ME_ID}-modal`}
              visible={modalVisible}
              data={modalData}
              index={indexToEdit}
              onSave={handleOk}
              onCancel={handleCancel}
              config={"edit"}
              fields={fields}
            />
          )}
        </TableCell>
        <TableCell style={{ width: "5%", backgroundColor: data.FO_BACKCOLOR }}>
          <Tooltip placement="right" title={commonTitles["CO|1|DLTOPT"]}>
            <DeleteOutlined
              onClick={() => {
                setIndexToDelete(data.FO_ORDER);
                showDeleteModal();
              }}
              style={{
                fontSize: "18px",
                color: "red",
              }}
            />
          </Tooltip>
          {deleteModalVisible && (
            <ConfirmationDeleteModal
              visible={deleteModalVisible}
              onDelete={handleDelete}
              onCancel={handleCancel}
              selectedItems={[indexToDelete]}
            />
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <TableBodySortable onSortEnd={onSort} useDragHandle>
      {data.map((item, index) => (
        <Row key={index} index={index} data={item} />
      ))}
    </TableBodySortable>
  );
};

export default StaticListBody;
