import moment from "moment";
import { convertMonthToLabel } from "../../../ultimateTable/helpers/converters";

const noGroupDateMonthly = (
  columns,
  data,
  xAxisField,
  dateFormatToUse,
  commonTitles,
  language,
  xValuesUsed,
  yValuesUsed,
  noDateDataColumnStatus,
  dependentColumns
) => {
  let childxAxisField;
  let xAxisFieldToUse = xAxisField;

  if (xAxisField?.includes("-")) {
    let tempXAxisField = xAxisField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  const xAxisFieldColumn = columns.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )[0];

  let xAxisFieldName;
  let childxAxisFieldIndex;
  let childxAxisNumber;

  if (childxAxisField) {
    const childTitle = xAxisFieldColumn.DEP_FIELDS?.filter(
      (field) => field.F_ID === childxAxisField
    ).map((item) => item.TITLE);
    xAxisFieldName = `${xAxisFieldColumn.T_TITLE} - ${childTitle}`;
    childxAxisNumber = xAxisFieldColumn.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === xAxisFieldToUse
    )[0].DEP_FIELDS;

    childxAxisFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childxAxisField;
    });
  } else {
    xAxisFieldName = xAxisFieldColumn.T_TITLE;
  }

  const isAlert =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_RENDER === "ALERT"
      ? true
      : false;

  const isDependent =
    columns.filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
      ?.F_TYPE === "30"
      ? true
      : false;

  let months = data.map((item) => {
    if (dependentColumns.includes(xAxisFieldToUse)) {
      if (item[xAxisFieldToUse] && item[xAxisFieldToUse].includes(",")) {
        const tempValue = item[xAxisFieldToUse].split(", ");
        const finalValue = tempValue[childxAxisFieldIndex];
        let month = moment(finalValue, dateFormatToUse).toDate().getMonth();
        let year = moment(finalValue, dateFormatToUse).toDate().getFullYear();
        return `${month} ${year}`;
      } else {
        return item[xAxisFieldToUse];
      }
    } else {
      if (isAlert) {
        if (item[xAxisFieldToUse]?.value) {
          let month = moment(item[xAxisFieldToUse]?.value, dateFormatToUse)
            .toDate()
            .getMonth();
          let year = moment(item[xAxisFieldToUse]?.value, dateFormatToUse)
            .toDate()
            .getFullYear();
          return `${month} ${year}`;
        } else return null;
      } else {
        if (item[xAxisFieldToUse]) {
          let month = moment(item[xAxisFieldToUse], dateFormatToUse)
            .toDate()
            .getMonth();
          let year = moment(item[xAxisFieldToUse], dateFormatToUse)
            .toDate()
            .getFullYear();
          return `${month} ${year}`;
        } else return null;
      }
    }
  });

  let uniqueMonths = [];
  months.forEach((item) => {
    if (item && !uniqueMonths.includes(item)) {
      uniqueMonths.push(item);
    }
  });

  let results = [];

  uniqueMonths.forEach((item) => {
    let month = item.substring(0, item.indexOf(" "));
    let year = item.substring(item.indexOf(" ") + 1);
    let label =
      convertMonthToLabel(parseInt(month), commonTitles, "short", language) +
      " " +
      year;
    results.push({ month: month, year: year, label: label });
  });

  //console.log("results: ", results);

  uniqueMonths = results;

  //console.log("uniqueMonths: ", uniqueMonths);

  uniqueMonths.sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    } else {
      return a.month - b.month;
    }
  });

  uniqueMonths.push(commonTitles["CO|1|NODATEDATA"]);

  //console.log("uniqueMonths: ", uniqueMonths);

  let monthlyCount = {};
  let noDateAvailable = 0;

  for (let i = 0; i < uniqueMonths.length; i++) {
    let noGroupFieldCounter = 0;
    for (let z = 0; z < data.length; z++) {
      let dateValue = isAlert
        ? data[z][xAxisFieldToUse]?.value
        : data[z][xAxisFieldToUse];

      console.log("dateValue: ", dateValue);

      if (!dateValue && uniqueMonths[i] === commonTitles["CO|1|NODATEDATA"]) {
        noDateAvailable += 1;
      } else {
        let month = moment(dateValue, dateFormatToUse).toDate().getMonth();
        let year = moment(dateValue, dateFormatToUse).toDate().getFullYear();
        let label =
          convertMonthToLabel(month, commonTitles, "short", language) +
          " " +
          year;

        if (
          label === uniqueMonths[i].label ||
          (isDependent && label.includes(uniqueMonths[i].label))
        ) {
          noGroupFieldCounter += 1;
        }
      }
    }

    if (uniqueMonths[i] !== commonTitles["CO|1|NODATEDATA"]) {
      monthlyCount = {
        ...monthlyCount,
        [uniqueMonths[i].label]: {
          ...monthlyCount[uniqueMonths[i].label],
          count: noGroupFieldCounter,
        },
      };
    }
  }

  if (noDateAvailable && !noDateDataColumnStatus) {
    monthlyCount = {
      ...monthlyCount,
      [commonTitles["CO|1|NODATEDATA"]]: { count: noDateAvailable },
    };
  }

  let totalSum = 0;
  Object.entries(monthlyCount).forEach(([key, value]) => {
    let sum = 0;

    Object.values(value).forEach((item) => {
      sum += item;
      totalSum += item;
    });
    if (sum || !xValuesUsed) {
      monthlyCount[key] = { ...monthlyCount[key], sum: sum };
    } else {
      if (xValuesUsed) {
        delete monthlyCount[key];
      }
    }
  });

  return {
    dailyCount: {},
    monthlyCount: { ...monthlyCount, totalSum: totalSum },
    yearlyCount: {},
  };
};

export default noGroupDateMonthly;
