const filterFieldsDataOnSearch = (allData, change, columnData) => {
  let tempData = [];
  const replacements = [
    { key: "ί", newKey: "ι" },
    { key: "έ", newKey: "ε" },
    { key: "ό", newKey: "ο" },
    { key: "ή", newKey: "η" },
    { key: "ύ", newKey: "υ" },
    { key: "ώ", newKey: "ω" },
    { key: "ά", newKey: "α" },
  ];

  const attachmentColumns = columnData
    .filter((column) => column.F_TYPE === "24" || column.F_TYPE === "25")
    .map((column) => column.F_ID);
  const staticListColumns = columnData
    .filter((column) => column.F_TYPE === "6")
    .map((column) => column.F_ID);

  if (Object.keys(allData).length) {
    Object.entries(allData).forEach(([key, value]) => {
      let isIncluded = false;
      Object.entries(value)
        .filter(
          ([key]) => key !== "LM" && !key.includes("-server") && key !== "ROWID"
        )
        .forEach(([key, value]) => {
          if (change) {
            change = change.toLowerCase();
            replacements.forEach(({ key, newKey }) => {
              var re = new RegExp(key, "g");
              change = change.replace(re, newKey);
            });
            if (attachmentColumns.includes(key)) {
              value.forEach((item) => {
                replacements.forEach(({ key, newKey }) => {
                  var re = new RegExp(key, "g");
                  item.name = item.name.replace(re, newKey);
                });
                if (item.name.toLowerCase().includes(change)) {
                  isIncluded = true;
                }
              });
            } else if (staticListColumns.includes(key)) {
              if (value.view) {
                replacements.forEach(({ key, newKey }) => {
                  var re = new RegExp(key, "g");
                  value.view = value.view.replace(re, newKey);
                });
                if (value.view.toLowerCase().includes(change)) {
                  isIncluded = true;
                }
              }
            } else {
              if (value && typeof value !== "object") {
                replacements.forEach(({ key, newKey }) => {
                  var re = new RegExp(key, "g");
                  value = value.replace(re, newKey);
                });

                if (value.toLowerCase().includes(change)) {
                  isIncluded = true;
                }
              }
            }
          }
        });
      isIncluded && tempData.push(value);
    });
  }

  //console.log("filterSearchData: tempData: ", tempData);

  // let filterSearchData = {};
  // for (let i = 0; i < tempData.length; i++) {
  //   filterSearchData = {
  //     ...filterSearchData,
  //     [tempData[i]?.ROWID]: { ...tempData[i] },
  //   };
  // }

  //console.log("filterSearchData: ", filterSearchData);

  return tempData;
};

export default filterFieldsDataOnSearch;
