import Flex from "../../../../../helpers/components/Flex";

const DependentType = ({ value, serverValue, foreignKeyValues, id }) => {
  if (serverValue) {
    const tempServerValue = serverValue.split(",").filter((item) => item);
    const newValue = tempServerValue?.map((item) => (
      <Flex key={item} direction={"horizontal"}>
        {tempServerValue?.length > 1 && <p style={{ marginRight: 5 }}>{"•"}</p>}
        <p>
          {foreignKeyValues?.[id]?.[item]?.view}
          <br />
        </p>
      </Flex>
    ));
    return newValue;
  }
  return <p className="p-context">{value}</p>;
};

export default DependentType;
