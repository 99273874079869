import "./css/emailConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

const EmailConfigHead = ({ headers = [] }) => {
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell key={"ghost-cell"} />
        {headers
          .filter((header) => !["N_PERIOD_TYPE"].includes(header.id))
          .map((item, index) => (
            <TableCell
              key={item.id}
              align={item?.align ?? "left"}
              className="admin-header"
              style={{ minWidth: 170 }}
            >
              <p key={`${item.id} - text`}>{item.title}</p>
            </TableCell>
          ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default EmailConfigHead;
