const choosePeriodicityDisplay = (
  number,
  type,
  titles,
  commonTitles,
  notificationType
) => {
  if (notificationType === "E") {
    return "-";
  }

  if (type === "M") {
    if (number === "1") {
      return number + " " + commonTitles["CO|1|MONTH"];
    }
    return number + " " + titles["FO|124|TITLE"];
  }

  if (type === "W") {
    if (number === "1") {
      return number + " " + commonTitles["CO|1|WEEK"];
    }
    return number + " " + titles["FO|125|TITLE"];
  }

  if (type === "D") {
    if (number === "1") {
      return number + " " + commonTitles["CO|1|DAY"];
    }
    return number + " " + titles["FO|126|TITLE"];
  }
};

export default choosePeriodicityDisplay;
