const initializeFilters = (filter = {}) => {
  const filters = filter;

  Object.entries(filters).forEach(([key, value]) => {
    if (typeof value === "object") {
      Object.entries(value).forEach(([key2, value2]) => {
        if (typeof value2 === "object") {
          Object.entries(value2).forEach(([key3, value3]) => {
            if (value3 === "" || value3.length === 0 || value3 === null) {
              delete filters[key][key2][key3];
            }
          });
          if (Object.keys(value2).length === 0) {
            delete filters[key][key2];
          }
        } else {
          if (value2 === "" || value2.length === 0 || value2 === null) {
            delete filters[key][key2];
          }
        }
      });
      if (Object.keys(value).length === 0) {
        delete filters[key];
      }
    } else {
      if (value === "" || value.length === 0 || value === null) {
        delete filters[key];
      }
    }
  });

  return filters;
};

export default initializeFilters;
