import React from "react";
import "./menu.css";

const Menu = ({ menuItem1, menuItem2, menuItem3 }) => {
  return (
    <div className="menu">
      <a href="#Home">{menuItem1}</a>
      <a href="#wgpt3">{menuItem2}</a>
      <a href="#blog">{menuItem3}</a>
    </div>
  );
};

export default Menu;
