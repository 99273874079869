import Flex from "../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../store/useStores";

const ModalFooterInfo = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Flex
      flexDirection={"row"}
      style={{
        alignContent: "flex-start",
        marginBottom: "-2rem",
      }}
      key={"footer-info"}
    >
      <p
        style={{
          color: "rgb(228, 21, 21)",
          fontSize: 18,
          marginRight: "0.3rem",
        }}
      >
        *
      </p>
      <p> - {commonTitles["CO|1|PNDGSAVE"]} </p>
    </Flex>
  );
};

export default ModalFooterInfo;
