import "../../css/newRegisterModal.css";
import { Modal, Button } from "antd";
import { useCommonTitlesStore } from "../../../../../../store/useStores";
import { WarningOutlined } from "@ant-design/icons";
import NewRegisterModalContent from "./NewRegisterModalContent";

const NewRegisterModal = ({
  visible,
  title,
  columns,
  staticOptions,
  foreignKeyValues,
  onSave,
  onCancel,
  changes,
  addChange,
  isProjectSpecific,
  privileges,
  autoCompletedFields,
  moduleID,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  let columnsChanged = Object.keys(changes);

  return (
    <Modal
      title={`${title} - ${commonTitles["CO|1|NEWREG"]}`}
      open={visible}
      onOk={onSave}
      onCancel={() => {
        onCancel();
      }}
      maskClosable={false}
      destroyOnClose={true}
      width="90vw"
      style={{ marginTop: -65, minWidth: "60vw", width: "90vw" }}
      footer={[
        <div className="align-left" key={"modal-info"}>
          <p className="asterisk-footer">*</p>
          <p> - {commonTitles["CO|1|PNDGSAVE"]} </p>
          <p
            style={{
              marginTop: -3.4,
              color: "grey",
              fontSize: 18,
              marginRight: "0.3rem",
              marginLeft: "0.8rem",
            }}
          >
            <WarningOutlined />
          </p>
          <p> - {commonTitles["CO|1|RQRDFIELD"]} </p>
        </div>,
        <Button
          key="back"
          onClick={() => {
            onCancel();
          }}
        >
          {commonTitles.cancel}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{
            backgroundColor: "rgb(8, 8, 46)",
            color: "white",
          }}
          onClick={() => onSave(changes)}
        >
          {commonTitles.save}
        </Button>,
      ]}
    >
      <NewRegisterModalContent
        columns={columns}
        staticOptions={staticOptions}
        foreignKeyValues={foreignKeyValues}
        columnsChanged={columnsChanged}
        addChange={addChange}
        isProjectSpecific={isProjectSpecific}
        privileges={privileges}
        moduleID={moduleID}
        autoCompletedFields={autoCompletedFields}
        changes={changes}
      />
    </Modal>
  );
};

export default NewRegisterModal;
