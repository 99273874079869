import React from "react";
import BannerItem from "./bannerItem/BannerItem";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  useCommonTitlesStore,
  useModTitlesStore,
} from "../../../store/useStores";
import BannerWrapper from "../../../helpers/components/BannerWrapper";
import BannerSearch from "../../../helpers/components/BannerSearch";

const Banner = ({ updateSearchValue, downloadPdfReport }) => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <BannerWrapper title={modTitles.alerts}>
      <BannerItem
        icon={<PictureAsPdfIcon />}
        tooltip={`${commonTitles["CO|1|EXPORTTO"]} PDF`}
        downloadPdfReport={downloadPdfReport}
        suffix={"pdf"}
      />
      <BannerSearch updateSearchValue={updateSearchValue} />
    </BannerWrapper>
  );
};

export default Banner;
