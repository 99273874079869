import { WarningOutlined } from "@ant-design/icons";
import Flex from "../../../../../helpers/components/Flex";
import { Input } from "antd";
import { useAuthStore } from "../../../../../store/useStores";
import htmlCodesRemover from "../../htmlCodesRemover";

const { TextArea } = Input;

const TextareaType = ({
  value,
  id,
  config,
  required,
  addChange,
  canupdate,
  canupdateifnull,
  row,
  autoCompletedFields = {},
  size,
}) => {
  const userID = useAuthStore((state) => state.userID);

  let newValue = htmlCodesRemover(value, 100, "input") ?? "";

  // if (value && value.includes("&#39;")) {
  //   let splitValue = value
  //     .split("&#39;")
  //     .filter((item) => item !== "")
  //     .join("'");
  //   newValue = splitValue;
  //   if (splitValue && splitValue.includes("&#10;")) {
  //     let newSplitValue = splitValue
  //       .split("&#10;")
  //       .filter((item) => item !== "");
  //     let str = "";
  //     newSplitValue.forEach((item) => {
  //       if (str !== "") {
  //         str += "\n";
  //       }
  //       str += item;
  //     });
  //     newValue = str;
  //   }
  // } else if (value && value.includes("&#10;")) {
  //   let newSplitValue = value.split("&#10;").filter((item) => item !== "");
  //   let str = "";
  //   newSplitValue.forEach((item) => {
  //     if (str !== "") {
  //       str += "\n";
  //     }
  //     str += item;
  //   });
  //   newValue = str;
  // }

  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    newValue = autoCompletedFields[id];
  }

  let isDisabled = false;
  if (config !== "filters") {
    if (Object.keys(autoCompletedFields).includes(id)) {
      isDisabled = true;
    } else {
      if (canupdate === false) {
        if (canupdateifnull === true) {
          if (newValue.length) {
            if (row[`${id}-US`] !== userID) {
              isDisabled = true;
            }
          }
        } else {
          isDisabled = true;
        }
      }
    }
  }

  return (
    <Flex flexDirection={"row"}>
      <TextArea
        type="text"
        style={{ minWidth: 275, minHeight: 100 }}
        defaultValue={newValue ?? ""}
        onChange={(e) => addChange(id, e.target.value, null)}
        disabled={isDisabled}
        maxLength={size ? size : null}
      />
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 18 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default TextareaType;
