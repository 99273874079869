import "../../../css/ultimateTable.css";
import { Modal, Button } from "antd";
import { useCommonTitlesStore } from "../../../../../store/useStores";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import FiltersModalContent from "./FiltersModalContent";

const FiltersModal = ({
  visible,
  columns,
  staticOptions,
  foreignKeyValues,
  onSave,
  onCancel,
  onErase,
  filters,
  privileges,
  moduleID,
}) => {
  const [filterChanges, setFilterChanges] = useState({});

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  let columnsFiltered = Object.keys(filterChanges);
  const dependentColumns = columns
    .filter((column) => column.F_TYPE === "30")
    .map((item) => item.F_ID);

  const addFilterChange = (id, value, suffix) => {
    if (dependentColumns.includes(id)) {
      const key = Object.keys(value)[0];
      const displayValue = Object.values(value)[0];
      if (suffix) {
        if (
          displayValue === "" ||
          displayValue.length === 0 ||
          displayValue === null
        ) {
          if (filterChanges[id]) {
            if (filterChanges[id][key]) {
              if (suffix in filterChanges[id][key]) {
                delete filterChanges[id][key][suffix];
                if (Object.keys(filterChanges[id][key]).length === 0) {
                  delete filterChanges[id][key];
                  if (Object.keys(filterChanges[id]).length === 0) {
                    delete filterChanges[id];
                  }
                }
                setFilterChanges({ ...filterChanges });
              }
            }
          } else {
            setFilterChanges({
              ...filterChanges,
              [id]: { [key]: { [suffix]: displayValue } },
            });
          }
        } else {
          if (filterChanges[id]) {
            if (filterChanges[id][key]) {
              setFilterChanges({
                ...filterChanges,
                [id]: {
                  ...filterChanges[id],
                  [key]: { ...filterChanges[id][key], [suffix]: displayValue },
                },
              });
            } else {
              setFilterChanges({
                ...filterChanges,
                [id]: {
                  ...filterChanges[id],
                  [key]: { [suffix]: displayValue },
                },
              });
            }
          } else {
            setFilterChanges({
              ...filterChanges,
              [id]: { [key]: { [suffix]: displayValue } },
            });
          }
        }
      } else {
        if (
          displayValue === "" ||
          displayValue.length === 0 ||
          displayValue === null
        ) {
          if (filterChanges[id]) {
            if (filterChanges[id][key]) {
              delete filterChanges[id][key];
              if (Object.keys(filterChanges[id]).length === 0) {
                delete filterChanges[id];
              }
              setFilterChanges({ ...filterChanges });
            } else {
              setFilterChanges({
                ...filterChanges,
                [id]: { ...filterChanges[id], [key]: displayValue },
              });
            }
          } else {
            setFilterChanges({
              ...filterChanges,
              [id]: { [key]: displayValue },
            });
          }
        } else {
          if (filterChanges[id]) {
            setFilterChanges({
              ...filterChanges,
              [id]: { ...filterChanges[id], [key]: displayValue },
            });
          } else {
            setFilterChanges({
              ...filterChanges,
              [id]: { [key]: displayValue },
            });
          }
        }
      }
    } else {
      if (suffix) {
        if (value === "" || value.length === 0 || value === null) {
          if (filterChanges[id]) {
            if (suffix in filterChanges[id]) {
              delete filterChanges[id][suffix];
              if (Object.keys(filterChanges[id]) === 0) {
                delete filterChanges[id];
              }
              setFilterChanges({ ...filterChanges });
            }
          } else {
            setFilterChanges({ ...filterChanges, [id]: { [suffix]: value } });
          }
        } else {
          if (filterChanges[id]) {
            setFilterChanges({
              ...filterChanges,
              [id]: { ...filterChanges[id], [suffix]: value },
            });
          } else {
            setFilterChanges({ ...filterChanges, [id]: { [suffix]: value } });
          }
        }
      } else {
        if (value === "" || value.length === 0 || value === null) {
          if (id in filterChanges) {
            if (id in filters) {
              setFilterChanges({ ...filterChanges, [id]: value });
            } else {
              delete filterChanges[id];
              setFilterChanges({ ...filterChanges });
            }
          } else {
            setFilterChanges({
              ...filterChanges,
              [id]: value,
            });
          }
        } else {
          setFilterChanges({ ...filterChanges, [id]: value });
        }
      }
    }
  };

  return (
    <Modal
      title={commonTitles["CO|1|FILTERS"]}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      maskClosable={false}
      width="max-content"
      style={{ marginTop: -60.5 }}
      footer={[
        <div className="align-left" key={"footer-info"}>
          <CheckOutlined
            style={{
              color: "green",
              marginRight: 5,
              marginTop: 2,
            }}
          />
          <p> - {commonTitles["CO|1|APPLFILT"]} </p>
          <p className="asterisk-footer">*</p>
          <p> - {commonTitles["CO|1|PNDGSAVE"]} </p>
        </div>,
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button key="erase" type="primary" onClick={onErase}>
          {commonTitles["CO|1|CLEARALL"]}
        </Button>,
        <Button
          key="submit"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          className="confirm-btn"
          onClick={() => onSave(filterChanges, "update")}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <FiltersModalContent
        columns={columns}
        staticOptions={staticOptions}
        foreignKeyValues={foreignKeyValues}
        filters={filters}
        columnsFiltered={columnsFiltered}
        addFilterChange={addFilterChange}
        filterChanges={filterChanges}
        privileges={privileges}
        moduleID={moduleID}
      />
    </Modal>
  );
};

export default FiltersModal;
