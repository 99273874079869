const validateValue = (value) => {
  const validChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let isValid = true;

  const chars = value.split("");
  chars.forEach((char) => {
    if (!validChars.includes(char)) {
      isValid = false;
    }
  });

  return isValid;
};

export default validateValue;
