import { useCommonTitlesStore } from "../../../../store/useStores";
import { Card } from "antd";

const NoAccessDisplay = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Card
      style={{
        borderRadius: "10px",
        marginBottom: "10px",
        width: "98.5vw",
        display: "flex",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <p>{commonTitles["CO|1|NOVIEWMOD"]}</p>
    </Card>
  );
};

export default NoAccessDisplay;
