import { Modal, Button } from "antd";
import { useCommonTitlesStore } from "../../../../../store/useStores";
import { useState } from "react";
import DeleteModalContent from "./DeleteModalContent";
import ConfirmationDeleteModal from "./confirmationDeleteModal/ConfirmationDeleteModal";

const DeleteModal = ({
  visible,
  title,
  selectedItems,
  onDelete,
  onCancel,
  moduleID,
}) => {
  const [confirmationDeleteModalVisible, setConfirmationDeleteModalVisible] =
    useState(false);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const onCancelConfirmation = () => {
    setConfirmationDeleteModalVisible(false);
    onCancel();
  };
  return (
    <Modal
      title={`${title} - ${commonTitles["CO|1|DELETERCRD"]}`}
      open={visible}
      onCancel={onCancel}
      width={500}
      style={{ marginTop: -60.5 }}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => setConfirmationDeleteModalVisible(true)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,
      ]}
    >
      <DeleteModalContent selectedItems={selectedItems} />
      {confirmationDeleteModalVisible && (
        <ConfirmationDeleteModal
          visible={confirmationDeleteModalVisible}
          onDelete={onDelete}
          moduleID={moduleID}
          onCancel={onCancelConfirmation}
          closable
        />
      )}
    </Modal>
  );
};

export default DeleteModal;
