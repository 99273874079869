import { message } from "antd";
import axios from "axios";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";

const apiCopyFile = async (baseURL, id, commonTitles) => {
  const url = `${baseURL}/Data_api/copyfile`;
  try {
    const response = await axios.post(
      url,
      { filename: id },
      {
        withCredentials: true,
      }
    );
    console.log("apiCopyFile: response: ", response.data);
    if (response?.data?.STATUS === "Success") {
      //message.success(commonTitles["CO|1|COPYFILE"], [3]);
      displayResponseMessage(commonTitles["CO|1|COPYFILE"], 3, "success");
    } else {
      //message.warning(commonTitles["CO|1|NOFILE"], [3]);
      displayResponseMessage(commonTitles["CO|1|NOFILE"], 3, "error");
    }
    const status = response?.data?.STATUS;
    const newFileName = response?.data?.NEWFILENAME;
    return { status, newFileName };
  } catch (err) {
    //message.error(err, [3]);
    displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
  }
};

export default apiCopyFile;
