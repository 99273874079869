import { useState } from "react";
//import Button from "@mui/material/Button";
import "./css/newRegister.css";
import ReactTooltip from "react-tooltip";
import AddIcon from "@mui/icons-material/Add";
import {
  useCommonTitlesStore,
  useModuleStore,
} from "../../../../store/useStores";
// import { NewRegisterModal } from "./helpers/modalsOLD";
import NewRegisterModal from "./helpers/newRegisterModal/NewRegisterModal";
import {
  convertDataFromServer,
  tranformChangesforServer,
} from "../../helpers/converters";
import axios from "axios";
import { message } from "antd";
import { useAuthStore } from "../../../../store/useStores";
import apiUpload from "../../../../api/endpoints/apiUpload";
import apiDeleteFile from "../../../../api/endpoints/apiDeleteFile";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";
import Flex from "../../../../helpers/components/Flex";
import { Button } from "antd";
import { useEffect } from "react";
import apiGetModuleFields from "../../../../api/endpoints/apiGetModuleFields";
import apiGetValueColor from "../../../../api/endpoints/apiGetValueColor";

const NewRegister = ({
  title,
  columns,
  staticOptions,
  foreignKeyValues,
  insertData,
  moduleID,
  isProjectSpecific,
  existingRowIDs,
  privileges,
  config,
  autoCompletedFields = {},
  updateTotals,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const dateTypes = useModuleStore((state) => state.dateTypes);
  const [newRegisterModalVisible, setNewRegisterModalVisible] = useState(false);
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);
  const baseURL = useAuthStore((state) => state.baseURL);
  const userID = useAuthStore((state) => state.userID);
  const [changes, setChanges] = useState({});
  const [tab, setTab] = useState("Επιλογή από βάση δεδομένων");

  let attachColumns = [];
  let renderAlertColumns = [];

  columns.forEach((column) => {
    if (column.F_TYPE === "24" || column.F_TYPE === "25") {
      attachColumns.push(column.F_ID);
    }
    if (column.F_RENDER === "ALERT") {
      renderAlertColumns.push(column.F_ID);
    }
  });

  useEffect(async () => {
    if (Object.keys(autoCompletedFields).length) {
      const { rows } = await apiGetModuleFields(baseURL, moduleID);

      const fieldType = rows?.filter(
        (field) => field.F_ID === Object.keys(autoCompletedFields)[0]
      )?.[0]?.F_TYPE;

      if (dateTypes.includes(fieldType)) {
        setChanges({
          ...changes,
          [Object.keys(autoCompletedFields)[0]]:
            Object.values(autoCompletedFields)[0]?.from ?? "",
        });
      } else {
        setChanges({
          ...changes,
          [Object.keys(autoCompletedFields)[0]]:
            Object.values(autoCompletedFields)[0],
        });
      }
    }
  }, []);

  const addChange = (id, value, config) => {
    if (value && value !== "" && value?.length !== 0) {
      if (attachColumns.includes(id)) {
        if (changes[id]) {
          if (config === "add") {
            let fileExists = [];
            for (let i = 0; i < value.length; i++) {
              changes[id].forEach((file) => {
                if (file.uid === value[i].uid) {
                  fileExists[i] = true;
                }
              });
            }

            for (let i = 0; i < value.length; i++) {
              !fileExists[i] &&
                setChanges({
                  ...changes,
                  [id]: [...changes[id], value[i]],
                });
            }
          } else {
            let tempChanges = [];
            changes[id].forEach((file) => tempChanges.push(file));
            tempChanges = tempChanges.filter((file) => file.uid !== value.uid);
            if (tempChanges.length) {
              setChanges({ ...changes, [id]: [...tempChanges] });
            } else {
              delete changes[id];
              setChanges({ ...changes });
            }
          }
        } else {
          setChanges({
            ...changes,
            [id]: value,
          });
        }
      } else {
        setChanges({ ...changes, [id]: value });
      }
    } else {
      delete changes[id];
      setChanges({ ...changes });
    }
  };

  //console.log("NewRegister: changes: ", changes);

  const showNewRegisterModal = () => {
    setNewRegisterModalVisible(true);
  };

  const uploadFiles = async (filesToUpload) => {
    let uploadFileTracker = {};
    let uploadError = false;

    for (let i = 0; i < attachColumns?.length; i++) {
      if (!uploadError) {
        let id = attachColumns[i];
        uploadFileTracker = { ...uploadFileTracker, [id]: [] };
        for (let j = 0; j < filesToUpload[id]?.length; j++) {
          const fmData = new FormData();
          fmData.append("file", filesToUpload[id][j]);

          const { uploadStatus, uploadedFile } = await apiUpload(
            baseURL,
            fmData,
            commonTitles
          );

          if (uploadStatus !== "Success") {
            uploadError = true;
            break;
          }

          uploadFileTracker[id].push(uploadedFile);
        }
      } else break;
    }

    if (uploadError) {
      return { uploadFileTracker, uploadStatus: "Error" };
    }

    return { uploadFileTracker, uploadStatus: "Success" };
  };

  const deleteFiles = async (filesToDelete) => {
    let deleteError = false;
    let deleteFileTracker = {};
    for (let i = 0; i < attachColumns.length; i++) {
      if (!deleteError) {
        let id = attachColumns[i];
        deleteFileTracker = { ...deleteFileTracker, [id]: [] };
        for (let j = 0; j < filesToDelete[id].length; j++) {
          const deleteStatus = await apiDeleteFile(
            baseURL,
            filesToDelete[id][j].id,
            commonTitles
          );
          if (deleteStatus !== "Success") {
            break;
          }
          deleteFileTracker[id].push(filesToDelete[id][j]);
        }
      } else break;
    }

    if (deleteError) {
      return { deleteFileTracker, deleteStatus: "Error" };
    }

    return { deleteFileTracker, deleteStatus: "Success" };
  };

  //console.log("NewRegister: changes: ", changes);

  const [rowsToInsert, setRowsToInsert] = useState([]);

  const updateRowsToInsert = (rows) => {
    const existingIDs = rowsToInsert.map((row) => row.ROWID);

    rows.forEach((row) => {
      if (existingIDs.includes(row.ROWID)) {
        setRowsToInsert(
          rowsToInsert.filter((item) => item.ROWID !== row.ROWID)
        );
      } else {
        setRowsToInsert([...rowsToInsert, row]);
      }
    });
  };

  // console.log("rowsToInsert: ", rowsToInsert);

  const insertFromDatabase = async (selectedItems) => {
    const url = `${baseURL}/Data_api/save`;

    // console.log("selectedItems: ", selectedItems);
    onCancel();

    // try {
    //   const response = await axios.post(
    //     url,
    //     { moduleid: moduleID, selectedItems },
    //     {
    //       withCredentials: true,
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   console.log("insertData: response: ", response?.data);

    //   if (response?.data?.STATUS === "Success") {
    //     message.success(commonTitles["CO|1|REGMSG1"], [3]);
    //     insertData(rowsToInsert);
    //     setNewRegisterModalVisible(false);
    //   } else {
    //     const responseMsg = response?.data?.MESSAGE ?? {};
    //     let template = commonTitles[responseMsg.ID];

    //     Object.entries(responseMsg)
    //       .filter(([key]) => key !== "ID")
    //       .forEach(([key, value]) => {
    //         var re = new RegExp(key, "g");
    //         template = template.replace(re, value);
    //       });

    //     message.warning(template, [5]);
    //   }
    // } catch (err) {
    //   message.error(err, [3]);
    // }
  };

  const onSave = async (changes) => {
    const url = `${baseURL}/Data_api/save`;
    //const { fileTracker, uploadStatus } = await uploadFiles();

    let filesToUpload = {};
    attachColumns.forEach((id) => {
      filesToUpload = { ...filesToUpload, [id]: [] };
      if (changes[id]) {
        let tempFilesToUpload = changes[id].filter(
          (file) => !("action" in file)
        );
        filesToUpload[id].push(...tempFilesToUpload);
      }
    });

    const { uploadFileTracker, uploadStatus } = await uploadFiles(
      filesToUpload
    );

    if (uploadStatus === "Success") {
      const fileTracker = uploadFileTracker;

      let renderAlertColorValues = {};

      for (let i = 0; i < renderAlertColumns.length; i++) {
        const colorValue = await apiGetValueColor(
          baseURL,
          renderAlertColumns[i],
          changes?.[renderAlertColumns[i]] ?? ""
        );

        renderAlertColorValues = {
          ...renderAlertColorValues,
          [renderAlertColumns[i]]: {
            value: changes?.[renderAlertColumns[i]] ?? "",
            color: colorValue?.COL ?? ["#ffffff", "B"],
          },
        };
      }

      const transformedChanges = tranformChangesforServer(
        changes,
        columns,
        [],
        "",
        foreignKeyValues,
        fileTracker,
        baseURL
      );

      //console.log("onSave: transformedChanges: ", transformedChanges);

      const tempData = await convertDataFromServer(
        columns,
        "",
        [],
        staticOptions,
        foreignKeyValues,
        transformedChanges,
        "insert",
        userID,
        renderAlertColorValues
      );

      // console.log("insertData: tempdata: ", tempData);
      // console.log("changes: ", changes);

      try {
        const response = await axios.post(
          url,
          { moduleid: moduleID, ...transformedChanges },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("insertData: response: ", response?.data);

        if (response?.data?.STATUS === "Success") {
          displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");

          let calcFieldValues = response?.data?.math ?? {};

          const changes = {
            [response?.data?.ROWID]: {
              ["LM"]: response?.data?.TIMESTAMP,
              ["ROWID"]: response?.data?.ROWID,
              [response?.data?.AUTOID?.FIELDID]: response?.data?.AUTOID?.VALUE,
              ...tempData,
              ...calcFieldValues,
            },
          };
          insertData(changes);
          updateTotals(response?.data?.totals);
          setChanges({});
          setNewRegisterModalVisible(false);
        } else {
          const responseMsg = response?.data?.MESSAGE ?? {};
          let template = commonTitles[responseMsg.ID];

          Object.entries(responseMsg)
            .filter(([key]) => key !== "ID")
            .forEach(([key, value]) => {
              var re = new RegExp(key, "g");
              template = template.replace(re, value);
            });

          displayResponseMessage(template, [5], "warning");
        }
      } catch (err) {
        //message.error(err, [3]);
        displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
      }
    } else {
      deleteFiles(uploadFileTracker);
    }
  };

  const onCancel = () => {
    setTab("Εισαγωγή από βάση δεδομένων");
    setChanges({});
    setNewRegisterModalVisible(false);
  };

  const [showExistingRowIDs, setShowExistingRowIDs] = useState(true);

  const updateExistingRowIDsVisibility = () => {
    setShowExistingRowIDs(!showExistingRowIDs);
  };

  return (
    <div className="newRegister">
      {config !== "info" ? (
        <a data-tip={`${commonTitles.newReg}`}>
          <Button
            style={{
              color: bannerTextColor,
              background: "transparent",
              border: "none",
            }}
            onClick={showNewRegisterModal}
          >
            <AddIcon />
          </Button>
        </a>
      ) : (
        <Button shape="round" onClick={showNewRegisterModal}>
          <Flex flexDirection={"row"} style={{ gap: 5 }}>
            <AddIcon color="rgb(8, 8, 46)" />
            <p>{commonTitles.newReg}</p>
          </Flex>
        </Button>
      )}

      <ReactTooltip place="bottom" />
      <NewRegisterModal
        visible={newRegisterModalVisible}
        title={title}
        columns={columns?.filter((field) => field.F_LIST === "Y")}
        staticOptions={staticOptions}
        foreignKeyValues={foreignKeyValues}
        onSave={onSave}
        onCancel={onCancel}
        changes={changes}
        addChange={addChange}
        isProjectSpecific={isProjectSpecific}
        existingRowIDs={existingRowIDs}
        moduleID={moduleID}
        updateRowsToInsert={updateRowsToInsert}
        insertFromDatabase={insertFromDatabase}
        tab={tab}
        setTab={setTab}
        updateExistingRowIDsVisibility={updateExistingRowIDsVisibility}
        showExistingRowIDs={showExistingRowIDs}
        privileges={privileges}
        autoCompletedFields={autoCompletedFields}
      />
    </div>
  );
};

export default NewRegister;
