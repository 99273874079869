import "../../components/ultimateTable/css/ultimateTable.css";
import { CalendarOutlined } from "@ant-design/icons";
import { DatePicker, Input } from "antd";
import Flex from "./Flex";
import { useState } from "react";
import dayjs from "dayjs";
import { useAuthStore, useCommonTitlesStore } from "../../store/useStores";
import { convertDateFormat } from "../../components/ultimateTable/helpers/converters";

const CustomDatePicker = ({
  id,
  value,
  addChange,
  config,
  suffix,
  isDisabled,
  showTime,
  dependentField,
}) => {
  const [datePickerValue, setDatePickerValue] = useState(value);
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(0);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const dateFormat = useAuthStore((state) => state.dateFormat);
  const dateFormatToUse = convertDateFormat(dateFormat);

  if (config === "filters") {
    return (
      <Flex key={key}>
        <Input
          type="text"
          key={key}
          style={{
            border: "1.25px solid",
            borderColor: "rgb(182, 180, 180)",
            borderRadius: 5,
            fontFamily: "Arial, Helvetica, sans-serif",
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            fontSize: 14,
            letterSpacing: "0.03em",
            height: 30,
            minWidth: 220,
          }}
          defaultValue={
            dependentField
              ? datePickerValue?.[dependentField]?.[suffix]
              : datePickerValue?.[suffix] ?? ""
          }
          onChange={(e) => {
            if (dependentField) {
              addChange(id, { [dependentField]: e.target.value }, suffix);
            } else {
              addChange(id, e.target.value, suffix);
            }
          }}
          disabled={isDisabled}
          placeholder={dateFormatToUse}
        />
        <DatePicker
          key={suffix}
          id={suffix}
          showTime={showTime}
          format={dateFormatToUse}
          placeholder={[commonTitles["CO|1|SLCTDATE"]]}
          open={open}
          onOpenChange={(open) => setOpen(open)}
          onChange={(dateString) => {
            if (dependentField) {
              const tempValue = {
                [dependentField]: {
                  [suffix]: dateString
                    ? dateString.format(dateFormatToUse)
                    : "",
                },
              };
              setDatePickerValue(tempValue);
              addChange(
                id,
                { [dependentField]: dateString.format(dateFormatToUse) ?? "" },
                suffix
              );
              setKey(key + 1);
            } else {
              const tempValue = {
                [suffix]: dateString ? dateString.format(dateFormatToUse) : "",
              };
              setDatePickerValue(tempValue);
              addChange(id, dateString.format(dateFormatToUse) ?? "", suffix);
              setKey(key + 1);
            }
          }}
          bordered={true}
          style={{
            marginLeft: 8,
            height: 35,
            marginTop: 5,
            marginRight: 15,
            visibility: "hidden",
            width: 0,
          }}
          defaultValue={
            datePickerValue?.[suffix]
              ? dayjs(datePickerValue?.[suffix], dateFormatToUse)
              : ""
          }
        />
        <CalendarOutlined
          style={{ marginTop: 12, marginLeft: -50 }}
          onClick={() => setOpen(!open)}
        />
      </Flex>
    );
  }

  return (
    <Flex key={datePickerValue} style={{ alignItems: "center" }}>
      <Input
        type="text"
        style={{
          border: "1.25px solid",
          borderColor: "rgb(182, 180, 180)",
          borderRadius: 5,
          fontFamily: "Arial, Helvetica, sans-serif",
          paddingRight: "0.5rem",
          paddingLeft: "0.5rem",
          fontSize: 14,
          letterSpacing: "0.03em",
          height: 30,
          minWidth: 250,
        }}
        defaultValue={datePickerValue ?? ""}
        onChange={(e) => addChange(id, e.target.value, null)}
        disabled={isDisabled}
        placeholder={dateFormatToUse}
      />
      <DatePicker
        format={dateFormatToUse}
        className="custom-picker"
        showTime={showTime}
        open={open}
        onOpenChange={(open) => setOpen(open)}
        placeholder={[commonTitles["CO|1|SLCTDATE"]]}
        onChange={(dateString) => {
          if (dateString) {
            addChange(id, dateString.format(dateFormatToUse), null);
            setDatePickerValue(dateString.format(dateFormatToUse));
          } else {
            addChange(id, "", null);
            setDatePickerValue("");
          }
        }}
        bordered={true}
        defaultValue={
          datePickerValue ? dayjs(datePickerValue, dateFormatToUse) : ""
        }
        style={{
          marginLeft: 8,
          height: 35,
          marginTop: 5,
          marginRight: 15,
          visibility: "hidden",
          width: 0,
        }}
        disabled={isDisabled}
      />
      <CalendarOutlined
        style={{ marginLeft: -50 }}
        onClick={() => setOpen(!open)}
      />
    </Flex>
  );
};

export default CustomDatePicker;
