import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./metrics.css";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";

const Metrics = ({ path }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);

  return (
    <Link
      to={`${path}/metrics`}
      className="metrics"
      data-tip={`${commonTitles.metrics}`}
      style={{ color: bannerTextColor }}
    >
      {<EqualizerIcon />}
      <ReactTooltip place="bottom" />
    </Link>
  );
};
export default Metrics;
