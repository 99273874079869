import "../../../css/ultimateTable.css";
import { Modal, Button } from "antd";
import { useCommonTitlesStore } from "../../../../../store/useStores";
import { WarningOutlined } from "@ant-design/icons";
import EditModalContent from "./EditModalContent";

const EditModal = ({
  visible,
  title,
  row,
  columns,
  staticOptions,
  foreignKeyValues,
  onSave,
  onCancel,
  onDelete,
  changes,
  addChange,
  privileges,
  autoCompletedFields,
  moduleID,
}) => {
  const columnsChanged = Object.keys(changes);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Modal
      title={`${title} - ${commonTitles["CO|1|EDIT"]}`}
      open={visible}
      onOk={onSave}
      onDelete={onDelete}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose={true}
      width="max-content"
      style={{ marginTop: -20.5 }}
      footer={[
        <div className="align-left" key={"modal-info"}>
          <p className="asterisk-footer">*</p>
          <p> - {commonTitles["CO|1|PNDGSAVE"]} </p>
          <p
            style={{
              marginTop: -3.4,
              color: "grey",
              fontSize: 18,
              marginRight: "0.3rem",
              marginLeft: "0.8rem",
            }}
          >
            <WarningOutlined />
          </p>
          <p> - {commonTitles["CO|1|RQRDFIELD"]} </p>
        </div>,
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(row.rowID, changes)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <EditModalContent
        row={row}
        columns={columns?.filter((field) => field.F_LIST === "Y")}
        staticOptions={staticOptions}
        foreignKeyValues={foreignKeyValues}
        columnsChanged={columnsChanged}
        addChange={addChange}
        changes={changes}
        privileges={privileges}
        autoCompletedFields={autoCompletedFields}
        moduleID={moduleID}
      />
    </Modal>
  );
};

export default EditModal;
