import axios from "axios";

const apiGetCommonTitles = async (language, setCommonTitles, baseURL) => {
  //const url = `${baseURL}/App_titles_api/get_titles/0/CO/${language}/0`;
  const url = `${baseURL}/App_titles_api/get_titles`;

  let response;

  try {
    response = await axios.post(
      url,
      {
        workspaceid: "0",
        reftype: "CO",
        lang: language,
        projectid: "0",
      },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  } catch (err) {
    response = null;
  }

  if (response === null) {
    return;
  }

  const responseData = response?.data ?? {};

  const username = responseData["CO|1|USERNAME"];
  const password = responseData["CO|1|PASSWORD"];
  const login = responseData["CO|1|LOGIN"];
  const regNewUser = responseData["CO|1|REGUSERLOG"];
  const register = responseData["CO|1|REGISTER"];
  const confirmPswd = responseData["CO|1|CONFPASSWD"];
  const metrics = responseData["CO|1|METRICS"];
  const data = responseData["CO|1|DATA"];
  const report = responseData["CO|1|REPORT"];
  const exportTitle = responseData["CO|2|EXPORT"];
  const help = responseData["CO|1|HELP"];
  const addNew = responseData["CO|1|ADDNEWVAL"];
  const projects = responseData["CO|2|PROJECTS"];
  const userPswInv = responseData["CO|1|USERPASINV"];
  const datefield = responseData["CO|1|DATEFIELD"];
  const groupfield = responseData["CO|1|GROUPFIELD"];
  const grouping = responseData["CO|1|GROUPING"];
  const displayFunction = responseData["CO|1|FUNCTION"];
  const chartType = responseData["CO|1|CHARTTYPE"];
  const chartBar = responseData["CO|1|CHARTBAR"];
  const chartLine = responseData["CO|1|CHARTLINE"];
  const chartPie = responseData["CO|1|CHARTPIE"];
  const daily = responseData["CO|1|DAILY"];
  const monthly = responseData["CO|1|MONTHLY"];
  const yearly = responseData["CO|1|YEARLY"];
  const count = responseData["CO|1|COUNT"];
  const pctCount = responseData["CO|1|PCTCOUNT"];
  const sum = responseData["CO|1|SUM"];
  const pctSum = responseData["CO|1|PCTSUM"];
  const home = responseData["CO|1|HOME"];
  const whatIs = responseData["CO|1|WHATIS"];
  const alreadyReg = responseData["CO|1|ALREADYREG"];
  const copy = responseData["CO|1|COPY"];
  const print = responseData["CO|1|PRINT"];
  const search = responseData["CO|1|SEARCH"];
  const exportTo = responseData["CO|1|EXPORTTO"];
  const languageTitle = responseData["CO|1|LANGUAGE"];
  const profile = responseData["CO|1|PROFILE"];
  const newReg = responseData["CO|1|NEWREG"];
  const importTitle = responseData["CO|1|IMPORT"];
  const from = responseData["CO|1|FROM"];
  const file = responseData["CO|1|FILE"];
  const loginMsg1 = responseData["CO|1|LOGINMSG1"];
  const loginMsg2 = responseData["CO|1|LOGINMSG2"];
  const pwdMatchMsg = responseData["CO|1|PWMATCHMSG"];
  const save = responseData["CO|1|SAVEBTN"];
  const cancel = responseData["CO|1|CANCELBTN"];
  const reportSettings = responseData["CO|1|REPORTSET"];
  const reportTitle = responseData["CO|1|REPTITLE"];
  const filename = responseData["CO|1|REPNAME"];
  const fontSize = responseData["CO|1|FONTSIZE"];
  const reportFormat = responseData["CO|1|REPFORMAT"];
  const lastUpdate = responseData["CO|1|LASTUPDATE"];
  const revisionNumber = responseData["CO|1|REVISIONNO"];
  const groupStyle = responseData["CO|1|GROUPSTYLE"];
  const grouped = responseData["CO|1|GROUPED"];
  const stacked = responseData["CO|1|STACKED"];
  const layout = responseData["CO|1|LAYOUT"];
  const horizontal = responseData["CO|1|HORIZONTAL"];
  const vertical = responseData["CO|1|VERTICAL"];
  const startAngle = responseData["CO|1|STARTANGLE"];
  const endAngle = responseData["CO|1|ENDANGLE"];
  const interpolation = responseData["CO|1|INTERPOLAT"];
  const smooth = responseData["CO|1|SMOOTH"];
  const linear = responseData["CO|1|LINEAR"];
  const colors = responseData["CO|1|COLORS"];
  const defaultTitle = responseData["CO|1|DEFAULTP"];
  const blue = responseData["CO|1|BLUE"];
  const pastel = responseData["CO|1|PASTEL"];
  const importMsg1 = responseData["CO|1|DRAGDROPFL"];
  const choose = responseData["CO|1|CHOOSE"];
  const importMsg2 = responseData["CO|1|NOFILECH"];
  const importMsg3 = responseData["CO|1|SUPFILETYP"];
  const firstName = responseData["CO|1|FIRSTNAME"];
  const lastName = responseData["CO|1|LASTNAME"];
  const pwdChange = responseData["CO|1|PASSCHANGE"];
  const oldPwd = responseData["CO|1|OLDPASSWD"];
  const newPwd = responseData["CO|1|NEWPASSWD"];
  const confirmPwd = responseData["CO|1|CONFPASSWD"];
  const successPwdChange = responseData["CO|1|PASSWDSUCC"];
  const preferences = responseData["CO|1|PREFERENCS"];
  const user = responseData["CO|2|USER"];
  const change = responseData["CO|1|CHANGE"];
  const innerRadius = responseData["CO|1|INNERRAD"];
  const selectedFields = responseData["CO|1|FIELDS"];
  const displayOrder = responseData["CO|1|SELORDER"];
  const date = responseData["CO|1|DATE"];
  const fullDate = responseData["CO|1|FULLDATE"];
  const month = responseData["CO|1|MONTH"];
  const year = responseData["CO|1|YEAR"];
  const january = responseData["CO|1|MONTH01"];
  const february = responseData["CO|1|MONTH02"];
  const march = responseData["CO|1|MONTH03"];
  const april = responseData["CO|1|MONTH04"];
  const may = responseData["CO|1|MONTH05"];
  const june = responseData["CO|1|MONTH06"];
  const july = responseData["CO|1|MONTH07"];
  const august = responseData["CO|1|MONTH08"];
  const september = responseData["CO|1|MONTH09"];
  const october = responseData["CO|1|MONTH10"];
  const november = responseData["CO|1|MONTH11"];
  const december = responseData["CO|1|MONTH12"];

  let tempHideNoDateColumn = responseData["CO|1|HIDENODT"];

  if (
    responseData["CO|1|HIDENODT"] &&
    responseData["CO|1|HIDENODT"].includes("&#39;")
  ) {
    tempHideNoDateColumn = responseData["CO|1|HIDENODT"]
      .split("&#39;")
      .join("'");
  }

  const hideNoDateColumn = tempHideNoDateColumn;

  const temp = {
    ...responseData,
    username,
    password,
    confirmPswd,
    login,
    register,
    regNewUser,
    metrics,
    data,
    report,
    exportTitle,
    help,
    addNew,
    projects,
    userPswInv,
    datefield,
    groupfield,
    grouping,
    displayFunction,
    chartType,
    chartBar,
    chartLine,
    chartPie,
    daily,
    monthly,
    yearly,
    count,
    pctCount,
    sum,
    pctSum,
    home,
    whatIs,
    profile,
    languageTitle,
    exportTo,
    importTitle,
    from,
    file,
    copy,
    print,
    search,
    alreadyReg,
    loginMsg1,
    loginMsg2,
    pwdMatchMsg,
    newReg,
    save,
    cancel,
    reportSettings,
    reportTitle,
    reportFormat,
    filename,
    fontSize,
    lastUpdate,
    revisionNumber,
    groupStyle,
    grouped,
    stacked,
    layout,
    horizontal,
    vertical,
    interpolation,
    smooth,
    linear,
    startAngle,
    endAngle,
    colors,
    blue,
    defaultTitle,
    pastel,
    importMsg1,
    importMsg2,
    importMsg3,
    choose,
    firstName,
    lastName,
    pwdChange,
    oldPwd,
    newPwd,
    confirmPwd,
    successPwdChange,
    preferences,
    user,
    change,
    innerRadius,
    selectedFields,
    displayOrder,
    date,
    month,
    year,
    january,
    february,
    march,
    april,
    may,
    june,
    july,
    august,
    september,
    october,
    november,
    december,
    fullDate,
    hideNoDateColumn,
  };

  setCommonTitles(temp);
  return temp;
};

export default apiGetCommonTitles;
