import Flex from "../../../../../../helpers/components/Flex";
import { Input } from "antd";

const AutoID = ({ fields, data, config, addChange }) => {
  let placeholder = "";
  fields[34].TITLE.split(" ")
    .filter((item, index) => index !== 0)
    .map((item) => {
      placeholder += item;
    });

  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[34].TITLE.split(" ")[0]}:
      </p>
      <Input
        style={{
          width: 300,
          marginLeft: 15,
          marginTop: -5,
          marginBottom: "1rem",
          borderRadius: 5,
        }}
        placeholder={placeholder}
        onChange={(e) => addChange("F_FORMAT", e.target.value)}
        defaultValue={config === "edit" ? data.F_FORMAT : null}
      />
    </Flex>
  );
};

export default AutoID;
