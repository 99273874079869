import { Card, Select } from "antd";
import { useEffect } from "react";
import Flex from "../../../helpers/components/Flex";

const { Option } = Select;

const AddSite = ({ projects, addChange }) => {
  return (
    <Card style={{ borderRadius: 15, marginTop: "1rem" }}>
      <Flex flexDirection={"column"} style={{ gap: "1rem" }}>
        <Flex flexDirection={"row"}>
          <p
            style={{
              width: "8rem",
              fontWeight: 600,
              marginRight: "1rem",
            }}
          >
            Έργο:
          </p>
          <div className="select">
            <Select
              allowClear
              style={{
                width: 150,
              }}
              showSearch={true}
              filterOption={(iv, op) => {
                let opToUse = op.key ? op.key : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              onChange={(e) => addChange("project", e)}
              defaultValue={""}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option key={""} className="option" value={""} />
              {projects.map((project) => (
                <Option
                  key={project.key}
                  className="option"
                  value={project.key}
                >
                  {project.title}
                </Option>
              ))}
            </Select>
          </div>
        </Flex>
        <Flex flexDirection={"row"}>
          <p
            style={{
              width: "8rem",
              fontWeight: 600,
              marginRight: "1rem",
            }}
          >
            Ονομασία:
          </p>
          <textarea
            type="text"
            style={{
              width: 500,
              borderRadius: 10,
              minHeight: 40,
              paddingLeft: 10,
              paddingTop: 5,
            }}
            onChange={(e) => addChange("title", e.target.value)}
          />
        </Flex>
        <Flex flexDirection={"row"}>
          <p
            style={{
              width: "8rem",
              fontWeight: 600,
              marginRight: "1rem",
            }}
          >
            Χώρα:
          </p>
          <textarea
            type="text"
            style={{
              width: 500,
              borderRadius: 10,
              minHeight: 40,
              paddingLeft: 10,
              paddingTop: 5,
            }}
            onChange={(e) => addChange("country", e.target.value)}
          />
        </Flex>
        <Flex flexDirection={"row"}>
          <p
            style={{
              width: "8rem",
              fontWeight: 600,
              marginRight: "1rem",
            }}
          >
            Διεύθυνση:
          </p>
          <textarea
            type="text"
            style={{
              width: 500,
              borderRadius: 10,
              minHeight: 40,
              paddingLeft: 10,
              paddingTop: 5,
            }}
            onChange={(e) => addChange("address", e.target.value)}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default AddSite;
