import "./css/fieldsConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

const FieldsConfigHead = ({ headers }) => {
  const centralAlignTitles = [
    "F_REQUIRED",
    "F_UNIQUE",
    "F_TOTALS",
    "F_WIDTH",
    "SIZE",
  ];
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell style={{ width: "5%" }} key={"ghost-cell"} />
        <TableCell style={{ width: "5%" }} key={"ghost-cell-2"} />
        {headers.map((item) => (
          <TableCell
            align={centralAlignTitles.includes(item.id) ? "center" : "left"}
            key={item.id}
            className="admin-header"
          >
            <p key={`${item.id} - text`}>{item.title}</p>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default FieldsConfigHead;
