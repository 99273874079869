import { Tag, Tooltip } from "antd";
import {
  ChooseTagTooltip,
  chooseTagColor,
  chooseTagTitle,
} from "../../helpers/components/ChooseTagProps";
import { tagStyle } from "../../styles/TagItemStyles";

const CustomTag = ({
  tag,
  index,
  functionTags,
  fieldTags,
  handleDoubleClick,
  handleClose,
}) => {
  return (
    <Tooltip
      key={`${tag}-${index}-tooltip`}
      placement="bottom"
      overlayInnerStyle={{ width: "max-content" }}
      title={
        <ChooseTagTooltip
          tag={tag}
          functionTags={functionTags}
          fieldTags={fieldTags}
        />
      }
    >
      <Tag
        key={`${tag}-${index}`}
        closable={false}
        style={tagStyle}
        onClose={() => handleClose(tag)}
        color={chooseTagColor(tag)}
        bordered={false}
      >
        <span onDoubleClick={(e) => handleDoubleClick(e, tag, index)}>
          <p style={{ fontSize: 14 }}>
            {chooseTagTitle(tag, fieldTags, functionTags)}
          </p>
        </span>
      </Tag>
    </Tooltip>
  );
};

export default CustomTag;
