const createFilters = (filters, changes, columns) => {
  const columnsWithDate = columns
    .filter(({ F_TYPE }) => F_TYPE === "4")
    .map(({ F_ID }) => F_ID);

  let tempfilters = filters ? { ...filters } : {};
  Object.entries(changes).map(([key, value]) => {
    if (columnsWithDate.includes(key)) {
      Object.entries(value).map(([suffix, suffixValue]) => {
        if (suffixValue !== "" && suffixValue?.length !== 0) {
          tempfilters = {
            ...tempfilters,
            [key]: { ...tempfilters[key], [suffix]: suffixValue },
          };
        } else {
          delete tempfilters[key][suffix];
          if (Object.keys(tempfilters[key]).length === 0) {
            delete tempfilters[key];
          }
        }
      });
    } else {
      if (value !== "" && value?.length !== 0) {
        tempfilters = { ...tempfilters, [key]: value };
      } else {
        delete tempfilters[key];
      }
    }
  });

  return tempfilters;
};

export default createFilters;
