export const convertDateFormat = (dateFormat) => {
  if (dateFormat === "21" /* DD/MM/YY */) {
    return "DD/MM/YY";
  } else if (dateFormat === "15" /* DD/MM/YYYY */) {
    return "DD/MM/YYYY";
  } else if (dateFormat === "16" /* MM/DD/YYYY */) {
    return "MM/DD/YYYY";
  } else if (dateFormat === "22" /* MM/DD/YY */) {
    return "MM/DD/YY";
  } else if (dateFormat === "17" /* YYYY/MM/DD */) {
    return "YYYY/MM/DD";
  }

  return "YYYY/MM/DD";
};
