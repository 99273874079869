import { useMenuStore, useAuthStore } from "../../../store/useStores";
import { Link } from "react-router-dom";
import { Dropdown, Space } from "antd";
import Flex from "../../../helpers/components/Flex";

const ModuleMenu = () => {
  const menu = useMenuStore((state) => state.menu);
  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );

  const moduleMenuTitle = menu?.[0]?.T_TITLE ?? "";

  const moduleItems =
    menu?.[0]?.children?.map((obj, index) => ({
      key: obj.ME_ORDER,
      label: (
        <Link to={`/module/${obj.ME_MODULEID}`}>
          {obj?.T_TITLE?.includes("&#39;")
            ? obj.T_TITLE.split("&#39;")
                .filter((item) => item !== "")
                .join("'")
            : obj?.T_TITLE ?? ""}
        </Link>
      ),
      // children: [
      //   { key: `${obj.ME_ORDER}-${index}`, label: "Module 1" },
      //   { key: `${obj.ME_ORDER}-${index + 1}`, label: "Module 2" },
      // ],
    })) ?? [];

  const titleStyle = {
    color: "#fff",
    fontFamily: "var(--font-family)",
    fontWeight: 500,
    fontSize: 15,
    textTransform: "capitalize",
    wordSpacing: "0.3em",
    zIndex: 5,
    margin: "0 1rem",
    cursor: "pointer",
  };

  if (!moduleItems?.length) {
    return <Flex />;
  }

  return (
    <Flex
      flexDirection={"row"}
      style={{ marginTop: "-1rem", marginRight: "2rem" }}
    >
      <Dropdown menu={{ items: moduleItems }}>
        <a style={titleStyle} onClick={(e) => e.preventDefault()}>
          <Space style={{ color: navigationTextColor }}>
            {moduleMenuTitle}
          </Space>
        </a>
      </Dropdown>
    </Flex>
  );
};

export default ModuleMenu;
