import "../../../css/newRegisterModal.css";
import { WarningOutlined } from "@ant-design/icons";
import Flex from "../../../../../../../helpers/components/Flex";
import FileUpload from "../../../fileUpload/FileUpload";

const DocumentType = ({ id, required, addChange }) => {
  return (
    <Flex flexDirection={"row"}>
      <FileUpload files={[]} addChange={addChange} id={id} />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 10, marginLeft: 10 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default DocumentType;
