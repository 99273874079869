import axios from "axios";

const apiGetData = async (
  moduleid,
  baseURL,
  filters,
  orderBy,
  orderDirection,
  pageSize,
  currentPage,
  noRows
) => {
  const url = `${baseURL}/Data_api/apigetdata`;

  const sizes = [10, 20, 50];

  let newPageSize = pageSize;
  if (!sizes.includes(pageSize)) {
    newPageSize = 0;
  }

  let noRowsValue = noRows;

  if (!noRowsValue) {
    noRowsValue = false;
  }

  try {
    const response = await axios.post(
      url,
      {
        moduleid: moduleid,
        filters: filters,
        orderid: orderBy,
        orderdirection: orderDirection,
        pagesize: newPageSize,
        currentpage: currentPage,
        norows: noRowsValue,
        //all: false,
      },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    console.log("apiGetData: response.data: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiGetData;
