const chooseEventDisplay = (eventType = [], titles) => {
  let displayValue = "";

  if (eventType.length === 0) {
    return "-";
  }

  eventType.forEach((type) => {
    if (type === "I") {
      if (displayValue !== "") {
        displayValue += ", ";
      }
      displayValue += titles["FO|127|TITLE"];
    }

    if (type === "U") {
      if (displayValue !== "") {
        displayValue += ", ";
      }
      displayValue += titles["FO|128|TITLE"];
    }

    if (type === "D") {
      if (displayValue !== "") {
        displayValue += ", ";
      }
      displayValue += titles["FO|129|TITLE"];
    }
  });

  return displayValue;
};

export default chooseEventDisplay;
