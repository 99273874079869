import Flex from "../../../../../../helpers/components/Flex";
import { Select } from "antd";
import CustomCheckbox from "../../../../../../helpers/components/CustomCheckbox";

const { Option } = Select;

const Unique = ({ data, addChange, fields, config, staticOptions }) => {
  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[9].TITLE}:
      </p>

      <div
        style={{
          marginLeft: 20,
          marginTop: -14,
          marginBottom: 12,
          width: 350,
        }}
      >
        <CustomCheckbox
          id={"F_UNIQUE"}
          value={config === "edit" ? data.F_UNIQUE : ""}
          addChange={addChange}
          config={config}
          isDisabled={false}
        />
      </div>

      {/* <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 20,
            marginBottom: "1rem",
          }}
          showSearch={true}
          onChange={(e) => {
            addChange("F_UNIQUE", e);
          }}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          defaultValue={config === "edit" && data.F_UNIQUE}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          <Option
            key={Object.keys(staticOptions[9])[0]}
            className="option"
            value={Object.keys(staticOptions[9])[0]}
          >
            {staticOptions[9].Y.TITLE}
          </Option>
          <Option
            key={Object.keys(staticOptions[9])[1]}
            className="option"
            value={Object.keys(staticOptions[9])[1]}
          >
            {staticOptions[9].N.TITLE}
          </Option>
        </Select>
      </div> */}
    </Flex>
  );
};

export default Unique;
