import Flex from "../../../../../helpers/components/Flex";

const RenderAlert = ({ value }) => {
  return (
    <Flex flexDirection={"column"}>
      <p
        className="p-context"
        style={{
          background: value?.color[1] === "B" ? value?.color[0] : "#ffffff",
          color: value?.color[1] === "F" && value?.color[0],
        }}
      >
        {value?.value ?? ""}
      </p>
    </Flex>
  );
};

export default RenderAlert;
