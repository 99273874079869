import "./alertConfig.css";
import { Layout, Select, Button, message } from "antd";
import { AlertConfigModal } from "./AlertConfigModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";
import axios from "axios";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const { Option } = Select;
const { Content } = Layout;

const AlertConfigSettings = ({
  headers,
  titles,
  modules,
  fields,
  newItemModalVisible,
  showModal,
  updateData,
  handleCancel,
  handleDelete,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);

  const onSave = async (changes) => {
    const url = `${baseURL}/App_alerts_api/save`;

    try {
      const response = await axios.post(
        url,
        {
          ...changes,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_alerts_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");

        let infoFieldTitlesValue = "";
        let infoFieldsValue = [];

        if (changes.INFORMATIONFIELDS) {
          infoFieldsValue = changes.INFORMATIONFIELDS.split(",").map(
            (item) => item
          );

          let str = "";
          infoFieldsValue.map((item) => {
            if (str !== "") {
              str += ", ";
            }
            str += fields[changes.F_MODULEID].filter(
              (field) => field.F_ID === item
            )[0].T_TITLE;
          });
          infoFieldTitlesValue += str;
        }

        let triggerFieldTitlesValue = "";

        if (changes.A_TRIGGER_FIELDID) {
          triggerFieldTitlesValue = fields[changes.F_MODULEID].filter(
            (field) => field.F_ID === changes.A_TRIGGER_FIELDID
          )[0].T_TITLE;
        }

        const changesWithExtraInfo = {
          T_TITLE: changes?.ALERTTEXT,
          INFOFIELDTITLES: infoFieldTitlesValue,
          TRIGGERFIELDTITLE: triggerFieldTitlesValue,
          A_INFORMATION_FIELDIDS: changes.INFORMATIONFIELDS ?? "",
          ...changes,
          ["A_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          ["A_ID"]: response?.data?.ID.toString(),
        };
        updateData("new", changesWithExtraInfo);
        setCoreChange(!coreChange);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(commonTitles["CO|1|ERR500"], [8]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"}>
        <Content
          style={{
            padding: 5,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        />
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {titles["MO|7|NEWROW"]}
          </Button>
          {newItemModalVisible && (
            <AlertConfigModal
              key={"new-item"}
              visible={newItemModalVisible}
              headers={headers}
              titles={titles}
              modules={modules}
              onSave={onSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
              fields={fields}
            />
          )}
        </div>
      </Flex>
    </Content>
  );
};

export default AlertConfigSettings;
