import { Modal } from "antd";
import { useState, useEffect } from "react";
import Flex from "../../../helpers/components/Flex";
import HeaderDecider from "./deciders/HeaderDecider";
import InputDecider from "./deciders/InputDecider";
import ModalFooter from "./modalFooter/ModalFooter";
import { useModuleStore } from "../../../store/useStores";

export const AlertConfigModal = ({
  visible,
  data,
  headers,
  modules,
  titles,
  onSave,
  onCancel,
  config,
  fields,
  showDeleteModal,
}) => {
  const [changes, setChanges] = useState({});
  const [moduleID, setModuleID] = useState(null);
  const [alertText, setAlertText] = useState("");
  const [triggerField, setTriggerField] = useState("");
  const [infoFields, setInfoFields] = useState([]);

  const dateTypes = useModuleStore((state) => state.dateTypes);

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  useEffect(() => {
    if (config === "new") {
      setChanges({
        ...changes,
        A_ACTIVE: "Y",
        A_ID: "",
        A_LASTMODIFIED: "",
        ALERTTEXT: "",
        A_WARNING_BEFORE: "0",
        A_RAISED_AFTER: "0",
        INFORMATIONFIELDS: "",
        A_FILTER: {},
        A_PARENTID: "",
      });
    }

    if (config === "edit") {
      const defaultInfoFieldsValue =
        data?.A_INFORMATION_FIELDIDS?.split(",")
          ?.map((item) => item)
          .filter((datum) => datum) ?? [];

      setAlertText(data.T_TITLE);
      setTriggerField(data.TRIGGERFIELDTITLE);
      setInfoFields(defaultInfoFieldsValue);
      setChanges({ ...changes, F_MODULEID: data.F_MODULEID });
      setModuleID(data.F_MODULEID);
    }
  }, []);

  useEffect(() => {
    if (alertText && alertText !== data?.T_TITLE) {
      setChanges({
        ...changes,
        ALERTTEXT: alertText ?? "",
      });
    }
  }, [moduleID, alertText]);

  useEffect(() => {
    if (config === "edit") {
      if (changes.F_MODULEID !== data.F_MODULEID) {
        setAlertText("");
        setTriggerField(null);
        setInfoFields([]);
      } else {
        const defaultInfoFieldsValue =
          data?.A_INFORMATION_FIELDIDS?.split(",")
            ?.map((item) => item)
            .filter((datum) => datum) ?? [];

        setAlertText(data.T_TITLE);
        setTriggerField(data.TRIGGERFIELDTITLE);
        setInfoFields(defaultInfoFieldsValue);
      }
    }
  }, [moduleID]);

  const updateModuleID = (id) => setModuleID(id);
  const updateAlertText = (value) => setAlertText(value);

  const triggerFieldOptions =
    fields[moduleID]?.filter((item) => dateTypes.includes(item.F_TYPE)) ?? [];

  return (
    <Modal
      key={`${config}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        marginTop: 20,
        minWidth: "max-content",
      }}
      bodyStyle={{ paddingLeft: 80, paddingRight: 160 }}
      title={config === "new" ? titles["MO|7|NEWROW"] : titles["MO|7|EDITROW"]}
      onOk={() => onSave(changes)}
      onCancel={() => onCancel(config)}
      onDelete={() => showDeleteModal(data?.A_ID)}
      footer={
        <ModalFooter
          data={data}
          changes={changes}
          config={config}
          onSave={onSave}
          onCancel={onCancel}
          showDeleteModal={showDeleteModal}
        />
      }
    >
      <Flex
        flexDirection={"row"}
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <div className="modal-text">
          {headers
            .filter((item, index) => index !== headers.length - 1)
            .map((header) => (
              <Flex
                key={header.id}
                flexDirection={"row"}
                style={{ alignItems: "start" }}
              >
                <HeaderDecider
                  id={header.id}
                  title={header.title}
                  changes={changes}
                  data={data}
                  config={config}
                />
                <InputDecider
                  id={header.id}
                  data={data}
                  addChange={addChange}
                  moduleID={moduleID}
                  updateModuleID={updateModuleID}
                  modules={modules}
                  triggerField={triggerField}
                  triggerFieldOptions={triggerFieldOptions}
                  titles={titles}
                  infoFields={infoFields}
                  alertText={alertText}
                  updateAlertText={updateAlertText}
                  config={config}
                  fields={fields}
                />
              </Flex>
            ))}
        </div>
      </Flex>
    </Modal>
  );
};
