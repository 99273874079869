import "../../css/newRegisterModal.css";
import { Col, Row, Tooltip } from "antd";
import FormControl from "@mui/material/FormControl";
import { Card, Layout } from "antd";
import { useAuthStore } from "../../../../../../store/useStores";
import inputDecider from "../inputDecider/inputDecider";
import Flex from "../../../../../../helpers/components/Flex";
import useWindowDimensions from "../../../../../ultimateTable/hooks/useWindowDimensions";
import htmlCodesRemover from "../../../../../ultimateTable/helpers/htmlCodesRemover";

const { Content } = Layout;

const NewRegisterModalContent = ({
  columns,
  staticOptions,
  foreignKeyValues,
  columnsChanged,
  addChange,
  privileges,
  moduleID,
  autoCompletedFields = {},
  changes,
}) => {
  const role = useAuthStore((state) => state.role);
  const { width } = useWindowDimensions();

  const isFirefox = typeof InstallTrigger !== "undefined";

  let newColumns = [];
  let i = 0;

  [...columns]
    .sort(
      (
        { F_EDITORDER: F_EDITORDER_A = 0 },
        { F_EDITORDER: F_EDITORDER_B = 0 }
      ) => {
        if (!F_EDITORDER_A) {
          if (isFirefox) {
            return -1;
          }
          return 1;
        } else if (!F_EDITORDER_B) {
          return -1;
        } else {
          return Number(F_EDITORDER_A) - Number(F_EDITORDER_B);
        }
      }
    )
    .forEach((column) => {
      if (column?.F_SINGLELINE) {
        if (column?.F_SINGLELINE === "L") {
          newColumns.push(column);
          newColumns.push({
            F_ID: i.toString(),
            T_TITLE: "",
            F_REQUIRED: "N",
            F_TYPE: "0",
            F_LIST: "Y",
          });
        }

        if (column?.F_SINGLELINE === "R") {
          newColumns.push({
            F_ID: i.toString(),
            T_TITLE: "",
            F_REQUIRED: "N",
            F_TYPE: "0",
            F_LIST: "Y",
          });
          newColumns.push(column);
        }

        i++;
      } else {
        newColumns.push(column);
      }
    });

  let insertPrivileges = {};
  newColumns.forEach((column) => {
    if (role === "A") {
      insertPrivileges = { ...insertPrivileges, [column.F_ID]: true };
    } else if (Object.keys(column).includes("caninsert")) {
      insertPrivileges = {
        ...insertPrivileges,
        [column.F_ID]: column.caninsert,
      };
    } else {
      insertPrivileges = {
        ...insertPrivileges,
        [column.F_ID]: privileges?.canInsert,
      };
    }
  });

  return (
    <Flex flexDirection={"column"}>
      <Content>
        <Card bodyStyle={{ maxHeight: "60vh", overflowY: "auto" }}>
          <Row gutter={[20, 10]} justify={width < 1450 ? "center" : "left"}>
            {newColumns
              .filter((column) => insertPrivileges[column.F_ID])
              .map(
                ({
                  F_ID,
                  F_TYPE,
                  T_TITLE,
                  F_REQUIRED,
                  INFO,
                  F_PARENTFIELDID,
                  F_FORMAT,
                }) => (
                  <Col
                    className="gutter-row"
                    span={width < 1450 ? 20 : 10}
                    key={F_ID}
                  >
                    <div className="grid-horizontal">
                      {F_TYPE !== "0" && (
                        <div className="filters-first_column">
                          <>
                            {columnsChanged.includes(F_ID) && (
                              <p className="asterisk">*</p>
                            )}
                            {htmlCodesRemover(T_TITLE).length > 22 ? (
                              <Tooltip
                                placement="bottom"
                                color="white"
                                overlayInnerStyle={{ color: "black" }}
                                title={
                                  <Flex flexDirection={"column"}>
                                    <b>{htmlCodesRemover(T_TITLE)}</b>
                                    {INFO && <i>{INFO}</i>}
                                  </Flex>
                                }
                              >
                                {htmlCodesRemover(T_TITLE).slice(0, 22) + "..."}
                                :
                              </Tooltip>
                            ) : (
                              <Tooltip
                                placement="bottom"
                                title={INFO && <i>{INFO}</i>}
                                color="white"
                                overlayInnerStyle={{ color: "black" }}
                              >
                                {htmlCodesRemover(T_TITLE)}:
                              </Tooltip>
                            )}
                          </>
                        </div>
                      )}
                      <FormControl
                        sx={{
                          mt: -1,
                          mb: 0.5,
                          ml: 1,
                          mr: 1,
                          minWidth: 300,
                        }}
                        className="filters-row"
                        key={F_ID}
                      >
                        {inputDecider(
                          F_REQUIRED,
                          F_TYPE,
                          F_ID,
                          staticOptions,
                          foreignKeyValues,
                          addChange,
                          moduleID,
                          autoCompletedFields,
                          F_PARENTFIELDID,
                          changes,
                          F_FORMAT
                        )}
                      </FormControl>
                    </div>
                  </Col>
                )
              )}
          </Row>
        </Card>
      </Content>
    </Flex>
  );
};

export default NewRegisterModalContent;
