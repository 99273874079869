import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../../store/useStores";
import { useState, useEffect } from "react";
import { Input, Select, Image } from "antd";
import Flex from "../../../../../helpers/components/Flex";
import apiGetLanguagesList from "../../../../../api/endpoints/apiGetLanguagesList";
import countryPhoneCodes from "../../../../module/helpers/countryPhoneCodes";

const { Option } = Select;

const PhoneType = ({ value = null, id, title, config, addChange, changes }) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const [validPhone, setValidPhone] = useState(true);
  const [countries, setCountries] = useState([]);
  const [phoneChanges, setPhoneChanges] = useState({ code: "+30" });
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  useEffect(() => {
    const fetchData = async () => {
      const serverData = await apiGetLanguagesList(
        baseURL,
        language,
        workspaceID
      );

      const temp = Object.values(serverData.flagfiles).map((flag) => {
        const countryName = flag.split(".")[0] ?? "";
        const phoneCode =
          countryPhoneCodes.filter(
            (country) => country.name === countryName
          )?.[0]?.code ?? "";
        return { name: countryName, phoneCode: phoneCode, flagName: flag };
      });
      setCountries(temp);
    };

    fetchData();
  }, []);

  const checkPhoneValidity = (phone) => {
    if (phone) {
      setValidPhone(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone));
    } else {
      setValidPhone(true);
    }
  };

  const createValue = (inputValue, inputConfig) => {
    if (config === "new") {
      if (inputConfig === "code") {
        if (phoneChanges?.phone) {
          if (inputValue) {
            const newValue = inputValue + " " + phoneChanges.phone;
            addChange(id, newValue, null);
          } else {
            if (phoneChanges?.code) {
              const newValue = phoneChanges?.phone ?? null;
              addChange(id, newValue, null);
            }
          }
        }
      } else {
        if (inputValue) {
          let newValue = null;
          if (phoneChanges?.code) {
            newValue = phoneChanges.code + " " + inputValue;
          } else {
            newValue = "+30 " + inputValue;
          }
          addChange(id, newValue, null);
        } else {
          addChange(id, null, null);
        }
      }
    }

    if (config === "edit") {
      if (inputConfig === "code") {
        if (phoneChanges?.phone) {
          if (inputValue) {
            const newValue = inputValue + " " + phoneChanges.phone;
            addChange(id, newValue, null);
          } else {
            if (phoneChanges?.code) {
              const newValue = phoneChanges?.phone ?? null;
              addChange(id, newValue, null);
            } else if (value) {
              if (value?.includes(" ")) {
                const newValue = value?.split(" ")[1].trim();
                addChange(id, newValue, null);
              } else {
                const newValue = value;
                addChange(id, newValue, null);
              }
            }
          }
        } else if (value) {
          if (inputValue) {
            if (value?.includes(" ")) {
              const newValue = inputValue + " " + value?.split(" ")[1].trim();
              addChange(id, newValue, null);
            } else {
              const newValue = inputValue + " " + value;
              addChange(id, newValue, null);
            }
          } else {
            if (value?.includes(" ")) {
              const newValue = value?.split(" ")[1].trim();
              addChange(id, newValue, null);
            } else {
              const newValue = inputValue + " " + value;
              addChange(id, newValue, null);
            }
          }
        }
      } else {
        if (inputValue) {
          let newValue = null;
          if (changes?.code) {
            newValue = changes.code + " " + inputValue;
          } else if (value?.split(" ")[0]) {
            newValue = value?.split(" ")[0] + " " + inputValue;
          } else {
            newValue = inputValue;
          }
          addChange(id, newValue, null);
        } else {
          addChange(id, null, null);
        }
      }
    }
  };

  return (
    <Flex key={id} flexDirection={"column"}>
      <Flex
        key={id}
        flexDirection={"row"}
        style={{ justifyContent: "flex-start" }}
      >
        <div className="modal-text" style={{ width: "35%" }}>
          <p>{title}:</p>
        </div>

        <div className="modal-input" style={{ width: "65%" }}>
          <div className="select">
            <Flex
              key={countries.length}
              flexDirection={"row"}
              style={{
                alignContent: "center",
                width: 305,
                marginLeft: 8,
                gap: "0.3rem",
              }}
            >
              <Select
                style={{
                  width: "40%",
                  marginLeft: 8,
                }}
                showSearch={true}
                onChange={(e) => {
                  setPhoneChanges({ ...phoneChanges, code: e });
                  createValue(e, "code");
                }}
                filterOption={(iv, op) => {
                  let opToUse = op.key ? op.key : "";
                  return opToUse.toLowerCase().includes(iv.toLowerCase());
                }}
                dropdownStyle={{ overflowY: "auto" }}
                placeholder={commonTitles["CO|1|OPTIONAL"]}
                virtual={false}
                defaultValue={
                  config === "edit"
                    ? value?.includes("+")
                      ? value?.split(" ")?.[0] ?? null
                      : null
                    : "+30"
                }
              >
                <Option value={""} />
                {countries
                  ?.filter((country) => country.phoneCode && country.flagName)
                  ?.map((item) => (
                    <Option
                      className="option"
                      key={item?.phoneCode}
                      value={item?.phoneCode}
                      label={item?.phoneCode ?? ""}
                    >
                      <Flex
                        flexDirection={"row"}
                        style={{ gap: "1rem", alignItems: "center" }}
                      >
                        <Image
                          width={20}
                          src={require(`../../../../../assets/Flags/${item?.flagName}`)}
                          alt={item?.name ?? ""}
                          preview={false}
                        />
                        <p>{`${item?.phoneCode}`}</p>
                      </Flex>
                    </Option>
                  ))}
              </Select>
              <Input
                type="text"
                style={{
                  border: "1.25px solid",
                  borderColor: "rgb(182, 180, 180)",
                  borderRadius: 5,
                  fontFamily: "Arial, Helvetica, sans-serif",
                  paddingRight: "0.5rem",
                  paddingLeft: "0.5rem",
                  fontSize: 14,
                  letterSpacing: "0.03em",
                  height: 32,
                  width: "60%",
                }}
                onChange={(e) => {
                  checkPhoneValidity(e.target.value);
                  setPhoneChanges({ ...phoneChanges, phone: e.target.value });
                  createValue(e.target.value, "phone");
                }}
                defaultValue={
                  config === "edit"
                    ? value?.includes("+")
                      ? value?.split(" ")?.[1] ?? null
                      : value ?? null
                    : null
                }
              />
            </Flex>
          </div>
        </div>
      </Flex>
      {!validPhone && (
        <p
          style={{
            marginTop: "-0.8rem",
            marginBottom: "0.4rem",
            marginLeft: "11rem",
            fontStyle: "italic",
            color: "red",
          }}
        >
          {commonTitles["CO|1|INVLDPHONE"]}
        </p>
      )}
    </Flex>
  );
};

export default PhoneType;
