import { useState } from "react";
import Flex from "../../../../../../helpers/components/Flex";
import { Select } from "antd";
import { useEffect } from "react";
import htmlCodesRemover from "../../../../../ultimateTable/helpers/htmlCodesRemover";

const { Option } = Select;

const ButtonType = ({
  fields,
  refModules = [],
  addChange,
  config,
  data,
  bodyData,
}) => {
  const [module, setModule] = useState(null);

  useEffect(() => {
    if (Object.keys(data)?.length) {
      setModule(data.REFMODULE);
    }
  }, []);

  return (
    <Flex
      flexDirection={"column"}
      style={{
        gap: "0.15rem",
        marginTop: config === "edit" ? "0.3rem" : "2rem",
      }}
    >
      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: 180,
            justifyContent: "flex-end",
          }}
        >
          {fields[6].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 15,
              marginBottom: "1rem",
            }}
            showSearch={true}
            onChange={(e) => {
              addChange("F_PARENTFIELDID", e);
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={data?.F_PARENTFIELDID ?? ""}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {bodyData
              // ?.filter((item) =>
              //   ["4", "5", "6", "7", "28", "33", "36"].includes(item.F_TYPE)
              // )
              .map((module) => (
                <Option
                  key={module.F_ID}
                  className="option"
                  value={module.F_ID}
                >
                  {module.T_TITLE}
                </Option>
              ))}
          </Select>
        </div>
      </Flex>
      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: 180,
            justifyContent: "flex-end",
          }}
        >
          {fields[26].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 15,
              marginBottom: "1rem",
            }}
            showSearch={true}
            onChange={(e) => {
              addChange("REFMODULE", e);
              setModule(e);
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={config === "edit" ? data?.REFMODULE ?? "" : ""}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {refModules.map((module) => (
              <Option key={module.M_ID} className="option" value={module.M_ID}>
                {htmlCodesRemover(module.T_TITLE)}
              </Option>
            ))}
          </Select>
        </div>
      </Flex>

      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: 180,
            justifyContent: "flex-end",
          }}
        >
          {fields[27].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 15,
              marginBottom: "1rem",
            }}
            // mode="multiple"
            showSearch={true}
            onChange={(e) => {
              addChange("REFFIELD", e);
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={config === "edit" ? data?.REFFIELD ?? "" : ""}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {refModules
              .filter((item) => item.M_ID === module)[0]
              ?.FIELDS.map((module) => (
                <Option
                  key={module.F_ID}
                  className="option"
                  value={module.F_ID}
                >
                  {htmlCodesRemover(module.T_TITLE)}
                </Option>
              ))}
          </Select>
        </div>
      </Flex>
    </Flex>
  );
};

export default ButtonType;
