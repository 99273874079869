import TableCell from "@mui/material/TableCell";
import "../css/ultimateTable.css";
import { Card, Layout } from "antd";
import { useAuthStore } from "../../../store/useStores";
import Flex from "../../../helpers/components/Flex";
import MiniCard from "./miniCard/MiniCard";
import InfoBody from "./infoBody/InfoBody";
import Actions from "./actions/Actions";

const InfoCard = ({
  title,
  rowID,
  columns,
  row,
  staticOptions,
  foreignKeyValues,
  length,
  updateRow,
  deleteRow,
  originalData,
  dataToUse,
  moduleID,
  config,
  insertData,
  privileges,
  autoCompletedFields,
  updateTotals,
}) => {
  const role = useAuthStore((state) => state.role);

  let viewRights = {};
  columns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  let canUpdate = false;
  let canDelete = false;
  let canCopy = false;

  if (config === "alerts") {
    if (role === "A") {
      canUpdate = true;
      canDelete = true;
      canCopy = true;
    } else {
      canUpdate = privileges?.[row.moduleID]?.canUpdate ?? false;
      canDelete = privileges?.[row.moduleID]?.canDelete ?? false;
      canCopy = privileges?.[row.moduleID]?.canCopy ?? false;
    }
  } else {
    canUpdate = privileges.canUpdate;
    canDelete = privileges.canDelete;
    canCopy = privileges.canCopy;
  }

  return (
    <TableCell key={`${rowID}-card`} colSpan={length > 10 ? 10 : length}>
      <Layout>
        <Flex flexDirection={"row"}>
          <Card style={{ display: "flex" }}>
            <Flex
              flexDirection={"column"}
              style={{ width: "250px", height: "max-content" }}
            >
              <MiniCard
                columns={columns}
                row={row}
                config={config}
                foreignKeyValues={foreignKeyValues}
              />
              <Actions
                row={row}
                rowID={rowID}
                columns={columns}
                privileges={privileges}
                canUpdate={canUpdate}
                canCopy={canCopy}
                canDelete={canDelete}
                moduleID={moduleID}
                config={config}
                title={title}
                foreignKeyValues={foreignKeyValues}
                staticOptions={staticOptions}
                deleteRow={deleteRow}
                updateRow={updateRow}
                insertData={insertData}
                originalData={originalData}
                dataToUse={dataToUse}
                autoCompletedFields={autoCompletedFields}
                updateTotals={updateTotals}
              />
            </Flex>
          </Card>
          <InfoBody
            row={row}
            columns={columns}
            config={config}
            viewRights={viewRights}
            foreignKeyValues={foreignKeyValues}
          />
        </Flex>
      </Layout>
    </TableCell>
  );
};

export default InfoCard;
