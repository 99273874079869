import "./users.css";
import { Layout, Card, Spin, message } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import UsersSettings from "./UsersSettings";
import UsersHead from "./UsersHead";
import UsersBody from "./UsersBody";
import apiGetUsersList from "../../../api/endpoints/apiGetUsersList";
import {
  useAuthStore,
  useCommonTitlesStore,
  useModTitlesStore,
} from "../../../store/useStores";
import Navigation from "../../navigation/Navigation";
import Flex from "../../../helpers/components/Flex";
import filterDataOnSearch from "../helpers/filterDataOnSearch";
import useSort from "./hooks/useSort";
import axios from "axios";
import { DeleteModal } from "./deleteModals";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import BackgroundWrapper from "../../../helpers/components/BackgroundWrapper";
import TableWrapper from "../../../helpers/components/TableWrapper";
import AdministrationBanner from "../administrationBanner/AdministrationBanner";

const { Content } = Layout;

const Users = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [data, setData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [roles, setRoles] = useState({});
  const [staticOptions, setStaticOptions] = useState({});
  const [titles, setTitles] = useState({});

  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const serverData = await apiGetUsersList(baseURL);
    const tempData = [];
    serverData?.users?.forEach((user) => {
      let userRoles = "";
      user.USER_ROLEID.split(",").map((item) => {
        if (userRoles !== "") {
          userRoles += ", ";
        }
        userRoles += serverData?.roles[item];
      });
      const value = { ...user, ["USER_ROLEID-title"]: userRoles };
      tempData.push(value);
    });
    setData(tempData);
    setBodyData(tempData);
    setStaticOptions(serverData?.staticoptions);
    setRoles(serverData?.roles);
    setTitles(serverData?.moduletitles);
    setHeadData([
      { id: "USER_LASTNAME", title: serverData?.moduletitles["FD|265|TITLE"] },
      { id: "USER_FIRSTNAME", title: serverData?.moduletitles["FD|266|TITLE"] },
      { id: "USER_LOGIN", title: serverData?.moduletitles["FD|267|TITLE"] },
      { id: "USER_TYPE", title: serverData?.moduletitles["FD|268|TITLE"] },
      { id: "USER_ROLEID", title: serverData?.moduletitles["FD|270|TITLE"] },
      { id: "USER_ACTIVE", title: serverData?.moduletitles["FD|269|TITLE"] },
    ]);
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [projectID, language]);

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const updateData = (config, changes, id) => {
    if (config === "edit") {
      setModalVisible(false);
      const tempData = bodyData.map((item) => {
        if (item.USER_ID === id) {
          return { ...item, ...changes };
        } else {
          return item;
        }
      });
      setBodyData(tempData);
      setData(tempData);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
      setBodyData([...bodyData, changes]);
      setData([...data, changes]);
    }
  };

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
    }
  };

  const resetPassword = async (username) => {
    const url = `${baseURL}/Users_api/resetpassword`;

    try {
      const response = await axios.post(
        url,
        { username: username },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Users_api/resetpassword: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        setModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const showDeleteModal = (id, username) => {
    setDeleteModalVisible(true);
    setItemToDelete({ id: id, username: username });
  };
  const cancelDeleteModal = () => setDeleteModalVisible(false);

  const handleDelete = async (id) => {
    const url = `${baseURL}/Users_api/delete`;

    try {
      const response = await axios.post(
        url,
        {
          id: id,
          username: itemToDelete.username,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Users_api/delete: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        setBodyData(bodyData.filter((item) => item.USER_ID !== id));
        setData(bodyData.filter((item) => item.USER_ID !== id));
        setDeleteModalVisible(false);
        setModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateSearchValue = (change) => {
    const tempData = filterDataOnSearch(data, change, headData);

    if (change) {
      setBodyData(tempData);
    } else {
      setBodyData(data);
    }
  };

  let dataToUse = bodyData;

  const { sortedItems, sort, decideSortIcon } = useSort(bodyData, headData);

  dataToUse = sortedItems;

  return (
    <Flex flexDirection={"column"}>
      <Navigation />
      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.users}`}
          updateSearchValue={updateSearchValue}
          showSearch={true}
        />
        <TableWrapper>
          <Layout
            style={{
              borderRadius: 10,
              overflow: "hidden",
              height: "max-content",
              width: "100%",
            }}
          >
            <UsersSettings
              key={"new-item"}
              data={dataToUse}
              titles={titles}
              staticOptions={staticOptions}
              roles={roles}
              newItemModalVisible={newItemModalVisible}
              showModal={showModal}
              updateData={updateData}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
            />
            <Layout className="site-layout">
              <Content
                style={{
                  paddingBottom: 15,
                  paddingLeft: 20,
                  paddingRight: 20,
                  minHeight: "max-content",
                  backgroundColor: "transparent",
                }}
              >
                <TableContainer
                  component={Paper}
                  className="testing_table"
                  style={{ maxHeight: "70vh" }}
                >
                  {isLoading ? (
                    <Card
                      style={{
                        borderRadius: "10px",
                        marginBottom: "10px",
                        width: "98.5vw",
                        display: "flex",
                        justifyContent: "center",
                        height: "85vh",
                      }}
                    >
                      <Spin size="large" style={{ marginTop: "15rem" }} />
                    </Card>
                  ) : (
                    <Table stickyHeader>
                      <UsersHead
                        headData={headData}
                        sort={sort}
                        decideSortIcon={decideSortIcon}
                      />
                      <UsersBody
                        key={`body`}
                        data={dataToUse}
                        titles={titles}
                        staticOptions={staticOptions}
                        roles={roles}
                        modalVisible={modalVisible}
                        modalData={modalData}
                        showModal={showModal}
                        updateData={updateData}
                        handleCancel={handleCancel}
                        showDeleteModal={showDeleteModal}
                        resetPassword={resetPassword}
                      />
                    </Table>
                  )}
                </TableContainer>
              </Content>
            </Layout>
          </Layout>
        </TableWrapper>
      </BackgroundWrapper>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          selectedItems={[itemToDelete.id]}
          onDelete={handleDelete}
          onCancel={cancelDeleteModal}
        />
      )}
    </Flex>
  );
};

export default Users;
