import Flex from "../../../../../../../helpers/components/Flex";
import { useState } from "react";
import validateTag from "../helpers/functions/validateTag";
import { useAdminStore } from "../../../../../../../store/useStores";
import EditInput from "./editInput/EditInput";
import CustomTag from "./customTag/CustomTag";

const TagDisplay = ({
  tags,
  updateTags,
  functionTags,
  updateEditFunctionTagInfo,
  fieldTags,
  updateEditFieldTagInfo,
  updateShowInputMessage,
  showFieldModal,
  showFunctionModal,
}) => {
  const currentModuleID = useAdminStore((state) => state.moduleID);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    updateTags(newTags);
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);

    const isValid = validateTag(e.target.value);

    if (isValid || !e.target.value) {
      updateShowInputMessage(false);
    } else {
      updateShowInputMessage(true);
    }
  };

  const handleEditInputConfirm = (e) => {
    const isValid = validateTag(e.target.value);
    if (isValid || !e.target.value) {
      const newTags = [...tags];
      newTags[editInputIndex] = editInputValue;
      updateTags(newTags);
      setEditInputIndex(-1);
      setEditInputValue("");
    }
  };

  const handleDoubleClick = (e, tag, index) => {
    if (!tag.includes("[") && !tag.includes("#")) {
      setEditInputIndex(index);
      setEditInputValue(tag);
      e.preventDefault();
    } else {
      if (tag.includes("#")) {
        updateEditFunctionTagInfo({
          functionID: tag,
          index: index,
        });
        showFunctionModal();
      } else if (tag.includes("|")) {
        const str1 = tag.split("[")[1];
        const moduleID = str1.split("|")[0];
        const fieldID = str1.split("|")[1].split("]")[0];
        updateEditFieldTagInfo({
          moduleID: moduleID,
          fieldID: fieldID,
          index: index,
        });
        showFieldModal();
      } else {
        const fieldID = tag.split("[")[1].split("]")[0];
        updateEditFieldTagInfo({
          moduleID: currentModuleID,
          fieldID: fieldID,
          index: index,
        });
        showFieldModal();
      }
    }
  };

  return (
    <Flex flexDirection={"row"} style={{ gap: 0, width: "max-content" }}>
      {tags.map((tag, index) => (
        <Flex key={`${tag}-${index}`} flexDirection={"column"}>
          {editInputIndex === index ? (
            <EditInput
              index={index}
              editInputIndex={editInputIndex}
              editInputValue={editInputValue}
              handleEditInputChange={handleEditInputChange}
              handleEditInputConfirm={handleEditInputConfirm}
            />
          ) : (
            <CustomTag
              tag={tag}
              index={index}
              fieldTags={fieldTags}
              functionTags={functionTags}
              handleDoubleClick={handleDoubleClick}
              handleClose={handleClose}
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default TagDisplay;
