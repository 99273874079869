import Flex from "../../../../../../../../helpers/components/Flex";
import AddMathOperationButton from "./AddMathOperationButton";
import AddFieldButton from "./AddFieldButton";
import AddFunctionButton from "./AddFunctionButton";
import EraseButton from "./EraseButton";
import AddFunctionParameterSeperator from "./AddFunctionParameterSeperator";

const Buttons = ({
  tags,
  updateTags,
  showInput,
  editFieldTagInfo,
  updateEditFieldTagInfo,
  editFunctionTagInfo,
  fieldTags,
  updateFieldTags,
  selectFieldModalVisible,
  showFieldModal,
  cancelFieldModal,
  functionModalVisible,
  showFunctionModal,
  cancelFunctionModal,
  addChange,
  currentFieldID,
  currentModuleID,
}) => {
  return (
    <Flex flexDirection={"column"}>
      <Flex
        flexDirection={"row"}
        style={{
          alignItems: "center",
          marginTop: tags.length === 0 ? -15 : -5,
        }}
      >
        <AddMathOperationButton showInput={showInput} />
        <AddFieldButton
          editFieldTagInfo={editFieldTagInfo}
          updateEditFieldTagInfo={updateEditFieldTagInfo}
          updateTags={updateTags}
          updateFieldTags={updateFieldTags}
          fieldTags={fieldTags}
          tags={tags}
          selectFieldModalVisible={selectFieldModalVisible}
          showFieldModal={showFieldModal}
          cancelFieldModal={cancelFieldModal}
          currentFieldID={currentFieldID}
          currentModuleID={currentModuleID}
        />
      </Flex>
      <Flex
        flexDirection={"row"}
        style={{ marginTop: 5, marginBottom: 10, gap: 10 }}
      >
        <AddFunctionButton
          editFieldTagInfo={editFieldTagInfo}
          updateTags={updateTags}
          tags={tags}
          functionModalVisible={functionModalVisible}
          showFunctionModal={showFunctionModal}
          cancelFunctionModal={cancelFunctionModal}
          editFunctionTagInfo={editFunctionTagInfo}
        />
        <AddFunctionParameterSeperator tags={tags} updateTags={updateTags} />
      </Flex>
      <EraseButton
        updateTags={updateTags}
        updateEditFieldTagInfo={updateEditFieldTagInfo}
        addChange={addChange}
      />
    </Flex>
  );
};

export default Buttons;
