import { Input } from "antd";

const DependentText = ({ value, id, fieldID, title, addChange }) => {
  let newValue = value && value[fieldID];
  if (value && fieldID in value && value[fieldID].includes("&#39;")) {
    let splitValue = value[fieldID]
      .split("&#39;")
      .filter((item) => item !== "")
      .join("'");
    newValue = splitValue;
    if (splitValue && splitValue.includes("&#10;")) {
      let newSplitValue = value[fieldID]
        .split("&#10;")
        .filter((item) => item !== "");
      let str = "";
      newSplitValue.forEach((item) => {
        if (str !== "") {
          str += "\n";
        }
        str += item;
      });
      newValue = str;
    }
  } else if (value && fieldID in value && value[fieldID].includes("&#10;")) {
    let newSplitValue = value[fieldID]
      .split("&#10;")
      .filter((item) => item !== "");
    let str = "";
    newSplitValue.forEach((item) => {
      if (str !== "") {
        str += "\n";
      }
      str += item;
    });
    newValue = str;
  }

  return (
    <Input
      type="text"
      className="edit-input"
      placeholder={title}
      onChange={(e) => addChange(id, { [fieldID]: e.target.value }, null)}
      defaultValue={newValue ?? ""}
      style={{ paddingLeft: 10, height: 33, width: 278 }}
    />
  );
};

export default DependentText;
