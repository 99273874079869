import "./adminMenu.css";
import { Layout, Spin, Card } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MenuTableSettings from "./AdminMenuSettings";
import AdminMenuHead from "./AdminMenuHead";
import AdminMenuBody from "./AdminMenuBody";
import apiGetMenuList from "../../../api/endpoints/apiGetMenuList";
import {
  useAuthStore,
  useCommonTitlesStore,
  useMenuStore,
  useModTitlesStore,
} from "../../../store/useStores";
import Navigation from "../../navigation/Navigation";
import Flex from "../../../helpers/components/Flex";
import filterDataOnSearch from "../helpers/filterDataOnSearch";
import axios from "axios";
import { DeleteModal } from "./deleteModals";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import BackgroundWrapper from "../../../helpers/components/BackgroundWrapper";
import AdministrationBanner from "../administrationBanner/AdministrationBanner";
import TableWrapper from "../../../helpers/components/TableWrapper";

const { Content } = Layout;

const AdminMenu = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const menu = useMenuStore((state) => state.menu);
  const setMenu = useMenuStore((state) => state.setMenu);

  const [data, setData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [allMenuData, setAllMenuData] = useState([]);
  const [titles, setTitles] = useState({});
  const [headers, setHeaders] = useState([]);
  const [types, setTypes] = useState([]);
  const [modules, setModules] = useState({});

  const [selection, setSelection] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({});

  const fetchData = async () => {
    setIsLoading(true);
    const serverData = await apiGetMenuList(baseURL);

    const initialSelection = serverData?.menu.filter(
      (item) => item.ME_PARENTID === null && item.ME_VISIBLE === "Y"
    )[0].ME_ID;
    setSelection(initialSelection);
    setAllMenuData(serverData.menu);
    setData(serverData.menu);
    setBodyData(serverData.menu);
    setTitles(serverData.moduletitles);
    setModules(serverData.modules);
    setHeaders([
      serverData.moduletitles["FD|17|TITLE"],
      serverData.moduletitles["FD|19|TITLE"],
      serverData.moduletitles["FD|20|TITLE"],
    ]);
    setTypes(serverData.menu.filter((item) => item.ME_PARENTID === null));
    setIsLoading(false);
  };

  useEffect(() => {
    if (data?.length) {
      const dataToUse = data.filter((datum) => datum.ME_PARENTID === selection);

      // dataToUse.sort((a, b) =>
      //   a.T_TITLE > b.T_TITLE ? 1 : b.T_TITLE > a.T_TITLE ? -1 : 0
      // );

      setBodyData(dataToUse);
    }
    setIsLoading(false);
  }, [data, selection]);

  useEffect(() => {
    fetchData();
  }, [projectID, language]);

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const updateData = (config, changes, index) => {
    if (config === "edit") {
      setModalVisible(false);
      const tempBodyData = bodyData.map((item) => {
        if (item.ME_ID === changes.ME_ID) {
          return { ...item, ...changes };
        } else {
          return item;
        }
      });
      setBodyData(tempBodyData);

      const tempData = data.map((item) => {
        if (item.ME_ID === changes.ME_ID) {
          return { ...item, ...changes };
        } else {
          return item;
        }
      });

      setData(tempData);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
      setBodyData([...bodyData, changes]);
      setData([...data, changes]);
    }
  };

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
    }
  };

  const showDeleteModal = (id, parentID) => {
    setDeleteModalVisible(true);
    setItemToDelete({ id: id, parentID: parentID });
  };
  const cancelDeleteModal = () => setDeleteModalVisible(false);

  const handleDelete = async (id) => {
    const url = `${baseURL}/App_menu_api/delete`;

    try {
      const response = await axios.post(
        url,
        {
          id: id,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_menu_api/delete: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        setBodyData(bodyData.filter((item) => item.ME_ID !== id));
        setData(data.filter((item) => item.ME_ID !== id));

        const tempMenu = menu.map((item) => {
          if (item.ME_ID === itemToDelete.parentID) {
            const idxObj = item.children.findIndex((object) => {
              return object.ME_ID === id;
            });
            item.children.splice(idxObj, 1);
            return item;
          } else {
            return item;
          }
        });
        setMenu(tempMenu);
        setDeleteModalVisible(false);
        setModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateSearchValue = (change) => {
    let tempData = [];

    tempData = filterDataOnSearch(data, change, headers);

    if (change) {
      setBodyData(tempData);
    } else {
      const dataToUse = data.filter((datum) => datum.ME_PARENTID === selection);
      setBodyData(dataToUse);
    }
  };

  const onSort = async (children) => {
    const newIndex = children.newIndex;
    const oldIndex = children.oldIndex;

    const tempBodyData = [];

    bodyData
      .filter((item) => item.ME_PARENTID === selection)
      .forEach((item, index) => {
        if (newIndex > oldIndex) {
          if (index < oldIndex || index > newIndex) {
            tempBodyData[index] = item;
          } else if (index > oldIndex && index <= newIndex) {
            tempBodyData[index - 1] = item;
          } else if (index === oldIndex) {
            tempBodyData[newIndex] = item;
          }
        } else if (oldIndex === newIndex) {
          tempBodyData[index] = item;
        } else {
          if (index < newIndex || index > oldIndex) {
            tempBodyData[index] = item;
          } else if (index >= newIndex && index < oldIndex) {
            tempBodyData[index + 1] = item;
          } else if (index === oldIndex) {
            tempBodyData[newIndex] = item;
          }
        }
      });

    setBodyData(tempBodyData);

    const url = `${baseURL}/App_menu_api/updateorder`;
    const menuIds = tempBodyData.map((item) => item.ME_ID);

    try {
      const response = await axios.post(
        url,
        {
          menuids: menuIds,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_menu_api/updateorder: response: ", response?.data);
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <Flex flexDirection={"column"}>
      <Navigation />
      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.menu}`}
          updateSearchValue={updateSearchValue}
          showSearch={true}
        />
        <TableWrapper>
          {isLoading ? (
            <Card
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
                width: "98.5vw",
                display: "flex",
                justifyContent: "center",
                height: "85vh",
              }}
            >
              <Spin size="large" style={{ marginTop: "15rem" }} />
            </Card>
          ) : (
            <Layout
              style={{
                borderRadius: 10,
                overflow: "hidden",
                height: "max-content",
                width: "100%",
              }}
            >
              <MenuTableSettings
                key={"new-item"}
                selection={selection}
                setSelection={setSelection}
                types={types}
                titles={titles}
                bodyData={bodyData}
                modules={modules}
                newItemModalVisible={newItemModalVisible}
                showModal={showModal}
                updateData={updateData}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
              />
              {selection && (
                <Layout className="site-layout">
                  <Content
                    style={{
                      paddingBottom: 15,
                      paddingLeft: 20,
                      paddingRight: 20,
                      minHeight: "max-content",
                      backgroundColor: "transparent",
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      className="testing_table"
                      style={{ maxHeight: "70vh" }}
                    >
                      <Table stickyHeader>
                        <AdminMenuHead headers={headers} />
                        <AdminMenuBody
                          key={selection}
                          data={bodyData.filter(
                            (datum) => datum.ME_PARENTID === selection
                          )}
                          headers={headers}
                          types={types}
                          titles={titles}
                          modules={modules}
                          modalVisible={modalVisible}
                          modalData={modalData}
                          showModal={showModal}
                          updateData={updateData}
                          handleDelete={handleDelete}
                          showDeleteModal={showDeleteModal}
                          handleCancel={handleCancel}
                          onSort={onSort}
                        />
                      </Table>
                    </TableContainer>
                  </Content>
                </Layout>
              )}
            </Layout>
          )}
        </TableWrapper>
      </BackgroundWrapper>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          selectedItems={[itemToDelete.id]}
          onDelete={handleDelete}
          onCancel={cancelDeleteModal}
        />
      )}
    </Flex>
  );
};

export default AdminMenu;
