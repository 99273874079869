import { Image } from "antd";
import { useAuthStore, useMenuStore } from "../../../store/useStores";
import { useState, useEffect } from "react";
import Flex from "../../../helpers/components/Flex";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";

const ProjectLogo = () => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const role = useAuthStore((state) => state.role);
  const projects = useMenuStore((state) => state.projects);
  const { width } = useWindowDimensions();
  const [logoUrlSuffix, setLogoUrlSuffix] = useState("");

  useEffect(() => {
    if (projects.length) {
      const currentProject =
        projects.filter((project) => project?.CURRENT === "Y")[0] ?? null;
      const tempCurrentProject = projects[0];

      if (currentProject) {
        const urlSuffix = currentProject?.LOGO ?? "";
        setLogoUrlSuffix(urlSuffix);
      } else {
        const urlSuffix = tempCurrentProject?.LOGO ?? "";
        setLogoUrlSuffix(urlSuffix);
      }
    }
  }, [projects]);

  let showLogo = true;

  if (role === "A") {
    if (width < 1500) {
      showLogo = false;
    }
  } else {
    if (width < 1150) {
      showLogo = false;
    }
  }

  if (!logoUrlSuffix) {
    return null;
  }

  return (
    <Flex
      style={{
        marginRight: 75,
        position: "fixed",
        left: "43.5vw",
        display: !showLogo && "none",
      }}
    >
      <Image
        width={"fit-content"}
        height={"fit-content"}
        style={{
          maxHeight: 50,
          marginLeft: 15,
          marginRight: 30,
          marginTop: -5,
        }}
        src={`${baseURL}/attachments/${logoUrlSuffix}`}
        alt={"Company's logo"}
        preview={false}
      />
    </Flex>
  );
};

export default ProjectLogo;
