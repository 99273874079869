import { useAuthStore } from "../../store/useStores";
import DarkBackground from "../../../src/assets/curved.jpg";
import LightBackground from "../../../src/assets/white-background.jpg";
import Flex from "./Flex";

const BackgroundWrapper = ({ children }) => {
  const darkTheme = useAuthStore((state) => state.darkTheme);

  const style = {
    backgroundImage: darkTheme
      ? `url(${DarkBackground})`
      : `url(${LightBackground})`,
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "repeat",
    paddingBottom: "20px",
    overflowY: "hidden",
  };

  return (
    <Flex key={darkTheme} flexDirection={"column"} style={style}>
      {children}
    </Flex>
  );
};

export default BackgroundWrapper;
