import { PlusOutlined } from "@ant-design/icons";
import { useCommonTitlesStore } from "../../../../../../../../store/useStores";
import { Button } from "antd";
import { addFieldButtonStyle } from "../../styles/TagItemStyles";

const AddFunctionParameterSeperator = ({ tags, updateTags }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const handleChange = () => {
    let newTags = [...tags];
    newTags.push(",");
    updateTags(newTags);
  };

  return (
    <Button
      icon={
        <PlusOutlined style={{ fontSize: 10 }} onClick={() => handleChange()} />
      }
      style={addFieldButtonStyle}
      onClick={() => handleChange()}
    >
      {commonTitles["CO|1|ADDPARASEP"]}
    </Button>
  );
};

export default AddFunctionParameterSeperator;
