import "./userRoles.css";
import { Layout, Card, Spin, message } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import UserRolesSettings from "./UserRolesSettings";
import UserRolesHead from "./UserRolesHead";
import UserRolesBody from "./UserRolesBody";
import apiGetUserRolesList from "../../../api/endpoints/apiGetUserRolesList";
import { useAuthStore, useModTitlesStore } from "../../../store/useStores";
import Navigation from "../../navigation/Navigation";
import Flex from "../../../helpers/components/Flex";
import filterDataOnSearch from "../helpers/filterDataOnSearch";
import axios from "axios";
import BackgroundWrapper from "../../../helpers/components/BackgroundWrapper";
import TableWrapper from "../../../helpers/components/TableWrapper";
import AdministrationBanner from "../administrationBanner/AdministrationBanner";

const { Content } = Layout;

const UserRoles = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);

  const [data, setData] = useState({});
  const [bodyData, setBodyData] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [fetchAgain, setFetchAgain] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const serverData = await apiGetUserRolesList(baseURL);
    setData(serverData);
  };

  useEffect(() => {
    fetchData();
  }, [projectID, language, fetchAgain]);

  useEffect(() => {
    setBodyData(roles);
    setIsLoading(false);
  }, [data]);

  const roles = data?.roles ?? [];
  roles.sort((a, b) =>
    a.T_TITLE > b.T_TITLE ? 1 : b.T_TITLE > a.T_TITLE ? -1 : 0
  );
  const titles = data?.moduletitles ?? {};

  const headers = [titles["FD|250|TITLE"]];

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const fetchDataAgain = () => {
    setFetchAgain(!fetchAgain);
  };

  const handleOk = async (config, changes, id, lastModified) => {
    if (config === "edit") {
      const url = `${baseURL}/User_roles_api/save`;

      try {
        const response = await axios.post(
          url,
          { ...changes, id: id, lastmodified: lastModified },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("User Roles: onSave: response: ", response.data);

        if (response.data.STATUS === "Success") {
          fetchDataAgain();
          setModalVisible(false);
        } else {
          message(response.data.MESSAGE, [3]);
        }
      } catch (err) {}
    }

    if (config === "new") {
      const url = `${baseURL}/User_roles_api/save`;

      try {
        const response = await axios.post(
          url,
          { ...changes },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("User Roles: onSave: response: ", response.data);

        if (response.data.STATUS === "Success") {
          fetchDataAgain();
          setNewItemModalVisible(false);
        } else {
          message(response.data.MESSAGE, [3]);
        }
      } catch (err) {}
    }
  };

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      if (config === "edit") {
        setModalVisible(false);
      }

      if (config === "new") {
        setNewItemModalVisible(false);
      }
    }
  };

  const handleDelete = async (id) => {
    const url = `${baseURL}/User_roles_api/delete`;

    try {
      const response = await axios.post(
        url,
        { id: id },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("User Roles: onDelete: response: ", response.data);

      if (response.data.STATUS === "Success") {
        fetchDataAgain();
        setModalVisible(false);
      } else {
        message(response.data.MESSAGE, [3]);
      }
    } catch (err) {}
  };

  const updateSearchValue = (change) => {
    const tempData = filterDataOnSearch(roles, change, headers);

    if (change) {
      setBodyData(tempData);
    } else {
      setBodyData(roles);
    }
  };

  return (
    <Flex flexDirection={"column"}>
      <Navigation />
      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.userRoles}`}
          updateSearchValue={updateSearchValue}
          showSearch={true}
        />
        <TableWrapper>
          <Layout
            style={{
              borderRadius: 10,
              overflow: "hidden",
              height: "max-content",
              width: "100%",
            }}
          >
            <UserRolesSettings
              key={"new-item"}
              headers={headers}
              titles={titles}
              newItemModalVisible={newItemModalVisible}
              showModal={showModal}
              handleOk={handleOk}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
            />
            <Layout className="site-layout">
              <Content
                style={{
                  paddingBottom: 15,
                  paddingLeft: 20,
                  paddingRight: 20,
                  minHeight: "max-content",
                  backgroundColor: "transparent",
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "70vh" }}
                  className="testing_table"
                >
                  {isLoading ? (
                    <Card
                      style={{
                        borderRadius: "10px",
                        marginBottom: "10px",
                        width: "98.5vw",
                        display: "flex",
                        justifyContent: "center",
                        height: "85vh",
                      }}
                    >
                      <Spin size="large" style={{ marginTop: "15rem" }} />
                    </Card>
                  ) : (
                    <Table stickyHeader>
                      <UserRolesHead headers={headers} titles={titles} />
                      <UserRolesBody
                        key={`body`}
                        data={bodyData}
                        headers={headers}
                        titles={titles}
                        modalVisible={modalVisible}
                        modalData={modalData}
                        showModal={showModal}
                        handleOk={handleOk}
                        handleDelete={handleDelete}
                        handleCancel={handleCancel}
                        fetchDataAgain={fetchDataAgain}
                      />
                    </Table>
                  )}
                </TableContainer>
              </Content>
            </Layout>
          </Layout>
        </TableWrapper>
      </BackgroundWrapper>
    </Flex>
  );
};

export default UserRoles;
