import "./css/emailConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import EmailConfigModal from "./EmailConfigModal";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import axios from "axios";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import { useState } from "react";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";
import FiltersModal from "../../ultimateTable/helpers/modals/filtersModal/FiltersModal";
import apiGetData from "../../../api/endpoints/apiGetData";
import choosePeriodicityDisplay from "./helpers/choosePeriodicityDisplay";
import chooseEventDisplay from "./helpers/chooseEventDisplay";
import chooseTypeDisplay from "./helpers/chooseTypeDisplay";
import chooseSentDateDisplay from "./helpers/chooseSentDateDisplay";

const EmailConfigBody = ({
  data = [],
  modules = {},
  titles = {},
  headers = {},
  showModal,
  modalVisible,
  modalData,
  updateData,
  handleCancel,
  showDeleteModal,
  fields = {},
  reverseFiltersChanged,
  notifyFields = {},
  templates = {},
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);
  const [filtersModalVisible, setFiltersModalVisible] = useState(false);
  const [filtersModalData, setFiltersModalData] = useState({});
  const [notificationID, setNotificationID] = useState(null);

  const showFiltersModal = async (id) => {
    setNotificationID(id);

    const moduleID = data.filter((item) => item.N_ID === id)?.[0]?.N_MODULEID;
    const filters =
      data.filter((item) => item.N_ID === id)?.[0]?.N_FILTER ?? {};

    const {
      fields,
      staticoptions,
      fkvalues,
      candelete,
      caninsert,
      canupdate,
      canview,
    } = await apiGetData(
      moduleID,
      baseURL,
      Object.keys(filters)?.length ? filters : {},
      "",
      "",
      50,
      1,
      true
    );

    let tempColumnData = [];
    tempColumnData =
      fields
        ?.filter((field) => field.F_LIST === "Y")
        .map((field) => ({
          ...field,
          T_TITLE: htmlCodesRemover(field.T_TITLE) ?? field.TITLE,
        })) ?? [];

    let hasAnyUpdatePrivileges = false;
    let hasAnyInsertPrivileges = false;
    fields?.forEach((field) => {
      if (field?.canupdate || field?.canupdateifnull) {
        hasAnyUpdatePrivileges = true;
      }
      if (field?.caninsert) {
        hasAnyInsertPrivileges = true;
      }
    });

    const tempFiltersModalData = {
      columns: tempColumnData,
      privileges: {
        canDelete: candelete,
        canInsert: caninsert || hasAnyInsertPrivileges ? true : false,
        canCopy: caninsert,
        canUpdate: canupdate || hasAnyUpdatePrivileges ? true : false,
        canView: canview,
      },
      foreignKeyValues: fkvalues,
      staticOptions: staticoptions,
      filters: filters,
    };

    setFiltersModalData(tempFiltersModalData);
    setFiltersModalVisible(true);
  };

  const applyFilters = (changes) => {
    const currentData = data.filter(
      (item) => item.N_ID === notificationID
    )?.[0];

    const changesForServer = {
      ...currentData,
      NOTIFICATIONTEXT: currentData.T_TITLE,
      INFORMATIONFIELDS: currentData.N_INFORMATION_FIELDIDS ?? "",
    };

    const notificationData = {
      ...changesForServer,
      N_FILTER: { ...changes },
    };

    onSave(notificationData);
    reverseFiltersChanged();
    setFiltersModalVisible(false);
  };

  const cancelFiltersModal = () => {
    setFiltersModalVisible(false);
  };

  const eraseFilters = () => {
    const currentData = data.filter(
      (item) => item.N_ID === notificationID
    )?.[0];

    const changesForServer = {
      ...currentData,
      NOTIFICATIONTEXT: currentData.T_TITLE,
      INFORMATIONFIELDS: currentData.N_INFORMATION_FIELDIDS ?? "",
    };

    const notificationData = {
      ...changesForServer,
      A_FILTER: {},
    };

    onSave(notificationData);
    reverseFiltersChanged();
    setFiltersModalVisible(false);
  };

  const onSave = async (changes) => {
    const url = `${baseURL}/app_notifications_api/save`;

    const changesForServer = {
      ...modalData,
      ...changes,
      NOTIFICATIONTEXT: changes.NOTIFICATIONTEXT ?? modalData.T_TITLE,
      INFORMATIONFIELDS: changes.INFORMATIONFIELDS
        ? changes.INFORMATIONFIELDS
        : changes.INFORMATIONFIELDS === ""
        ? changes.INFORMATIONFIELDS
        : modalData.N_INFORMATION_FIELDIDS,
    };

    try {
      const response = await axios.post(
        url,
        { ...changesForServer },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("app_notifications_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");

        let infoFieldTitlesValue = "";
        let infoFieldsValue = [];

        if (Object.keys(changes).includes("INFORMATIONFIELDS")) {
          infoFieldsValue = changes.INFORMATIONFIELDS.split(",").map(
            (item) => item
          );
          let str = "";
          infoFieldsValue.map((item) => {
            if (str !== "") {
              str += ", ";
            }
            str +=
              fields[changes.N_MODULEID]?.filter(
                (field) => field.F_ID === item
              )[0]?.T_TITLE ?? "";
          });
          infoFieldTitlesValue += str;
        }

        let triggerFieldTitlesValue = "";

        if (changes.N_TRIGGER_FIELDID) {
          triggerFieldTitlesValue = fields[changes?.N_MODULEID].filter(
            (field) => field.F_ID === changes.N_TRIGGER_FIELDID
          )[0]?.T_TITLE;
        }

        let changesWithExtraInfo = {};

        if (Object.keys(changes).includes("INFORMATIONFIELDS")) {
          changesWithExtraInfo = {
            INFOFIELDTITLES: infoFieldTitlesValue,
            INFORMATIONFIELDS: infoFieldsValue,
            N_INFORMATION_FIELDIDS: changes.INFORMATIONFIELDS,
          };
        }

        if (Object.keys(changes).includes("N_TRIGGER_FIELDID")) {
          changesWithExtraInfo = {
            ...changesWithExtraInfo,
            TRIGGERFIELDTITLE: triggerFieldTitlesValue,
          };
        }

        changesWithExtraInfo = {
          ["N_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          ...modalData,
          ...changes,
          ...changesWithExtraInfo,
          T_TITLE: changes?.NOTIFICATIONTEXT
            ? changes?.NOTIFICATIONTEXT
            : modalData.T_TITLE,
        };
        updateData("edit", changesWithExtraInfo, modalData.N_ID);
        setCoreChange(!coreChange);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        displayResponseMessage(responseMsg, 5, "warning");
        //message.warning(responseMsg, [5]);
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  let notifyFieldTitles = {};

  data.forEach((item) => {
    let str = "";
    if (item.N_NOTIFY_FIELD?.length) {
      item.N_NOTIFY_FIELD.forEach((field) => {
        const fieldToAdd = notifyFields?.[item?.N_MODULEID]?.filter(
          (option) => option.ID === field
        )?.[0]?.T_TITLE;

        if (fieldToAdd) {
          if (str !== "") {
            str += ", ";
          }
          str += fieldToAdd;
        }
      });
    }

    notifyFieldTitles = { ...notifyFieldTitles, [item.N_ID]: str };
  });

  return (
    <TableBody>
      {data.map((item) => (
        <TableRow key={item.N_ID}>
          <TableCell>
            <Tooltip placement="right" title={titles["MO|20|EDITROW"]}>
              <EditOutlined
                onClick={() => showModal(item, "edit")}
                style={{
                  fontSize: "18px",
                  color: "rgb(8, 8, 46)",
                }}
              />
            </Tooltip>
            {modalVisible && (
              <EmailConfigModal
                key={`${item.N_ID}-modal`}
                visible={modalVisible}
                data={modalData}
                headers={headers}
                titles={titles}
                modules={modules}
                onSave={onSave}
                showDeleteModal={showDeleteModal}
                onCancel={handleCancel}
                fields={fields}
                config={"edit"}
                notifyFields={notifyFields}
                templates={templates}
              />
            )}
          </TableCell>
          <TableCell key={`${item.N_ID}-module`}>
            {htmlCodesRemover(
              Object.entries(modules).filter(
                ([key, value]) => key === item?.N_MODULEID
              )[0]?.[1]
            )}
          </TableCell>
          <TableCell key={`${item.N_ID}-notifyType`}>
            {chooseTypeDisplay(item.N_TYPE, titles)}
          </TableCell>
          <TableCell key={`${item.N_ID}-trigger`}>
            {item?.TRIGGERFIELDTITLE
              ? htmlCodesRemover(item.TRIGGERFIELDTITLE)
              : "-"}
          </TableCell>
          <TableCell align="center" key={`${item.N_ID}-before`}>
            {item?.N_TYPE === "E" ? "-" : item.N_WARNING_BEFORE ?? "0"}
          </TableCell>
          <TableCell align="center" key={`${item.N_ID}-after`}>
            {item?.N_TYPE === "E" ? "-" : item.N_RAISED_AFTER ?? "0"}
          </TableCell>
          <TableCell align="center" key={`${item.N_ID}-periodNum`}>
            {choosePeriodicityDisplay(
              item.N_PERIOD_NUM,
              item.N_PERIOD_TYPE,
              titles,
              commonTitles,
              item.N_TYPE
            )}
          </TableCell>
          <TableCell align="left" key={`${item.N_ID}-sentDate`}>
            {!item?.N_PERIOD_TYPE
              ? "-"
              : item?.N_PERIOD_TYPE === "W"
              ? chooseSentDateDisplay(
                  item?.N_PERIOD_TYPE,
                  item?.N_WEEK_DAY,
                  titles
                )
              : item?.N_PERIOD_TYPE === "M"
              ? chooseSentDateDisplay(
                  item?.N_PERIOD_TYPE,
                  item?.N_MONTH_DAY,
                  titles
                )
              : "-"}
          </TableCell>
          <TableCell key={`${item.N_ID}-event`}>
            {chooseEventDisplay(item?.N_EVENT ?? "", titles)}
          </TableCell>
          <TableCell key={`${item.N_ID}-notifyField`}>
            {notifyFieldTitles?.[item.N_ID] ?? ""}
          </TableCell>
          <TableCell key={`${item.N_ID}-emails`}>
            {item.N_NOTIFY_EMAILS}
          </TableCell>
          <TableCell key={`${item.N_ID}-template`}>
            {templates?.[item.N_TEMPLATEID] ?? ""}
          </TableCell>
          {/* <TableCell align="center" key={`${item.N_ID}-expiration`}>
            {item.N_EXPIREINDICATION === "Y" ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </TableCell> */}
          <TableCell key={`${item.N_ID}-notificationText`}>
            {htmlCodesRemover(item.T_TITLE)}
          </TableCell>
          <TableCell key={`${item.N_ID}-contact`}>{item.N_CONTACT}</TableCell>
          <TableCell key={`${item.N_ID}-infofield`}>
            {htmlCodesRemover(item.INFOFIELDTITLES)}
          </TableCell>
          <TableCell align="center" key={`${item.N_ID}-status`}>
            {item.N_ACTIVE === "Y" ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </TableCell>
          <TableCell key={`${item.N_ID}-filters`} align="center">
            <Button
              shape="round"
              className="dropdown"
              onClick={() => {
                showFiltersModal(item.N_ID);
              }}
              style={{
                backgroundColor: "rgb(8, 8, 46)",
                color: "#fff",
                marginLeft: 20,
              }}
            >
              {titles["FD|372|TITLE"]} (
              {Object?.keys(item?.N_FILTER ?? {}).length})
            </Button>
          </TableCell>
        </TableRow>
      ))}
      {filtersModalVisible && (
        <FiltersModal
          visible={filtersModalVisible}
          columns={filtersModalData.columns}
          staticOptions={filtersModalData.staticOptions}
          foreignKeyValues={filtersModalData.foreignKeyValues}
          onSave={applyFilters}
          onCancel={cancelFiltersModal}
          onErase={eraseFilters}
          filters={filtersModalData.filters}
          privileges={filtersModalData.privileges}
        />
      )}
    </TableBody>
  );
};

export default EmailConfigBody;
