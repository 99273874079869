import ModalFooterInfo from "./ModalFooterInfo";
import ModalFooterButtons from "./ModalFooterButtons";

const ModalFooter = ({
  data,
  changes,
  config,
  onSave,
  onCancel,
  showDeleteModal,
}) => {
  return [
    <ModalFooterInfo key={"info"} />,
    <ModalFooterButtons
      key={"buttons"}
      data={data}
      changes={changes}
      config={config}
      onSave={onSave}
      onCancel={onCancel}
      showDeleteModal={showDeleteModal}
    />,
  ];
};

export default ModalFooter;
