import "./adminTitles.css";
import { Layout, Spin, Card, Button, message } from "antd";
import { useEffect, useState } from "react";
import AdminTitlesSettings from "./AdminTitlesSettings";
import apiGetSystemTitles from "../../../api/endpoints/apiGetSystemTitles";
import {
  useAuthStore,
  useCommonTitlesStore,
  useModTitlesStore,
} from "../../../store/useStores";
import Navigation from "../../navigation/Navigation";
import Flex from "../../../helpers/components/Flex";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdminTitlesContent from "./AdminTitlesContent";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import BackgroundWrapper from "../../../helpers/components/BackgroundWrapper";
import TableWrapper from "../../../helpers/components/TableWrapper";
import AdministrationBanner from "../administrationBanner/AdministrationBanner";

const AdminTitles = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [selections, setSelections] = useState([]);
  const [changes, setChanges] = useState({});
  const [selection, setSelection] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  const fetchData = async (refType, refID) => {
    setIsLoading(true);
    const serverData = await apiGetSystemTitles(
      baseURL,
      language,
      refType,
      refID
    );

    const initialSelection = selection
      ? selection
      : serverData?.selections.filter((item) => item.SELID === "CO")[0].SELID;
    setSelection(initialSelection);
    setSelections(serverData.selections);
    setIsLoading(false);
  };

  useEffect(() => {
    if (selection === "CO") {
      fetchData("CO", "");
      setChanges({
        system: "1",
        reftype: "CO",
        refid: "",
        lang: language,
        titles: [],
      });
    } else if (selection === "ME") {
      fetchData("ME", "");
      setChanges({
        system: "1",
        reftype: "ME",
        refid: "",
        lang: language,
        titles: [],
      });
    } else {
      fetchData("MO", selection);
      setChanges({
        system: "1",
        reftype: "MO",
        refid: selection,
        lang: language,
        titles: [],
      });
    }
    setIsLoading(false);
  }, [selection]);

  useEffect(() => {
    if (selection === "") {
      fetchData("CO", "");
      setChanges({
        system: "1",
        reftype: "CO",
        refid: "",
        lang: language,
        titles: [],
      });
    }
  }, [projectID, language]);

  const addChange = (id, value) => {
    const exisitingIDs = changes.titles.map((item) => item.T1_ID);
    let temp = changes.titles;

    if (!exisitingIDs.includes(id)) {
      temp.push(value);
    } else {
      temp = changes.titles.map((item) => {
        if (item.T1_ID === id) {
          return value;
        } else {
          return item;
        }
      });
    }

    temp = temp.filter((item) => item);

    setChanges({ ...changes, titles: temp });
  };

  const changeSelection = (value) => setSelection(value);

  const onSave = async (changes) => {
    const url = `${baseURL}/App_titles2_api/save`;
    try {
      const response = await axios.post(
        url,
        { ...changes },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      console.log("App_titles2_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|SUCDLTMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|SUCDLTMSG1"], 3, "success");
        if (selection === "CO") {
          updateChanges({
            system: "1",
            reftype: "CO",
            refid: "",
            lang: language,
            titles: [],
          });
        } else if (selection === "ME") {
          updateChanges({
            system: "1",
            reftype: "ME",
            refid: "",
            lang: language,
            titles: [],
          });
        } else {
          updateChanges({
            system: "1",
            reftype: "MO",
            refid: selection,
            lang: language,
            titles: [],
          });
        }
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [3]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateChanges = (value) => setChanges(value);

  return (
    <Flex flexDirection={"column"}>
      <Navigation />
      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.systemTitles}`}
          showSearch={false}
        />
        <TableWrapper>
          {isLoading ? (
            <Card
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
                width: "98.5vw",
                display: "flex",
                justifyContent: "center",
                height: "85vh",
              }}
            >
              <Spin size="large" style={{ marginTop: "15rem" }} />
            </Card>
          ) : (
            <Layout
              style={{
                borderRadius: 10,
                overflow: "hidden",
                height: "max-content",
                width: "100%",
                marginBottom: "1rem",
              }}
            >
              <AdminTitlesSettings
                key={"selection"}
                selection={selection}
                changeSelection={changeSelection}
                selections={selections}
              />
              {selection && (
                <AdminTitlesContent
                  key={selection}
                  selection={selection}
                  changeSelection={changeSelection}
                  changes={changes}
                  updateChanges={updateChanges}
                  addChange={addChange}
                />
              )}
              <Flex
                style={{
                  justifyContent: "center",
                  marginBottom: "0.5rem",
                  gap: "1rem",
                }}
                flexDirection={"row"}
              >
                <Button
                  style={{
                    maxWidth: "max-content",
                  }}
                  onClick={() => navigate(-1)}
                >
                  {commonTitles["CO|1|CANCELBTN"]}
                </Button>
                <Button
                  style={{
                    maxWidth: "max-content",
                    backgroundColor: "rgb(8, 8, 46)",
                    color: "white",
                  }}
                  onClick={() => onSave(changes)}
                >
                  {commonTitles["CO|1|SAVEBTN"]}
                </Button>
              </Flex>
            </Layout>
          )}
        </TableWrapper>
      </BackgroundWrapper>
    </Flex>
  );
};

export default AdminTitles;
