import Navbar from "../../navbar/Navbar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { useEffect, useRef, useState } from "react";
import "./changePassword.css";
import { Button, message, Input } from "antd";
import { useNavigate } from "react-router-dom";
import apiGetProfileTitles from "../../../../api/endpoints/apiGetProfileTitles";
import {
  useAuthStore,
  useCommonTitlesStore,
  useAlertStore,
  useMenuStore,
  useFilterStore,
  useModTitlesStore,
} from "../../../../store/useStores";
import apiChangePassword from "../../../../api/endpoints/apiChangePassword";
import Flex from "../../../../helpers/components/Flex";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";

const Profile = () => {
  const [profileTitles, setProfileTitles] = useState();
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const baseURL = useAuthStore((state) => state.baseURL);
  const setLoginStatus = useAuthStore((state) => state.setLoginStatus);

  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const password = useAuthStore((state) => state.password);
  const setPassword = useAuthStore((state) => state.setPassword);
  const setWorkspaceID = useAuthStore((state) => state.setWorkspaceID);
  const setUsername = useAuthStore((state) => state.setUsername);
  const setRole = useAuthStore((state) => state.setRole);
  const setUserID = useAuthStore((state) => state.setUserID);
  const setFirstName = useAuthStore((state) => state.setFirstName);
  const setLastName = useAuthStore((state) => state.setLastName);
  const setDateFormat = useAuthStore((state) => state.setDateFormat);
  const setProjectID = useAuthStore((state) => state.setProjectID);
  const setTimeFormat = useAuthStore((state) => state.setTimeFormat);
  const setAlerts = useAlertStore((state) => state.setAlerts);
  const setAlertTitles = useAlertStore((state) => state.setAlertTitles);
  const setMenu = useMenuStore((state) => state.setMenu);
  const setModTitles = useModTitlesStore((state) => state.setModTitles);
  const setFilters = useFilterStore((state) => state.setFilters);

  const [passwordChanges, setPasswordChanges] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });

  let navigate = useNavigate();

  const handlePasswordChange = async () => {
    if (
      passwordChanges.oldpassword &&
      passwordChanges.newpassword &&
      passwordChanges.confirmpassword
    ) {
      if (passwordChanges.oldpassword === password) {
        if (passwordChanges.newpassword !== password) {
          if (passwordChanges.newpassword === passwordChanges.confirmpassword) {
            const response = await apiChangePassword(
              baseURL,
              passwordChanges,
              commonTitles
            );
            console.log("response: ", response);
            if (response.STATUS === "Success") {
              setPassword(passwordChanges.newpassword);
              setLoginStatus("Success");
              setPasswordChanges({
                oldpassword: "",
                newpassword: "",
                confirmpassword: "",
              });
              //message.success(commonTitles["CO|1|PASSWDSUCC"], [3]);
              displayResponseMessage(
                commonTitles["CO|1|PASSWDSUCC"],
                3,
                "success"
              );
              navigate("/home");
            } else {
              //message.error(commonTitles[response?.MESSAGE], [3]);
              displayResponseMessage(
                commonTitles[response?.MESSAGE],
                3,
                "error"
              );
            }
          } else {
            //message.warning(commonTitles["CO|1|PASSNOMATC"], [3]);
            displayResponseMessage(
              commonTitles["CO|1|PASSNOMATC"],
              3,
              "warning"
            );
          }
        } else {
          //message.warning(commonTitles["CO|1|PASSSELNEW"], [3]);
          displayResponseMessage(commonTitles["CO|1|PASSSELNEW"], 3, "warning");
        }
      } else {
        //message.warning(commonTitles["CO|1|OPWNOMATCH"], [3]);
        displayResponseMessage(commonTitles["CO|1|OPWNOMATCH"], 3, "warning");
      }
    } else {
      let emptyFields = "";
      const tags = {
        oldpassword: commonTitles.oldPwd,
        newpassword: commonTitles.newPwd,
        confirmpassword: commonTitles.confirmPwd,
      };
      Object.entries(passwordChanges).forEach(([key, value]) => {
        if (!value) {
          if (emptyFields !== "") {
            emptyFields += ", ";
          }
          emptyFields += tags[key];
        }
      });

      if (emptyFields.includes(",")) {
        let ucSetMsg2 = commonTitles["CO|1|UCSETMSG2"];
        var re = new RegExp("<1>", "g");
        ucSetMsg2 = ucSetMsg2.replace(re, emptyFields);
        //message.warning(ucSetMsg2, [3]);
        displayResponseMessage(ucSetMsg2, 3, "warning");
      } else {
        let ucSetMsg1 = commonTitles["CO|1|UCSETMSG1"];
        var re = new RegExp("<1>", "g");
        ucSetMsg1 = ucSetMsg1.replace(re, emptyFields);
        //message.warning(ucSetMsg1, [3]);
        displayResponseMessage(ucSetMsg1, 3, "warning");
      }
    }
  };

  useEffect(async () => {
    const profileTitlesData = await apiGetProfileTitles(
      workspaceID,
      language,
      baseURL
    );
    setProfileTitles(profileTitlesData);
  }, [language]);

  const addPasswordChanges = (id, value) => {
    setPasswordChanges({ ...passwordChanges, [id]: value });
  };

  const handleCancel = (e) => {
    //setLanguage("EN");
    setLoginStatus("");
    setFirstName("");
    setLastName("");
    setUsername("");
    setPassword("");
    setRole("");
    setUserID("");
    setWorkspaceID("");
    setProjectID("");
    setDateFormat("");
    setTimeFormat("");
    setAlerts({});
    setAlertTitles({});
    setMenu([]);
    setModTitles({});
    setFilters({});
    navigate("/");
  };

  //console.log("passwordChanges: ", passwordChanges);

  return (
    <div className="gradient__bg">
      <Navbar />
      <div className="profile">
        <Box className="first__box" style={{ marginTop: "-2rem" }}>
          <Box className="profile__box" component="form">
            <div className="profile__box-content">
              <div className="password__change">
                <h2>{commonTitles.pwdChange}</h2>
                <div className="password">
                  <div className="profile__box-firstColumn">
                    <Flex flexDirection={"column"} style={{ gap: "1.7rem" }}>
                      <p>{commonTitles.oldPwd}:</p>
                      <p>{commonTitles.newPwd}:</p>
                      <p>{commonTitles.confirmPwd}:</p>
                    </Flex>
                  </div>
                  <div className="profile__box-secondColumn">
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Input.Password
                        id="password"
                        onChange={(e) => {
                          addPasswordChanges("oldpassword", e.target.value);
                        }}
                        style={{
                          height: 30,
                          paddingLeft: 2,
                          color: "black",
                          backgroundColor: "white",
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Input.Password
                        id="password"
                        onChange={(e) => {
                          addPasswordChanges("newpassword", e.target.value);
                        }}
                        style={{
                          height: 30,
                          paddingLeft: 2,
                          color: "black",
                          backgroundColor: "white",
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Input.Password
                        type="password"
                        onChange={(e) => {
                          addPasswordChanges("confirmpassword", e.target.value);
                        }}
                        sx={{
                          height: 30,
                          paddingLeft: 2,
                          color: "black",
                          backgroundColor: "white",
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <Flex flexDirection={"row"} style={{ gap: "1rem" }}>
                  <div className="buttons">
                    <Button
                      // style={{ marginTop: 55 }}
                      onClick={(e) => handlePasswordChange()}
                      type="primary"
                      shape="round"
                      className="button__pwd"
                    >
                      {commonTitles.change}
                    </Button>
                  </div>
                  <div className="cancel-btn">
                    <Button onClick={handleCancel} type="primary" shape="round">
                      {commonTitles.cancel}
                    </Button>
                  </div>
                </Flex>
              </div>
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Profile;
