import { useChartStore } from "../../../store/useStores";
import chartSelector from "../UC helpers/chartSelector";
import "../css/ultimateChart.css";

const UltimateChartBody = ({
  chartData,
  columns,
  filters,
  foreignKeyValues,
  staticOptions,
  updateFilters,
  updateValuesUsed,
  yValuesUsed,
  xValuesUsed,
  noDateDataColumnStatus,
  updateNoDateDataColumnStatus,
  privileges,
  chartParamMenuVisible,
  changeChartParamMenuVisible,
  chartTitle,
}) => {
  const chartType = useChartStore((state) => state.chartType);
  const grouping = useChartStore((state) => state.grouping);
  const dateField = useChartStore((state) => state.dateField);
  const groupField = useChartStore((state) => state.groupField);
  const displayFunction = useChartStore((state) => state.displayFunction);

  return (
    <div>
      {chartSelector(
        chartData,
        chartType,
        grouping,
        dateField,
        groupField,
        displayFunction,
        columns,
        filters,
        foreignKeyValues,
        staticOptions,
        updateFilters,
        updateValuesUsed,
        yValuesUsed,
        xValuesUsed,
        noDateDataColumnStatus,
        updateNoDateDataColumnStatus,
        privileges,
        chartParamMenuVisible,
        changeChartParamMenuVisible,
        chartTitle
      )}
    </div>
  );
};

export default UltimateChartBody;
