import Flex from "../../../../../../helpers/components/Flex";
import { Input } from "antd";
import { useState } from "react";
import validateValue from "./validateValue";

const Decimal = ({ fields, data, config, addChange, type }) => {
  const [integralStatus, setIntegralStatus] = useState(null);
  const [decimalStatus, setDecimalStatus] = useState(null);

  const checkValueValidity = (id, value) => {
    const isValid = validateValue(value);

    if (isValid) {
      if (id === "INTEGRAL") {
        setIntegralStatus(null);
      }

      if (id === "DECIMAL") {
        setDecimalStatus(null);
      }

      addChange(id, value);
    } else {
      if (id === "INTEGRAL") {
        setIntegralStatus("error");
      }

      if (id === "DECIMAL") {
        setDecimalStatus("error");
      }
    }
  };

  return (
    <>
      {type !== "116" && (
        <Flex
          flexDirection={"row"}
          style={{ gap: "0.5rem", justifyItems: "center" }}
        >
          <p
            style={{
              display: "flex",
              width: 180,
              justifyContent: "flex-end",
            }}
          >
            {fields[23].TITLE}:
          </p>
          <Input
            style={{
              width: 300,
              marginLeft: 20,
              marginTop: -5,
              marginBottom: "1rem",
              borderRadius: 5,
            }}
            status={integralStatus}
            onChange={(e) => checkValueValidity("INTEGRAL", e.target.value)}
            defaultValue={config === "edit" ? data.INTEGRAL : null}
          />
        </Flex>
      )}
      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: 180,
            justifyContent: "flex-end",
          }}
        >
          {fields[24].TITLE}:
        </p>
        <Input
          style={{
            width: 300,
            marginLeft: 20,
            marginTop: -5,
            marginBottom: "1rem",
            borderRadius: 5,
          }}
          maxLength={1}
          status={decimalStatus}
          onChange={(e) => checkValueValidity("DECIMAL", e.target.value)}
          defaultValue={config === "edit" ? data.DECIMAL : null}
        />
      </Flex>
    </>
  );
};

export default Decimal;
