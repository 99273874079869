const chooseTypeDisplay = (value, titles) => {
  if (value === "E") {
    return titles["FO|166|TITLE"];
  }

  if (value === "S") {
    return titles["FO|167|TITLE"];
  }

  if (value === "L") {
    return titles["FO|168|TITLE"];
  }

  if (value === "R") {
    return titles["FO|181|TITLE"];
  }

  return "-";
};

export default chooseTypeDisplay;
