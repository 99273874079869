import "./staticList.css";
import { Layout, Select, Button } from "antd";
import { StaticListModal } from "./StaticListModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../../store/useStores";

const { Option } = Select;
const { Content } = Layout;

const StaticListSettings = ({
  newItemModalVisible,
  showModal,
  handleOk,
  handleCancel,
  handleDelete,
  fields,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"}>
        <Content
          style={{
            padding: 5,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        >
          <div className="select"></div>
        </Content>
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {commonTitles["CO|1|NEWOPT"]}
          </Button>
          {newItemModalVisible && (
            <StaticListModal
              key={"new-item"}
              visible={newItemModalVisible}
              onSave={handleOk}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
              fields={fields}
            />
          )}
        </div>
      </Flex>
    </Content>
  );
};

export default StaticListSettings;
