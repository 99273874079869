import { Image } from "antd";
import { useCommonTitlesStore } from "../../../../../store/useStores";
import { EyeOutlined } from "@ant-design/icons";
import Flex from "../../../../../helpers/components/Flex";
import { useState } from "react";
import "../../../css/ultimateTable.css";

const InfoImage = ({ value }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [visible, setVisible] = useState(false);
  if (value?.length) {
    return (
      <div onClick={() => setVisible(false)}>
        <Image
          width={"fit-content"}
          style={{ maxWidth: 100 }}
          height={"fit-content"}
          onClick={(e) => e.stopPropagation()}
          src={value?.[0]?.url}
          alt={value.DN}
          preview={{
            visible,
            onVisibleChange: (value) => {
              setVisible(value);
            },
            mask: (
              <Flex
                flexDirection={"column"}
                style={{ gap: "0.5rem", alignItems: "center" }}
              >
                <EyeOutlined />
                <p style={{ fontSize: 8 }}>{commonTitles["CO|1|PREVIEW"]}</p>
              </Flex>
            ),
          }}
        />
      </div>
    );
  }

  return <div />;
};

export default InfoImage;
