import axios from "axios";

const apiGetMenuList = async (baseURL) => {
  const url = `${baseURL}/App_menu_api/list`;
  try {
    const response = await axios.post(
      url,
      {},
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    console.log("apiGetMenuList: response: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiGetMenuList;
