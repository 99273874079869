import "./languages.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { LanguagesModal } from "./LanguagesModal";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip, message, Image } from "antd";
import {
  useAuthStore,
  useCommonTitlesStore,
  useMenuStore,
} from "../../../store/useStores";
import axios from "axios";
import Flex from "../../../helpers/components/Flex";
import { useState } from "react";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";

const LanguagesBody = ({
  data,
  titles,
  flags,
  headers,
  showModal,
  modalVisible,
  modalData,
  handleCancel,
  showDeleteModal,
  updateData,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const languages = useMenuStore((state) => state.languages);
  const setLanguages = useMenuStore((state) => state.setLanguages);

  const [langToEdit, setLangToEdit] = useState(null);

  const onSave = async (changes) => {
    const url = `${baseURL}/Languages_api/save`;

    try {
      const response = await axios.post(
        url,
        {
          lang: language,
          L_WORKSPACEID: workspaceID,
          ...data.filter((item) => item.L_ID === langToEdit)[0],
          ...changes,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Languages_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changesWithExtraInfo = {
          ...data.filter((item) => item.L_ID === langToEdit)[0],
          ...changes,
          L_LASTMODIFIED: response?.data?.TIMESTAMP,
        };
        console.log("changesWithExtraInfo: ", changesWithExtraInfo);
        updateData("edit", changesWithExtraInfo, langToEdit);
        setLanguages([...languages, changesWithExtraInfo]);
        handleCancel("edit");
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <TableBody>
      {data.map((item) => (
        <TableRow key={item.L_ID}>
          <TableCell>
            <Tooltip placement="right" title={titles["MO|13|EDITROW"]}>
              <EditOutlined
                onClick={() => {
                  showModal(item, "edit");
                  setLangToEdit(item.L_ID);
                }}
                style={{
                  fontSize: "18px",
                  color: "rgb(8, 8, 46)",
                }}
              />
            </Tooltip>
            {modalVisible && (
              <LanguagesModal
                key={`${item.L_ID}-modal`}
                visible={modalVisible}
                data={modalData}
                headers={headers}
                titles={titles}
                flags={flags}
                onSave={onSave}
                showDeleteModal={showDeleteModal}
                onCancel={handleCancel}
                config={"edit"}
              />
            )}
          </TableCell>
          <TableCell key={`${item.L_ID}-abbr`}>{item.L_ABBR}</TableCell>
          <TableCell key={`${item.UP_ID}-title`}>
            {htmlCodesRemover(item.T_TITLE)}
          </TableCell>
          <TableCell key={`${item.UP_ID}-flag`}>
            <Flex
              flexDirection={"row"}
              style={{ gap: "1rem", alignItems: "center" }}
            >
              <Image
                width={35}
                src={require(`../../../assets/Flags/${item?.L_FLAGFILENAME}`)}
                alt={item?.L_FLAGFILENAME.split(".")[0]}
                preview={false}
              />
            </Flex>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default LanguagesBody;
