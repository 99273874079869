const createColumns = (alertTitles, commonTitles) => {
  const columns = [
    {
      T_TITLE: `${alertTitles["FD|207|TITLE"]}`,
      F_ID: "module",
      F_TYPE: "7",
      F_REQUIRED: "Y",
      F_WIDTH: "100",
      F_RENDER: "",
      F_LIST: "Y",
      F_ORDER: "1",
      F_UNIQUE: "",
      F_ALIGN: "Left",
    },
    {
      T_TITLE: alertTitles["FD|229|TITLE"],
      F_ID: "info",
      F_TYPE: "1",
      F_REQUIRED: "Y",
      F_WIDTH: "400",
      F_RENDER: "",
      F_LIST: "Y",
      F_ORDER: "2",
      F_UNIQUE: "",
      F_ALIGN: "Left",
    },
    {
      T_TITLE: alertTitles["FD|230|TITLE"],
      F_ID: "type",
      F_TYPE: "28",
      F_REQUIRED: "Y",
      F_WIDTH: "100",
      F_RENDER: "",
      F_LIST: "Y",
      F_ORDER: "3",
      F_UNIQUE: "",
      F_ALIGN: "Left",
    },
    {
      T_TITLE: alertTitles["FD|1000|TITLE"],
      F_ID: "date",
      F_TYPE: "4",
      F_REQUIRED: "Y",
      F_WIDTH: "100",
      F_RENDER: "",
      F_LIST: "Y",
      F_ORDER: "4",
      F_UNIQUE: "",
      F_ALIGN: "Center",
    },
    {
      T_TITLE: commonTitles["CO|1|STATUS"],
      F_ID: "status",
      F_TYPE: "6",
      F_REQUIRED: "Y",
      F_WIDTH: "100",
      F_RENDER: "color",
      F_LIST: "Y",
      F_ORDER: "5",
      F_UNIQUE: "",
      F_ALIGN: "Center",
    },
  ];

  return columns;
};

export default createColumns;
