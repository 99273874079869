import { Select, message, Image } from "antd";
import "./language.css";
import { useAuthStore, useMenuStore } from "../../../../store/useStores";
import axios from "axios";
import Flex from "../../../../helpers/components/Flex";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";

const { Option } = Select;

const Language = ({ updateVisibility }) => {
  const language = useAuthStore((state) => state.language);
  const languages = useMenuStore((state) => state.languages);
  const setLanguage = useAuthStore((state) => state.setLanguage);
  const baseURL = useAuthStore((state) => state.baseURL);

  const changeLanguage = async (lang) => {
    const url = `${baseURL}/Languages_api/change`;
    //console.log("changeLanguage: lang: ", lang);
    try {
      const response = await axios.post(
        url,
        {
          lang: lang,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data.STATUS === "Success") {
        setLanguage(lang);
      } else {
        //message.error("Αδυναμία επικοινωνίας με τον server", [3]);
        displayResponseMessage(
          "Αδυναμία επικοινωνίας με τον server",
          5,
          "error"
        );
      }

      //console.log("Languages_api: response: ", response.data);
    } catch (err) {
      //console.log("err: ", err);
      displayResponseMessage("Αδυναμία επικοινωνίας με τον server", 8, "error");
    }
  };

  return (
    <Select
      defaultValue={language}
      style={{
        width: 200,
        marginBottom: 10,
        background: "transparent",
      }}
      showSearch={true}
      filterOption={(iv, op) => {
        let opToUse = op.label ? op.label : "";
        return opToUse.toLowerCase().includes(iv.toLowerCase());
      }}
      bordered={false}
      autoFocus={false}
      onChange={(e) => {
        changeLanguage(e);
        updateVisibility();
      }}
      dropdownStyle={{
        paddingLeft: 18,
        bordered: "true",
        overflowY: "auto",
      }}
      virtual={false}
    >
      {languages.map(({ L_ABBR, L_FLAGFILENAME, T_TITLE }) => (
        <Option classname="option" key={L_ABBR} value={L_ABBR} label={T_TITLE}>
          <Flex
            flexDirection={"row"}
            style={{ gap: "1rem", alignItems: "center" }}
          >
            <Image
              width={35}
              src={require(`../../../../assets/Flags/${L_FLAGFILENAME}`)}
              alt={T_TITLE}
              preview={false}
            />
            <p style={{ color: "black" }}>{T_TITLE}</p>
          </Flex>
        </Option>
      ))}
    </Select>
  );
};

export default Language;
