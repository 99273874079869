import { useModTitlesStore, useAuthStore } from "../../../store/useStores";
import { Link } from "react-router-dom";
import { Dropdown, Space } from "antd";
import Flex from "../../../helpers/components/Flex";
import getConfigMenu from "../../../getFunctions/getConfigMenu";

const AdminMenu = () => {
  const role = useAuthStore((state) => state.role);

  const modTitles = useModTitlesStore((state) => state.modTitles);
  const configMenu = getConfigMenu(modTitles);

  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );

  const adminItems =
    configMenu.map((item, index) => {
      return {
        key: index,
        label: <Link to={item.path}>{item.title}</Link>,
      };
    }) ?? [];

  const titleStyle = {
    color: "#fff",
    fontFamily: "var(--font-family)",
    fontWeight: 500,
    fontSize: 15,
    textTransform: "capitalize",
    wordSpacing: "0.3em",
    zIndex: 5,
    margin: "0 1rem",
    cursor: "pointer",
  };

  if (role !== "A") {
    return <Flex />;
  }

  return (
    <Flex
      flexDirection={"row"}
      style={{ marginTop: "-1rem", marginRight: "2rem" }}
    >
      <Dropdown menu={{ items: adminItems }}>
        <a style={titleStyle} onClick={(e) => e.preventDefault()}>
          <Space style={{ color: navigationTextColor }}>
            {modTitles.administration}
          </Space>
        </a>
      </Dropdown>
    </Flex>
  );
};

export default AdminMenu;
