import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import "./reports.css";
import ReactTooltip from "react-tooltip";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  useCommonTitlesStore,
  useAuthStore,
  useChartStore,
} from "../../../../store/useStores";
import { ChartReportModal } from "./modal/ReportModal";
import moment from "moment";
import { convertDateFormat } from "../../../module/banner/newRegister/helpers/converters";
import apiReport from "../../../../api/endpoints/apiReport";
import apiDownload from "../../../../api/endpoints/apiDownload";
import { useEffect } from "react";
import { message } from "antd";

const ChartReport = ({ title, moduleID, reportIconVisible }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const chartBase64 = useChartStore((state) => state.chartBase64);
  const chartType = useChartStore((state) => state.chartType);
  const grouping = useChartStore((state) => state.grouping);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const groupField = useChartStore((state) => state.groupField);
  const dateField = useChartStore((state) => state.dateField);

  const [reportModalVisible, setReportModalVisible] = useState(false);

  const showReportModal = () => {
    setReportModalVisible(true);
  };

  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);

  let today = new Date();
  today = moment(today, dateFormatToUse).format(dateFormatToUse);

  const [changes, setChanges] = useState({
    reporttype: "M",
    docformat: "P",
    docsize: "4",
    orientation: "L",
    filename: title.includes(" ") ? title.split(" ").join("_") : title,
    reporttitle: title,
    fontsize: "10",
    lastupdate: today,
    revisionnumber: "",
    datefield: dateField,
    grouping: grouping,
    groupfield: groupField,
    displayfunction: displayFunction,
  });

  const updateReportParams = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  useEffect(() => {
    setChanges({
      ...changes,
      datefield: dateField,
      grouping: grouping,
      groupfield: groupField,
      displayfunction: displayFunction,
    });
  }, [dateField, grouping, groupField, displayFunction]);

  const restoreChanges = () => {
    setChanges({
      reporttype: "M",
      docformat: "P",
      docsize: "4",
      orientation: "L",
      filename: title.includes(" ") ? title.split(" ").join("_") : title,
      reporttitle: title,
      fontsize: "10",
      lastupdate: today,
      revisionnumber: "",
      datefield: dateField,
      grouping: grouping,
      groupfield: groupField,
      displayfunction: displayFunction,
      chartimage: chartBase64,
    });
  };

  useEffect(() => {
    updateReportParams("chartimage", chartBase64);
  }, [chartBase64]);

  useEffect(() => {
    let serverChartType = "";
    if (chartType === "Pie" || chartType === "Radial") {
      serverChartType = "P";
    }

    if (chartType === "Bar") {
      serverChartType = "B";
    }

    if (chartType === "Line") {
      serverChartType = "L";
    }

    if (chartType === "") {
      updateReportParams("chartimage", null);
    }
    updateReportParams("charttype", serverChartType);
  }, [chartType]);

  //console.log("ChartReports: changes: ", changes);

  const onSave = async () => {
    const response = await apiReport(
      baseURL,
      moduleID,
      changes,
      dateField,
      grouping,
      groupField,
      displayFunction,
      chartType
    );
    console.log("Reports: onSave: reponse: ", response);
    if (response.STATUS === "Success") {
      let suffix = "pdf";
      if (changes.docformat === "E") {
        suffix = "xlsx";
      }
      const downloadResponse = await apiDownload(
        baseURL,
        response.MESSAGE,
        changes.filename,
        suffix
      );
      console.log("Reports: onSuccess: downloadResponse: ", downloadResponse);
      restoreChanges();
      setReportModalVisible(false);
      //message.success("Επιτυχής έκδοση αναφοράς", [3]);
    } else {
      restoreChanges();
      setReportModalVisible(false);
      //message.error("Παρουσιάστηκε σφάλμα κατά την έκδοση της αναφοράς", [3]);
    }
  };

  const onCancel = () => {
    restoreChanges();
    setReportModalVisible(false);
  };

  return (
    <div className="chart-reports">
      {reportIconVisible && (
        <>
          <a data-tip={`${commonTitles.report}`}>
            <Button sx={{ color: "white" }} onClick={showReportModal}>
              {<PictureAsPdfIcon />}
            </Button>
          </a>
          <ReactTooltip place="bottom" />
          <ChartReportModal
            visible={reportModalVisible}
            title={title}
            today={today}
            onSave={onSave}
            onCancel={onCancel}
            updateReportParams={updateReportParams}
          />
        </>
      )}
    </div>
  );
};

export default ChartReport;
