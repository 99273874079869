import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import htmlCodesRemover from "../../../../ultimateTable/helpers/htmlCodesRemover";

const fieldDecider = (id, value, staticOptions) => {
  if (id === "F_TYPE") {
    return staticOptions[5][value]?.TITLE;
  }

  if (id === "F_ALIGN") {
    return staticOptions[11][value]?.TITLE;
  }

  if (id === "SIZE") {
    if (value) {
      return value;
    } else {
      return "-";
    }
  }

  if (value === "Y") {
    return <CheckOutlined style={{ color: "green" }} />;
  }

  if (id === "F_TOTALS") {
    if (value?.length) {
      if (value.length === 1 && (value[0] === "N" || !value[0])) {
        return <CloseOutlined style={{ color: "red" }} />;
      }
      let newValue = "";
      value.map((item) => {
        if (newValue !== "") {
          newValue += ", ";
        }
        newValue += staticOptions?.[32]?.[item]?.TITLE;
      });
      return newValue;
    } else {
      return <CloseOutlined style={{ color: "red" }} />;
    }
  }

  if (value === "N") {
    return <CloseOutlined style={{ color: "red" }} />;
  }

  return htmlCodesRemover(value);
};

export default fieldDecider;
