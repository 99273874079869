import Flex from "../../../../../../helpers/components/Flex";
import { Select } from "antd";

const { Option } = Select;

const EditFieldType = ({
  data,
  changes,
  addChange,
  fields,
  staticOptions,
  changeType,
}) => {
  const typeOptions = Object.entries(staticOptions[5]).map(([key, value]) => ({
    ...value,
    value: key,
  }));
  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center", marginTop: -5 }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
          marginTop: 5,
        }}
      >
        {fields[5].TITLE}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 20,
            marginBottom: "1rem",
          }}
          showSearch={true}
          onChange={(e) => {
            addChange("F_TYPE", e);
            changeType(e);
          }}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          defaultValue={changes?.F_TYPE ? changes?.F_TYPE : data.F_TYPE}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          {typeOptions.map((option) => (
            <Option key={option.value} className="option" value={option.value}>
              {option.TITLE}
            </Option>
          ))}
        </Select>
      </div>
    </Flex>
  );
};

export default EditFieldType;
