import { useCommonTitlesStore } from "../../../../../store/useStores";
import { Input } from "antd";

const AutomaticIdType = ({ value, config }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  if (config === "filters") {
    return null;
  } else if (config === "copy") {
    return (
      <Input
        placeholder={commonTitles["CO|1|DFIELDMSG"]}
        disabled={true}
        style={{ width: 278, height: 34 }}
      />
    );
  } else {
    return (
      <Input
        value={value ?? ""}
        disabled={true}
        style={{ width: 278, height: 34 }}
      />
    );
  }
};

export default AutomaticIdType;
