import "./adminTitles.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { Input } from "antd";
import Flex from "../../../helpers/components/Flex";

const AdminTitlesBody = ({ data, addChange, changes }) => {
  const changedIDs = changes?.titles?.map((item) => item.T1_ID);
  return (
    <TableBody>
      {data.map((item, index) => (
        <TableRow key={index}>
          <TableCell align="left" style={{ width: "50%" }}>
            {item.T1_TITLE}
          </TableCell>
          <TableCell align="center">
            <Flex flexDirection={"row"}>
              {changedIDs.includes(item.T1_ID) && (
                <p style={{ color: "red", marginRight: 8, marginTop: 8 }}>*</p>
              )}
              <Input
                defaultValue={item.T2_TITLE}
                onChange={(e) =>
                  addChange(item.T1_ID, {
                    T1_ID: item.T1_ID,
                    T2_ID: item.T2_ID,
                    T2_TITLE: e.target.value,
                  })
                }
              />
            </Flex>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default AdminTitlesBody;
