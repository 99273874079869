import { Input, Modal, Select, Button, Image } from "antd";
import Flex from "../../../helpers/components/Flex";
import { useState } from "react";
import { useCommonTitlesStore } from "../../../store/useStores";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";

const { Option } = Select;

export const LanguagesModal = ({
  visible,
  data,
  headers,
  flags,
  titles,
  onSave,
  showDeleteModal,
  onCancel,
  config,
}) => {
  const [changes, setChanges] = useState({});
  const dataChanged = Object.keys(changes);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  return (
    <Modal
      key={`${data}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      data={data}
      headers={headers}
      titles={titles}
      style={{
        marginTop: 20,
        minWidth: "max-content",
      }}
      bodyStyle={{ paddingTop: 80, paddingLeft: 80, paddingRight: 80 }}
      title={
        config === "edit" ? titles["MO|13|EDITROW"] : titles["MO|13|NEWROW"]
      }
      onOk={() => onSave(changes)}
      onCancel={() => onCancel(config)}
      onDelete={() => showDeleteModal(data.L_ID)}
      footer={[
        <>
          <Button key="back" onClick={() => onCancel(config)}>
            {commonTitles["CO|1|CANCELBTN"]}
          </Button>
          {config === "edit" && (
            <Button
              key="delete"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => showDeleteModal(data.L_ID)}
            >
              {commonTitles["CO|1|DELETEBTN"]}
            </Button>
          )}
          <Button
            key="submit"
            type="primary"
            style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
            onClick={() => onSave(changes)}
          >
            {commonTitles["CO|1|SAVEBTN"]}
          </Button>
        </>,
      ]}
    >
      <Flex flexDirection={"row"}>
        <div className="modal-text">
          {headers.map((header) => (
            <p key={header.id}>{header.title}:</p>
          ))}
        </div>
        <div className="modal-input">
          <div className="select">
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginTop: 0,
                marginBottom: "1rem",
              }}
              onChange={(e) => addChange("L_ABBR", e.target.value)}
              defaultValue={config === "edit" ? data.L_ABBR : null}
            />
          </div>
          <div className="select">
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginTop: 0,
                marginBottom: "1rem",
              }}
              onChange={(e) => addChange("T_TITLE", e.target.value)}
              defaultValue={
                config === "edit" ? htmlCodesRemover(data.T_TITLE) : null
              }
            />
          </div>
          <div className="select">
            <Select
              style={{
                width: 300,
                marginLeft: 15,
              }}
              showSearch={true}
              onChange={(e) => addChange("L_FLAGFILENAME", e)}
              filterOption={(iv, op) => {
                let opToUse = op.label ? op.label : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              defaultValue={config === "edit" ? data.L_FLAGFILENAME ?? "" : ""}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option value={""} />
              {Object.values(flags).map((item, index) => (
                <Option
                  className="option"
                  key={index}
                  value={item}
                  label={item.split(".")[0]}
                >
                  <Flex
                    flexDirection={"row"}
                    style={{ gap: "1rem", alignItems: "center" }}
                  >
                    <Image
                      width={35}
                      src={require(`../../../assets/Flags/${item}`)}
                      alt={item.split(".")[0]}
                      preview={false}
                    />
                    <p>{item.split(".")[0]}</p>
                  </Flex>
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </Flex>
    </Modal>
  );
};
