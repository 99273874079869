import Flex from "../../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../../store/useStores";
import { Card } from "antd";

const ConfirmationDeleteModalContent = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Flex flexDirection={"column"}>
      <Card>
        <p>{commonTitles["CO|1|DELCONF2"]}?</p>
      </Card>
    </Flex>
  );
};

export default ConfirmationDeleteModalContent;
