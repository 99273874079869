import { useCommonTitlesStore } from "../../../../../store/useStores";
import { useState } from "react";
import Flex from "../../../../../helpers/components/Flex";
import { Input } from "antd";

const EmailType = ({ value = null, id, title, config, addChange }) => {
  const [validEmail, setValidEmail] = useState(true);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const checkEmailValidity = (email) => {
    if (email) {
      setValidEmail(/\S+@\S+\.\S+/.test(email));
    } else {
      setValidEmail(true);
    }
  };

  return (
    <Flex
      key={id}
      flexDirection={"column"}
      style={{ justifyContent: "flex-end" }}
    >
      <Flex
        key={id}
        flexDirection={"row"}
        style={{ justifyContent: "flex-start" }}
      >
        <div className="modal-text" style={{ width: "35%" }}>
          <p>{title}:</p>
        </div>
        <div className="modal-input" style={{ width: "65%" }}>
          <div className="select">
            <Flex flexDirection={"column"}>
              <Input
                style={{
                  width: 300,
                  marginLeft: 15,
                  marginTop: 0,
                  marginBottom: "1rem",
                }}
                onChange={(e) => {
                  checkEmailValidity(e.target.value);
                  addChange(id, e.target.value, null);
                }}
                defaultValue={config === "edit" ? value : null}
              />
            </Flex>
          </div>
        </div>
      </Flex>

      {!validEmail && (
        <p
          style={{
            marginTop: "-0.8rem",
            marginBottom: "0.4rem",
            marginLeft: "11rem",
            fontStyle: "italic",
            color: "red",
          }}
        >
          {commonTitles["CO|1|INVLDEMAIL"]}
        </p>
      )}
    </Flex>
  );
};

export default EmailType;
