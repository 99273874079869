import Flex from "../../../../helpers/components/Flex";

const HeaderDecider = ({ id, title, changes, data, config }) => {
  const dataChanged = Object.keys(changes);

  const showAsterisk = (id) => {
    if (dataChanged.includes(id)) {
      let isChanged = false;
      if (config === "edit") {
        const valueToCheck = data[id]?.includes("&#39;")
          ? data[id]
              .split("&#39;")
              .filter((item) => item !== "")
              .join("'")
          : data[id];

        if (id === "ALERTTEXT") {
          if (changes[id] !== data?.T_TITLE) {
            isChanged = true;
          } else {
            isChanged = false;
          }
        } else {
          if (changes[id] !== valueToCheck) {
            isChanged = true;
          } else {
            isChanged = false;
          }
        }
      }

      if (config === "new") {
        if (changes[id]) {
          if (id === "A_ACTIVE" && changes[id] === "Y") {
            isChanged = false;
          } else if (id === "A_WARNING_BEFORE" && changes[id] === "0") {
            isChanged = false;
          } else if (id === "A_RAISED_AFTER" && changes[id] === "0") {
            isChanged = false;
          } else {
            isChanged = true;
          }
        } else {
          isChanged = false;
        }
      }

      return isChanged;
    }
  };

  return (
    <Flex
      key={id}
      flexDirection={"row"}
      style={{ width: 350, justifyContent: "flex-end" }}
    >
      {showAsterisk(id) ? (
        <p style={{ color: "red", marginRight: 5 }}>*</p>
      ) : (
        <p style={{ marginRight: 12 }}></p>
      )}
      <p
        key={id}
        style={{
          marginRight: 5,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {title}:
      </p>
    </Flex>
  );
};

export default HeaderDecider;
