import "../../../css/ultimateTable.css";
import { Card, Row, Col, Layout } from "antd";
import { useAuthStore } from "../../../../../store/useStores";
import inputDecider from "../../inputDecider/inputDecider";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Flex from "../../../../../helpers/components/Flex";
import FormControl from "@mui/material/FormControl";
import DisplayDecider from "./DisplayDecider";
import htmlCodesRemover from "../../htmlCodesRemover";

const { Content } = Layout;

const EditModalContent = ({
  row,
  columns,
  staticOptions,
  foreignKeyValues,
  columnsChanged,
  addChange,
  changes,
  privileges,
  autoCompletedFields,
  moduleID,
}) => {
  const role = useAuthStore((state) => state.role);
  const { width } = useWindowDimensions();

  console.log("EditModalContent: moduleID: ", moduleID);

  const isFirefox = typeof InstallTrigger !== "undefined";

  let newColumns = [];
  let i = 0;

  [...columns]
    .sort(
      (
        { F_EDITORDER: F_EDITORDER_A = 0 },
        { F_EDITORDER: F_EDITORDER_B = 0 }
      ) => {
        if (!F_EDITORDER_A) {
          if (isFirefox) {
            return -1;
          }
          return 1;
        } else if (!F_EDITORDER_B) {
          return -1;
        } else {
          return Number(F_EDITORDER_A) - Number(F_EDITORDER_B);
        }
      }
    )
    .forEach((column) => {
      if (column?.F_SINGLELINE) {
        if (column.F_SINGLELINE === "L") {
          newColumns.push(column);
          newColumns.push({
            F_ID: i.toString(),
            T_TITLE: "",
            F_REQUIRED: "N",
            F_TYPE: "0",
            F_LIST: "Y",
          });
        }

        if (column.F_SINGLELINE === "R") {
          newColumns.push({
            F_ID: i.toString(),
            T_TITLE: "",
            F_REQUIRED: "N",
            F_TYPE: "0",
            F_LIST: "Y",
          });
          newColumns.push(column);
        }

        i++;
      } else {
        newColumns.push(column);
      }
    });

  let viewRights = {};
  newColumns.forEach((column) => {
    if (role === "A") {
      viewRights = { ...viewRights, [column.F_ID]: true };
    } else if (Object.keys(column).includes("canview")) {
      viewRights = {
        ...viewRights,
        [column.F_ID]: column.canview,
      };
    } else {
      viewRights = {
        ...viewRights,
        [column.F_ID]: privileges?.canView,
      };
    }
  });

  return (
    <Flex flexDirection={"column"}>
      <Content>
        <Card>
          <Row gutter={[20, 10]} justify={width < 1450 ? "center" : "left"}>
            {newColumns
              .filter((column) => viewRights[column.F_ID])
              .map(
                ({
                  F_ID,
                  F_TYPE,
                  T_TITLE,
                  F_REQUIRED,
                  INFO,
                  canupdate,
                  canupdateifnull,
                  F_PARENTFIELDID,
                  F_RENDER,
                  F_FORMAT,
                }) => (
                  <Col
                    className="gutter-row"
                    span={newColumns.length === 1 ? 20 : width < 1450 ? 20 : 10}
                    key={F_ID}
                  >
                    <div className="grid-horizontal">
                      <DisplayDecider
                        changes={changes}
                        columnsChanged={columnsChanged}
                        fieldID={F_ID}
                        row={row}
                        title={htmlCodesRemover(T_TITLE)}
                        info={INFO}
                      />

                      <FormControl
                        sx={{
                          mt: -1,
                          mb: 0.5,
                          ml: 1,
                          mr: 1,
                          minWidth: 300,
                        }}
                        className="filters-row"
                        key={F_ID}
                      >
                        {inputDecider(
                          F_TYPE,
                          F_ID,
                          row[F_ID],
                          staticOptions,
                          foreignKeyValues,
                          addChange,
                          "update",
                          F_REQUIRED,
                          [],
                          row,
                          canupdate,
                          canupdateifnull,
                          moduleID,
                          autoCompletedFields,
                          F_RENDER,
                          F_FORMAT,
                          F_PARENTFIELDID,
                          changes
                        )}
                      </FormControl>
                    </div>
                  </Col>
                )
              )}
          </Row>
        </Card>
      </Content>
    </Flex>
  );
};

export default EditModalContent;
