import "./css/fieldsConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { EditFieldModal } from "./modals/EditFieldModal";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import fieldDecider from "./deciders/fieldDecider";
import {
  useAdminStore,
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import axios from "axios";
import { message } from "antd";
import { useState } from "react";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";

const FieldsConfigBody = ({
  data,
  headData,
  staticOptions,
  fields,
  refModules,
  showModal,
  showDeleteModal,
  modalVisible,
  modalData,
  updateData,
  handleCancel,
  handleDelete,
  onSort,
  currentModuleID,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [fieldToEdit, setFieldToEdit] = useState({});

  const DragHandle = SortableHandle(({ style }) => (
    <span style={{ ...style, ...{ cursor: "move" } }}>
      <DragHandleIcon />
    </span>
  ));

  const TableBodySortable = SortableContainer(({ children }) => (
    <TableBody>{children}</TableBody>
  ));

  const centralAlignTitles = [
    "F_REQUIRED",
    "F_UNIQUE",
    "F_TOTALS",
    "F_WIDTH",
    "SIZE",
  ];

  const onSave = async (changes) => {
    const url = `${baseURL}/App_fields_api/save`;

    let changesForServer = {};
    if (changes?.F_TYPE) {
      if (changes.F_TYPE === fieldToEdit.F_TYPE) {
        changesForServer = { ...fieldToEdit, ...changes };
      } else {
        if (changes?.T_TITLE && changes?.T_TITLE !== "") {
          changesForServer = { ...changes };
        } else {
          changesForServer = {
            T_TITLE: fieldToEdit.T_TITLE,
            ...changes,
          };
        }
      }
    } else {
      changesForServer = { ...fieldToEdit, ...changes };
    }

    try {
      const response = await axios.post(
        url,
        { id: fieldToEdit.F_ID, ...changesForServer },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_fields_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changesWithExtraInfo = {
          ...changes,
          F_ID: response?.data?.ID,
          F_LASTMODIFIED: response?.data?.TIMESTAMP,
        };
        updateData("edit", changesWithExtraInfo);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const Row = SortableElement(({ data, ...other }) => {
    return (
      <TableRow {...other}>
        <TableCell style={{ width: "5%" }}>
          <Tooltip placement="right" title={commonTitles["CO|1|EDITFLD"]}>
            <EditOutlined
              onClick={() => {
                setFieldToEdit(data);
                showModal(data, "edit");
              }}
              style={{
                fontSize: "18px",
                color: "rgb(8, 8, 46)",
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell style={{ width: "5%" }}>
          <DragHandle />
        </TableCell>
        {headData.map((header) => (
          <TableCell
            align={centralAlignTitles.includes(header.id) ? "center" : "left"}
            key={header.id}
          >
            {fieldDecider(header.id, data[header.id], staticOptions)}
          </TableCell>
        ))}
      </TableRow>
    );
  });

  return (
    <TableBodySortable onSortEnd={onSort} useDragHandle>
      {data.map((item, index) => (
        <Row key={index} index={index} data={item} />
      ))}
      {modalVisible && (
        <EditFieldModal
          visible={modalVisible}
          headers={headData}
          data={modalData}
          bodyData={data}
          staticOptions={staticOptions}
          fields={fields}
          refModules={refModules}
          onSave={onSave}
          onDelete={handleDelete}
          showDeleteModal={showDeleteModal}
          onCancel={handleCancel}
          config={"edit"}
          currentModuleID={currentModuleID}
        />
      )}
    </TableBodySortable>
  );
};

export default FieldsConfigBody;
