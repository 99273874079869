import "./projectsConfig.css";
import { Layout, Select, Button, message } from "antd";
import { NewProjectModal } from "./NewProjectModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore, useAuthStore } from "../../../store/useStores";
import axios from "axios";
import { tranformChangesforServer } from "../../module/helpers/converters";
import { convertDataFromServer } from "../../module/helpers/converters";
import apiDeleteFile from "../../../api/endpoints/apiDeleteFile";
import apiUpload from "../../../api/endpoints/apiUpload";
import { useState } from "react";
import { useEffect } from "react";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const { Option } = Select;
const { Content } = Layout;

const ProjectsConfigSettings = ({
  headers,
  staticOptions,
  foreignKeyValues,
  newItemModalVisible,
  showModal,
  updateData,
  handleCancel,
  handleDelete,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);
  const baseURL = useAuthStore((state) => state.baseURL);
  const userID = useAuthStore((state) => state.userID);
  const [isUploading, setIsUploading] = useState(false);

  let attachColumns = [];

  headers.forEach((column) => {
    if (column.F_TYPE === "24" || column.F_TYPE === "25") {
      attachColumns.push(column.F_ID);
    }
  });

  const uploadFiles = async (filesToUpload) => {
    let uploadFileTracker = {};
    let uploadError = false;

    for (let i = 0; i < attachColumns?.length; i++) {
      if (!uploadError) {
        let id = attachColumns[i];
        uploadFileTracker = { ...uploadFileTracker, [id]: [] };
        for (let j = 0; j < filesToUpload[id]?.length; j++) {
          const fmData = new FormData();
          fmData.append("file", filesToUpload[id][j]);

          const { uploadStatus, uploadedFile } = await apiUpload(
            baseURL,
            fmData,
            commonTitles
          );

          if (uploadStatus !== "Success") {
            uploadError = true;
            break;
          }

          uploadFileTracker[id].push(uploadedFile);
        }
      } else break;
    }

    if (uploadError) {
      return { uploadFileTracker, uploadStatus: "Error" };
    }

    return { uploadFileTracker, uploadStatus: "Success" };
  };

  const deleteFiles = async (filesToDelete) => {
    let deleteError = false;
    let deleteFileTracker = {};
    for (let i = 0; i < attachColumns.length; i++) {
      if (!deleteError) {
        let id = attachColumns[i];
        deleteFileTracker = { ...deleteFileTracker, [id]: [] };
        for (let j = 0; j < filesToDelete[id].length; j++) {
          const deleteStatus = await apiDeleteFile(
            baseURL,
            filesToDelete[id][j].id,
            commonTitles
          );
          if (deleteStatus !== "Success") {
            break;
          }
          deleteFileTracker[id].push(filesToDelete[id][j]);
        }
      } else break;
    }

    if (deleteError) {
      return { deleteFileTracker, deleteStatus: "Error" };
    }

    return { deleteFileTracker, deleteStatus: "Success" };
  };

  const onSave = async (changes) => {
    // console.log("changes: ", changes);

    const url = `${baseURL}/Data_api/save`;

    let filesToUpload = {};
    attachColumns.forEach((id) => {
      filesToUpload = { ...filesToUpload, [id]: [] };
      if (changes[id]) {
        let tempFilesToUpload = changes[id].filter(
          (file) => !("action" in file)
        );
        filesToUpload[id].push(...tempFilesToUpload);
      }
    });

    setIsUploading(true);
    const { uploadFileTracker, uploadStatus } = await uploadFiles(
      filesToUpload
    );
    setIsUploading(false);

    if (uploadStatus === "Success") {
      const fileTracker = uploadFileTracker;

      const transformedChanges = tranformChangesforServer(
        changes,
        headers,
        [],
        "",
        foreignKeyValues,
        fileTracker,
        baseURL
      );

      //console.log("onSave: transformedChanges: ", transformedChanges);

      const tempData = await convertDataFromServer(
        headers,
        "",
        [],
        staticOptions,
        foreignKeyValues,
        transformedChanges,
        "insert",
        userID
      );

      //console.log("onSave: tempdata: ", tempData);

      try {
        const response = await axios.post(
          url,
          { moduleid: "5", ...transformedChanges },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Data_api/save: response: ", response?.data);

        if (response?.data?.STATUS === "Success") {
          //message.success(commonTitles["CO|1|REGMSG1"], [3]);
          displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
          const changes = {
            [response?.data?.ROWID]: {
              ["LM"]: response?.data?.TIMESTAMP,
              ROWID: `${response?.data?.ROWID}`,
              ...tempData,
            },
          };
          //console.log("onSave: changes: ", changes);
          updateData("new", changes);
          setCoreChange(!coreChange);
        } else {
          const responseMsg = response?.data?.MESSAGE ?? {};
          let template = commonTitles[responseMsg.ID];

          Object.entries(responseMsg)
            .filter(([key]) => key !== "ID")
            .forEach(([key, value]) => {
              var re = new RegExp(key, "g");
              template = template.replace(re, value);
            });

          //message.warning(template, [5]);
          displayResponseMessage(template, 5, "warning");
        }
      } catch (err) {
        //message.error(err, [3]);
        displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
      }
    } else {
      deleteFiles(uploadFileTracker);
    }
  };

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"} style={{ justifyContent: "flex-end" }}>
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {commonTitles["CO|1|NEWPRJ"]}
          </Button>
          {newItemModalVisible && (
            <NewProjectModal
              key={"new-item"}
              visible={newItemModalVisible}
              headers={headers}
              staticOptions={staticOptions}
              foreignKeyValues={foreignKeyValues}
              onSave={onSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
            />
          )}
        </div>
      </Flex>
      {/* {isUploading && message.loading("Uploading files...", 4)} */}
    </Content>
  );
};

export default ProjectsConfigSettings;
