const createLabels = (
  grouping,
  displayFunction,
  commonTitles,
  dateField,
  columns
) => {
  let XLabel = "{date data}";
  let YLabel = "{type of function}";

  const columnUsed = columns.filter(
    (column) => column?.F_ID === dateField
  )?.[0];

  if (Object.keys(commonTitles).length) {
    if (columnUsed?.F_TYPE === "4" || columnUsed?.F_TYPE === "5") {
      if (grouping === "Daily") {
        XLabel = commonTitles.date;
      }

      if (grouping === "Monthly") {
        XLabel = commonTitles.month;
      }

      if (grouping === "Yearly") {
        XLabel = commonTitles.year;
      }
    } else {
      XLabel = columnUsed?.T_TITLE;
    }

    if (displayFunction === "count") {
      YLabel = commonTitles.count;
    }

    if (displayFunction === "pctCount") {
      YLabel = commonTitles.pctCount;
    }

    if (displayFunction === "sum") {
      YLabel = commonTitles.sum;
    }

    if (displayFunction === "pctSum") {
      YLabel = commonTitles.pctSum;
    }
  }

  return { XLabel, YLabel };
};

export default createLabels;
