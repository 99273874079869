const config = (set) => ({
  menu: [],
  setMenu: (newMenu) => set(() => ({ menu: newMenu })),

  languages: [],
  setLanguages: (newLanguages) => set(() => ({ languages: newLanguages })),

  adminMenu: [],
  setAdminMenu: (newAdminMenu) => set(() => ({ adminMenu: newAdminMenu })),

  projects: [],
  setProjects: (newProjects) => set(() => ({ projects: newProjects })),

  username: "",
  setUsername: (newUsername) => set(() => ({ username: newUsername })),
});

const MenuStore = {
  config,
  shouldLog: true,
};

export default MenuStore;
