const config = (set) => ({
  filters: {},
  setFilters: (newItem) =>
    set((state) => ({ filters: { ...state.filters, ...newItem } })),
});

const FilterStore = {
  config,
  shouldLog: true,
};

export default FilterStore;
