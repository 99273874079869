import { useCommonTitlesStore } from "../../../../../../../../store/useStores";

const InvalidInput = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <p
      style={{
        marginTop: -6,
        marginBottom: 10,
        color: "#cc0000",
        fontSize: 12,
      }}
    >
      {commonTitles["CO|1|TAGINMSG"]}
    </p>
  );
};

export default InvalidInput;
