import axios from "axios";

const apiGetUserProjectRights = async (baseURL, id) => {
  const url = `${baseURL}/User_projects_api/list`;
  try {
    const response = await axios.post(
      url,
      { userid: id },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("apiGetUserProjectRights: response: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiGetUserProjectRights;
