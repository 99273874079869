import CheckboxType from "./checkboxType/CheckboxType";
import InfoDocument from "./documentType/InfoDocument";
import InfoImage from "./imageType/InfoImage";
import MultipleSelectionType from "./multipleSelectionType/MultipleSelectionType";
import RenderColor from "./renderColor/RenderColor";
import RenderDeadline from "./renderDeadline/RenderDeadline";
import RenderProgress from "./renderProgress/RenderProgress";
import StaticListType from "./staticListType/StaticListType";
import EmailType from "./emailType/EmailType";
import InfoText from "./textType/InfoText";
import DependentType from "./dependentType/DependentType";
import ButtonType from "./buttonType/ButtonType";
import RenderAlert from "./renderAlert/RenderAlert";

const infoDisplayDecider = (
  render,
  type,
  value,
  config,
  columns,
  row,
  serverValue,
  foreignKeyValues,
  id,
  inMiniCard,
  title,
  parentField,
  refModule,
  refField
) => {
  if (type === "24" /* type = Document link */) {
    return <InfoDocument value={value} />;
  }

  if (type === "25" /* type = Single image link */) {
    return <InfoImage value={value} />;
  }

  if (render === "ALERT") {
    return <RenderAlert value={value} />;
  }

  if (render === "PROGRESS") {
    return <RenderProgress value={value} inMiniCard={inMiniCard} />;
  }

  if (render === "color") {
    return <RenderColor value={value} config={config} />;
  }

  if (render === "DEADLINE") {
    return <RenderDeadline value={value} columns={columns} row={row} />;
  }

  if (type === "6" && render !== "PROGRESS" /* type = Static option list */) {
    return <StaticListType value={value} />;
  }

  if (type === "33" /*type = Email */) {
    return <EmailType value={value} />;
  }

  if (type === "45" /*type = Checkbox */) {
    return <CheckboxType value={value} />;
  }

  if (type === "28") {
    return (
      <MultipleSelectionType
        value={value}
        serverValue={serverValue}
        foreignKeyValues={foreignKeyValues}
        id={id}
      />
    );
  }

  if (type === "1" /* type = Text */ || type === "27" /* type = Text Area */) {
    return <InfoText value={value} config={config} id={id} />;
  }

  if (type === "30") {
    return (
      <DependentType
        value={value}
        serverValue={serverValue}
        foreignKeyValues={foreignKeyValues}
        id={id}
      />
    );
  }

  if (type === "26" /* type = Button */) {
    return (
      <ButtonType
        row={row}
        title={title}
        parentField={parentField}
        refModule={refModule}
        refField={refField}
      />
    );
  }

  if (type === "99" /* type = Checkbox */) {
    return <CheckboxType value={value} />;
  }

  return <p className="p-context">{value}</p>;
};

export default infoDisplayDecider;
