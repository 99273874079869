import Flex from "../../../../../../../helpers/components/Flex";
import { WarningOutlined } from "@ant-design/icons";
import CustomCheckbox from "../../../../../../../helpers/components/CustomCheckbox";

const CheckboxType = ({ id, required, addChange }) => {
  return (
    <Flex flexDirection={"row"} style={{ marginLeft: 6 }}>
      <CustomCheckbox
        id={id}
        value={false}
        addChange={addChange}
        config={"new"}
        isDisabled={false}
      />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default CheckboxType;
