import { Input, ColorPicker, Card, Checkbox, Button, Tooltip } from "antd";
import { useState, useEffect } from "react";
import Flex from "../../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../../store/useStores";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";

const ColorDate = ({ addChange, data = [], config }) => {
  const [alertInfoChanges, setAlertInfoChanges] = useState({});
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [weeksBefore1ColorRgb, setWeeksBefore1ColorRgb] =
    useState("rgb(255,255,255)");
  const [weeksBefore2ColorRgb, setWeeksBefore2ColorRgb] =
    useState("rgb(255,255,255)");
  const [expiredColorRgb, setExpiredColorRgb] = useState("rgb(255,255,255)");
  const [emptyColorRgb, setEmptyColorRgb] = useState("rgb(255,255,255)");
  const [weeksBefore1Checked, setWeeksBefore1Checked] = useState(true);
  const [weeksBefore2Checked, setWeeksBefore2Checked] = useState(true);
  const [expiredChecked, setExpiredChecked] = useState(true);
  const [emptyChecked, setEmptyChecked] = useState(true);

  useEffect(() => {
    if (data?.RENDEROPTIONS?.WEEKS_BEFORE1?.type === "font") {
      setWeeksBefore1Checked(false);
    }

    if (data?.RENDEROPTIONS?.WEEKS_BEFORE2?.type === "font") {
      setWeeksBefore2Checked(false);
    }

    if (data?.RENDEROPTIONS?.EXPIRED?.type === "font") {
      setExpiredChecked(false);
    }

    if (data?.RENDEROPTIONS?.EMPTY?.type === "font") {
      setEmptyChecked(false);
    }
  }, []);

  const addAlertInfoChange = (id, value, suffix) => {
    const tempData = data?.RENDEROPTIONS ?? {};
    if (id === "MONTHS_AFTER") {
      let temp = {
        ...tempData,
        ...alertInfoChanges,
        [id]: value,
      };
      setAlertInfoChanges(temp);
      addChange("RENDEROPTIONS", temp);
    } else {
      const tempDataIDinfo = tempData?.[id] ?? {};
      let temp = {
        ...tempData,
        ...alertInfoChanges,
        [id]: { ...tempDataIDinfo, ...alertInfoChanges[id], [suffix]: value },
      };

      if (temp?.[id]?.REFMODULE) {
        if (temp?.[id]?.value) {
          delete temp[id].value;
        }
      }

      setAlertInfoChanges(temp);
      addChange("RENDEROPTIONS", temp);
    }
  };

  const clearColor = (id) => {
    const tempData = {
      ...data?.RENDEROPTIONS,
      ...alertInfoChanges,
      [id]: {
        value: null,
        color: "#FFFFFF",
        type: "background",
      },
    };

    setAlertInfoChanges(tempData);
    addChange("RENDEROPTIONS", tempData);
  };

  useEffect(() => {
    if (config === "edit") {
      setWeeksBefore1ColorRgb(
        hexToRgb(data?.RENDEROPTIONS?.WEEKS_BEFORE1?.color ?? "#ffffff")
      );
      setWeeksBefore2ColorRgb(
        hexToRgb(data?.RENDEROPTIONS?.WEEKS_BEFORE2?.color ?? "#ffffff")
      );

      setExpiredColorRgb(
        hexToRgb(data?.RENDEROPTIONS?.EXPIRED?.color ?? "#ffffff")
      );
      setEmptyColorRgb(
        hexToRgb(data?.RENDEROPTIONS?.EMPTY?.color ?? "#ffffff")
      );
    }
  }, []);

  const rgbToHex = (color) => {
    const rgb = (color.r << 16) | (color.g << 8) | (color.b << 0);
    return "#" + (0x1000000 + rgb).toString(16).slice(1);
  };

  const hexToRgb = (hex) => {
    const normal = hex?.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) {
      const colorArray = normal.slice(1).map((e) => parseInt(e, 16));
      return `rgb(${colorArray[0]},${colorArray[1]},${colorArray[2]})`;
    }

    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) {
      const colorArray = shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));
      return `rgb(${colorArray[0]},${colorArray[1]},${colorArray[2]})`;
    }

    return null;
  };

  return (
    <Flex
      flexDirection={"row"}
      style={{
        gap: "0.5rem",
        justifyItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <p
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "flex-end",
        }}
      />
      <Card>
        <Flex
          flexDirection={"row"}
          style={{ marginTop: "1rem", width: "max-content" }}
        >
          <div className="modal-text">
            <p>{commonTitles["CO|1|ALR1MSG"]}:</p>
            <p>{commonTitles["CO|1|ALR2MSG"]}:</p>
            <p>{commonTitles["CO|1|ALRMNMSG"]}:</p>
            <p>{commonTitles["CO|1|EXPIRED"]}:</p>
            <p>{commonTitles["CO|1|EMPTY"]}:</p>
          </div>
          <div className="modal-input">
            <Flex flexDirection={"row"}>
              <div className="select">
                <Input
                  style={{
                    width: 40,
                    marginLeft: 15,
                    marginTop: 0,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) => {
                    addAlertInfoChange(
                      "WEEKS_BEFORE1",
                      e.target.value,
                      "value"
                    );
                  }}
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.WEEKS_BEFORE1?.value ?? null
                      : null
                  }
                />
              </div>
              <ColorPicker
                format={"rgb"}
                value={weeksBefore1ColorRgb}
                style={{ marginLeft: 15 }}
                onChange={(e) => {
                  setWeeksBefore1ColorRgb(e);
                  addAlertInfoChange(
                    "WEEKS_BEFORE1",
                    rgbToHex(e.metaColor),
                    "color"
                  );
                }}
              />
              <Checkbox
                style={{ marginTop: 5, marginLeft: 10 }}
                checked={weeksBefore1Checked ? true : false}
                onChange={(e) => {
                  setWeeksBefore1Checked(!weeksBefore1Checked);
                  addAlertInfoChange(
                    "WEEKS_BEFORE1",
                    e.target.checked ? "background" : "font",
                    "type"
                  );
                }}
              >
                {commonTitles["CO|1|CLRINBCK"]}
              </Checkbox>
              <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
                <Button
                  className="dropdown"
                  shape="round"
                  icon={
                    <FormatColorResetIcon
                      style={{ fontSize: 18, marginTop: 5 }}
                    />
                  }
                  onClick={() => {
                    clearColor("WEEKS_BEFORE1");
                    setWeeksBefore1ColorRgb("rgb(255,255,255)");
                    setWeeksBefore1Checked(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#6082B6",
                    border: "none",
                  }}
                />
              </Tooltip>
            </Flex>

            <Flex flexDirection={"row"}>
              <div className="select">
                <Input
                  style={{
                    width: 40,
                    marginLeft: 15,
                    marginTop: 0,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) =>
                    addAlertInfoChange("WEEKS_BEFORE2", e.target.value, "value")
                  }
                  defaultValue={
                    config === "edit"
                      ? data?.RENDEROPTIONS?.WEEKS_BEFORE2?.value
                      : null
                  }
                />
              </div>
              <ColorPicker
                format={"rgb"}
                value={weeksBefore2ColorRgb}
                style={{ marginLeft: 15 }}
                onChange={(e) => {
                  setWeeksBefore2ColorRgb(e);
                  addAlertInfoChange(
                    "WEEKS_BEFORE2",
                    rgbToHex(e.metaColor),
                    "color"
                  );
                }}
              />
              <Checkbox
                style={{ marginTop: 5, marginLeft: 10 }}
                checked={weeksBefore2Checked ? true : false}
                onChange={(e) => {
                  setWeeksBefore2Checked(!weeksBefore2Checked);
                  addAlertInfoChange(
                    "WEEKS_BEFORE2",
                    e.target.checked ? "background" : "font",
                    "type"
                  );
                }}
              >
                {commonTitles["CO|1|CLRINBCK"]}
              </Checkbox>
              <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
                <Button
                  className="dropdown"
                  icon={
                    <FormatColorResetIcon
                      style={{ fontSize: 18, marginTop: 5 }}
                    />
                  }
                  onClick={() => {
                    clearColor("WEEKS_BEFORE2");
                    setWeeksBefore2ColorRgb("rgb(255,255,255)");
                    setWeeksBefore2Checked(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#6082B6",
                    border: "none",
                    marginLeft: 8,
                  }}
                />
              </Tooltip>
            </Flex>

            <Flex flexDirection={"row"}>
              <div className="select">
                <Input
                  style={{
                    width: 40,
                    marginLeft: 15,
                    marginTop: 0,
                    marginBottom: "1rem",
                  }}
                  onChange={(e) =>
                    addAlertInfoChange("MONTHS_AFTER", e.target.value, "value")
                  }
                  defaultValue={
                    config === "edit" ? data?.RENDEROPTIONS?.MONTHS_AFTER : null
                  }
                />
              </div>
            </Flex>

            <Flex flexDirection={"row"}>
              <div
                style={{
                  width: 40,
                  marginLeft: 15,
                  marginTop: 0,
                  marginBottom: "1rem",
                }}
              />
              <ColorPicker
                format={"rgb"}
                value={expiredColorRgb}
                style={{ marginLeft: 15 }}
                onChange={(e) => {
                  setExpiredColorRgb(e);
                  addAlertInfoChange("EXPIRED", rgbToHex(e.metaColor), "color");
                }}
              />
              <Checkbox
                style={{ marginTop: 5, marginLeft: 10 }}
                checked={expiredChecked ? true : false}
                onChange={(e) => {
                  setExpiredChecked(!expiredChecked);
                  addAlertInfoChange(
                    "EXPIRED",
                    e.target.checked ? "background" : "font",
                    "type"
                  );
                }}
              >
                {commonTitles["CO|1|CLRINBCK"]}
              </Checkbox>
              <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
                <Button
                  className="dropdown"
                  icon={
                    <FormatColorResetIcon
                      style={{ fontSize: 18, marginTop: 5 }}
                    />
                  }
                  onClick={() => {
                    clearColor("EXPIRED");
                    setExpiredColorRgb("rgb(255,255,255)");
                    setExpiredChecked(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#6082B6",
                    border: "none",
                    marginLeft: 8,
                  }}
                />
              </Tooltip>
            </Flex>

            <Flex flexDirection={"row"} style={{ marginTop: 15 }}>
              <div
                style={{
                  width: 40,
                  marginLeft: 15,
                  marginTop: 0,
                  marginBottom: "1rem",
                }}
              />
              <ColorPicker
                format={"rgb"}
                value={emptyColorRgb}
                style={{ marginLeft: 15 }}
                onChange={(e) => {
                  setEmptyColorRgb(e);
                  addAlertInfoChange("EMPTY", rgbToHex(e.metaColor), "color");
                }}
              />
              <Checkbox
                style={{ marginTop: 5, marginLeft: 10 }}
                checked={emptyChecked ? true : false}
                onChange={(e) => {
                  setEmptyChecked(!emptyChecked);
                  addAlertInfoChange(
                    "EMPTY",
                    e.target.checked ? "background" : "font",
                    "type"
                  );
                }}
              >
                {commonTitles["CO|1|CLRINBCK"]}
              </Checkbox>
              <Tooltip placement="right" title={commonTitles["CO|1|CLRCOLOR"]}>
                <Button
                  className="dropdown"
                  icon={
                    <FormatColorResetIcon
                      style={{ fontSize: 18, marginTop: 5 }}
                    />
                  }
                  onClick={() => {
                    clearColor("EMPTY");
                    setEmptyColorRgb("rgb(255,255,255)");
                    setEmptyChecked(true);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    color: "#6082B6",
                    border: "none",
                    marginLeft: 8,
                  }}
                />
              </Tooltip>
            </Flex>
          </div>
        </Flex>
      </Card>
    </Flex>
  );
};

export default ColorDate;
