import Flex from "../../../../../../../helpers/components/Flex";
import { Input } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useState } from "react";
import validateValue from "./validateValue";

const NumberType = ({ id, addChange, isDisabled, required, size, type }) => {
  const [status, setStatus] = useState(null);

  let maxLengthInt = "1000";
  let maxLengthDec = "1000";

  if (size) {
    if (type === "2") {
      if (size.includes("|")) {
        maxLengthInt = size.split("|")?.[0] ?? "1000";
      } else {
        maxLengthInt = size;
      }
    }

    if (type === "3") {
      if (size.includes("|")) {
        maxLengthInt = size.split(",")?.[0] ?? "1000";
        maxLengthDec = size.split(",")?.[1].split("|")?.[0] ?? "1000";
      } else {
        maxLengthInt = size.split(",")?.[0] ?? "1000";
        maxLengthDec = size.split(",")?.[1] ?? "1000";
      }
    }
  }

  const checkValueValiditiy = (id, value) => {
    const isValid = validateValue(value, type);

    if (isValid) {
      setStatus(null);

      if (type === "2") {
        addChange(id, value, null);
      }

      if (type === "3") {
        if (value.includes(".")) {
          const int = value.split(".")?.[0];
          const decimal = value.split(".")?.[1];
          if (int.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else if (decimal.length > parseInt(maxLengthDec)) {
            setStatus("error");
          } else {
            addChange(id, value, null);
            setStatus(null);
          }
        }

        if (value.includes(",")) {
          const int = value.split(",")?.[0];
          const decimal = value.split(",")?.[1];
          if (int.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else if (decimal.length > parseInt(maxLengthDec)) {
            setStatus("error");
          } else {
            addChange(id, value, null);
            setStatus(null);
          }
        }

        if (!value.includes(".") && !value.includes(",")) {
          if (value.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else {
            addChange(id, value, null);
            setStatus(null);
          }
        }
      }
    } else {
      setStatus("error");
    }
  };

  return (
    <Flex flexDirection={"row"}>
      <Input
        type="text"
        onChange={(e) => checkValueValiditiy(id, e.target.value)}
        disabled={isDisabled}
        status={status}
        maxLength={type === "2" ? maxLengthInt : null}
      />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default NumberType;
