import { Tooltip } from "antd";
import Flex from "../../../../../../../helpers/components/Flex";
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useCommonTitlesStore } from "../../../../../../../store/useStores";

const InfoPanel = ({ tags }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  if (!tags?.length) {
    return (
      <Flex
        flexDirection={"column"}
        style={{
          gap: 5,
          marginBottom: 15,
          width: "100%",
          marginTop: 4,
          marginLeft: -15,
        }}
      >
        <i style={{ fontSize: 11, fontWeight: 500 }}>
          {commonTitles["CO|1|USEFULTIPS"]}:
        </i>
        <i style={{ fontSize: 11 }}>
          {"•"} {commonTitles["CO|1|DECSEPOINT"]}
        </i>
        <Flex
          flexDirection={"row"}
          style={{ fontSize: 11, gap: 5, marginLeft: 5 }}
        >
          <CheckOutlined style={{ color: "green" }} /> 2.15
          <CloseOutlined style={{ color: "red", marginLeft: 4 }} /> 2,15
        </Flex>
        <i style={{ fontSize: 11 }}>
          {"•"} {commonTitles["CO|1|DECSEPMATH"]}
        </i>
        <Flex
          flexDirection={"row"}
          style={{ fontSize: 11, gap: 2, marginLeft: 5 }}
        >
          <p>{"pow(2.1"}</p>
          <p style={{ color: "green" }}>{" , "}</p>
          <p>{"3.4)"}</p>
        </Flex>
      </Flex>
    );
  }

  return (
    <Tooltip
      placement="right"
      title={
        <Flex flexDirection={"column"} style={{ gap: 5 }}>
          <i style={{ fontSize: 11 }}>
            {"•"}
            {commonTitles["CO|1|DECSEPOINT"]}:
          </i>
          <Flex flexDirection={"row"} style={{ fontSize: 11, gap: 5 }}>
            <CheckOutlined style={{ color: "green" }} /> 2.15
            <CloseOutlined style={{ color: "red" }} /> 2,15
          </Flex>
          <i style={{ fontSize: 11 }}>
            {"•"}
            {commonTitles["CO|1|DECSEPMATH"]}
          </i>
          <Flex flexDirection={"row"} style={{ fontSize: 11, gap: 2 }}>
            <p>{"pow(2.1"}</p>
            <p style={{ color: "yellow" }}>{" , "}</p>
            <p>{"3.4)"}</p>
          </Flex>
        </Flex>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};

export default InfoPanel;
