import "./alertConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { AlertConfigModal } from "./AlertConfigModal";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import axios from "axios";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import { useState } from "react";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";
import FiltersModal from "../../ultimateTable/helpers/modals/filtersModal/FiltersModal";
import apiGetData from "../../../api/endpoints/apiGetData";

const AlertConfigBody = ({
  data,
  modules,
  titles,
  headers,
  showModal,
  modalVisible,
  modalData,
  updateData,
  handleCancel,
  showDeleteModal,
  fields,
  reverseFiltersChanged,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);
  const [filtersModalVisible, setFiltersModalVisible] = useState(false);
  const [filtersModalData, setFiltersModalData] = useState({});
  const [alertID, setAlertID] = useState(null);

  const showFiltersModal = async (id) => {
    setAlertID(id);

    const moduleID = data.filter((item) => item.A_ID === id)?.[0]?.F_MODULEID;
    const filters =
      data.filter((item) => item.A_ID === id)?.[0]?.A_FILTER ?? {};

    const {
      fields,
      staticoptions,
      fkvalues,
      candelete,
      caninsert,
      canupdate,
      canview,
    } = await apiGetData(
      moduleID,
      baseURL,
      Object.keys(filters)?.length ? filters : {},
      "",
      "",
      50,
      1,
      true
    );

    let tempColumnData = [];
    tempColumnData =
      fields
        ?.filter((field) => field.F_LIST === "Y")
        .map((field) => ({
          ...field,
          T_TITLE: htmlCodesRemover(field.T_TITLE) ?? field.TITLE,
        })) ?? [];

    let hasAnyUpdatePrivileges = false;
    let hasAnyInsertPrivileges = false;
    fields?.forEach((field) => {
      if (field?.canupdate || field?.canupdateifnull) {
        hasAnyUpdatePrivileges = true;
      }
      if (field?.caninsert) {
        hasAnyInsertPrivileges = true;
      }
    });

    const tempFiltersModalData = {
      columns: tempColumnData,
      privileges: {
        canDelete: candelete,
        canInsert: caninsert || hasAnyInsertPrivileges ? true : false,
        canCopy: caninsert,
        canUpdate: canupdate || hasAnyUpdatePrivileges ? true : false,
        canView: canview,
      },
      foreignKeyValues: fkvalues,
      staticOptions: staticoptions,
      filters: filters,
    };

    setFiltersModalData(tempFiltersModalData);
    setFiltersModalVisible(true);
  };

  const applyFilters = (changes) => {
    const currentData = data.filter((item) => item.A_ID === alertID)?.[0];

    const changesForServer = {
      ...currentData,
      ALERTTEXT: currentData.T_TITLE,
      INFORMATIONFIELDS: currentData.A_INFORMATION_FIELDIDS ?? "",
    };

    const alertData = {
      ...changesForServer,
      A_FILTER: { ...changes },
    };

    onSave(alertData);
    reverseFiltersChanged();
    setFiltersModalVisible(false);
  };

  const cancelFiltersModal = () => {
    setFiltersModalVisible(false);
  };

  const eraseFilters = () => {
    const currentData = data.filter((item) => item.A_ID === alertID)?.[0];

    const changesForServer = {
      ...currentData,
      ALERTTEXT: currentData.T_TITLE,
      INFORMATIONFIELDS: currentData.A_INFORMATION_FIELDIDS ?? "",
    };

    const alertData = {
      ...changesForServer,
      A_FILTER: {},
    };

    onSave(alertData);
    reverseFiltersChanged();
    setFiltersModalVisible(false);
  };

  const onSave = async (changes) => {
    const url = `${baseURL}/App_alerts_api/save`;

    const changesForServer = {
      ...modalData,
      ...changes,
      ALERTTEXT: changes.ALERTTEXT ?? modalData.T_TITLE,
      INFORMATIONFIELDS: changes.INFORMATIONFIELDS
        ? changes.INFORMATIONFIELDS
        : changes.INFORMATIONFIELDS === ""
        ? changes.INFORMATIONFIELDS
        : modalData.A_INFORMATION_FIELDIDS,
    };

    try {
      const response = await axios.post(
        url,
        { ...changesForServer },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_alerts_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");

        let infoFieldTitlesValue = "";
        let infoFieldsValue = [];

        if (Object.keys(changes).includes("INFORMATIONFIELDS")) {
          infoFieldsValue = changes.INFORMATIONFIELDS.split(",").map(
            (item) => item
          );
          let str = "";
          infoFieldsValue.map((item) => {
            if (str !== "") {
              str += ", ";
            }
            str +=
              fields[changes.F_MODULEID]?.filter(
                (field) => field.F_ID === item
              )[0]?.T_TITLE ?? "";
          });
          infoFieldTitlesValue += str;
        }

        let triggerFieldTitlesValue = "";

        if (changes.A_TRIGGER_FIELDID) {
          triggerFieldTitlesValue = fields[changes.F_MODULEID].filter(
            (field) => field.F_ID === changes.A_TRIGGER_FIELDID
          )[0]?.T_TITLE;
        }

        let changesWithExtraInfo = {};

        if (Object.keys(changes).includes("INFORMATIONFIELDS")) {
          changesWithExtraInfo = {
            INFOFIELDTITLES: infoFieldTitlesValue,
            INFORMATIONFIELDS: infoFieldsValue,
            A_INFORMATION_FIELDIDS: changes.INFORMATIONFIELDS,
          };
        }

        if (Object.keys(changes).includes("A_TRIGGER_FIELDID")) {
          changesWithExtraInfo = {
            ...changesWithExtraInfo,
            TRIGGERFIELDTITLE: triggerFieldTitlesValue,
          };
        }

        changesWithExtraInfo = {
          ["A_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          ...modalData,
          ...changes,
          ...changesWithExtraInfo,
          T_TITLE: changes?.ALERTTEXT ? changes?.ALERTTEXT : modalData.T_TITLE,
        };
        updateData("edit", changesWithExtraInfo, modalData.A_ID);
        setCoreChange(!coreChange);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        displayResponseMessage(responseMsg, 5, "warning");
        //message.warning(responseMsg, [5]);
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <TableBody>
      {data.map((item) => (
        <TableRow key={item.A_ID}>
          <TableCell>
            <Tooltip placement="right" title={titles["MO|7|EDITROW"]}>
              <EditOutlined
                onClick={() => showModal(item, "edit")}
                style={{
                  fontSize: "18px",
                  color: "rgb(8, 8, 46)",
                }}
              />
            </Tooltip>
            {modalVisible && (
              <AlertConfigModal
                key={`${item.A_ID}-modal`}
                visible={modalVisible}
                data={modalData}
                headers={headers}
                titles={titles}
                modules={modules}
                onSave={onSave}
                showDeleteModal={showDeleteModal}
                onCancel={handleCancel}
                fields={fields}
                config={"edit"}
              />
            )}
          </TableCell>
          <TableCell key={`${item.A_ID}-module`}>
            {htmlCodesRemover(
              Object.entries(modules).filter(
                ([key, value]) => key === item.F_MODULEID
              )[0]?.[1]
            )}
          </TableCell>
          <TableCell key={`${item.A_ID}-trigger`}>
            {htmlCodesRemover(item.TRIGGERFIELDTITLE)}
          </TableCell>
          <TableCell key={`${item.A_ID}-before`}>
            {item.A_WARNING_BEFORE}
          </TableCell>
          <TableCell key={`${item.A_ID}-after`}>
            {item.A_RAISED_AFTER}
          </TableCell>
          <TableCell key={`${item.A_ID}-infofield`}>
            {htmlCodesRemover(item.INFOFIELDTITLES)}
          </TableCell>
          <TableCell key={`${item.A_ID}-infotext`}>
            {htmlCodesRemover(item.T_TITLE)}
          </TableCell>
          <TableCell align="center" key={`${item.A_ID}-status`}>
            {item.A_ACTIVE === "Y" ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </TableCell>
          <TableCell key={`${item.A_ID}-filters`} align="center">
            <Button
              shape="round"
              className="dropdown"
              onClick={() => {
                showFiltersModal(item.A_ID);
              }}
              style={{ backgroundColor: "rgb(8, 8, 46)", color: "#fff" }}
            >
              {titles["FD|231|TITLE"]} (
              {Object?.keys(item?.A_FILTER ?? {}).length})
            </Button>
          </TableCell>
        </TableRow>
      ))}
      {filtersModalVisible && (
        <FiltersModal
          visible={filtersModalVisible}
          columns={filtersModalData.columns}
          staticOptions={filtersModalData.staticOptions}
          foreignKeyValues={filtersModalData.foreignKeyValues}
          onSave={applyFilters}
          onCancel={cancelFiltersModal}
          onErase={eraseFilters}
          filters={filtersModalData.filters}
          privileges={filtersModalData.privileges}
        />
      )}
    </TableBody>
  );
};

export default AlertConfigBody;
