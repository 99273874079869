import React from "react";
import { useState } from "react";
import DateField from "./menu/DateField";
import Grouping from "./menu/Grouping";
import GroupField from "./menu/GroupField";
import DisplayFunction from "./menu/DisplayFunction";
import ChartType from "./menu/ChartType";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Layout, Card, Button, Tooltip, message } from "antd";
import { useCommonTitlesStore, useModuleStore } from "../../../store/useStores";
import { useChartStore } from "../../../store/useStores";
import "../css/ultimateChart.css";
import { useEffect } from "react";
import Flex from "../../../helpers/components/Flex";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";
import { LeftOutlined } from "@ant-design/icons";
import SumField from "./menu/SumField";

const { Content } = Layout;

const UltimateChartSettings = ({
  dateFieldOptions,
  groupFieldOptions,
  sumFieldOptions,
  invertChartVisibility,
  chartVisible,
  changeChartParamMenuVisible,
  eraseButton,
  setEraseButton,
  eraseButton2,
  setEraseButton2,
  columns,
}) => {
  //const [eraseButton, setEraseButton] = useState(false);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const numberTypes = useModuleStore((state) => state.numberTypes);

  const chartType = useChartStore((state) => state.chartType);
  const setChartType = useChartStore((state) => state.setChartType);

  const grouping = useChartStore((state) => state.grouping);
  const setGrouping = useChartStore((state) => state.setGrouping);

  const dateField = useChartStore((state) => state.dateField);
  const setDateField = useChartStore((state) => state.setDateField);

  const groupField = useChartStore((state) => state.groupField);
  const setGroupField = useChartStore((state) => state.setGroupField);

  const displayFunction = useChartStore((state) => state.displayFunction);
  const setDisplayFunction = useChartStore((state) => state.setDisplayFunction);

  const dateTypes = useModuleStore((state) => state.dateTypes);

  const setChartBase64 = useChartStore((state) => state.setChartBase64);

  const { width } = useWindowDimensions();

  const [showGrouping, setShowGrouping] = useState(false);
  //const [showSumField, setShowSumField] = useState(false);

  let childxAxisField;
  let xAxisFieldToUse = dateField;

  if (dateField?.includes("-")) {
    let tempXAxisField = dateField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const dateColumns = columns
    .filter((column) => dateTypes.includes(column.F_TYPE))
    .map((value) => value.F_ID);

  let dependendDate = false;

  if (
    childxAxisField &&
    dateTypes.includes(
      columns
        .filter((column) => column?.F_ID === xAxisFieldToUse)?.[0]
        ?.DEP_FIELDS.filter((field) => field?.F_ID === childxAxisField)?.[0]
        ?.F_TYPE
    )
  ) {
    dependendDate = true;
  }

  let dependendNumber = false;

  if (
    childGroupField &&
    numberTypes.includes(
      columns
        .filter((column) => column?.F_ID === groupFieldToUse)?.[0]
        ?.DEP_FIELDS.filter((field) => field?.F_ID === childGroupField)?.[0]
        ?.F_TYPE
    )
  ) {
    dependendNumber = true;
  }

  let showSumField = false;

  const groupFieldType = columns.filter(
    (column) => column.F_ID === groupField
  )?.[0]?.F_TYPE;

  if (sumFieldOptions.length) {
    if (dateColumns.includes(dateField) || dependendDate) {
      if (groupField && ["sum", "pctSum"].includes(displayFunction)) {
        showSumField = true;
      }
    }

    // if (
    //   numberTypes.includes(groupFieldType) &&
    //   ["sum", "pctSum"].includes(displayFunction)
    // ) {
    //   showSumField = true;
    // }
  }

  useEffect(() => {
    if (
      !dateField ||
      !grouping ||
      // !groupField ||
      !displayFunction ||
      !chartType
    ) {
      setEraseButton(false);
      if (chartVisible) {
        invertChartVisibility();
      }
    }
  }, [dateField, grouping, groupField, displayFunction, chartType]);

  useEffect(() => {
    if (dateColumns.includes(dateField) || dependendDate) {
      setShowGrouping(true);
    } else {
      setShowGrouping(false);
      setGrouping("Daily");
    }
  }, [dateField]);

  return (
    <Content
      key={showSumField}
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Card
        style={{
          borderRadius: "10px",
          paddingBottom: -8,
          width: width < 1525 ? "14vw" : "100%",
        }}
        bodyStyle={{
          width: width < 1525 ? "max-content" : "100%",
          height: width < 1525 ? "80vh" : "max-content",
        }}
      >
        {/* <div className="chart__menu"> */}
        <Flex
          style={{
            gap: "1rem",
            alignItems: width < 1525 ? "flex-start" : "center",
            justifyContent: width < 1525 ? "flex-start" : "space-between",
            width: width < 1525 ? "max-content" : "95vw",
            heigth: "100%",
            marginTop: width > 1525 && -12,
            marginBottom: width > 1525 && -4,
          }}
          flexDirection={width < 1525 ? "column" : "row"}
        >
          {chartVisible && width < 1525 && (
            <Button
              style={{
                border: "none",
                color: "rgb(84, 190, 243)",
                marginLeft: -20,
                marginTop: -10,
              }}
              icon={<LeftOutlined style={{ color: "rgb(84, 190, 243)" }} />}
              onClick={() => changeChartParamMenuVisible(false)}
            >
              {commonTitles["CO|1|HIDE"]}
            </Button>
          )}
          <Flex
            flexDirection={"column"}
            style={{ justifyContent: "flex-start", gap: 2, marginLeft: -10 }}
          >
            <p>{commonTitles["CO|1|XAXISFLD"]}:</p>
            <DateField
              key={dateField ? `${groupField}-datefield` : "blank-dateField"}
              dateFieldOptions={dateFieldOptions}
            />
          </Flex>

          <Flex
            flexDirection={"column"}
            style={{
              justifyContent: "flex-start",
              gap: 2,
              display: !showGrouping && "none",
              marginLeft: -10,
            }}
          >
            <p style={{ marginTop: width < 1525 ? 10 : 0 }}>
              {commonTitles.grouping}:
            </p>
            <Grouping key={grouping ? grouping : "blank-grouping"} />
          </Flex>

          <Flex
            flexDirection={"column"}
            style={{ justifyContent: "flex-start", gap: 2, marginLeft: -10 }}
          >
            <p style={{ marginTop: width < 1525 ? 10 : 0 }}>
              {commonTitles.groupfield}:
            </p>
            <GroupField
              key={groupField ? `${groupField}-groupfield` : "blank-groupField"}
              groupFieldOptions={groupFieldOptions}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            style={{ justifyContent: "flex-start", gap: 2, marginLeft: -10 }}
          >
            <p style={{ marginTop: width < 1525 ? 10 : 0 }}>
              {commonTitles.displayFunction}:
            </p>
            <DisplayFunction
              key={displayFunction ? displayFunction : "blank-displayFunction"}
              columns={columns}
              sumFieldOptions={sumFieldOptions}
              dependendDate={dependendDate}
              dependendNumber={dependendNumber}
            />
          </Flex>

          <Flex
            flexDirection={"column"}
            style={{
              justifyContent: "flex-start",
              gap: 2,
              display: !showSumField && "none",
              marginLeft: -10,
            }}
          >
            <p style={{ marginTop: width < 1525 ? 10 : 0 }}>
              {commonTitles["CO|1|SUMFIELD"]}:
            </p>
            <SumField
              key={displayFunction ? displayFunction : "blank-displayFunction"}
              sumFieldOptions={sumFieldOptions}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            style={{ justifyContent: "flex-start", gap: 2, marginLeft: -10 }}
          >
            <p style={{ marginTop: width < 1525 ? 10 : 0 }}>
              {commonTitles.chartType}:
            </p>
            <ChartType key={chartType ? chartType : "blank-chartType"} />
          </Flex>

          <div className={width < 1525 ? "buttons-vertical" : "buttons"}>
            {!eraseButton && (
              <Flex flexDirection={"row"}>
                <Tooltip
                  placement="bottom"
                  title={commonTitles["CO|1|CALCULATE"]}
                >
                  <Button
                    style={{
                      backgroundColor: "rgb(8, 8, 46)",
                      color: "#fff",
                    }}
                    shape="round"
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                      if (
                        // groupField &&
                        dateField &&
                        displayFunction &&
                        grouping &&
                        chartType
                      ) {
                        setEraseButton(true);
                        !chartVisible && invertChartVisibility();
                      } else {
                        const missingMenuSettings = [
                          {
                            value: dateField,
                            stringValue: commonTitles.datefield,
                          },
                          {
                            value: grouping,
                            stringValue: commonTitles.grouping,
                          },
                          {
                            value: groupField,
                            stringValue: commonTitles.groupfield,
                          },
                          {
                            value: displayFunction,
                            stringValue: commonTitles.displayFunction,
                          },
                          {
                            value: chartType,
                            stringValue: commonTitles.chartType,
                          },
                        ];
                        let missingMenuSettingsString = "";
                        missingMenuSettings.forEach((item) => {
                          if (!item.value) {
                            if (missingMenuSettingsString !== "") {
                              missingMenuSettingsString += ", ";
                            }
                            missingMenuSettingsString += item.stringValue;
                          }
                        });
                        if (missingMenuSettingsString.includes(",")) {
                          let ucSetMsg2 = commonTitles["CO|1|UCSETMSG2"];
                          var re = new RegExp("<1>", "g");
                          ucSetMsg2 = ucSetMsg2.replace(
                            re,
                            missingMenuSettingsString
                          );
                          displayResponseMessage(
                            <p style={{ margin: 5 }}>{ucSetMsg2}</p>,
                            3,
                            "info"
                          );
                        } else {
                          let ucSetMsg1 = commonTitles["CO|1|UCSETMSG1"];
                          var re = new RegExp("<1>", "g");
                          ucSetMsg1 = ucSetMsg1.replace(
                            re,
                            missingMenuSettingsString
                          );
                          displayResponseMessage(
                            <p style={{ margin: 5 }}>{ucSetMsg1}</p>,
                            3,
                            "info"
                          );
                        }
                      }
                    }}
                  />
                </Tooltip>
              </Flex>
            )}
            {eraseButton && (
              <Tooltip placement="bottom" title={commonTitles["CO|1|CLEARALL"]}>
                <Button
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    marginLeft: "5px",
                  }}
                  shape="round"
                  icon={<CloseOutlined />}
                  onClick={(e) => {
                    setEraseButton(false);
                    setChartType(null);
                    setDateField(null);
                    setDisplayFunction(null);
                    setGroupField(null);
                    setGrouping(null);
                    setChartBase64("");
                    invertChartVisibility();
                  }}
                />
              </Tooltip>
            )}
          </div>
        </Flex>
        {/* </div> */}
      </Card>
    </Content>
  );
};

export default UltimateChartSettings;
