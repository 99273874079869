import Flex from "../../../../../../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../../../../../../store/useStores";

export const ChooseTagTooltip = ({ tag, functionTags, fieldTags }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  if (tag.includes("#")) {
    return (
      <Flex flexDirection={"column"} style={{ gap: 3 }}>
        {functionTags[tag]?.fieldTitle}
        {functionTags[tag].tooltip}
        <i style={{ marginTop: 4, fontSize: 12 }}>
          {commonTitles["CO|1|DBCLKMSG"]}
        </i>
      </Flex>
    );
  }

  if (tag.includes("[")) {
    return (
      <Flex flexDirection={"column"} style={{ gap: 3, style: "max-content" }}>
        <p>
          {commonTitles["CO|1|MODULE"]}:{fieldTags[tag]?.moduleTitle}
        </p>
        <p>
          {commonTitles["CO|1|FIELD"]}: {fieldTags[tag]?.fieldTitle}
        </p>
        <i style={{ marginTop: 4, fontSize: 12 }}>
          {commonTitles["CO|1|DBCLKMSG"]}
        </i>
      </Flex>
    );
  }

  return <i style={{ fontSize: 12 }}>{commonTitles["CO|1|DBCLKMSG"]}</i>;
};

export const chooseTagColor = (tag) => {
  if (tag.includes("[")) {
    // if (tag.includes("|")) {
    //   return "cyan";
    // }
    return "blue";
  }

  if (tag.includes("#") || tag === ",") {
    return "yellow";
  }

  return "default";
};

export const chooseTagTitle = (tag, fieldTags, functionTags) => {
  if (tag.includes("[")) {
    return fieldTags[tag]?.fieldTitle;
  }

  if (tag.includes("#")) {
    return functionTags[tag].value;
  }

  return tag;
};
