import React from "react";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@material-ui/core/styles";
import {
  useCommonTitlesStore,
  useChartStore,
} from "../../../../store/useStores";

function valuetext(value) {
  return `${value}`;
}

const radiusMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 0.15,
    label: "0.15",
  },
  {
    value: 0.3,
    label: "0.3",
  },
  {
    value: 0.45,
    label: "0.45",
  },
  {
    value: 0.6,
    label: "0.6",
  },
  {
    value: 0.75,
    label: "0.75",
  },
];

const useStyles = makeStyles((theme) => ({
  mark: {
    color: "rgb(8, 8, 46)",
  },
  valueLabelDisplay: {
    color: "white",
    background: "rgb(8, 8, 46)",
  },
}));

const InnerRadius = () => {
  const classes = useStyles();
  const innerRadius = useChartStore((state) => state.innerRadius);
  const setInnerRadius = useChartStore((state) => state.setInnerRadius);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <div className="chart__options-angles">
      <p>{commonTitles.innerRadius}</p>
      <Slider
        sx={{ width: 160, mr: 3, mt: -1 }}
        size="small"
        aria-label="Inner Radius"
        value={innerRadius}
        onChange={(e) => setInnerRadius(e.target.value)}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={0.05}
        marks={radiusMarks}
        classes={{
          markLabel: classes.mark,
          valueLabel: classes.valueLabelDisplay,
        }}
        min={0}
        max={0.75}
      />
    </div>
  );
};

export default InnerRadius;
