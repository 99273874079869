import { Modal, Select, Button, DatePicker } from "antd";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
//import Select from "@mui/material/Select";
import "../reports.css";
import Box from "@mui/material/Box";
import {
  useCommonTitlesStore,
  useAuthStore,
} from "../../../../../store/useStores";
import dayjs from "dayjs";
import { convertDateFormat } from "../../../../module/banner/newRegister/helpers/converters";

const { Option } = Select;

const ChartReportModalContent = ({ updateReportParams, title, today }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [fileType, setFileType] = useState("P");
  const [paperSize, setPaperSize] = useState("4");
  const [allignment, setAllignment] = useState("L");
  const [fileName, setFileName] = useState(
    title.includes(" ") ? title.split(" ").join("_") : title
  );
  const [reportTitle, setReportTitle] = useState(title);
  const [fontSize, setFontSize] = useState("10");
  const [lastUpdate, setLastUpdate] = useState(today);
  const [review, setReview] = useState("");

  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);

  const fontSizeOptions = [
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
  ];

  return (
    <div className="chart-reports__dialog">
      <Box
        key={`chart-report-info`}
        className="chart-reports__box"
        component="form"
      >
        <div className="chart-reports__box-firstColumn">
          <p>{commonTitles.reportFormat}:</p>
          <p>{commonTitles.filename}:</p>
          <p>{commonTitles.reportTitle}:</p>
          <p>{commonTitles.fontSize}:</p>
          <p>{commonTitles.lastUpdate}:</p>
          <p>{commonTitles.revisionNumber}:</p>
        </div>
        <div className="chart-reports__box-secondColumn">
          <div className="chart-reports_box-secondColumn_type">
            <FormControl sx={{ m: 1, Width: 40 }}>
              <Select
                style={{
                  width: "max-content",
                  marginLeft: 1,
                  marginTop: 1,
                }}
                showSearch={true}
                filterOption={(iv, op) =>
                  op.value
                    .toLocaleLowerCase()
                    .includes((iv || "").toLocaleLowerCase())
                }
                onChange={(e) => {
                  setFileType(e);
                  updateReportParams("docformat", e);
                }}
                value={fileType ?? ""}
                dropdownStyle={{ overflowY: "auto" }}
                virtual={false}
              >
                <Option key={"P"} className="option" value="P">
                  PDF
                </Option>
                <Option key={"E"} className="option" value="E">
                  Excel
                </Option>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, Width: 30 }}>
              <Select
                style={{
                  width: "max-content",
                  marginLeft: 1,
                  marginTop: 1,
                }}
                showSearch={true}
                filterOption={(iv, op) =>
                  op.value
                    .toLocaleLowerCase()
                    .includes((iv || "").toLocaleLowerCase())
                }
                onChange={(e) => {
                  setPaperSize(e);
                  updateReportParams("docsize", e);
                }}
                value={paperSize}
                dropdownStyle={{ overflowY: "auto" }}
                virtual={false}
              >
                <Option key={"3"} className="option" value="3">
                  A3
                </Option>
                <Option key={"4"} className="option" value="4">
                  A4
                </Option>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, Width: 60 }}>
              <Select
                style={{
                  width: "max-content",
                  marginLeft: 1,
                  marginTop: 1,
                }}
                showSearch={true}
                filterOption={(iv, op) =>
                  op.value
                    .toLocaleLowerCase()
                    .includes((iv || "").toLocaleLowerCase())
                }
                onChange={(e) => {
                  setAllignment(e);
                  updateReportParams("orientation", e);
                }}
                value={allignment}
                dropdownStyle={{ overflowY: "auto" }}
                virtual={false}
              >
                <Option key={"L"} className="option" value="L">
                  {commonTitles.horizontal}
                </Option>
                <Option key={"P"} className="option" value="P">
                  {commonTitles.vertical}
                </Option>
              </Select>
            </FormControl>
          </div>
          <FormControl sx={{ m: 1, mt: -1, minWidth: 120 }}>
            <input
              type="text"
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
                updateReportParams("filename", e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, mt: -1.3, minWidth: 120 }}>
            <input
              type="text"
              value={reportTitle}
              onChange={(e) => {
                setReportTitle(e.target.value);
                updateReportParams("reporttitle", e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, mt: 0.1, minWidth: 120 }}>
            <Select
              style={{
                width: "80px",
                marginLeft: 1,
                marginTop: 1,
              }}
              showSearch={true}
              filterOption={(iv, op) => {
                let opToUse = op.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              onChange={(e) => {
                setFontSize(e);
                updateReportParams("fontsize", e);
              }}
              value={fontSize}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              {fontSizeOptions.map((value) => (
                <Option key={value} className="option" value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </FormControl>
          <DatePicker
            format={dateFormatToUse}
            placeholder={[commonTitles["CO|1|SLCTDATE"]]}
            onChange={(dateString) => {
              if (dateString) {
                updateReportParams(
                  "lastupdate",
                  dateString.format(dateFormatToUse)
                );
              } else {
                updateReportParams("lastupdate", "");
              }
            }}
            bordered={true}
            style={{
              width: 265,
              marginTop: 5,
              marginLeft: 8,
              height: 34,
            }}
            defaultValue={lastUpdate ? dayjs(lastUpdate, dateFormatToUse) : ""}
          />
          <FormControl sx={{ m: 1, mt: -1.4, minWidth: 120 }}>
            <input
              type="text"
              value={review}
              onChange={(e) => {
                setReview(e.target.value);
                updateReportParams("revisionnumber", e.target.value);
              }}
            />
          </FormControl>
        </div>
      </Box>
    </div>
  );
};

export const ChartReportModal = ({
  visible,
  onSave,
  onCancel,
  today,
  title,
  updateReportParams,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Modal
      title={commonTitles.report}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      destroyOnClose={true}
      width="max-content"
      style={{ marginTop: -90.5 }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles.cancel}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={onSave}
        >
          {commonTitles.report}
        </Button>,
      ]}
    >
      <ChartReportModalContent
        updateReportParams={updateReportParams}
        title={title}
        today={today}
      />
    </Modal>
  );
};
