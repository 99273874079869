import { Input } from "antd";

const TextInput = ({ id, data, addChange, config }) => {
  return (
    <div className="select">
      <Input
        style={{
          width: 300,
          marginLeft: 15,
          marginTop: 0,
          marginBottom: "1rem",
        }}
        onChange={(e) => addChange(id, e.target.value)}
        defaultValue={config === "edit" ? data[id] : null}
      />
    </div>
  );
};

export default TextInput;
