import "./modulesConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { ModulesConfigModal } from "./modals/ModulesConfigModal";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useAdminStore,
  useAuthStore,
  useCommonTitlesStore,
} from "../../../store/useStores";
import axios from "axios";
import { useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { TitlesModal } from "./modals/TitlesModal";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const ModulesConfigBody = ({
  data,
  staticOptions,
  titles,
  headers,
  showModal,
  showDeleteModal,
  modalVisible,
  modalData,
  updateData,
  handleCancel,
  handleDelete,
}) => {
  let navigate = useNavigate();
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const setModuleID = useAdminStore((state) => state.setModuleID);
  const setBannerTitle = useAdminStore((state) => state.setBannerTitle);
  const [moduleToEdit, setModuleToEdit] = useState({});
  const [moduleIndexToEdit, setModuleIndexToEdit] = useState(null);
  const [titlesModalVisible, setTitlesModalVisible] = useState(false);

  const routeChange = (id, title) => {
    setModuleID(id);
    setBannerTitle(title);
    let path = `/config/modules/${id}`;
    navigate(path);
  };

  const onSave = async (changes) => {
    const url = `${baseURL}/app_modules_api/save`;

    try {
      const response = await axios.post(
        url,
        {
          id: moduleToEdit.M_ID,
          name: changes?.T_TITLE ?? moduleToEdit.T_TITLE,
          singularname: changes?.TITLESINGULAR ?? moduleToEdit.TITLESINGULAR,
          abbr: changes?.M_ABBREVIATION ?? moduleToEdit.M_ABBREVIATION,
          descr: changes?.DESCR ?? moduleToEdit.DESCR,
          type: changes?.M_TYPE ?? moduleToEdit.M_TYPE,
          projectspecific:
            changes?.M_PROJECTSPECIFIC ?? moduleToEdit.M_PROJECTSPECIFIC,
          copyfrommodule: changes?.copyfrommodule ?? "",
          copydata: changes?.copydata ?? "N",
          copyfromproject: "",
          lastmodified: moduleToEdit.M_LASTMODIFIED,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("app_modules_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changesWithExtraInfo = {
          ["M_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          ...changes,
        };
        updateData("edit", changesWithExtraInfo, moduleIndexToEdit);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const showTitlesModal = () => setTitlesModalVisible(true);

  const cancelTitlesModal = () => setTitlesModalVisible(false);

  const saveTitles = async (changes) => {
    const url = `${baseURL}/App_titles2_api/save`;

    try {
      const response = await axios.post(
        url,
        { ...changes },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_titles2_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        setTitlesModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <TableBody>
      {data.map((item, index) => (
        <TableRow key={item.M_ID}>
          <TableCell>
            <Tooltip placement="right" title={titles["MO|2|EDITROW"]}>
              <EditOutlined
                onClick={() => {
                  showModal(item, "edit");
                  setModuleToEdit(item);
                  setModuleIndexToEdit(index);
                }}
                style={{
                  fontSize: "18px",
                  color: "rgb(8, 8, 46)",
                }}
              />
            </Tooltip>
          </TableCell>
          <TableCell>
            {item?.T_TITLE?.includes("&#39;")
              ? item?.T_TITLE.split("&#39;")
                  .filter((item) => item !== "")
                  .join("'")
              : item?.T_TITLE}
          </TableCell>
          <TableCell>{item?.M_ABBREVIATION ?? ""} </TableCell>
          <TableCell>
            {item?.DESCR?.includes("&#39;")
              ? item?.DESCR.split("&#39;")
                  .filter((item) => item !== "")
                  .join("'")
              : item?.DESCR}
          </TableCell>
          <TableCell>
            {item?.M_TYPE === "M"
              ? staticOptions?.[239]?.M?.TITLE
              : staticOptions?.[239]?.P?.TITLE}
          </TableCell>
          <TableCell align="center">
            {item?.M_PROJECTSPECIFIC === "Y" ? (
              <CheckOutlined style={{ color: "green" }} />
            ) : (
              <CloseOutlined style={{ color: "red" }} />
            )}
          </TableCell>
          <TableCell width="10%">
            <Button
              shape="round"
              className="dropdown"
              onClick={() => routeChange(item.M_ID, item?.T_TITLE)}
              style={{ backgroundColor: "rgb(8, 8, 46)", color: "#fff" }}
            >
              {titles["MO|2|BTNFIELDS"]}
            </Button>
          </TableCell>
          <TableCell width="10%">
            <Button
              shape="round"
              className="dropdown"
              onClick={() => {
                setModuleToEdit(item);
                showTitlesModal();
              }}
              style={{ backgroundColor: "rgb(8, 8, 46)", color: "#fff" }}
            >
              {titles["MO|2|BTNTITLES"]}
            </Button>
          </TableCell>
        </TableRow>
      ))}
      {modalVisible && (
        <ModulesConfigModal
          visible={modalVisible}
          data={modalData}
          modules={data}
          headers={headers}
          staticOptions={staticOptions}
          titles={titles}
          onSave={onSave}
          onDelete={handleDelete}
          onCancel={handleCancel}
          config={"edit"}
          showDeleteModal={showDeleteModal}
        />
      )}
      {titlesModalVisible && (
        <TitlesModal
          visible={titlesModalVisible}
          moduleID={moduleToEdit.M_ID}
          onSave={saveTitles}
          onCancel={cancelTitlesModal}
        />
      )}
    </TableBody>
  );
};

export default ModulesConfigBody;
