const filterAlertsOnSearch = (allData, change) => {
  let tempData = [];
  const replacements = [
    { key: "ί", newKey: "ι" },
    { key: "έ", newKey: "ε" },
    { key: "ό", newKey: "ο" },
    { key: "ή", newKey: "η" },
    { key: "ύ", newKey: "υ" },
    { key: "ώ", newKey: "ω" },
    { key: "ά", newKey: "α" },
  ];

  if (Object.keys(allData).length) {
    let allAlerts = Object.values(allData).map((item) => item);
    allAlerts.forEach((datum) => {
      let isIncluded = false;
      Object.entries(datum)
        .filter(([key]) => !["key", "rowID", "moduleID"].includes(key))
        .forEach(([key, value]) => {
          if (change) {
            change = change.toLowerCase();
            const dataKey = key;
            let valueToTest = "";
            replacements.forEach(({ key, newKey }) => {
              var re = new RegExp(key, "g");
              change = change.replace(re, newKey);

              if (dataKey === "status") {
                valueToTest = value?.view.replace(re, newKey);
              } else if (dataKey === "info") {
                let newValue = "";
                value.forEach((item) => {
                  if (newValue !== "") {
                    newValue += ", ";
                  }
                  if (item.value) {
                    newValue += item.value;
                  }
                });
                valueToTest = newValue.replace(re, newKey);
              } else {
                valueToTest = value.replace(re, newKey);
              }
            });
            if (valueToTest && valueToTest.toLowerCase().includes(change)) {
              isIncluded = true;
            }
          }
        });
      isIncluded && tempData.push(datum);
    });
  }

  return { ...tempData };
};

export default filterAlertsOnSearch;
