import { Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { tagPlusStyle } from "../../styles/TagItemStyles";
import { useCommonTitlesStore } from "../../../../../../../../store/useStores";

const AddMathOperationButton = ({ showInput }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
      {commonTitles["CO|1|ADDMTHOP"]}
    </Tag>
  );
};

export default AddMathOperationButton;
