const config = (set) => ({
  commonTitles: {},
  setCommonTitles: (newItem) => set(() => ({ commonTitles: newItem })),
});

const CommonTitlesStore = {
  config,
  shouldLog: true,
};

export default CommonTitlesStore;
