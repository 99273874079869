import useWindowDimensions from "../../components/ultimateTable/hooks/useWindowDimensions";
import { useAuthStore } from "../../store/useStores";
import Flex from "./Flex";

const BannerWrapper = ({ title, path, children }) => {
  const bannerColor = useAuthStore((state) => state.bannerColor);
  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );
  const bannerPathColor = useAuthStore((state) => state.bannerPathColor);
  const { width } = useWindowDimensions();

  const style = {
    paddingTop: "5rem",
    position: "fixed",
    paddingLeft: "2rem",
    backgroundColor: bannerColor,
    width: "100%",
    paddingBottom: "1rem",
    justifyContent: "flex-start",
    alignItems: "center",
    zIndex: 1,
    height: 100,
  };

  const titleStyle = {
    fontFamily: "var(--font-family)",
    fontWeight: 300,
    fontSize: width < 545 ? 6 : width < 900 ? 10 : 20,
    letterSpacing: "0.04em",
    wordSpacing: "0.1em",
    marginBottom: "0.4rem",
    color: navigationTextColor,
  };

  const pathStyle = {
    fontFamily: "var(--font-family)",
    fontWeight: 300,
    fontSize: width < 545 ? 4 : width < 900 ? 8 : 18,
    letterSpacing: "0.04em",
    wordSpacing: "0.1em",
    marginTop: 2,
    marginLeft: "0.2rem",
    color: bannerPathColor,
  };

  return (
    <Flex flexDirection={"row"} style={style}>
      <Flex flexDirection={"row"}>
        <h1 style={titleStyle}>{title}</h1>
        {path && <h3 style={pathStyle}>{` /${path}`}</h3>}
      </Flex>
      {children}
    </Flex>
  );
};

export default BannerWrapper;
