import Flex from "../../../../../../helpers/components/Flex";
import { WarningOutlined } from "@ant-design/icons";
import AutomaticIdType from "./automaticIdType/AutomaticIdType";
import CheckboxType from "./checkboxType/CheckboxType";
import DateTimeType from "./dateType/DateTimeType";
import OnlyDateType from "./dateType/OnlyDateType";
import DependentType from "./dependentType/DependentType";
import DocumentType from "./documentType/DocumentType";
import EmailType from "./emailType/EmailType";
import ImageType from "./imageType/ImageType";
import MultipleSelectionType from "./otherModuleType/MultipleSelectionType";
import SingleSelectionType from "./otherModuleType/SingleSelectionType";
import PhoneType from "./phoneType/PhoneType";
import StaticListType from "./staticListType/StaticListType";
import TextType from "./textType/TextType";
import TextareaType from "./textareaType/TextareaType";
import ButtonType from "./buttonType/ButtonType";
import NumberType from "./numberType/NumberType";
import { Input } from "antd";
import CountingField from "./countingField/CountingField";

const inputDecider = (
  required,
  type,
  id,
  staticOptions,
  foreignKeyValues,
  addChange,
  moduleID,
  autoCompletedFields,
  parentField,
  changes,
  size
) => {
  if (type === "0" /* type = single line*/) {
    return <Flex flexDirection={"row"} />;
  }

  if (type === "1" /* type = text*/) {
    return (
      <TextType
        id={id}
        required={required}
        addChange={addChange}
        autoCompletedFields={autoCompletedFields}
        size={size}
      />
    );
  }

  if (type === "27" /* type = textarea*/) {
    return (
      <TextareaType
        id={id}
        required={required}
        addChange={addChange}
        autoCompletedFields={autoCompletedFields}
        size={size}
      />
    );
  }

  if (type === "29" /* type = Automatic id */) {
    return <AutomaticIdType />;
  }

  if (type === "30" /* type = Dependent field*/) {
    return <DependentType />;
  }

  if (type === "24" /* type = Document link */) {
    return <DocumentType id={id} required={required} addChange={addChange} />;
  }

  if (type === "25" /* type = Single image link */) {
    return <ImageType id={id} required={required} addChange={addChange} />;
  }

  if (type === "4" /* type = date*/) {
    return (
      <OnlyDateType
        id={id}
        required={required}
        addChange={addChange}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "5" /* type = date & time*/) {
    return (
      <DateTimeType
        id={id}
        required={required}
        addChange={addChange}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "6" /* type = Values from static list */) {
    return (
      <StaticListType
        id={id}
        required={required}
        addChange={addChange}
        staticOptions={staticOptions}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "7" /* type = Values from another module */) {
    return (
      <SingleSelectionType
        id={id}
        required={required}
        addChange={addChange}
        foreignKeyValues={foreignKeyValues}
        autoCompletedFields={autoCompletedFields}
        changes={changes}
        parentField={parentField}
        moduleID={moduleID}
      />
    );
  }

  if (type === "26" /* type = Button */) {
    return (
      <ButtonType
        moduleID={moduleID}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "28" /* type = Values from another module (multiple)*/) {
    return (
      <MultipleSelectionType
        id={id}
        required={required}
        addChange={addChange}
        foreignKeyValues={foreignKeyValues}
        autoCompletedFields={autoCompletedFields}
        changes={changes}
        parentField={parentField}
        moduleID={moduleID}
      />
    );
  }

  if (type === "33" /* type = email*/) {
    return (
      <EmailType
        id={id}
        required={required}
        addChange={addChange}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "36" /* type = phone*/) {
    return (
      <PhoneType
        id={id}
        required={required}
        addChange={addChange}
        autoCompletedFields={autoCompletedFields}
      />
    );
  }

  if (type === "99" /* type === Checkbox */) {
    return <CheckboxType id={id} required={required} addChange={addChange} />;
  }

  if (type === "2" /* type = integer */ || type === "3" /* type = decimal */) {
    return (
      <NumberType
        id={id}
        required={required}
        addChange={addChange}
        autoCompletedFields={autoCompletedFields}
        size={size}
        type={type}
      />
    );
  }

  if (type === "116" /* type = counting field */) {
    return <CountingField />;
  }

  return (
    <Flex flexDirection={"row"}>
      <Input
        type="text"
        style={{ minWidth: 275 }}
        onChange={(e) => addChange(id, e.target.value, null)}
      />
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default inputDecider;
