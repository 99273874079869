import Flex from "../../../../../../helpers/components/Flex";
import CustomDatePicker from "../../../../../../helpers/components/CustomDatepicker";
import { useCommonTitlesStore } from "../../../../../../store/useStores";

const FilterDateType = ({ value, id, config, addChange }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Flex flexDirection={"column"}>
      <Flex flexDirection={"row"}>
        <div
          className="datepicker-text"
          style={{ width: 27, display: "flex", justifyContent: "right" }}
        >
          <p>{commonTitles["CO|1|FROM"]}:</p>
        </div>
        <CustomDatePicker
          value={value}
          id={id}
          config={config}
          suffix="from"
          isDisabled={false}
          addChange={addChange}
          showTime={false}
        />
      </Flex>
      <Flex flexDirection={"row"}>
        <div
          className="datepicker-text"
          style={{ width: 27, display: "flex", justifyContent: "right" }}
        >
          <p>{commonTitles["CO|1|TO"]}:</p>
        </div>
        <CustomDatePicker
          value={value}
          id={id}
          config={config}
          suffix="to"
          isDisabled={false}
          addChange={addChange}
          showTime={false}
        />
      </Flex>
    </Flex>
  );
};

export default FilterDateType;
