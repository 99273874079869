import { useState } from "react";

const useInvert = () => {
  const [items, setItems] = useState([]);

  const isInverted = (value) => items.includes(value);

  const invert = (value) => {
    if (typeof value === "object") {
      const itemTemp = items.filter((item) => !value.includes(item));
      setItems(itemTemp);
    } else {
      if (isInverted(value)) {
        const itemsTemp = items.filter((item) => item !== value);
        setItems(itemsTemp);
      } else {
        setItems([...items, value]);
      }
    }
  };

  const forceInvert = (values) => setItems(values);

  return { items, isInverted, invert, forceInvert };
};

export default useInvert;
