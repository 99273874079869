import { useState, useEffect } from "react";
import { Checkbox } from "antd";
import Flex from "./Flex";
import { useCommonTitlesStore } from "../../store/useStores";

const CustomCheckbox = ({ id, value, addChange, config, isDisabled }) => {
  const [checked, setChecked] = useState(false);
  const [notSelected, setNotSelected] = useState(false);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  useEffect(() => {
    if (value === "Y") {
      setChecked(true);
      setNotSelected(false);
    } else {
      setChecked(false);
      setNotSelected(false);
    }
  }, []);

  if (config === "filters") {
    const countResult = (id, value, config) => {
      if (config === "checked") {
        if (value && !notSelected) {
          addChange(id, "Y", null);
        } else if (!value && notSelected) {
          addChange(id, "N", null);
        } else {
          addChange(id, "", null);
        }
      } else {
        if (checked && !value) {
          addChange(id, "Y", null);
        } else if (!checked && value) {
          addChange(id, "N", null);
        } else {
          addChange(id, "", null);
        }
      }
    };

    return (
      <Flex flexDirection={"row"} style={{ gap: "1.5rem" }}>
        <Checkbox
          style={{ marginTop: 13, marginLeft: 5 }}
          checked={checked ? true : false}
          onChange={(e) => {
            setChecked(!checked);
            if (!checked && notSelected) {
              setNotSelected(!notSelected);
            }
            countResult(id, e.target.checked, "checked");
          }}
        >
          <Flex style={{ display: "flex", width: "max-content" }}>
            {commonTitles["CO|1|CBOXSEL"]}
          </Flex>
        </Checkbox>
        <Checkbox
          style={{ marginTop: 13, marginLeft: 5 }}
          checked={notSelected}
          onChange={(e) => {
            setNotSelected(!notSelected);
            if (!notSelected && checked) {
              setChecked(!checked);
            }
            countResult(id, e.target.checked, "notSelected");
          }}
        >
          <Flex style={{ display: "flex", width: "max-content" }}>
            {commonTitles["CO|1|CBOXNSEL"]}
          </Flex>
        </Checkbox>
      </Flex>
    );
  } else {
    return (
      <Checkbox
        style={{ marginTop: 15, marginLeft: 5, transform: "scale(1.3)" }}
        checked={checked}
        disabled={isDisabled}
        onChange={(e) => {
          setChecked(!checked);
          addChange(id, e.target.checked ? "Y" : "N", null);
        }}
      />
    );
  }
};

export default CustomCheckbox;
