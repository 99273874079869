import "./languages.css";
import { Layout, Button, message } from "antd";
import { LanguagesModal } from "./LanguagesModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";
import {
  useAuthStore,
  useCommonTitlesStore,
  useMenuStore,
} from "../../../store/useStores";
import axios from "axios";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const { Content } = Layout;

const LanguagesSettings = ({
  headers,
  flags,
  titles,
  modules,
  newItemModalVisible,
  showModal,
  handleCancel,
  showDeleteModal,
  updateData,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const languages = useMenuStore((state) => state.languages);
  const setLanguages = useMenuStore((state) => state.setLanguages);

  const onSave = async (changes) => {
    const url = `${baseURL}/Languages_api/save`;

    try {
      const response = await axios.post(
        url,
        { lang: language, L_WORKSPACEID: workspaceID, ...changes },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Languages_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changesWithExtraInfo = {
          L_LASTMODIFIED: response?.data?.TIMESTAMP,
          L_ID: response?.data?.ID.toString(),
          L_LASTUSER: response?.data?.LASTUSER,
          L_WORKSPACEID: response?.data?.WORKSPACEID,
          ...changes,
        };
        console.log("changesWithExtraInfo: ", changesWithExtraInfo);
        updateData("new", changesWithExtraInfo);
        setLanguages([...languages, changesWithExtraInfo]);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(commonTitles["CO|1|ERR500"], [8]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"}>
        <Content
          style={{
            padding: 5,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        />
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {titles["MO|13|NEWROW"]}
          </Button>
          {newItemModalVisible && (
            <LanguagesModal
              key={"new-item"}
              visible={newItemModalVisible}
              headers={headers}
              titles={titles}
              flags={flags}
              modules={modules}
              onSave={onSave}
              onCancel={handleCancel}
              config={"new"}
            />
          )}
        </div>
      </Flex>
    </Content>
  );
};

export default LanguagesSettings;
