const createModuleParams = (menu = [], paramMenu = [], moduleID) => {
  let moduleParams = {};
  for (let i = 0; i < menu.length; i++) {
    for (let j = 0; j < menu[i].children.length; j++) {
      if (menu[i].children[j].ME_MODULEID === moduleID) {
        moduleParams = menu[i].children[j];
      }
    }
  }

  for (let i = 0; i < paramMenu.length; i++) {
    if (paramMenu[i].ME_MODULEID === moduleID) {
      moduleParams = paramMenu[i];
    }
  }

  return moduleParams;
};

export default createModuleParams;
