import { useState } from "react";
import Flex from "../../../../../../helpers/components/Flex";
import { Button, Input, Select } from "antd";
import { useEffect } from "react";
import htmlCodesRemover from "../../../../../ultimateTable/helpers/htmlCodesRemover";

const { Option } = Select;

const OtherModuleSingle = ({
  fields,
  staticOptions,
  refModules = [],
  addChange,
  config,
  data,
}) => {
  const [module, setModule] = useState(null);

  useEffect(() => {
    if (Object.keys(data)?.length) {
      setModule(data.REFMODULE);
    }
  }, []);

  const arrayToString = (array) => {
    let str = "";
    array.map((item) => {
      if (str !== "") {
        str += ",";
      }
      str += item;
    });

    return str;
  };

  let refFieldsValue = [];
  let infoFieldsValue = [];

  if (Object.keys(data).length) {
    if (data.REFFIELDS) {
      if (data.REFFIELDS.includes(",")) {
        let temp = data.REFFIELDS.split(",");
        temp.map((item) => {
          refFieldsValue.push(item);
        });
      } else {
        refFieldsValue.push(data.REFFIELDS);
      }
    }
  }

  if (Object.keys(data).length) {
    if (data.INFOFIELDS) {
      if (data.INFOFIELDS.includes(",")) {
        let temp = data.INFOFIELDS.split(",");
        temp.map((item) => {
          infoFieldsValue.push(item);
        });
      } else {
        infoFieldsValue.push(data.INFOFIELDS);
      }
    }
  }

  return (
    <Flex
      flexDirection={"column"}
      style={{
        gap: "0.15rem",
        marginTop: config === "edit" ? 8 : 10,
      }}
    >
      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: 180,
            justifyContent: "flex-end",
          }}
        >
          {fields[26].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 20,
              marginBottom: "1rem",
            }}
            showSearch={true}
            onChange={(e) => {
              addChange("REFMODULE", e);
              setModule(e);
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={config === "edit" ? data.REFMODULE : ""}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {refModules.map((module) => (
              <Option key={module.M_ID} className="option" value={module.M_ID}>
                {htmlCodesRemover(module.T_TITLE)}
              </Option>
            ))}
          </Select>
        </div>
      </Flex>

      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: 180,
            justifyContent: "flex-end",
          }}
        >
          {fields[27].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 20,
              marginBottom: "1rem",
            }}
            mode="multiple"
            showSearch={true}
            onChange={(e) => {
              addChange("REFFIELDS", arrayToString(e));
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={refFieldsValue}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {refModules
              .filter((item) => item.M_ID === module)[0]
              ?.FIELDS.map((module) => (
                <Option
                  key={module.F_ID}
                  className="option"
                  value={module.F_ID}
                >
                  {htmlCodesRemover(module.T_TITLE)}
                </Option>
              ))}
          </Select>
        </div>
      </Flex>

      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: 180,
            justifyContent: "flex-end",
          }}
        >
          {fields[45].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 20,
              marginBottom: "1rem",
            }}
            mode="multiple"
            showSearch={true}
            onChange={(e) => {
              addChange("INFOFIELDS", arrayToString(e));
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={infoFieldsValue}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {refModules
              .filter((item) => item.M_ID === module)[0]
              ?.FIELDS.map((module) => (
                <Option
                  key={module.F_ID}
                  className="option"
                  value={module.F_ID}
                >
                  {module.T_TITLE}
                </Option>
              ))}
          </Select>
        </div>
      </Flex>

      {/* <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: "40%",
            justifyContent: "flex-end",
          }}
        >
          {fields[6].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 20,
              marginBottom: "1rem",
            }}
            showSearch={true}
            onChange={(e) => {
              addChange("F_PARENTFIELDID", e);
            }}
            filterOption={(iv, op) => {
               let opToUse = op.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={config === "edit" && data.F_PARENTFIELDID}
              dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
          >
            <Option className="option" value={""} />
            {refModules
              .filter((item) => item.M_ID === module)[0]
              ?.FIELDS.map((module) => (
                <Option
                  key={module.F_ID}
                  className="option"
                  value={module.F_ID}
                >
                  {module.T_TITLE}
                </Option>
              ))}
          </Select>
        </div>
      </Flex>
      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: "40%",
            justifyContent: "flex-end",
          }}
        >
          {fields[30].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 20,
              marginBottom: "1rem",
            }}
            showSearch={true}
            onChange={(e) => {
              addChange("F_CHILDFIELDID", e);
            }}
            filterOption={(iv, op) => {
               let opToUse = op.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={config === "edit" && data.F_CHILDFIELDID}
              dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
          >
            <Option className="option" value={""} />
          </Select>
        </div>
      </Flex> */}
    </Flex>
  );
};

export default OtherModuleSingle;
