import { useEffect, useState } from "react";
import Flex from "../../../../../../../../helpers/components/Flex";
import { Select } from "antd";
import apiGetModulesList from "../../../../../../../../api/endpoints/apiGetModulesList";
import apiGetModuleFields from "../../../../../../../../api/endpoints/apiGetModuleFields";
import {
  useAdminStore,
  useAuthStore,
  useCommonTitlesStore,
  useModuleStore,
} from "../../../../../../../../store/useStores";
import htmlCodesRemover from "../../../../../../../ultimateTable/helpers/htmlCodesRemover";
import { useParams } from "react-router-dom";

const { Option } = Select;

const SelectFieldModalContent = ({
  fieldID,
  updateModuleID,
  updateFieldID,
  updateModuleTitle,
  updateFieldTitle,
  currentFieldID,
  currentModuleID,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);
  const numberTypes = useModuleStore((state) => state.numberTypes);

  useEffect(async () => {
    const { modules } = await apiGetModulesList(baseURL);
    setModuleOptions(modules.filter((item) => item?.M_ID === currentModuleID));
  }, []);

  useEffect(async () => {
    if (currentModuleID) {
      const { rows } = await apiGetModuleFields(baseURL, currentModuleID);
      setFieldOptions(
        rows.filter(
          (item) =>
            numberTypes.includes(item?.F_TYPE) && item?.F_ID !== currentFieldID
        )
      );
    }
  }, [currentModuleID]);

  return (
    <Flex
      key={currentModuleID}
      flexDirection={"column"}
      style={{ display: !currentModuleID && "none" }}
    >
      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: "40%",
            justifyContent: "flex-end",
          }}
        >
          {commonTitles["CO|1|MODULE"]}:
        </p>
        <div className="select">
          <Select
            key={moduleOptions.length}
            style={{
              width: 300,
              marginLeft: 20,
              marginBottom: "1rem",
            }}
            showSearch={true}
            onChange={(e) => {
              const id = e.split("-")[0];
              const title = e.split("-")[1];
              updateModuleID(id);
              updateModuleTitle(title);
              updateFieldID("");
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={
              currentModuleID
                ? `${currentModuleID}-${
                    moduleOptions.filter(
                      (module) => module.M_ID === currentModuleID
                    )?.[0]?.T_TITLE
                  }`
                : ""
            }
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {moduleOptions.map((module) => (
              <Option
                key={module?.M_ID}
                className="option"
                value={`${module?.M_ID}-${module?.T_TITLE}`}
              >
                {htmlCodesRemover(module?.T_TITLE)}
              </Option>
            ))}
          </Select>
        </div>
      </Flex>
      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
      >
        <p
          style={{
            display: "flex",
            width: "40%",
            justifyContent: "flex-end",
          }}
        >
          {commonTitles["CO|1|FIELD"]}:
        </p>
        <div className="select">
          <Select
            key={fieldOptions.length}
            style={{
              width: 300,
              marginLeft: 20,
              marginBottom: "1rem",
            }}
            showSearch={true}
            onChange={(e) => {
              const id = e.split("-")[0];
              const title = e.split("-")[1];
              updateFieldID(id);
              updateFieldTitle(title);
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            value={
              fieldID && currentModuleID
                ? `${fieldID}-${
                    fieldOptions.filter((field) => field.F_ID === fieldID)?.[0]
                      ?.T_TITLE
                  }`
                : ""
            }
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {fieldOptions.map((field) => (
              <Option
                key={field?.F_ID}
                className="option"
                value={`${field?.F_ID}-${field?.T_TITLE}`}
              >
                {htmlCodesRemover(field?.T_TITLE)}
              </Option>
            ))}
          </Select>
        </div>
      </Flex>
    </Flex>
  );
};

export default SelectFieldModalContent;
