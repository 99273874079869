import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Button } from "antd";
import EditModal from "../../helpers/modals/editModal/EditModal";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";
import axios from "axios";
import {
  convertDataFromServer,
  tranformChangesforServer,
  transformDataForServer,
} from "../../../module/helpers/converters";
import uploadFiles from "../fileManagement/uploadFiles";
import deleteFiles from "../fileManagement/deleteFiles";
import apiGetValueColor from "../../../../api/endpoints/apiGetValueColor";

const EditAction = ({
  row,
  rowID,
  moduleID,
  columns,
  title,
  privileges,
  foreignKeyValues,
  staticOptions,
  canUpdate,
  dataToUse,
  originalData,
  updateRow,
  autoCompletedFields,
  updateTotals,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const userID = useAuthStore((state) => state.userID);
  const [rowModalVisible, setRowModalVisible] = useState(false);
  const [changes, setChanges] = useState({});

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const showRowModal = () => {
    setRowModalVisible(true);
  };

  const onCancel = () => {
    setChanges({});
    setRowModalVisible(false);
  };

  let attachColumns = [];
  let automaticFieldColumns = [];
  let renderAlertColumns = [];

  columns.forEach((column) => {
    if (column.F_TYPE === "24" || column.F_TYPE === "25") {
      attachColumns.push(column.F_ID);
    }
    if (column.F_TYPE === "29") {
      automaticFieldColumns.push(column.F_ID);
    }
    if (column.F_RENDER === "ALERT") {
      renderAlertColumns.push(column.F_ID);
    }
  });

  const addChange = (id, value, config) => {
    if (value && value !== "" && value?.length !== 0) {
      if (attachColumns.includes(id)) {
        if (changes[id]) {
          if (config === "add") {
            let fileExists = [];
            for (let i = 0; i < value.length; i++) {
              changes[id].forEach((file) => {
                if (file.uid === value[i].uid) {
                  fileExists[i] = true;
                }
              });
            }

            for (let i = 0; i < value.length; i++) {
              !fileExists[i] &&
                setChanges({
                  ...changes,
                  [id]: [...changes[id], value[i]],
                });
            }
          } else if (config === "add to delete") {
            setChanges({ ...changes, [id]: [...changes[id], value] });
            changes[id].map((file) => typeof file);
          } else {
            let tempChanges = [];
            changes[id].forEach((file) => tempChanges.push(file));
            tempChanges = tempChanges.filter((file) => file.uid !== value.uid);
            if (tempChanges.length) {
              setChanges({ ...changes, [id]: [...tempChanges] });
            } else {
              delete changes[id];
              setChanges({ ...changes });
            }
          }
        } else {
          if (config === "add to delete") {
            setChanges({ ...changes, [id]: [value] });
          } else {
            setChanges({
              ...changes,
              [id]: value,
            });
          }
        }
      } else {
        if (
          (!originalData[rowID][id] ||
            originalData[rowID][id] === "" ||
            originalData[rowID][id]?.length === 0) &&
          (!value || value === "" || value?.length === 0)
        ) {
          delete changes[id];
          setChanges({ ...changes });
        } else {
          setChanges({ ...changes, [id]: value });
        }
      }
    } else {
      if (!attachColumns.includes(id)) {
        if (
          (!originalData[rowID][id] ||
            originalData[rowID][id] === "" ||
            originalData[rowID][id]?.length === 0) &&
          (!value || value === "" || value?.length === 0)
        ) {
          delete changes[id];
          setChanges({ ...changes });
        } else {
          setChanges({ ...changes, [id]: value });
        }
      }
    }
  };

  const onSave = async (rowID, changes) => {
    const url = `${baseURL}/Data_api/save`;

    let filesToDelete = {};
    let filesToUpload = {};
    attachColumns.forEach((id) => {
      filesToUpload = { ...filesToUpload, [id]: [] };
      filesToDelete = { ...filesToDelete, [id]: [] };

      if (changes[id]) {
        let tempFilesToDelete = changes[id].filter(
          (file) => file.action === "D"
        );
        filesToDelete[id].push(...tempFilesToDelete);

        let tempFilesToUpload = changes[id].filter(
          (file) => !("action" in file)
        );
        filesToUpload[id].push(...tempFilesToUpload);
      }
    });

    const { uploadFileTracker, uploadStatus } = await uploadFiles(
      filesToUpload,
      attachColumns,
      baseURL,
      commonTitles
    );

    const { deleteFileTracker, deleteStatus } = await deleteFiles(
      filesToDelete,
      attachColumns,
      baseURL,
      commonTitles
    );

    //console.log("onSave: deleteStatus: ", deleteStatus);

    if (uploadStatus === "Success" && deleteStatus === "Success") {
      let fileTracker = {};
      for (let i = 0; i < attachColumns.length; i++) {
        let id = attachColumns[i];
        fileTracker = {
          ...fileTracker,
          [id]: [...uploadFileTracker[id], ...deleteFileTracker[id]],
        };
      }
      // console.log("onSuccess: fileTracker: ", fileTracker);

      let renderAlertColorValues = {};

      for (let i = 0; i < renderAlertColumns.length; i++) {
        const colorValue = await apiGetValueColor(
          baseURL,
          renderAlertColumns[i],
          changes?.[renderAlertColumns[i]] ?? ""
        );

        renderAlertColorValues = {
          ...renderAlertColorValues,
          [renderAlertColumns[i]]: {
            value: changes?.[renderAlertColumns[i]] ?? "",
            color: colorValue?.COL ?? ["#ffffff", "B"],
          },
        };
      }

      const transformedChanges = tranformChangesforServer(
        changes,
        columns,
        dataToUse,
        rowID,
        foreignKeyValues,
        fileTracker,
        baseURL
      );
      //console.log("updateData: transformedChanges: ", transformedChanges);

      const dataForServer = transformDataForServer(
        rowID,
        originalData,
        columns,
        staticOptions,
        foreignKeyValues
      );
      //console.log("updateData: dataForServer: ", dataForServer);

      const tempData = await convertDataFromServer(
        columns,
        rowID,
        originalData,
        staticOptions,
        foreignKeyValues,
        transformedChanges,
        "update",
        userID,
        renderAlertColorValues
      );
      //console.log("updateData: tempData: ", tempData);

      const lastModified = originalData[rowID].LM;

      try {
        const response = await axios.post(
          url,
          {
            moduleid: moduleID,
            rowid: rowID,
            lastmodified: lastModified,
            ...dataForServer,
            ...transformedChanges,
          },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("updateData: response: ", response?.data);
        if (response?.data?.STATUS === "Success") {
          //message.success(commonTitles["CO|1|UPDMSG1"], [3]);
          displayResponseMessage(commonTitles["CO|1|UPDMSG1"], 3, "success");

          let calcFieldValues = response?.data?.math ?? {};

          const changes = {
            [rowID]: {
              ...originalData[rowID],
              ...tempData,
              ...calcFieldValues,
              LM: response?.data?.TIMESTAMP,
              US: userID,
            },
          };
          updateRow(changes);
          updateTotals(response?.data?.totals);
          setChanges({});
          setRowModalVisible(false);
        } else {
          const responseMsg = response?.data?.MESSAGE ?? {};
          let template = commonTitles[responseMsg.ID];
          Object.entries(responseMsg)
            .filter(([key]) => key !== "ID")
            .forEach(([key, value]) => {
              var re = new RegExp(key, "g");
              template = template.replace(re, value);
            });
          //message.warning(template, [3]);
          displayResponseMessage(template, 5, "warning");
        }
      } catch (err) {
        //message.error(err, [3]);
        displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
      }
    } else {
      deleteFiles(uploadFileTracker, attachColumns, baseURL, commonTitles);
    }
  };

  if (canUpdate) {
    return (
      <>
        <Button
          className="infocard-btn"
          shape="round"
          icon={<EditOutlined />}
          size="small"
          onClick={() => showRowModal()}
        >
          {commonTitles["CO|1|EDIT"]}
        </Button>
        <EditModal
          key={changes}
          visible={rowModalVisible}
          title={title}
          row={row}
          columns={columns}
          staticOptions={staticOptions}
          foreignKeyValues={foreignKeyValues}
          onSave={onSave}
          //onDelete={onDelete}
          onCancel={onCancel}
          changes={changes}
          addChange={addChange}
          privileges={privileges}
          autoCompletedFields={autoCompletedFields}
          moduleID={moduleID}
        />
      </>
    );
  } else return null;
};

export default EditAction;
