import React from "react";
import Button from "@mui/material/Button";
import { Tooltip } from "@material-ui/core";
import { useAuthStore } from "../../../../store/useStores";

const BannerItem = ({ icon, tooltip, downloadPdfReport, suffix }) => {
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);

  return (
    <Tooltip placement="bottom" title={tooltip}>
      <Button
        sx={{ color: bannerTextColor, marginTop: -1 }}
        onClick={() => downloadPdfReport(suffix)}
      >
        {icon}
      </Button>
    </Tooltip>
  );
};

export default BannerItem;
