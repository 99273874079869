import { useCommonTitlesStore } from "../../../../store/useStores";
import { Select } from "antd";
import { useChartStore } from "../../../../store/useStores";
import "./../../css/ultimateChart.css";
import useWindowDimensions from "../../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const ChartType = () => {
  const chartType = useChartStore((state) => state.chartType);
  const setChartType = useChartStore((state) => state.setChartType);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();

  const options = [
    { value: "Bar", label: commonTitles.chartBar },
    { value: "Pie", label: commonTitles.chartPie },
    { value: "Line", label: commonTitles.chartLine },
  ];

  return (
    <div className="select">
      <Select
        style={{
          width: width < 1525 ? 120 : 200,
          marginLeft: 0,
          marginRight: 10,
          marginTop: 0,
          marginBottom: -50,
        }}
        showSearch={true}
        filterOption={(iv, op) => {
          let opToUse = op.children ? op.children : "";
          return opToUse.toLowerCase().includes(iv.toLowerCase());
        }}
        dropdownStyle={{
          minWidth: "max-content",
          paddingLeft: 5,
          paddingRight: 5,
          overflowY: "auto",
        }}
        onChange={(e) => setChartType(e)}
        defaultValue={chartType}
        placeholder={`${commonTitles.chartType}`}
        virtual={false}
      >
        <Option className="option" key={""} value={null} />
        {options.map(({ value, label }) => (
          <Option
            className="option"
            key={`${value}-${label}`}
            value={value}
            label={label}
          >
            {label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default ChartType;
