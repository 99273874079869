import Flex from "./Flex";

const TableWrapper = ({ children }) => {
  const style = {
    paddingTop: "7.5rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    borderRadius: "15px",
    width: "99%",
  };

  return (
    <Flex flexDirection={"column"} style={style}>
      {children}
    </Flex>
  );
};

export default TableWrapper;
