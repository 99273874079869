import "./navbar.css";
import logo from "../../../assets/PLCS-logoo.png";
import MyMenu from "./menu/Menu";
import Box from "@mui/material/Box";
import { useAuthStore } from "../../../store/useStores";
import { Select } from "antd";
import Flex from "../../../helpers/components/Flex";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";

const { Option } = Select;

const Navbar = ({ tag1, tag2, tag3 }) => {
  const language = useAuthStore((state) => state.language);
  const setLanguage = useAuthStore((state) => state.setLanguage);
  const { width } = useWindowDimensions();

  const countries = [
    {
      flagName: "United States",
      value: "EN",
      src: "http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg",
    },
    {
      flagName: "Greece",
      value: "EL",
      src: "http://purecatamphetamine.github.io/country-flag-icons/3x2/GR.svg",
    },
  ];

  return (
    <div className="testSite__navbar">
      <div className="testSite__navbar-links">
        <Flex flexDirection={"row"} style={{ alignItems: "center" }}>
          <div className="testSite__navbar-links_logo">
            <img src={logo} alt="logo" />
          </div>
          <p
            className="navbar-fullName"
            style={{
              fontSize: width < 1050 ? 20 : 30,
              fontWeight: 600,
              color: "#FFFFF0",
              // color: "white",
              marginTop: -5,
              marginLeft: "-5.5rem",
            }}
          >
            Project Life Cycle Safety Suite
          </p>
        </Flex>

        <div className="testSite__navbar-links_container">
          <MyMenu menuItem1={tag1} menuItem2={tag2} menuItem3={tag3} />
        </div>
      </div>
      <div className="testSite__navbar-sign">
        <Box
          size="small"
          sx={{
            bgcolor: "transparent",
            border: "none",
            outlineColor: "white",
            p: 1,
            width: 110,
            maxHeight: 80,
          }}
        >
          <Select
            defaultValue={language}
            style={{
              width: 120,
            }}
            showSearch={true}
            filterOption={(iv, op) => {
              let opToUse = op.label ? op.label : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            bordered={false}
            autoFocus={false}
            onChange={(e) => {
              setLanguage(e);
            }}
            dropdownStyle={{ background: "transparent", overflowY: "auto" }}
            virtual={false}
          >
            {countries.map(({ flagName, value, src }) => (
              <Option key={`${flagName}`} value={value} label={flagName}>
                <img alt={flagName} src={src} width="40" />
              </Option>
            ))}
          </Select>
        </Box>
      </div>
    </div>
  );
};

export default Navbar;
