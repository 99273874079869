const countTotalRowWidth = (columns = []) => {
  let totalWidth = 1;

  for (let i = 0; i < columns.length; i++) {
    if (columns[i].F_LIST === "Y") {
      totalWidth = totalWidth + parseInt(columns[i].F_WIDTH);
    }
  }

  return totalWidth;
};

export default countTotalRowWidth;
