import Flex from "../../../helpers/components/Flex";

const ModuleWrapper = ({ children }) => {
  const style = {
    background: "transparent",
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
  };

  return (
    <Flex flexDirection={"column"} style={style}>
      {children}
    </Flex>
  );
};

export default ModuleWrapper;
