import htmlCodesRemover from "../../../ultimateTable/helpers/htmlCodesRemover";
import { Input, Select } from "antd";
import TextInput from "../fieldInputs/TextInput";
import SelectInput from "../fieldInputs/SelectInput";
import CustomCheckbox from "../../../../helpers/components/CustomCheckbox";

const { Option } = Select;

const InputDecider = ({
  id,
  data,
  addChange,
  moduleID,
  updateModuleID,
  modules,
  triggerField,
  triggerFieldOptions,
  titles,
  infoFields,
  alertText,
  updateAlertText,
  config,
  fields,
}) => {
  const textInputFields = ["A_WARNING_BEFORE", "A_RAISED_AFTER"];

  const arrayToString = (array) => {
    let str = "";
    array.forEach((item) => {
      if (str !== "") {
        str += ",";
      }
      str += item;
    });

    return str;
  };

  if (id === "F_MODULEID") {
    return (
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          showSearch={true}
          onChange={(e) => {
            addChange("F_MODULEID", e);
            updateModuleID(e);
          }}
          defaultValue={
            config === "edit"
              ? htmlCodesRemover(
                  Object.entries(modules).filter(
                    ([key, value]) => key === data.F_MODULEID
                  )[0][1]
                )
              : null
          }
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          {Object.entries(modules).map(([key, value]) => (
            <Option key={key} className="option" value={key}>
              {htmlCodesRemover(value)}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (id === "A_TRIGGER_FIELDID") {
    return (
      <div className="select" key={triggerField}>
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          onChange={(e) => {
            addChange("A_TRIGGER_FIELDID", e);
            const title =
              htmlCodesRemover(
                fields[moduleID]?.filter((item) => item.F_ID === e)[0]?.T_TITLE
              ) ?? "";
            updateAlertText(title);
          }}
          showSearch={true}
          defaultValue={htmlCodesRemover(triggerField)}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          {triggerFieldOptions?.map((item) => (
            <Option key={item.F_ID} className="option" value={item.F_ID}>
              {htmlCodesRemover(item?.T_TITLE)}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (textInputFields.includes(id)) {
    return (
      <TextInput id={id} data={data} addChange={addChange} config={config} />
    );
  }

  if (id === "ALERTTEXT") {
    return (
      <div className="select" key={`${alertText}-${moduleID}`}>
        <Input
          style={{
            width: 300,
            marginLeft: 15,
            marginTop: 0,
            marginBottom: "1rem",
          }}
          onChange={(e) => addChange("ALERTTEXT", e.target.value)}
          defaultValue={htmlCodesRemover(alertText)}
        />
      </div>
    );
  }

  if (id === "INFORMATIONFIELDS") {
    return (
      <div className="select" key={infoFields.length}>
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          showSearch={true}
          mode="multiple"
          onChange={(e) => addChange("INFORMATIONFIELDS", arrayToString(e))}
          defaultValue={infoFields}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          {fields[moduleID]?.map((item) => (
            <Option key={item.F_ID} className="option" value={item.F_ID}>
              {htmlCodesRemover(item?.T_TITLE)}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (id === "A_ACTIVE") {
    return (
      <div
        style={{
          marginLeft: 15,
          marginTop: -14,
          marginBottom: 12,
          width: 340,
        }}
      >
        <CustomCheckbox
          id={"A_ACTIVE"}
          value={config === "edit" ? data.A_ACTIVE : "Y"}
          addChange={addChange}
          config={config}
          isDisabled={false}
        />
      </div>

      // <SelectInput
      //   id={id}
      //   mode={false}
      //   defaultValue={
      //     config === "edit"
      //       ? data.A_ACTIVE === "Y"
      //         ? titles["FO|88|"]
      //         : titles["FO|89|"]
      //       : titles["FO|88|"]
      //   }
      //   addChange={addChange}
      //   options={[
      //     { ID: "Y", T_TITLE: titles["FO|88|"] },
      //     { ID: "N", T_TITLE: titles["FO|89|"] },
      //   ]}
      // />
    );
  }
};

export default InputDecider;
