import axios from "axios";

const apiUpdateFieldsOrder = async (baseURL, fields) => {
  const url = `${baseURL}/App_fields_api/updateeditorder`;
  try {
    const response = await axios.post(
      url,
      { fields: fields },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("apiUpdateFieldsOrder: response: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiUpdateFieldsOrder;
