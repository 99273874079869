import { useEffect, useState } from "react";
import Flex from "../../../../../../helpers/components/Flex";
import FormulaConfiguration from "./FormulaConfiguration";
import { useAdminStore, useAuthStore } from "../../../../../../store/useStores";
import apiGetModuleFields from "../../../../../../api/endpoints/apiGetModuleFields";
import htmlCodesRemover from "../../../../../ultimateTable/helpers/htmlCodesRemover";
import { useParams } from "react-router-dom";

const FormulaType = ({
  data,
  changes,
  addChange,
  config,
  fields,
  refModules,
  bodyData,
  currentModuleID,
}) => {
  const [defaultFieldTags, setDefaultFieldTags] = useState({});
  const baseURL = useAuthStore((state) => state.baseURL);
  //const moduleID = useAdminStore((state) => state.moduleID);

  //const { moduleID } = useParams();

  const extractWords = (str) => {
    if (!str) {
      return [];
    }

    const words = [];
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) === "[") {
        const stopIndex = str.indexOf("]", i);
        if (stopIndex !== -1) words.push(str.substring(i + 1, stopIndex));
      }
    }
    return words;
  };

  const fieldsInFormula = extractWords(data?.F_CALC);

  useEffect(async () => {
    let tempFieldTags = {};

    for (let i = 0; i < fieldsInFormula.length; i++) {
      const field = fieldsInFormula[i];

      let fieldTitle = "";
      let moduleTitle = "";

      if (field.includes("|")) {
        const moduleID = field.split("|")?.[0];
        const fieldID = field.split("|")?.[1];

        const { rows } = await apiGetModuleFields(baseURL, moduleID);

        fieldTitle = rows.filter((field) => field?.F_ID === fieldID)?.[0]
          ?.T_TITLE;
      } else {
        fieldTitle = bodyData.filter((item) => item.F_ID === field)?.[0]
          ?.T_TITLE;
      }

      moduleTitle = htmlCodesRemover(
        refModules.filter((module) => module?.M_ID === currentModuleID)?.[0]
          ?.T_TITLE
      );

      tempFieldTags = {
        ...tempFieldTags,
        [`[${field}]`]: { fieldTitle: fieldTitle, moduleTitle: moduleTitle },
      };
    }

    setDefaultFieldTags(tempFieldTags);
  }, []);

  return (
    <Flex
      key={Object.keys(defaultFieldTags).length}
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center", marginLeft: -50 }}
    >
      <p
        style={{
          display: "flex",
          width: 230,
          justifyContent: "flex-end",
        }}
      >
        {fields?.[245]?.TITLE ?? ""}:
      </p>
      <Flex style={{ width: "57%" }}>
        <FormulaConfiguration
          key={Object.keys(defaultFieldTags).length}
          data={data}
          changes={changes}
          addChange={addChange}
          config={config}
          defaultFieldTags={defaultFieldTags}
          currentModuleID={currentModuleID}
        />
      </Flex>
    </Flex>
  );
};

export default FormulaType;
