import PieOpt from "../UC body/pie/PieOpt";
import BarOpt from "../UC body/bar/BarOpt";
import LineOpt from "../UC body/line/LineOpt";
import RadialOpt from "../UC body/radial/RadialOpt";

const chartSelector = (
  chartData,
  chartType,
  grouping,
  dateField,
  groupField,
  displayFunction,
  columns,
  filters,
  foreignKeyValues,
  staticOptions,
  updateFilters,
  updateValuesUsed,
  yValuesUsed,
  xValuesUsed,
  noDateDataColumnStatus,
  updateNoDateDataColumnStatus,
  privileges,
  chartParamMenuVisible,
  changeChartParamMenuVisible,
  chartTitle
) => {
  if (chartType === "Bar") {
    return (
      <BarOpt
        key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-BarOpt`}
        chartData={chartData}
        columns={columns}
        filters={filters}
        foreignKeyValues={foreignKeyValues}
        staticOptions={staticOptions}
        updateFilters={updateFilters}
        updateValuesUsed={updateValuesUsed}
        yValuesUsed={yValuesUsed}
        xValuesUsed={xValuesUsed}
        noDateDataColumnStatus={noDateDataColumnStatus}
        updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
        privileges={privileges}
        chartParamMenuVisible={chartParamMenuVisible}
        changeChartParamMenuVisible={changeChartParamMenuVisible}
        chartTitle={chartTitle}
      />
    );
  }

  if (chartType === "Pie" && Object.keys(chartData).length) {
    if (grouping === "Daily") {
      if (
        Object.keys(chartData?.daily).filter((key) => key !== "totalSum")
          .length < 2
      ) {
        return (
          <PieOpt
            key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-PieOpt`}
            chartData={chartData.daily}
            columns={columns}
            filters={filters}
            foreignKeyValues={foreignKeyValues}
            staticOptions={staticOptions}
            updateFilters={updateFilters}
            updateValuesUsed={updateValuesUsed}
            yValuesUsed={yValuesUsed}
            xValuesUsed={xValuesUsed}
            noDateDataColumnStatus={noDateDataColumnStatus}
            updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
            privileges={privileges}
            chartParamMenuVisible={chartParamMenuVisible}
            changeChartParamMenuVisible={changeChartParamMenuVisible}
            chartTitle={chartTitle}
          />
        );
      } else {
        return (
          <RadialOpt
            key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-RadialOpt`}
            chartData={chartData.daily}
            columns={columns}
            filters={filters}
            foreignKeyValues={foreignKeyValues}
            staticOptions={staticOptions}
            updateFilters={updateFilters}
            updateValuesUsed={updateValuesUsed}
            yValuesUsed={yValuesUsed}
            xValuesUsed={xValuesUsed}
            noDateDataColumnStatus={noDateDataColumnStatus}
            updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
            privileges={privileges}
            chartParamMenuVisible={chartParamMenuVisible}
            changeChartParamMenuVisible={changeChartParamMenuVisible}
            chartTitle={chartTitle}
          />
        );
      }
    }

    if (grouping === "Monthly") {
      if (
        Object.keys(chartData.monthly).filter((key) => key !== "totalSum")
          .length < 2
      ) {
        return (
          <PieOpt
            key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-PieOpt`}
            chartData={chartData.monthly}
            columns={columns}
            filters={filters}
            foreignKeyValues={foreignKeyValues}
            staticOptions={staticOptions}
            updateFilters={updateFilters}
            updateValuesUsed={updateValuesUsed}
            yValuesUsed={yValuesUsed}
            xValuesUsed={xValuesUsed}
            noDateDataColumnStatus={noDateDataColumnStatus}
            updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
            privileges={privileges}
            chartParamMenuVisible={chartParamMenuVisible}
            changeChartParamMenuVisible={changeChartParamMenuVisible}
            chartTitle={chartTitle}
          />
        );
      } else {
        return (
          <RadialOpt
            key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-RadialOpt`}
            chartData={chartData.monthly}
            columns={columns}
            filters={filters}
            foreignKeyValues={foreignKeyValues}
            staticOptions={staticOptions}
            updateFilters={updateFilters}
            updateValuesUsed={updateValuesUsed}
            yValuesUsed={yValuesUsed}
            xValuesUsed={xValuesUsed}
            noDateDataColumnStatus={noDateDataColumnStatus}
            updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
            privileges={privileges}
            chartParamMenuVisible={chartParamMenuVisible}
            changeChartParamMenuVisible={changeChartParamMenuVisible}
            chartTitle={chartTitle}
          />
        );
      }
    }

    if (grouping === "Yearly") {
      if (
        Object.keys(chartData.yearly).filter((key) => key !== "totalSum")
          .length < 2
      ) {
        return (
          <PieOpt
            key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-PieOpt`}
            chartData={chartData.yearly}
            columns={columns}
            filters={filters}
            foreignKeyValues={foreignKeyValues}
            staticOptions={staticOptions}
            updateFilters={updateFilters}
            updateValuesUsed={updateValuesUsed}
            yValuesUsed={yValuesUsed}
            xValuesUsed={xValuesUsed}
            noDateDataColumnStatus={noDateDataColumnStatus}
            updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
            privileges={privileges}
            chartParamMenuVisible={chartParamMenuVisible}
            changeChartParamMenuVisible={changeChartParamMenuVisible}
            chartTitle={chartTitle}
          />
        );
      } else {
        return (
          <RadialOpt
            key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-RadialOpt`}
            chartData={chartData.yearly}
            columns={columns}
            filters={filters}
            foreignKeyValues={foreignKeyValues}
            staticOptions={staticOptions}
            updateFilters={updateFilters}
            updateValuesUsed={updateValuesUsed}
            yValuesUsed={yValuesUsed}
            xValuesUsed={xValuesUsed}
            noDateDataColumnStatus={noDateDataColumnStatus}
            updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
            privileges={privileges}
            chartParamMenuVisible={chartParamMenuVisible}
            changeChartParamMenuVisible={changeChartParamMenuVisible}
            chartTitle={chartTitle}
          />
        );
      }
    }
  }

  if (chartType === "Line") {
    return (
      <LineOpt
        key={`${chartType}-${grouping}-${dateField}-${groupField}-${displayFunction}-LineOpt`}
        chartData={chartData}
        columns={columns}
        filters={filters}
        foreignKeyValues={foreignKeyValues}
        staticOptions={staticOptions}
        updateFilters={updateFilters}
        updateValuesUsed={updateValuesUsed}
        yValuesUsed={yValuesUsed}
        xValuesUsed={xValuesUsed}
        noDateDataColumnStatus={noDateDataColumnStatus}
        updateNoDateDataColumnStatus={updateNoDateDataColumnStatus}
        privileges={privileges}
        chartParamMenuVisible={chartParamMenuVisible}
        changeChartParamMenuVisible={changeChartParamMenuVisible}
        chartTitle={chartTitle}
      />
    );
  }

  return null;
};

export default chartSelector;
