import Buttons from "./buttons/Buttons";
import { useState } from "react";
import InvalidInput from "../helpers/components/InvalidInput";
import Flex from "../../../../../../../helpers/components/Flex";
import InputElement from "./inputElement/InputElement";

const TagOperations = ({
  tags,
  updateTags,
  showInputMessage,
  updateShowInputMessage,
  editFieldTagInfo,
  updateEditFieldTagInfo,
  editFunctionTagInfo,
  fieldTags,
  updateFieldTags,
  selectFieldModalVisible,
  showFieldModal,
  cancelFieldModal,
  functionModalVisible,
  showFunctionModal,
  cancelFunctionModal,
  addChange,
  currentFieldID,
  currentModuleID,
}) => {
  const [inputVisible, setInputVisible] = useState(false);

  const showInput = () => {
    setInputVisible(true);
  };

  const updateInputVisible = (value) => setInputVisible(value);

  if (inputVisible) {
    return (
      <InputElement
        tags={tags}
        updateTags={updateTags}
        showInputMessage={showInputMessage}
        updateShowInputMessage={updateShowInputMessage}
        inputVisible={inputVisible}
        updateInputVisible={updateInputVisible}
      />
    );
  }

  return (
    <Flex flexDirection={"column"}>
      {showInputMessage && <InvalidInput />}
      <Buttons
        tags={tags}
        updateTags={updateTags}
        showInput={showInput}
        editFieldTagInfo={editFieldTagInfo}
        updateEditFieldTagInfo={updateEditFieldTagInfo}
        editFunctionTagInfo={editFunctionTagInfo}
        fieldTags={fieldTags}
        updateFieldTags={updateFieldTags}
        selectFieldModalVisible={selectFieldModalVisible}
        showFieldModal={showFieldModal}
        cancelFieldModal={cancelFieldModal}
        functionModalVisible={functionModalVisible}
        showFunctionModal={showFunctionModal}
        cancelFunctionModal={cancelFunctionModal}
        addChange={addChange}
        currentFieldID={currentFieldID}
        currentModuleID={currentModuleID}
      />
    </Flex>
  );
};

export default TagOperations;
