import { useStore } from "./StoreProvider";

export const useAlertMessageStore = (selectorFn, eqFn) =>
  useStore("AlertMessageStore", selectorFn, eqFn);

export const useAlertStore = (selectorFn, eqFn) =>
  useStore("AlertStore", selectorFn, eqFn);

export const useAuthStore = (selectorFn, eqFn) =>
  useStore("AuthStore", selectorFn, eqFn);

export const useCommonTitlesStore = (selectorFn, eqFn) =>
  useStore("CommonTitlesStore", selectorFn, eqFn);

export const useMenuStore = (selectorFn, eqFn) =>
  useStore("MenuStore", selectorFn, eqFn);

export const useModTitlesStore = (selectorFn, eqFn) =>
  useStore("ModTitlesStore", selectorFn, eqFn);

export const useFilterStore = (selectorFn, eqFn) =>
  useStore("FilterStore", selectorFn, eqFn);

export const useChartStore = (selectorFn, eqFn) =>
  useStore("ChartStore", selectorFn, eqFn);

export const useAdminStore = (selectorFn, eqFn) =>
  useStore("AdminStore", selectorFn, eqFn);

export const useImportStore = (selectorFn, eqFn) =>
  useStore("ImportStore", selectorFn, eqFn);

export const usePaginationStore = (selectorFn, eqFn) =>
  useStore("PaginationStore", selectorFn, eqFn);

export const useModuleStore = (selectorFn, eqFn) =>
  useStore("ModuleStore", selectorFn, eqFn);
