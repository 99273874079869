import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { addFieldButtonStyle } from "../../styles/TagItemStyles";
import {
  useAdminStore,
  useCommonTitlesStore,
} from "../../../../../../../../store/useStores";
import { useState } from "react";
import SelectFieldModal from "../../modals/selectFieldModal/SelectFieldModal";

const AddFieldButton = ({
  tags,
  editFieldTagInfo,
  updateTags,
  updateFieldTags,
  fieldTags,
  selectFieldModalVisible,
  showFieldModal,
  cancelFieldModal,
  currentFieldID,
  currentModuleID,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const addField = (id, title, moduleTitle, index) => {
    if (index >= 0) {
      const newTags = [...tags];
      newTags[index] = id;
      updateTags(newTags);
    } else {
      updateTags([...tags, id]);
    }

    updateFieldTags({
      ...fieldTags,
      [id]: { fieldTitle: title, moduleTitle: moduleTitle },
    });
    cancelFieldModal();
  };

  const deleteField = (index) => {
    if (index >= 0) {
      let newTags = [...tags];
      newTags.splice(index, 1);
      updateTags(newTags);
    }
    cancelFieldModal();
  };

  return (
    <>
      <Button
        icon={
          <PlusOutlined
            style={{ fontSize: 10 }}
            onClick={() => showFieldModal()}
          />
        }
        style={addFieldButtonStyle}
        onClick={() => showFieldModal()}
      >
        {commonTitles["CO|1|ADDFIELD"]}
      </Button>
      {selectFieldModalVisible && (
        <SelectFieldModal
          visible={selectFieldModalVisible}
          addField={addField}
          deleteField={deleteField}
          onCancel={cancelFieldModal}
          editFieldTagInfo={editFieldTagInfo}
          currentFieldID={currentFieldID}
          currentModuleID={currentModuleID}
        />
      )}
    </>
  );
};

export default AddFieldButton;
