import Colors from "../UC settings/options/Colors";
import Interpolation from "../UC settings/options/Interpolation";
import StartAngle from "../UC settings/options/StartAngle";
import EndAngle from "../UC settings/options/EndAngle";
import InnerRadius from "../UC settings/options/InnerRadius";
import GroupMode from "../UC settings/options/GroupMode";
import LineGroupMode from "../UC settings/options/LineGroupMode";
import Layout from "../UC settings/options/Layout";
import { useChartStore, useCommonTitlesStore } from "../../../store/useStores";
import { Checkbox } from "antd";
import "../css/ultimateChart.css";

const ChartBodySettings = ({
  columns,
  updateValuesUsed,
  yValuesUsed,
  xValuesUsed,
  config,
  noDateDataColumnStatus,
  updateNoDateDataColumnStatus,
  keys,
}) => {
  const chartType = useChartStore((state) => state.chartType);
  const groupField = useChartStore((state) => state.groupField);
  const grouping = useChartStore((state) => state.grouping);
  const dateField = useChartStore((state) => state.dateField);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let childxAxisField;
  let xAxisFieldToUse = dateField;

  if (dateField?.includes("-")) {
    let tempXAxisField = dateField.split(" - ");

    xAxisFieldToUse = tempXAxisField[0];
    childxAxisField = tempXAxisField[1];
  }

  const xAxisFieldColumn = columns.filter(
    (column) => column.F_ID === xAxisFieldToUse
  )[0];

  let groupFieldToUse;
  let childGroupField = null;
  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    const parentGroupField = tempGroupField[0];
    childGroupField = tempGroupField[1];
    groupFieldToUse = parentGroupField;
  } else {
    groupFieldToUse = groupField;
  }

  const groupFieldColumn = columns.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  if (childGroupField) {
    const childTitle = groupFieldColumn.DEP_FIELDS.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
  }

  if (chartType === "Bar") {
    let onlyGroupFieldUsed = commonTitles["CO|1|ONLYUSEDFL"];
    var re = new RegExp("<1>", "g");
    onlyGroupFieldUsed = onlyGroupFieldUsed.replace(re, groupFieldName);
    return (
      <div key={`${commonTitles}-barSet`} className="chart__options">
        <GroupMode keys={keys} />
        <Layout />
        {/* <Colors /> */}
        {groupField && (
          <Checkbox
            checked={yValuesUsed}
            style={{ marginTop: 20 }}
            onChange={(e) => updateValuesUsed("y", e.target.checked)}
          >
            {onlyGroupFieldUsed}
          </Checkbox>
        )}
        {/* {grouping === "Monthly" ? (
          <Checkbox
            checked={xValuesUsed}
            style={{ marginTop: 5, marginLeft: 0 }}
            onChange={(e) => updateValuesUsed("x", e.target.checked)}
          >
            {commonTitles["CO|1|ONLYUSEDDT"]}
          </Checkbox>
        ) : ( */}
        {groupField && (
          <Checkbox
            checked={xValuesUsed}
            //disabled
            style={{ marginTop: 5, marginLeft: 0 }}
            onChange={(e) => updateValuesUsed("x", e.target.checked)}
          >
            {commonTitles["CO|1|ONLYUSEDDT"]}
          </Checkbox>
        )}
        {/* )} */}
        <Checkbox
          checked={noDateDataColumnStatus}
          style={{ marginTop: groupField ? 5 : 20, marginLeft: 0 }}
          onChange={(e) => updateNoDateDataColumnStatus(e.target.checked)}
        >
          {commonTitles.hideNoDateColumn}
        </Checkbox>
      </div>
    );
  }

  if (chartType === "Pie" && config !== "radial") {
    let onlyGroupFieldUsed = commonTitles["CO|1|ONLYUSEDFL"];
    var re = new RegExp("<1>", "g");
    onlyGroupFieldUsed = onlyGroupFieldUsed.replace(re, groupFieldName);
    return (
      <div className="chart__options">
        <StartAngle />
        <EndAngle />
        {/* <Colors /> */}
        {groupField && (
          <Checkbox
            checked={yValuesUsed}
            style={{ marginTop: 20 }}
            onChange={(e) => updateValuesUsed("y", e.target.checked)}
          >
            {onlyGroupFieldUsed}
          </Checkbox>
        )}
        {/* {grouping === "Monthly" ? ( */}
        {groupField && (
          <Checkbox
            checked={xValuesUsed}
            style={{ marginTop: 5, marginLeft: 0 }}
            onChange={(e) => updateValuesUsed("x", e.target.checked)}
          >
            {commonTitles["CO|1|ONLYUSEDDT"]}
          </Checkbox>
        )}
        {/* // ) : (
        //   <Checkbox
        //     checked={xValuesUsed}
        //     disabled
        //     style={{ marginTop: 5, marginLeft: 0 }}
        //     onChange={(e) => updateValuesUsed("x", e.target.checked)}
        //   >
        //     {commonTitles["CO|1|ONLYUSEDDT"]}
        //   </Checkbox>
        // )} */}
        <Checkbox
          checked={noDateDataColumnStatus}
          style={{ marginTop: groupField ? 5 : 20, marginLeft: 0 }}
          onChange={(e) => updateNoDateDataColumnStatus(e.target.checked)}
        >
          {commonTitles.hideNoDateColumn}
        </Checkbox>
      </div>
    );
  }

  if (chartType === "Pie" && config === "radial") {
    let onlyGroupFieldUsed = commonTitles["CO|1|ONLYUSEDFL"];
    var re = new RegExp("<1>", "g");
    onlyGroupFieldUsed = onlyGroupFieldUsed.replace(re, groupFieldName);
    return (
      <div className="chart__options">
        <InnerRadius />
        {/* <Colors /> */}
        {groupField && (
          <Checkbox
            checked={yValuesUsed}
            style={{ marginTop: 20 }}
            onChange={(e) => updateValuesUsed("y", e.target.checked)}
          >
            {onlyGroupFieldUsed}
          </Checkbox>
        )}
        {/* {grouping === "Monthly" ? ( */}
        {groupField && (
          <Checkbox
            checked={xValuesUsed}
            style={{ marginTop: 5, marginLeft: 0 }}
            onChange={(e) => updateValuesUsed("x", e.target.checked)}
          >
            {commonTitles["CO|1|ONLYUSEDDT"]}
          </Checkbox>
        )}
        {/* ) : (
          <Checkbox
            checked={xValuesUsed}
            disabled
            style={{ marginTop: 5, marginLeft: 0 }}
            onChange={(e) => updateValuesUsed("x", e.target.checked)}
          >
            {commonTitles["CO|1|ONLYUSEDDT"]}
          </Checkbox>
        )} */}
        <Checkbox
          checked={noDateDataColumnStatus}
          style={{ marginTop: groupField ? 5 : 20, marginLeft: 0 }}
          onChange={(e) => updateNoDateDataColumnStatus(e.target.checked)}
        >
          {commonTitles.hideNoDateColumn}
        </Checkbox>
      </div>
    );
  }

  if (chartType === "Line") {
    let onlyGroupFieldUsed = commonTitles["CO|1|ONLYUSEDFL"];
    var re = new RegExp("<1>", "g");
    onlyGroupFieldUsed = onlyGroupFieldUsed.replace(re, groupFieldName);
    return (
      <div className="chart__options">
        <LineGroupMode />
        <Interpolation />
        {/* <Colors /> */}
        {groupField && (
          <Checkbox
            checked={yValuesUsed}
            style={{ marginTop: 20 }}
            onChange={(e) => updateValuesUsed("y", e.target.checked)}
          >
            {onlyGroupFieldUsed}
          </Checkbox>
        )}
        {/* {grouping === "Monthly" ? ( */}
        {groupField && (
          <Checkbox
            checked={xValuesUsed}
            style={{ marginTop: 5, marginLeft: 0 }}
            onChange={(e) => updateValuesUsed("x", e.target.checked)}
          >
            {commonTitles["CO|1|ONLYUSEDDT"]}
          </Checkbox>
        )}
        {/* ) : (
          <Checkbox
            checked={xValuesUsed}
            disabled
            style={{ marginTop: 5, marginLeft: 0 }}
            onChange={(e) => updateValuesUsed("x", e.target.checked)}
          >
            {commonTitles["CO|1|ONLYUSEDDT"]}
          </Checkbox>
        )} */}
        <Checkbox
          checked={noDateDataColumnStatus}
          style={{ marginTop: groupField ? 5 : 20, marginLeft: 0 }}
          onChange={(e) => updateNoDateDataColumnStatus(e.target.checked)}
        >
          {commonTitles.hideNoDateColumn}
        </Checkbox>
      </div>
    );
  }
};

export default ChartBodySettings;
