import Flex from "../../../../../../helpers/components/Flex";
import EditFieldTitle from "./EditFieldTitle";
import EditFieldType from "./EditFieldtype";
import EditFieldInfo from "./EditFieldsInfo";

const EditBasicInfo = ({
  data,
  fields,
  changes,
  addChange,
  staticOptions,
  changeType,
}) => {
  return (
    <Flex flexDirection={"column"} style={{ gap: "0.5rem", marginTop: "2rem" }}>
      <EditFieldTitle
        data={data}
        changes={changes}
        addChange={addChange}
        fields={fields}
      />
      <EditFieldType
        data={data}
        changes={changes}
        addChange={addChange}
        fields={fields}
        staticOptions={staticOptions}
        changeType={changeType}
      />
      <EditFieldInfo
        data={data}
        changes={changes}
        addChange={addChange}
        fields={fields}
      />
    </Flex>
  );
};

export default EditBasicInfo;
