import Flex from "../../../../../helpers/components/Flex";
import FileUpload from "../../../../module/banner/newRegister/fileUpload/FileUpload";
import { WarningOutlined } from "@ant-design/icons";

const DocumentType = ({
  value,
  id,
  addChange,
  canupdate,
  canupdateifnull,
  required,
  config,
}) => {
  return (
    <Flex flexDirection={"row"}>
      <FileUpload
        files={value ?? []}
        addChange={addChange}
        id={id}
        canupdate={canupdate}
        canupdateifnull={canupdateifnull}
      />
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 10, marginLeft: 10 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default DocumentType;
