import apiCopyFile from "../../../../api/endpoints/apiCopyFile";

const copyFiles = async (filesToCopy, attachColumns, baseURL, commonTitles) => {
  let copyFileTracker = {};
  let copyError = false;
  for (let i = 0; i < attachColumns.length; i++) {
    if (!copyError) {
      let id = attachColumns[i];
      copyFileTracker = { ...copyFileTracker, [id]: [] };
      for (let j = 0; j < filesToCopy[id].length; j++) {
        const { status, newFileName } = await apiCopyFile(
          baseURL,
          filesToCopy[id][j].uid,
          commonTitles
        );
        if (status !== "Success") {
          copyError = true;
          break;
        }

        let copiedFile = {
          name: filesToCopy[id][j].name,
          uid: newFileName,
          link: `${baseURL}/attachments/${newFileName}`,
          status: "done",
          action: "I",
        };

        copyFileTracker[id].push(copiedFile);
      }
    } else break;
  }

  if (copyError) {
    return { copyFileTracker, copyStatus: "Error" };
  }

  return { copyFileTracker, copyStatus: "Success" };
};

export default copyFiles;
