import Flex from "../../../helpers/components/Flex";
import ModuleMenu from "./ModuleMenu";
import ParamMenu from "./ParamMenu";
import AdminMenu from "./AdminMenu";
import useWindowDimensions from "../../ultimateTable/hooks/useWindowDimensions";

const Menu = ({ paramMenu, paramMenuTitle }) => {
  const { width } = useWindowDimensions();

  return (
    <Flex style={{ marginLeft: width <= 1060 ? "6rem" : "1rem" }}>
      <ModuleMenu />
      <ParamMenu paramMenu={paramMenu} paramMenuTitle={paramMenuTitle} />
      <AdminMenu />
    </Flex>
  );
};

export default Menu;
