import { useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Dropdown from "./dropdown/Dropdown";
import { Button, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../navigation.css";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import { useEffect, useRef } from "react";
import Flex from "../../../helpers/components/Flex";

const Settings = () => {
  const [open, setOpen] = useState(false);
  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const wrapperRef = useRef(null);

  const updateVisibility = () => {
    setOpen(false);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  return (
    <Flex
      style={{
        width: "max-content",
        justifyContent: "center",
        marginTop: "0.4rem",
        marginLeft: "2rem",
        marginRight: "-1rem",
      }}
    >
      <Button
        onClick={() => setOpen(true)}
        style={{
          background: "transparent",
          color: "#fff",
          border: "none",
          marginLeft: -15,
          marginRight: -15,
          width: "max-content",
        }}
      >
        <SettingsIcon
          style={{ color: navigationTextColor }}
          className="settings"
        />
      </Button>
      <Drawer
        title={[
          <p key={"title-text"} className="drawer-header-text">
            {commonTitles["CO|1|CONFIG"]}
          </p>,
        ]}
        placement="right"
        headerStyle={{ color: "#02081b" }}
        style={{
          width: "20vw",
        }}
        closable={false}
        open={open}
        width={"20vw"}
        key={"right"}
      >
        <div ref={wrapperRef}>
          <Dropdown key={"dropdown"} updateVisibility={updateVisibility} />
        </div>
      </Drawer>
    </Flex>
  );
};

export default Settings;
