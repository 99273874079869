import { Input, Modal, Select, Button } from "antd";
import { useEffect, useState } from "react";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../store/useStores";
import CustomCheckbox from "../../../helpers/components/CustomCheckbox";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";

const { Option } = Select;

export const AdminMenuModal = ({
  visible,
  selection,
  data,
  bodyData,
  types,
  modules,
  titles,
  onSave,
  onDelete,
  onCancel,
  config,
  showDeleteModal,
}) => {
  const [changes, setChanges] = useState({});
  const dataChanged = Object.keys(changes);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [title, setTitle] = useState(null);

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  useEffect(() => {
    if (config === "new") {
      setChanges({
        ME_VISIBLE: "Y",
        ME_PARENTID: selection,
        ME_ORDER: `${bodyData.length + 1}`,
      });
    }

    if (config === "edit") {
      const tempTitle = data.T_TITLE.includes("&#39;")
        ? data.T_TITLE.split("&#39;")
            .filter((item) => item !== "")
            .join("'")
        : data.T_TITLE;
      setTitle(tempTitle);
    }
  }, []);

  useEffect(() => {
    if (title) {
      setChanges({ ...changes, T_TITLE: title });
    }
  }, [title]);

  const headers =
    [
      { id: "ME_MODULEID", title: titles["FD|19|TITLE"] },
      { id: "T_TITLE", title: titles["FD|17|TITLE"] },
      { id: "ME_PARENTID", title: titles["FD|18|TITLE"] },
      { id: "ME_VISIBLE", title: titles["FD|20|TITLE"] },
    ] ?? [];

  const showAsterisk = (id) => {
    if (dataChanged.includes(id)) {
      let isChanged = false;
      if (config === "edit") {
        const valueToCheck = data[id].includes("&#39;")
          ? data[id]
              .split("&#39;")
              .filter((item) => item !== "")
              .join("'")
          : data[id];

        if (changes[id] !== valueToCheck) {
          isChanged = true;
        } else {
          isChanged = false;
        }
      }

      if (config === "new") {
        if (changes[id]) {
          if (id === "ME_PARENTID" && changes[id] === selection) {
            isChanged = false;
          } else if (id === "ME_VISIBLE" && changes[id] === "Y") {
            isChanged = false;
          } else {
            isChanged = true;
          }
        } else {
          isChanged = false;
        }
      }

      return isChanged;
    }
  };

  return (
    <Modal
      key={`${data}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      data={data}
      headers={headers}
      types={types}
      modules={modules}
      titles={titles}
      style={{
        marginTop: 40,
        marginLeft: "30vw",
        display: "flex",
        justifyContent: "center",
      }}
      bodyStyle={{
        display: "flex",
        justifyContent: "center",
        minWidth: "50vw",
        marginBottom: "4rem",
      }}
      title={config === "edit" ? titles["MO|1|EDITROW"] : titles["MO|1|NEWROW"]}
      onOk={() => onSave(changes)}
      onCancel={() => onCancel(config)}
      onDelete={() => showDeleteModal(data.ME_ID)}
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            marginBottom: "-2rem",
          }}
          key={"footer-info"}
        >
          <p
            style={{
              color: "rgb(228, 21, 21)",
              fontSize: 18,
              marginRight: "0.3rem",
            }}
          >
            *
          </p>
          <p> - {commonTitles["CO|1|PNDGSAVE"]} </p>
        </div>,
        <Button key="back" onClick={() => onCancel(config)}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="delete"
          style={{ backgroundColor: "red", color: "white" }}
          onClick={() => showDeleteModal(data.ME_ID, data.ME_PARENTID)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(changes)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <Flex flexDirection={"row"} style={{ marginTop: "4rem" }}>
        <div className="modal-text">
          {headers.map((header) => (
            <Flex key={header.id} flexDirection={"row"}>
              {showAsterisk(header.id) ? (
                <p style={{ color: "red", marginRight: 5 }}>*</p>
              ) : (
                <p style={{ marginRight: 12 }}></p>
              )}
              <p
                key={header.id}
                style={{
                  marginRight: 5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {header.title}:
              </p>
            </Flex>
          ))}
        </div>
        <div className="modal-input">
          <div className="select">
            <Select
              style={{
                width: 300,
                marginLeft: 15,
                marginBottom: "1rem",
                marginTop: 0,
              }}
              showSearch={true}
              onChange={(e) => {
                addChange("ME_MODULEID", e.split("-")[0]);
                setTitle(e.split("-")[1]);
              }}
              filterOption={(iv, op) => {
                let opToUse = op?.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              defaultValue={
                config === "edit"
                  ? htmlCodesRemover(
                      Object.entries(modules).filter(
                        ([key, value]) => key === data?.ME_MODULEID
                      )[0]?.[1]
                    )
                  : //   .includes("&#39;")
                    // ? Object.entries(modules)
                    //     .filter(
                    //       ([key, value]) => key === data?.ME_MODULEID
                    //     )[0]?.[1]
                    //     .split("&#39;")
                    //     .filter((item) => item !== "")
                    //     .join("'")
                    // : Object.entries(modules).filter(
                    //     ([key, value]) => key === data?.ME_MODULEID
                    //   )[0]?.[1]
                    null
              }
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option className="option" value={""}></Option>
              {Object.entries(modules).map(([key, value]) => (
                <Option key={key} className="option" value={`${key}-${value}`}>
                  {htmlCodesRemover(value)}
                </Option>
              ))}
            </Select>
          </div>
          <div className="select" key={title}>
            <Input
              style={{
                width: 300,
                marginLeft: 15,
                marginTop: -5,
                marginBottom: "1rem",
              }}
              onChange={(e) => addChange("T_TITLE", e.target.value)}
              defaultValue={htmlCodesRemover(title)}
            ></Input>
          </div>
          <div className="select">
            <Select
              style={{
                width: 300,
                marginLeft: 15,
                marginBottom: "1rem",
              }}
              showSearch={true}
              onChange={(e) => addChange("ME_PARENTID", e)}
              filterOption={(iv, op) => {
                let opToUse = op?.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              defaultValue={
                config === "edit"
                  ? types.filter((type) => type.ME_ID === data?.ME_PARENTID)[0]
                      ?.ME_ID
                  : types.filter((type) => type.ME_ID === selection)[0].ME_ID
              }
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option className="option" value={""}></Option>
              {types.map((type) => (
                <Option key={type.ME_ID} className="option" value={type.ME_ID}>
                  {type.T_TITLE}
                </Option>
              ))}
            </Select>
          </div>

          <div style={{ marginLeft: 13, marginTop: -3 }}>
            <CustomCheckbox
              id={"ME_VISIBLE"}
              value={config === "edit" ? data.ME_VISIBLE : "Y"}
              addChange={addChange}
              config={config}
              isDisabled={false}
            />
          </div>
          {/* <div className="select"> 
           <Select
              style={{
                width: 300,
                marginLeft: 15,
              }}
              showSearch={true}
              onChange={(e) => addChange("ME_VISIBLE", e)}
              filterOption={(iv, op) => {
                let opToUse = op?.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              defaultValue={config === "edit" ? data.ME_VISIBLE : "Y"}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option className="option" value={"Y"}>
                {titles["FO|42|"]}
              </Option>
              <Option className="option" value={"N"}>
                {titles["FO|43|"]}
              </Option>
            </Select> 
          </div> */}
        </div>
      </Flex>
    </Modal>
  );
};
