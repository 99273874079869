import Flex from "../../../../../helpers/components/Flex";

const InfoDocument = ({ value }) => {
  if (value?.length) {
    const newValue = value?.map((item) => (
      <Flex
        key={item?.uid}
        direction={"horizontal"}
        style={{ width: "fit-content" }}
      >
        {value?.length > 1 && <p style={{ marginRight: 5 }}>{"•"}</p>}
        <a
          className="link"
          href={`${item?.url}`}
          target="_blank"
          rel="noreferrer"
        >
          {item?.name}
          <br />
        </a>
      </Flex>
    ));

    return newValue;
  }
  return <div />;
};

export default InfoDocument;
