import axios from "axios";

const apiGetFkValues = async (
  baseURL,
  moduleID,
  fieldID,
  parentID,
  parentValue
) => {
  const url = `${baseURL}/Data_api/getvalues`;

  // console.log("apiGetFkValues: moduleID: ", moduleID);
  // console.log("apiGetFkValues: fieldID: ", fieldID);
  // console.log("apiGetFkValues: parentID: ", parentID);
  // console.log("apiGetFkValues: parentValue: ", parentValue);

  try {
    const response = await axios.post(
      url,
      {
        moduleid: moduleID,
        fieldid: fieldID,
        parentid: parentID,
        parentvalue: parentValue,
      },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Data_api/getfkvalues: response.data: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiGetFkValues;
