import "./userRoles.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

const UserRolesHead = ({ titles }) => {
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell key={"ghost-cell"} />

        <TableCell className="admin-header">
          <p>{titles["FD|250|TITLE"]}</p>
        </TableCell>

        <TableCell key={`button`} />
      </TableRow>
    </TableHead>
  );
};

export default UserRolesHead;
