import { message } from "antd";
import axios from "axios";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";

const apiDeleteFile = async (baseURL, id, commonTitles) => {
  const url = `${baseURL}/Data_api/deletefile`;
  try {
    const response = await axios.post(
      url,
      { filename: id },
      {
        withCredentials: true,
      }
    );
    console.log("apiDeleteFile: response: ", response.data);
    if (response?.data?.STATUS === "Success") {
      //message.success(commonTitles["CO|1|DLTFILE"], [3]);
      displayResponseMessage(commonTitles["CO|1|DLTFILE"], 3, "success");
    } else {
      //message.warning(commonTitles["CO|1|NOFILE"], [3]);
      displayResponseMessage(commonTitles["CO|1|NOFILE"], 3, "error");
    }
    return response?.data?.STATUS;
  } catch (err) {
    //message.error(err, [3]);
    displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
  }
};

export default apiDeleteFile;
