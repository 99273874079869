import Flex from "../../../../../helpers/components/Flex";
import { Select } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useAuthStore } from "../../../../../store/useStores";

const { Option } = Select;

const StaticListType = ({
  value,
  id,
  config,
  required,
  addChange,
  staticOptions,
  canupdate,
  canupdateifnull,
  row,
  autoCompletedFields = {},
}) => {
  const userID = useAuthStore((state) => state.userID);
  let newValue = value?.view ?? {};

  if (typeof value !== "object") {
    newValue = value ? value : [];
  }

  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    newValue = autoCompletedFields[id];
  }

  if (config === "filters") {
    newValue = value ?? [];
  }

  let isDisabled = false;
  if (config !== "filters") {
    if (Object.keys(autoCompletedFields).includes(id)) {
      isDisabled = true;
    } else {
      if (canupdate === false) {
        if (canupdateifnull === true) {
          if (newValue.length) {
            if (row[`${id}-US`] !== userID) {
              isDisabled = true;
            }
          }
        } else {
          isDisabled = true;
        }
      }
    }
  }

  let options = [];

  if (staticOptions?.[id]) {
    options = Object.entries(staticOptions[id]).map(([key, value]) => ({
      key: key,
      title: value?.TITLE,
      order: value?.ORDER ? parseInt(value?.ORDER) : null,
    }));
  }

  options.sort((a, b) => a.order - b.order);

  return (
    <Flex flexDirection={"row"}>
      <div className="select">
        <Select
          mode={config === "filters" && "multiple"}
          allowClear
          style={{
            width: 278,
            marginLeft: 7,
            marginTop: 4,
          }}
          showSearch={true}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          onChange={(e) => addChange(id, e, null)}
          defaultValue={newValue}
          disabled={isDisabled}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option value=""></Option>
          {options.map((item) => (
            <Option key={item?.key} className="option" value={item?.key}>
              {item?.title}
            </Option>
          ))}
        </Select>
      </div>
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 8, marginLeft: 15 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default StaticListType;
