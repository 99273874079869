const createDataForTable = (data, type, config) => {
  let dataForTable = [];

  if (type === "Bar") {
    dataForTable = data;
  }

  if (type === "Pie" && config === "single") {
    dataForTable[0] = { id: [data[0].generalKey] };
    data.forEach((item) => {
      dataForTable[0] = { ...dataForTable[0], [item.label]: item.value };
    });
  }

  if (type === "Pie" && config === "radial") {
    for (let i = 0; i < data.length; i++) {
      dataForTable[i] = { id: data[i].id };
      data[i].data.forEach((item) => {
        dataForTable[i] = { ...dataForTable[i], [item.x]: item.y };
      });
    }
  }

  if (type === "Line") {
    const dates = data[0].data.map((item) => item.x);

    for (let i = 0; i < dates.length; i++) {
      dataForTable[i] = { id: dates[i] };
      for (let j = 0; j < data.length; j++) {
        for (let z = 0; z < data[j].data.length; z++) {
          if (data[j].data[z].x === dates[i]) {
            dataForTable[i] = {
              ...dataForTable[i],
              [data[j].id]: data[j].data[z].y,
            };
          }
        }
      }
    }
  }

  return dataForTable;
};

export default createDataForTable;
