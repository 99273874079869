import Flex from "../../../../../helpers/components/Flex";
import htmlCodesRemover from "../../htmlCodesRemover";

const InfoText = ({ value, config, id }) => {
  if (config === "alerts" && id === "info") {
    const newValue = value.map((item, index) =>
      item.title ? (
        <Flex
          key={index}
          flexDirection={"column"}
          style={{
            gap: "0.1rem",
            justifyContent: "flex-start",
            marginBottom: "0.5rem",
          }}
        >
          <b className="p-context">{item.title}:</b>
          <p className="p-context">{item.value}</p>
        </Flex>
      ) : null
    );

    return newValue ?? "";
  }

  return <p>{htmlCodesRemover(value)}</p>;

  // if (value && value.includes("&#39;")) {
  //   let splitValue = value
  //     .split("&#39;")
  //     .filter((item) => item !== "")
  //     .join("'");
  //   if (splitValue && splitValue.includes("\n")) {
  //     let newSplitValue = splitValue.split("\n").filter((item) => item !== "");
  //     const newValue = newSplitValue.map((item) => (
  //       <p className="p-context" key={item}>
  //         {item}
  //         <br />
  //       </p>
  //     ));
  //     return newValue;
  //   } else if (splitValue && splitValue.includes("\n")) {
  //     let newSplitValue = splitValue
  //       .split("&#10;")
  //       .filter((item) => item !== "");
  //     const newValue = newSplitValue.map((item) => (
  //       <p className="p-context" key={item}>
  //         {item}
  //         <br />
  //       </p>
  //     ));
  //     return newValue;
  //   } else if (splitValue && splitValue.includes("&#10;")) {
  //     let newSplitValue = splitValue
  //       .split("&#10;")
  //       .filter((item) => item !== "");
  //     const newValue = newSplitValue.map((item) => (
  //       <p className="p-context" key={item}>
  //         {item}
  //         <br />
  //       </p>
  //     ));
  //     return newValue;
  //   }
  //   return <p className="p-context">{splitValue}</p>;
  // } else if (value && value.includes("\n")) {
  //   let splitValue = value.split("\n").filter((item) => item !== "");
  //   const newValue = splitValue.map((item) => (
  //     <p className="p-context" key={item}>
  //       {item}
  //       <br />
  //     </p>
  //   ));
  //   return newValue;
  // } else if (value && value.includes("&#10;")) {
  //   let newSplitValue = value.split("&#10;").filter((item) => item !== "");
  //   const newValue = newSplitValue.map((item) => (
  //     <p className="p-context" key={item}>
  //       {item}
  //       <br />
  //     </p>
  //   ));
  //   return newValue;
  // } else {
  //   return <p className="p-context">{value}</p>;
  // }
};

export default InfoText;
