import { useCommonTitlesStore } from "../../../../../store/useStores";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { Col, Modal, Row, Button, Card, Tooltip } from "antd";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import "../css/fieldsConfig.css";
import Flex from "../../../../../helpers/components/Flex";

const FieldsOrderModalContent = ({ items, updateFieldOrderItems }) => {
  const SortableItem = sortableElement(({ value }) => (
    <Col className="gutter-row" span={10} key={value.F_ID}>
      <div className="draggable">
        <p className="draggable-first_column">
          {value?.T_TITLE ? (
            value.T_TITLE.length > 30 ? (
              <Tooltip
                placement="bottom"
                color="white"
                overlayInnerStyle={{ color: "black" }}
                title={
                  <Flex flexDirection={"column"}>
                    <b>{value.T_TITLE}</b>
                    {value?.INFO && <i>{value.INFO}</i>}
                  </Flex>
                }
              >
                {value.T_TITLE.slice(0, 22) + "..."}:
              </Tooltip>
            ) : (
              <Tooltip
                placement="bottom"
                title={value?.INFO && <i>{value.INFO}</i>}
                color="white"
                overlayInnerStyle={{ color: "black" }}
              >
                {value.T_TITLE}:
              </Tooltip>
            )
          ) : null}
        </p>

        <FormControl
          sx={{
            mt: 0,
            mb: 0.5,
            ml: 1,
            mr: 1,
            minWidth: 120,
          }}
          className="filters-row"
          key={value.F_ID}
        >
          <p
            style={{
              border: value?.T_TITLE ? "1px solid grey" : "1px dashed grey",
              height: 25,
              borderRadius: "10px",
              display: "flex",
              background: value?.T_TITLE ? "#EFEBEB" : "transparent",
            }}
          />
        </FormControl>
      </div>
    </Col>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <Row
        gutter={[20, 10]}
        justify={"left"}
        style={{ marginTop: "1rem", marginLeft: "5rem" }}
      >
        {children}
      </Row>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tempItems = [];

    items.forEach((item, index) => {
      if (newIndex > oldIndex) {
        if (index < oldIndex || index > newIndex) {
          tempItems[index] = item;
        } else if (index > oldIndex && index <= newIndex) {
          tempItems[index - 1] = item;
        } else if (index === oldIndex) {
          tempItems[newIndex] = item;
        }
      } else if (oldIndex === newIndex) {
        tempItems[index] = item;
      } else {
        if (index < newIndex || index > oldIndex) {
          tempItems[index] = item;
        } else if (index >= newIndex && index < oldIndex) {
          tempItems[index + 1] = item;
        } else if (index === oldIndex) {
          tempItems[newIndex] = item;
        }
      }
    });

    tempItems = tempItems.filter((item, index) => {
      if (item.F_TYPE === "0" && index === tempItems.length - 1) {
        return false;
      } else {
        return true;
      }
    });

    let finalData = [];
    for (let i = 0; i < tempItems.length; i++) {
      // console.log("finalData: tempItems[i-1]: ", tempItems[i - 1]?.T_TITLE);
      // console.log("finalData: tempItems[i]: ", tempItems[i]?.T_TITLE);
      // console.log("finalData: tempItems[i+1]: ", tempItems[i + 1]?.T_TITLE);
      // console.log("finalData: i: ", i);
      // console.log("finalData: i % 2: ", i % 2);
      if (
        i > 0 &&
        i < tempItems.length - 1 &&
        tempItems[i].F_TYPE !== "0" &&
        tempItems[i - 1].F_TYPE === "0" &&
        tempItems[i + 1].F_TYPE === "0"
      ) {
        if (i % 2 === 0) {
          finalData[i] = { ...tempItems[i], F_SINGLELINE: "L" };
        } else {
          finalData[i] = { ...tempItems[i], F_SINGLELINE: "R" };
        }
      } else if (
        i > 0 &&
        tempItems[i].F_TYPE !== "0" &&
        tempItems[i - 1].F_TYPE === "0"
      ) {
        if (i % 2 === 0) {
          finalData[i] = { ...tempItems[i], F_SINGLELINE: "" };
        } else {
          finalData[i] = { ...tempItems[i], F_SINGLELINE: "R" };
        }
      } else if (
        i < tempItems.length - 1 &&
        tempItems[i].F_TYPE !== "0" &&
        tempItems[i + 1].F_TYPE === "0"
      ) {
        if (i % 2 === 0) {
          finalData[i] = { ...tempItems[i], F_SINGLELINE: "L" };
        } else {
          finalData[i] = { ...tempItems[i], F_SINGLELINE: "" };
        }
      } else {
        finalData[i] = { ...tempItems[i], F_SINGLELINE: "" };
      }
    }

    finalData = finalData.map((item, index) => ({
      ...item,
      F_EDITORDER: (index + 1).toString(),
    }));

    //console.log("finalData: ", finalData);
    updateFieldOrderItems(finalData);
  };

  return (
    <Card
      key={items.length}
      style={{ display: "flex", width: "80vw" }}
      bodystyle={{
        display: "flex",
        heigth: "55vh",
        width: "100%",
        overflowY: "auto",
        justifyContent: "center",
      }}
    >
      <SortableContainer key={items.length} onSortEnd={onSortEnd} axis="xy">
        {items?.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </SortableContainer>
    </Card>
  );
};

const FieldsOrderModal = ({
  visible,
  items,
  onSave,
  onCancel,
  updateFieldOrderItems,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [orderItems, setOrderItems] = useState([]);

  useEffect(() => {
    setOrderItems(items);
  }, [items]);

  const addBlankItem = () => {
    const tempItems = [
      ...orderItems,
      {
        F_TYPE: "0",
        F_EDITORDER: `${orderItems.length + 1}`,
        F_ID: `${orderItems.length + 1}`,
        T_TITLE: "",
      },
    ];
    setOrderItems(tempItems);
  };

  return (
    <Modal
      mask={false}
      maskClosable={false}
      open={visible}
      style={{
        //marginTop: "-11vh",
        display: "flex",
        marginLeft: "5vw",
        width: "90vw",
      }}
      bodyStyle={{
        display: "flex",
        width: "max-content",
        minWidth: "50vw",
        maxWidth: "90vw",
        height: "70vh",
        overflowY: "auto",
      }}
      title={commonTitles["CO|1|ORDERMOD"]}
      onOk={() => onSave(items)}
      onCancel={() => onCancel()}
      footer={[
        <div className="align-left" key={"modal-info"}>
          <Button onClick={() => addBlankItem()}>
            {commonTitles["CO|1|ADDBLANK"]}
          </Button>
        </div>,
        <Button key="back" onClick={() => onCancel()}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{
            backgroundColor: "rgb(8, 8, 46)",
            color: "white",
            marginTop: 15,
          }}
          onClick={() => onSave(items)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <FieldsOrderModalContent
        key={orderItems.length}
        items={orderItems}
        updateFieldOrderItems={updateFieldOrderItems}
      />
    </Modal>
  );
};

export default FieldsOrderModal;
