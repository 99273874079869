import Flex from "../../../../../helpers/components/Flex";
import { Progress } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const RenderProgress = ({ value }) => {
  let percentageValueParts = "";
  let newValue = value ?? "";
  if (typeof value === "object") {
    percentageValueParts = value?.view?.split("%");
    newValue = value.view;
  } else {
    percentageValueParts = value?.split("%");
  }

  newValue = newValue ?? "";

  return (
    <Flex flexDirection={"row"}>
      <div className="progress-bar">
        <Progress
          type="line"
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          percent={percentageValueParts?.[0]}
          size="small"
          showInfo={false}
        />
      </div>
      <div className="progress-info">
        {percentageValueParts?.[0] === "100" ? (
          <CheckCircleFilled style={{ color: "#52c41a", marginTop: 1 }} />
        ) : (
          <p>{newValue}</p>
        )}
      </div>
    </Flex>
  );
};

export default RenderProgress;
