import SearchIcon from "@mui/icons-material/Search";
import "../navigation.css";
import { useCommonTitlesStore } from "../../../store/useStores";

const ProjectSearch = ({ value, changeProjectItems, updateVisibility }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <div className="project__search" onClick={(e) => e.stopPropagation()}>
      <SearchIcon className="project__search-icon" />
      <input
        autoFocus
        className="project__searchInput"
        type="text"
        style={{ width: 250, border: "none" }}
        placeholder={`${commonTitles.search}...`}
        defaultValue={value ?? ""}
        onChange={(e) => {
          updateVisibility(true);
          changeProjectItems(e.target.value);
          return false;
        }}
      />
    </div>
  );
};

export default ProjectSearch;
