import { useAuthStore } from "../../../../../store/useStores";
import Flex from "../../../../../helpers/components/Flex";
import { Input } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const GeneralCase = ({
  value,
  id,
  required,
  config,
  addChange,
  canupdate,
  canupdateifnull,
  row,
}) => {
  const userID = useAuthStore((state) => state.userID);
  const newValue = value ?? "";

  if (value && value.includes("&#39;")) {
    let splitValue = value
      .split("&#39;")
      .filter((item) => item !== "")
      .join("'");
    newValue = splitValue;
    if (splitValue && splitValue.includes("&#10;")) {
      let newSplitValue = value.split("&#10;").filter((item) => item !== "");
      let str = "";
      newSplitValue.forEach((item) => {
        if (str !== "") {
          str += "\n";
        }
        str += item;
      });
      newValue = str;
    }
  } else if (value && value.includes("&#10;")) {
    let newSplitValue = value.split("&#10;").filter((item) => item !== "");
    let str = "";
    newSplitValue.forEach((item) => {
      if (str !== "") {
        str += "\n";
      }
      str += item;
    });
    newValue = str;
  }

  let isDisabled = false;
  if (config !== "filters") {
    if (canupdate === false) {
      if (canupdateifnull === true) {
        if (newValue.length) {
          if (row[`${id}-US`] !== userID) {
            isDisabled = true;
          }
        }
      } else {
        isDisabled = true;
      }
    }
  }

  return (
    <Flex flexDirection={"row"}>
      <Input
        type="text"
        defaultValue={newValue}
        onChange={(e) => addChange(id, e.target.value, null)}
        disabled={isDisabled}
      />
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default GeneralCase;
