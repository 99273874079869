const createChartConfigurations = (
  data,
  chartType,
  displayFunction,
  groupMode
) => {
  let indexBy = "id";
  let keys = [];
  let max = 0;
  let total = [];

  if (chartType === "Bar") {
    indexBy = "id";

    keys =
      data.length &&
      Object.keys(data[0]).filter((item) => item !== "id" && item !== "sum");

    if (displayFunction === "count" || displayFunction === "sum") {
      if (data.length && groupMode === "stacked") {
        const tempData = [];
        data.forEach((item) => {
          item = Object.fromEntries(
            Object.entries(item).filter(
              ([key]) => key !== "id" && key !== "sum"
            )
          );
          tempData.push(item);
        });
        tempData.forEach((item) => {
          let sum = 0;
          Object.entries(item).forEach(([key, value]) => {
            sum += value;
          });
          if (sum > max) {
            max = sum;
          }
        });
      } else {
        const tempData = [];
        data.forEach((item) => {
          item = Object.fromEntries(
            Object.entries(item).filter(
              ([key]) => key !== "id" && key !== "sum"
            )
          );
          tempData.push(item);
        });

        tempData.forEach((item) => {
          Object.entries(item)
            .filter(([key]) => key !== "id" && key !== "sum")
            .forEach(([key, value]) => {
              if (value > max) {
                max = value;
              }
            });
        });
      }

      if (max > 50 && max < 150) {
        for (let i = 0; i < max + 1; i = i + 5) {
          total.push(i);
        }
      } else if (max > 150) {
        for (let i = 0; i < max + 1; i = i + 10) {
          total.push(i);
        }
      } else {
        for (let i = 0; i < max + 1; i++) {
          total.push(i);
        }
      }
    } else {
      max = 1;
      total = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
    }
  }

  if (chartType === "Line") {
    if (groupMode) {
      if (displayFunction === "count" || displayFunction === "sum") {
        for (let i = 0; i < data.length; i++) {
          const temp = Object.values(data[i].data);
          for (let j = 0; j < temp.length; j++) {
            if (temp[j].y > max) {
              max = temp[j].y;
            } else continue;
          }
        }

        if (max > 50 && max < 150) {
          for (let i = 0; i < max + 1; i = i + 5) {
            total.push(i);
          }
        } else if (max > 150) {
          for (let i = 0; i < max + 1; i = i + 10) {
            total.push(i);
          }
        } else {
          for (let i = 0; i < max + 1; i++) {
            total.push(i);
          }
        }
      } else {
        max = 1;
        total = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
      }
    }

    if (!groupMode) {
      if (displayFunction === "count" || displayFunction === "sum") {
        const maxByItem = [];

        let temp = Object.values(data[0].data);
        temp = temp.map((item) => item.x);

        for (let i = 0; i < temp.length; i++) {
          let sum = 0;
          let id = temp[i];
          for (let j = 0; j < data.length; j++) {
            for (let z = 0; z < data[j].data.length; z++) {
              if (data[j].data[z].x === id) {
                sum += data[j].data[z].y;
              }
            }
          }
          maxByItem.push(sum);

          max = Math.max(...maxByItem);

          if (max > 50 && max < 150) {
            for (let i = 0; i < max + 1; i = i + 5) {
              total.push(i);
            }
          } else if (max > 150) {
            for (let i = 0; i < max + 1; i = i + 10) {
              total.push(i);
            }
          } else {
            for (let i = 0; i < max + 1; i++) {
              total.push(i);
            }
          }
        }
      } else {
        max = 1;
        total = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
      }
    }
  }

  return { indexBy, keys, max, total };
};

export default createChartConfigurations;
