import SearchIcon from "@mui/icons-material/Search";
import Flex from "./Flex";
import { useAuthStore, useCommonTitlesStore } from "../../store/useStores";
import { ConfigProvider, Input } from "antd";
import useWindowDimensions from "../../components/ultimateTable/hooks/useWindowDimensions";

const BannerSearch = ({ updateSearchValue }) => {
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const { width } = useWindowDimensions();

  const style = {
    flex: 1,
    justifyContent: "flex-end",
    marginRight: "2rem",
    color: "white",
    alignItems: "center",
    marginLeft: "-2rem",
    display: width < 1100 ? "none" : "flex",
  };

  const iconStyle = {
    marginLeft: "2rem",
    marginBottom: "0.3rem",
    color: bannerTextColor,
  };

  const inputStyle = {
    borderRadius: 15,
    border: `1px solid ${bannerTextColor}`,
    background: "transparent",
    lineHeight: 40,
    paddingLeft: 20,
    fontSize: 13,
    marginLeft: "0.5rem",
    width: 250,
    marginRight: -20,
    maxWidth: 250,
    color: bannerTextColor,
    height: 25,
    marginTop: -6,
  };

  return (
    <Flex flexDirection={"row"} style={style}>
      <SearchIcon style={iconStyle} />
      <ConfigProvider
        theme={{
          token: {
            colorTextPlaceholder:
              bannerTextColor.replace(")", ", 0.75)").replace("rgb", "rgba") ??
              "rgb(255, 255, 255)",
          },
        }}
      >
        <Input
          style={inputStyle}
          placeholder={`${commonTitles.search}...`}
          onChange={(e) => updateSearchValue(e.target.value)}
        />
      </ConfigProvider>
    </Flex>
  );
};

export default BannerSearch;
