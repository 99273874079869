import apiDeleteFile from "../../../../api/endpoints/apiDeleteFile";

const deleteFiles = async (
  filesToDelete,
  attachColumns,
  baseURL,
  commonTitles
) => {
  let deleteError = false;
  let deleteFileTracker = {};
  for (let i = 0; i < attachColumns.length; i++) {
    if (!deleteError) {
      let id = attachColumns[i];
      deleteFileTracker = { ...deleteFileTracker, [id]: [] };
      for (let j = 0; j < filesToDelete[id].length; j++) {
        const deleteStatus = await apiDeleteFile(
          baseURL,
          filesToDelete[id][j].id,
          commonTitles
        );
        if (deleteStatus !== "Success") {
          break;
        }
        deleteFileTracker[id].push(filesToDelete[id][j]);
      }
    } else break;
  }

  if (deleteError) {
    return { deleteFileTracker, deleteStatus: "Error" };
  }

  return { deleteFileTracker, deleteStatus: "Success" };
};

export default deleteFiles;
