import { Modal, Button } from "antd";
import { useCommonTitlesStore } from "../../../../store/useStores";

const ConfirmationModal = ({
  file,
  confirmationMessage,
  onCancel,
  onSave,
  visible,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Modal
      title={commonTitles["CO|1|IMPORTFILE"]}
      open={visible}
      onOk={() => onSave(file)}
      onCancel={() => onCancel("confirmation")}
      destroyOnClose={true}
      width="max-content"
      style={{ marginTop: 40, minWidth: 650 }}
      footer={[
        <Button key="back" onClick={() => onCancel("confirmation")}>
          {commonTitles.cancel}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{
            backgroundColor: "rgb(8, 8, 46)",
            color: "white",
            marginTop: "1rem",
          }}
          onClick={() => onSave(file)}
        >
          {commonTitles["CO|1|UPLOAD"]}
        </Button>,
      ]}
    >
      {confirmationMessage}
    </Modal>
  );
};

export default ConfirmationModal;
