import Flex from "../../../../../../helpers/components/Flex";
import { Select } from "antd";

const { Option } = Select;

const Totals = ({ data, addChange, fields, config, staticOptions }) => {
  let defaultValue = [];

  if (config === "edit") {
    if (data.F_TOTALS) {
      if (data.F_TOTALS.length === 1 && !data.F_TOTALS?.[0]) {
        defaultValue = [];
      } else {
        defaultValue = data.F_TOTALS;
      }
    }
  }

  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[32].TITLE}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          mode="multiple"
          showSearch={true}
          onChange={(e) => {
            addChange("F_TOTALS", e);
          }}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          defaultValue={defaultValue}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={[]} />
          <Option key={"AVG"} className="option" value={"AVG"}>
            {staticOptions[32].AVG.TITLE}
          </Option>
          <Option key={"CNT"} className="option" value={"CNT"}>
            {staticOptions[32].CNT.TITLE}
          </Option>
          <Option key={"MAX"} className="option" value={"MAX"}>
            {staticOptions[32].MAX.TITLE}
          </Option>
          <Option key={"MIN"} className="option" value={"MIN"}>
            {staticOptions[32].MIN.TITLE}
          </Option>
          <Option key={"SUM"} className="option" value={"SUM"}>
            {staticOptions[32].SUM.TITLE}
          </Option>
        </Select>
      </div>
    </Flex>
  );
};

export default Totals;
