import React from "react";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@material-ui/core/styles";
import {
  useCommonTitlesStore,
  useChartStore,
} from "../../../../store/useStores";

function valuetext(value) {
  return `${value}°`;
}

const startMarks = [
  {
    value: 0,
    label: "0°",
  },
  {
    value: 45,
    label: "45°",
  },
  {
    value: 90,
    label: "90°",
  },
  {
    value: 180,
    label: "180°",
  },
];

const useStyles = makeStyles((theme) => ({
  mark: {
    color: "rgb(8, 8, 46)",
    fontSize: "13px",
  },
  valueLabelDisplay: {
    color: "white",
    background: "rgb(8, 8, 46)",
  },
}));

const StartAngle = () => {
  const classes = useStyles();
  const startAngle = useChartStore((state) => state.startAngle);
  const setStartAngle = useChartStore((state) => state.setStartAngle);

  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <div className="chart__options-angles">
      <p>{commonTitles.startAngle}</p>
      <Slider
        sx={{ width: 160, mr: 3, mt: -1 }}
        size="small"
        aria-label="Start Angle"
        value={startAngle}
        onChange={(e) => setStartAngle(e.target.value)}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={1}
        marks={startMarks}
        classes={{
          markLabel: classes.mark,
          valueLabel: classes.valueLabelDisplay,
        }}
        min={0}
        max={180}
      />
    </div>
  );
};

export default StartAngle;
