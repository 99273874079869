import { ResponsivePie } from "@nivo/pie";
import {
  useCommonTitlesStore,
  useChartStore,
} from "../../../../store/useStores";
import createLabels from "../../UC helpers/createLabels";
import Flex from "../../../../helpers/components/Flex";
import "./pie.css";

const Pie = ({ data, staticOptions, columns, chartTitle }) => {
  const startAngle = useChartStore((state) => state.startAngle);
  const endAngle = useChartStore((state) => state.endAngle);
  const color = useChartStore((state) => state.color);
  const sumField = useChartStore((state) => state.sumField);
  const displayFunction = useChartStore((state) => state.displayFunction);
  const colorSet20 = useChartStore((state) => state.colorSet20);
  const colorSet40 = useChartStore((state) => state.colorSet40);
  const grouping = useChartStore((state) => state.grouping);
  const dateField = useChartStore((state) => state.dateField);
  const groupField = useChartStore((state) => state.groupField);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let childGroupField;
  let groupFieldToUse = groupField;

  if (groupField?.includes("-")) {
    let tempGroupField = groupField.split(" - ");

    groupFieldToUse = tempGroupField[0];
    childGroupField = tempGroupField[1];
  }

  const groupFieldColumn = columns?.filter(
    (column) => column.F_ID === groupFieldToUse
  )[0];

  let groupFieldName;
  let groupFieldType;
  let childGroupFieldIndex;
  let childGroupNumber;
  if (childGroupField) {
    const childTitle = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.TITLE);
    const childType = groupFieldColumn?.DEP_FIELDS?.filter(
      (field) => field.F_ID === childGroupField
    ).map((item) => item.F_TYPE);
    groupFieldName = `${groupFieldColumn?.T_TITLE} - ${childTitle}`;
    groupFieldType = childType;
    childGroupNumber = groupFieldColumn?.DEP_FIELDS?.length;
    const dependentFields = columns?.filter(
      (column) => column.F_ID === groupFieldToUse
    )[0]?.DEP_FIELDS;

    childGroupFieldIndex = dependentFields.findIndex((object) => {
      return object.F_ID === childGroupField;
    });
  } else {
    groupFieldName = groupFieldColumn?.T_TITLE;
    groupFieldType = groupFieldColumn?.F_TYPE;
  }

  let numOfLegends = data.length;
  const { XLabel } = createLabels(
    grouping,
    displayFunction,
    commonTitles,
    dateField,
    columns
  );

  //console.log("PieChart: data: ", data);

  let chartColor = colorSet20;
  if (numOfLegends > 20) {
    chartColor = [...colorSet20, ...colorSet40];
  }

  let dataForLegends = data.map((item) => item.id);

  //console.log("dataForLegends: ", dataForLegends);

  let dataForLegendsPart1 = [];
  let dataForLegendsPart2 = [];
  if (numOfLegends <= 20) {
    dataForLegendsPart1.push(...dataForLegends);
  } else {
    for (let i = 0; i < dataForLegends.length; i++) {
      if (i <= numOfLegends / 2 - 1) {
        dataForLegendsPart1.push(dataForLegends[i]);
      } else {
        dataForLegendsPart2.push(dataForLegends[i]);
      }
    }
    dataForLegendsPart2.push(
      `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
    );
  }

  if (groupFieldType === "6") {
    let options = Object.values(staticOptions?.[groupField]);
    options.sort((a, b) => a.ORDER - b.ORDER);

    dataForLegends = options.map((option) => option.TITLE);
    numOfLegends = dataForLegends.length;
    if (dataForLegends.length > 20) {
      dataForLegendsPart1 = dataForLegends.slice(
        0,
        dataForLegends.length / 2 - 1
      );
      dataForLegendsPart2 = dataForLegends.slice(
        dataForLegends.length / 2 - 1,
        dataForLegends.length
      );
      dataForLegendsPart2.push(
        `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
      );
    } else {
      dataForLegendsPart1 = dataForLegends;
      dataForLegendsPart1.push(
        `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`
      );
    }
  }

  if (["sum", "pctSum"].includes(displayFunction) && !sumField) {
    dataForLegendsPart1 = data.map((item) => item.generalKey);
  }

  let colorKeys = data.map((item) => item.id);

  if (groupFieldType === "6") {
    colorKeys = Object.values(staticOptions?.[groupField]).map(
      (item) => item.TITLE
    );
  }

  if (["sum", "pctSum"].includes(displayFunction) && !sumField) {
    colorKeys = data.map((item) => item.generalKey);
  }

  let i = 0;
  let pieColors = {};

  colorKeys.forEach((key, index) => {
    if (groupFieldType === "6") {
      const color = Object.values(staticOptions?.[groupField]).filter(
        (item) => item?.TITLE === key
      )?.[0]?.BC;

      if (!["#ffffff", "rgb(255, 255, 255)"].includes(color) && color) {
        pieColors = { ...pieColors, [key]: color };
      } else {
        pieColors = { ...pieColors, [key]: chartColor[i] };
        i++;
      }
    } else {
      if (key === `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`) {
        pieColors = { ...pieColors, [key]: "white" };
      } else {
        pieColors = { ...pieColors, [key]: chartColor[i] };
        i++;
      }
    }
  });

  const getColor = (pie) => {
    return pieColors?.[pie?.id];
  };

  //console.log("staticData: ", staticData);

  //console.log("dataForLegendsPart1: ", dataForLegendsPart1);
  //console.log("dataForLegendsPart2: ", dataForLegendsPart2);

  let showLegends = true;

  if (!groupField) {
    showLegends = false;
  }

  if (["sum", "pctSum"].includes(displayFunction) && !sumField) {
    showLegends = false;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: 400,
            fontWeight: 500,
            marginBottom: -20,
          }}
        >{`${XLabel}: ${data[0].generalKey}`}</div>
        <ResponsivePie
          data={data}
          margin={{ top: 75, bottom: 90, left: 100, right: 500 }}
          innerRadius={0.5}
          startAngle={startAngle}
          endAngle={endAngle}
          valueFormat={
            ["pctCount", "pctSum"].includes(displayFunction) && " >-.1%"
          }
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          //colors={numOfLegends > 10 ? chartColorPalette : { scheme: color }}
          colors={getColor}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          enableArcLabels={true}
          enableArcLinkLabels={
            groupField && !["sum", "pctSum"].includes(displayFunction) && true
          }
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="rgb(8, 8, 46)"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={"rgb(8, 8, 46)"}
          arcLabelsSkipAngle={10}
          // arcLabelsTextColor={{
          //   from: "color",
          //   modifiers: [["darker", 2]],
          // }}
          defs={[
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgb(161, 160, 160)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}`,
              },
              id: "lines",
            },
          ]}
          // legends={[
          //   {
          //     anchor: "right",
          //     direction: "column",
          //     justify: false,
          //     translateX: 300,
          //     translateY: -100,
          //     itemsSpacing: 3,
          //     itemWidth: 50,
          //     itemHeight: 12,
          //     textColor: "rgb(8, 8, 46)",
          //     itemTextColor: "rgb(8, 8, 46)",
          //     itemDirection: "left-to-right",
          //     itemOpacity: 1,
          //     symbolSize: 12,
          //     symbolShape: "circle",
          //     effects: [
          //       {
          //         on: "hover",
          //         style: {
          //           itemTextColor: "#000",
          //         },
          //       },
          //     ],
          //   },
          // ]}
        />
      </div>
      <Flex
        flexDirection={"column"}
        style={{
          marginLeft: "-20rem",
          marginTop: "4rem",
          display: !showLegends && "none",
        }}
      >
        {dataForLegendsPart1.map((item, index) => (
          <Flex
            key={index}
            flexDirection={"row"}
            style={{
              maxHeight: 13,
              marginBottom: 2,
              marginTop: numOfLegends < 15 ? 4 : 2,
            }}
          >
            {item === `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}` ? (
              <div
                className={
                  item ===
                    `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}` &&
                  "noData"
                }
                style={{
                  width: numOfLegends < 15 ? 13 : 10,
                  maxHeight: 9,
                  borderRadius: 15,
                  border: "solid rgb(161, 160, 160) 0.5px",
                }}
              />
            ) : (
              <div
                style={{
                  width: numOfLegends < 15 ? 13 : 10,
                  maxHeight: 9,
                  borderRadius: 15,
                  background: pieColors[item],
                }}
              />
            )}
            <p
              style={{
                marginTop: numOfLegends < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfLegends < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
      <Flex
        flexDirection={"column"}
        style={{
          marginLeft: "1rem",
          marginTop: "4rem",
          display: !showLegends && "none",
        }}
      >
        {dataForLegendsPart2.map((item, index) => (
          <Flex
            key={index}
            flexDirection={"row"}
            style={{
              maxHeight: 13,
              marginBottom: 2,
              marginTop: numOfLegends < 15 ? 4 : 2,
            }}
          >
            {item === `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}` ? (
              <div
                className={
                  item ===
                    `${commonTitles["CO|1|NODATAFOR"]} ${groupFieldName}` &&
                  "noData"
                }
                style={{
                  width: numOfLegends < 15 ? 13 : 10,
                  maxHeight: 9,
                  borderRadius: 15,
                  border: "solid rgb(161, 160, 160) 0.5px",
                }}
              />
            ) : (
              <div
                style={{
                  width: numOfLegends < 15 ? 13 : 10,
                  maxHeight: 9,
                  borderRadius: 15,
                  background: pieColors[item],
                }}
              />
            )}
            <p
              style={{
                marginTop: numOfLegends < 15 ? -3 : -2,
                marginLeft: 6,
                fontSize: numOfLegends < 15 ? 11 : 9,
                width: "max-content",
              }}
            >
              {item}
            </p>
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default Pie;
