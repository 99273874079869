import "./modulesConfig.css";
import { Layout, Card, Spin } from "antd";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ModulesConfigSettings from "./ModulesConfigSettings";
import ModulesConfigHead from "./ModulesConfigHead";
import ModulesConfigBody from "./ModulesConfigBody";
import apiGetModulesList from "../../../api/endpoints/apiGetModulesList";
import {
  useAuthStore,
  useCommonTitlesStore,
  useModTitlesStore,
} from "../../../store/useStores";
import Navigation from "../../navigation/Navigation";
import Flex from "../../../helpers/components/Flex";
import filterDataOnSearch from "../helpers/filterDataOnSearch";
import { DeleteModal } from "./modals/deleteModals";
import axios from "axios";
import useSort from "./hooks/useSort";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";
import htmlCodesRemover from "../../ultimateTable/helpers/htmlCodesRemover";
import BackgroundWrapper from "../../../helpers/components/BackgroundWrapper";
import TableWrapper from "../../../helpers/components/TableWrapper";
import AdministrationBanner from "../administrationBanner/AdministrationBanner";

const { Content } = Layout;

const ModulesConfig = () => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const projectID = useAuthStore((state) => state.projectID);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [data, setData] = useState({});
  const [bodyData, setBodyData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [staticOptions, setStaticOptions] = useState({});
  const [titles, setTitles] = useState({});
  const [fields, setFields] = useState({});
  const [modules, setModules] = useState([]);
  const [projects, setProjects] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [bannerTitle, setBannerTitle] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    const serverData = await apiGetModulesList(baseURL);
    setHeadData([
      { F_ID: "T_TITLE", TITLE: serverData.fields[1] },
      { F_ID: "TITLESINGULAR", TITLE: commonTitles["CO|1|SINGULAR"] },
      { F_ID: "M_ABBREVIATION", TITLE: serverData.fields[217] },
      { F_ID: "DESCR", TITLE: serverData.fields[240] },
      { F_ID: "M_TYPE", TITLE: serverData.fields[239] },
      { F_ID: "M_PROJECTSPECIFIC", TITLE: serverData.fields[199] },
      { F_ID: "copyfrommodule", TITLE: serverData.fields[200] },
    ]);
    setStaticOptions(serverData.staticoptions);
    setTitles(serverData.moduletitles);
    setFields(serverData.fields);

    let tempModules = serverData?.modules.map((item) => {
      item.T_TITLE = htmlCodesRemover(item.T_TITLE);
      item.TITLESINGULAR = htmlCodesRemover(item.TITLESINGULAR);
      // if (item?.T_TITLE?.includes("&#39;")) {
      //   item.T_TITLE = item.T_TITLE.split("&#39;")
      //     .filter((item) => item !== "")
      //     .join("'");
      // }
      // if (item?.TITLESINGULAR?.includes("&#39;")) {
      //   item.TITLESINGULAR = item.TITLESINGULAR.split("&#39;")
      //     .filter((item) => item !== "")
      //     .join("'");
      // }
      return item;
    });

    setModules(tempModules);
    setProjects(serverData.projects);
    setData(serverData);
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      data.modules.sort((a, b) =>
        a.T_TITLE > b.T_TITLE ? 1 : b.T_TITLE > a.T_TITLE ? -1 : 0
      );
      setBodyData(modules);
    }
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    fetchData();
  }, [projectID, language]);

  const showModal = (param, config) => {
    if (config === "edit") {
      setModalData(param);
      setModalVisible(true);
    }

    if (config === "new") {
      setNewItemModalVisible(true);
    }
  };

  const showDeleteModal = (id) => {
    setDeleteModalVisible(true);
    setItemToDelete(id);
  };
  const cancelDeleteModal = () => setDeleteModalVisible(false);

  const updateData = (config, changes, index) => {
    if (config === "edit") {
      setModalVisible(false);
      const tempData = bodyData.map((item, ind) => {
        if (ind === index) {
          return { ...item, ...changes };
        } else {
          return item;
        }
      });
      setBodyData(tempData);
      setModules(tempData);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
      setBodyData([...bodyData, changes]);
      setModules([...modules, changes]);
    }
  };

  const handleCancel = (config) => {
    if (config === "edit") {
      setModalVisible(false);
    }

    if (config === "new") {
      setNewItemModalVisible(false);
    }
  };

  const handleDelete = async (id) => {
    const url = `${baseURL}/app_modules_api/delete`;

    try {
      const response = await axios.post(
        url,
        {
          id: id,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("app_modules_api/delete: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        setBodyData(bodyData.filter((item) => item.M_ID !== id));
        setModules(bodyData.filter((item) => item.M_ID !== id));
        setDeleteModalVisible(false);
        setModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        if (typeof responseMsg === "object") {
          Object.entries(responseMsg)
            .filter(([key]) => key !== "ID")
            .forEach(([key, value]) => {
              var re = new RegExp(key, "g");
              template = template.replace(re, value);
            });

          //message.warning(template, [5]);
          displayResponseMessage(template, 5, "warning");
        } else {
          displayResponseMessage(responseMsg, 5, "warning");
        }
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const updateSearchValue = (change) => {
    const tempData = filterDataOnSearch(modules, change, headData);

    if (change) {
      setBodyData(tempData);
    } else {
      setBodyData(modules);
    }
  };

  let dataToUse = bodyData;

  const { sortedItems, sort, decideSortIcon } = useSort(bodyData, headData);

  dataToUse = sortedItems;

  return (
    <Flex flexDirection={"column"}>
      <Navigation />
      <BackgroundWrapper>
        <AdministrationBanner
          title={`/ ${modTitles.commonData} & ${modTitles.modules} `}
          updateSearchValue={updateSearchValue}
          showSearch={true}
        />
        <TableWrapper>
          {isLoading ? (
            <Card
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
                width: "98.5vw",
                display: "flex",
                justifyContent: "center",
                height: "85vh",
              }}
            >
              <Spin size="large" style={{ marginTop: "15rem" }} />
            </Card>
          ) : (
            <>
              <Layout
                style={{
                  borderRadius: 10,
                  overflow: "hidden",
                  height: "max-content",
                  width: "100%",
                }}
              >
                <ModulesConfigSettings
                  key={"new-item"}
                  headers={headData}
                  modules={dataToUse}
                  titles={titles}
                  fields={fields}
                  projects={projects}
                  staticOptions={staticOptions}
                  newItemModalVisible={newItemModalVisible}
                  showModal={showModal}
                  updateData={updateData}
                  handleDelete={handleDelete}
                  handleCancel={handleCancel}
                />
                <Layout className="site-layout">
                  <Content
                    style={{
                      paddingBottom: 15,
                      paddingLeft: 20,
                      paddingRight: 20,
                      minHeight: "max-content",
                      backgroundColor: "transparent",
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      className="testing_table"
                      style={{ maxHeight: "70vh" }}
                    >
                      <Table stickyHeader>
                        <ModulesConfigHead
                          headers={headData}
                          sort={sort}
                          decideSortIcon={decideSortIcon}
                        />
                        <ModulesConfigBody
                          key={bodyData?.length}
                          data={dataToUse}
                          headers={headData}
                          staticOptions={staticOptions}
                          titles={titles}
                          modalVisible={modalVisible}
                          modalData={modalData}
                          showModal={showModal}
                          showDeleteModal={showDeleteModal}
                          updateData={updateData}
                          handleDelete={handleDelete}
                          handleCancel={handleCancel}
                        />
                      </Table>
                    </TableContainer>
                  </Content>
                </Layout>
              </Layout>
            </>
          )}
        </TableWrapper>
      </BackgroundWrapper>
      {deleteModalVisible && (
        <DeleteModal
          visible={deleteModalVisible}
          selectedItems={[itemToDelete]}
          onDelete={handleDelete}
          onCancel={cancelDeleteModal}
        />
      )}
    </Flex>
  );
};

export default ModulesConfig;
