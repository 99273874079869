import "./projectsConfig.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import { EditProjectModal } from "./EditProjectModal";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tooltip, Button, message } from "antd";
import ProjectTableDisplayDecider from "./helpers/ProjectTableDisplayDecider";
import { useCommonTitlesStore } from "../../../store/useStores";
import { useAuthStore } from "../../../store/useStores";
import { tranformChangesforServer } from "../../module/helpers/converters";
import { convertDataFromServer } from "../../module/helpers/converters";
import axios from "axios";
import apiUpload from "../../../api/endpoints/apiUpload";
import { transformDataForServer } from "../../module/helpers/converters";
import apiDeleteFile from "../../../api/endpoints/apiDeleteFile";
import { useState } from "react";
import { ProjectModulesModal } from "./ProjectModulesModal";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const ProjectsConfigBody = ({
  data,
  headers,
  staticOptions,
  foreignKeyValues,
  showModal,
  modalVisible,
  modalData,
  updateData,
  handleCancel,
  showDeleteModal,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);
  const baseURL = useAuthStore((state) => state.baseURL);
  const userID = useAuthStore((state) => state.userID);
  const [projectModulesModalVisible, setProjectModulesModalVisible] =
    useState(false);
  const [projectID, setProjectID] = useState(null);

  const cancelProjectModulesModal = () => setProjectModulesModalVisible(false);

  const saveProjectModules = async (projectID, modules) => {
    const modulesIncluded = modules
      .filter((item) => item.ACTIVE)
      .map((module) => module.ID);

    const url = `${baseURL}/Project_modules_api/save`;
    try {
      const response = await axios.post(
        url,
        { projectid: projectID, modules: modulesIncluded },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Project_modules_api/save: response: ", response.data);
      if (response?.data?.STATUS === "Success") {
        setCoreChange(!coreChange);
        setProjectModulesModalVisible(false);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {}
  };

  let attachColumns = [];
  let automaticFieldColumns = [];

  headers.forEach((column) => {
    if (column.F_TYPE === "24" || column.F_TYPE === "25") {
      attachColumns.push(column.F_ID);
    }
    if (column.F_TYPE === "29") {
      automaticFieldColumns.push(column.F_ID);
    }
  });

  const deleteFiles = async (filesToDelete) => {
    let deleteError = false;
    let deleteFileTracker = {};
    for (let i = 0; i < attachColumns.length; i++) {
      if (!deleteError) {
        let id = attachColumns[i];
        deleteFileTracker = { ...deleteFileTracker, [id]: [] };
        for (let j = 0; j < filesToDelete[id].length; j++) {
          const deleteStatus = await apiDeleteFile(
            baseURL,
            filesToDelete[id][j].id,
            commonTitles
          );
          if (deleteStatus !== "Success") {
            break;
          }
          deleteFileTracker[id].push(filesToDelete[id][j]);
        }
      } else break;
    }

    if (deleteError) {
      return { deleteFileTracker, deleteStatus: "Error" };
    }

    return { deleteFileTracker, deleteStatus: "Success" };
  };

  const uploadFiles = async (filesToUpload) => {
    let uploadFileTracker = {};
    let uploadError = false;

    for (let i = 0; i < attachColumns?.length; i++) {
      if (!uploadError) {
        let id = attachColumns[i];
        uploadFileTracker = { ...uploadFileTracker, [id]: [] };
        for (let j = 0; j < filesToUpload[id]?.length; j++) {
          const fmData = new FormData();
          fmData.append("file", filesToUpload[id][j]);

          const { uploadStatus, uploadedFile } = await apiUpload(
            baseURL,
            fmData,
            commonTitles
          );

          if (uploadStatus !== "Success") {
            uploadError = true;
            break;
          }

          uploadFileTracker[id].push(uploadedFile);
        }
      } else break;
    }

    if (uploadError) {
      return { uploadFileTracker, uploadStatus: "Error" };
    }

    return { uploadFileTracker, uploadStatus: "Success" };
  };

  let originalData = {};
  data.forEach((item) => {
    originalData = { ...originalData, [item.ROWID]: item };
  });

  const onSave = async (changes, rowID, lastModified) => {
    const url = `${baseURL}/Data_api/save`;

    let filesToDelete = {};
    let filesToUpload = {};
    attachColumns.forEach((id) => {
      filesToUpload = { ...filesToUpload, [id]: [] };
      filesToDelete = { ...filesToDelete, [id]: [] };

      if (changes[id]) {
        let tempFilesToDelete = changes[id].filter(
          (file) => file.action === "D"
        );
        filesToDelete[id].push(...tempFilesToDelete);

        let tempFilesToUpload = changes[id].filter(
          (file) => !("action" in file)
        );
        filesToUpload[id].push(...tempFilesToUpload);
      }
    });

    const { uploadFileTracker, uploadStatus } = await uploadFiles(
      filesToUpload
    );

    const { deleteFileTracker, deleteStatus } = await deleteFiles(
      filesToDelete
    );

    //console.log("onSave: deleteStatus: ", deleteStatus);

    if (uploadStatus === "Success" && deleteStatus === "Success") {
      let fileTracker = {};
      for (let i = 0; i < attachColumns.length; i++) {
        let id = attachColumns[i];
        fileTracker = {
          ...fileTracker,
          [id]: [...uploadFileTracker[id], ...deleteFileTracker[id]],
        };
      }
      // console.log("onSuccess: fileTracker: ", fileTracker);

      const transformedChanges = tranformChangesforServer(
        changes,
        headers,
        data,
        rowID,
        foreignKeyValues,
        fileTracker,
        baseURL
      );
      //console.log("updateData: transformedChanges: ", transformedChanges);

      const dataForServer = transformDataForServer(
        rowID,
        originalData,
        headers,
        staticOptions,
        foreignKeyValues
      );
      //console.log("updateData: dataForServer: ", dataForServer);

      const tempData = await convertDataFromServer(
        headers,
        rowID,
        originalData,
        staticOptions,
        foreignKeyValues,
        transformedChanges,
        "update",
        userID
      );
      console.log("updateData: tempData: ", tempData);

      try {
        const response = await axios.post(
          url,
          {
            moduleid: "5",
            rowid: rowID,
            lastmodified: lastModified,
            ...dataForServer,
            ...transformedChanges,
          },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Data_api/save: response: ", response?.data);

        if (response?.data?.STATUS === "Success") {
          //message.success(commonTitles["CO|1|UPDMSG1"], [3]);
          displayResponseMessage(commonTitles["CO|1|UPDMSG1"], 3, "success");
          const changes = {
            [rowID]: {
              ...modalData[rowID],
              ...tempData,
              LM: response?.data?.TIMESTAMP,
              ROWID: `${response?.data?.ROWID}`,
              US: userID,
            },
          };
          updateData("edit", changes);
        } else {
          const responseMsg = response?.data?.MESSAGE ?? {};
          let template = commonTitles[responseMsg.ID];
          Object.entries(responseMsg)
            .filter(([key]) => key !== "ID")
            .forEach(([key, value]) => {
              var re = new RegExp(key, "g");
              template = template.replace(re, value);
            });
          //message.warning(template, [3]);
          displayResponseMessage(template, 5, "warning");
        }
      } catch (err) {
        //message.error(err, [3]);
        displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
      }
    } else {
      deleteFiles(uploadFileTracker);
    }
  };

  return (
    <TableBody>
      {data.map((row) => (
        <TableRow key={row.ROWID}>
          <TableCell>
            <Tooltip placement="right" title={commonTitles["CO|1|EDITPRJ"]}>
              <EditOutlined
                onClick={() => showModal(row, "edit")}
                style={{
                  fontSize: "18px",
                  color: "rgb(8, 8, 46)",
                }}
              />
            </Tooltip>
          </TableCell>
          {headers.map(({ F_ID, F_RENDER, T_TITLE, F_TYPE }) => (
            <TableCell key={F_ID}>
              {ProjectTableDisplayDecider(F_RENDER, F_TYPE, row[F_ID], T_TITLE)}
            </TableCell>
          ))}
          <TableCell width="10%">
            <Button
              shape="round"
              className="dropdown"
              onClick={() => {
                setProjectID(row.ROWID);
                setProjectModulesModalVisible(true);
              }}
              style={{ backgroundColor: "rgb(8, 8, 46)", color: "#fff" }}
            >
              {commonTitles["CO|1|MODULES"]}
            </Button>
          </TableCell>
        </TableRow>
      ))}
      {modalVisible && (
        <EditProjectModal
          visible={modalVisible}
          data={modalData}
          originalData={originalData}
          headers={headers}
          staticOptions={staticOptions}
          foreignKeyValues={foreignKeyValues}
          onSave={onSave}
          onCancel={handleCancel}
          showDeleteModal={showDeleteModal}
          config={"edit"}
        />
      )}
      {projectModulesModalVisible && (
        <ProjectModulesModal
          visible={projectModulesModalVisible}
          projectID={projectID}
          project={
            data.filter((project) => project.ROWID === projectID)[0]?.["279"] ??
            ""
          }
          title={commonTitles["CO|1|ΜODULES"]}
          onSave={saveProjectModules}
          onCancel={cancelProjectModulesModal}
        />
      )}
    </TableBody>
  );
};

export default ProjectsConfigBody;
