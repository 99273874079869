import "../../ultimateTable/css/ultimateTable.css";
import { Modal, Button } from "antd";
import { Card } from "antd";
import { useCommonTitlesStore } from "../../../store/useStores";
import { useState } from "react";
import Flex from "../../../helpers/components/Flex";

const ConfirmationDeleteModalContent = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Flex flexDirection={"column"}>
      <Card>
        <p>{commonTitles["CO|1|DELCONF2"]}?</p>
      </Card>
    </Flex>
  );
};

export const ConfirmationDeleteModal = ({
  visible,
  onDelete,
  onCancel,
  selectedItems,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Modal
      title={commonTitles["CO|1|DELCONF2"]}
      open={visible}
      onCancel={onCancel}
      width={600}
      style={{ marginTop: 40 }}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={() => onCancel("delete")}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onDelete(selectedItems[0])}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,
      ]}
    >
      <ConfirmationDeleteModalContent />
    </Modal>
  );
};

const DeleteModalContent = ({ selectedItems }) => {
  const count = selectedItems.length;
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let deleteMsg2 = commonTitles["CO|1|DELETEMSG2"];
  var re = new RegExp("<1>", "g");
  deleteMsg2 = deleteMsg2.replace(re, count);

  return (
    <Flex flexDirection={"column"}>
      <Card>
        {count === 1 ? (
          <p>{commonTitles["CO|1|DELETEMSG1"]}</p>
        ) : (
          <p>{deleteMsg2}</p>
        )}
      </Card>
    </Flex>
  );
};

export const DeleteModal = ({ visible, selectedItems, onDelete, onCancel }) => {
  const [confirmationDeleteModalVisible, setConfirmationDeleteModalVisible] =
    useState(false);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const onCancelConfirmation = () => {
    setConfirmationDeleteModalVisible(false);
    onCancel();
  };

  return (
    <Modal
      title={commonTitles["CO|1|DELETERCRD"]}
      open={visible}
      onCancel={onCancel}
      width={600}
      style={{ marginTop: -60.5 }}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => setConfirmationDeleteModalVisible(true)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,
      ]}
    >
      <DeleteModalContent selectedItems={selectedItems} />
      {confirmationDeleteModalVisible && (
        <ConfirmationDeleteModal
          visible={confirmationDeleteModalVisible}
          onDelete={onDelete}
          onCancel={onCancelConfirmation}
          selectedItems={selectedItems}
          closable
        />
      )}
    </Modal>
  );
};
