import { useAuthStore, useModTitlesStore } from "../../../store/useStores";
import Flex from "../../../helpers/components/Flex";
import BannerWrapper from "./BannerWrapper";
import BannerSearch from "../../../helpers/components/BannerSearch";

const AdministrationBanner = ({
  title,
  field,
  updateSearchValue,
  showSearch,
}) => {
  const modTitles = useModTitlesStore((state) => state.modTitles);
  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );
  const bannerPathColor = useAuthStore((state) => state.bannerPathColor);

  const h1Style = {
    color: navigationTextColor,
    fontFamily: "var(--font-family)",
    fontWeight: 300,
    fontSize: 20,
    letterSpacing: "0.04em",
    wordSpacing: "0.1em",
    marginBottom: "0.4rem",
  };

  const h3Style = {
    color: bannerPathColor,
    fontFamily: "var(--font-family)",
    fontWeight: 300,
    fontSize: 18,
    letterSpacing: "0.04em",
    wordSpacing: "0.1em",
    marginBottom: "0.4rem",
    marginLeft: "0.2rem",
  };

  return (
    <BannerWrapper>
      <Flex flexDirection={"row"} style={{ alignItems: "center" }}>
        <h1 style={h1Style}>{modTitles.administration}</h1>
        <h3 style={h3Style}>{title}</h3>
        {field && (
          <h4 style={{ color: "white", marginLeft: 5, marginTop: -3 }}>
            {field}
          </h4>
        )}
      </Flex>
      {showSearch && <BannerSearch updateSearchValue={updateSearchValue} />}
    </BannerWrapper>
  );
};

export default AdministrationBanner;
