import "../modulesConfig.css";
import { Layout, Button, message } from "antd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../../helpers/components/Flex";
import {
  useAdminStore,
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { useNavigate } from "react-router-dom";
import { NewFieldModal } from "./modals/ΝewFieldModal";
import axios from "axios";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import FieldsOrderModal from "./modals/FieldsOrderModal";
import { useEffect, useState } from "react";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";

const { Content } = Layout;

const FieldsConfigSettings = ({
  headData,
  bodyData,
  fields,
  staticOptions,
  refModules,
  newItemModalVisible,
  showModal,
  updateData,
  handleCancel,
  handleDelete,
  updateDataEditOrder,
  currentModuleID,
}) => {
  let navigate = useNavigate();
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  //const moduleID = useAdminStore((state) => state.moduleID);
  const setModuleID = useAdminStore((state) => state.setModuleID);
  const setBannerTitle = useAdminStore((state) => state.setBannerTitle);
  const [fieldsOrderModalVisible, setFieldsOrderModalVisible] = useState(false);

  const [orderItems, setOrderItems] = useState([]);

  const initializeOrderItems = () => {
    let tempData = [];
    let count = 0;

    let getEmptyElement = (index) => ({
      F_TYPE: "0",
      F_EDITORDER: null,
      F_ID: `${index}-blank`,
      T_TITLE: "",
    });

    [...bodyData]
      .sort(
        (
          { F_EDITORDER: F_EDITORDER_A = 0 },
          { F_EDITORDER: F_EDITORDER_B = 0 }
        ) => {
          if (!F_EDITORDER_A) {
            return 1;
          } else if (!F_EDITORDER_B) {
            return -1;
          } else {
            return Number(F_EDITORDER_A) - Number(F_EDITORDER_B);
          }
        }
      )
      .forEach((item, index) => {
        if (item.F_SINGLELINE === "L") {
          if ((index + count) % 2 === 0) {
            tempData.push(item);
            tempData.push(getEmptyElement(index));
          } else {
            tempData.push(getEmptyElement(index));
            tempData.push(item);
          }
          count++;
        } else if (item.F_SINGLELINE === "R") {
          if ((index + count) % 2 === 0) {
            tempData.push(getEmptyElement(index));
            tempData.push(item);
          } else {
            tempData.push(item);
            tempData.push(getEmptyElement(index));
          }
          count++;
        } else {
          tempData.push(item);
        }
      });

    setOrderItems(tempData);
  };

  const showFieldsOrderModal = () => {
    initializeOrderItems();
    setFieldsOrderModalVisible(true);
  };

  const cancelFieldsOrderModal = () => {
    setFieldsOrderModalVisible(false);
  };

  const saveFieldsOrderModal = (orderItems) => {
    let count = 0;
    let tempData = [];

    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].F_TYPE === "0") {
        count++;
      } else {
        let order = Number(orderItems[i].F_EDITORDER) - count;
        order = order.toString();
        tempData.push({ ...orderItems[i], F_EDITORDER: order });
      }
    }

    tempData = tempData.sort((a, b) => a.F_ORDER - b.F_ORDER);
    updateDataEditOrder(tempData);
    setFieldsOrderModalVisible(false);
  };

  const updateFieldOrderItems = (items) => {
    setOrderItems(items);
  };

  const routeChange = () => {
    setModuleID(null);
    setBannerTitle(null);
    let path = "/config/modules";
    navigate(path);
  };

  const onSave = async (changes) => {
    const url = `${baseURL}/App_fields_api/save`;

    try {
      const response = await axios.post(
        url,
        { F_MODULEID: currentModuleID, ...changes },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_fields_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changesWithExtraInfo = {
          F_LASTMODIFIED: response?.data?.TIMESTAMP,
          F_ID: `${response?.data?.ID}`,
          F_ORDER: `${bodyData.length + 1}`,
          F_EDITORDER: null,
          FIELDOPTIONS: response?.data?.FIELDOPTIONS,
          ...changes,
        };
        updateData("new", changesWithExtraInfo);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(commonTitles["CO|1|ERR500"], [8]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <Content
      key={`${bodyData.length}`}
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"}>
        <Content
          style={{
            padding: 5,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        >
          <div className="select">
            <Button
              shape="round"
              icon={
                <ArrowBackIcon
                  style={{ marginRight: 5 }}
                  color="rgb(8, 8, 46)"
                />
              }
              style={{ display: "flex", justifyContent: "center" }}
              className="dropdown"
              onClick={() => routeChange()}
            >
              {commonTitles["CO|1|BACKTOMOD"]}
            </Button>
          </div>
        </Content>
        <div className="add-button">
          <Button
            shape="round"
            icon={<AppRegistrationIcon color="rgb(8, 8, 46)" />}
            className="dropdown"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => showFieldsOrderModal()}
          >
            {commonTitles["CO|1|ORDERMOD"]}
          </Button>
          {fieldsOrderModalVisible && (
            <FieldsOrderModal
              visible={fieldsOrderModalVisible}
              onSave={saveFieldsOrderModal}
              onCancel={cancelFieldsOrderModal}
              updateFieldOrderItems={updateFieldOrderItems}
              items={orderItems}
            />
          )}
        </div>
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {commonTitles["CO|1|NEWFIELD"]}
          </Button>
          {newItemModalVisible && (
            <NewFieldModal
              key={"new-item"}
              data={bodyData}
              visible={newItemModalVisible}
              headers={headData}
              staticOptions={staticOptions}
              fields={fields}
              refModules={refModules}
              onSave={onSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
              currentModuleID={currentModuleID}
            />
          )}
        </div>
      </Flex>
    </Content>
  );
};

export default FieldsConfigSettings;
