const config = (set) => ({
  file: {},
  setFile: (newItem) => set(() => ({ file: newItem })),

  confirmationMessage: "",
  setConfirmationMessage: (confirmationMessage) =>
    set(() => ({ confirmationMessage: confirmationMessage })),
});

const ImportStore = {
  config,
  shouldLog: true,
};

export default ImportStore;
