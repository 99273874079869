import { Select } from "antd";
import Flex from "../../../../../../helpers/components/Flex";
import { useEffect, useState } from "react";

const { Option } = Select;

const ParentField = ({
  data,
  bodyData,
  config,
  addChange,
  fields,
  refModules = [],
}) => {
  const [refModule, setRefModule] = useState(null);
  const [defaultRefFieldsValue, setDefaultRefFieldsValue] = useState([]);
  const [defaultParentFieldValue, setDefaultParentFieldValue] = useState([]);

  useEffect(() => {
    if (config === "edit") {
      setRefModule(data.REFMODULE);
    }
    if (config === "new") {
      addChange(["F_REQUIRED", "F_UNIQUE"], ["N", "N"]);
    }
  }, []);

  useEffect(() => {
    let refFieldsValue = [];

    if (Object.keys(data).length) {
      if (data.REFFIELDS) {
        if (data.REFFIELDS.includes(",")) {
          let temp = data.REFFIELDS.split(",");
          temp.map((item) => {
            refFieldsValue.push(item);
          });
        } else {
          refFieldsValue.push(data.REFFIELDS);
        }
      }
    }

    if (config === "edit") {
      let temp = [];

      if (data.REFMODULE && data.REFMODULE !== "") {
        temp = refModules
          .filter((module) => module.M_ID === data.REFMODULE)[0]
          ?.FIELDS.filter((field) => refFieldsValue.includes(field.F_ID))
          .map((item) => item.F_ID);
      } else {
        temp = refModules
          .filter((module) => module.M_ID === refModule)[0]
          ?.FIELDS.filter((field) => refFieldsValue.includes(field.F_ID))
          .map((item) => item.F_ID);
      }
      setDefaultRefFieldsValue(temp);
    }
  }, [refModule]);

  useEffect(() => {
    if (config === "edit") {
      const t1 = bodyData.filter(
        (item) => item.F_ID === data.F_PARENTFIELDID
      )[0]?.REFMODULE;
      const t2 = bodyData.filter(
        (item) => item.F_ID === data.F_PARENTFIELDID
      )[0]?.F_ID;

      if (t1 && t2) {
        setDefaultParentFieldValue(`${t1}-${t2}`);
      }
    }
  }, []);

  return (
    <div key={`${defaultRefFieldsValue}`}>
      <Flex
        flexDirection={"row"}
        style={{ gap: "0.5rem", justifyItems: "center" }}
        key={defaultParentFieldValue}
      >
        <p
          style={{
            display: "flex",
            width: 180,
            justifyContent: "flex-end",
          }}
        >
          {fields[6].TITLE}:
        </p>
        <div className="select">
          <Select
            style={{
              width: 300,
              marginLeft: 20,
              marginBottom: 3,
            }}
            showSearch={true}
            onChange={(e) => {
              setDefaultRefFieldsValue([]);

              if (e) {
                setDefaultParentFieldValue(
                  `${e.split("-")[0]}-${e.split("-")[1]}`
                );
              }

              setRefModule(e.split("-")[0]);
              addChange(
                ["F_PARENTFIELDID", "REFMODULE"],
                [e.split("-")[1], e.split("-")[0]]
              );
            }}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            defaultValue={defaultParentFieldValue ?? ""}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {bodyData
              ?.filter((item) => ["6", "7", "28"].includes(item.F_TYPE))
              .map((module) => (
                <Option
                  key={module.F_ID}
                  className="option"
                  value={`${module.REFMODULE}-${module.F_ID}`}
                >
                  {module.T_TITLE}
                </Option>
              ))}
          </Select>
        </div>
      </Flex>
    </div>
  );
};

export default ParentField;
