import { Modal, Button } from "antd";
import { useState } from "react";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../store/useStores";
import inputDecider from "./helpers/inputDecider";

export const UsersModal = ({
  visible,
  data,
  roles,
  titles,
  staticOptions,
  onSave,
  onCancel,
  config,
  showDeleteModal,
  resetPassword,
}) => {
  const [changes, setChanges] = useState({});
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  const headers = [
    {
      serverID: "USER_LASTNAME",
      id: "lastname",
      key: "265",
      type: "input",
      title: titles["FD|265|TITLE"],
    },
    {
      serverID: "USER_FIRSTNAME",
      id: "firstname",
      key: "266",
      type: "input",
      title: titles["FD|266|TITLE"],
    },
    {
      serverID: "USER_LOGIN",
      id: "username",
      key: "267",
      type: "input",
      title: titles["FD|267|TITLE"],
    },
    {
      serverID: "USER_EMAIL",
      id: "email",
      key: "200",
      type: "email",
      title: "Email",
    },
    {
      serverID: "USER_SMSPHONE",
      id: "smsphone",
      key: "201",
      type: "phone",
      title: commonTitles["CO|1|PHONE"],
    },
    {
      serverID: "USER_TYPE",
      id: "type",
      key: "268",
      type: "singleSelection",
      title: titles["FD|268|TITLE"],
    },
    {
      serverID: "USER_ROLEID",
      id: "roleid",
      key: "270",
      type: "multipleSelection",
      title: titles["FD|270|TITLE"],
    },
    {
      serverID: "USER_DATEFORMAT",
      id: "dateformat",
      key: "25",
      type: "singleSelection",
      title: titles["FD|271|TITLE"],
    },
    {
      serverID: "USER_DATEFORMAT",
      id: "timeformat",
      key: "28",
      type: "singleSelection",
      title: titles["FD|272|TITLE"],
    },
    {
      serverID: "USER_ACTIVE",
      id: "active",
      key: "269",
      type: "checkbox",
      title: titles["FD|269|TITLE"],
    },
  ];

  return (
    <Modal
      key={`${data}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      data={data}
      roles={roles}
      titles={titles}
      style={{
        marginTop: -50,
        minWidth: "max-content",
      }}
      bodyStyle={{ paddingTop: 30, paddingLeft: 80, paddingRight: 80 }}
      title={config === "edit" ? titles["MO|6|EDITROW"] : titles["MO|6|NEWROW"]}
      onOk={() => onSave(changes)}
      onCancel={() => onCancel(config)}
      onDelete={() => showDeleteModal(data.USER_ID, data.USER_LOGIN)}
      footer={[
        <>
          {config === "edit" ? (
            <div className="align-left">
              <Button
                key="reset"
                type="primary"
                style={{
                  marginBottom: "0.8rem",
                  // backgroundColor: "#a8071a",
                  // color: "#fff",
                }}
                onClick={() => resetPassword(data.USER_LOGIN)}
              >
                Reset password (12345)
              </Button>
            </div>
          ) : null}

          <Button key="back" onClick={() => onCancel(config)}>
            {commonTitles["CO|1|CANCELBTN"]}
          </Button>
          <>
            {config === "edit" && (
              <Button
                key="delete"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={() => showDeleteModal(data.USER_ID, data.USER_LOGIN)}
              >
                {commonTitles["CO|1|DELETEBTN"]}
              </Button>
            )}{" "}
          </>
          <Button
            key="submit"
            type="primary"
            style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
            onClick={() => onSave(changes)}
          >
            {commonTitles["CO|1|SAVEBTN"]}
          </Button>
        </>,
      ]}
    >
      <Flex flexDirection={"column"}>
        {headers.map(({ serverID, id, key, type, title }) => (
          <Flex key={id} flexDirection={"column"}>
            {inputDecider(
              data?.[serverID],
              id,
              title,
              key,
              type,
              addChange,
              staticOptions,
              roles,
              config,
              changes
            )}
          </Flex>
        ))}
      </Flex>
    </Modal>
  );
};
