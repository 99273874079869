import { Modal, Button } from "antd";
import { useCommonTitlesStore } from "../../../../../../store/useStores";
import ConfirmationDeleteModalContent from "./ConfirmationDeleteModalContent";

const ConfirmationDeleteModal = ({ visible, onDelete, onCancel, moduleID }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Modal
      title={commonTitles["CO|1|DELCONF2"]}
      open={visible}
      onCancel={onCancel}
      width={500}
      style={{ marginTop: 40 }}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onDelete(moduleID)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,
      ]}
    >
      <ConfirmationDeleteModalContent />
    </Modal>
  );
};

export default ConfirmationDeleteModal;
