import { Card, Modal, Button } from "antd";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../store/useStores";

const ConfirmationDeleteModalContent = () => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Flex flexDirection={"column"}>
      <Card>
        <p>{commonTitles["CO|1|DELCONF2"]}?</p>
      </Card>
    </Flex>
  );
};

export const ConfirmationDeleteModal = ({
  visible,
  onDelete,
  onCancel,
  data,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  return (
    <Modal
      title={commonTitles["CO|1|DELCONF2"]}
      open={visible}
      onCancel={onCancel}
      width={500}
      style={{ marginTop: 40 }}
      destroyOnClose={true}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onDelete(data.UR_ID)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>,
      ]}
    >
      <ConfirmationDeleteModalContent />
    </Modal>
  );
};
