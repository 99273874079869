const createFilters = (filters, changes, columns, numberTypes) => {
  let columnData = [];
  columnData = columns?.filter((field) => field.F_LIST === "Y");

  const columnsWithDate = columnData
    .filter(({ F_TYPE }) => F_TYPE === "4")
    .map(({ F_ID }) => F_ID);

  const dependentColumns = columnData
    .filter(({ F_TYPE }) => F_TYPE === "30")
    .map(({ F_ID }) => F_ID);

  const numberColumns = columnData
    .filter(({ F_TYPE }) => numberTypes.includes(F_TYPE))
    .map(({ F_ID }) => F_ID);

  let tempfilters = filters ? { ...filters } : {};
  const INITIAL = filters ? { ...filters } : {};
  Object.entries(changes).map(([key, value]) => {
    if (columnsWithDate.includes(key)) {
      Object.entries(value).map(([suffix, suffixValue]) => {
        if (suffixValue !== "" && suffixValue?.length !== 0) {
          tempfilters = {
            ...tempfilters,
            [key]: { ...tempfilters[key], [suffix]: suffixValue },
          };
        } else {
          delete tempfilters[key][suffix];
          if (Object.keys(tempfilters[key]).length === 0) {
            delete tempfilters[key];
          }
        }
      });
    } else if (numberColumns.includes(key)) {
      Object.entries(value).map(([suffix, suffixValue]) => {
        if (suffixValue !== "" && suffixValue?.length !== 0) {
          tempfilters = {
            ...tempfilters,
            [key]: { ...tempfilters[key], [suffix]: suffixValue },
          };
        } else {
          delete tempfilters[key][suffix];
          if (Object.keys(tempfilters[key]).length === 0) {
            delete tempfilters[key];
          }
        }
      });
    } else if (dependentColumns.includes(key)) {
      if (Object.keys(value).length === 0) {
        delete tempfilters[key];
      } else {
        Object.entries(value).forEach(([key2, value2]) => {
          if (Object.prototype.toString.call(value2).indexOf("Object") > -1) {
            Object.entries(value2).forEach(([key3, value3]) => {
              if (value3 === "" || value3.length === 0 || value3 === null) {
                delete tempfilters[key][key2][key3];
                if (Object.keys(tempfilters[key][key2]).length === 0) {
                  delete tempfilters[key][key2];
                  if (Object.keys(tempfilters[key]).length === 0) {
                    delete tempfilters[key];
                  }
                }
              } else {
                tempfilters = {
                  ...tempfilters,
                  [key]: {
                    ...tempfilters[key],
                    [key2]: { ...tempfilters[key][key2], [key3]: value3 },
                  },
                };
              }
            });
          } else {
            if (value2 === "" || value2.length === 0 || value2 === null) {
              delete tempfilters[key][key2];
              if (Object.keys(tempfilters[key]).length === 0) {
                delete tempfilters[key];
              }
            } else {
              tempfilters = {
                ...tempfilters,
                [key]: {
                  ...tempfilters[key],
                  [key2]: value2,
                },
              };
            }
          }
        });
      }
    } else {
      if (value !== "" && value?.length !== 0) {
        tempfilters = { ...tempfilters, [key]: value };
      } else {
        delete tempfilters[key];
      }
    }
  });

  return tempfilters;
};

export default createFilters;
