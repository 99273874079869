import "./adminMenu.css";
import { Layout, Select, Button, message } from "antd";
import { AdminMenuModal } from "./AdminMenuModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";
import {
  useAuthStore,
  useCommonTitlesStore,
  useMenuStore,
} from "../../../store/useStores";
import axios from "axios";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const { Option } = Select;
const { Content } = Layout;

const AdminMenuSettings = ({
  selection,
  setSelection,
  types,
  titles,
  modules,
  bodyData,
  newItemModalVisible,
  showModal,
  updateData,
  handleCancel,
  handleDelete,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const coreChange = useAuthStore((state) => state.coreChange);
  const setCoreChange = useAuthStore((state) => state.setCoreChange);
  const baseURL = useAuthStore((state) => state.baseURL);
  const menu = useMenuStore((state) => state.menu);
  const setMenu = useMenuStore((state) => state.setMenu);

  const onSave = async (changes) => {
    const url = `${baseURL}/App_menu_api/save`;
    try {
      const response = await axios.post(
        url,
        { ME_ID: "", ...changes },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("App_menu_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");
        const changesWithExtraInfo = {
          ["ME_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          ["ME_ID"]: `${response?.data?.ID}`,
          ["ME_LASTUSER"]: response?.data?.LASTUSER,
          ["ME_WORKSPACEID"]: response?.data?.WORKSPACEID,
          ...changes,
        };
        updateData("new", changesWithExtraInfo);

        if (changesWithExtraInfo.ME_VISIBLE) {
          const tempMenu = menu.map((item) => {
            if (changesWithExtraInfo.ME_PARENTID === item.ME_ID) {
              return {
                ...item,
                children: [...item.children, changesWithExtraInfo],
              };
            } else {
              return item;
            }
          });
          setMenu(tempMenu);
        }

        setCoreChange(!coreChange);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? {};
        let template = commonTitles[responseMsg.ID];

        Object.entries(responseMsg)
          .filter(([key]) => key !== "ID")
          .forEach(([key, value]) => {
            var re = new RegExp(key, "g");
            template = template.replace(re, value);
          });

        //message.warning(template, [5]);
        displayResponseMessage(template, 5, "warning");
      }
    } catch (err) {
      //message.error(err, [3]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  const headers =
    [
      titles["FD|17|TITLE"],
      titles["FD|18|TITLE"],
      titles["FD|19|TITLE"],
      titles["FD|20|TITLE"],
    ] ?? [];

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"}>
        <p className="elements">{commonTitles["CO|1|ELEMENTS"]}: </p>
        <Content
          style={{
            padding: 5,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        >
          <div className="select">
            <Select
              style={{
                width: 160,
                marginLeft: 7,
                marginTop: 4,
              }}
              showSearch={true}
              onChange={(e) => setSelection(e)}
              filterOption={(iv, op) => {
                let opToUse = op.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              defaultValue={selection}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              {types
                .filter((type) => type.ME_VISIBLE === "Y")
                .map((item) => (
                  <Option
                    key={item.ME_ID}
                    className="option"
                    value={item.ME_ID}
                  >
                    {item.T_TITLE}
                  </Option>
                ))}
              {/* <Option className="option" value="params">
                Παράμετροι
              </Option>
              <Option className="option" value="entities">
                Ενότητες
              </Option> */}
            </Select>
          </div>
        </Content>
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {titles?.["MO|1|NEWROW"]}
          </Button>
          {newItemModalVisible && (
            <AdminMenuModal
              key={"new-item"}
              visible={newItemModalVisible}
              selection={selection}
              headers={headers}
              bodyData={bodyData}
              types={types}
              titles={titles}
              modules={modules}
              onSave={onSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
            />
          )}
        </div>
      </Flex>
    </Content>
  );
};

export default AdminMenuSettings;
