import { useModuleStore } from "../../../../../../store/useStores";
import ColorDate from "./ColorDate";
import ColorNumber from "./ColorNumber";

const RenderColorOptions = ({ addChange, data = [], config, type }) => {
  const dateTypes = useModuleStore((state) => state.dateTypes);
  const numberTypes = useModuleStore((state) => state.numberTypes);

  if (dateTypes.includes(type)) {
    return <ColorDate addChange={addChange} data={data} config={config} />;
  }

  if (numberTypes.includes(type)) {
    return <ColorNumber addChange={addChange} data={data} config={config} />;
  }

  return <div />;
};

export default RenderColorOptions;
