const config = (set) => ({
  alerts: {},
  setAlerts: (newAlerts) => set(() => ({ alerts: newAlerts })),

  alertTitles: {},
  setAlertTitles: (newAlertTitles) =>
    set(() => ({ alertTitles: newAlertTitles })),

  setAlertsAndTitles: (newAlerts, newAlertTitles) =>
    set(() => ({ alerts: newAlerts, alertTitles: newAlertTitles })),

  alertsChanged: false,
  setAlertsChanged: (newAlertsChanged) =>
    set(() => ({ alertsChanged: newAlertsChanged })),
});

const AlertStore = {
  config,
  shouldLog: true,
};

export default AlertStore;
