import { WarningOutlined } from "@ant-design/icons";
import Flex from "../../../../../../../helpers/components/Flex";
import { Select } from "antd";

const { Option } = Select;

const StaticListType = ({
  id,
  required,
  addChange,
  staticOptions,
  autoCompletedFields,
}) => {
  let value = [];
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    value = autoCompletedFields?.[id];
  }

  let options = [];

  if (staticOptions?.[id]) {
    options = Object.entries(staticOptions[id]).map(([key, value]) => ({
      key: key,
      title: value?.TITLE,
      order: value?.ORDER ? parseInt(value?.ORDER) : null,
    }));
  }

  options.sort((a, b) => a.order - b.order);

  return (
    <Flex flexDirection={"row"}>
      <div className="select">
        <Select
          style={{
            width: 278,
            marginLeft: 7,
            marginTop: 4,
          }}
          showSearch={true}
          onChange={(e) => addChange(id, e, null)}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
          defaultValue={value ? value : ""}
          disabled={value?.length ? true : false}
        >
          <Option value="" />
          {options.map((item) => (
            <Option key={item?.key} className="option" value={item?.key}>
              {item?.title}
            </Option>
          ))}
        </Select>
      </div>
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 8, marginLeft: 15 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default StaticListType;
