import "./users.css";
import { Layout, Button, message } from "antd";
import { UsersModal } from "./UsersModal";
import { PlusOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";
import axios from "axios";
import { useAuthStore, useCommonTitlesStore } from "../../../store/useStores";
import displayResponseMessage from "../../../helpers/functions/displayResponseMessage";

const { Content } = Layout;

const UsersSettings = ({
  roles,
  titles,
  staticOptions,
  newItemModalVisible,
  showModal,
  updateData,
  handleCancel,
  handleDelete,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const onSave = async (changes) => {
    const url = `${baseURL}/Users_api/save`;

    console.log("UsersSettings: changes: ", changes);

    try {
      const response = await axios.post(
        url,
        {
          id: "",
          ...changes,
          lastmodified: "",
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Users_api/save: response: ", response?.data);

      if (response?.data?.STATUS === "Success") {
        //message.success(commonTitles["CO|1|REGMSG1"], [3]);
        displayResponseMessage(commonTitles["CO|1|REGMSG1"], 3, "success");

        let userRolesTitle = "";
        if (changes.roleid) {
          changes.roleid.split(",").map((item) => {
            if (userRolesTitle !== "") {
              userRolesTitle += ", ";
            }
            userRolesTitle += roles[item];
          });
        }

        const changesWithExtraInfo = {
          ["USER_LASTMODIFIED"]: response?.data?.TIMESTAMP,
          ["USER_ID"]: response?.data?.ID,
          USER_LASTNAME: changes?.lastname ?? "",
          USER_FIRSTNAME: changes?.firstname ?? "",
          USER_LOGIN: changes?.username ?? "",
          USER_EMAIL: changes?.email ?? "",
          USER_SMSPHONE: changes?.smsphone ?? "",
          USER_TYPE: changes?.type ?? "A",
          USER_ROLEID: changes?.roleid ?? "",
          ["USER_ROLEID-title"]: userRolesTitle ?? "",
          USER_ACTIVE: changes?.active ?? "Y",
          USER_DATEFORMAT:
            `${changes?.dateformat ?? ""},${changes?.timeformat ?? ""}` ?? "",
        };
        //console.log("changesWithExtraInfo: ", changesWithExtraInfo);
        updateData("new", changesWithExtraInfo);
      } else {
        const responseMsg = response?.data?.MESSAGE ?? "";
        //message.warning(responseMsg, [5]);
        displayResponseMessage(responseMsg, 5, "warning");
      }
    } catch (err) {
      //message.error(commonTitles["CO|1|ERR500"], [8]);
      displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
    }
  };

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"}>
        <Content
          style={{
            padding: 5,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        />
        <div className="add-button">
          <Button
            shape="round"
            icon={<PlusOutlined color="rgb(8, 8, 46)" />}
            className="dropdown"
            onClick={() => showModal(null, "new")}
          >
            {titles["MO|6|NEWROW"]}
          </Button>
          {newItemModalVisible && (
            <UsersModal
              key={"new-item"}
              visible={newItemModalVisible}
              titles={titles}
              roles={roles}
              staticOptions={staticOptions}
              onSave={onSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
              config={"new"}
            />
          )}
        </div>
      </Flex>
    </Content>
  );
};

export default UsersSettings;
