import { useAuthStore, useMenuStore } from "../../../../../store/useStores";
import { useState } from "react";
import { useEffect } from "react";
import apiGetModuleFields from "../../../../../api/endpoints/apiGetModuleFields";
import htmlCodesRemover from "../../htmlCodesRemover";

const ButtonType = ({ moduleID = "1299" }) => {
  const [module, setModule] = useState("");
  const baseURL = useAuthStore((state) => state.baseURL);
  const language = useAuthStore((state) => state.language);
  const menu = useMenuStore((state) => state.menu);

  const fetchData = async () => {
    const id = menu?.[0]?.children?.[0]?.ME_MODULEID ?? "";

    const serverData = await apiGetModuleFields(baseURL, id);

    const moduleTitle =
      serverData.refmodules.filter((item) => item.M_ID === moduleID)?.[0]
        ?.T_TITLE ?? "";
    setModule(htmlCodesRemover(moduleTitle));
  };

  useEffect(() => {
    fetchData();
  }, [language]);

  return (
    <p key={module} style={{ width: 300, marginLeft: 5, marginTop: 12 }}>
      Θα αντληθεί πληροφορία αυτόματα από την ενότητα <b>{module}</b>
    </p>
  );
};

export default ButtonType;
