import Flex from "../../../../helpers/components/Flex";
import StaticList from "./step2Contents/staticList/StaticList";
import OtherModuleSingle from "./step2Contents/otherModuleSingle/OtherModuleSingle";
import AutoID from "./step2Contents/autoID/AutoID";
import Decimal from "./step2Contents/decimal/Decimal";
import Dependent from "./step2Contents/dependent/Dependent";
import RenderColorOptions from "./step2Contents/renderColorOptions/RenderColorOptions";
import NewFieldBasicInfo from "./step2Contents/newField/NewFieldBasicInfo";
import EditBasicInfo from "./step2Contents/editBasicInfo/EditBasicInfo";
import Size from "./step2Contents/size/Size";
import Required from "./step2Contents/required/Required";
import Unique from "./step2Contents/unique/Unique";
import ShowInList from "./step2Contents/showInList/ShowInList";
import Widths from "./step2Contents/widths/Widths";
import Align from "./step2Contents/align/Align";
import Frame from "./step2Contents/frame/Frame";
import Totals from "./step2Contents/totals/Totals";
import RenderTypes from "./step2Contents/renderTypes/RenderTypes";
import ParentField from "./step2Contents/parentField/ParentField";
import ButtonType from "./step2Contents/buttonType/ButtonType";
import FormulaType from "./step2Contents/formulaType/FormulaType";
import { useModuleStore } from "../../../../store/useStores";

const StepContent = ({
  current,
  type,
  changeType,
  fields,
  staticOptions,
  refModules,
  changes,
  addChange,
  config,
  data,
  bodyData,
  currentModuleID,
}) => {
  const textTypes = useModuleStore((state) => state.textTypes);
  const dateTypes = useModuleStore((state) => state.dateTypes);
  const numberTypes = useModuleStore((state) => state.numberTypes);
  const fromOtherModuleTypes = useModuleStore(
    (state) => state.fromOtherModuleTypes
  );

  const renderTypes = [
    ...dateTypes,
    ...numberTypes,
    "6",
    ...fromOtherModuleTypes,
  ];
  const sizeTypes = ["2", ...textTypes];
  const notRequiredTypes = ["26", "29", "30"];
  const parentFieldTypes = ["6", ...fromOtherModuleTypes];

  if (current === 0) {
    return (
      <NewFieldBasicInfo
        changes={changes}
        addChange={addChange}
        fields={fields}
        staticOptions={staticOptions}
        changeType={changeType}
      />
    );
  }

  if (current > 0) {
    return (
      <Flex
        flexDirection={"column"}
        style={{ gap: "0.15rem", marginTop: "2rem" }}
      >
        {config === "edit" && (
          <EditBasicInfo
            data={data}
            changes={changes}
            addChange={addChange}
            fields={fields}
            staticOptions={staticOptions}
            changeType={changeType}
          />
        )}
        {type === "29" && (
          <AutoID
            fields={fields}
            data={data}
            config={config}
            addChange={addChange}
          />
        )}
        {type === "6" && (
          <StaticList
            data={data ?? []}
            config={config}
            addChange={addChange}
            fields={fields}
          />
        )}
        {parentFieldTypes.includes(type) && (
          <ParentField
            data={data}
            bodyData={bodyData}
            refModules={refModules}
            config={config}
            fields={fields}
            addChange={addChange}
          />
        )}
        {type === "30" && (
          <Dependent
            data={data}
            bodyData={bodyData}
            refModules={refModules}
            config={config}
            fields={fields}
            addChange={addChange}
          />
        )}
        {fromOtherModuleTypes.includes(type) && (
          <OtherModuleSingle
            fields={fields}
            data={data}
            config={config}
            staticOptions={staticOptions}
            refModules={refModules}
            addChange={addChange}
            type={type}
          />
        )}
        {type === "26" && (
          <ButtonType
            fields={fields}
            data={data}
            config={config}
            staticOptions={staticOptions}
            refModules={refModules}
            addChange={addChange}
            type={type}
            bodyData={bodyData}
          />
        )}
        {(type === "3" || type === "116") && (
          <Decimal
            fields={fields}
            data={data}
            config={config}
            addChange={addChange}
            type={type}
          />
        )}
        {sizeTypes.includes(type) && (
          <Size
            fields={fields}
            data={data}
            config={config}
            addChange={addChange}
          />
        )}
        {!notRequiredTypes.includes(type) && (
          <Required
            fields={fields}
            data={data}
            config={config}
            addChange={addChange}
            staticOptions={staticOptions}
          />
        )}
        {!notRequiredTypes.includes(type) && (
          <Unique
            fields={fields}
            data={data}
            config={config}
            addChange={addChange}
            staticOptions={staticOptions}
          />
        )}
        <ShowInList
          fields={fields}
          data={data}
          config={config}
          addChange={addChange}
          staticOptions={staticOptions}
        />
        <Widths
          fields={fields}
          data={data}
          config={config}
          addChange={addChange}
        />
        <Align
          fields={fields}
          data={data}
          config={config}
          addChange={addChange}
          staticOptions={staticOptions}
        />
        <Frame
          fields={fields}
          data={data}
          config={config}
          addChange={addChange}
          staticOptions={staticOptions}
        />
        {type === "116" && (
          <FormulaType
            data={data}
            changes={changes}
            addChange={addChange}
            config={config}
            fields={fields}
            bodyData={bodyData}
            refModules={refModules}
            currentModuleID={currentModuleID}
          />
        )}
        {numberTypes.includes(type) && (
          <Totals
            fields={fields}
            data={data}
            config={config}
            addChange={addChange}
            staticOptions={staticOptions}
          />
        )}
        {renderTypes.includes(type) && (
          <RenderTypes
            fields={fields}
            data={data}
            config={config}
            addChange={addChange}
            staticOptions={staticOptions}
            type={type}
          />
        )}
        {(changes.F_RENDER === "A" ||
          (!Object.keys(changes).includes("F_RENDER") &&
            data.F_RENDER === "A")) && (
          <RenderColorOptions
            data={data}
            config={config}
            addChange={addChange}
            changes={changes}
            refModules={refModules}
            fields={fields}
            type={type}
          />
        )}
      </Flex>
    );
  }
};

export default StepContent;
