import { useNavigate } from "react-router-dom";
import { Button, Card, Image } from "antd";
import image403 from "../../assets/error403.jpg";
import Flex from "../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../store/useStores";
import BackgroundWrapper from "../../helpers/components/BackgroundWrapper";

const AccessNotAllowed = () => {
  const navigate = useNavigate();
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <BackgroundWrapper>
      <Card
        style={{
          display: "flex",
          width: "98vw",
          height: "max-content",
          backgroundColor: "white",
          marginLeft: 15,
          marginTop: 15,
          borderRadius: 15,
        }}
      >
        <Flex flexDirection={"row"}>
          <Image
            src={image403}
            alt="logo"
            width={350}
            height={250}
            preview={false}
          />
          <Flex flexDirection={"column"}>
            <p
              style={{
                marginTop: 60,
                marginLeft: 50,
                fontSize: 20,
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              {commonTitles["C0|1|ERROR403"]}
            </p>
            <p
              style={{
                marginLeft: 50,
                fontSize: 16,
                fontWeight: 200,
              }}
            >
              {commonTitles["C0|1|NOACCESS"]}
            </p>
            <Button
              style={{
                marginLeft: 50,
                marginTop: 5,
                backgroundColor: "rgb(8, 8, 46)",
                color: "#fff",
                borderRadius: 5,
                width: "max-content",
              }}
              onClick={() => navigate("/")}
            >
              {commonTitles["CO|1|BACKHOME"]}
            </Button>
          </Flex>
        </Flex>
      </Card>
    </BackgroundWrapper>
  );
};

export default AccessNotAllowed;
