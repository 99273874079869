import "./users.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";

const UsersHead = ({ headData, sort, decideSortIcon }) => {
  const centerAlignedHeaders = ["USER_ACTIVE"];
  return (
    <TableHead>
      <TableRow key={"header-row"}>
        <TableCell key={"ghost-cell"} />
        {headData?.map((item) => (
          <TableCell
            key={item?.id}
            align={centerAlignedHeaders?.includes(item?.id) ? "center" : "left"}
            onClick={() => sort(item.id)}
            className="admin-header"
          >
            <p key={`${item?.id} - text`}>
              {decideSortIcon(item.id, item.title)}
            </p>
          </TableCell>
        ))}
        <TableCell key={"button"} width="8%" />
      </TableRow>
    </TableHead>
  );
};

export default UsersHead;
