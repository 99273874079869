import Flex from "../../../../../helpers/components/Flex";
import CustomCheckbox from "../../../../../helpers/components/CustomCheckbox";

const CheckBoxType = ({ value = "Y", id, title, config, addChange }) => {
  return (
    <Flex
      key={id}
      flexDirection={"row"}
      style={{ justifyContent: "flex-start" }}
    >
      <div className="modal-text" style={{ width: "42%" }}>
        <p>{title}:</p>
      </div>

      <div
        style={{
          marginLeft: 15,
          marginTop: -14,
          marginBottom: 12,
          width: 340,
        }}
      >
        <CustomCheckbox
          id={id}
          value={value}
          addChange={addChange}
          config={config}
          isDisabled={false}
        />
      </div>
    </Flex>
  );
};

export default CheckBoxType;
