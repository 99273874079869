const config = (set) => ({
  moduleID: null,
  setModuleID: (newItem) => set(() => ({ moduleID: newItem })),

  bannerTitle: null,
  setBannerTitle: (newItem) => set(() => ({ bannerTitle: newItem })),
});

const AdminStore = {
  config,
  shouldLog: true,
};

export default AdminStore;
