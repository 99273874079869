import "./import.css";
import { Modal, Button } from "antd";
import { Card, Layout } from "antd";
import {
  useAuthStore,
  useCommonTitlesStore,
} from "../../../../store/useStores";
import { FolderOpenFilled, InfoCircleFilled } from "@ant-design/icons";
import { message, Upload } from "antd";
import Flex from "../../../../helpers/components/Flex";
import FileUpload from "../newRegister/fileUpload/FileUpload";
import { useState } from "react";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";

const { Dragger } = Upload;
const props = {
  name: "file",
  multiple: false,
  accept: ".csv",

  onChange(info, commonTitles) {
    const { status } = info.file;

    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }

    if (status === "done") {
      //message.success(`${commonTitles["CO|1|UPLDFILE"]}: ${info.file.name}`);
      displayResponseMessage(
        `${commonTitles["CO|1|UPLDFILE"]}: ${info.file.name}`,
        3,
        "success"
      );
    } else if (status === "error") {
      //message.error(`${info.file.name}: ${commonTitles["CO|1|UPLDMSG1"]}`);
      displayResponseMessage(
        `${info.file.name}: ${commonTitles["CO|1|UPLDMSG1"]}`,
        5,
        "error"
      );
    }
  },

  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const ImportModalContent = ({ changes, addChange }) => {
  return (
    <Flex flexDirection={"column"}>
      <Card style={{ paddingBottom: "3rem" }}>
        {/* <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <FolderOpenFilled />
          </p>
          <p className="ant-upload-text">{commonTitles["CO|1|IMPORTMSG1"]}</p>
          <p className="ant-upload-hint">{commonTitles["CO|1|IMPORTMSG2"]}</p>
        </Dragger> */}
        <FileUpload
          files={[]}
          id={"import"}
          canupdate={true}
          canupdateifnull={true}
          addChange={addChange}
          accept={".csv, .xlsx, .xls"}
          multiple={false}
        />
      </Card>
    </Flex>
  );
};

export const ImportModal = ({ visible, onSave, onCancel }) => {
  const [changes, setChanges] = useState({});
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const addChange = (id, value, config) => {
    if (value && value !== "" && value?.length !== 0) {
      if (changes[id]) {
        if (config === "add") {
          let fileExists = [];
          for (let i = 0; i < value.length; i++) {
            changes[id].forEach((file) => {
              if (file.uid === value[i].uid) {
                fileExists[i] = true;
              }
            });
          }

          for (let i = 0; i < value.length; i++) {
            !fileExists[i] &&
              setChanges({
                ...changes,
                [id]: [...changes[id], value[i]],
              });
          }
        } else {
          let tempChanges = [];
          changes[id].forEach((file) => tempChanges.push(file));
          tempChanges = tempChanges.filter((file) => file.uid !== value.uid);
          if (tempChanges.length) {
            setChanges({ ...changes, [id]: [...tempChanges] });
          } else {
            delete changes[id];
            setChanges({ ...changes });
          }
        }
      } else {
        setChanges({
          ...changes,
          [id]: value,
        });
      }
    } else {
      delete changes[id];
      setChanges({ ...changes });
    }
  };

  return (
    <Modal
      title={commonTitles["CO|1|IMPORTFILE"]}
      open={visible}
      onOk={() => onSave(changes["import"])}
      onCancel={() => onCancel("import")}
      destroyOnClose={true}
      width="max-content"
      style={{ marginTop: 40, minWidth: 650 }}
      footer={[
        <div className="align-left" key={"modal-info"}>
          <p
            style={{
              marginTop: -2,
              color: "grey",
              fontSize: 16,
              marginRight: "0.3rem",
              marginLeft: "0.8rem",
            }}
          >
            <InfoCircleFilled />
          </p>
          <p>- {commonTitles["CO|1|ACCFILETYP"]}: .csv, .xlsx, .xls</p>
        </div>,
        <Button key="back" onClick={() => onCancel("import")}>
          {commonTitles.cancel}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{
            backgroundColor: "rgb(8, 8, 46)",
            color: "white",
            marginTop: "1rem",
          }}
          onClick={() => onSave(changes["import"])}
        >
          {commonTitles["CO|1|UPLOAD"]}
        </Button>,
      ]}
    >
      <ImportModalContent changes={changes} addChange={addChange} />
    </Modal>
  );
};
