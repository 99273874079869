import htmlCodesRemover from "../../../ultimateTable/helpers/htmlCodesRemover";
import { Input, Select } from "antd";
import TextInput from "../fieldInputs/TextInput";
import SelectInput from "../fieldInputs/SelectInput";
import CustomCheckbox from "../../../../helpers/components/CustomCheckbox";

const { Option } = Select;

const InputDecider = ({
  id,
  data,
  addChange,
  moduleID,
  updateModuleID,
  modules,
  triggerField,
  triggerFieldOptions,
  titles,
  notifyFieldOptions,
  templates,
  infoFields,
  notificationText,
  updateNotificationText,
  config,
  fields,
  notificationType,
  updateNotificationType,
  periodType,
  updatePeriodType,
}) => {
  const textInputFields = [
    "N_WARNING_BEFORE",
    "N_RAISED_AFTER",
    "N_PERIOD_NUM",
    "N_NOTIFY_EMAILS",
    "N_CONTACT",
  ];

  const arrayToString = (array) => {
    let str = "";
    array.forEach((item) => {
      if (str !== "") {
        str += ",";
      }
      str += item;
    });

    return str;
  };

  if (id === "N_MODULEID") {
    return (
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          showSearch={true}
          onChange={(e) => {
            addChange("N_MODULEID", e);
            updateModuleID(e);
          }}
          defaultValue={
            config === "edit"
              ? htmlCodesRemover(
                  Object.entries(modules).filter(
                    ([key, value]) => key === data?.N_MODULEID
                  )?.[0]?.[1] ?? ""
                )
              : null
          }
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          {Object.entries(modules).map(([key, value]) => (
            <Option key={key} className="option" value={key}>
              {htmlCodesRemover(value)}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (id === "N_TRIGGER_FIELDID") {
    if (["S", "L", "R"].includes(notificationType)) {
      return (
        <div className="select" key={triggerField}>
          <Select
            style={{
              width: 300,
              marginLeft: 15,
              marginBottom: "1rem",
            }}
            onChange={(e) => {
              addChange("N_TRIGGER_FIELDID", e);
              const title =
                htmlCodesRemover(
                  fields[moduleID]?.filter((item) => item.F_ID === e)[0]
                    ?.T_TITLE
                ) ?? "";
              updateNotificationText(title);
            }}
            showSearch={true}
            defaultValue={htmlCodesRemover(triggerField)}
            filterOption={(iv, op) => {
              let opToUse = op?.children ? op.children : "";
              return opToUse.toLowerCase().includes(iv.toLowerCase());
            }}
            dropdownStyle={{ overflowY: "auto" }}
            virtual={false}
          >
            <Option className="option" value={""} />
            {triggerFieldOptions?.map((item) => (
              <Option key={item.F_ID} className="option" value={item.F_ID}>
                {htmlCodesRemover(item?.T_TITLE)}
              </Option>
            ))}
          </Select>
        </div>
      );
    } else return <div />;
  }

  if (textInputFields.includes(id)) {
    return (
      <TextInput id={id} data={data} addChange={addChange} config={config} />
    );
  }

  if (id === "NOTIFICATIONTEXT") {
    return (
      <div className="select" key={`${notificationText}-${moduleID}`}>
        <Input
          style={{
            width: 300,
            marginLeft: 15,
            marginTop: 0,
            marginBottom: "1rem",
          }}
          onChange={(e) => addChange("NOTIFICATIONTEXT", e.target.value)}
          defaultValue={htmlCodesRemover(notificationText)}
        />
      </div>
    );
  }

  if (id === "N_TYPE") {
    const typeOptions = [
      { ID: "E", T_TITLE: titles["FO|166|TITLE"] },
      { ID: "S", T_TITLE: titles["FO|167|TITLE"] },
      { ID: "L", T_TITLE: titles["FO|168|TITLE"] },
      { ID: "R", T_TITLE: titles["FO|181|TITLE"] },
    ];

    return (
      <div className="select" key={notificationType}>
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          onChange={(e) => {
            addChange(id, e);
            updateNotificationType(e);
          }}
          showSearch={true}
          defaultValue={notificationType}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          {typeOptions.map((option) => (
            <Option key={option.ID} className="option" value={option.ID}>
              {option.T_TITLE}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (id === "N_PERIOD_TYPE") {
    const options = [
      { ID: "M", T_TITLE: titles["FO|124|TITLE"] },
      { ID: "W", T_TITLE: titles["FO|125|TITLE"] },
      { ID: "D", T_TITLE: titles["FO|126|TITLE"] },
    ];

    return (
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          onChange={(e) => {
            addChange(id, e);
            updatePeriodType(e);
          }}
          showSearch={true}
          defaultValue={config === "edit" ? periodType : null}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          {options.map((option) => (
            <Option key={option.ID} className="option" value={option.ID}>
              {option.T_TITLE}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (id === "N_WEEK_DAY") {
    return (
      <SelectInput
        id={id}
        mode={false}
        defaultValue={config === "edit" ? data?.N_WEEK_DAY : null}
        addChange={addChange}
        options={[
          { ID: "SUN", T_TITLE: titles["FO|171|TITLE"] },
          { ID: "MON", T_TITLE: titles["FO|172|TITLE"] },
          { ID: "TUE", T_TITLE: titles["FO|173|TITLE"] },
          { ID: "WED", T_TITLE: titles["FO|174|TITLE"] },
          { ID: "THU", T_TITLE: titles["FO|175|TITLE"] },
          { ID: "FRI", T_TITLE: titles["FO|176|TITLE"] },
          { ID: "SAT", T_TITLE: titles["FO|177|TITLE"] },
        ]}
      />
    );
  }

  if (id === "N_MONTH_DAY") {
    return (
      <SelectInput
        id={id}
        mode={false}
        defaultValue={config === "edit" ? data?.N_MONTH_DAY : null}
        addChange={addChange}
        options={[
          { ID: "FD", T_TITLE: titles["FO|178|TITLE"] },
          { ID: "LW", T_TITLE: titles["FO|179|TITLE"] },
          { ID: "LD", T_TITLE: titles["FO|180|TITLE"] },
        ]}
      />
    );
  }

  if (id === "N_EVENT") {
    if (notificationType === "E") {
      return (
        <SelectInput
          key={`${notificationType}-event`}
          id={id}
          multiple={true}
          defaultValue={config === "edit" ? data[id] : []}
          addChange={addChange}
          options={[
            { ID: "I", T_TITLE: titles["FO|127|TITLE"] },
            { ID: "U", T_TITLE: titles["FO|128|TITLE"] },
            { ID: "D", T_TITLE: titles["FO|129|TITLE"] },
          ]}
        />
      );
    } else return <div />;
  }

  if (id === "N_NOTIFY_FIELD") {
    return (
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          mode="multiple"
          onChange={(e) => {
            addChange(id, e);
          }}
          showSearch={true}
          defaultValue={config === "edit" ? data?.[id] ?? [] : []}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          {notifyFieldOptions.map((option) => (
            <Option key={option.ID} className="option" value={option.ID}>
              {option.T_TITLE}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (id === "N_TEMPLATEID") {
    return (
      <div className="select" key={moduleID}>
        <Select
          key={moduleID}
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          onChange={(e) => {
            addChange("N_TEMPLATEID", e);
          }}
          showSearch={true}
          defaultValue={config === "edit" ? data[id] : []}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          {Object.entries(templates).map(([key, value]) => (
            <Option key={key} className="option" value={key}>
              {value}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (id === "N_EXPIREINDICATION") {
    return (
      <SelectInput
        id={id}
        mode={false}
        defaultValue={
          config === "edit"
            ? data.N_EXPIREINDICATION === "Y"
              ? titles["FO|162|TITLE"]
              : titles["FO|163|TITLE"]
            : titles["FO|162|TITLE"]
        }
        addChange={addChange}
        options={[
          { ID: "Y", T_TITLE: titles["FO|162|TITLE"] },
          { ID: "N", T_TITLE: titles["FO|163|TITLE"] },
        ]}
      />
    );
  }

  if (id === "INFORMATIONFIELDS") {
    return (
      <div className="select" key={infoFields.length}>
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          showSearch={true}
          mode="multiple"
          onChange={(e) => addChange("INFORMATIONFIELDS", arrayToString(e))}
          defaultValue={infoFields}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          {fields[moduleID]?.map((item) => (
            <Option key={item.F_ID} className="option" value={item.F_ID}>
              {htmlCodesRemover(item?.T_TITLE)}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (id === "N_ACTIVE") {
    return (
      <div
        style={{
          marginLeft: 15,
          marginTop: -14,
          marginBottom: 12,
          width: 340,
        }}
      >
        <CustomCheckbox
          id={"N_ACTIVE"}
          value={config === "edit" ? data.N_ACTIVE : "Y"}
          addChange={addChange}
          config={config}
          isDisabled={false}
        />
      </div>

      // <SelectInput
      //   id={id}
      //   mode={false}
      //   defaultValue={
      //     config === "edit"
      //       ? data.N_ACTIVE === "Y"
      //         ? titles["FO|162|TITLE"]
      //         : titles["FO|163|TITLE"]
      //       : titles["FO|162|TITLE"]
      //   }
      //   addChange={addChange}
      //   options={[
      //     { ID: "Y", T_TITLE: titles["FO|162|TITLE"] },
      //     { ID: "N", T_TITLE: titles["FO|163|TITLE"] },
      //   ]}
      // />
    );
  }
};

export default InputDecider;
