import Flex from "../../../../../helpers/components/Flex";
import { Input } from "antd";
import { useCommonTitlesStore } from "../../../../../store/useStores";
import { WarningOutlined } from "@ant-design/icons";
import { useState } from "react";
import validateValue from "./validateValue";

const NumberType = ({
  value,
  id,
  config,
  addChange,
  dependentField,
  isDisabled,
  required,
  size,
  type,
  render,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [status, setStatus] = useState(null);

  let maxLengthInt = "1000";
  let maxLengthDec = "1000";

  if (size) {
    if (type === "2") {
      if (size.includes("|")) {
        maxLengthInt = size.split("|")?.[0] ?? "1000";
      } else {
        maxLengthInt = size;
      }
    }

    if (type === "3") {
      if (size.includes("|")) {
        maxLengthInt = size.split(",")?.[0] ?? "1000";
        maxLengthDec = size.split(",")?.[1].split("|")?.[0] ?? "1000";
      } else {
        maxLengthInt = size.split(",")?.[0] ?? "1000";
        maxLengthDec = size.split(",")?.[1] ?? "1000";
      }
    }
  }

  const checkValueValiditiy = (id, value, suffix) => {
    const isValid = validateValue(value, type);

    if (isValid) {
      setStatus(null);
      if (type === "2") {
        addChange(id, value, suffix);
      }

      if (type === "3") {
        if (value.includes(".")) {
          const int = value.split(".")?.[0];
          const decimal = value.split(".")?.[1];
          if (int.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else if (decimal.length > parseInt(maxLengthDec)) {
            setStatus("error");
          } else {
            addChange(id, value, suffix);
            setStatus(null);
          }
        }

        if (value.includes(",")) {
          const int = value.split(",")?.[0];
          const decimal = value.split(",")?.[1];
          if (int.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else if (decimal.length > parseInt(maxLengthDec)) {
            setStatus("error");
          } else {
            addChange(id, value, suffix);
            setStatus(null);
          }
        }

        if (!value.includes(".") && !value.includes(",")) {
          if (value.length > parseInt(maxLengthInt)) {
            setStatus("error");
          } else {
            addChange(id, value, suffix);
            setStatus(null);
          }
        }
      }
    } else {
      setStatus("error");
    }
  };

  if (config === "filters") {
    return (
      <Flex flexDirection={"column"}>
        <Flex flexDirection={"row"}>
          <div
            className="datepicker-text"
            style={{ width: 27, display: "flex", justifyContent: "right" }}
          >
            <p>{commonTitles["CO|1|FROM"]}:</p>
          </div>
          <Input
            type="text"
            style={{
              border: "1.25px solid",
              borderColor: !status && "rgb(182, 180, 180)",
              borderRadius: 5,
              fontFamily: "Arial, Helvetica, sans-serif",
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
              fontSize: 14,
              letterSpacing: "0.03em",
              height: 30,
              width: 240,
            }}
            status={status}
            maxLength={type === "2" ? maxLengthInt : null}
            defaultValue={
              dependentField ? value?.[dependentField]?.from : value?.from ?? ""
            }
            onChange={(e) => {
              if (dependentField) {
                addChange(
                  id,
                  { [dependentField]: e.target.value.replace(/,/g, ".") },
                  "from"
                );
              } else {
                checkValueValiditiy(
                  id,
                  e.target.value.replace(/,/g, "."),
                  "from"
                );
              }
            }}
          />
        </Flex>
        <Flex flexDirection={"row"}>
          <div
            className="datepicker-text"
            style={{ width: 27, display: "flex", justifyContent: "right" }}
          >
            <p>{commonTitles["CO|1|TO"]}:</p>
          </div>
          <Input
            type="text"
            status={status}
            style={{
              border: "1.25px solid",
              borderColor: !status && "rgb(182, 180, 180)",
              borderRadius: 5,
              fontFamily: "Arial, Helvetica, sans-serif",
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
              fontSize: 14,
              letterSpacing: "0.03em",
              height: 30,
              width: 240,
            }}
            maxLength={type === "2" ? maxLengthInt : null}
            defaultValue={
              dependentField ? value?.[dependentField]?.to : value?.to ?? ""
            }
            onChange={(e) => {
              if (dependentField) {
                addChange(id, { [dependentField]: e.target.value }, "to");
              } else {
                checkValueValiditiy(id, e.target.value, "to");
              }
            }}
          />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"row"}>
      <Input
        type="text"
        defaultValue={render === "ALERT" ? value?.value ?? "" : value}
        onChange={(e) => checkValueValiditiy(id, e.target.value, null)}
        disabled={isDisabled}
        status={status}
        maxLength={type === "2" ? maxLengthInt : null}
      />
      {required === "Y" && (config === "copy" || config === "update") && (
        <p style={{ color: "grey", marginTop: 8 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default NumberType;
