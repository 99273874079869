import Flex from "../../../../helpers/components/Flex";
import EditAction from "./EditAction";
import DeleteAction from "./DeleteAction";
import CopyAction from "./CopyAction";
import EditAlertAction from "./EditAlertAction";

const Actions = ({
  row,
  rowID,
  columns,
  privileges,
  canUpdate,
  canCopy,
  canDelete,
  moduleID,
  config,
  title,
  foreignKeyValues,
  staticOptions,
  deleteRow,
  updateRow,
  insertData,
  originalData,
  dataToUse,
  autoCompletedFields,
  updateTotals,
}) => {
  return (
    <div className="infocard-actions">
      <Flex flexDirection={"column"}>
        <Flex flexDirection={"row"}>
          {config === "alerts" ? (
            <EditAlertAction
              row={row}
              privileges={privileges}
              canUpdate={canUpdate}
              updateRow={updateRow}
            />
          ) : (
            <EditAction
              row={row}
              rowID={rowID}
              moduleID={moduleID}
              columns={columns}
              title={title}
              privileges={privileges}
              canUpdate={canUpdate}
              foreignKeyValues={foreignKeyValues}
              staticOptions={staticOptions}
              dataToUse={dataToUse}
              originalData={originalData}
              updateRow={updateRow}
              autoCompletedFields={autoCompletedFields}
              updateTotals={updateTotals}
            />
          )}
          <DeleteAction
            row={row}
            rowID={rowID}
            moduleID={moduleID}
            config={config}
            title={title}
            canDelete={canDelete}
            deleteRow={deleteRow}
          />
        </Flex>
        <CopyAction
          row={row}
          rowID={rowID}
          moduleID={moduleID}
          columns={columns}
          config={config}
          title={title}
          foreignKeyValues={foreignKeyValues}
          staticOptions={staticOptions}
          privileges={privileges}
          canCopy={canCopy}
          dataToUse={dataToUse}
          originalData={originalData}
          insertData={insertData}
          autoCompletedFields={autoCompletedFields}
          updateTotals={updateTotals}
        />
      </Flex>
    </div>
  );
};

export default Actions;
