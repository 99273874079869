import { Card, Row, Col } from "antd";
import infoDisplayDecider from "../../helpers/infoDisplayDecider/infoDisplayDecider";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const InfoBody = ({ row, columns, foreignKeyValues, viewRights, config }) => {
  const { width } = useWindowDimensions();

  let miniCardColumn = columns
    .filter((column) => column.F_FRAME === "Y")
    .filter((column, index) => index === 0);

  if (!miniCardColumn.length) {
    miniCardColumn = columns.filter((column, index) => index === 0);
  }

  const miniCardColumnID = miniCardColumn[0].F_ID;

  const columnsToShow = columns.filter(
    (column) => column.F_ID !== miniCardColumnID && column?.F_LIST === "Y"
  );

  return (
    <Card style={{ minHeight: 300, width: width - 425 }}>
      <Row gutter={40} justify="left">
        {columnsToShow
          .filter((item) => viewRights[item.F_ID])
          .map((item) => (
            <Col
              key={`info-details-${item.F_ID}`}
              className="gutter-row"
              span={6}
            >
              <div className="grid">
                <p className="p-title">{item.T_TITLE}</p>
                <div className="horizontal-line"></div>
                {infoDisplayDecider(
                  item?.F_RENDER,
                  item.F_TYPE,
                  row[item.F_ID],
                  config,
                  columns,
                  row,
                  row[`${item.F_ID}-server`] ?? null,
                  foreignKeyValues,
                  item.F_ID,
                  false,
                  item.T_TITLE,
                  item.F_PARENTFIELDID,
                  item?.REFMODULE,
                  item?.REFFIELD
                )}
              </div>
            </Col>
          ))}
      </Row>
    </Card>
  );
};

export default InfoBody;
