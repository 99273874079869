import Flex from "../../../../../../helpers/components/Flex";
import { Input } from "antd";
import validateValue from "./validateValue";
import { useState } from "react";

const Size = ({ data, addChange, fields, config }) => {
  const [status, setStatus] = useState(null);

  const checkValueValidity = (id, value) => {
    const isValid = validateValue(value);

    if (isValid) {
      setStatus(null);
      addChange(id, value);
    } else {
      setStatus("error");
    }
  };

  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[7].TITLE}:
      </p>
      <Input
        style={{
          width: 300,
          marginLeft: 20,
          marginTop: -5,
          marginBottom: "0.7rem",
          borderRadius: 5,
        }}
        onChange={(e) => checkValueValidity("SIZE", e.target.value)}
        status={status}
        defaultValue={config === "edit" ? data.SIZE : null}
      />
    </Flex>
  );
};

export default Size;
