import Flex from "../../../../../helpers/components/Flex";
import { Tooltip } from "antd";
import "../../../css/ultimateTable.css";

const DisplayDecider = ({
  columnsChanged,
  fieldID,
  changes,
  row,
  title,
  info,
}) => {
  if (!title) {
    return null;
  }
  if (columnsChanged.includes(fieldID) && changes[fieldID] !== row[fieldID]) {
    if (title.length > 22) {
      return (
        <div className="filters-first_column">
          <Flex flexDirection={"row"}>
            <p className="asterisk">*</p>
            <Tooltip
              placement="bottom"
              color="white"
              overlayInnerStyle={{ color: "black" }}
              title={
                <Flex flexDirection={"column"}>
                  <b>{title}</b>
                  {info && <i>{info}</i>}
                </Flex>
              }
            >
              {title.slice(0, 22) + "... "}:
            </Tooltip>
          </Flex>
        </div>
      );
    } else {
      return (
        <div className="filters-first_column">
          <p className="asterisk">*</p>
          <Tooltip
            placement="bottom"
            title={info && <i>{info}</i>}
            color="white"
            overlayInnerStyle={{ color: "black" }}
          >
            {title}:
          </Tooltip>
        </div>
      );
    }
  } else {
    if (title.length > 22) {
      return (
        <p className="filters-first_column">
          <Tooltip
            placement="bottom"
            color="white"
            overlayInnerStyle={{ color: "black" }}
            title={
              <Flex flexDirection={"column"}>
                <b>{title}</b>
                {info && <i>{info}</i>}
              </Flex>
            }
          >
            {title.slice(0, 22) + "... "}:
          </Tooltip>
        </p>
      );
    } else {
      return (
        <p className="filters-first_column">
          <Tooltip
            placement="bottom"
            title={info && <i>{info}</i>}
            color="white"
            overlayInnerStyle={{ color: "black" }}
          >
            {title}:
          </Tooltip>
        </p>
      );
    }
  }
};

export default DisplayDecider;
