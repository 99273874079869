import "./adminTitles.css";
import { Layout, Select } from "antd";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../store/useStores";

const { Option } = Select;
const { Content } = Layout;

const AdminTitlesSettings = ({ selection, changeSelection, selections }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Content
      style={{
        padding: 10,
        minHeight: "max-content",
        backgroundColor: "transparent",
      }}
    >
      <Flex flexDirection={"row"}>
        <p className="elements">{commonTitles["CO|1|SELECTION"]}: </p>
        <Content
          style={{
            padding: 5,
            minHeight: "max-content",
            backgroundColor: "transparent",
          }}
        >
          <div className="select">
            <Select
              style={{
                width: 300,
                marginLeft: 7,
                marginTop: 4,
              }}
              showSearch={true}
              onChange={(e) => changeSelection(e)}
              filterOption={(iv, op) => {
                let opToUse = op.children ? op.children : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              defaultValue={selection}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              {selections.map((item) => (
                <Option key={item.SELID} className="option" value={item.SELID}>
                  {item.SELNAME}
                </Option>
              ))}
            </Select>
          </div>
        </Content>
      </Flex>
    </Content>
  );
};

export default AdminTitlesSettings;
