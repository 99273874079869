import axios from "axios";

async function apiGetAlertTitles(workspaceID, language, baseURL) {
  const url = `${baseURL}/app_titles_api/module_titles`;
  try {
    const response = await axios.post(
      url,
      {
        workspaceid: workspaceID,
        reftype: "MO",
        refid: "7",
        lang: language,
        fieldtitles: true,
      },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {}
}

export default apiGetAlertTitles;
