import axios from "axios";

const apiGetMetrics = async (
  moduleid,
  baseURL,
  filters,
  datefield,
  groupField,
  grouping,
  displayFunction,
  chartType
) => {
  const url = `${baseURL}/metrics_api/getdata`;

  console.log("GetMetrics: moduleid: ", moduleid);
  console.log("GetMetrics: baseURL: ", baseURL);
  console.log("GetMetrics: filters: ", filters);
  console.log("GetMetrics: datefield: ", datefield);
  console.log("GetMetrics: groupField: ", groupField);
  console.log("GetMetrics: grouping: ", grouping);
  console.log("GetMetrics: displayFunction: ", displayFunction);
  console.log("GetMetrics: chartType: ", chartType);

  try {
    const response = await axios.post(
      url,
      {
        moduleid: moduleid,
        filters: filters?.[moduleid] ?? {},
        xfield: datefield,
        groupfield: groupField,
        grouping: grouping,
        displayfunction: displayFunction,
        charttype: chartType,
      },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    console.log(
      "GetMetrics: metrics_api/getdata: response.data: ",
      response.data
    );
    return response.data;
  } catch (err) {}
};

export default apiGetMetrics;
