import axios from "axios";

const apiGetSystemTitles = async (baseURL, language, refType, refID) => {
  const url = `${baseURL}/App_titles2_api/list`;

  let params = { system: "1", reftype: refType, refid: refID, lang: language };
  if (refType !== "CO") {
    params = { ...params, projectID: "0" };
  }

  try {
    const response = await axios.post(
      url,
      { ...params },
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("apiGetSystemTitles: response: ", response.data);
    return response.data;
  } catch (err) {}
};

export default apiGetSystemTitles;
