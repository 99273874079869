import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../../helpers/modals/deleteModal/DeleteModal";
import { useState } from "react";
import { useCommonTitlesStore } from "../../../../store/useStores";

const DeleteAction = ({
  row,
  rowID,
  moduleID,
  config,
  title,
  canDelete,
  deleteRow,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const cancelDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const onDelete = (moduleID) => {
    setDeleteModalVisible(false);
    deleteRow([rowID], "single", config, moduleID);
  };

  if (canDelete) {
    return (
      <>
        <Button
          className="infocard-btn"
          shape="round"
          icon={<DeleteOutlined />}
          size="small"
          onClick={() => setDeleteModalVisible(true)}
        >
          {commonTitles["CO|1|DELETEBTN"]}
        </Button>
        {deleteModalVisible && (
          <DeleteModal
            visible={deleteModalVisible}
            title={title}
            selectedItems={[rowID]}
            onDelete={onDelete}
            moduleID={config === "alerts" ? row.moduleID : moduleID}
            onCancel={cancelDeleteModal}
            closable
          />
        )}
      </>
    );
  } else return null;
};

export default DeleteAction;
