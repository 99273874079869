import Flex from "../../../../../helpers/components/Flex";

const DocumentType = ({ value }) => {
  if (value && value?.length) {
    const newValue = value?.map((item) => (
      <Flex key={item?.uid} direction={"horizontal"}>
        {value?.length > 1 && <p style={{ marginRight: 5 }}>{"•"}</p>}
        <a
          className="link"
          href={`${item?.url}`}
          target="_blank"
          rel="noreferrer"
        >
          {item?.name}
          <br />
        </a>
      </Flex>
    ));

    if (newValue.length > 2) {
      const shortNewValue = newValue
        ?.filter((item, index) => index < 2)
        ?.map((item) => item);
      shortNewValue.push(
        <Flex direction={"horizontal"}>
          {newValue?.length > 1 && <p style={{ marginRight: 5 }}>{"•"}</p>}
          <p>
            {"..."}
            <br />
          </p>
        </Flex>
      );

      return shortNewValue;
    }
    return newValue;
  }

  return null;
};

export default DocumentType;
