import apiGetValueColor from "../../../api/endpoints/apiGetValueColor";

export const convertDataFromServer = async (
  columnData,
  rowID,
  originalData,
  staticOptions,
  foreignKeyValues,
  changes,
  config,
  userID,
  renderAlertColorValues
) => {
  let staticFields = [];
  let foreignFields = [];
  let multipleForeignFields = [];
  let attachmentFields = [];
  let dependentFields = [];
  let dependentParentFields = [];
  let renderAlertFields = [];
  let checkboxFields = [];

  columnData.forEach((item) => {
    if (item.F_TYPE === "6") {
      staticFields.push(item.F_ID);
    }
    if (item.F_TYPE === "7") {
      foreignFields.push(item.F_ID);
    }
    if (item.F_TYPE === "28") {
      multipleForeignFields.push(item.F_ID);
    }
    if (item.F_TYPE === "24" || item.F_TYPE === "25") {
      attachmentFields.push(item.F_ID);
    }
    if (item.F_TYPE === "30") {
      dependentFields.push(item.F_ID);
      dependentParentFields.push(item.F_PARENTFIELDID);
    }
    if (item.F_RENDER === "ALERT") {
      renderAlertFields.push(item.F_ID);
    }
    if (item.F_TYPE === "99") {
      checkboxFields.push(item.F_ID);
    }
  });

  let tempData = {};

  checkboxFields.forEach((field) => {
    if (!Object.keys(changes).includes(field)) {
      tempData = { ...tempData, [field]: "N" };
    }
  });

  Object.entries(changes).map(([key, value]) => {
    if (attachmentFields.includes(key)) {
      if (config === "insert" || config === "copy") {
        const newValue = value.map((item) => ({
          name: item.name,
          url: item.link,
          uid: item.id,
        }));
        tempData = {
          ...tempData,
          [key]: value.length ? [...newValue] : [],
        };
      } else {
        let filesToDelete = [];
        value.forEach((item) => {
          if (item.action === "D") {
            filesToDelete.push(item.id);
          }
        });

        originalData[rowID][key].map((file) => console.log(file));

        // const oldFilesToKeep =
        //   originalData[rowID][key] ??
        //   [].filter((file) => !filesToDelete.includes(file.uid));

        let oldFilesToKeep = originalData[rowID][key] ?? [];

        value.forEach((file) => {
          if (file.action === "D") {
            oldFilesToKeep = oldFilesToKeep.filter(
              (item) => item.uid !== file.id
            );
          }
        });

        tempData = { ...tempData, [key]: [...oldFilesToKeep] ?? [] };

        value = value.filter((item) => item.action !== "D");

        //console.log("tempdata: value: ", value);

        if (value.length) {
          value = value.map((item) => ({
            name: item.name,
            url: item.link,
            status: "done",
            uid: item.id,
          }));

          tempData = {
            ...tempData,
            [key]: [...tempData[key], ...value],
          };
        }
      }
    } else if (staticFields.includes(key)) {
      let newValue = staticOptions[key][value]?.TITLE;
      let bgColor = staticOptions[key][value]?.BC;
      tempData = {
        ...tempData,
        [key]: { view: newValue, bgColor: bgColor },
        [`${key}-server`]: value,
        [`${key}-US`]: userID,
      };
    } else if (foreignFields.includes(key)) {
      let newValue =
        foreignKeyValues[key][value]?.view ?? foreignKeyValues[key][value];
      tempData = {
        ...tempData,
        [key]: newValue,
        [`${key}-server`]: value,
        [`${key}-US`]: userID,
      };

      if (dependentParentFields.includes(key)) {
        const dependentFields = columnData.filter(
          (column) => column.F_PARENTFIELDID === key
        );

        dependentFields.forEach((field) => {
          const dependentID = field.F_ID;
          const finalValue = foreignKeyValues[dependentID]?.[value]?.view ?? "";
          const finalServerValue = value;

          tempData = {
            ...tempData,
            [dependentID]: finalValue,
            [`${dependentID}-server`]: finalServerValue,
            [`${dependentID}-US`]: userID,
          };
        });
      }
    } else if (multipleForeignFields.includes(key)) {
      let newValue = value?.split(",") ?? [];
      var str = "";
      newValue
        .filter((item) => item !== "")
        .forEach((item) => {
          if (str !== "") {
            str += ",";
          }
          str += foreignKeyValues[key][item]?.view;
        });
      tempData = {
        ...tempData,
        [key]: str,
        [`${key}-server`]: value,
        [`${key}-US`]: userID,
      };

      if (dependentParentFields.includes(key)) {
        const dependentFields = columnData.filter(
          (column) => column.F_PARENTFIELDID === key
        );

        dependentFields.forEach((field) => {
          const dependentID = field.F_ID;
          const dependentFieldInfo =
            Object.prototype.toString.call(field.DEP_FIELDS) ===
            "[object Array]"
              ? field.DEP_FIELDS
              : [field.DEP_FIELDS];

          let finalValue = "";
          let finalServerValue = "";

          let newValue = value?.split(",") ?? [];
          newValue
            .filter((item) => item !== "")
            .forEach((item) => {
              const foreignKey = item;

              const dependentKey = columnData.filter(
                (column) => column.F_PARENTFIELDID === key
              )?.[0]?.F_ID;

              if (Object.keys(foreignKeyValues).includes(dependentKey)) {
                const serverValue = foreignKey;
                const displayValue =
                  foreignKeyValues?.[dependentKey]?.[serverValue]?.view ?? "";

                if (finalValue !== "") {
                  finalValue += ",";
                }
                finalValue += displayValue;

                if (finalServerValue !== "") {
                  finalServerValue += ",";
                }
                finalServerValue += serverValue;
              } else {
                dependentFieldInfo.forEach((item) => {
                  if (item.F_TYPE === "6") {
                    const serverValue = Object.entries(staticOptions[item.F_ID])
                      .filter(
                        ([, value]) =>
                          value.TITLE ===
                          foreignKeyValues[dependentID][foreignKey].view
                      )
                      .map(([key]) => key);

                    const displayValue = Object.entries(
                      staticOptions[item.F_ID]
                    )
                      .filter(
                        ([, value]) =>
                          value.TITLE ===
                          foreignKeyValues[dependentID][foreignKey].view
                      )
                      .map(([, value]) => value.TITLE);

                    if (finalValue !== "") {
                      finalValue += ",";
                    }
                    finalValue += displayValue;

                    if (finalServerValue !== "") {
                      finalServerValue += ",";
                    }
                    finalServerValue += serverValue;
                  } else if (item.F_TYPE === "7" || item.F_TYPE === "28") {
                    const serverValue = foreignKey;
                    const displayValue =
                      foreignKeyValues[item.F_ID][foreignKey].view;

                    if (finalValue !== "") {
                      finalValue += ",";
                    }
                    finalValue += displayValue;

                    if (finalServerValue !== "") {
                      finalServerValue += ",";
                    }
                    finalServerValue += serverValue;
                  }
                });
              }

              tempData = {
                ...tempData,
                [dependentID]: finalValue,
                [`${dependentID}-server`]: finalServerValue,
                [`${dependentID}-US`]: userID,
              };
            });
        });
      }
    } else if (renderAlertFields.includes(key)) {
      tempData = {
        ...tempData,
        [key]: renderAlertColorValues?.[key]
          ? renderAlertColorValues?.[key]
          : {
              value: value,
              color: ["#ffffff", "B"],
            },
        [`${key}-US`]: userID,
      };
    } else if (key === "LM") {
      tempData = { ...tempData, [key]: value };
    } else {
      tempData = { ...tempData, [key]: value, [`${key}-US`]: userID };
    }
  });

  return tempData;
};

export const transformDataForServer = (
  rowID,
  data,
  columnData,
  staticOptions,
  foreignKeyValues,
  config
) => {
  let staticFields = [];
  let foreignFields = [];
  let multipleForeignFields = [];
  let attachmentFields = [];
  let automaticIDFields = [];
  let dependentFields = [];
  columnData.forEach((item) => {
    if (item.F_TYPE === "6") {
      staticFields.push(item.F_ID);
    }
    if (item.F_TYPE === "7") {
      foreignFields.push(item.F_ID);
    }
    if (item.F_TYPE === "28") {
      multipleForeignFields.push(item.F_ID);
    }
    if (item.F_TYPE === "24" || item.F_TYPE === "25") {
      attachmentFields.push(item.F_ID);
    }
    if (item.F_TYPE == "29") {
      automaticIDFields.push(item.F_ID);
    }
    if (item.F_TYPE == "30") {
      dependentFields.push(item.F_ID);
    }
  });

  let reversedData = {};
  Object.entries(data[rowID]).map(([key, value]) => {
    let isNum = /^\d+$/.test(key);
    if (isNum) {
      if (attachmentFields.includes(key)) {
        reversedData = {
          ...reversedData,
          [key]: [],
        };
      } else if (staticFields.includes(key)) {
        reversedData = {
          ...reversedData,
          [key]: data[rowID][`${key}-server`],
        };
      } else if (foreignFields.includes(key)) {
        reversedData = {
          ...reversedData,
          [key]: data[rowID][`${key}-server`],
        };
      } else if (multipleForeignFields.includes(key)) {
        reversedData = {
          ...reversedData,
          [key]: data[rowID][`${key}-server`],
        };
      } else if (key === "LM") {
        reversedData = { ...reversedData, [key]: value };
      } else if (
        (config === "copy" && automaticIDFields.includes(key)) ||
        dependentFields.includes(key)
      ) {
        return;
      } else {
        reversedData = { ...reversedData, [key]: value };
      }
    } else return;
  });

  return reversedData;
};

export const tranformChangesforServer = (
  changes,
  columnData,
  dataToUse,
  rowID,
  foreignKeyValues,
  transformedAttachChanges,
  baseURL,
  userID
) => {
  let multipleForeignFields = [];
  let attachmentFields = [];

  columnData.forEach((item) => {
    if (item.F_TYPE === "28") {
      multipleForeignFields.push(item.F_ID);
    }
    if (item.F_TYPE === "24" || item.F_TYPE === "25") {
      attachmentFields.push(item.F_ID);
    }
  });

  let transformedChanges = {};

  Object.entries(changes).map(([key, value]) => {
    if (multipleForeignFields.includes(key)) {
      let newValue = [];
      var str = "";
      Object.values(value).map((item) => {
        let isnum = /^\d+$/.test(item);

        if (!isnum) {
          const filteredData = dataToUse.filter((item) => item.rowID === rowID);
          item = filteredData[0][`${key}-server`];
        }
        newValue.push(item);
      });

      newValue.map((item) => {
        if (str !== "") {
          str += ",";
        }

        str += item;
      });

      transformedChanges = {
        ...transformedChanges,
        [key]: str,
      };
    } else if (attachmentFields.includes(key)) {
      let newValue = transformedAttachChanges[key].map((file) => {
        if (file.action !== "D") {
          return {
            name: file.name,
            id: file.uid,
            link: file.link,
            action: file.action,
          };
        } else {
          return { name: file.view, id: file.id, action: file.action };
        }
      });

      transformedChanges = { ...transformedChanges, [key]: newValue };
    } else {
      let isnum = /^\d+$/.test(key);
      if (isnum) {
        transformedChanges = { ...transformedChanges, [key]: value };
      }
    }
  });

  return transformedChanges;
};
