const StaticListType = ({ value }) => {
  let newValue = value ?? "";
  if (typeof value === "object") {
    newValue = value?.view;
  }
  newValue = newValue ?? "";
  return newValue;
};

export default StaticListType;
