import Flex from "../../../../helpers/components/Flex";
import {
  useCommonTitlesStore,
  useAuthStore,
} from "../../../../store/useStores";
import apiTriggerSendEmail from "../../../../api/endpoints/apiTriggerSendEmail";
import Button from "@mui/material/Button";
import ReactTooltip from "react-tooltip";
import EmailIcon from "@mui/icons-material/Email";
import displayResponseMessage from "../../../../helpers/functions/displayResponseMessage";

const EmailTrigger = ({ showEmailTriggerButton }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const baseURL = useAuthStore((state) => state.baseURL);
  const bannerTextColor = useAuthStore((state) => state.bannerTextColor);

  const triggerSendEmails = async () => {
    const response = await apiTriggerSendEmail(baseURL);
    if (response?.STATUS === "Success") {
      displayResponseMessage(response?.MESSAGE, 3, "success");
    } else {
      displayResponseMessage(response?.MESSAGE, 3, "error");
    }
  };

  return (
    <Flex
      style={{
        marginBottom: "0.2rem",
        marginLeft: 5,
        width: "max-content",
        height: "max-content",
        display: !showEmailTriggerButton && "none",
      }}
    >
      <a data-tip={commonTitles["CO|1|TRIGEML"]}>
        <Button sx={{ color: bannerTextColor }} onClick={triggerSendEmails}>
          {<EmailIcon />}
        </Button>
      </a>
      <ReactTooltip place="bottom" />
    </Flex>
  );
};

export default EmailTrigger;
