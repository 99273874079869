import Flex from "../../../../../../helpers/components/Flex";
import { Select } from "antd";

const { Option } = Select;

const Align = ({ data, addChange, fields, config, staticOptions }) => {
  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[11].TITLE}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          showSearch={true}
          onChange={(e) => {
            addChange("F_ALIGN", e);
          }}
          filterOption={(iv, op) => {
            let opToUse = op?.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          defaultValue={config === "edit" && data.F_ALIGN}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          <Option
            key={Object.keys(staticOptions[11])[0]}
            className="option"
            value={Object.keys(staticOptions[11])[0]}
          >
            {staticOptions[11].L.TITLE}
          </Option>
          <Option
            key={Object.keys(staticOptions[11])[1]}
            className="option"
            value={Object.keys(staticOptions[11])[1]}
          >
            {staticOptions[11].C.TITLE}
          </Option>
          <Option
            key={Object.keys(staticOptions[11])[2]}
            className="option"
            value={Object.keys(staticOptions[11])[2]}
          >
            {staticOptions[11].R.TITLE}
          </Option>
        </Select>
      </div>
    </Flex>
  );
};

export default Align;
