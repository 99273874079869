import { message } from "antd";
import axios from "axios";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";

const apiAlertDownload = async (
  baseURL,
  filename,
  title,
  suffix,
  commonTitles
) => {
  const url = `${baseURL}/Report_api/alertDownload`;
  try {
    const response = await axios.post(
      url,
      { filename: filename },
      { responseType: "blob" },
      {
        withCredentials: true,
      }
    );
    //console.log("apiDownload: response: ", response);
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${title}.${suffix}`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return response.data;
  } catch (err) {
    //message.error(err, [3]);
    displayResponseMessage(commonTitles["CO|1|ERR500"], 3, "error");
  }
};

export default apiAlertDownload;
