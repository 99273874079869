import { useAuthStore, useMenuStore } from "../../../store/useStores";
import { UserOutlined } from "@ant-design/icons";
import Flex from "../../../helpers/components/Flex";

const User = () => {
  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );
  const username = useMenuStore((state) => state.username);
  return (
    <Flex style={{ marginTop: "0.8rem" }}>
      <UserOutlined
        style={{
          marginLeft: 30,
          marginRight: 10,
          marginTop: -7,
          color: navigationTextColor,
        }}
      />
      <p style={{ color: navigationTextColor }}>{username}</p>
    </Flex>
  );
};

export default User;
