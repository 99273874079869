import Flex from "../../../../../../helpers/components/Flex";
import { Select } from "antd";
import { useModuleStore } from "../../../../../../store/useStores";

const { Option } = Select;

const RenderTypes = ({
  data,
  addChange,
  fields,
  config,
  staticOptions,
  type,
}) => {
  let showDeadlineOption = true;
  let showProgressOption = true;
  let showColourOption = true;

  const numberTypes = useModuleStore((state) => state.numberTypes);
  const dateTypes = useModuleStore((state) => state.dateTypes);
  const fromOtherModuleTypes = useModuleStore(
    (state) => state.fromOtherModuleTypes
  );

  if (type) {
    if (
      ![...numberTypes, ...dateTypes, ...fromOtherModuleTypes].includes(type)
    ) {
      showColourOption = false;
    }

    if (![...dateTypes, ...fromOtherModuleTypes].includes(type)) {
      showDeadlineOption = false;
    }

    if (![...numberTypes, "6", ...fromOtherModuleTypes].includes(type)) {
      showProgressOption = false;
    }
  }

  return (
    <Flex
      flexDirection={"row"}
      style={{ gap: "0.5rem", justifyItems: "center" }}
    >
      <p
        style={{
          display: "flex",
          width: 180,
          justifyContent: "flex-end",
        }}
      >
        {fields[132].TITLE}:
      </p>
      <div className="select">
        <Select
          style={{
            width: 300,
            marginLeft: 15,
            marginBottom: "1rem",
          }}
          showSearch={true}
          onChange={(e) => {
            addChange("F_RENDER", e);
          }}
          filterOption={(iv, op) => {
            let opToUse = op.children ? op.children : "";
            return opToUse.toLowerCase().includes(iv.toLowerCase());
          }}
          defaultValue={config === "edit" && data.F_RENDER}
          dropdownStyle={{ overflowY: "auto" }}
          virtual={false}
        >
          <Option className="option" value={""} />
          {showColourOption && (
            <Option key={"A"} className="option" value={"A"}>
              {staticOptions[132].A.TITLE}
            </Option>
          )}
          {showDeadlineOption && (
            <Option key={"D"} className="option" value={"D"}>
              {staticOptions[132].D.TITLE}
            </Option>
          )}
          {showProgressOption && (
            <Option key={"P"} className="option" value={"P"}>
              {staticOptions[132].P.TITLE}
            </Option>
          )}
        </Select>
      </div>
    </Flex>
  );
};

export default RenderTypes;
