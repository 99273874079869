import EmailType from "./emailType/EmailType";
import InputType from "./inputType/InputType";
import PhoneType from "./phoneType/PhoneType";
import MultipleSelection from "./staticListType/MultipleSelection";
import SingleSelection from "./staticListType/SingleSelection";
import CheckBoxType from "./checkboxType/CheckboxType";

const inputDecider = (
  value,
  id,
  title,
  key,
  type,
  addChange,
  staticOptions,
  roles,
  config,
  changes
) => {
  if (type === "input") {
    return (
      <InputType
        value={value}
        id={id}
        title={title}
        config={config}
        addChange={addChange}
      />
    );
  }

  if (type === "email") {
    return (
      <EmailType
        value={value}
        id={id}
        title={title}
        config={config}
        addChange={addChange}
      />
    );
  }

  if (type === "phone") {
    return (
      <PhoneType
        value={value}
        id={id}
        title={title}
        config={config}
        changes={changes}
        addChange={addChange}
      />
    );
  }

  if (type === "singleSelection") {
    return (
      <SingleSelection
        value={value}
        id={id}
        title={title}
        config={config}
        staticOptions={staticOptions}
        staticOptionsKey={key}
        addChange={addChange}
      />
    );
  }

  if (type === "checkbox") {
    return (
      <CheckBoxType
        value={value}
        id={id}
        title={title}
        config={config}
        addChange={addChange}
      />
    );
  }

  if (type === "multipleSelection") {
    return (
      <MultipleSelection
        value={value}
        id={id}
        title={title}
        config={config}
        roles={roles}
        addChange={addChange}
      />
    );
  }
};

export default inputDecider;
