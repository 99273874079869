import Navigation from "../navigation/Navigation";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import { useEffect, useRef, useState } from "react";
import "./profile.css";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import apiGetProfileTitles from "../../api/endpoints/apiGetProfileTitles";
import { useAuthStore, useCommonTitlesStore } from "../../store/useStores";
import apiChangePassword from "../../api/endpoints/apiChangePassword";
import Flex from "../../helpers/components/Flex";
import displayResponseMessage from "../../helpers/functions/displayResponseMessage";
import BackgroundWrapper from "../../helpers/components/BackgroundWrapper";
import BannerWrapper from "../../helpers/components/BannerWrapper";
import ModuleWrapper from "../module/wrappers/ModuleWrapper";

const Profile = () => {
  const [profileTitles, setProfileTitles] = useState();
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();

  const baseURL = useAuthStore((state) => state.baseURL);

  const firstName = useAuthStore((state) => state.firstName);
  const setFirstName = useAuthStore((state) => state.setFirstName);
  const lastName = useAuthStore((state) => state.lastName);
  const setLastName = useAuthStore((state) => state.setLastName);
  const dateFormat = useAuthStore((state) => state.dateFormat);
  const setDateFormat = useAuthStore((state) => state.setDateFormat);
  const timeFormat = useAuthStore((state) => state.timeFormat);
  const setTimeFormat = useAuthStore((state) => state.setTimeFormat);
  const language = useAuthStore((state) => state.language);
  const workspaceID = useAuthStore((state) => state.workspaceID);
  const password = useAuthStore((state) => state.password);
  const setPassword = useAuthStore((state) => state.setPassword);

  const [tempFirstName, setTempFirstName] = useState(firstName);
  const [tempLastName, setTempLastName] = useState(lastName);
  const [tempDateFormat, setTempDateFormat] = useState(dateFormat);
  const [tempTimeFormat, setTempTimeFormat] = useState(timeFormat);
  const [passwordChanges, setPasswordChanges] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });

  let navigate = useNavigate();

  const handleSave = (e) => {
    setFirstName(tempFirstName);
    setLastName(tempLastName);
    setDateFormat(tempDateFormat);
    setTimeFormat(tempTimeFormat);

    navigate(-1);
  };

  const handlePasswordChange = async () => {
    if (
      passwordChanges.oldpassword &&
      passwordChanges.newpassword &&
      passwordChanges.confirmpassword
    ) {
      if (passwordChanges.oldpassword === password) {
        if (passwordChanges.newpassword !== password) {
          if (passwordChanges.newpassword === passwordChanges.confirmpassword) {
            const response = await apiChangePassword(
              baseURL,
              passwordChanges,
              commonTitles
            );
            console.log("response: ", response);
            if (response.STATUS === "Success") {
              setPassword(passwordChanges.newpassword);
              setPasswordChanges({
                oldpassword: "",
                newpassword: "",
                confirmpassword: "",
              });
              //message.success(commonTitles["CO|1|PASSWDSUCC"], [3]);
              displayResponseMessage(
                commonTitles["CO|1|PASSWDSUCC"],
                3,
                "success"
              );
              //navigate(-1);
            } else {
              //message.error(commonTitles[response?.MESSAGE], [3]);
              displayResponseMessage(
                commonTitles[response?.MESSAGE],
                3,
                "error"
              );
            }
          } else {
            //message.warning(commonTitles["CO|1|PASSNOMATC"], [3]);
            displayResponseMessage(
              commonTitles["CO|1|PASSNOMATC"],
              5,
              "warning"
            );
          }
        } else {
          //message.warning(commonTitles["CO|1|PASSSELNEW"], [3]);
          displayResponseMessage(commonTitles["CO|1|PASSSELNEW"], 5, "warning");
        }
      } else {
        //message.warning(commonTitles["CO|1|OPWNOMATCH"], [3]);
        displayResponseMessage(commonTitles["CO|1|OPWNOMATCH"], 5, "warning");
      }
    } else {
      let emptyFields = "";
      const tags = {
        oldpassword: commonTitles.oldPwd,
        newpassword: commonTitles.newPwd,
        confirmpassword: commonTitles.confirmPwd,
      };
      Object.entries(passwordChanges).forEach(([key, value]) => {
        if (!value) {
          if (emptyFields !== "") {
            emptyFields += ", ";
          }
          emptyFields += tags[key];
        }
      });

      if (emptyFields.includes(",")) {
        let ucSetMsg2 = commonTitles["CO|1|UCSETMSG2"];
        var re = new RegExp("<1>", "g");
        ucSetMsg2 = ucSetMsg2.replace(re, emptyFields);
        //message.warning(ucSetMsg2, [3]);
        displayResponseMessage(ucSetMsg2, 3, "warning");
      } else {
        let ucSetMsg1 = commonTitles["CO|1|UCSETMSG1"];
        var re = new RegExp("<1>", "g");
        ucSetMsg1 = ucSetMsg1.replace(re, emptyFields);
        //message.warning(ucSetMsg1, [3]);
        displayResponseMessage(ucSetMsg1, 3, "warning");
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(async () => {
    const profileTitlesData = await apiGetProfileTitles(
      workspaceID,
      language,
      baseURL
    );
    setProfileTitles(profileTitlesData);
  }, [language]);

  const addPasswordChanges = (id, value) => {
    setPasswordChanges({ ...passwordChanges, [id]: value });
  };

  console.log("passwordChanges: ", passwordChanges);

  return (
    <Flex flexDirection={"column"}>
      <Navigation />
      <BackgroundWrapper>
        <ModuleWrapper>
          <BannerWrapper title={commonTitles.profile} />
          <Box className="profile__box" component="form">
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <div className="profile__box-content">
              <div className="user__preferences">
                <h2>{commonTitles.preferences}</h2>
                <div className="preferences">
                  <div className="profile__box-firstColumn">
                    {profileTitles ? (
                      <Flex flexDirection={"column"} style={{ gap: "1.7rem" }}>
                        <p>{profileTitles["FD|266|TITLE"]}:</p>
                        <p>{profileTitles["FD|265|TITLE"]}:</p>
                        <p>{profileTitles["FD|271|TITLE"]}:</p>
                        <p>{profileTitles["FD|272|TITLE"]}:</p>
                      </Flex>
                    ) : null}
                  </div>
                  <div className="profile__box-secondColumn">
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Input
                        value={tempFirstName}
                        onChange={(e) => setTempFirstName(e.target.value)}
                        sx={{ height: 30, pl: 2 }}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Input
                        value={tempLastName}
                        onChange={(e) => setTempLastName(e.target.value)}
                        sx={{ height: 30, pl: 2 }}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Select
                        native
                        value={tempDateFormat}
                        showSearch={true}
                        filterOption={(iv, op) =>
                          op.value
                            .toLocaleLowerCase()
                            .includes((iv || "").toLocaleLowerCase())
                        }
                        onChange={(e) => setTempDateFormat(e.target.value)}
                        sx={{ height: 30 }}
                      >
                        {profileTitles ? (
                          <>
                            <option value={"15"}>
                              {profileTitles["FO|15|"]}
                            </option>
                            <option value={"21"}>
                              {profileTitles["FO|21|"]}
                            </option>
                            <option value={"16"}>
                              {profileTitles["FO|16|"]}
                            </option>
                            <option value={"22"}>
                              {profileTitles["FO|22|"]}
                            </option>
                            <option value={"17"}>
                              {profileTitles["FO|17|"]}
                            </option>
                          </>
                        ) : null}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Select
                        native
                        value={tempTimeFormat}
                        showSearch={true}
                        filterOption={(iv, op) =>
                          op.value
                            .toLocaleLowerCase()
                            .includes((iv || "").toLocaleLowerCase())
                        }
                        onChange={(e) => setTempTimeFormat(e.target.value)}
                        sx={{ height: 30 }}
                      >
                        {profileTitles ? (
                          <>
                            <option value={"19"}>
                              {profileTitles["FO|19|"]}
                            </option>
                            <option value={"18"}>
                              {profileTitles["FO|18|"]}
                            </option>
                          </>
                        ) : null}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="buttons">
                  <Button
                    style={{ marginTop: 20 }}
                    onClick={handleSave}
                    type="primary"
                    shape="round"
                  >
                    {commonTitles.save}
                  </Button>
                </div>
              </div>
              <div
                className={
                  language === "EN" ? "vertical__line" : "vertical__line-gr"
                }
              ></div>
              <div className="password__change">
                <h2>{commonTitles.pwdChange}</h2>
                <div className="password">
                  <div className="profile__box-firstColumn">
                    <Flex flexDirection={"column"} style={{ gap: "1.7rem" }}>
                      <p>{commonTitles.oldPwd}:</p>
                      <p>{commonTitles.newPwd}:</p>
                      <p>{commonTitles.confirmPwd}:</p>
                    </Flex>
                  </div>
                  <div className="profile__box-secondColumn">
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Input
                        type="password"
                        onChange={(e) => {
                          addPasswordChanges("oldpassword", e.target.value);
                        }}
                        sx={{ height: 30, pl: 2 }}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Input
                        type="password"
                        onChange={(e) => {
                          addPasswordChanges("newpassword", e.target.value);
                        }}
                        sx={{ height: 30, pl: 2 }}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, Width: 40 }}>
                      <Input
                        type="password"
                        onChange={(e) => {
                          addPasswordChanges("confirmpassword", e.target.value);
                        }}
                        sx={{ height: 30, pl: 2 }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="buttons">
                  <Button
                    style={{ marginTop: 55 }}
                    onClick={(e) => handlePasswordChange()}
                    type="primary"
                    shape="round"
                    className="button__pwd"
                  >
                    {commonTitles.change}
                  </Button>
                </div>
              </div>
            </div>
            <div className="cancel-btn">
              <Button onClick={handleCancel} type="primary" shape="round">
                {commonTitles.cancel}
              </Button>
            </div>
          </Box>
        </ModuleWrapper>
      </BackgroundWrapper>
    </Flex>
  );
};

export default Profile;
