import Badge from "@mui/material/Badge";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Link } from "react-router-dom";
import "../navigation.css";
import { useAlertStore, useAuthStore } from "../../../store/useStores";
import Flex from "../../../helpers/components/Flex";

const Alerts = () => {
  const navigationTextColor = useAuthStore(
    (state) => state.navigationTextColor
  );
  const alerts = useAlertStore((state) => state.alerts);

  return (
    <Flex
      flexDirection={"row"}
      style={{
        justifyContent: "flex-end",
        alignItems: "center",
        zIndex: 5,
      }}
    >
      <Badge
        badgeContent={alerts["COUNTNEAREXPIRATION"]}
        showZero
        color="warning"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Badge
          style={{ marginRight: 22, zIndex: 5 }}
          badgeContent={alerts["COUNTEXPIRED"]}
          showZero
          color="error"
        >
          <Link to="/home">
            <AccessTimeFilledIcon
              className="notifications"
              style={{ color: navigationTextColor }}
            />
          </Link>
        </Badge>
      </Badge>
    </Flex>
  );
};

export default Alerts;
