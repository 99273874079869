import { useCommonTitlesStore } from "../../../../../store/useStores";
import DependentDate from "./dependentDate/DependentDate";
import DependentDateTime from "./dependentDate/DependentDateTime";
import DependentMultipleSelection from "./dependentSelection/DependentMultipleSelection";
import DependentSingleSelection from "./dependentSelection/DependentSingleSelection";
import DependentStaticList from "./dependentStaticList/DependentStaticList";
import DependentText from "./dependentText/DependentText";
import DependentTextarea from "./dependentTextarea/DependentTextarea";
import Flex from "../../../../../helpers/components/Flex";
import { Input } from "antd";

const DependentType = ({
  value,
  id,
  addChange,
  config,
  staticOptions,
  foreignKeyValues,
  dependentFields,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  if (config === "filters") {
    return (
      <Flex flexDirection="column" style={{ gap: "0.2rem" }}>
        {dependentFields.map(({ F_ID, TITLE, F_TYPE }) => {
          if (F_TYPE === "1") {
            return (
              <DependentText
                key={F_ID}
                value={value}
                id={id}
                fieldID={F_ID}
                title={TITLE}
                addChange={addChange}
              />
            );
          } else if (F_TYPE === "27") {
            return (
              <DependentTextarea
                key={F_ID}
                value={value}
                id={id}
                fieldID={F_ID}
                title={TITLE}
                addChange={addChange}
              />
            );
          } else if (F_TYPE === "6") {
            return (
              <DependentStaticList
                key={F_ID}
                value={value}
                id={id}
                fieldID={F_ID}
                addChange={addChange}
                staticOptions={staticOptions}
                title={TITLE}
                config={config}
              />
            );
          } else if (F_TYPE === "4") {
            return (
              <DependentDate
                key={F_ID}
                value={value}
                id={id}
                fieldID={F_ID}
                addChange={addChange}
                config={config}
                title={TITLE}
              />
            );
          } else if (F_TYPE === "5") {
            return (
              <DependentDateTime
                key={F_ID}
                value={value}
                id={id}
                fieldID={F_ID}
                addChange={addChange}
                config={config}
                title={TITLE}
              />
            );
          } else if (F_TYPE === "7") {
            return (
              <DependentSingleSelection
                key={F_ID}
                value={value}
                id={id}
                fieldID={F_ID}
                foreignKeyValues={foreignKeyValues}
                addChange={addChange}
                title={TITLE}
                config={config}
              />
            );
          } else if (F_TYPE === "28") {
            return (
              <DependentMultipleSelection
                key={F_ID}
                value={value}
                id={id}
                fieldID={F_ID}
                foreignKeyValues={foreignKeyValues}
                addChange={addChange}
                title={TITLE}
              />
            );
          }
        })}
      </Flex>
    );
  } else if (config === "copy") {
    return (
      <Input
        placeholder={commonTitles["CO|1|DFIELDMSG"]}
        disabled={true}
        style={{ width: 278, height: 34 }}
      />
    );
  } else {
    return (
      <Input
        value={value ?? ""}
        disabled={true}
        style={{ width: 278, height: 34 }}
      />
    );
  }
};

export default DependentType;
