import { WarningOutlined } from "@ant-design/icons";
import { Select } from "antd";
import Flex from "../../../../../../../helpers/components/Flex";
import { useAuthStore } from "../../../../../../../store/useStores";
import apiGetFkValues from "../../../../../../../api/endpoints/apiGetFkValues";
import { useState, useEffect } from "react";

const { Option } = Select;

const MultipleSelectionType = ({
  id,
  required,
  addChange,
  foreignKeyValues,
  autoCompletedFields,
  moduleID = "",
  parentField = "",
  changes,
}) => {
  const baseURL = useAuthStore((state) => state.baseURL);
  const [options, setOptions] = useState([]);

  let value = [];
  if (
    Object.keys(autoCompletedFields).includes(id) &&
    autoCompletedFields?.[id]
  ) {
    value = autoCompletedFields?.[id] ?? [];
  }

  // let options = [];
  // if (foreignKeyValues[id]) {
  //   options = Object.entries(foreignKeyValues[id]).map(([key, value]) => ({
  //     id: key,
  //     view: value.view,
  //   }));
  //   options.sort((a, b) => a.view.localeCompare(b.view));
  // }

  useEffect(async () => {
    const fieldID = id;
    const parentFieldValue = changes[parentField] ?? "";

    if (parentField && parentField !== "0" && parentFieldValue) {
      const fkValues = await apiGetFkValues(
        baseURL,
        moduleID,
        fieldID,
        parentField,
        parentFieldValue
      );

      console.log("fkValues: ", fkValues);

      if (typeof fkValues === "object") {
        let tempOptions = [];
        if (foreignKeyValues[id]) {
          tempOptions = Object.entries(fkValues[id]).map(([key, value]) => ({
            id: key,
            view: value.view,
          }));
          tempOptions.sort((a, b) => a.view.localeCompare(b.view));
        }
        setOptions(tempOptions);
      } else {
        let tempOptions = [];
        if (foreignKeyValues[id]) {
          tempOptions = Object.entries(foreignKeyValues[id]).map(
            ([key, value]) => ({
              id: key,
              view: value.view,
            })
          );
          tempOptions.sort((a, b) => a.view.localeCompare(b.view));
        }
        setOptions(tempOptions);
      }
    } else {
      let tempOptions = [];
      if (foreignKeyValues[id]) {
        tempOptions = Object.entries(foreignKeyValues[id]).map(
          ([key, value]) => ({
            id: key,
            view: value.view,
          })
        );
        tempOptions.sort((a, b) => a.view.localeCompare(b.view));
      }
      setOptions(tempOptions);
    }
  }, [changes[parentField]]);

  return (
    <Flex key={options.length} flexDirection={"row"}>
      <div className="select">
        <Select
          mode="multiple"
          allowClear
          style={{
            width: 278,
            marginLeft: 7,
            marginTop: 4,
          }}
          showSearch={true}
          onChange={(e) => addChange(id, e, null)}
          filterOption={(iv, op) => {
            const replacements = [
              { key: "ί", newKey: "ι" },
              { key: "έ", newKey: "ε" },
              { key: "ό", newKey: "ο" },
              { key: "ή", newKey: "η" },
              { key: "ύ", newKey: "υ" },
              { key: "ώ", newKey: "ω" },
              { key: "ά", newKey: "α" },
              { key: "ς", newKey: "σ" },
            ];
            let opToUse = op.children ? op.children.toLowerCase() : "";
            let ivToUse = iv.toLowerCase();
            replacements.forEach(({ key, newKey }) => {
              var re = new RegExp(key, "g");
              ivToUse = ivToUse.replace(re, newKey);
              opToUse = opToUse.replace(re, newKey);
            });
            return opToUse.includes(ivToUse);
          }}
          dropdownStyle={{ overflowY: "auto" }}
          defaultValue={value}
          disabled={value?.length ? true : false}
          virtual={false}
        >
          <Option value="" />
          {options.map((item, index) => (
            <Option key={index} className="option" value={item?.id}>
              {item?.view}
            </Option>
          ))}
        </Select>
      </div>
      {required === "Y" && (
        <p style={{ color: "grey", marginTop: 8, marginLeft: 15 }}>
          <WarningOutlined />
        </p>
      )}
    </Flex>
  );
};

export default MultipleSelectionType;
