import { Card, Modal, Button } from "antd";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import Flex from "../../../helpers/components/Flex";
import { useCommonTitlesStore } from "../../../store/useStores";
import clientInputDecider from "./helpers/clientInputDecider";

export const NewClientModal = ({
  visible,
  data,
  headers,
  staticOptions,
  foreignKeyValues,
  onSave,
  onCancel,
  config,
}) => {
  const [changes, setChanges] = useState({});
  const columnsChanged = Object.keys(changes);
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  let attachColumns = [];
  headers.forEach((column) => {
    if (column.F_TYPE === "24" || column.F_TYPE === "25") {
      attachColumns.push(column.F_ID);
    }
  });

  const addChange = (id, value, config) => {
    if (value && value !== "" && value?.length !== 0) {
      if (attachColumns.includes(id)) {
        if (changes[id]) {
          if (config === "add") {
            let fileExists = [];
            for (let i = 0; i < value.length; i++) {
              changes[id].forEach((file) => {
                if (file.uid === value[i].uid) {
                  fileExists[i] = true;
                }
              });
            }

            for (let i = 0; i < value.length; i++) {
              !fileExists[i] &&
                setChanges({
                  ...changes,
                  [id]: [...changes[id], value[i]],
                });
            }
          } else {
            let tempChanges = [];
            changes[id].forEach((file) => tempChanges.push(file));
            tempChanges = tempChanges.filter((file) => file.uid !== value.uid);
            if (tempChanges.length) {
              setChanges({ ...changes, [id]: [...tempChanges] });
            } else {
              delete changes[id];
              setChanges({ ...changes });
            }
          }
        } else {
          setChanges({
            ...changes,
            [id]: value,
          });
        }
      } else {
        setChanges({ ...changes, [id]: value });
      }
    } else {
      delete changes[id];
      setChanges({ ...changes });
    }
  };

  console.log("changes: ", changes);

  return (
    <Modal
      key={`${data}-modal`}
      mask={false}
      maskClosable={false}
      open={visible}
      data={data}
      headers={headers}
      style={{
        marginTop: -40,
        display: "flex",
        justifyContent: "flex-start",
        width: "70vw",
      }}
      title={commonTitles["CO|1|NEWCLN"]}
      onOk={() => onSave(changes, data?.ROWID)}
      onCancel={() => onCancel(config)}
      footer={[
        <Button key="back" onClick={() => onCancel(config)}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(changes, data?.ROWID)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <Flex flexDirection={"column"} style={{ paddingLeft: "-8rem" }}>
        <Card
          bodyStyle={{
            maxHeight: "60vh",
            overflowY: "auto",
            width: "max-content",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {headers.map(({ F_ID, F_TYPE, T_TITLE }) => (
            <Flex
              key={F_ID}
              flexDirection={"row"}
              style={{ marginLeft: "-13rem", justifyContent: "flex-start" }}
            >
              {columnsChanged.includes(F_ID) ? (
                <div className="filters-first_column">
                  <p className="asterisk">*</p>
                  {T_TITLE}:
                </div>
              ) : (
                <p className="filters-first_column">{T_TITLE}:</p>
              )}
              <FormControl
                sx={{
                  mt: -1,
                  mb: 0.5,
                  ml: 1,
                  mr: 1,
                  minWidth: 300,
                }}
                className="filters-row"
                key={F_ID}
              >
                {clientInputDecider(
                  F_TYPE,
                  F_ID,
                  null,
                  staticOptions,
                  foreignKeyValues,
                  addChange,
                  true,
                  true
                )}
              </FormControl>
            </Flex>
          ))}
        </Card>
      </Flex>
    </Modal>
  );
};
