import Flex from "../../../helpers/components/Flex";
import {
  Layout,
  Card,
  Modal,
  Button,
  Segmented,
  Select,
  Tooltip,
  Row,
  Col,
} from "antd";
import FormControl from "@mui/material/FormControl";
import { useCommonTitlesStore, useAuthStore } from "../../../store/useStores";
import { convertDateFormat } from "../../ultimateTable/helpers/converters";
import { inputDecider } from "./deciders";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableHead, TableBody } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import "../css/safetyAudit.css";
import { useState } from "react";
import { tableDecider } from "./deciders";
import AddProject from "../components/AddProject";
import AddAudit from "../components/AddAudit";
import AddSite from "../components/AddSite";
import { EditOutlined } from "@ant-design/icons";
import { qHeaders } from "../server";
import { useEffect } from "react";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const { Content } = Layout;
const { Option } = Select;

const NewParticipantModalContent = ({ headers, addChange }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);

  return (
    <Flex flexDirection={"column"}>
      <Content>
        <Card>
          <Flex flexDirection={"column"} style={{ gap: "0.7rem" }}>
            {headers.map((item) => (
              <Flex flexDirection={"row"} key={item.id}>
                <p style={{ width: 150 }}>{item.title}:</p>

                <FormControl
                  sx={{
                    mt: -1,
                    mb: 0.5,
                    ml: 1,
                    mr: 1,
                    minWidth: 300,
                  }}
                  className="filters-row"
                  key={item.id}
                >
                  {inputDecider(item, addChange, commonTitles, dateFormatToUse)}
                </FormControl>
              </Flex>
            ))}
          </Flex>
        </Card>
      </Content>
    </Flex>
  );
};

export const NewParticipantModal = ({
  visible,
  headers,
  onSave,
  onCancel,
  changes,
  addChange,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Modal
      title={"Προσθήκη Συμμετέχοντα"}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      destroyOnClose={true}
      width={500}
      style={{ marginTop: -20.5 }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(changes)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <NewParticipantModalContent headers={headers} addChange={addChange} />
    </Modal>
  );
};

const AddNavItemModalContent = ({ headers, addChange, rowKey, modalData }) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const dateFormat = useAuthStore((state) => state.dateFormat);
  let dateFormatToUse = convertDateFormat(dateFormat);

  return (
    <Card>
      <Flex flexDirection={"column"} style={{ gap: "0.7rem" }}>
        {headers.map((item) => (
          <Flex flexDirection={"row"} key={item.id}>
            <p style={{ width: 150 }}>{item.title}:</p>

            <FormControl
              sx={{
                mt: -1,
                mb: 0.5,
                ml: 1,
                mr: 1,
                minWidth: 300,
              }}
              className="filters-row"
              key={item.id}
            >
              {inputDecider(item, addChange, commonTitles, dateFormatToUse)}
            </FormControl>
          </Flex>
        ))}
      </Flex>
    </Card>
  );
};

export const AddNavItemModal = ({
  visible,
  onCancel,
  onSave,
  title,
  headers,
  addChange,
  config,
  parentKey,
  rowKey,
  modalData,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Modal
      title={title}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      destroyOnClose={true}
      width={"max-content"}
      style={{ height: "70vh", maxHeight: "70vh", marginTop: "-5rem" }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(config, parentKey)}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <AddNavItemModalContent
        headers={headers}
        addChange={addChange}
        rowKey={rowKey}
        modalData={modalData}
      />
    </Modal>
  );
};

const NavigationModalContent = ({
  modalData,
  modalAddTitles,
  onSave,
  onCancel,
  showAddNavItemModal,
  addNavItemModalVisible,
  addNavItemModalTitle,
  addNavItemModalHeaders,
  addNavItemModalConfig,
  addChange,
  updateNavDataChecked,
}) => {
  const [parentKey, setParentKey] = useState(Object.keys(modalData.data)[0]);

  return (
    <Flex key={parentKey} flexDirection={"row"}>
      <Card
        style={{
          maxHeight: "60vh",
          width: !modalData.childrenHeaders ? "100%" : "30%",
        }}
      >
        <TableContainer
          style={{
            maxHeight: "50vh",
          }}
          component={Paper}
          className="testing_table"
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "#186A8E",
                  }}
                />
                {modalData.headers.map((header) => (
                  <TableCell
                    style={{
                      backgroundColor: "#186A8E",
                      color: "white",
                      fontSize: 12,
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                    key={header.id}
                  >
                    {header.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(modalData.data).map(([key, value]) => (
                <TableRow
                  style={{
                    backgroundColor: key === parentKey ? "#cfe2f3" : "white",
                  }}
                  onClick={() => {
                    setParentKey(key);
                  }}
                  key={key}
                >
                  <TableCell
                    width="3%"
                    style={{
                      backgroundColor: key === parentKey ? "#cfe2f3" : "white",
                    }}
                  >
                    <EditOutlined
                      onClick={() =>
                        console.log("Επεξεργασία", modalData.headers, "parent")
                      }
                    />
                  </TableCell>
                  {modalData.headers.map((header) => (
                    <TableCell
                      key={header.id}
                      style={{
                        backgroundColor:
                          header.type === "color"
                            ? value[header.id]
                            : key === parentKey
                            ? "#cfe2f3"
                            : "white",
                      }}
                    >
                      {tableDecider(
                        header.type,
                        value[header.id],
                        updateNavDataChecked,
                        header.id,
                        key
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          style={{
            justifyContent: "flex-start",
            border: "none",
            color: "#186A8E",
            marginTop: "0.5rem",
          }}
          onClick={() =>
            showAddNavItemModal(modalAddTitles[0], modalData.headers, "parent")
          }
        >
          + {modalAddTitles[0]}
        </Button>
        {addNavItemModalVisible && (
          <AddNavItemModal
            visible={addNavItemModalVisible}
            onSave={onSave}
            onCancel={onCancel}
            headers={addNavItemModalHeaders}
            title={addNavItemModalTitle}
            addChange={addChange}
            config={addNavItemModalConfig}
          />
        )}
      </Card>
      {modalData.childrenHeaders ? (
        <>
          <ChevronRightIcon style={{ marginTop: "10rem", color: "#186A8E" }} />
          <Card style={{ width: "70%" }}>
            <TableContainer
              component={Paper}
              style={{ maxHeight: "60vh" }}
              className="testing_table"
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: "#186A8E",
                      }}
                    />
                    {modalData.childrenHeaders.map((header) => (
                      <TableCell
                        style={{
                          backgroundColor: "#186A8E",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 600,
                          whiteSpace: "nowrap",
                        }}
                        key={header.id}
                      >
                        {header.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(modalData.data[parentKey].children).map(
                    ([key, value]) => (
                      <TableRow key={key}>
                        <TableCell
                          width="3%"
                          style={{
                            backgroundColor:
                              key === parentKey ? "#cfe2f3" : "white",
                          }}
                        >
                          <EditOutlined onClick={() => console.log("edit")} />
                        </TableCell>
                        {modalData.childrenHeaders.map((header) => (
                          <TableCell key={header.id}>
                            {tableDecider(
                              header.type,
                              value[header.id],
                              updateNavDataChecked,
                              header.id,
                              key
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              style={{
                justifyContent: "flex-start",
                border: "none",
                color: "#186A8E",
                marginTop: "0.5rem",
              }}
              onClick={() =>
                showAddNavItemModal(
                  modalAddTitles[1],
                  modalData.childrenHeaders,
                  "child"
                )
              }
            >
              + {modalAddTitles[1]}
            </Button>
            {addNavItemModalVisible && (
              <AddNavItemModal
                visible={addNavItemModalVisible}
                onSave={onSave}
                onCancel={onCancel}
                headers={addNavItemModalHeaders}
                title={addNavItemModalTitle}
                addChange={addChange}
                config={addNavItemModalConfig}
                parentKey={parentKey}
              />
            )}
          </Card>
        </>
      ) : null}
    </Flex>
  );
};

export const NavigationModal = ({
  visible,
  onCancel,
  onSave,
  title,
  modalData,
  modalAddTitles,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [navData, setNavData] = useState(modalData);
  const [changes, setChanges] = useState({});
  const [addNavItemModalVisible, setAddNavItemModalVisible] = useState(false);
  const [addNavItemModalHeaders, setAddNavItemModalHeaders] = useState([]);
  const [addNavItemModalTitle, setAddNavItemModalTitle] = useState(null);
  const [addNavItemModalConfig, setAddNavItemModalConfig] = useState(null);

  const showAddNavItemModal = (title, headers, config) => {
    setAddNavItemModalHeaders(headers);
    setAddNavItemModalTitle(title);
    setAddNavItemModalConfig(config);
    setAddNavItemModalVisible(true);
  };

  const onAddNavItemModalCancel = () => {
    setAddNavItemModalVisible(false);
  };

  const onAddNavItemModalSave = (config, parentKey) => {
    if (config === "parent") {
      setNavData({
        ...navData,
        data: {
          ...navData.data,
          [Object.keys(navData).length + 1]: { ...changes, children: [] },
        },
      });
      setAddNavItemModalVisible(false);
    } else {
      setNavData({
        ...navData,
        data: {
          ...navData.data,
          [parentKey]: {
            ...navData.data[parentKey],
            children: {
              ...navData.data[parentKey].children,
              [Object.keys(navData.data[parentKey].children.length + 1)]: {
                ...changes,
                children: [],
              },
            },
          },
        },
      });
      setAddNavItemModalVisible(false);
    }
  };

  const addChange = (id, value) => {
    setChanges({ ...changes, [id]: value });
  };

  const updateNavDataChecked = (key, id, value) => {
    setNavData({
      ...navData,
      data: {
        ...navData.data,
        [key]: {
          ...navData.data[key],
          [id]: value,
        },
      },
    });
  };

  return (
    <Modal
      title={title}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      destroyOnClose={true}
      width={"95vw"}
      style={{ height: "70vh", maxHeight: "70vh", marginTop: "-5rem" }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave()}
        >
          {commonTitles["CO|1|SAVEBTN"]}
        </Button>,
      ]}
    >
      <NavigationModalContent
        modalData={navData}
        modalAddTitles={modalAddTitles}
        onCancel={onAddNavItemModalCancel}
        onSave={onAddNavItemModalSave}
        showAddNavItemModal={showAddNavItemModal}
        addNavItemModalVisible={addNavItemModalVisible}
        addNavItemModalTitle={addNavItemModalTitle}
        addNavItemModalHeaders={addNavItemModalHeaders}
        addNavItemModalConfig={addNavItemModalConfig}
        addChange={addChange}
        updateNavDataChecked={updateNavDataChecked}
      />
    </Modal>
  );
};

const AddTreeNodeContent = ({ projects, sites, addChange, tab, setTab }) => {
  return (
    <Card style={{ borderRadius: 15 }}>
      <Flex flexDirection={"column"} style={{ justifyContent: "center" }}>
        <Segmented
          style={{
            backgroundColor: "#cfe2f3",
            borderRadius: 10,
            marginBottom: 10,
            width: "max-content",
          }}
          options={["Έργο", "Εγκατάσταση", "Επιθεώρηση"]}
          value={tab}
          onChange={setTab}
        />
        {tab === "Έργο" && <AddProject addChange={addChange} />}
        {tab === "Εγκατάσταση" && (
          <AddSite projects={projects} addChange={addChange} />
        )}
        {tab === "Επιθεώρηση" && (
          <AddAudit projects={projects} sites={sites} addChange={addChange} />
        )}
      </Flex>
    </Card>
  );
};

export const AddTreeNodeModal = ({
  visible,
  onSave,
  onCancel,
  projects,
  sites,
  addChange,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const [tab, setTab] = useState("Έργο");

  const tabToConfig = (tab) => {
    if (tab === "Έργο") {
      return "project";
    }
    if (tab === "Εγκατάσταση") {
      return "site";
    }
    if (tab === "Επιθεώρηση") {
      return "audit";
    }
  };

  return (
    <Modal
      title={"Νέος κόμβος"}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      destroyOnClose={true}
      width={"90vw"}
      style={{ height: "70vh", maxHeight: "70vh", marginTop: "-5rem" }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(tabToConfig(tab))}
        >
          {"Εισαγωγή"}
        </Button>,
      ]}
    >
      <AddTreeNodeContent
        projects={projects}
        sites={sites}
        addChange={addChange}
        tab={tab}
        setTab={setTab}
      />
    </Modal>
  );
};

const AddSectionModalContent = ({
  sections,
  addChange,
  sectionsInUse,
  subsectionsInUse,
  tab,
  setTab,
  parentKey,
  dataKey,
  setParentKey,
  sectionName,
}) => {
  return (
    <Flex flexDirection={"column"}>
      <Segmented
        style={{
          backgroundColor: "#cfe2f3",
          borderRadius: 10,
          marginBottom: 10,
          width: "max-content",
        }}
        options={["Τμήμα", "Υποτμήμα"]}
        value={tab}
        onChange={setTab}
      />
      <Card>
        {tab === "Τμήμα" && (
          <>
            <Flex flexDirection={"row"}>
              <p
                style={{
                  width: "8rem",
                  fontWeight: 600,
                  marginRight: "1rem",
                }}
              >
                Τμήμα:
              </p>
              <div className="select">
                <Select
                  allowClear
                  style={{
                    width: 300,
                  }}
                  showSearch={true}
                  filterOption={(iv, op) =>
                    op.value
                      .toLocaleLowerCase()
                      .includes((iv || "").toLocaleLowerCase())
                  }
                  onChange={(e) => {
                    setParentKey(e.split("-")[0]);
                    addChange("section", e.split("-")[1], "section");
                  }}
                  defaultValue={""}
                  dropdownStyle={{ overflowY: "auto" }}
                  virtual={false}
                >
                  <Option key={""} className="option" value={""} />
                  {Object.entries(sections.data).map(([key, value]) => (
                    <Option
                      key={value.id}
                      disabled={
                        sectionsInUse.includes(value.section) ? true : false
                      }
                      className={
                        sectionsInUse.includes(value.section)
                          ? "option-disabled"
                          : "option"
                      }
                      value={`${key}-${value.section}`}
                    >
                      {value.section}
                    </Option>
                  ))}
                </Select>
              </div>
            </Flex>
            <Flex flexDirection={"row"}>
              <p
                style={{
                  width: "8rem",
                  fontWeight: 600,
                  marginRight: "1rem",
                }}
              >
                Υποτμήμα:
              </p>
              <div className="select">
                <Select
                  allowClear
                  style={{
                    width: 300,
                  }}
                  showSearch={true}
                  filterOption={(iv, op) =>
                    op.value
                      .toLocaleLowerCase()
                      .includes((iv || "").toLocaleLowerCase())
                  }
                  onChange={(e) => addChange("subsection", e, "section")}
                  defaultValue={""}
                  dropdownStyle={{ overflowY: "auto" }}
                  virtual={false}
                >
                  <Option key={""} className="option" value={""} />
                  {parentKey &&
                    Object.entries(sections.data[parentKey].children).map(
                      ([key, value]) => (
                        <Option
                          key={value.id}
                          className="option"
                          value={value.subsection}
                        >
                          {value.subsection}
                        </Option>
                      )
                    )}
                </Select>
              </div>
            </Flex>
          </>
        )}
        {tab === "Υποτμήμα" && (
          <Flex key={dataKey} flexDirection={"column"}>
            <Flex flexDirection={"row"}>
              <p
                style={{
                  width: "8rem",
                  fontWeight: 600,
                  marginRight: "1rem",
                }}
              >
                Τμήμα:
              </p>
              <div className="select">
                <Select
                  allowClear
                  style={{
                    width: 300,
                  }}
                  showSearch={true}
                  filterOption={(iv, op) =>
                    op.value
                      .toLocaleLowerCase()
                      .includes((iv || "").toLocaleLowerCase())
                  }
                  onChange={(e) => {
                    setParentKey(e.split("-")[0]);
                    // addChange("section", e.split("-")[1], "section");
                  }}
                  defaultValue={parentKey ? sectionName : ""}
                  dropdownStyle={{ overflowY: "auto" }}
                  virtual={false}
                >
                  <Option key={""} className="option" value={""} />
                  {Object.entries(sections.data)
                    .filter(([key, value]) =>
                      sectionsInUse.includes(value.section)
                    )
                    .map(([key, value]) => (
                      <Option
                        key={value.id}
                        className={"option"}
                        value={`${key}-${value.section}`}
                      >
                        {value.section}
                      </Option>
                    ))}
                </Select>
              </div>
            </Flex>
            <Flex flexDirection={"row"}>
              <p
                style={{
                  width: "8rem",
                  fontWeight: 600,
                  marginRight: "1rem",
                }}
              >
                Υποτμήμα:
              </p>
              <div className="select">
                <Select
                  allowClear
                  style={{
                    width: 300,
                  }}
                  showSearch={true}
                  filterOption={(iv, op) =>
                    op.value
                      .toLocaleLowerCase()
                      .includes((iv || "").toLocaleLowerCase())
                  }
                  onChange={(e) => addChange("name", e, "section")}
                  defaultValue={""}
                  dropdownStyle={{ overflowY: "auto" }}
                  virtual={false}
                >
                  <Option key={""} className="option" value={""} />
                  {parentKey &&
                    Object.entries(sections.data[parentKey].children).map(
                      ([key, value]) => (
                        <Option
                          key={value.id}
                          disabled={
                            subsectionsInUse.includes(value.subsection)
                              ? true
                              : false
                          }
                          className={
                            subsectionsInUse.includes(value.subsection)
                              ? "option-disabled"
                              : "option"
                          }
                          value={value.subsection}
                        >
                          {value.subsection}
                        </Option>
                      )
                    )}
                </Select>
              </div>
            </Flex>
          </Flex>
        )}
      </Card>
    </Flex>
  );
};

export const AddSectionModal = ({
  visible,
  onSave,
  onCancel,
  sections,
  addChange,
  qData,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const sectionsInUse = Object.values(qData).map((item) => item.section);
  const [tab, setTab] = useState("Τμήμα");

  const [parentKey, setParentKey] = useState(null);

  const sectionName = parentKey ? sections.data[parentKey].section : null;
  const dataKey = sectionName
    ? Object.entries(qData)
        .filter(([key, value]) => value.section === sectionName)
        .map(([key]) => key)[0]
    : null;

  const subsectionsInUse = dataKey
    ? qData[dataKey].subsections.map((subsection) => subsection.name)
    : [];

  useEffect(() => setParentKey(null), [tab]);

  return (
    <Modal
      title={"Νέο Τμήμα / Υποτμήμα"}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      destroyOnClose={true}
      width={"max-content"}
      style={{ height: "70vh", maxHeight: "70vh", marginTop: "-5rem" }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave(tab, sectionName)}
        >
          {"Εισαγωγή"}
        </Button>,
      ]}
    >
      <AddSectionModalContent
        sections={sections}
        addChange={addChange}
        sectionsInUse={sectionsInUse}
        subsectionsInUse={subsectionsInUse}
        tab={tab}
        setTab={setTab}
        parentKey={parentKey}
        dataKey={dataKey}
        setParentKey={setParentKey}
        sectionName={sectionName}
      />
    </Modal>
  );
};

const AddSubsectionModalContent = ({
  sections,
  addChange,
  sectionKey,
  subsectionsInUse,
}) => {
  return (
    <Card>
      <Flex flexDirection={"column"}>
        <Flex flexDirection={"row"}>
          <p
            style={{
              width: "8rem",
              fontWeight: 600,
              marginRight: "1rem",
            }}
          >
            Τμήμα:
          </p>
          <div className="select">
            <Select
              allowClear
              disabled
              style={{
                width: 300,
              }}
              showSearch={true}
              filterOption={(iv, op) => {
                let opToUse = op.key ? op.key : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              onChange={(e) => {
                addChange("section", e.split("-")[1], "subsection");
              }}
              defaultValue={sections.data[sectionKey].section}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option
                key={sections.data[sectionKey].section}
                className="option"
                value={sections.data[sectionKey].section}
              >
                {sections.data[sectionKey].section}
              </Option>
            </Select>
          </div>
        </Flex>
        <Flex flexDirection={"row"}>
          <p
            style={{
              width: "8rem",
              fontWeight: 600,
              marginRight: "1rem",
            }}
          >
            Υποτμήμα:
          </p>
          <div className="select">
            <Select
              allowClear
              style={{
                width: 300,
              }}
              showSearch={true}
              filterOption={(iv, op) => {
                let opToUse = op.key ? op.key : "";
                return opToUse.toLowerCase().includes(iv.toLowerCase());
              }}
              onChange={(e) => addChange("name", e)}
              defaultValue={""}
              dropdownStyle={{ overflowY: "auto" }}
              virtual={false}
            >
              <Option key={""} className="option" value={""} />
              {Object.entries(sections.data[sectionKey].children).map(
                ([key, value]) => (
                  <Option
                    key={value.id}
                    disabled={
                      subsectionsInUse.includes(value.subsection) ? true : false
                    }
                    className={
                      subsectionsInUse.includes(value.subsection)
                        ? "option-disabled"
                        : "option"
                    }
                    value={value.subsection}
                  >
                    {value.subsection}
                  </Option>
                )
              )}
            </Select>
          </div>
        </Flex>
      </Flex>
    </Card>
  );
};

export const AddSubsectionModal = ({
  visible,
  onSave,
  onCancel,
  sections,
  sectionKey,
  addChange,
  qData,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const sectionName = sections.data[sectionKey].section;
  const dataKey = Object.entries(qData)
    .filter(([key, value]) => value.section === sectionName)
    .map(([key]) => key)[0];

  const subsectionsInUse = qData[dataKey].subsections.map(
    (subsection) => subsection.name
  );

  return (
    <Modal
      title={"Νέο Τμήμα"}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      destroyOnClose={true}
      width={"max-content"}
      style={{ height: "70vh", maxHeight: "70vh", marginTop: "-5rem" }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave()}
        >
          {"Εισαγωγή"}
        </Button>,
      ]}
    >
      <AddSubsectionModalContent
        sections={sections}
        sectionKey={sectionKey}
        addChange={addChange}
        subsectionsInUse={subsectionsInUse}
      />
    </Modal>
  );
};

const AddFieldModalContent = ({
  sections,
  addChange,
  sectionKey,
  subsectionKey,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);
  const dateFormat = useAuthStore((state) => state.dateFormatToUse);
  let dateFormatToUse = convertDateFormat(dateFormat);

  // console.log("sectionKey", sectionKey);
  // console.log("subsectionKey", subsectionKey);
  return (
    <Card>
      <Flex
        flexDirection={"column"}
        style={{ gap: "2rem", marginLeft: "10rem" }}
      >
        <Row gutter={[20, 10]}>
          <Col className="gutter-row" span={10}>
            <Flex flexDirection={"row"}>
              <p
                style={{
                  width: "8rem",
                  fontWeight: 600,
                  marginRight: "1rem",
                }}
              >
                Τμήμα:
              </p>
              <p>{sections.data[sectionKey].section}</p>
            </Flex>
          </Col>
          <Col className="gutter-row" span={10}>
            <Flex flexDirection={"row"}>
              <p
                style={{
                  width: "8rem",
                  fontWeight: 600,
                  marginRight: "1rem",
                }}
              >
                Υποτμήμα:
              </p>
              <p>
                {sections.data[sectionKey].children[subsectionKey].subsection}
              </p>
            </Flex>
          </Col>
        </Row>

        <Row gutter={[20, 10]}>
          {qHeaders.map((header) => (
            <Col className="gutter-row" span={10} key={header.id}>
              <Flex
                key={header.id}
                flexDirection={"row"}
                style={{ alignItems: "center", marginBottom: "0.5rem" }}
              >
                <p
                  style={{
                    width: "8rem",
                    fontWeight: 600,
                    marginRight: "1rem",
                  }}
                >
                  {header.title}:
                </p>
                {inputDecider(header, addChange, commonTitles, dateFormatToUse)}
              </Flex>
            </Col>
          ))}
        </Row>
      </Flex>
    </Card>
  );
};

export const AddFieldModal = ({
  visible,
  onSave,
  onCancel,
  sections,
  sectionKey,
  subsectionKey,
  addChange,
}) => {
  const commonTitles = useCommonTitlesStore((state) => state.commonTitles);

  return (
    <Modal
      title={"Νέο Πεδίο"}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      destroyOnClose={true}
      width={"max-content"}
      style={{
        height: "50vh",
        maxHeight: "50vh",
        marginTop: "-5rem",
      }}
      footer={[
        <Button key="back" onClick={onCancel}>
          {commonTitles["CO|1|CANCELBTN"]}
        </Button>,
        <Button
          key="submit"
          className="confirm-btn"
          style={{ backgroundColor: "rgb(8, 8, 46)", color: "white" }}
          onClick={() => onSave()}
        >
          {"Εισαγωγή"}
        </Button>,
      ]}
    >
      <AddFieldModalContent
        sections={sections}
        sectionKey={sectionKey}
        subsectionKey={subsectionKey}
        addChange={addChange}
      />
    </Modal>
  );
};
