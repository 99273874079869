import axios from "axios";

export const loginUsingPost = async ({ username, password, baseURL }) => {
  const response = await axios.post(
    `${baseURL}/Login/apiloginuser`,
    { username, password },
    {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  //console.log("login: response: ", response.data);

  return response?.data ?? null;
};

export const loginUsingGet = async ({ username, password, baseURL }) => {
  const url = `${baseURL}/Login/apiloginuser/${username}/${password}`;

  const response = await axios.get(url, {
    headers: {
      "Content-type": "application/json\r\n",
      Accept: "application/json\r\n",
    },
    method: "GET",
  });

  return response?.data ?? null;
};
